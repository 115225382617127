import { Modal } from "reactstrap";
import styles from "../../styles.module.css";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { jackColors } from "../../../../assets/colors";
import { Banner } from "../../../../components/Banner";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { useState } from "react";
import { PDFOrImage } from "../../../invoiceComponents/tableJack";
import { useMutation } from "../../../../tools/api";

const CheckedBox = ({ onClick = () => {} }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <rect x="2" y="2" width="16" height="16" rx="4" fill="#343434" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7785 6.98974C14.0566 7.2594 14.0754 7.71648 13.8205 8.01065L9.52781 12.9655C9.40194 13.1108 9.22545 13.1954 9.03922 13.1996C8.853 13.2039 8.6732 13.1275 8.54149 12.9882L6.20002 10.5107C5.93333 10.2286 5.93333 9.77105 6.20002 9.48887C6.46672 9.20668 6.89913 9.20668 7.16583 9.48887L9.00294 11.4326L12.8136 7.03412C13.0685 6.73995 13.5005 6.72007 13.7785 6.98974Z"
        fill="white"
      />
    </svg>
  );
};

const UncheckedBox = ({ isReachedLimit = false, onClick = () => {} }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: isReachedLimit ? "not-allowed" : "pointer" }}
      onClick={onClick}
    >
      <rect
        x="3"
        y="3"
        width="14"
        height="14"
        rx="3"
        fill="white"
        stroke="#E6E6E8"
        stroke-width="2"
      />
    </svg>
  );
};

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <g clip-path="url(#clip0_2922_178526)">
        <path
          d="M21.1749 20.0005L24.7583 16.4255C24.9152 16.2686 25.0034 16.0557 25.0034 15.8338C25.0034 15.6119 24.9152 15.3991 24.7583 15.2421C24.6014 15.0852 24.3885 14.9971 24.1666 14.9971C23.9447 14.9971 23.7319 15.0852 23.575 15.2421L19.9999 18.8255L16.4249 15.2421C16.268 15.0852 16.0552 14.9971 15.8333 14.9971C15.6114 14.9971 15.3985 15.0852 15.2416 15.2421C15.0847 15.3991 14.9965 15.6119 14.9965 15.8338C14.9965 16.0557 15.0847 16.2686 15.2416 16.4255L18.8249 20.0005L15.2416 23.5755C15.1635 23.653 15.1015 23.7451 15.0592 23.8467C15.0169 23.9482 14.9951 24.0571 14.9951 24.1671C14.9951 24.2772 15.0169 24.3861 15.0592 24.4876C15.1015 24.5892 15.1635 24.6813 15.2416 24.7588C15.3191 24.8369 15.4113 24.8989 15.5128 24.9412C15.6144 24.9835 15.7233 25.0053 15.8333 25.0053C15.9433 25.0053 16.0522 24.9835 16.1538 24.9412C16.2553 24.8989 16.3475 24.8369 16.4249 24.7588L19.9999 21.1755L23.575 24.7588C23.6524 24.8369 23.7446 24.8989 23.8461 24.9412C23.9477 24.9835 24.0566 25.0053 24.1666 25.0053C24.2766 25.0053 24.3855 24.9835 24.4871 24.9412C24.5886 24.8989 24.6808 24.8369 24.7583 24.7588C24.8364 24.6813 24.8984 24.5892 24.9407 24.4876C24.983 24.3861 25.0048 24.2772 25.0048 24.1671C25.0048 24.0571 24.983 23.9482 24.9407 23.8467C24.8984 23.7451 24.8364 23.653 24.7583 23.5755L21.1749 20.0005Z"
          fill="#343434"
        />
      </g>
      <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#E6E6E8" />
      <defs>
        <clipPath id="clip0_2922_178526">
          <rect width="40" height="40" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const DraftSelectionModal = ({
  batchId = null,
  isOpen = false,
  limit = 0,
  drafts = [],
  toggle = () => {},
  onContinue = () => {},
}) => {
  const [selectedDrafts, setSelectedDrafts] = useState([]);

  const { mutation: createNewBatch, loading: loadingCreateNewBatch } =
    useMutation({
      url: `/reimbursements/${batchId}/create_new_batch`,
      afterSuccess: () => {
        onContinue();
      },
    });

  const data = drafts;

  const isReachedLimit = selectedDrafts.length >= limit;

  const handleContinue = () => {
    const matchedIdFinder = (id, items) =>
      items.find(({ id: itemId }) => itemId === id);

    const deletedDrafts =
      drafts?.filter(({ id }) => !matchedIdFinder(id, selectedDrafts)) ?? [];

    const deletedDraftIds = deletedDrafts.map(({ id }) => id);

    const payload = { receipt_ids: deletedDraftIds };

    createNewBatch(payload);
  };

  const handleSelectDraft = ({ draft, isSelected }) => {
    setSelectedDrafts((prev) => {
      if (isSelected) return prev.filter(({ id }) => id !== draft.id);
      if (isReachedLimit) return prev;
      return [...prev, draft];
    });
  };

  const handleCloseModal = () => {
    if (loadingCreateNewBatch) return;
    toggle();
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={handleCloseModal}
      style={{ width: "550px" }}
    >
      <div className={styles["draft-selection-modal"]}>
        <div className={styles["header"]}>
          <GothamMedium
            className="font20"
            style={{ color: jackColors.neutral900 }}
          >
            Confirm Action
          </GothamMedium>
          <CloseButton onClick={handleCloseModal} />
        </div>
        <div className={styles["content"]}>
          <Banner
            type="warning"
            msg={
              <div>
                <GothamMedium
                  className="font12"
                  style={{ color: jackColors.neutral900 }}
                >
                  You can't proceed all your expense.
                </GothamMedium>
                <GothamRegular
                  woFontColor
                  className="font12"
                  style={{ color: jackColors.neutral800, marginTop: "4px" }}
                >
                  Please select{" "}
                  <span style={{ fontFamily: "GothamMedium" }}>
                    max. {limit}
                  </span>{" "}
                  expenses to continue.
                </GothamRegular>
              </div>
            }
          />
          <div className={styles["draft-table"]}>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "36px" }}></th>
                  <th style={{ width: "280px", paddingLeft: "16px" }}>
                    <GothamRegular
                      woFontColor
                      className="font12"
                      style={{ color: jackColors.neutral700 }}
                    >
                      Expense
                    </GothamRegular>
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      paddingRight: "8px",
                      width: "110px",
                    }}
                  >
                    <GothamRegular
                      woFontColor
                      className="font12"
                      style={{ color: jackColors.neutral700 }}
                    >
                      Amount
                    </GothamRegular>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((draft, index) => {
                  const { id: draftId, merchant, amount, thumbnail } = draft;

                  const isLast = index === data.length - 1;
                  const isSelected = selectedDrafts.find(
                    ({ id }) => id === draftId
                  );

                  const handleClick = () =>
                    handleSelectDraft({ draft, isSelected });

                  return (
                    <tr
                      key={draftId}
                      style={{ borderBottom: isLast ? "none" : "" }}
                    >
                      <td style={{ paddingLeft: "8px", width: "36px" }}>
                        {isSelected ? (
                          <CheckedBox onClick={handleClick} />
                        ) : (
                          <UncheckedBox
                            isReachedLimit={isReachedLimit}
                            onClick={handleClick}
                          />
                        )}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                          width: "280px",
                          paddingLeft: "16px",
                        }}
                      >
                        <PDFOrImage pdf={thumbnail} />
                        <GothamRegular
                          style={{
                            color: jackColors.neutral900,
                            maxWidth: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {merchant}
                        </GothamRegular>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "8px",
                          width: "110px",
                        }}
                      >
                        <GothamRegular style={{ color: jackColors.neutral900 }}>
                          IDR {formatCurrencyNoDecimal(amount)}
                        </GothamRegular>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles["footer"]}>
          <ButtonJack
            type="outline"
            style={{ width: "100%" }}
            isLoading={loadingCreateNewBatch}
            onClick={handleCloseModal}
          >
            Back
          </ButtonJack>
          <ButtonJack
            style={{ width: "100%" }}
            isLoading={loadingCreateNewBatch}
            disabled={selectedDrafts?.length === 0}
            rightIcon={
              <JackIcons name="arrow-forward" fill={jackColors.neutral900} />
            }
            onClick={handleContinue}
          >
            Continue
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};

export default DraftSelectionModal;
