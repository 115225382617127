import { Fragment } from "react";
import { Banner } from "../../../../../components/Banner";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import { SINGLE, DATA_ERROR, TRX_TEMPLATE, BATCH } from "../../constants";
import {
  grayBannerMessageDecider,
  retryResubmitBannerMessageDecider,
  retryResubmitBannerTitleDecider,
  retryResubmitBooleans,
  retryResubmitValidator,
} from "../../helpers";
import { pluralize } from "../../../../../pageComponents/invoiceComponents/data/formatter";
import { jackColors } from "../../../../../assets/colors";
import { useTranslation } from "react-i18next";

const RetryResubmitBanner = ({
  trx = TRX_TEMPLATE,
  trxType = SINGLE, // single | batch
  trxStatus = "", // failed | partial_failed (should match to render)
  warningType = DATA_ERROR, // data_error | system_error | mixed_error
  failedTrxCount = 0, // should have more than 0 to render
  invalidFailedTrxCount = 0,
  drafter = {}, // should have drafter to render
  releaser = {}, // should have releaser to render
  style = {},
  resubmittedAt = "",
  remindTiming = {
    shouldWaitRemindTiming: false,
    enableRemindTiming: () => {},
  },
  onSeeDetails = () => {},
}) => {
  const { user } = useGetAuth();
  const { t } = useTranslation("retry-resubmit/retry-resubmit");

  const { isDrafter, isReleaser, isReminder, isResubmit } =
    retryResubmitBooleans({
      user,
      drafter,
      releaser,
      warningType,
    });

  const isValidRetryResubmit = retryResubmitValidator({
    purpose: "banner",
    drafter,
    releaser,
    trxStatus,
    warningType,
    failedTrxCount,
  });

  const shallShowBanner = isValidRetryResubmit && (isDrafter || isReleaser);

  if (!shallShowBanner) return null;

  const title = retryResubmitBannerTitleDecider({
    trxType,
    warningType,
    failedTrxCount,
    t,
  });
  const message = retryResubmitBannerMessageDecider({
    user,
    drafter,
    releaser,
    warningType,
    t,
  });
  const grayMessage = grayBannerMessageDecider({
    trx,
    user,
    drafter,
    releaser,
    warningType,
    resubmittedAt,
    t,
  });

  const { shouldWaitRemindTiming } = remindTiming ?? {};

  const isRetriedSingle = trxType === SINGLE && !!resubmittedAt;
  const isResubmittedBatch = trxType === BATCH && !!resubmittedAt;

  const showGrayBanner =
    (grayMessage !== "-" && isReminder && shouldWaitRemindTiming) ||
    isRetriedSingle ||
    isResubmittedBatch;

  const showBanner = message !== "-";

  const showSeeDetailsButton = isRetriedSingle || isResubmittedBatch;

  if (showGrayBanner && grayMessage === "-") return null;

  if (showGrayBanner) {
    return (
      <Banner
        type="information"
        // title={isReminder && !resubmittedAt ? grayMessage : ""}
        msg={grayMessage}
        style={style}
        textContainerStyle={{
          paddingRight: showSeeDetailsButton ? "24px" : "0px",
        }}
        button={
          showSeeDetailsButton && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GothamMedium
                woFontColor
                style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                onClick={onSeeDetails}
              >
                {t("See Details")}
              </GothamMedium>
            </div>
          )
        }
      />
    );
  }

  if (!showBanner) return null;

  return (
    <Banner
      type="warning"
      title={title}
      msg={
        <Fragment>
          {message}
          {!!invalidFailedTrxCount && (
            <GothamRegular
              className="font12"
              style={{ marginTop: "12px", color: jackColors.neutral800 }}
            >
              <span
                style={{
                  fontFamily: "GothamMedium",
                }}
              >
                Note:
              </span>{" "}
              {invalidFailedTrxCount}{" "}
              {pluralize(invalidFailedTrxCount, "transaction")} might not be{" "}
              {isResubmit ? "resubmitted" : "retried"} and will be refunded to
              your company balance.
            </GothamRegular>
          )}
        </Fragment>
      }
      style={style}
    />
  );
};

export default RetryResubmitBanner;
