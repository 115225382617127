import { useRouter } from "next/router";
import { useEffect } from "react";
import { fetch, useMutation } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { ValidatingScreen } from "../../localTransferComponents/validating/components";
import { localTransferCounterFormatter } from "../../localTransferComponents/validating/formatter";
import { useTranslation } from "react-i18next";

export const ValidatingPayroll = () => {
  const { query, push } = useRouter();
  const { id } = query;
  const { t } = useTranslation("payroll/create");

  const { data: batch_id } = fetch({
    url: `/payrolls/${id}`,
    formatter: (res) => res?.data?.batch_id,
    defaultValue: false,
  });

  const { data, refetch } = fetch({
    url: `/local_transaction_batches/${batch_id}/show_validating_progress`,
    formatter: localTransferCounterFormatter,
    woInit: true,
    defaultValue: {},
  });

  const {
    processed = 0,
    total = 1,
    isCompleted,
    isDataValidation,
  } = data || {};

  const { mutation: changeState } = useMutation({
    url: `/local_transaction_batches/${batch_id}/change_state`,
    afterSuccess: () => push(`/payroll/create/${id}`),
  });

  useEffect(() => {
    if (!batch_id) return;
    let interval = setInterval(async () => await refetch(), 3000);

    return () => clearInterval(interval);
  }, [batch_id]);

  useEffect(() => {
    if (total == 0) return changeState({ state: "draft" });

    if (!isCompleted) return;

    if (isDataValidation)
      return push({ query: { ...query, id, state: "validation" } });

    push({ query: { ...query, id, state: "confirmation" } });
  }, [isCompleted]);

  useEffect(() => eventsTracker("payroll_validating", { id }), []);
  return (
    <ValidatingScreen
      total={total}
      processed={processed}
      title={t("Validating bank accounts")}
    />
  );
};
