import styles from "./CategoryOnboardModal.module.css";
import SimpleCenteredModal from "../../../../../modals/SimpleCenteredModal";
import { GothamBold, GothamRegular } from "../../../../../components/Text";
import { jackColors } from "../../../../../assets/colors";
import { Fragment } from "react";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { getUserRole } from "../../../../../contexts/AuthContext";
import CategoryArticle from "../CategoryArticle";
import { Trans, useTranslation } from "react-i18next";
import { useLanguage } from "public/locales/translationFunctions";

const CategoryOnboardModal = ({
  isOpen = false,
  toggle = () => {},
  onClick = () => {},
}) => {
  const { t } = useTranslation("category/index");
  const { isBahasa } = useLanguage();
  const { isEmployee, isBookKeeper } = getUserRole();

  const isWeakUser = isEmployee || isBookKeeper;

  return (
    <SimpleCenteredModal
      modalClassName={styles["category-onboard-modal"]}
      isOpen={isOpen}
      toggle={toggle}
      customButtons={
        isWeakUser ? (
          <ButtonJack style={{ width: "100%" }} onClick={toggle}>
            Got it!
          </ButtonJack>
        ) : (
          <Fragment>
            <ButtonJack
              type="outline"
              style={{ width: "100%" }}
              onClick={toggle}
            >
              {t("I'll do it later")}
            </ButtonJack>
            <ButtonJack
              style={{ width: "100%" }}
              rightIcon={
                <JackIcons
                  name="arrow-forward-outline"
                  fill={jackColors.neutral900}
                />
              }
              onClick={onClick}
            >
              {t("Set up Categories")}
            </ButtonJack>
          </Fragment>
        )
      }
    >
      <div className={styles["category-onboard-content"]}>
        <img
          width={476}
          height={200}
          src={`/images/category-onboard${isBahasa ? "-id" : ""}.png`}
          alt="Category Intro Image"
        />
        <GothamBold
          woFontSize
          className="font20"
          style={{
            color: jackColors.neutral900,
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          {isWeakUser
            ? "Introducing new feature: Categories"
            : t("Set up Categories in minutes!")}
        </GothamBold>
        {isWeakUser ? (
          <Fragment>
            <GothamRegular
              woFontColor
              style={{ color: jackColors.neutral800, marginTop: "12px" }}
            >
              On this page, you can see categories that are available to you.
            </GothamRegular>
            <GothamRegular
              woFontColor
              style={{ color: jackColors.neutral800, marginTop: "20px" }}
            >
              Help your company have well-organized transactions by ensuring to
              select a suitable category for your transactions.
            </GothamRegular>
          </Fragment>
        ) : (
          <GothamRegular
            woFontColor
            style={{ color: jackColors.neutral800, marginTop: "12px" }}
          >
            <Trans
              i18nKey={t("Dynamic.onboard_text")}
              components={[<span style={{ fontFamily: "GothamMedium" }} />]}
            />
          </GothamRegular>
        )}
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral800, marginTop: "20px" }}
        >
          {isWeakUser
            ? "Need more details, you can go check"
            : t("For full guidance, you can go check")}{" "}
          <CategoryArticle />
        </GothamRegular>
      </div>
    </SimpleCenteredModal>
  );
};

export default CategoryOnboardModal;
