import { jackColors } from "../../assets/colors";
import { GothamMedium } from "../Text";
import styles from "./styles.module.css";
import { useLanguage } from "public/locales/translationFunctions";

const NewTag = () => {
  const { isBahasa } = useLanguage();
  return (
    <div className={styles.newTag}>
      <GothamMedium className="font10" style={{ color: jackColors.neutral900 }}>
        {isBahasa ? "Baru" : "New"}
      </GothamMedium>
    </div>
  );
};

export default NewTag;
