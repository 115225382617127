import { upperCase } from "change-case";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../../components/Avatar";
import { windowDimension } from "../../../components/tools";
import {
  GothamMedium,
  GothamRegular,
} from "../../../components/typescript/Text";
import { RecipientType } from "pageComponents/localTransferEnhancement/typescript/create/singleTransferComponents/add-recipient/index.types";
import { TextInlineMedium } from "components/Text";
import styles from "./styles.module.css";
import { useLanguage } from "public/locales/translationFunctions";

type IndividualUserProps = {
  user: RecipientType;
  handleSelectRecipient: (
    user: RecipientType,
    select?: boolean | undefined
  ) => void;
  checkedFromParent?: boolean;
  isMinimalistStyle?: boolean;
  woMultipleSelect?: boolean;
  disabled?: boolean;
};

const IndividualUser = ({
  user,
  handleSelectRecipient,
  checkedFromParent = false,
  isMinimalistStyle = false,
  woMultipleSelect = false,
  disabled = false,
}: IndividualUserProps) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checkedFromParent) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkedFromParent]);

  const handleClick = (user: RecipientType) => {
    setChecked((prev) => !prev);
    if (checked) {
      handleSelectRecipient(user, false);
    } else {
      handleSelectRecipient(user);
    }
  };

  const { isBahasa } = useLanguage();
  const { isTabOrPhone } = windowDimension();

  const {
    name: name_raw = "",
    registered_name = "",
    bank_name = "",
    account_number = "",
    created_at = "",
  } = user || {};

  const name = registered_name || name_raw;

  const createdAt = new Date(created_at);
  const now = new Date();
  const timeDifference = now.getTime() - createdAt.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  const showNewBadge = hoursDifference <= 24;

  const newBadge = (
    <TextInlineMedium
      className="font12"
      style={{
        borderRadius: "4px",
        background: "var(--Primary-Stabilow, #B9FC00)",
        padding: "2px 4px",
      }}
    >
      {isBahasa ? "Baru" : "New"}
    </TextInlineMedium>
  );

  return (
    <HoveredDiv
      className={`hover-600 ${disabled ? styles.disabled : ""}`}
      onClick={() => handleClick(user)}
      // style={{ backgroundColor: checked ? jackColors.neutral400 : undefined }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          margin: "0px 8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Avatar
            name={name}
            size="large"
            style={{}}
            bottomRightStyle={{}}
            textStyle={{}}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 12,
              gap: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
              }}
            >
              <GothamMedium
                style={{
                  textOverflow: "ellipsis",
                  maxWidth: 260,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {name}
              </GothamMedium>
              {showNewBadge && newBadge}
            </div>
            {isMinimalistStyle ? (
              <div className="d-flex">
                <GothamRegular
                  style={{ fontSize: "12px", color: jackColors.grey90 }}
                >
                  {upperCase(bank_name)} - {account_number}
                </GothamRegular>
              </div>
            ) : (
              <>
                <GothamRegular>{upperCase(bank_name)}</GothamRegular>
                <GothamRegular>{account_number}</GothamRegular>
              </>
            )}
          </div>
        </div>
        <div>
          {woMultipleSelect ? (
            <JackIcons
              name={checked ? "checkmark-circle-2" : ""}
              fill={checked ? jackColors.neutral900 : jackColors.neutral600}
              style={{ width: 20, height: 20 }}
            />
          ) : (
            <JackIcons
              name={checked || disabled ? "checkmark-square-2" : "square"}
              fill={checked ? jackColors.neutral900 : jackColors.neutral600}
              style={{ width: 20, height: 20 }}
            />
          )}
        </div>
      </div>
    </HoveredDiv>
  );
};

const HoveredDiv = styled.div`
  padding: 14px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  position: relative;
`;
// max-width: 438px;

export default IndividualUser;
