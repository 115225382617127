import { GothamMedium, GothamRegular } from "components/Text";
import styles from "./styles.module.css";
import { Fragment } from "react";
import { useModalHook } from "components/Modals";
import DanamonAdvertisementModal from "modals/DanamonAdvertisementModal";

const DanamonReleaseAdvertisement = () => {
  const { isOpen, open, close } = useModalHook();

  return (
    <Fragment>
      <div className={styles.danamonReleaseAdvertisement}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.19331 7.71914H4.0705V11.649H5.19331V7.71914ZM8.56173 7.71914H7.43892V11.649H8.56173V7.71914ZM13.3337 12.7718H2.66699V13.8946H13.3337V12.7718ZM11.9301 7.71914H10.8073V11.649H11.9301V7.71914ZM8.00033 2.6665L2.66699 5.47352V6.59633H13.3337V5.47352L8.00033 2.6665ZM5.07541 5.47352L8.00033 3.93528L10.9252 5.47352H5.07541Z"
            fill="#B9FC00"
          />
        </svg>
        <div className={styles.textContainer}>
          <GothamMedium className="font12" style={{ color: "white" }}>
            No more fees with a Checking Account!
          </GothamMedium>
          <GothamRegular
            woFontColor
            className="font12"
            style={{ color: "white" }}
          >
            Save up to IDR 2,500,000/month.{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={open}
            >
              Click here to find out how
            </span>
          </GothamRegular>
        </div>
      </div>

      <DanamonAdvertisementModal
        isOpen={isOpen}
        toggle={close}
        onClickLater={close}
      />
    </Fragment>
  );
};

export default DanamonReleaseAdvertisement;
