export const memberFinder = ({
  isExact = true,
  searchValue = "",
  member = {},
  isEmailOnly = false,
}) => {
  const { name: nameRaw, email: emailRaw } = member;

  const name = (nameRaw ?? "").toLocaleLowerCase();
  const email = (emailRaw ?? "").toLocaleLowerCase();

  if (isExact) {
    if (isEmailOnly) return email === searchValue;
    return name === searchValue || email === searchValue;
  }
  return isEmailOnly
    ? email.includes(searchValue)
    : name.includes(searchValue) || email.includes(searchValue);
};
