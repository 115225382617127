import { Fragment } from "react";

const MixedFont = ({ sentences = [{ content: "", isBolder: false }] }) => {
  return (
    <Fragment>
      {sentences.map(({ content, isBolder }) => {
        if (isBolder) {
          return <span style={{ fontFamily: "GothamMedium" }}>{content}</span>;
        }
        return content;
      })}
    </Fragment>
  );
};

export default MixedFont;
