import parse, { domToReact } from "html-react-parser";
import { GothamMedium, GothamRegular } from "./Text";

const ParserRichTextArea = ({ text, textStyle }) => {
  const options = {
    replace: ({ children, name }) => {
      if (name === "p") {
        return (
          <GothamRegular
            style={{
              display: "block",
              fontSize: "12px",
              ...textStyle,
            }}
          >
            {domToReact(children, options)}
          </GothamRegular>
        );
      }

      if (name === "strong") {
        return (
          <GothamMedium
            style={{
              display: "inline",
              fontSize: "12px",
              ...textStyle,
            }}
          >
            {domToReact(children, options)}
          </GothamMedium>
        );
      }

      if (name === "ul") {
        return (
          <ul
            style={{
              margin: 0,
              padding: "0px",
              paddingLeft: "0px",
              listStylePosition: "inside",
              fontSize: "12px",
              ...textStyle,
            }}
          >
            {domToReact(children, options)}
          </ul>
        );
      }

      if (name === "ol") {
        return (
          <ol
            style={{
              margin: 0,
              padding: "0px",
              paddingLeft: "0px",
              listStylePosition: "inside",
              fontSize: "12px",
              ...textStyle,
            }}
          >
            {domToReact(children, options)}
          </ol>
        );
      }
    },
  };
  const isRichText =
    text.includes("<p>") ||
    text.includes("<ul>") ||
    text.includes("<ol>") ||
    text.includes("<strong>");
  const formattedText = isRichText ? text : `<p>${text}</p>`;

  return <div>{parse(formattedText, options)}</div>;
};

export default ParserRichTextArea;
