import talenta from "../../../public/images/talenta.png";
import gadjian from "../../../public/images/gadjian.png";
import bca from "../../../public/images/BCA.png";
import oy from "../../../public/images/oy.png";
import xendit from "../../../public/images/xendit.png";

export const TemplatePayrollArr = [
  { image: talenta, title: "Talenta", value: "talenta" },
  // { image: gadjian, title: "Gadjian", value: "gadjian" },
  // { image: bca, title: "BCA Multi Auto Payment", value: "bca" },
  // { image: oy, title: "OY", value: "oy" },
  // { image: xendit, title: "Xendit", value: "xendit" },
];
