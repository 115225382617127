import MIXPANELS from "../../../../universalFunctions/mixpanels";
import { LOCAL, PAYROLL, BILL, REIMBURSEMENT } from "../constants";

const remindConfirmationMixpanels = ({ trx, isRetry }) => {
  switch (trx?.type) {
    case LOCAL:
      if (isRetry) {
        return MIXPANELS.RETRY_RESUBMIT.RETRY_REMIND_LOCAL_TRANSFER();
      }
      return MIXPANELS.RETRY_RESUBMIT.RESUBMIT_REMIND_LOCAL_TRANSFER();
    case PAYROLL:
      if (isRetry) {
        return MIXPANELS.RETRY_RESUBMIT.RETRY_REMIND_PAYROLL();
      }
      return MIXPANELS.RETRY_RESUBMIT.RESUBMIT_REMIND_PAYROLL();
    case REIMBURSEMENT:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_REMIND_REIMBURSEMENT();
    case BILL:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_REMIND_INVOICE();
    default:
      return;
  }
};
const remindMixpanels = ({ trx, isRetry }) => {
  switch (trx?.type) {
    case LOCAL:
      if (isRetry) {
        return MIXPANELS.RETRY_RESUBMIT.RETRY_REMIND_LOCAL_TRANSFER_CONFIRM();
      }
      return MIXPANELS.RETRY_RESUBMIT.RESUBMIT_REMIND_LOCAL_TRANSFER_CONFIRM();
    case PAYROLL:
      if (isRetry) {
        return MIXPANELS.RETRY_RESUBMIT.RETRY_REMIND_PAYROLL_CONFIRM();
      }
      return MIXPANELS.RETRY_RESUBMIT.RESUBMIT_REMIND_PAYROLL_CONFIRM();
    case REIMBURSEMENT:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_REMIND_REIMBURSEMENT_CONFIRM();
    case BILL:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_REMIND_INVOICE_CONFIRM();
    default:
      return;
  }
};

const retryConfirmationMixpanels = ({ trx }) => {
  switch (trx?.type) {
    case LOCAL:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_LOCAL_TRANSFER();
    case PAYROLL:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_PAYROLL();
    case REIMBURSEMENT:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_REIMBURSEMENT();
    case BILL:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_INVOICE();
    default:
      return;
  }
};
const retryMixpanels = ({ trx }) => {
  switch (trx?.type) {
    case LOCAL:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_INPUT_PIN_LOCAL_TRANSFER_CONFIRM();
    case PAYROLL:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_INPUT_PIN_PAYROLL_CONFIRM();
    case REIMBURSEMENT:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_INPUT_PIN_REIMBURSEMENT_CONFIRM();
    case BILL:
      return MIXPANELS.RETRY_RESUBMIT.RETRY_INPUT_PIN_INVOICE_CONFIRM();
    default:
      return;
  }
};
const retryResubmitButtonMixpanels = ({ trx, isRetry }) => {
  return {
    remindConfirmationMixpanels: () =>
      remindConfirmationMixpanels({ trx, isRetry }),
    remindMixpanels: () => remindMixpanels({ trx, isRetry }),
    retryConfirmationMixpanels: () => retryConfirmationMixpanels({ trx }),
    retryMixpanels: () => retryMixpanels({ trx }),
  };
};

export default retryResubmitButtonMixpanels;
