import styles from "./styles.module.css";
import { useState } from "react";
import { GothamMedium } from "../Text";
import { jackColors } from "../../assets/colors";
import PropTypes from "prop-types";
import { EXAMPLE_UPDATES, FOLDED, INACTIVE, PRIMARY } from "./constants";
import foldedUpdatesFormatter from "./helpers/foldedUpdatesFormatter";
import { StatusDot, UpdateContent } from "./components";

const updatesTemplate = [
  {
    status: INACTIVE, // completed | success | failed | active | inactive (default) | partial_failed | retry
    contents: [
      {
        type: PRIMARY, // primary | secondary | reason | custom
        content: "Content",
      },
    ],
  },
];

const UpdateTrackerV2 = ({
  updates: updatesRaw = updatesTemplate,
  style = {},
  dotStyle = {},
  progressLineStyle = {},
}) => {
  const [showAll, setShowAll] = useState(false);

  const isValidUpdates = Array.isArray(updatesRaw) && updatesRaw.length > 0;

  if (!isValidUpdates) return null;

  const hasManyUpdates = updatesRaw.length > 4;
  const showFoldedUpdates = hasManyUpdates && !showAll;
  const foldedUpdates = foldedUpdatesFormatter(updatesRaw);
  const updates = showFoldedUpdates ? foldedUpdates : updatesRaw;

  const handleShowToggle = () => setShowAll((prev) => !prev);

  return (
    <div
      data-testid="update-tracker-v2"
      className={styles.mainContainer}
      style={style}
    >
      {updates.map(({ status, contents }, index) => {
        const isLast = index === updates.length - 1;
        const isFolded = status === FOLDED;
        const isFoldedNextUpdate = updates[index + 1]?.status === FOLDED;

        const hideProgressLine = isLast || isFolded || isFoldedNextUpdate;

        return (
          <div
            data-testid="update-tracker-v2-item"
            key={`${status}-${index}`}
            className={styles.update}
          >
            <div className={styles.iconContainer}>
              <StatusDot status={status} />
            </div>

            {!hideProgressLine && <div className={styles.progressLine} />}

            <div className={styles.contentContainer}>
              {contents.map(({ type, content }, index) => {
                return (
                  <UpdateContent
                    key={`${type}-${index}`}
                    type={type}
                    content={content}
                  />
                );
              })}
            </div>
          </div>
        );
      })}

      {hasManyUpdates && (
        <div className={styles.seeContainer}>
          <GothamMedium
            data-testid="show-toggle"
            className="font12"
            style={{
              color: jackColors.neutral900,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleShowToggle}
          >
            {showAll ? "See less" : "See all updates"}
          </GothamMedium>
        </div>
      )}
    </div>
  );
};

UpdateTrackerV2.propTypes = {
  /**
   * Possible update status:
   * - completed
   * - success
   * - failed
   * - active
   * - inactive (default)
   * - partial_failed
   * - retry
   *
   * Possible update content type:
   * - primary
   * - secondary (slightly lighter color)
   * - reason (has reason label)
   * - custom (use your own component)
   *
   * To create mixed font weight you can use this pattern in the content instead of raw string:
   * `
   * [
   *    {
   *        type: "bold",
   *        value: "You",
   *    },
   *    " created this transaction"
   * ]
   * `
   */
  updates: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * Container style
   */
  style: PropTypes.object,
};

UpdateTrackerV2.defaultProps = {
  updates: EXAMPLE_UPDATES,
};

export default UpdateTrackerV2;
