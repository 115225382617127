import {
  TextFieldProps as TextFieldPropsMui,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  withStyles,
  InputBaseComponentProps,
  InputProps,
  FilledInputProps,
  OutlinedInputProps,
} from "@material-ui/core";
import { colors, jackColors } from "assets/colors";
import JackIcons from "assets/jackIcons/typescript/parent";
import { GothamRegular, TextInlineRegular } from "components/typescript/Text";
import { jackIconsTo20, useAutofocus } from "components/inputs/textfield";
import { uniqueId } from "lodash";
import {
  CSSProperties,
  isValidElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Tooltip } from "reactstrap";
import {
  AtomicTextFieldJackProps,
  CustomTooltipProps,
  ErrorMsgProps,
  FormValues,
  NumberFieldJackProps,
  TextFieldJackHelpProps,
  TextFieldJackStateProps,
  TextFieldProps,
} from "./textfield.types";
import { sentenceCase, titleCase } from "change-case";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormReturn,
} from "react-hook-form";
import { formatCurrency, unformatCurrency } from "@tools";
import { OverrideReturnType } from "components/typescript/tools";
import { useLanguage } from "public/locales/translationFunctions";

const CustomTooltip = ({
  children,
  placement = "top",
  text = "",
  customId = null,
  alwaysOpen = false,
  ...props
}: CustomTooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipId = useMemo(() => "tooltip" + uniqueId(), []);

  return (
    <span id={"Tooltip-" + (customId || tooltipId)}>
      {children}
      <Tooltip
        placement={placement}
        isOpen={tooltipOpen || alwaysOpen}
        target={"Tooltip-" + (customId || tooltipId)}
        toggle={toggle}
        {...props}
      >
        {text}
      </Tooltip>
    </span>
  );
};
export default CustomTooltip;

export const ErrorMsg = ({
  children,
  isError = true,
  style,
}: ErrorMsgProps) => (
  <GothamRegular
    className="font10"
    style={{
      marginTop: 4,
      color: isError ? jackColors.redE7 : jackColors.grey90,
      ...style,
    }}
  >
    {children}
  </GothamRegular>
);

export const TextFieldJackHelp = ({
  msg,
  isError,
  helperTextStyle,
}: TextFieldJackHelpProps) => {
  if (!msg) return null;
  return (
    <ErrorMsg isError={isError} style={helperTextStyle}>
      {msg}
    </ErrorMsg>
  );
};

export const AtomicTextFieldJack = ({
  icon: iconProps,
  iconRight: iconRightProps,
  placeholder = "",
  helperText,
  label,
  name,
  style,
  textFieldStyle,
  woLabel,
  noBorder,
  labelStyle,
  disabled,
  required, // di culik biar ga ke kirim ke <TextField
  isAutoFill,
  textFieldInputStyle,
  placeholderStyle,
  onBlur: onBlurProps = (e) => {},
  onFocus: onFocusProps = (e) => {},
  preventTab,
  isTransparent,
  helperTextStyle,
  withLetterCounter,
  maxLength,
  letterCounterStyle,
  tooltip,
  tooltipStyle,
  isOptional,
  disableUnderline = true,
  idContainer,
  isError = false,
  hasValue = false,
  value,
  error,
  customClassname = "", // this props is meant to override built in mui's textfield style
  readOnly = false,
  ...props
}: AtomicTextFieldJackProps) => {
  const { isBahasa } = useLanguage();

  const [icon, iconRight] = jackIconsTo20([iconProps, iconRightProps]);
  const [isFocused, setIsFocused] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isAutoFill) return setIsReady(true);
    const func = () => setIsReady(true);
    document.addEventListener("click", func);
    return () => {
      document.removeEventListener("click", func);
    };
  }, []);

  const isPlaceHolderPushed = isFocused && !hasValue;

  const [clientWidth, setClientWidth] = useState(0);

  useEffect(() => {
    if (!!icon) return;
    setClientWidth(0);
  }, [!!icon]);

  const left = clientWidth + 4 + 12 + (isPlaceHolderPushed ? 4 : 0);
  const textFieldClassNameDecider = () => {
    // if you need to add a custom style (overriding mui's builtin style properties) please provide two global css classname on input.css, first one is the "custom_classname" for the default style and the other is "{custom_classname}-focus" to set the textfield style on focus.
    // you also need to set border: "none" to textFieldStyle props in order for this to work
    if (isFocused && !!customClassname) return `${customClassname}-focus`;
    if (!!customClassname) return customClassname;
    if (isFocused || isError) return "";
    if (noBorder) return "";
    return "textfield";
  };

  const textFieldClassName = textFieldClassNameDecider();

  const handleOnFocus = (value: any) => {
    setIsFocused(true);
    onFocusProps(value);
  };

  const handleOnBlur = (value: any) => {
    setIsFocused(false);
    onBlurProps(value);
  };

  return (
    <div
      style={{
        flexDirection: "column",
        position: "relative",
        marginBottom: 32,
        ...style,
      }}
      className="d-flex"
      id={idContainer}
    >
      {!hasValue && isReady && (
        <div
          style={{
            position: "absolute",
            top: label ? 29 + 6 : 19,
            left: -2,
            translate: `${left}px 0px`,
            transition: "all 0.2s linear",
            ...placeholderStyle,
          }}
        >
          {typeof placeholder === "string" ? (
            <GothamRegular
              style={{
                color: jackColors.greyBB,
              }}
            >
              {placeholder}
            </GothamRegular>
          ) : (
            placeholder
          )}
        </div>
      )}
      {tooltip && (
        <div
          style={{
            position: "absolute",
            top: 1.5,
            right: 0,
          }}
        >
          <CustomTooltip
            text={tooltip}
            style={{
              backgroundColor: colors.neutral100,
              boxShadow:
                "0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16)",
              opacity: 1,
              maxWidth: "151px",
              color: colors.neutral900,
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "left",
              ...tooltipStyle,
            }}
            popperClassName="white-arrow"
          >
            <JackIcons
              name="info-outline"
              fill={colors.neutral700}
              style={{ width: "12px", height: "12px" }}
            />
          </CustomTooltip>
        </div>
      )}
      {!woLabel && (
        <GothamRegular
          className="font12"
          style={{
            color: isError ? jackColors.redE7 : jackColors.black34,
            marginBottom: 8,
            ...labelStyle,
          }}
        >
          {label}
          {required && <span style={{ color: jackColors.redE7 }}>*</span>}
          {isOptional && !required && (
            <TextInlineRegular
              style={{
                color: colors.neutral700,
                fontSize: "10px",
                marginLeft: "4px",
              }}
            >
              ({isBahasa ? "Opsional" : "Optional"})
            </TextInlineRegular>
          )}
        </GothamRegular>
      )}
      {withLetterCounter && maxLength && (
        <GothamRegular
          woFontColor
          className="font10"
          style={{
            color: jackColors.neutral900,
            position: "absolute",
            bottom: "8px",
            right: "8px",
            ...letterCounterStyle,
          }}
        >
          {value ? value.length : 0}/{maxLength}
        </GothamRegular>
      )}
      <div
        style={{
          backgroundColor: isTransparent ? "transparent" : "white",
          borderRadius: 4,
        }}
      >
        <TextField
          {...props}
          name={name}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          disabled={!!disabled}
          inputProps={{ maxLength: maxLength ?? "none" }}
          InputProps={{
            disableUnderline: disableUnderline,
            startAdornment: !!icon && (
              <div
                className="d-flex align-items-center mr-1"
                ref={(e) => {
                  if (!e) return;
                  setClientWidth((prev) => {
                    if (prev == e.clientWidth) return prev;
                    return e.clientWidth;
                  });
                }}
              >
                {icon}
              </div>
            ),
            endAdornment: !!iconRight && (
              <div className="d-flex align-items-center ml-1">{iconRight}</div>
            ),
            style: {
              fontFamily: "GothamBook",
              fontSize: 14,
              marginTop: -4,
              ...textFieldInputStyle,
            },
            readOnly,
          }}
          variant="standard"
          style={{
            height: 40,
            borderRadius: 4,
            width: "100%",
            padding: 12,
            paddingTop: 10,
            paddingBottom: 10,
            border: `1px solid ${
              isError ? jackColors.redE7 : jackColors.black34
            }`,
            transition: "all 0.3s ease",
            backgroundColor: disabled ? jackColors.greyF1 : "",
            ...textFieldStyle,
          }}
          className={textFieldClassName}
        />
      </div>
      {/* {Boolean(fixedHelperText) && (
        <FixedHelperText>{fixedHelperText}</FixedHelperText>
      )} */}
      <TextFieldJackHelp
        isError={isError}
        msg={helperText}
        helperTextStyle={helperTextStyle}
      />
    </div>
  );
};

export const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "hsl(0, 0, 60%)", // Change the text color
      backgroundColor: jackColors.greyF1,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E6E6E8", // Change the border color here
    },
  },
})(
  ({
    name,
    onBlur,
    onFocus,
    disabled,
    inputProps,
    InputProps,
    maxLength,
    disableUnderline,
    icon,
    style,
    textFieldClassName,
    ...props
  }: AtomicTextFieldJackProps & {
    InputProps:
      | Partial<InputProps>
      | Partial<FilledInputProps>
      | Partial<OutlinedInputProps>
      | undefined;
    inputProps: InputBaseComponentProps | undefined;
    textFieldClassName: string;
    error?: boolean | undefined;
  }) => {
    const { placeholder: placeholderProps, ...rest } = props;
    const getTextFromCustomComponent = (node: ReactNode) => {
      if (isValidElement(node)) {
        return node.props.children;
      }
      return null;
    };

    const extractedText = getTextFromCustomComponent(placeholderProps);
    const placeholder =
      typeof placeholderProps === "string" ? placeholderProps : extractedText;

    return (
      <TextField
        {...rest}
        placeholder={placeholder}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={!!disabled}
        inputProps={inputProps}
        InputProps={InputProps}
        variant="standard"
        style={style}
        className={textFieldClassName}
      />
    );
  }
);

export const CustomAtomicTextFieldJack = ({
  icon: iconProps,
  iconRight: iconRightProps,
  placeholder = "",
  helperText,
  label,
  name,
  style,
  textFieldStyle,
  woLabel,
  noBorder,
  labelStyle,
  disabled,
  required, // di culik biar ga ke kirim ke <TextField
  isAutoFill,
  textFieldInputStyle,
  placeholderStyle,
  onBlur: onBlurProps = (e) => {},
  onFocus: onFocusProps = (e) => {},
  preventTab,
  isTransparent,
  helperTextStyle,
  withLetterCounter,
  maxLength,
  letterCounterStyle,
  tooltip,
  tooltipStyle,
  isOptional,
  disableUnderline = true,
  idContainer,
  isError = false,
  hasValue = false,
  value,
  error,
  customClassname = "", // this props is meant to override built in mui's textfield style
  readOnly = false,
  ...props
}: AtomicTextFieldJackProps) => {
  const [icon, iconRight] = jackIconsTo20([iconProps, iconRightProps]);

  const [isFocused, setIsFocused] = useState(false);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isAutoFill) return setIsReady(true);
    const func = () => setIsReady(true);
    document.addEventListener("click", func);
    return () => {
      document.removeEventListener("click", func);
    };
  }, []);

  const isPlaceHolderPushed = isFocused && !hasValue;

  const [clientWidth, setClientWidth] = useState(0);

  useEffect(() => {
    if (!!icon) return;
    setClientWidth(0);
  }, [!!icon]);

  const left = clientWidth + 4 + 12 + (isPlaceHolderPushed ? 4 : 0);
  const textFieldClassNameDecider = () => {
    // if you need to add a custom style (overriding mui's builtin style properties) please provide two global css classname on input.css, first one is the "custom_classname" for the default style and the other is "{custom_classname}-focus" to set the textfield style on focus.
    // you also need to set border: "none" to textFieldStyle props in order for this to work
    if (isFocused && !!customClassname) return `${customClassname}-focus`;
    if (!!customClassname) return customClassname;
    if (isFocused || isError) return "";
    if (noBorder) return "";
    if (disabled) return "textfield-disabled";
    return "textfield";
  };

  const textFieldClassName = textFieldClassNameDecider();

  const handleOnFocus = (value: any) => {
    setIsFocused(true);
    onFocusProps(value);
  };

  const handleOnBlur = (value: any) => {
    setIsFocused(false);
    onBlurProps(value);
  };

  return (
    <div
      style={{
        flexDirection: "column",
        position: "relative",
        marginBottom: 32,
        ...style,
      }}
      className="d-flex"
      id={idContainer}
    >
      {!hasValue && isReady && (
        <div
          style={{
            position: "absolute",
            top: label ? 29 + 6 : 19,
            left: -2,
            translate: `${left}px 0px`,
            transition: "all 0.2s linear",
            ...placeholderStyle,
          }}
        >
          {typeof placeholder === "string" ? (
            <GothamRegular
              style={{
                color: jackColors.greyBB,
              }}
            >
              {placeholder}
            </GothamRegular>
          ) : (
            placeholder
          )}
        </div>
      )}
      {tooltip && (
        <div
          style={{
            position: "absolute",
            top: 1.5,
            right: 0,
          }}
        >
          <CustomTooltip
            text={tooltip}
            style={{
              backgroundColor: colors.neutral100,
              boxShadow:
                "0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16)",
              opacity: 1,
              maxWidth: "151px",
              color: colors.neutral900,
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "left",
              ...tooltipStyle,
            }}
            popperClassName="white-arrow"
          >
            <JackIcons
              name="info-outline"
              fill={colors.neutral700}
              style={{ width: "12px", height: "12px" }}
            />
          </CustomTooltip>
        </div>
      )}
      {!woLabel && (
        <GothamRegular
          className="font12"
          style={{
            color: isError ? jackColors.redE7 : jackColors.black34,
            marginBottom: 8,
            ...labelStyle,
          }}
        >
          {label}
          {required && <span style={{ color: jackColors.redE7 }}>*</span>}
          {isOptional && !required && (
            <TextInlineRegular
              style={{
                color: colors.neutral700,
                fontSize: "10px",
                marginLeft: "4px",
              }}
            >
              (optional)
            </TextInlineRegular>
          )}
        </GothamRegular>
      )}
      {withLetterCounter && maxLength && (
        <GothamRegular
          woFontColor
          className="font10"
          style={{
            color: jackColors.neutral900,
            position: "absolute",
            bottom: "8px",
            right: "8px",
            ...letterCounterStyle,
          }}
        >
          {value ? value.length : 0}/{maxLength}
        </GothamRegular>
      )}
      <div
        style={{
          backgroundColor: isTransparent ? "transparent" : "white",
          borderRadius: 4,
        }}
      >
        <CustomTextField
          {...props}
          name={name}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          disabled={!!disabled}
          inputProps={{ maxLength: maxLength ?? "none" }}
          InputProps={{
            disableUnderline: disableUnderline,
            startAdornment: !!icon && (
              <div
                className="d-flex align-items-center mr-1"
                ref={(e) => {
                  if (!e) return;
                  setClientWidth((prev) => {
                    if (prev == e.clientWidth) return prev;
                    return e.clientWidth;
                  });
                }}
              >
                {icon}
              </div>
            ),
            endAdornment: !!iconRight && (
              <div className="d-flex align-items-center ml-1">{iconRight}</div>
            ),
            style: {
              fontFamily: "GothamBook",
              fontSize: 14,
              marginTop: -4,
              ...textFieldInputStyle,
            },
            readOnly,
          }}
          variant="standard"
          style={{
            height: 40,
            borderRadius: 4,
            width: "100%",
            padding: 12,
            paddingTop: 10,
            paddingBottom: 10,
            border: `1px solid ${
              disabled
                ? "#E6E6E8"
                : isError
                ? jackColors.redE7
                : jackColors.black34
            }`,
            transition: "all 0.3s ease",
            backgroundColor: disabled ? jackColors.greyF1 : "",
            ...textFieldStyle,
          }}
          className={textFieldClassName}
        />
      </div>
      {/* {Boolean(fixedHelperText) && (
        <FixedHelperText>{fixedHelperText}</FixedHelperText>
      )} */}
      <TextFieldJackHelp
        isError={isError}
        msg={helperText}
        helperTextStyle={helperTextStyle}
      />
    </div>
  );
};

const helperTextDecider = (
  helperText: string | undefined,
  error: boolean | string | undefined,
  errorMsg:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined,
  woFormat?: boolean | undefined
): string | undefined => {
  if (typeof error === "boolean") return "";
  if (typeof error === "string" && !!error) return error;
  if (errorMsg)
    return woFormat ? String(errorMsg) : sentenceCase(String(errorMsg));
  return helperText;
};

export const TextFieldJack = ({
  name,
  label: labelProps = "",
  required = true,
  error = "",
  helperText = "",
  autoFocus = false,
  onBlur = () => {},
  onFocus = () => {},
  useFormObj,
  type,
  woFormatError = false,
  ...props
}: AtomicTextFieldJackProps) => {
  const {
    watch,
    register,
    formState: { errors },
    clearErrors,
    setValue,
  } = useFormObj as OverrideReturnType;
  const value = watch(name);
  const hasValue = !!value;
  const errorMsg = error || errors?.[name]?.message;

  const isError = !!errorMsg || !!errors?.[name];

  const label = labelProps || titleCase(name);

  const autoFocusProps = useAutofocus({ name, autoFocus, register, required });

  useEffect(() => {
    if (!isError) return;
    if (hasValue) return clearErrors(name);
  }, [value]);

  const isNumber = type == "number";

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!isNumber) return;
    if (isFirstRender) return setIsFirstRender(false);
    setValue(name || "", value?.replace(/\D/g, ""));
  }, [value]);

  return (
    <AtomicTextFieldJack
      {...autoFocusProps}
      {...props}
      required={required}
      useFormObj={useFormObj}
      name={name}
      value={watch(name) || ""}
      helperText={helperTextDecider(helperText, error, errorMsg, woFormatError)}
      hasValue={hasValue}
      isError={isError}
      label={label}
      onBlur={onBlur}
      onFocus={onFocus}
      type={isNumber ? "text" : type}
    />
  );
};

const toNumber = (str: string) => {
  const pattern = /\d+/g;
  const matches = (str || "").match(pattern);
  let longestDigits = "";

  if (matches) {
    matches.forEach((match) => {
      if (match.length > longestDigits.length) {
        longestDigits = match;
      }
    });
  }

  return longestDigits;
};

const formatToCurr = (numberRaw: string, isCurrency: boolean) => {
  const number = Math.abs(Number(unformatCurrency(numberRaw)));
  const is0 = number == 0 || !number;

  const curr = formatCurrency(is0 ? "" : number);

  const valueDecider = () => {
    if (!isCurrency) return toNumber(numberRaw);
    if (!curr) return "";
    return curr;
  };

  return valueDecider();
};

export const useNumberOnChangeProps = (props: NumberFieldJackProps) => {
  const { name, useFormObj, isCurrency = true, maxLength } = props;

  const { setValue, watch } = useFormObj;
  const value = watch(name);

  const onChange = (textRaw: string) => {
    const text = maxLength ? String(textRaw).slice(0, maxLength) : textRaw;
    const value = formatToCurr(text, isCurrency); // Assuming formatToCurr is a function defined elsewhere
    setValue(name, value);
  };

  useEffect(() => {
    onChange(value);
  }, [value]); // Added value as a dependency to useEffect

  return {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      onChange(e?.target?.value),
  };
};

export const NumberFieldJack = (props: NumberFieldJackProps) => {
  return <TextFieldJack {...props} {...useNumberOnChangeProps(props)} />;
};

export const TextFieldJackState = (props: TextFieldJackStateProps) => {
  const {
    error,
    helperText = "",
    value,
    onChange = () => {},
    autoFocus,
    onBlur = () => {},
    onFocus = () => {},
    ...defaultProps
  } = props;
  const hasValue = !!value;
  const errorMsg = error;
  const isError = !!errorMsg;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  return (
    <AtomicTextFieldJack
      helperText={helperTextDecider(helperText, error, errorMsg)}
      isError={isError}
      inputRef={inputRef}
      hasValue={hasValue}
      onChange={(e) => onChange(e?.target?.value)}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      {...defaultProps}
    />
  );
};

export const CustomTextFieldJack = ({
  name,
  label: labelProps = "",
  required = true,
  error = "",
  helperText = "",
  autoFocus = false,
  onBlur = () => {},
  onFocus = () => {},
  useFormObj,
  type,
  woFormatError = false,
  ...props
}: AtomicTextFieldJackProps) => {
  const {
    watch,
    register,
    formState: { errors },
    clearErrors,
    setValue,
  } = useFormObj as OverrideReturnType;
  const value = watch(name);
  const hasValue = !!value;
  const errorMsg = error || errors?.[name]?.message;

  const isError = !!errorMsg || !!errors?.[name];

  const label = labelProps || titleCase(name);

  const autoFocusProps = useAutofocus({ name, autoFocus, register, required });

  useEffect(() => {
    if (!isError) return;
    if (hasValue) return clearErrors(name);
  }, [value]);

  const isNumber = type == "number";

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!isNumber) return;
    if (isFirstRender) return setIsFirstRender(false);
    setValue(name || "", value?.replace(/\D/g, ""));
  }, [value]);

  return (
    <CustomAtomicTextFieldJack
      {...autoFocusProps}
      {...props}
      required={required}
      useFormObj={useFormObj}
      name={name}
      value={watch(name) || ""}
      helperText={helperTextDecider(helperText, error, errorMsg, woFormatError)}
      hasValue={hasValue}
      isError={isError}
      label={label}
      onBlur={onBlur}
      onFocus={onFocus}
      type={isNumber ? "text" : type}
    />
  );
};
