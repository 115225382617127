import { isEmpty, upperCase } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { buttonContainerStyle } from "..";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Avatar } from "../../../../components/Avatar";
import { Banner } from "../../../../components/Banner";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { StatusLabelJack } from "../../../../components/StatusLabel";
import { TableJackSimple } from "../../../../components/Table/parent";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
  TextInlineBold,
} from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { getUserRole, useGetAuth } from "../../../../contexts/AuthContext";
import { WrapperModalButtons } from "../../../../pageComponents/homeComponents/topup/components";
import { InvoicePaymentTableJack } from "../../../../pageComponents/invoiceComponents/tableJack";
import { TableRowDots } from "../../../../pageComponents/tableComponents";
import { DetailsModal } from "../../crossBorderSingleModal/components";
import { CompanyBalanceAndTopUp } from "../../crossBorderSingleModal/ReleasePaymentModal";
import { DescriptionAccordion } from "../../invoicePayment/parent";
import {
  CancelledBox,
  cancelReimbursementReceipt,
  isLastToCancelCheck,
  reimbursementStatus,
  releaserSummaryDetail,
  supportingdocHook,
  totalAmountCalc,
  useApproveRejectReceipt,
} from "../logics";
import { DetailModalUpdateTracker } from "./updateTrackers";
import { ActionButtonSupportingDoc } from "../../invoicePayment/supportingDocs";
import CategorySelection from "../../../../pageComponents/categoryComponents/general/components/CategorySelection";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { useMutation } from "../../../../tools/api";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import {
  batchCategoryChangePayloadFormatter,
  canUpdateCategoryDecider,
} from "../../../../pageComponents/categoryComponents/general/helpers";
import { ScheduledPaymentOptions } from "../../../../pageComponents/scheduledPaymentComponents/paymentOptions";
import { idrStringFormatter } from "../../../../pageComponents/invoiceComponents/data/formatter";
import { useModalHook } from "../../../../components/Modals";
import { CancelReceiptModal } from ".";
import { ReimbursementDetails } from "../../../../pageComponents/reimbursementComponents/createPage/submitPage";
import { TooltipError } from "../../../../pageComponents/PayrollCreateEnhancement/create/tooltip";
import { InvoiceFormSummary } from "../../../../pageComponents/invoiceComponents/createPage/modals/formComponents/component";
import { createReimbursementChildSummary } from "../../invoicePayment/hooks/logics";
import { useTranslation } from "react-i18next";

export const ReimbursementTableView = ({ data, activeId, onClick, toggle }) => {
  return (
    <div style={{ padding: 16 }}>
      <ButtonJack
        className="mb-4"
        type="outline"
        onClick={toggle}
        leftIcon={<JackIcons name="arrowhead_left_outline" fill="#343434" />}
      >
        Return to batch details
      </ButtonJack>
      <InvoicePaymentTableJack
        bodyArr={data}
        tableType="rmbdetail"
        defaultSelectedIds={[]}
        activeRowId={activeId}
        onClick={onClick}
      />
    </div>
  );
};

export const ReimbursementReceiptDetail = ({
  detailId,
  data,
  batchId,
  hideButtons = true,
  refetch,
  workflowObjs,
  isApprover,
  setAllData,
  isDrafter,
  dataState,
  dataParent,
  allData,
  listener = () => {},
}) => {
  const [category, setCategory] = useState(null);

  const detail = useMemo(() => {
    if (!detailId) return data?.[0] ?? {};

    return data?.filter(({ id }) => id == detailId).pop();
  }, [detailId, data]);

  const { categories } = useConstants();

  const {
    merchant,
    amountIDR,
    memo_text,
    purchasedDateShort,
    attachment_data,
    category_id,
  } = detail || {};

  useEffect(() => {
    const category = (categories ?? []).find(({ id }) => id === category_id);
    setCategory(category);
  }, [category_id, categories]);

  const isLasttoCancel = isLastToCancelCheck(data, detail?.id);

  const { cancelComponent } = cancelReimbursementReceipt({
    receipt: detail,
    refetch: refetch,
    batchId,
    isLasttoCancel,
  });

  const { hide, title, onClickSupportingDoc } =
    supportingdocHook(attachment_data);
  const { successSnackBar } = ToasterHook();

  const { mutation: updateCategory } = useMutation({
    url: `/reimbursement_receipts/${detail?.id}`,
    afterSuccess: (_, payload) => {
      const categoryId = payload?.category_id;

      const batchPayload = batchCategoryChangePayloadFormatter({
        dataParent,
        categories,
        categoryId,
        data: allData,
        dataDetail: detail,
      });

      listener(batchPayload);

      successSnackBar({ msg: "Transaction category has been updated." });

      setAllData((prev) => {
        const reimbursementChild = prev.reimbursement_child.find(
          ({ id }) => id === detailId
        );
        reimbursementChild.category_id = categoryId;
        return prev;
      });
    },
  });

  const canUpdateCategory = canUpdateCategoryDecider({
    deps: { isDrafter, isApprover, state: dataState },
  });

  const summary = createReimbursementChildSummary(detail);

  return (
    <div
      style={{ padding: 16, display: "flex", flexDirection: "column", gap: 32 }}
    >
      <div className="w-100 d-flex-column" style={{ textAlign: "center" }}>
        <GothamRegular className="mb-1">{merchant}</GothamRegular>
        <GothamBold style={{ fontSize: 24, marginBottom: 0 }}>
          {amountIDR}
        </GothamBold>
      </div>
      {!hideButtons && (
        <div className="w-100 d-flex-column" style={{ textAlign: "center" }}>
          {cancelComponent()}
        </div>
      )}
      <DetailModalUpdateTracker
        transaction={data}
        detailId={detailId}
        workflowObjs={workflowObjs}
      />
      <DetailsModal
        array={[
          { title: "Purchase date", value: purchasedDateShort },
          { title: "Merchant", value: merchant },
        ]}
      />
      {canUpdateCategory && (
        <CategorySelection
          isForTrxDetails
          showAllCategories={isApprover}
          selectedCategory={category ?? {}}
          style={{ marginBottom: "0px" }}
          onSelect={(category) => {
            setCategory(category);
            updateCategory({ category_id: category?.id });
          }}
        />
      )}
      {/* partial takeout */}
      {/* <InvoiceFormSummary data={summary} isReimbursement /> */}
      <DescriptionAccordion description={memo_text} />
      {!hide ? (
        <ActionButtonSupportingDoc
          iconName="layers_outline"
          title={title}
          text="See all supporting documents"
          onClick={onClickSupportingDoc}
        />
      ) : null}
    </div>
  );
};
export const ReviewModalHeader = ({ batchName }) => {
  const { t } = useTranslation("reimbursement/reimbursement");
  return (
    <div>
      <GothamBold style={{ fontSize: 24, marginBottom: 8 }}>
        {t("Review Expense")}
      </GothamBold>
      <GothamRegular style={{ marginBottom: 16 }}>
        {/* You have to <TextInlineBold>approve</TextInlineBold>,{" "}
        <TextInlineBold>reject</TextInlineBold>, or{" "}
        <TextInlineBold>flag</TextInlineBold> each expense before continuing. */}
        {t("You have to")} <TextInlineBold>{t("approve")}</TextInlineBold>{" "}
        {t("or")} <TextInlineBold>{t("reject")}</TextInlineBold>{" "}
        {t("each expense before continuing.")}
      </GothamRegular>
      <div
        className="d-flex "
        style={{
          border: "1px solid #E6E6E8",
          borderRadius: 4,
          height: 66,
          padding: 16,
        }}
      >
        <Avatar
          mainIcon={
            <JackIcons
              name="reimbursement"
              style={{ height: 19, width: "auto" }}
            />
          }
          size="medium"
        />
        <div
          style={{ marginLeft: 8 }}
          className="d-flex-column justify-content-between"
        >
          <GothamMedium
            className="font12"
            style={{ color: jackColors.black34 }}
          >
            Reimbursement
          </GothamMedium>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.grey90 }}
          >
            {batchName}
          </GothamRegular>
        </div>
      </div>
    </div>
  );
};

const calcwidth = (width, totalwidth = 552) => `${width / totalwidth}%`;
const headerArr = [
  { label: "", width: calcwidth(36), textAlign: "left" },
  { label: "Merchant", width: calcwidth(266), textAlign: "left" },
  { label: "Amount", width: calcwidth(110), textAlign: "right" },
  { label: "Require Action", width: calcwidth(140), textAlign: "center" },
  // { label: "", width: calcwidth(48) },
];
//because flag colum is hidden so +24 for review status colum and +20 for amount column
export const ReviewTable = ({
  activeRowId,
  bodyArr = [],
  onClick = () => {},
  setState,
  getOption,
}) => {
  const tbodyRef = useRef(null);
  const { users } = useConstants();
  const { t } = useTranslation("reimbursement/reimbursement");
  const getUserNameById = (userId) =>
    users?.find(({ id }) => id == userId)?.name;

  const formattedData = bodyArr?.map((data) => {
    return { ...data, option: getOption(data?.id) };
  });
  return (
    <TableJackSimple
      tbodyRef={tbodyRef}
      headerTable={headerArr}
      thStyle={{
        position: "sticky",
        top: -8,
        height: 40,
        backgroundColor: "#FFFFFF",
        borderRadius: 0,
      }}
      style={{ overflowY: "auto", height: "100%", zIndex: 10 }}
      bodyComponent={formattedData.map((item, index) => {
        const {
          id,
          is_duplicate,
          check_is_complete,
          merchant,
          amountIDR,
          sttus: statusRaw,
          state,
          rejected_by_id,
          total_amount,
          user_id,
          option,
        } = item || {};

        const disableButton = state == "cancelled";
        const optionState = option?.state;
        const statusDecider = () => {
          if (disableButton) return state;
          if (optionState == "approved") return "Marked as approved";
          if (optionState == "rejected") return "Marked as rejected";

          return "Pending Review";
        };
        const status = statusDecider();
        const number = index + 1;
        const isActive = id == activeRowId;
        const defaultTdTextStyle = () => {
          return {
            //width: "calc(100% - 16px)",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          };
        };

        const { buttons } = useApproveRejectReceipt({
          receipt: item,
          setState,
          defaultValue: optionState,
          disableButton,
        });

        const isCancelled = state == "cancelled";
        const isRejected = isCancelled && Boolean(rejected_by_id);
        const showDescription = isCancelled || isRejected;
        const cancelDesc = isRejected
          ? `${t("(Rejected by")} ${getUserNameById(rejected_by_id)})`
          : `${t("(Canceled by")} ${getUserNameById(user_id)})`;

        const createTooltipContent = (item) => {
          const {
            is_duplicate,
            edited_by_user_id,
            subtotal_match_with_items_total_price: match,
          } = item;
          let content = [];
          if (is_duplicate) content.push(t("Duplicated receipt"));
          // if (edited_by_user_id) content.push("Summary changes");
          // if (!match) content.push("Missmatch subtotal");

          return content;
        };

        const content = createTooltipContent(item);
        return (
          <TableRowDots
            style={{
              height: 64,
              backgroundColor: isActive && colors.greye6,
            }}
            trOnClick={() => {
              onClick && onClick(item);
            }}
            key={number}
            onClick={(arrayItem) => onClick({ ...item, arrayItem })}
            woTd
            className="tdrow-invoice"
          >
            <td valign="middle" align="left" style={{ padding: "0px 8px" }}>
              <TableTooltip content={content} />
            </td>
            <td valign="middle" align="left" style={{ padding: "0px 8px" }}>
              <div className="d-flex-column justify-content-between">
                <GothamRegular style={defaultTdTextStyle(is_duplicate)}>
                  {merchant}
                </GothamRegular>
                {showDescription && (
                  <GothamRegular
                    className="font12"
                    style={{ color: "#909098" }}
                  >
                    {cancelDesc}
                  </GothamRegular>
                )}
              </div>
            </td>
            {/* <td style={{ padding: "0px 0px" }} align="left">
              <StatusLabelJack
                isOutline={false}
                status={reimbursementStatus(status, rejected_by_id, true)}
                woTextFormatter={true}
                customStatusColors={customColor}
              />
            </td> */}
            <td style={{ padding: "0px 8px" }}>
              <GothamRegular
                style={{
                  textAlign: "right",
                }}
              >
                {idrStringFormatter(total_amount)}
              </GothamRegular>
            </td>
            <td style={{ padding: "0px 8px" }} align="middle">
              {buttons()}
            </td>
          </TableRowDots>
        );
      })}
    />
  );
};

const TableTooltip = ({ content }) => {
  if (isEmpty(content)) return null;
  const { t } = useTranslation("reimbursement/reimbursement");

  return (
    <div className="d-flex" style={{ gap: 4, alignItems: "center" }}>
      <TooltipError
        placement="right"
        desc={
          <div style={{}}>
            <GothamRegular className="font12" style={{ color: "#6c6c71" }}>
              {t("This expense might need your extra attention as it contains")}
              :
            </GothamRegular>
            <div>
              {content?.map((text) => (
                <GothamMedium style={{ color: "#6c6c71" }} className="font12">
                  • {text}
                </GothamMedium>
              ))}
            </div>
          </div>
        }
      >
        <div style={{ width: 20, height: 20 }}>
          <JackIcons
            name="info_outline"
            style={{ width: 20, height: 20 }}
            fill="#909098"
          />
        </div>
      </TooltipError>
    </div>
  );
};

const customColor = [
  {
    statuses: ["Marked as approved"],
    color: "#9BDEB8",
  },
  {
    statuses: ["approved"],
    color: "#06ac4e",
  },
  {
    statuses: ["Marked as rejected"],
    color: "#FEACAA",
  },
  {
    statuses: ["Pending Review"],
    color: colors.neutral600,
  },
  {
    statuses: [
      "cancelled",
      "canceled",
      "Marked as rejected",
      "Rejected",
      "rejected",
    ],
    color: colors.redDC,
  },
];

const headerTable = [
  { label: "Initiated by", width: calcwidth(88, 432), textAlign: "left" },
  { label: "Expense", width: calcwidth(121, 432), textAlign: "left" },
  { label: "Amount", width: calcwidth(121, 432), textAlign: "right" },
  { label: "Review Status", width: calcwidth(102, 432), textAlign: "left" },
];

const BannerDecider = ({ isPay, isFullReject, localRecipientData }) => {
  const { t } = useTranslation("reimbursement/reimbursement");
  if (isFullReject)
    return (
      <Banner
        msg={t(
          "This reimbursement won’t go to the next approval flow as all expenses are rejected."
        )}
        type="warning"
      />
    );

  if (isPay) {
    const { account_number, registered_name, bank_name } =
      localRecipientData || {};
    const bankInfo = `${registered_name} - ${upperCase(
      bank_name
    )} (${account_number})`;
    return (
      <Banner
        msg={`${t("The funds will be transferred to")} ${bankInfo}`}
        type="information"
      />
    );
  }

  return (
    <Banner
      msg={t("The approved expense(s) will go to the next approval flow.")}
      type="information"
    />
  );
};

export const SubmitSummaryContent = ({
  data: bodyArr,
  toggle,
  onClick: onClickContinue,
  isFullReject = false,
  isPay = false,
  localRecipient,
  fee = 0,
  onClickTopUp,
  schedulePaymentState,
  summaryData,
}) => {
  const { user, refetchUser, userLoading } = useGetAuth();
  const { t } = useTranslation("reimbursement/reimbursement");
  const { mainBalance } = user;
  const { balance } = mainBalance;
  const companyBalanceIdr = `IDR ${formatCurrencyNoDecimal(balance)}`;
  const payerMode = isPay && !isFullReject;

  const { totalAmountApproved, totalFee } = totalAmountCalc(bodyArr, fee);
  const totalPaymentRaw = Number(totalAmountApproved) + Number(totalFee);

  const remainingBalance = balance - totalPaymentRaw;
  const isSufficient = remainingBalance >= 0;

  const [payment, setPayment] = schedulePaymentState;
  const { type, payload: schedulePayload } = payment;
  const isPayloadEmpty = isEmpty(schedulePayload);
  const isPayImmediately = type == "pay_immediately";

  // const disableButton =
  //   isPayloadEmpty || isPayImmediately ? !isSufficient : false;
  const disableButton = false;

  return (
    <WrapperModalButtons
      childrenButton={
        <>
          {/* {payerMode && (
            <CompanyBalanceAndTopUp
              onClickTopUp={onClickTopUp}
              companyBalanceIdr={companyBalanceIdr}
              refresh={refetchUser}
              loading={userLoading}
            />
          )} */}
          <BackConfirmButtons
            onClickLeft={toggle}
            onClickRight={onClickContinue}
            isPay={payerMode}
            isSmall={true}
            disableButton={disableButton}
            customRightText={t("Confirm")}
          />
        </>
      }
      containerStyle={{
        ...buttonContainerStyle,
        width: 500,
      }}
      customWidth={500}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
          height: `calc(100vh - ${payerMode ? "144px" : "80px"} - 16px)`,
          overflow: "scroll",
        }}
      >
        <GothamMedium className="font24">{t("Confirm Action")}</GothamMedium>
        <BannerDecider
          isFullReject={isFullReject}
          isPay={isPay}
          localRecipientData={localRecipient}
        />
        <ConfirmSubmitTable bodyArr={bodyArr} headerTable={headerTable} />
      </div>
    </WrapperModalButtons>
  );
};

const ConfirmSubmitTable = ({ bodyArr: array, headerTable }) => {
  const canShowMore = array?.length > 3;
  const { isOpen, toggle } = useModalHook(false);
  const { t } = useTranslation("reimbursement/reimbursement");
  const bodyArr = isOpen ? array : array?.slice(0, 3);
  const { users } = useConstants();
  const getUserNameById = (userId) =>
    users?.find(({ id }) => id == userId)?.name;
  return (
    <div
      style={{ borderRadius: 4, border: "1px solid #e6e6e8" }}
      className={isOpen ? "smoothIn" : ""}
    >
      <div
        style={{
          height: 32,
          width: "100%",
          padding: "0px 8px",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #e6e6e8",
            width: "100%",
            height: 32,
          }}
          className="d-flex align-items-center"
        >
          <GothamMedium className="font12">
            {t("Reviewed Expense")}
          </GothamMedium>
        </div>
      </div>

      <TableJackSimple
        isDumb
        // style={{ backgroundColor: "blue", border: "" }}
        tableStyle={{
          border: "",
          borderRadius: 0,
          borderBottom: "1px solid #e6e6e8",
        }}
        trStyle={{ height: 40 }}
        headerTable={headerTable}
        bodyComponent={bodyArr.map((item, index) => {
          const {
            id,
            merchant,
            amountIDR,
            state,
            rejected_by_id,
            user_id,
            action_by_user,
          } = item || {};

          const number = index + 1;
          const defaultTdTextStyle = () => {
            return {
              //width: "calc(100% - 16px)",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            };
          };
          const stateDecider = (state) => {
            if (state == "rejected") return "Rejected";
            if (state == "approved") return "Approved";
            if (state == "cancelled" && Boolean(rejected_by_id))
              return "Rejected";
            return state;
          };
          const receiptState = stateDecider(state);

          const initiatedByDecider = (receiptState) => {
            if (receiptState == "cancelled") return user_id;
            if (receiptState == "Rejected")
              return rejected_by_id || action_by_user;
            return action_by_user;
          };

          return (
            <tr
              style={{
                height: 40,
              }}
              key={number}
              // className="tdrow-invoice"
            >
              <td
                valign="middle"
                align="left"
                style={{ padding: "0px 8px", height: 40 }}
              >
                <GothamRegular
                  style={defaultTdTextStyle(false)}
                  className="font12"
                >
                  {getUserNameById(initiatedByDecider(receiptState))}
                </GothamRegular>
              </td>
              <td
                valign="middle"
                align="left"
                style={{ padding: "0px 8px", height: 40 }}
              >
                <GothamRegular
                  style={defaultTdTextStyle(false)}
                  className="font12"
                >
                  {merchant}
                </GothamRegular>
              </td>
              <td style={{ padding: "0px 8px", height: 40 }}>
                <GothamRegular
                  style={{
                    textAlign: "right",
                  }}
                  className="font12"
                >
                  {amountIDR}
                </GothamRegular>
              </td>
              <td style={{ padding: "0px 0px", height: 40 }} align="left">
                <StatusLabelJack
                  isOutline={false}
                  status={receiptState}
                  woTextFormatter={true}
                  customStatusColors={customColor}
                />
              </td>
            </tr>
          );
        })}
      />

      {canShowMore && (
        <div
          className="w-100"
          style={{
            height: 32,
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={toggle}
        >
          <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
            <GothamMedium className="font12">
              {isOpen ? t("See Less") : t("Show More")}
            </GothamMedium>
            <JackIcons
              fill="#343434"
              name={isOpen ? "chevron_up" : "chevron_down"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const BackConfirmButtons = ({
  onClickLeft,
  onClickRight,
  style,
  isPay = false,
  disableButton = false,
  customRightText,
}) => {
  const buttonText = customRightText;
  const { t } = useTranslation("reimbursement/reimbursement");
  return (
    <div
      className="d-flex justify-content-end w-100"
      style={{ gap: 12, marginTop: 16 }}
    >
      <ButtonJack
        type="outline"
        onClick={() => onClickLeft && onClickLeft()}
        leftIcon={<JackIcons name="arrow-back" fill={jackColors.neutral900} />}
      >
        {t("Back")}
      </ButtonJack>
      <ButtonJack
        disabled={isPay && disableButton}
        // leftIcon={
        //   isPay && (
        //     <JackIcons name="checkmark_circle_2" fill={jackColors.neutral900} />
        //   )
        // }
        rightIcon={
          // !isPay && (
          <JackIcons name="arrow-forward" fill={jackColors.neutral900} />
          // )
        }
        onClick={() => onClickRight && onClickRight()}
      >
        {buttonText}
      </ButtonJack>
    </div>
  );
};

export const ApproverSummary = ({ data, detail, getOption, onClick }) => {
  const { state, rejection_note, action_by_user } = getOption(detail.id) || {};
  const {
    merchant,
    id,
    amountIDR,
    activity_state,
    rejected_by_id,
    user_id,
    rejection_note: reject_note,
  } = detail;

  const isApprove = state == "approved";
  const isCancelled = activity_state == "cancelled";
  const isRejected = isCancelled && Boolean(rejected_by_id);
  const isRejectedByCurrentUser = state == "rejected";
  const { isOpen, toggle } = useModalHook();
  const canEdit = !isCancelled;

  if (isApprove)
    return (
      <ApprovedByInfo
        userId={action_by_user}
        canUndo={true}
        handleUndo={() => onClick("undo", { id })}
      />
    );

  if (isRejectedByCurrentUser || isRejected)
    return (
      <>
        <RejectedByInfo
          rejection_note={rejection_note || reject_note}
          user_id={action_by_user || rejected_by_id}
          onClickEdit={toggle}
          canEdit={canEdit}
        />
        <CancelReceiptModal
          merchant={merchant}
          amount={amountIDR}
          isOpen={isOpen}
          toggle={toggle}
          onSubmit={({ rejection_note }) => {
            const payload = { id, rejection_note };
            onClick("change-reject-note", payload);
            toggle();
          }}
          isReject
          defaultValue={rejection_note || reject_note}
        />
      </>
    );

  if (isCancelled)
    return <CancelledBox rejection_note={reject_note} user_id={user_id} />;
  return null;
};

export const ApprovedByInfo = ({
  userId,
  canUndo = false,
  handleUndo,
  customText = "",
}) => {
  const { t } = useTranslation("reimbursement/reimbursement");
  const { users } = useConstants();
  const { user } = useGetAuth();
  const name =
    userId == user?.id
      ? t("You")
      : users?.find(({ id }) => id == userId)?.name ?? "You";
  const text = Boolean(customText) ? customText : `${t("Approved by")} ${name}`;
  return (
    <div
      style={{
        height: 40,
        width: "100%",
        padding: "8px 10px",
        display: "flex",
        justifyContent: canUndo ? "space-between" : "center",
        gap: 8,
        alignItems: "center",
        borderRadius: 4,
        border: "1px solid #e6e6e8",
        backgroundColor: "#FCFCFC",
      }}
    >
      <div className="d-flex align-items-center" style={{ height: "100%" }}>
        <JackIcons name="checkmark_circle_2" fill="#06AC4E" />
        <GothamMedium
          style={{ color: "#06AC4E", marginLeft: 4 }}
          className="font12"
        >
          {text}
        </GothamMedium>
      </div>
      {canUndo && (
        <div
          style={{ cursor: "pointer" }}
          className="d-flex align-items-center"
          onClick={handleUndo}
        >
          <JackIcons name="undo_grey" fill="#6c6c71" />
          <GothamMedium
            className="font12"
            style={{ color: "#909098", marginLeft: 4 }}
          >
            {t("Undo")}
          </GothamMedium>
        </div>
      )}
    </div>
  );
};

export const RejectedByInfo = ({
  user_id,
  isDrafter,
  rejection_note,
  onClickEdit,
  canEdit = false,
}) => {
  const { user } = useGetAuth();
  const { users } = useConstants();
  const { t } = useTranslation("reimbursement/reimbursement");
  const userName =
    user_id == user?.id
      ? t("You")
      : users?.find(({ id }) => user_id == id)?.name;

  return (
    <div style={{ border: "1px solid #e6e8e8", borderRadius: 4 }}>
      <div
        style={{
          padding: "8px 10px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FCFCFC",
          borderBottom: "1px solid #E6E6E8",
          justifyContent: "space-between",
        }}
      >
        <div className="d-flex align-items-center">
          <JackIcons name="close_circle" fill="#E73126" />
          <GothamMedium
            className="font12"
            style={{ color: "#E73126", marginLeft: 4 }}
          >
            {t("Rejected by")} {userName}
          </GothamMedium>
        </div>
        {canEdit && (
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={onClickEdit}
          >
            <JackIcons
              name="edit_outline"
              fill="#6c6c71"
              style={{ width: 18, height: 18 }}
            />
            <GothamMedium
              className="font12"
              style={{ color: "#6c6c71", marginLeft: 4 }}
            >
              {t("Edit")}
            </GothamMedium>
          </div>
        )}
      </div>
      <div style={{ padding: 16, justifyContent: "start" }}>
        <GothamRegular
          className="font12"
          style={{ color: "#6c6c71", textAlign: "start" }}
        >
          {rejection_note}
        </GothamRegular>
      </div>
    </div>
  );
};
