import { snakeCase, titleCase } from "change-case";
import {
  applyRegexWhiteSpaces,
  containsOnlyDigits,
} from "../../components/tools";
import { useRouter } from "next/router";
import { fetch, useMutation } from "../../tools/api";
import { useEffect, useState } from "react";
import { isEmailChecker } from "../localTransferEnhancement/create/table/inputs/email/chip";
import { defaultValuesFormatter } from "./loop/logics";
import { isEmpty } from "lodash";

const labelDecider = (stringRaw, index) => {
  const number = index + 1;
  const string = snakeCase(stringRaw);
  const isEmail = string.includes("email");
  // const isDepartment = string.includes("department");
  const isAccountNumber = string.includes("account_number");
  const isPayerId = string.includes("payer_id");
  if (isEmail) return `email_${number}`;
  // if (isDepartment) return `department_name_${number}`;
  if (isAccountNumber) return `account_number_${number}`;
  if (isPayerId) return `bank_${number}`;
  return `${string}_${number}`;
};

export const payrollCreateFormatter = (res) => {
  const array = res?.data?.data || [];
  let obj = {};

  array.forEach((item, index) => {
    Object.keys(item).forEach((key) => {
      const label = labelDecider(key, index);
      const value = item[key];

      if (key == "Bank") return;
      obj[label] = value;
    });
    return obj;
  });

  return obj;
};

const labelDeciderExcel = (stringRaw, index) => {
  const isAccountNumber = stringRaw === "account_number";

  if (isAccountNumber) return "Bank Account Number";
  return titleCase(stringRaw);
};

export const payrollCreateExcelFormatter = (res, isMinimalTrxTreshold) => {
  const rawData = res?.data?.data;

  const isMoreThanLimitMaxTrx = rawData?.length >= isMinimalTrxTreshold;
  if (isMoreThanLimitMaxTrx) return rawData;

  const dataAfterValidationExcel = defaultValuesFormatter(rawData);

  const newData = dataAfterValidationExcel.map((item, index) => {
    let obj = [];
    for (const [key, value] of Object.entries(item)) {
      obj.push({ name: key, value, label: labelDeciderExcel(key) });
    }
    return obj;
  });
  return newData;
};

export const ValidationValueContainWhiteSpace = (
  valueRaw,
  valueCategory,
  isRequiredCategory = false
) => {
  const valueValidation = applyRegexWhiteSpaces(valueRaw);

  const isOnlyWhiteSpace = valueRaw?.length === 1 && valueRaw === " ";

  const isEmptyValue = !valueRaw;

  const isDisabled = isOnlyWhiteSpace || isEmptyValue;
  const isDisabledCategory = isRequiredCategory
    ? isEmpty(valueCategory)
    : false;

  return {
    valueValidation,
    isDisabled,
    isEmptyValue,
    isOnlyWhiteSpace,
    isDisabledCategory,
  };
};

export const CreateFromBgFormatter = (res, titleData, idCompany) => {
  const { payroll_name, category, ...rest } = titleData || {};

  const mapData = res?.map((item) => {
    const { recipient_email, email, ...rest } = item || {};

    // create array email
    const emailString = email + "";
    const arrayEmail = emailString?.includes(",")
      ? emailString?.split(",")
      : [email];

    // eleminated all email that not pass regex
    const filteredEmail = arrayEmail?.filter((email) => isEmailChecker(email));
    const arrayEmailValid = filteredEmail?.length;

    // restructure email payload
    const primaryEmail = arrayEmailValid ? filteredEmail[0] : null;
    const additionalEmails =
      arrayEmailValid > 1 ? filteredEmail.slice(1).join(",") : null;

    return {
      email: primaryEmail,
      recipient_email: primaryEmail,
      additional_emails: additionalEmails,
      ...rest,
    };
  });

  const mutationData = {
    transaction_type: "payroll",
    payroll_attributes: {
      ...rest,
      name: payroll_name,
      partner_id: idCompany,
    },
    data: mapData,
  };

  const hasCategory = !isEmpty(category);

  if (hasCategory) {
    mutationData.category_id = category?.id;
    delete mutationData?.payroll_attributes?.category;
  }

  return mutationData;
};

export const CreateFromBgFunc = ({ idBgProcess, setIdBgProcess }) => {
  const [idPayrollProcess, setIdPayrollProcess] = useState(false);
  const [isError, setIsError] = useState(false);
  const { push } = useRouter();

  const { mutation: createFromBackground } = useMutation({
    url: "local_transaction_batches/request_background_create",
    method: "post",
    afterSuccess: (res) => {
      const { id, payroll } = res?.data?.data || {};
      const { id: idPayroll } = payroll || {};
      setIdBgProcess(id);
      setIdPayrollProcess(idPayroll);
    },
  });

  const {
    data: dataInterval,
    refetch: intervalChecker,
    loading: loadingInterval,
  } = fetch({
    url: `/local_transaction_batches/${idBgProcess}/show_background_progress`,
    woInit: true,
  });

  const { queued_at, error_message } = dataInterval?.batch || {};

  const isDoneCreateFromBg = queued_at === null; // Flagging from BE
  const isErrorCreate = !!error_message;

  useEffect(() => {
    if (isDoneCreateFromBg) {
      push(`/payroll/create/${idPayrollProcess}?state=validating`);
      setIdBgProcess(false);
      setIdPayrollProcess(false);
    }
  }, [isDoneCreateFromBg]);

  useEffect(() => {
    if (!isDoneCreateFromBg && isErrorCreate) {
      setIsError(true);
    }
  }, [isDoneCreateFromBg, error_message]);

  useEffect(() => {
    if (idBgProcess && !loadingInterval) {
      setTimeout(() => {
        intervalChecker();
      }, 5000);
      // if (idBgProcess && !loadingInterval) {
      // Interval hit for loop checking
      // intervalChecker();
      // const intervalId = setInterval(() => {
      //   intervalChecker();
      // }, 5000);
      // return () => {
      //   clearInterval(intervalId);
      // };
      // }
    }
  }, [idBgProcess, loadingInterval]);

  return { createFromBackground, isError };
};

export const PrevPayrollRecreateFormatter = (values, firstValues) => {
  const { payroll_name, ...rest } = firstValues || {};
  const { id: parentId } = values || {};
  const data = {
    transaction_type: "payroll",
    payroll_attributes: {
      ...rest,
      name: payroll_name,
    },
    recreate: true,
    ancestor_id: parentId,
  };
  return data;
};

export const CheckerValidationPayroll = ({ data: dataRaw }) => {
  let counter = 0;

  for (const el of dataRaw) {
    const isValidAccNumber = containsOnlyDigits(el["account_number"]);
    const isValidSalary = containsOnlyDigits(el["net salary"]);
    const isNameOnlyNumber = containsOnlyDigits(el["name"]);
    if (!isValidAccNumber || !isValidSalary || isNameOnlyNumber) {
      counter += 1;
    }
  }

  const isNotPass = counter > 0;
  return { isNotPass };
};
