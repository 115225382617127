import { booleanState } from "../../../../components/Status";
import ancestoryLinesFormatter from "./ancestoryLinesFormatter";
import longestAncestoryLineDecider from "./longestAncestoryLineDecider";

const invalidFailedTrxCountDecider = (transactions = []) => {
  const ancestoryLines = ancestoryLinesFormatter(transactions);
  const longestAncestoryLine = longestAncestoryLineDecider(ancestoryLines);
  const longestAncestoryLineLength = longestAncestoryLine.length;
  const invalidFailedTrxCount = Object.values(ancestoryLines).filter(
    (ancestoryLine) => {
      const trx = ancestoryLine[ancestoryLine.length - 1];
      const { isFailed, isDeclined } = booleanState(trx?.state);
      const isFailedLastTrx = isFailed || isDeclined;
      const isInvalidFailedTrx = ![2, 4, 5, 14].includes(trx?.error_code);
      const isLongestAncestoryLine =
        ancestoryLine.length === longestAncestoryLineLength;
      return isFailedLastTrx && isLongestAncestoryLine && isInvalidFailedTrx;
    }
  ).length;

  return invalidFailedTrxCount;
};

export default invalidFailedTrxCountDecider;
