import { Fragment, useState } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import {
  SINGLE,
  DATA_ERROR,
  BATCH,
  TRX_TEMPLATE,
  PAYROLL,
  LOCAL,
} from "../../constants";
import {
  formattedRawTrxDecider,
  retryResubmitBooleans,
  retryResubmitButtonMixpanels,
  retryResubmitButtonTextDecider,
  retryResubmitButtonUrlDecider,
  retryResubmitValidator,
} from "../../helpers";
import { ConfirmationModalJack } from "../../../../../components/ButtonsJack/confirmation";
import { isEmpty } from "lodash";
import NextImage from "../../../../../components/NextImage";
import { TopUpModal, useModalHook } from "../../../../../components/Modals";
import PINModal from "../../../../../components/PINModal/parent";
import CustomHoverInfo from "../../../../../components/CustomHoverInfo/CustomHoverInfo";
import { GothamRegular } from "../../../../../components/Text";
import { useMutation } from "../../../../../tools/api";
import { useRouter } from "next/router";
import MIXPANELS from "../../../../../universalFunctions/mixpanels";
import { ReleasePaymentModalJack } from "../../../crossBorderSingleModal/ReleasePaymentModal";
import { useSubscriptionUsage } from "../../../../../pageComponents/subscriptionsJackComponents/logics/general-hooks";
import PreventionModal from "../../../../../pageComponents/subscriptionsJackComponents/general-components/PreventionModal/parent";
import {
  useReleaserTaskId,
  useRetryResubmitButtonAfterSuccess,
} from "../../hooks";
import { useBankingStuffDanamon } from "../../../crossBorderSingleModal/components";
import { useLanguage } from "public/locales/translationFunctions";
import { useTranslation } from "react-i18next";

const DrafterReleaserText = ({ name = "", role = "" }) => {
  const { isBahasa } = useLanguage();

  if (isBahasa)
    return (
      <Fragment>
        Setelah anda mengklik{" "}
        <span style={{ fontFamily: "GothamMedium" }}>Remind</span>, {name} (
        {role}) akan menerima notifikasi email.
      </Fragment>
    );

  return (
    <Fragment>
      Once you click the{" "}
      <span style={{ fontFamily: "GothamMedium" }}>Remind</span> button, {name}{" "}
      ({role}) will receive an email notification.
    </Fragment>
  );
};

const RetryResubmitButton = ({
  rawTrx = null,
  trx = TRX_TEMPLATE,
  trxType = SINGLE, // single | batch
  trxStatus = "", // failed | partial_failed | declined (should match to render)
  warningType = DATA_ERROR, // data_error | system_error | mixed_error
  failedTrxCount = 0, // should have more than 0 to render
  invalidFailedTrxCount = 0,
  drafter = {}, // should have drafter to render
  releaser = {}, // should have releaser to render
  style = {},
  resubmittedAt = "",
  remindTiming = {
    shouldWaitRemindTiming: false,
    enableRemindTiming: () => {},
    disableRemindTiming: () => {},
  },
}) => {
  const [confirmationData, setConfirmationData] = useState({});
  const [isHoveredWaitRemind, setIsHoveredWaitRemind] = useState(false);
  const [isLoadingRetryTrx, setIsLoadingRetryTrx] = useState(false);

  const { push } = useRouter();

  const { user } = useGetAuth();

  const { t } = useTranslation("retry-resubmit/retry-resubmit");
  const { isEnglish, isBahasa } = useLanguage();

  const { isSuspended, loadingSubscriptionUsage } = useSubscriptionUsage();

  const formattedRawTrx = formattedRawTrxDecider({ trx, rawTrx });

  const { workflow_approval_tasks, approval_tasks } = formattedRawTrx ?? {};
  const tasks = workflow_approval_tasks ?? approval_tasks ?? [];
  const releaserTask = useReleaserTaskId(tasks);
  const taskId = releaserTask?.id;

  const { propsTransferService, detailData, payloadBanking } =
    useBankingStuffDanamon({
      taskId,
      transaction: formattedRawTrx,
      isRetry: true,
    });

  const { shouldWaitRemindTiming } = remindTiming ?? {};

  const isOpenConfirmationModal = !isEmpty(confirmationData);

  const { isOpen: isOpenSuspendModal, toggle: toggleSuspendModal } =
    useModalHook();
  const { isOpen: isOpenTopUpModal, toggle: toggleTopUpModal } = useModalHook();
  const {
    isOpen: isOpenSummaryModal,
    open: openSummaryModal,
    close: closeSummaryModal,
  } = useModalHook();
  const {
    isOpen: isOpenPINModal,
    open: openPINModal,
    close: closePINModal,
  } = useModalHook();

  const {
    isRetry,
    isResubmit,
    isDrafter,
    isReleaser,
    isReminder,
    isBothDrafterAndReleaser,
  } = retryResubmitBooleans({
    user,
    drafter,
    releaser,
    warningType,
  });

  const { retryAfterSuccessDecider, remindAfterSuccessDecider } =
    useRetryResubmitButtonAfterSuccess({
      trx,
      drafter,
      releaser,
      isRetry,
      isResubmit,
      remindTiming,
    });

  const isValidRetryResubmit = retryResubmitValidator({
    drafter,
    releaser,
    trxStatus,
    warningType,
    failedTrxCount,
  });

  const { retryUrlDecider, remindUrlDecider } = retryResubmitButtonUrlDecider({
    trx,
    isRetry,
  });

  const { mutation: retryTrx } = useMutation({
    method: "post",
    url: retryUrlDecider(),
    afterSuccess: retryAfterSuccessDecider(),
    handleError: () => {
      setIsLoadingRetryTrx(false);
    },
  });

  const {
    retryMixpanels,
    remindMixpanels,
    retryConfirmationMixpanels,
    remindConfirmationMixpanels,
  } = retryResubmitButtonMixpanels({ trx, isRetry });

  const { mutation: remind, loading: loadingRemind } = useMutation({
    method: "post",
    url: remindUrlDecider(),
    afterSuccess: remindAfterSuccessDecider(),
  });

  const shallShowButton =
    isValidRetryResubmit && (isDrafter || isReleaser) && !resubmittedAt;

  if (!shallShowButton) return null;

  const buttonText = retryResubmitButtonTextDecider({
    user,
    drafter,
    releaser,
    warningType,
    t,
  });

  const handleOpenConfirmationModal = (confirmationData) => {
    setConfirmationData(confirmationData);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationData({});
  };

  const handleRemindConfirmation = () => {
    if (!isDrafter && !isReleaser) return;

    const type = () => {
      if (isDrafter) return "retry";
      if (isReleaser) return "resubmit";
      return "-";
    };

    const name = () => {
      const drafterName = drafter?.name || drafter?.email || "-";
      const releaserName = releaser?.name || releaser?.email || "-";

      if (isDrafter) return releaserName;
      if (isReleaser) return drafterName;
      return "-";
    };

    const role = () => {
      if (isDrafter) return "releaser";
      if (isReleaser) return "drafter";
      return "-";
    };

    const title = () => {
      if (isBahasa) {
        if (isDrafter)
          return `Ingatkan ${name()} untuk lakukan pembayaran ulang?`;
        return `Ingatkan ${name()} untuk ajukan kembali transaksi?`;
      }
      return `Remind ${name()} to ${type()} the transaction?`;
    };

    const data = {
      img: "/images/retry-resubmit-confirmation.svg",
      title: title(),
      message: <DrafterReleaserText name={name()} role={role()} />,
      buttonTextLeft: t("Cancel"),
      buttonTextRight: t("Remind"),
    };

    remindConfirmationMixpanels();

    return handleOpenConfirmationModal(data);
  };

  const handleRetryConfirmation = () => {
    const data = {
      img: "/images/retry-resubmit-confirmation.svg",
      buttonTextLeft: t("Back"),
      buttonTextRight: t("Retry Transaction(s)"),
    };

    const title = (isSingle = false, totalTrx = 0) => {
      if (isSingle) {
        if (isBahasa) return `Ulangi pembayaran transaksi?`;
        return "Retry transaction?";
      }
      if (isBahasa) return `Ulangi pembayaran ${totalTrx} transaksi?`;
      return `Retry ${totalTrx} failed transaction(s)?`;
    };

    switch (trxType) {
      case SINGLE:
        return handleOpenConfirmationModal({
          ...data,
          title: title(true),
          message: t("Make sure you’ve done a final check before retrying."),
        });
      case BATCH:
        return handleOpenConfirmationModal({
          ...data,
          title: title(false, failedTrxCount - invalidFailedTrxCount),
          message: t("Make sure you’ve done a final check before retrying."),
        });
      default:
        return;
    }
  };

  const handleResubmitConfirmation = () => {
    switch (trx?.type) {
      case LOCAL:
        MIXPANELS.RETRY_RESUBMIT.RESUBMIT_LOCAL_TRANSFER();
        return push({
          pathname: `/local-transfer/create/${trx?.id}`,
          query: { action: "resubmit", ancestor_id: trx?.id },
        });
      case PAYROLL:
        MIXPANELS.RETRY_RESUBMIT.RESUBMIT_PAYROLL();
        return push({
          pathname: `/payroll/create/${trx?.id}`,
          query: {
            action: "resubmit",
            ancestor_id: trx?.batch_id,
            payroll_id: trx?.id,
          },
        });
      default:
        return;
    }
  };

  const handleClick = () => {
    if (isSuspended) return toggleSuspendModal();
    if (isReminder) return handleRemindConfirmation();

    if (isBothDrafterAndReleaser) {
      if (isRetry) handleRetryConfirmation();
      if (isResubmit) return handleResubmitConfirmation();
      return;
    }

    if (isDrafter) return handleResubmitConfirmation();
    if (isReleaser) return handleRetryConfirmation();
    return;
  };

  const handleRemind = () => {
    remindMixpanels();
    const drafterId = Number(drafter?.id);
    const releaserId = Number(releaser?.id);
    if (isRetry) return remind({ user_id: releaserId });
    if (isResubmit) return remind({ user_id: drafterId });
    return;
  };
  const handleRetry = () => {
    retryConfirmationMixpanels();
    openSummaryModal();
  };
  const handleResubmit = () => {};

  const handleConfirm = () => {
    if (isReminder) return handleRemind();

    if (isBothDrafterAndReleaser) {
      if (isRetry) return handleRetry();
      if (isResubmit) return handleResubmit();
      return;
    }

    if (isDrafter) return handleResubmit();
    if (isReleaser) return handleRetry();
    return;
  };

  const isWaitRemind = isReminder && shouldWaitRemindTiming;

  const isDisabled = isWaitRemind;

  const handleHoverWaitRemind = () => {
    if (isWaitRemind) setIsHoveredWaitRemind(true);
  };
  const handleUnhoverWaitRemind = () => {
    if (isWaitRemind) setIsHoveredWaitRemind(false);
  };

  const handleRetryTrx = ({ pin }) => {
    const isValid = pin?.length === 6;
    if (!isValid || isLoadingRetryTrx) return;
    const { payment_method, transfer_service } = payloadBanking ?? {};
    const retryPayload = { pin };
    if (!!transfer_service && !!payment_method) {
      retryPayload.payment_method = payment_method;
      retryPayload.transfer_service = transfer_service;
    }
    setIsLoadingRetryTrx(true);
    retryMixpanels();
    retryTrx(retryPayload);
  };

  return (
    <Fragment>
      <div
        style={{ position: "relative", width: "100%" }}
        onMouseEnter={handleHoverWaitRemind}
        onMouseLeave={handleUnhoverWaitRemind}
      >
        <ButtonJack
          type="outline"
          style={{ width: "100%", ...style }}
          disabled={isDisabled}
          isLoading={loadingRemind}
          leftIcon={
            !loadingRemind && (
              <JackIcons
                name={isReminder ? "bell-outline" : "retry-resubmit"}
                fill={
                  isDisabled ? jackColors.neutral500 : jackColors.neutral900
                }
              />
            )
          }
          onClick={handleClick}
        >
          {buttonText}
        </ButtonJack>
        <CustomHoverInfo
          show={isHoveredWaitRemind}
          style={{
            width: "320px",
            padding: "12px",
            translate: "30px -120px",
            flexDirection: "column",
            gap: "8px",
          }}
          translateTrianglePoint="155px 54px"
          rotateTrianglePoint="-90deg"
          backgroundColor="white"
        >
          <GothamRegular
            woFontColor
            className="font12"
            style={{ color: jackColors.neutral800 }}
          >
            {t("You're allowed to send a reminder only once within an hour.")}
          </GothamRegular>
        </CustomHoverInfo>
      </div>

      <ConfirmationModalJack
        isCenteredTitle
        modal={isOpenConfirmationModal}
        title={confirmationData?.title}
        text={confirmationData?.message}
        buttonTextLeft={confirmationData?.buttonTextLeft}
        buttonTextRight={confirmationData?.buttonTextRight}
        image={
          <div style={{ marginBottom: "32px" }}>
            <NextImage
              width={160}
              height={160}
              src={confirmationData?.img}
              alt="Retry Resubmit Image"
            />
          </div>
        }
        onClick={handleConfirm}
        toggle={handleCloseConfirmationModal}
        onClickLeft={handleCloseConfirmationModal}
      />

      <ReleasePaymentModalJack
        loading={loadingSubscriptionUsage}
        isOpen={isOpenSummaryModal}
        toggle={closeSummaryModal}
        onClick={openPINModal}
        onClickTopUp={toggleTopUpModal}
        data={formattedRawTrx}
        detailData={detailData}
        {...propsTransferService}
      />
      <PINModal
        isLoading={isLoadingRetryTrx}
        isOpen={isOpenPINModal}
        toggle={closePINModal}
        onBack={closePINModal}
        onSubmit={handleRetryTrx}
      />
      <TopUpModal
        modal={isOpenTopUpModal}
        toggle={toggleTopUpModal}
        dummyPaidButton
        paid
      />
      <PreventionModal
        type="suspended-retry-resubmit"
        isOpen={isOpenSuspendModal}
        toggle={toggleSuspendModal}
        onClick={toggleSuspendModal}
      />
    </Fragment>
  );
};

export default RetryResubmitButton;
