const BottomContainer = ({ children, style }) => {
  const containerStyle = {
    position: "absolute",
    bottom: "0px",
    left: "-16px",
    width: "calc(100% + 32px)",
    ...style,
  };

  return <div style={containerStyle}>{children}</div>;
};

export default BottomContainer;
