import { Trans, useTranslation } from "react-i18next";
import retryResubmitBooleans from "./retryResubmitBooleans";

const retryResubmitButtonTextDecider = ({
  user,
  drafter,
  releaser,
  warningType,
  t = () => {},
}) => {
  const { isRetry, isResubmit, isReminder } = retryResubmitBooleans({
    user,
    drafter,
    releaser,
    warningType,
  });

  if (isReminder) {
    const drafterName = drafter?.name || drafter?.email || "-";
    const releaserName = releaser?.name || releaser?.email || "-";
    // if (isRetry) return `Remind ${releaserName} to Retry`;
    // if (isResubmit) return `Remind ${drafterName} to Resubmit`;
    if (isRetry)
      return (
        <Trans
          i18nKey={t("Dynamic.button_reminder_retry", {
            releaser: releaserName,
          })}
        />
      );
    if (isResubmit)
      return (
        <Trans
          i18nKey={t("Dynamic.button_reminder_resubmit", {
            drafter: drafterName,
          })}
        />
      );
    return "-";
  }

  if (isRetry) return t("Retry Transaction");
  if (isResubmit) return t("Resubmit Transaction");
  return "-";
};

export default retryResubmitButtonTextDecider;
