import { useRouter } from "next/router";
import { windowDimension } from "../../components/tools";
import Head from "next/head";
import { titleCase } from "change-case";

const getPropsMainNav = () => {
  const { pathname } = useRouter();

  const hideSideBarAndNavbarArray = [
    "/login",
    "/",
    "/register",
    "/login-demo",
    "/create-password",
    "/kyb/basis",
    "/forgot-password",
  ];
  const hideSideBarAndNavbar = hideSideBarAndNavbarArray.includes(pathname);

  const hideSidebarArray = [
    "/account/create-pin",
    "/account/invite-users",
    "/deactive",
  ];
  const hideSideBar =
    pathname.includes("kyb") || hideSidebarArray.includes(pathname);
  const isDash = pathname == "/";
  const defaultObj = (title) => ({
    title,
    hideSideBarAndNavbar,
    hideSideBar,
  });

  const isCreateCards = pathname.includes("/cards/create");
  const isEditCards = pathname.includes("/cards/[id]/edit");
  const isDeepTransactionDetails = pathname.includes("transactionId");

  if (isCreateCards) return defaultObj("New Card");

  if (isEditCards) return defaultObj("Edit Card");

  if (isDeepTransactionDetails) return defaultObj("Transaction Details");

  if (pathname.includes("[id]")) {
    const safeSplit = pathname.split("/")[1] || "";
    const title = safeSplit.split("-")[0] + " details";
    return defaultObj(titleCase(title));
  }

  if (isDash) {
    return defaultObj("Jack Account");
  }

  return defaultObj(titleCase(pathname));
};

export const BrowserHeader = () => {
  const { title, hideSideBarAndNavbar } = getPropsMainNav();
  const { isTabOrPhone } = windowDimension();

  return (
    <>
      <Head>
        <title>Jack - {title}</title>
        {hideSideBarAndNavbar && (
          <meta name="description" content="Register your Jack Account Now!" />
        )}
        {isTabOrPhone && (
          <meta
            name="viewport"
            content="width=device-width, user-scalable=no"
          />
        )}
        <link rel="icon" href="/new-favicon.png" />
      </Head>
    </>
  );
};
