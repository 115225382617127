import { useLanguage } from "public/locales/translationFunctions";
import { jackColors } from "../../assets/colors";
import { GothamRegular } from "../Text";

const DynamicText = ({ text, isCustomText }) => {
  if (isCustomText) return text;
  const { isBahasa: isID } = useLanguage();

  const isIncludes = (include) => String(text).includes(include);

  const isDrafterRequested = isID
    ? isIncludes("mengajukan")
    : isIncludes("requested");
  const isDrafterCreated = isID ? isIncludes("membuat") : isIncludes("created");
  const isDrafterEdited = isID ? isIncludes("mengubah") : isIncludes("edited");
  const isDrafterRequestedInvite = isID
    ? isIncludes("ingin mengundang")
    : isIncludes("requested to invite");
  const isDrafterEditedRequest = isID
    ? isIncludes("mengubah undangan")
    : isIncludes("edited the request");
  const isDrafterSentInvitation = isID
    ? isIncludes("mengirimkan undangan ke")
    : isIncludes("sent invitation to");
  const isDrafterJoinedJack = isID
    ? isIncludes("bergabung ke akun perusahaan anda")
    : isIncludes("joined Jack under your company");
  const isDrafterDeactivated = isID
    ? isIncludes("menonaktifkan akun")
    : isIncludes("deactivated");
  const isDrafterActivated = isID
    ? isIncludes("mengaktifkan akun")
    : isIncludes("activated");
  const isDrafterInvitationRequest =
    isDrafterEditedRequest ||
    isDrafterSentInvitation ||
    isDrafterJoinedJack ||
    isDrafterRequestedInvite ||
    isDrafterEdited ||
    isDrafterDeactivated ||
    isDrafterActivated;
  const isDrafter =
    isDrafterCreated || isDrafterRequested || isDrafterInvitationRequest;
  const isApprover = isID
    ? isIncludes("dari") && !isIncludes("transaksi gagal")
    : isIncludes("from");
  const isApproved = isID
    ? isIncludes("Disetujui oleh")
    : isIncludes("Approved by");
  const isRejected = isID
    ? isIncludes("Ditolak oleh")
    : isIncludes("Rejected by");
  const isCanceled = isID
    ? isIncludes("Dibatalkan oleh")
    : isIncludes("Canceled by");
  const isMarkRejected = isIncludes("Marked as rejected by");
  const isMarkApproved = isIncludes("Marked as approved by");
  const isReviewed = isID
    ? isIncludes("Ditinjau oleh")
    : isIncludes("Reviewed by");
  const isReleasedBy =
    isIncludes("Payment has been released by") ||
    isIncludes("Telah dibayar oleh");

  let splittedString = "";

  const textDecider = () => {
    const splitter = (split) => {
      splittedString = split;
      return String(text).split(split);
    };

    if (isDrafterRequested)
      return isID ? splitter("mengajukan") : splitter("requested");
    if (isDrafterCreated)
      return isID ? splitter("membuat") : splitter("created");
    if (isApprover) return isID ? splitter("dari") : splitter("from");
    if (isApproved)
      return isID ? splitter("Disetujui oleh") : splitter("Approved by");
    if (isRejected)
      return isID ? splitter("Ditolak oleh") : splitter("Rejected by");
    if (isCanceled)
      return isID ? splitter("Dibatalkan oleh") : splitter("Canceled by");
    if (isMarkRejected) return splitter("Marked as rejected by");
    if (isMarkApproved) return splitter("Marked as approved by");
    if (isReviewed)
      return isID ? splitter("Ditinjau oleh") : splitter("Reviewed by");
    if (isReleasedBy)
      return isID
        ? splitter("Telah dibayar oleh")
        : splitter("Payment has been released by");
    if (isDrafterRequestedInvite)
      return isID
        ? splitter("mengajukan undangan untuk")
        : splitter("requested to invite");
    if (isDrafterEditedRequest)
      return isID
        ? splitter("mengubah undangan")
        : splitter("edited the request");
    if (isDrafterSentInvitation)
      return isID
        ? splitter("mengirimkan undangan ke")
        : splitter("sent invitation to");
    if (isDrafterJoinedJack)
      return isID
        ? splitter("bergabung ke akun perusahaan anda")
        : splitter("joined Jack under your company");
    if (isDrafterEdited)
      return isID ? splitter("mengubah rincian") : splitter("edited");
    if (isDrafterDeactivated)
      return isID ? splitter("menonaktifkan akun") : splitter("deactivated");
    if (isDrafterActivated)
      return isID ? splitter("mengaktifkan akun") : splitter("activated");
    return String(text);
  };

  const isSpecialCase =
    isDrafterRequested ||
    isDrafterCreated ||
    isApprover ||
    isApproved ||
    isRejected ||
    isMarkRejected ||
    isMarkApproved ||
    isCanceled ||
    isReviewed ||
    isReleasedBy ||
    isDrafterEditedRequest ||
    isDrafterJoinedJack ||
    isDrafterEdited ||
    isDrafterActivated ||
    isDrafterDeactivated ||
    isDrafterRequestedInvite;

  const isMultipleBold = isDrafterSentInvitation;

  const textToRender = textDecider();

  if (isSpecialCase) {
    return (
      <>
        <span style={{ fontFamily: isDrafter ? "GothamMedium" : "GothamBook" }}>
          {textToRender[0] + " "}
        </span>
        <span>{splittedString}</span>
        <span style={{ fontFamily: isDrafter ? "GothamBook" : "GothamMedium" }}>
          {" " + textToRender[1]}
        </span>
      </>
    );
  }

  if (isMultipleBold) {
    return (
      <>
        <span style={{ fontFamily: "GothamMedium" }}>
          {textToRender[0] + " "}
        </span>
        <span>{splittedString}</span>
        <span style={{ fontFamily: "GothamMedium" }}>
          {" " + textToRender[1]}
        </span>
      </>
    );
  }

  return <span>{textToRender}</span>;
};

const Text = ({ item, children, isCustomText }) => {
  const { isActive } = item || {};

  return (
    <GothamRegular
      style={{
        color: isActive ? jackColors.neutral900 : jackColors.neutral700,
        fontSize: "12px",
        lineHeight: "16px",
      }}
    >
      <DynamicText text={children} isCustomText={isCustomText} />
    </GothamRegular>
  );
};

export default Text;
