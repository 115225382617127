import {
  TooltipEdit,
  TooltipError,
} from "../../../../PayrollCreateEnhancement/create/tooltip";
import editIcon from "../../../../../assets/images/edit-outline.svg";
import incorrectIcon from "../../../../../assets/images/incorrect-icon.svg";
import info from "../../../../../assets/images/info-yellow.svg";
import { useState } from "react";
import {
  ModalEditSelectTable,
  ModalEditTable,
} from "../../../../PayrollCreateEnhancement/create/table/modal";
import { useModalHook } from "../../../../../components/Modals";
import { titleCase } from "change-case";
import { colors } from "../../../../../assets/colors";
import { GothamRegular } from "../../../../../components/Text";

export const TableInput = ({
  item,
  index,
  childIndex,
  setData,
  data,
  styleText,
  styleModal,
  getWidth,
}) => {
  const {
    name,
    value,
    label: labelProps,
    invalid,
    error,
    invalidYellow,
  } = item || {};
  const [isHover, setIsHover] = useState(false);
  const isShowIcon = isHover ? "visible" : "hidden";

  const label = labelProps || titleCase(name);

  const { isOpen, close, open } = useModalHook();

  const errorDecider = () => {
    if (invalidYellow)
      return (
        <TooltipError
          title={error?.title || "Error!"}
          desc={error?.msg || "Cannot be empty"}
          style={{ color: "#EEAB47" }}
        >
          <img
            src={info}
            style={{
              width: 20,
              marginRight: 4,
            }}
          />
        </TooltipError>
      );
    if (invalid || !value)
      return (
        <TooltipError
          title={error?.title || "Error!"}
          desc={error?.msg || "Cannot be empty"}
        >
          <img
            src={incorrectIcon}
            style={{
              width: 20,
            }}
          />
        </TooltipError>
      );
    return null;
  };

  return (
    <td
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={open}
      style={{
        padding: "12px 8px",
        position: "relative",
      }}
    >
      <div className="d-flex" style={{ gap: 16 }}>
        {errorDecider()}
        <GothamRegular style={{ color: colors.grey33, ...styleText }}>
          {value || "-"}
        </GothamRegular>
        <TooltipEdit>
          <img
            src={editIcon}
            style={{
              visibility: isShowIcon,
              width: 20,
              cursor: "pointer",
            }}
          />
        </TooltipEdit>
      </div>
      {isOpen && (
        <ModalEditTable
          data={data}
          item={{ ...item, label }}
          style={{ ...styleModal }}
          getWidth={getWidth}
          setData={setData}
          index={index}
          childIndex={childIndex}
          close={close}
        />
      )}
    </td>
  );
};

export const TableSelectionInput = ({
  item,
  index,
  childIndex,
  setData,
  data,
  styleModal,
  getWidth,
}) => {
  const {
    name,
    value: valueProps,
    label: labelProps,
    options,
    invalid,
    error,
  } = item || {};

  const { label: value } =
    options.filter(({ value }) => valueProps == value)[0] || {};
  const [isHover, setIsHover] = useState(false);
  const isShowIcon = isHover ? "visible" : "hidden";

  const label = labelProps || titleCase(name);

  const { isOpen, close, open } = useModalHook();

  return (
    <td
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={open}
      style={{
        padding: "12px 8px",
        position: "relative",
      }}
    >
      <div className="d-flex" style={{ gap: 16 }}>
        {Boolean(invalid || !value) && (
          <TooltipError title={error?.title || ""} desc={error?.msg || ""}>
            <img src={incorrectIcon} style={{ width: 20 }} />
          </TooltipError>
        )}
        <GothamRegular style={{ color: colors.grey33 }}>{value}</GothamRegular>
        <TooltipEdit>
          <img
            src={editIcon}
            style={{
              visibility: isShowIcon,
              width: 20,
              cursor: "pointer",
            }}
          />
        </TooltipEdit>
      </div>
      {isOpen && (
        <ModalEditSelectTable
          options={options}
          data={data}
          style={{ ...styleModal }}
          item={{ ...item, label }}
          setData={setData}
          getWidth={getWidth}
          index={index}
          childIndex={childIndex}
          close={close}
        />
      )}
    </td>
  );
};
