import { times } from "lodash";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors, jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { StatusLabelJack } from "../../components/StatusLabel";
import { GothamMedium, GothamRegular } from "../../components/Text";
import {
  canRefetchDecider,
  formatCurrencyNoDecimal,
  windowDimension,
} from "../../components/tools";
import { useConstants } from "../../contexts/ConstantsContext/parent";
import { reimbursementStatus } from "../../modals/smartActivityModal/reimbursementModal/logics";
import { CategoryIcon } from "../categoryComponents/general/components";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../categoryComponents/general/hooks";
import { idrStringFormatter } from "./data/formatter";
import { useTranslation } from "react-i18next";
import dynamic from "next/dynamic";

const CheckBox = dynamic(() =>
  import("../../components/Checkbox").then((mod) => mod.CheckBox)
);
const CustomTooltip = dynamic(() =>
  import("../../components/Tooltip").then((mod) => mod.default)
);
const TableRowDots = dynamic(() =>
  import("../tableComponents").then((mod) => mod.TableRowDots)
);
const Tbody = dynamic(() =>
  import("../tableComponents").then((mod) => mod.Tbody)
);

const figmaTableWidth = (width, totalWidth = 1360) =>
  `${(width * 100) / totalWidth}`;

const headerArrDefault = [
  { title: "Invoice Number", width: 14 },
  { title: "Payment to", width: 15 },
  { title: "Created Date", width: 12 },
  { title: "Due Date", width: 12 },
  { title: "Total Amount", width: 12 },
  { title: "Status", width: 12 },
  { title: "Description", width: 20 },
];
const headerArrCreate = [
  { title: "No", width: figmaTableWidth(48, 1376), align: "middle" },
  { title: "", width: figmaTableWidth(36, 1376), align: "middle" },
  { title: "Invoice Number", width: figmaTableWidth(200, 1376), align: "left" },
  { title: "Payment to", width: figmaTableWidth(200, 1376), align: "left" },
  { title: "Due Date", width: figmaTableWidth(160, 1376), align: "left" },
  { title: "Description", width: figmaTableWidth(224, 1376), align: "left" },
  { title: "Category", width: figmaTableWidth(148, 1376), align: "center" },
  { title: "Amount", width: figmaTableWidth(160, 1376), align: "right" },
  { title: "", width: figmaTableWidth(120, 1376), align: "left" },
  { title: "", width: figmaTableWidth(64, 1376), align: "left" },
];
const headerArrReimbursement = [
  { title: "No", width: figmaTableWidth(48), align: "middle" },
  { title: "", width: figmaTableWidth(36), align: "left" },
  { title: "Merchant", width: figmaTableWidth(240), align: "left" },
  { title: "Purchase Date", width: figmaTableWidth(160), align: "left" },
  { title: "Description", width: figmaTableWidth(384), align: "left" },
  { title: "Category", width: figmaTableWidth(148), align: "center" },
  { title: "Amount", width: figmaTableWidth(160), align: "right" },
  { title: "", width: figmaTableWidth(120), align: "left" },
  { title: "", width: figmaTableWidth(64), align: "left" },
];

const headerRmbDetails = [
  { title: "Merchant", width: figmaTableWidth(112, 432), align: "left" },
  { title: "Category", width: figmaTableWidth(72, 432), align: "left" },
  { title: "Amount", width: figmaTableWidth(148, 432), align: "right" },
  { title: "Review Status", width: figmaTableWidth(100, 432), align: "left" },
];

const defaultTdTextStyle = (is_duplicate) => {
  return {
    color: is_duplicate && "#909098",
    width: "calc(100% - 16px)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };
};

export const IconToolTip = ({
  title = "",
  text = "",
  icon = "alert_circle_outline",
  iconColor = "",
  position = "right",
  iconStyle,
}) => {
  const translateDecider = () => {
    const isRight = position == "right";
    const isLeft = position == "left";
    const isTop = position == "top";
    const isBottom = position == "bottom";

    const translate = "translateTooltip";
    if (isRight) return translate + "R";
    if (isLeft) return translate + "L";
    if (isTop) return translate + "T";
    if (isBottom) return translate + "B";

    return translate;
  };

  const translateTooltip = translateDecider();
  return (
    <CustomTooltip
      placement={position}
      innerClassName={`duplicateToolTip ${translateTooltip}`}
      popperClassName="white-arrow"
      arrowClassName={translateTooltip}
      style={{
        backgroundColor: "white",
        boxShadow:
          "0px 16px 32px 0px rgba(22, 34, 51, 0.16), 0px 4px 12px -4px rgba(22, 34, 51, 0.12)",
      }}
      text={
        <div
          style={{
            width: 230,
            height: "auto",
            backgroundColor: "white",
            zIndex: 55,
            padding: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: "start",
          }}
        >
          <GothamMedium className="text-black34 mb-1">{title}</GothamMedium>
          <GothamRegular
            className="text-grey6c font12"
            style={{ color: colors.grey6c }}
          >
            {text}
          </GothamRegular>
        </div>
      }
    >
      <JackIcons name={icon} fill={iconColor} style={iconStyle} />
    </CustomTooltip>
  );
};

export const StatusToolTipComponent = ({
  isDuplicate = false,
  status,
  isOutline = true,
  isReimbursement = false,
}) => {
  const toolTipTitle = isReimbursement
    ? "Receipt already exists"
    : "Invoice already exists";
  const toolTipText = isReimbursement
    ? "Duplicate receipt detected. Please keep one and delete the other."
    : "Duplicate invoice detected. Please keep one and delete the other.";
  if (isDuplicate) {
    return (
      <CustomTooltip
        placement="bottom"
        innerClassName="duplicateTooltip translateTooltip"
        popperClassName="white-arrow"
        arrowClassName="translateTooltip"
        text={
          <div
            style={{
              width: 230,
              height: "auto",
              backgroundColor: "white",
              zIndex: 55,
              padding: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              textAlign: "start",
            }}
          >
            <GothamMedium className="text-black34 mb-1">
              {toolTipTitle}
            </GothamMedium>
            <GothamRegular
              className="text-grey6c font12"
              style={{ color: colors.grey6c }}
            >
              {toolTipText}
            </GothamRegular>
          </div>
        }
      >
        <StatusLabelJack
          isOutline={isOutline}
          status="Already exists"
          woTextFormatter
        />
      </CustomTooltip>
    );
  }
  return (
    <StatusLabelJack
      isOutline={isOutline}
      status={status}
      woTextFormatter={true}
      woTitleCase={true}
    />
  );
};

const StatusLabelLoading = () => {
  const { t } = useTranslation("common");
  return (
    <div
      style={{
        width: 88,
        height: 24,
        borderRadius: 4,
        border: "1px solid #E6E6E8",
        display: "flex",
        padding: "0px 8px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        className="loading-bank"
        style={{
          width: 8,
          height: 8,
          position: "relative",
        }}
      >
        <JackIcons
          name={"irm_Status"}
          fill="#BBBBC0"
          style={{ position: "absolute", top: 0, right: 0 }}
        />
      </div>
      <GothamRegular className="font10">{t("Processing...")}</GothamRegular>
    </div>
  );
};

const CardShimmer = ({
  length = 10,
  dataLength = 0,
  showCategory = false,
  isReimbursement = false,
}) => {
  const width = "calc(100% - 16px)";
  return times(length, (index) => (
    <TableRowDots
      key={index}
      style={{ height: 64, borderTop: "1px solid #E6E6E8" }}
    >
      {/* number */}
      <td style={{ padding: "0px 8px" }} align="middle" valign="middle">
        <GothamRegular>{index + 1 + dataLength}</GothamRegular>
      </td>
      {/* duplicate icon */}
      <td style={{ padding: "0px 8px" }} valign="middle">
        {" "}
      </td>
      {/*inv number || merchant */}
      <td style={{ padding: "0px 8px" }} valign="middle">
        <Skeleton width={width} height={12} />
      </td>
      {/* payment to */}
      {!isReimbursement && (
        <td style={{ padding: "0px 8px" }} valign="middle">
          <Skeleton width={width} height={12} />
        </td>
      )}
      {/* due date ||purchased date */}
      <td style={{ padding: "0px 8px" }} valign="middle">
        <Skeleton width={width} height={12} />
      </td>
      {/* description */}
      <td style={{ padding: "0px 8px" }} valign="middle">
        <Skeleton width={width} height={12} />
      </td>
      {/* category */}
      {showCategory && (
        <td style={{ padding: "0px 8px" }} valign="middle">
          <Skeleton width={width} height={12} />
        </td>
      )}
      {/* total amount || amount*/}
      <td style={{ padding: "0px 8px" }} align="right" valign="middle">
        <Skeleton width={width} height={12} />
      </td>
      {/* status */}
      <td style={{ padding: "0px 8px" }} valign="middle">
        <StatusLabelLoading />
      </td>
    </TableRowDots>
  ));
};

export const useCBInvoice = (defaultIds, showCheckBox) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const isAllChecked = defaultIds.length == selectedIds.length;

  const handleClickAll = () => {
    if (isAllChecked) return setSelectedIds([]);
    setSelectedIds(defaultIds);
  };

  const handleAddId = (id) => setSelectedIds((prev) => [...prev, id]);

  const handleRemoveId = (id) =>
    setSelectedIds((prev) => prev.filter((item) => item !== id));

  const handleRemoveAllId = () => {
    setSelectedIds([]);
  };
  const handleClickItem = (id, isActive) => {
    if (isActive) return handleRemoveId(id);
    handleAddId(id);
  };
  const selectedAmount = selectedIds.length;

  useEffect(() => {
    if (showCheckBox) {
      setSelectedIds(defaultIds);
    }
    return;
  }, [showCheckBox]);

  return {
    handleClickAll,
    selectedIds,
    isAllChecked,
    handleClickItem,
    handleRemoveAllId,
    selectedAmount,
  };
};

export const PDFOrImage = ({ pdf }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundColor: jackColors.greyF1,
        width: 40,
        height: 40,
        borderRadius: 8,
      }}
    >
      {pdf ? (
        <img src={pdf} style={{ width: 25, height: 36 }} />
      ) : (
        <JackIcons name="image-2" f />
      )}
    </div>
  );
};

export const InvoicePaymentTableJack = ({
  onClick,
  handleScroll,
  headerArrProps = headerArrDefault,
  bodyArr = [],
  emptyScreenMsg = "",
  componentHeight = 0,
  selection = [],
  tableType,
  setSelectedIds,
  defaultSelectedIds = [],
  isLoading,
  UploadingLength,
  showCheckBox = false,
  activeRowId,
}) => {
  const { t: tCategory } = useTranslation("category/index");
  const tableBodyRef = useRef();
  const onScroll = () => {
    const canRefetch = canRefetchDecider(tableBodyRef, isLoading);
    if (!canRefetch) return;
    handleScroll && handleScroll();
  };
  const { t } = useTranslation("common");
  const { t: tInvoice } = useTranslation("invoice-payment/invoice-payment");
  const isDraft = tableType == "draft";
  const isCreate = tableType == "create";
  const isReimbursement = tableType == "reimbursement";
  const isRmbDetail = tableType == "rmbdetail";
  const {
    handleClickAll,
    selectedIds,
    isAllChecked,
    handleClickItem,
    handleRemoveAllId,
  } = useCBInvoice(defaultSelectedIds, showCheckBox);

  const { categories } = useConstants();

  // send selectedIds to parent
  useEffect(() => {
    if (!setSelectedIds) return;
    setSelectedIds(selectedIds);
  }, [selectedIds]);
  // send selectedIds to parent
  // cleans if tablearray not synced
  useEffect(() => {
    const bodyArrIds = bodyArr.map(({ id }) => id);
    const isDeletedBodyArr = selectedIds.filter(
      (id) => !bodyArrIds.includes(id)
    ).length;
    if (isDeletedBodyArr) return;
    handleRemoveAllId();
  }, [bodyArr.length]);
  // cleans if tablearray not synced
  //
  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory, isNotRequiredCategory } = useCategoryFlag();
  const showCategory = isRequiredCategory || isNotRequiredCategory;

  const headerArrDecider = () => {
    if (isCreate) {
      if (showCheckBox)
        return [
          {
            title: (
              <CheckBox isActive={isAllChecked} onClick={handleClickAll} />
            ),
            width: 3,
            align: "middle",
          },
          ,
          ...headerArrCreate.slice(0, -1),
        ];
      return headerArrCreate;
    }

    if (isReimbursement)
      return showCategory
        ? headerArrReimbursement
        : headerArrReimbursement.filter(({ title }) => title !== "Category");
    if (isRmbDetail)
      return showCategory
        ? headerRmbDetails
        : headerRmbDetails.filter(({ title }) => title !== "Category");
    return headerArrProps;
  };

  const headerArr = headerArrDecider();

  const { screenRealHeight } = windowDimension();
  const totalHeight = screenRealHeight - componentHeight;

  const { users } = useConstants();
  const getUserNameById = (userId) =>
    users?.find(({ id }) => id == userId)?.name;
  return (
    <div
      ref={tableBodyRef}
      onScroll={() => onScroll()}
      style={{
        maxHeight: totalHeight,
        border: "1px solid #E6E6E8",
        backgroundColor: "#FFFFFF",
        borderRadius: isRmbDetail ? 4 : 8,
        padding: "8px 8px 8px 8px",
        marginBottom: 145,
      }}
    >
      <table
        className="w-100"
        style={{
          width: "100%",
          padding: "8px 8px 0px 8px",
          position: "relative",
          tableLayout: "fixed",
          borderRadius: 8,
        }}
      >
        <thead>
          <tr
            style={{
              position: "sticky",
              top: -8,
              borderRadius: 8,
              height: 40,
              backgroundColor: "#FFFFFF",
            }}
          >
            {headerArr?.map((header, index) => {
              const { title, width, align } = header;
              return (
                <td
                  key={index}
                  valign="middle"
                  align={align}
                  style={{
                    width: `${width}%`,
                    padding: "0px 8px",
                  }}
                >
                  <GothamRegular style={{ color: "#909098", fontSize: 12 }}>
                    {t(title)}
                  </GothamRegular>
                </td>
              );
            })}
          </tr>
        </thead>
        {isReimbursement ? (
          <Tbody style={{ textJustify: "center" }}>
            {bodyArr.map((item, index) => {
              const {
                description,
                id,
                is_duplicate,
                check_is_complete,
                merchant,
                purchasedDateShort,
                category_id,
                total_amount,
              } = item || {};
              const number = index + 1;
              const isActive = selectedIds.includes(id);

              const selectionDecider = () => {
                if (showCheckBox) return false;
                if (isCreate || (isReimbursement && !check_is_complete)) {
                  return selection?.filter((option) => option.name != "submit");
                }
                return selection;
              };

              const checkboxDecider = () => {
                if (showCheckBox)
                  return (
                    <td
                      style={{ padding: "0px 8px" }}
                      valign="middle"
                      align="middle"
                    >
                      <CheckBox
                        isActive={isActive}
                        onClick={(e) => {
                          if (!e) return;
                          e.preventDefault();
                          e.stopPropagation();
                          if (check_is_complete) handleClickItem(id, isActive);
                        }}
                      />
                    </td>
                  );
                return null;
              };

              const statusDecider = () => {
                const isMissingRequiredCategory =
                  isRequiredCategory && !category_id && hasCategoryList;

                if (isMissingRequiredCategory) return t("Missing details");
                if (check_is_complete) return "Ready to submit";
                return t("Missing details");
              };

              const category = (categories ?? []).find(
                ({ id }) => id === category_id
              );

              return (
                <TableRowDots
                  style={{
                    height: 64,
                    borderTop: "1px solid #E6E6E8",
                    opacity: showCheckBox && !check_is_complete && 0.5,
                  }}
                  trOnClick={() => {
                    if (showCheckBox) {
                      if (check_is_complete) handleClickItem(id, isActive);
                      return;
                    }
                    onClick && onClick(item);
                  }}
                  data={selectionDecider()}
                  key={number}
                  onClick={(arrayItem) => onClick({ ...item, arrayItem })}
                  woTd
                  className={
                    showCheckBox
                      ? check_is_complete && "tdrow-invoice"
                      : "tdrow-invoice"
                  }
                >
                  {checkboxDecider()}
                  <td valign="middle" align="middle">
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {number}
                    </GothamRegular>
                  </td>
                  <td valign="middle" align="middle">
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {is_duplicate && (
                        <IconToolTip
                          iconColor="#FCB037"
                          title={tInvoice("Duplicated receipt")}
                          text={tInvoice(
                            "We've detected identical invoice in our system. Please decide whether to keep or delete it."
                          )}
                        />
                      )}
                    </GothamRegular>
                  </td>
                  <td style={{ padding: "0px 8px" }}>
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {merchant || "-"}
                    </GothamRegular>
                  </td>

                  <td style={{ padding: "0px 8px" }}>
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {purchasedDateShort || "-"}
                    </GothamRegular>
                  </td>
                  <td
                    style={{
                      padding: "0px 8px",
                    }}
                  >
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {description || "-"}
                    </GothamRegular>
                  </td>
                  {showCategory && (
                    <td valign="middle" align="middle">
                      {category ? (
                        <CategoryIcon
                          name={category?.icon}
                          fill={category?.color}
                        />
                      ) : (
                        <GothamRegular style={defaultTdTextStyle(false)}>
                          -
                        </GothamRegular>
                      )}
                    </td>
                  )}
                  <td style={{ padding: "0px 8px" }}>
                    <GothamRegular
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {idrStringFormatter(total_amount)}
                    </GothamRegular>
                  </td>
                  <td style={{ padding: "0px 8px" }}>
                    <StatusToolTipComponent
                      status={statusDecider()}
                      isDuplicate={false}
                      isReimbursement={true}
                    />
                  </td>
                  {/* {(isCreate || isReimbursement) && false && !showCheckBox ? (
                    <td style={{ cursor: "pointer" }} align="middle">
                      <JackIcons
                        name="delete"
                        fill="#BBBBC0"
                        onClick={() =>
                          onClick({ ...item, arrayItem: { value: "delete" } })
                        }
                      />
                    </td>
                  ) : null} */}
                </TableRowDots>
              );
            })}
            {isLoading && (
              <CardShimmer
                showCategory={showCategory}
                length={UploadingLength}
                dataLength={bodyArr?.length}
                isReimbursement={isReimbursement}
              />
            )}
          </Tbody>
        ) : isRmbDetail ? (
          <Tbody style={{ textJustify: "center" }}>
            {bodyArr.map((item, index) => {
              const {
                id,
                is_duplicate,
                check_is_complete,
                merchant,
                total_amount,
                status: statusRaw,
                activity_state,
                rejected_by_id,
                category_id,
                user_id,
                approval_item_saved_reviews,
              } = item || {};
              const savedState = approval_item_saved_reviews?.state;

              const originalState =
                statusRaw == activity_state ? statusRaw : activity_state;

              const state = savedState || originalState;

              const number = index + 1;
              const isActive = id == activeRowId;
              const isMissingRequriedCategory =
                isRequiredCategory && !category_id;
              const status = reimbursementStatus(
                state,
                rejected_by_id,
                true,
                check_is_complete,
                isMissingRequriedCategory
              );
              const category = (categories ?? []).find(
                ({ id }) => id === category_id
              );

              const showSubtitle = activity_state == "cancelled";
              const isReject = Boolean(rejected_by_id);
              const actor = isReject
                ? getUserNameById(rejected_by_id)
                : getUserNameById(user_id);
              const subtitle = `(${
                isReject ? "Rejected" : "Canceled"
              } by ${actor})`;
              return (
                <TableRowDots
                  style={{
                    height: 64,
                    borderTop: "1px solid #E6E6E8",
                    backgroundColor: isActive && colors.greye6,
                    opacity: showCheckBox && !check_is_complete && 0.5,
                  }}
                  trOnClick={() => {
                    if (showCheckBox) {
                      if (check_is_complete) handleClickItem(id, isActive);
                      return;
                    }
                    onClick && onClick(item);
                  }}
                  key={number}
                  onClick={(arrayItem) => onClick({ ...item, arrayItem })}
                  woTd
                  className={
                    showCheckBox
                      ? check_is_complete && "tdrow-invoice"
                      : "tdrow-invoice"
                  }
                >
                  <td
                    valign="middle"
                    align="left"
                    style={{ padding: "0px 8px" }}
                  >
                    <div className="d-flex-column justify-content-between">
                      <GothamRegular style={defaultTdTextStyle(is_duplicate)}>
                        {merchant}
                      </GothamRegular>
                      {showSubtitle && (
                        <GothamRegular
                          className="font10"
                          style={{
                            // ...defaultTdTextStyle(false),
                            color: "#909098",
                          }}
                        >
                          {subtitle}
                        </GothamRegular>
                      )}
                    </div>
                  </td>
                  {showCategory && (
                    <td valign="middle" align="middle">
                      {category ? (
                        <CategoryIcon
                          name={category?.icon}
                          fill={category?.color}
                        />
                      ) : (
                        <GothamRegular style={defaultTdTextStyle(false)}>
                          -
                        </GothamRegular>
                      )}
                    </td>
                  )}
                  <td style={{ padding: "0px 8px" }}>
                    <GothamRegular
                      style={{
                        textAlign: "right",
                      }}
                    >
                      IDR {formatCurrencyNoDecimal(total_amount)}
                    </GothamRegular>
                  </td>
                  <td style={{ padding: "0px 0px" }} align="left">
                    <StatusToolTipComponent
                      status={status}
                      isDuplicate={false}
                      isOutline={false}
                    />
                  </td>
                </TableRowDots>
              );
            })}
            {isLoading ? (
              <CardShimmer
                length={UploadingLength - bodyArr?.length}
                dataLength={bodyArr?.length}
              />
            ) : null}
          </Tbody>
        ) : (
          <Tbody style={{ textJustify: "center" }}>
            {bodyArr.map((item, index) => {
              const {
                invoiceNumber,
                billTo,
                totalAmount,
                status,
                description,
                id,
                is_duplicate,
                check_is_complete,
                dueDateShort,
                category_id,
              } = item || {};
              const number = index + 1;
              const isActive = selectedIds.includes(id);
              const selectionDecider = () => {
                if (showCheckBox) return false;
                // if (is_duplicate) return false;
                if (isCreate && !check_is_complete) {
                  return selection?.filter((option) => option.name != "submit");
                }
                return selection;
              };

              const category = (categories ?? []).find(
                ({ id }) => id === category_id
              );

              const checkboxDecider = () => {
                if (showCheckBox)
                  return (
                    <td
                      style={{ padding: "0px 8px" }}
                      valign="middle"
                      align="middle"
                    >
                      <CheckBox
                        isActive={isActive}
                        onClick={(e) => {
                          if (!e) return;
                          e.preventDefault();
                          e.stopPropagation();
                          if (check_is_complete) handleClickItem(id, isActive);
                        }}
                      />
                    </td>
                  );
                return null;
              };

              const statusDecider = () => {
                const isMissingRequiredCategory =
                  isRequiredCategory && !category_id && hasCategoryList;

                if (isMissingRequiredCategory) return t("Missing details");
                if (check_is_complete) return "Ready to submit";
                return t("Missing details");
              };

              return (
                <TableRowDots
                  style={{
                    height: 64,
                    borderTop: "1px solid #E6E6E8",
                    opacity: showCheckBox && !check_is_complete && 0.5,
                  }}
                  trOnClick={() => {
                    if (showCheckBox) {
                      if (check_is_complete) handleClickItem(id, isActive);
                      return;
                    }
                    onClick && onClick(item);
                  }}
                  data={selectionDecider()}
                  key={number}
                  onClick={(arrayItem) => onClick({ ...item, arrayItem })}
                  woTd
                  className={
                    showCheckBox
                      ? check_is_complete && "tdrow-invoice"
                      : "tdrow-invoice"
                  }
                >
                  {checkboxDecider()}
                  <td valign="middle" align="middle">
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {number}
                    </GothamRegular>
                  </td>
                  <td valign="middle" align="middle">
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {is_duplicate && (
                        <IconToolTip
                          iconColor="#FCB037"
                          title={tInvoice("Duplicated invoice")}
                          text={tInvoice(
                            "We’ve detected identical invoice in our system. Pleace decide whether to keep or delete it."
                          )}
                        />
                      )}
                    </GothamRegular>
                  </td>
                  <td style={{ padding: "0px 8px" }}>
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {invoiceNumber || "-"}
                    </GothamRegular>
                  </td>

                  <td style={{ padding: "0px 8px" }}>
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {billTo || "-"}
                    </GothamRegular>
                  </td>
                  <td style={{ padding: "0px 8px" }}>
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {dueDateShort}
                    </GothamRegular>
                  </td>
                  <td
                    style={{
                      padding: "0px 8px",
                    }}
                  >
                    <GothamRegular style={defaultTdTextStyle(false)}>
                      {description || "-"}
                    </GothamRegular>
                  </td>
                  <td valign="middle" align="middle">
                    {category ? (
                      <CategoryIcon
                        name={category?.icon}
                        fill={category?.color}
                      />
                    ) : (
                      <GothamRegular style={defaultTdTextStyle(false)}>
                        -
                      </GothamRegular>
                    )}
                  </td>
                  <td style={{ padding: "0px 8px" }}>
                    <GothamRegular
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {totalAmount}
                    </GothamRegular>
                  </td>
                  <td style={{ padding: "0px 8px" }}>
                    <StatusToolTipComponent
                      status={statusDecider()}
                      isDuplicate={false}
                    />
                  </td>
                  {/* {isCreate && is_duplicate && !showCheckBox ? (
                    <td style={{ cursor: "pointer" }} align="middle">
                      <JackIcons
                        name="delete"
                        fill="#BBBBC0"
                        onClick={() =>
                          onClick({ ...item, arrayItem: { value: "delete" } })
                        }
                      />
                    </td>
                  ) : null} */}
                </TableRowDots>
              );
            })}
            {isLoading ? (
              <CardShimmer
                showCategory={true}
                length={UploadingLength - bodyArr?.length}
                dataLength={bodyArr?.length}
              />
            ) : null}
          </Tbody>
        )}
      </table>
    </div>
  );
};
