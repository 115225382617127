import { useGetAuth } from "contexts/AuthContext";

type User = { partner?: { id: number } };

const CHOSEN_COMPANY_IDS = [
  1100, // Busindo Ayana
  608, // CV Berwisata Meraih Mimpi
  1185, // CV Dab Subur
  1644, // CV Dibeliinkita Jaya Retail
  1830, // CV Karya Muda Digital Indonesia
  112, // CV KT International
  2395, // CV Putra Bangsa Sejahtera
  584, // CV Rakhmad
  42, // CV RC Electronic
  108, // CV Rivia Andika
  407, // Golden Charm Pte Ltd
  718, // Himpuh (Himpunan Penyelenggara Haji dan Umrah)
  1558, // PT Adhimatratama
  412, // PT Adhimix PCI Indonesia
  410, // PT Adhimix RMX Indonesia
  2110, // PT Adita Al Tahir
  127, // PT Adyaraka Visi Komunika
  314, // PT AFG VENTURA TEKNOLOGI
  33, // PT Afirmus Biotek Indonesia
  1624, // PT Ahsanu Amala Boga
  545, // PT Akasia Digital Creative
  1418, // PT Aku Mitra Digital
  318, // PT AL HIJRAH MULIA WISATA
  1581, // PT Aldawood Barokah Utama
  2719, // PT Aliz Technologies Indonesia
  3478, // PT Aneka Teknologi Nusantara
  1111, // PT Antares Global Teknologi
  1128, // PT Anugrah Samudra Propertindo
  286, // PT Aplikasi Paidbaq Indonesia
  693, // PT Aplikasi Pekerja Indonesia
  345, // PT Arkadia Sinergi Indonesia
  1420, // PT Armada Lintas Khatulistiwa
  710, // PT Asia Carbon Indonesia
  1136, // PT ASSA PUTRA PROPERTI
  1142, // PT Assalam Fil Alamin
  951, // PT Astana Bumitama Persada
  1510, // PT Aswa Mulia Berkah
  37, // PT Aventrada Jaya Dharma
  515, // PT Axella Adhi Pratama
  1000, // PT Babah Mandiri Wisata
  3240, // PT Badar Mulia Wisata
  755, // PT Bakti Mubarak Wisata
  1452, // PT Bali Made Tur
  725, // PT Bask Gili Meno
  1049, // PT Basmah Jaya Wisata
  294, // PT Berdampak Membangun Indonesia
  984, // PT Berkah Cahaya Safar
  88, // PT Berkat Mulia Sukses Sejati
  1386, // PT BIRO PERJALANAN UMUM DAYAKINDO KALIMANTAN UTAMA
  922, // PT Biro Perjalanan Wisata Rezki Internasional Indonesia
  572, // PT Blink Indonesia Global
  41, // PT Boga Perdana Jaya
  995, // PT Brahmana Muda Sentosa
  238, // PT Candi Graha Artha
  1127, // PT Capella Digicrats Indonesia
  17, // PT Cendana Murni Indonesia
  384, // PT Centra Visi
  1673, // PT Core Industri Internasional
  1607, // PT Dala Riset Global
  93, // PT Daniyal Barokah Travel
  1592, // PT Daur Material Indonesia
  1392, // PT DAVA TOUR & TRAVEL
  1005, // PT Delta Laras Wisata
  1344, // PT Desty Karsa Wesya
  419, // PT Dharma Maju Sejahtera
  81, // PT Dunia Pendidikan Indonesia
  1068, // PT Duta Faras
  733, // PT Duta Utama Senjaya
  1437, // PT Edrus Expo Anugrah
  21, // PT Effori Prospero Solusi
  333, // PT Ekalya Purnamasari Offshore
  832, // PT Enkripsi Teknologi Handal
  2245, // PT FALAH INOVASI TEKNOLOGI
  2012, // PT FHCreative Group Indonesia
  1478, // PT Fintek Integrasi Digital
  95, // PT Flado Indonesia Utama
  1106, // PT Fore Kopi Indonesia
  23, // PT Forest Indo Niaga
  1683, // PT FORMASI MUTU LAKSANA
  604, // PT Fruters Indonesia Perkasa
  243, // PT GAMMA ENERGI PRATAMA
  327, // PT Gemilang Cipta Pratama Ekspress
  875, // PT Generasi Misi Kapital
  1384, // PT GHIFARY ANUGERAH ILAHI
  551, // PT Giat Bangun Indonesia
  61, // PT Giri Sakti Utama
  1364, // PT Global Bioray Teknologi
  358, // PT Global Radius Brands
  173, // PT Globalindo Eco Nawasena
  474, // PT Graha Aska
  1312, // PT Granada Bening Lestari
  1638, // PT GRIVY DOTCOM
  267, // PT Gua Teknologi Indonesia
  869, // PT Handal Selaras Ganesha
  845, // PT Handal Selaras Medika
  326, // PT Heaven Grup Indonesia
  80, // PT Herlyk Citra Prima
  964, // PT HIJRAH QOLBU QUALITY
  688, // PT Ide Dia Kreatif
  1415, // PT Indigra Abyakta Gemilang
  979, // PT Indo Citra Tamasya
  391, // PT INDO SARANA JAYA PERKASA
  391, // PT Indosarana Jaya Perkasa
  8, // PT Infiniti Marvela Levana
  585, // PT Insurtech Technologies Indonesia
  443, // PT Intan Cahaya Amanah
  1259, // PT INTERAKSI KREASI ANTARA
  3083, // PT Jawa Kreatif Desain
  727, // PT Jaya Medika Alkesindo
  3151, // PT JOANA SUKSES BERSAMA
  1383, // PT Joob Global Indonesia
  3621, // PT Juwistik Global Jaya
  1065, // PT Kafilah Maghfirah Wisata
  778, // PT Kartini Otomasi Indonesia
  556, // PT Karya Jasa Antaran
  582, // PT Kasual Jaya Sejahtera
  1310, // PT Kencana Luna Indonesia
  2520, // PT KHAIRO MULYA WISATA
  1669, // PT Kliknbook Internasional
  1593, // PT Koding Kreasi Indonesia
  668, // PT Kors Beautipedia Permata
  803, // PT Kreasi Anak Indonesia
  1998, // PT LABAIKA KHADIMUL UMAT
  306, // PT Labbaika Cipta Imani
  1981, // PT LIENS DIGITAL GRUP
  1701, // PT Lim Trading International
  133, // PT Liotec Mitra Utama
  5, // PT Love Bonito Indonesia
  36, // PT Lumiship Logistik Indonesia
  996, // PT Luna Amanah Internasional
  86, // PT Macain Energy Services
  1062, // PT Madinah Iman Wisata
  377, // PT Magalarva Sayana Indonesia
  1114, // PT Maka Group Internasional
  707, // PT Manikam Mega Persada
  613, // PT Marvel Investama Asia
  575, // PT Mayar Kernel Supernova
  323, // PT Mazel Cantika Indonesia
  24, // PT Media Notifikasi Indonesia
  900, // PT Media Wave Interaktif
  2381, // PT Menggapai Bintang Angkasa
  3041, // PT Meridian Medical Indonesia
  285, // PT Meroket Bersama Supersewa
  2839, // PT Mismass Indo Group
  256, // PT Mitra Pedagang Indonesia Tbk
  1394, // PT Mitra Semeru Indonesia
  416, // PT Modern Pulsa Investama
  106, // PT Morello Mitra Abadi
  1348, // PT Multi Sinar Bintang
  564, // PT New Resource Mine Consulting
  1467, // PT Nizar Amanah Wisata
  159, // PT Nobel Managemen Indonesia
  1576, // PT Nusa Indah Internasional
  2103, // PT Nusa Media Teknologi
  1580, // PT Wisata Pilihan
  758, // PT Yatsrib Daarul Ulum
  2377, // Universitas Negeri Malang - UPT KUI
  250, // Yayasan Layanan UMKM Naik Kelas
] as const;

const useIsChosenDanamonAdsCompany = (): boolean => {
  const { user } = useGetAuth() as { user?: User };
  const { partner } = user ?? {};
  const { id } = partner ?? {};
  const isChosen = !!CHOSEN_COMPANY_IDS.find(
    (chosenCompanyId) => chosenCompanyId === id
  );
  return isChosen;
};

export default useIsChosenDanamonAdsCompany;
