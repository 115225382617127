import React, { useEffect, useState } from "react";
import { useHeader } from "../../contexts/Layout/parent";
import { MoveBalanceMainMenu } from "./components/mainMenu";
import { useRouter } from "next/router";
import { useModalHook } from "../../components/Modals";
import PINModal from "../../components/PINModal/parent";
import { useFetchCompanyBalance, useMoveBalance } from "./logics";
import { ToasterHook } from "../../contexts/ToasterContext";
import { useGetAuth } from "../../contexts/AuthContext";
import { formatCurrencyNoDecimal, isProduction } from "../../components/tools";
import { GothamMedium } from "../../components/Text";
import { MoveBalanceTransactionModal } from "./components/detailModal";
import { fetch } from "../../tools/api";
import { isEmpty } from "lodash";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { useSubscriptionUsage } from "../subscriptionsJackComponents/logics/general-hooks";
import { useTranslation } from "react-i18next";

export const useFetchMoveBalance = ({
  url = "/business_internal_transfers/show_move_balance",
  formatter,
}) => {
  const {
    data: moveBalanceData,
    loading: loadingMoveBalance,
    refetch: refetchMoveBalance,
  } = fetch({
    url,
    woInit: true,
    formatter,
  });

  return { moveBalanceData, loadingMoveBalance, refetchMoveBalance };
};

const MoveBalanceComponent = () => {
  const [transactionId, setTransactionId] = useState(null);
  const [forceLoading, setForceLoading] = useState(false);
  const { t } = useTranslation("ledgers/ledgers");
  // DEV ONLY. REMOVE BEFORE LAUNCH VV
  // const [simulation, setSimulation] = useState({ state: false, ms: 2000 });
  // DEV ONLY. REMOVE BEFORE LAUNCH ^^
  const {
    vaPocketBalance,
    danamonBalance: primaryBalance,
    refetch: refetchDanamon,
  } = useFetchCompanyBalance();
  const { isUltimate } = useSubscriptionUsage();
  const { setHeader } = useHeader();
  const { push } = useRouter();
  const { successSnackBar } = ToasterHook();
  const { moveBalanceData, refetchMoveBalance } = useFetchMoveBalance({
    formatter: (res) => res?.data,
  });

  const successMsgDecider = (isComplete = true, amount = 0) => {
    if (isComplete)
      return (
        <>
          <span>
            <GothamMedium
              style={{ display: "inline-block" }}
            >{`IDR ${formatCurrencyNoDecimal(Number(amount))}`}</GothamMedium>
          </span>{" "}
          {t("has been moved to primary bank account.")}
        </>
      );
    return t("We’re processing your balance move");
  };

  const { mutation, loading } = useMoveBalance({
    afterSuccess: (res) => {
      const { id } = res?.data?.data;
      localStorage.setItem("move-balance-id", id);
      setForceLoading(true);
    },
  });

  const afterLoading = () => {
    const { amount, state, created_at, id } = moveBalanceData || {};
    const isComplete = state === "completed";
    successSnackBar({
      msg: successMsgDecider(isComplete, amount),
      showClose: true,
    });
    localStorage.removeItem("move-balance-id");
    refetchDanamon();
    setTransactionId(null);
    setForceLoading(false);
    if (!isComplete) {
      localStorage.setItem(
        "move-balance-delayed-details",
        JSON.stringify({
          id,
          created_at,
          currentVaBalance: Number(vaPocketBalance),
          currentDanamonBalance: Number(primaryBalance),
        })
      );
      return push("/dashboard?show-move-balance-status=true");
    }
    return push("/dashboard");
  };

  const {
    isOpen: isOpenPINModal,
    open: openPINModal,
    close: closePINModal,
    toggle: togglePINModal,
  } = useModalHook();

  const handleClose = () => {
    push("/dashboard");
  };

  const handleClick = ({ type }) => {
    if (type === "cancel") return push("/dashboard");
    openPINModal();
  };

  const onSubmitPIN = ({ pin }) => {
    const payload = {
      pin,
      amount: !isProduction ? 100000 : vaPocketBalance, // kalo staging bakal move 100k doang (request be)
      // amount: 100000, //? TESTING ONLY
    };
    mutation(payload);
  };

  // EFFECTS
  useEffect(() => {
    setHeader({
      type: "close",
      onCloseProps: () => handleClose(),
      onClick: (value) => value,
    });
  }, []);

  useEffect(() => {
    if (!transactionId) return;
    if (!isUltimate) return;
    refetchMoveBalance();
  }, [transactionId]);

  useEffect(() => {
    if (!forceLoading) return;
    const moveBalanceId = localStorage.getItem("move-balance-id");
    const timeout = setTimeout(() => {
      setTransactionId(moveBalanceId);
      // DEV ONLY. CHANGE TO 2000 BEFORE LAUNCH
    }, 2000);
    return () => clearTimeout(timeout);
  }, [forceLoading]);

  useEffect(() => {
    if (isEmpty(moveBalanceData)) return;
    afterLoading();
  }, [moveBalanceData]);

  return (
    <>
      <MoveBalanceMainMenu handleClick={handleClick} />
      {/* <div
        // DEV ONLY. REMOVE BEFORE LAUNCH
        style={{
          padding: 10,
          display: "flex",
          gap: 8,
          width: "fit-content",
          scale: "75%",
        }}
      >
        Delayed Move Balance {"(for dev testing only)"}:
        <ButtonJack
          isBullet
          isSmall
          style={{
            backgroundColor: !simulation.state ? "red" : "green",
          }}
          styleText={{ color: "white" }}
          onClick={() => {
            if (simulation.state)
              return setSimulation({ state: false, ms: 2000 });
            return setSimulation({ state: true, ms: 100 });
          }}
        >
          {simulation.state ? "On" : "Off"}
        </ButtonJack>
      </div> */}
      <PINModal
        isOpen={isOpenPINModal}
        isLoading={forceLoading || loading}
        onBack={closePINModal}
        toggle={togglePINModal}
        onSubmit={onSubmitPIN}
        woClose={forceLoading || loading}
      />
    </>
  );
};

export default MoveBalanceComponent;
