import { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { useModalHook } from "../../../../../components/Modals";
import { GothamRegular } from "../../../../../components/Text";
import SearchDropdown from "../SearchDropdown";
import styles from "./CategoryTeamSelection.module.css";
import { useConstants } from "../../../../../contexts/ConstantsContext/parent";
import { useClickOutside } from "../../../../../universalFunctions/useClickOutside";
import { useTranslation } from "react-i18next";

export const CategoryTeamSelectionDropdown = forwardRef(
  (
    {
      isOpen = false,
      woSearch = false,
      search = "",
      roleUpAnimationCN = "",
      roleDownAnimationCN = "",
      teams = [],
      selectedTeams = [],
      style = {},
      onSelect = () => {},
      setSearch = () => {},
      t = () => {},
    },
    ref
  ) => {
    const selectedTeamIds = selectedTeams.map(({ id }) => id);

    return (
      <SearchDropdown
        ref={ref}
        woSearch={woSearch}
        placeholder={t("Search team name")}
        value={search}
        isOpen={isOpen}
        style={style}
        roleUpAnimationCN={roleUpAnimationCN}
        roleDownAnimationCN={roleDownAnimationCN}
        onChange={(value) => setSearch(value)}
        onClick={(e) => e.stopPropagation()}
      >
        {woSearch && (
          <div className={styles["selected-teams-container_v2"]}>
            {selectedTeams.length > 0 ? (
              <Fragment>
                {selectedTeams.map((selectedTeam) => {
                  return (
                    <div
                      key={selectedTeam.id}
                      className={styles["selected-team"]}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <JackIcons
                        name={
                          selectedTeam.id === "anyone"
                            ? "lock-open"
                            : "pricetags-outline"
                        }
                        fill={selectedTeam.color || jackColors.neutral800}
                        style={{ scale: "0.6" }}
                      />
                      <GothamRegular
                        woFontColor
                        className="font12"
                        style={{
                          color: selectedTeam.color || jackColors.neutral800,
                          maxWidth: "100px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {selectedTeam?.name}
                      </GothamRegular>
                      <JackIcons
                        name="close-outline"
                        fill={jackColors.neutral700}
                        style={{ scale: "0.8" }}
                        onClick={() => onSelect(selectedTeam?.id)}
                      />
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <GothamRegular
                woFontColor
                style={{ color: jackColors.neutral600 }}
              >
                {t("Select team")}
              </GothamRegular>
            )}
          </div>
        )}

        <div className={styles["team-list-container"]}>
          {!search.length > 0 && !selectedTeamIds.includes("anyone") && (
            <div className={styles["team"]} onClick={() => onSelect()}>
              <JackIcons
                name="lock-open"
                fill={jackColors.neutral800}
                style={{ scale: "0.8" }}
              />
              <GothamRegular
                woFontColor
                style={{ color: jackColors.neutral800 }}
              >
                {t("Anyone")}
              </GothamRegular>
            </div>
          )}
          {teams.map((team) => {
            return (
              <div
                key={team.id}
                className={styles["team"]}
                onClick={() => onSelect(team.id)}
              >
                <JackIcons
                  name="pricetags-outline"
                  fill={team.color || jackColors.neutral800}
                  style={{ scale: "0.8" }}
                />
                <GothamRegular
                  woFontColor
                  style={{ color: team.color || jackColors.neutral800 }}
                >
                  {team.name}
                </GothamRegular>
              </div>
            );
          })}
        </div>
      </SearchDropdown>
    );
  }
);

const CategoryTeamSelection = ({
  woLabel = false,
  autoOpen = false,
  selectedTeamIds = [],
  onSelect = () => {},
  onToggle = () => {},
  t = () => {},
}) => {
  const [search, setSearch] = useState("");
  const [inputHeight, setInputHeight] = useState(40);

  const { teamsData } = useConstants();

  const { isOpen, open, close, toggle } = useModalHook();

  const ref = useRef();
  const dropdownOffsetTop = inputHeight + 8;

  useClickOutside({ ref, clickOutside: close });

  useEffect(() => {
    if (autoOpen) open();
  }, [autoOpen]);

  const filteredTeams = (teamsData ?? []).filter((team) => {
    const isSelected = !!selectedTeamIds.find((id) => team.id === id);
    if (isSelected) return false;

    const isSearching = !!search;

    const teamName = team.name?.toLowerCase();
    const searchParam = search.toLowerCase();

    const isMatchSearchParam = teamName.includes(searchParam);

    if (isSearching && !isMatchSearchParam) return false;
    return true;
  });

  const anyoneTeam = {
    id: "anyone",
    name: t("Anyone"),
    color: jackColors.neutral800,
  };

  const teamIds = teamsData.map(({ id }) => id);
  const selectedTeamIdsToUse = selectedTeamIds.filter(
    (teamId) => teamIds.includes(teamId) || teamId === "anyone"
  );

  const selectedTeams = selectedTeamIdsToUse
    .map((teamId) => {
      if (teamId === "anyone") return anyoneTeam;
      return teamsData.find(({ id }) => teamId === id);
    })
    .filter((team) => team);
  console.log("selectedTeams:", selectedTeams);

  const handleToggle = () => {
    toggle();
    onToggle();
    setSearch("");
  };

  return (
    <div className={styles["category-team-selection"]}>
      {!woLabel && (
        <GothamRegular
          woFontColor
          className="font12"
          style={{ color: jackColors.neutral900 }}
        >
          {t("Category Icon")}
        </GothamRegular>
      )}
      <div
        ref={(e) => {
          if (e && e.clientHeight) setInputHeight(e.clientHeight);
        }}
        className={styles["category-team-selection-input"]}
        style={{ padding: selectedTeams.length > 0 ? "12px" : "0px 12px" }}
        onClick={handleToggle}
      >
        <div className={styles["selected-teams-container"]}>
          {selectedTeams.length > 0 ? (
            <Fragment>
              {selectedTeams.map((selectedTeam) => {
                return (
                  <div
                    key={selectedTeam.id}
                    className={styles["selected-team"]}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <JackIcons
                      name={
                        selectedTeam.id === "anyone"
                          ? "lock-open"
                          : "pricetags-outline"
                      }
                      fill={selectedTeam.color ?? jackColors.neutral800}
                      style={{ scale: "0.6" }}
                    />
                    <GothamRegular
                      woFontColor
                      className="font12"
                      style={{
                        color: selectedTeam.color,
                        maxWidth: "100px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {selectedTeam?.name}
                    </GothamRegular>
                    <JackIcons
                      name="close-outline"
                      fill={jackColors.neutral700}
                      style={{ scale: "0.8" }}
                      onClick={() => onSelect(selectedTeam?.id)}
                    />
                  </div>
                );
              })}
            </Fragment>
          ) : (
            <GothamRegular woFontColor style={{ color: jackColors.neutral600 }}>
              {t("Select team")}
            </GothamRegular>
          )}
        </div>

        <JackIcons name="chevron-down" fill={jackColors.neutral600} />

        <CategoryTeamSelectionDropdown
          isOpen={isOpen}
          search={search}
          teams={filteredTeams}
          selectedTeams={selectedTeams}
          style={{ top: dropdownOffsetTop }}
          onSelect={onSelect}
          setSearch={setSearch}
          t={t}
        />
      </div>
    </div>
  );
};

export default CategoryTeamSelection;
