import React, { useState } from "react";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../Text";
import { ButtonJack } from "../ButtonsJack/parent";

export const ListMenuItem = ({
  array,
  handleClick,
  woButton = false,
  onSelected,
  activeTemplate,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [active, setActive] = useState(activeTemplate);

  return (
    <>
      {array?.map((item, index) => {
        const { title, desc, icon, value } = item || {};
        const isFirst = index === 0;
        const isActive = value === active;
        const isActiveHover = isHover === index;
        const activeColor = isActive
          ? jackColors.neutral900
          : jackColors.neutral600;
        return (
          <div
            key={index}
            className="d-flex align-items-center justify-content-between"
            style={{
              border: `1px solid ${
                isActive ? jackColors.black34 : jackColors.neutral500
              }`,
              borderRadius: 4,
              cursor: "pointer",
              padding: 12,
              height: 80,
              width: 404,
              marginTop: !isFirst && 16,
              backgroundColor: "white",
              transition: "all 0.4s ease",
              backgroundColor: isActiveHover ? "rgba(0, 0, 0, 0.04)" : "white",
            }}
            onMouseEnter={() => setIsHover(index)}
            onMouseLeave={() => setIsHover(false)}
            onClick={() => {
              onSelected(value);
              setActive(value);
            }}
          >
            <div className="d-flex align-items-center">
              <JackIcons
                style={{ width: 40, height: 40 }}
                styleDiv={{
                  borderRadius: 8,
                  marginRight: 8,
                }}
                name={icon}
                fill={activeColor}
              />
              <div style={{ paddingRight: 12 }}>
                <GothamMedium
                  style={{
                    color: isActive
                      ? jackColors.neutral900
                      : jackColors.neutral800,
                    lineHeight: "20px",
                    marginBottom: 4,
                  }}
                >
                  {title}
                </GothamMedium>
                <GothamRegular
                  className="font12"
                  style={{ color: jackColors.neutral800, lineHeight: "16px" }}
                >
                  {desc}
                </GothamRegular>
              </div>
            </div>
            <JackIcons
              name={isActive ? "checkmark-circle-2" : "uncheck-circle"}
              fill={activeColor}
              style={{ width: 24, height: 24 }}
            />
          </div>
        );
      })}
      {!woButton && (
        <div
          className="d-flex justify-content-end"
          style={{ gap: 16, marginTop: 32 }}
        >
          <ButtonJack
            type="outline"
            children="Back"
            style={{ minWidth: 65 }}
            onClick={() => handleClick({ type: "back" })}
            leftIcon={
              <JackIcons
                name="arrow-back-outline"
                fill="black"
                style={{ width: 20, height: 20 }}
              />
            }
          />
          <ButtonJack
            disabled={!active}
            type="filled"
            children="Next"
            style={{ minWidth: 60 }}
            onClick={() => handleClick({ type: "next", value: active })}
            rightIcon={
              <JackIcons
                style={{ width: 20, height: 20 }}
                name="arrow-forward"
                fill={!active ? jackColors.neutral600 : "black"}
              />
            }
          />
        </div>
      )}
    </>
  );
};
