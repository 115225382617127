import Skeleton from "react-loading-skeleton";
import { TransactionsFilters } from "../../transactionsComponents/transactions/filters/parent";
import { useTransactionFiltersArray } from "../../transactionsComponents/transactions/logics/filterArray";
import { useTranslation } from "react-i18next";

export const SkeletonTd = () => (
  <td>
    <Skeleton width={"90%"} />
  </td>
);

export const DraftSearchAndFilter = () => {
  const activeFilters = ["date"];
  const filters = useTransactionFiltersArray("mine");
  const containerStyle = {
    margin: "24px 0",
  };
  const { t } = useTranslation("draft/draft");
  return (
    <div style={containerStyle}>
      <TransactionsFilters
        searchPlaceholder={t("Search keywords")}
        activeFilters={activeFilters}
        filters={filters}
        searchQueryName="search_draft"
        woAdd={true}
      />
    </div>
  );
};
