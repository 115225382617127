import { isEmpty } from "lodash";
import { unformatCurrency } from "../../../../components/tools";
import {
  createtaxDescription,
  totalAmountOnFields,
} from "../../../../pageComponents/invoiceComponents/createPage/modals/formLogic";

// const totalAmountDetails = {
//   transaction_items: formatItems,
//   transaction_taxes: formatTaxes,
//   item_subtotal: subTotal,
//   tax_total: tax,
//   amount: Number(subTotal) + Number(tax),
//   fee,
//   finalAmount: Number(subTotal) + Number(tax) + Number(fee),
// };

const formatTax = (taxes) =>
  taxes?.map((tax) => {
    const { fee_tax_name, amount, add_or_deduct } = tax;
    const isMinus = add_or_deduct == "deduct";
    const tax_description = createtaxDescription(tax);
    const tax_value = isMinus ? amount * -1 : amount;
    return { tax_value, fee_tax_name, tax_description };
  });

export const createInvoiceSummary = (details) => {
  if (isEmpty(details)) return {};

  const {
    transaction_items,
    transaction_taxes,
    tax,
    amount,
    total_amount,
    final_amount_with_handling_fee: finalAmount,
    fee,
  } = details;

  const formatTaxes = transaction_taxes?.map((tax) => {
    const { fee_tax_name, amount, add_or_deduct } = tax;
    const isMinus = add_or_deduct == "deduct";
    const tax_description = createtaxDescription(tax);
    const tax_value = isMinus ? amount * -1 : amount;
    return { tax_value, fee_tax_name, tax_description };
  });

  const subtotal = Number(unformatCurrency(amount));
  const itemsSubtotal = Number(
    unformatCurrency(totalAmountOnFields(transaction_items))
  );

  const isMissmatch = subtotal != itemsSubtotal;

  const feeCalc = finalAmount - total_amount;
  const feeDecider = fee || feeCalc;
  const totalAmountDetails = {
    transaction_items,
    transaction_taxes: formatTaxes,
    item_subtotal: amount,
    tax_total: Number(tax),
    amount: total_amount,
    fee: feeDecider,
    finalAmount: finalAmount,
    isMissmatch,
  };

  return totalAmountDetails;
};

export const createReimbursementChildSummary = (details) => {
  if (isEmpty(details)) return {};

  const {
    transaction_items,
    transaction_taxes,
    tax,
    amount,
    total_amount,
    fee,
  } = details;

  const formatTaxes = formatTax(transaction_taxes);
  const subTotal = Number(unformatCurrency(amount));
  const itemsSubtotal = Number(
    unformatCurrency(totalAmountOnFields(transaction_items))
  );
  const totalAmountWithFee = total_amount + fee;
  const finalAmount = Number(unformatCurrency(totalAmountWithFee));
  const isMissmatch = subTotal != itemsSubtotal;

  const totalAmountDetails = {
    transaction_items,
    transaction_taxes: formatTaxes,
    item_subtotal: subTotal,
    tax_total: Number(tax),
    amount: total_amount,
    fee,
    finalAmount: finalAmount,
    isMissmatch,
  };

  return totalAmountDetails;
};
