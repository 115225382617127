import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { TextFieldJack } from "../../../../../components/inputs/textfield";
import { useModalHook } from "../../../../../components/Modals";
import {
  GothamRegular,
  TextInlineMedium,
} from "../../../../../components/Text";
import {
  formatCurrency,
  unformatCurrency,
} from "../../../../../components/tools";
import { fetch } from "../../../../../tools/api";
import {
  getTotalAmountSummary,
  idrStringFormatter,
} from "../../../data/formatter";
import {
  calculateTaxDetails,
  createtaxDescription,
  totalAmountOnFields,
} from "../formLogic";
import {
  AddButton,
  LineItemsHeader,
  LineItemsTable,
  MismatchSubtotalToolTip,
  MismatchSubtotalToolTipV2,
  PrevNextButtons,
  VendorBankSelection,
} from "./component";
import { useLineItemPagination } from "../formLogic";
import { useClickOutside } from "../../../../../universalFunctions/useClickOutside";
import { useModalHookData } from "../../../../scheduledPaymentComponents/dashboardComponent/hooks";
import { confirmIcon } from "../../../../reimbursementComponents/data/constant";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { useTranslation } from "react-i18next";
import dynamic from "next/dynamic";

const AddBankRightModal = dynamic(() =>
  import("./component").then((mod) => mod.AddBankRightModal)
);
const AddFeesTaxModal = dynamic(() =>
  import("./modalForm").then((mod) => mod.AddFeesTaxModal)
);
const EditFeesTaxModal = dynamic(() =>
  import("./modalForm").then((mod) => mod.EditFeesTaxModal)
);
const ConfirmationModalJack = dynamic(() =>
  import("../../../../../components/ButtonsJack/confirmation").then(
    (mod) => mod.ConfirmationModalJack
  )
);
const AddFirstVendorBank = dynamic(() =>
  import("./component").then((mod) => mod.AddFirstVendorBank)
);

export const LineItems = ({
  useFormObj,
  name,
  totalName,
  isSubmitted = true,
  ignoreEmptyValue = true,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { isOpen, toggle } = useModalHook(true);
  const { t } = useTranslation("invoice-payment/invoice-payment");

  const { control, watch, register, setValue } = useFormObj;

  const { fields, append } = useFieldArray({
    control,
    name,
  });

  const handleDeleteRow = (id) => {
    const newVal = currValue;
    newVal?.splice(id, 1);

    setValue(name, newVal);
    afterDelete();
  };

  const currValue = watch(name);

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...currValue[index],
    };
  });
  const emptyObj = {
    item_name: "",
    price_per_item: "",
    item_quantity: "",
    total_price: "",
  };

  const currTotalAmount = totalAmountOnFields(currValue);

  useEffect(() => {
    if (isEmpty(currValue) && !ignoreEmptyValue) return;
    if (isFirstRender) return setIsFirstRender(false);
    setValue(totalName, currTotalAmount);
  }, [currTotalAmount]);

  const {
    shownIndex,
    setPage,
    handleNext,
    handlePrev,
    page,
    canClickNext,
    canClickPrev,
    handleAddNewItems,
    afterDelete,
  } = useLineItemPagination({ fields: controlledFields });

  const handleAddRow = () => {
    append(emptyObj);
    const newItemIndex = shownIndex?.length;
    handleAddNewItems();
    const newItemPage = Math.ceil((newItemIndex + 1) / 5);
    // if (newItemPage != page) return setPage(newItemPage);
  };

  const subtotalVal = Number(unformatCurrency(watch(totalName)));
  const lineItemsSubtotalInt = Number(unformatCurrency(currTotalAmount));

  const isMissmatch = subtotalVal != lineItemsSubtotalInt;
  const Tooltip = isSubmitted
    ? MismatchSubtotalToolTip
    : MismatchSubtotalToolTipV2;

  return (
    <div
      style={{
        borderRadius: 8,
        border: "1px solid var(--neutral-500, #E6E6E8)",
        height: isOpen ? "auto" : 40,
        overflow: "hidden",
        width: "100%",
        padding: "0px 8px",
      }}
    >
      {/* header */}
      <LineItemsHeader
        isOpen={isOpen}
        toggle={toggle}
        title={t("Items Subtotal")}
        icon="shopping_cart_outline"
      />

      {/* table */}
      <LineItemsTable
        useFormObj={useFormObj}
        currValue={fields}
        parentName={name}
        handleDelete={handleDeleteRow}
        shownIndex={shownIndex}
      />

      {/* button */}
      <div
        style={{
          height: 48,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <AddButton onClickAdd={handleAddRow} />
      </div>

      {/* footer */}
      <div
        style={{
          width: "100%",
          minHeight: 48,
          height: "auto",
          borderTop: "1px dashed #e6e6e8",
          padding: "8px 12px 8px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* prev next button */}
        <PrevNextButtons
          onClickNext={handleNext}
          onClickPrev={handlePrev}
          canNext={canClickNext}
          canPrev={canClickPrev}
        />

        {/* item subtotal text and input */}
        <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
          <div className="d-flex" style={{ gap: 8, alignItems: "center" }}>
            <Tooltip isMissmatch={isMissmatch} />{" "}
            <GothamRegular className="font12">
              {t("Items Subtotal")}
            </GothamRegular>
          </div>
          <TextFieldJack
            useFormObj={useFormObj}
            name={totalName}
            style={{
              width: 168,
              minHeight: 32,
              margin: 0,
              padding: 0,
            }}
            woLabel
            textFieldStyle={{
              height: 32,
              margin: 0,
              padding: "0px 4px",
              position: "relative",
            }}
            textFieldInputStyle={{
              height: 32,
              margin: 0,
              padding: 0,
              position: "absolute",
              top: 0,
            }}
            woFormatError
            onChange={(e) => {
              const value = e?.target?.value;
              const newValue = `IDR ${formatCurrency(value)}`;

              return setValue(totalName, newValue);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const TaxItem = ({
  tax,
  itemSubtotal,
  useFormObj,
  name,
  taxAmount,
  showRow,
  index,
  handleDelete,
  openEdit,
}) => {
  const { register, setValue, watch } = useFormObj;
  const { t } = useTranslation("invoice-payment/invoice-payment");
  const { t: tCommon } = useTranslation("common");
  const destroyName = name + "._destroy";
  const hideRow = watch(destroyName);

  const {
    id,
    fee_tax_name,
    fee_tax_value,
    fee_tax_element,
    add_or_deduct,
    fee_tax_type,
    calculate_from,
    _destroy,
  } = tax;
  const isAdd = add_or_deduct == "add";
  const isDeduct = add_or_deduct == "deduct";
  const isPercentage = fee_tax_element == "percentage";
  const taxInt = isPercentage
    ? unformatCurrency(itemSubtotal) * (Number(fee_tax_value) / 100)
    : Number(fee_tax_value);

  const positiveAmount = isDeduct ? taxAmount * -1 : taxAmount;
  const idrTax = idrStringFormatter(positiveAmount);

  const divStyle = {
    display: "flex",
    height: "inherit",
    padding: "0px 8px",
    alignItems: "center",
  };

  const taxDescription = createtaxDescription(tax);

  const handleDeleteRow = (id, index) => {
    if (id) {
      register(destroyName);
      setValue(destroyName, true);
      return;
    }

    return handleDelete(index);
  };

  useEffect(() => {
    // register(name);
    const taxVal = {
      id,
      fee_tax_name,
      fee_tax_value,
      fee_tax_element,
      fee_tax_type,
      add_or_deduct,
      calculate_from,
      _destroy,
      taxAmount,
    };

    const taxValKeys = Object.keys(taxVal);
    taxValKeys?.forEach((key) => {
      const propertyKey = `${name}.${key}`;
      register(propertyKey);
      setValue(propertyKey, taxVal[key]);
    });
  }, []);

  useEffect(() => {
    const taxAmountkey = `${name}.taxAmount`;
    setValue(taxAmountkey, taxAmount);
  }, [taxAmount]);

  const {
    isOpen: isOpenDelete,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModalHook();

  return (
    <div
      style={{
        width: "100%",
        height: 48,
        display: showRow && !hideRow ? "flex" : "none",
        alignItems: "center",
      }}
    >
      <div
        style={{
          ...divStyle,
          width: "60%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <GothamRegular className="font12">{fee_tax_name}</GothamRegular>
        <GothamRegular className="font10" style={{ color: "#909098" }}>
          {taxDescription}
        </GothamRegular>
      </div>
      <div
        style={{
          ...divStyle,
          width: "34%",
          gap: 4,
          justifyContent: "end",
        }}
      >
        <JackIcons
          name={isAdd ? "plus" : "minus"}
          fill={isAdd ? "#238730" : "#DC2F44"}
          style={{ width: 12, height: 12 }}
        />
        <GothamRegular className="font12">{idrTax}</GothamRegular>
      </div>
      <ThreeDotsTax
        containerStyle={divStyle}
        handleDelete={openDeleteModal}
        handleEdit={() => openEdit({ ...tax, rowkey: name })}
        id={index}
      />
      <ConfirmationModalJack
        modal={isOpenDelete}
        img={confirmIcon.tongSampah}
        buttonTextLeft={tCommon("Cancel")}
        buttonTextRight={tCommon("Delete")}
        title={t("Are you sure to delete this fee/tax?")}
        text={t("Calculation in this fee/tax will be erased permanently.")}
        onClick={() => handleDeleteRow(id, index)}
        toggle={closeDeleteModal}
      />
    </div>
  );
};

const ThreeDotsTax = ({ containerStyle, handleDelete, handleEdit, index }) => {
  const { isOpen, toggle, close } = useModalHook(false);
  const { t: tCommon } = useTranslation("common");
  const ref = useRef();
  useClickOutside({
    ref,
    clickOutside: () => {
      close();
    },
  });

  const optionStyle = {
    display: "flex",
    height: 40,
    padding: "0px 8px",
    gap: 22,
    alignItems: "center",
  };
  return (
    <div
      id={index}
      ref={ref}
      style={{
        ...containerStyle,
        width: "6%",
        position: "relative",
      }}
    >
      <JackIcons
        name="more_horizontal_outline"
        fill="#BBBBC0"
        onClick={(e) => {
          e?.stopPropagation();
          toggle();
        }}
      />
      {isOpen && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
            boxShadow:
              " 0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 3px 6px -3px rgba(23, 24, 24, 0.08)",
            borderRadius: 8,
            width: 161,
            height: "auto",
            top: -48,
            right: 24,
            zIndex: 10,
            padding: "8px 0px",
          }}
        >
          <div
            style={optionStyle}
            onClick={(e) => {
              e.stopPropagation();
              handleEdit();
            }}
            className="darkhover"
          >
            <JackIcons name="edit_outline" fill="#343434" />
            <GothamRegular>{tCommon("Edit")}</GothamRegular>
          </div>
          <div
            style={optionStyle}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
            className="darkhover"
          >
            <JackIcons name="delete" fill="#DC2F44" />
            <GothamRegular>{tCommon("Delete")}</GothamRegular>
          </div>
        </div>
      )}
    </div>
  );
};

export const TaxLineItems = ({
  useFormObj,
  name,
  totalName,
  isReimbursement = false,
}) => {
  const { isOpen, toggle } = useModalHook(true);
  const { isOpen: isOpenAdd, toggle: toggleAdd } = useModalHook();
  const { t } = useTranslation("invoice-payment/invoice-payment");

  const { control, watch, register, setValue, getValues } = useFormObj;
  const { fields, append, remove, swap } = useFieldArray({ control, name });

  const currValue = watch(name);
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...currValue[index],
    };
  });
  const itemSubtotal = watch("amount");

  const taxSourceOptions = useMemo(() => {
    return [
      {
        label: "Item Subtotal",
        value: { name: "items_subtotal", is_custom: false },
      },
      ...controlledFields
        ?.filter(({ _destroy }) => !_destroy)
        .map(({ fee_tax_name, fee_tax_type }) => {
          const is_custom = fee_tax_type == "custom";
          return {
            label: fee_tax_name,
            value: { name: fee_tax_name, is_custom },
          };
        }),
    ];
  }, [controlledFields]);

  const woDestroyFields =
    controlledFields?.filter(({ _destroy }) => !_destroy) || [];

  const calculateTotalTax = useMemo(
    () =>
      getTotalAmountSummary(woDestroyFields, "taxAmount")?.totalAmountString,
    [woDestroyFields]
  );
  const taxAmountDetails = calculateTaxDetails(controlledFields, itemSubtotal);

  useEffect(() => {
    setValue(totalName, calculateTotalTax);
  }, [calculateTotalTax]);

  const {
    shownIndex,
    page,
    setPage,
    handleNext,
    handlePrev,
    canClickNext,
    canClickPrev,
  } = useLineItemPagination({ fieldsLength: fields?.length, fields });

  // useEffect(() => {
  //   register(name);
  // }, [register, name]);

  const handleDelete = (id) => {
    const newVal = currValue;
    newVal?.splice(id, 1);

    const names = [
      "fee_tax_element",
      "fee_tax_value",
      "add_or_deduct",
      "fee_tax_name",
      "id",
    ];
    newVal.forEach((_, index) => {
      const rowName = `${name}.${index}`;
      register(rowName);
      names?.forEach((name) => register(rowName + `.${name}`));
    });
    setValue(name, newVal);
  };

  const {
    isOpen: isEditOpen,
    close: closeEdit,
    open: openEdit,
    data: editData,
  } = useModalHookData();

  const { errorSnackBar, successSnackBar } = ToasterHook();
  return (
    <div
      style={{
        borderRadius: 8,
        border: "1px solid var(--neutral-500, #E6E6E8)",
        height: isOpen ? "auto" : 40,
        overflow: "hidden",
        minHeight: 40,
        width: "100%",
        padding: "0px 8px",
      }}
    >
      <LineItemsHeader
        icon="discount"
        title={t("Taxes & Other Fees")}
        isOpen={isOpen}
        toggle={toggle}
      />

      {/* Tax items */}
      <div style={{ borderTop: "1px solid #e6e6e8", width: "100%" }}>
        {controlledFields?.map((tax, index) => {
          const taxName = tax?.fee_tax_name;
          const taxAmount = taxAmountDetails
            ?.filter(({ name: arrName }) => arrName == taxName)
            ?.pop()?.value;
          register(`${name}.${index}`);
          const showRow = shownIndex?.includes(index);
          return (
            <TaxItem
              index={index}
              itemSubtotal={itemSubtotal}
              tax={tax}
              useFormObj={useFormObj}
              name={`${name}.${index}`}
              taxAmount={taxAmount}
              showRow={showRow}
              handleDelete={handleDelete}
              openEdit={openEdit}
            />
          );
        })}
      </div>

      {/* button */}
      <div
        style={{
          height: 48,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <AddButton
          buttonText={
            fields?.length == 0 ? t("Add Taxes & Fees") : t("Add item")
          }
          onClickAdd={toggleAdd}
        />
      </div>

      {/* footer */}
      <div
        style={{
          width: "100%",
          height: 48,
          borderTop: "1px dashed #e6e6e8",
          padding: "8px 12px 8px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* prev next button */}
        <PrevNextButtons
          onClickNext={handleNext}
          onClickPrev={handlePrev}
          canNext={canClickNext}
          canPrev={canClickPrev}
        />

        {/* item subtotal text and input */}
        <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
          <GothamRegular className="font12">
            {t("Taxes & Other Fees")}
          </GothamRegular>
          {/* <div
                style={{
                  width: 168,
                  height: 32,
                  borderRadius: 4,
                  border: "1px solid #e6e6e8",
                }}
              ></div> */}
          <div style={{ position: "relative" }}>
            <TextFieldJack
              useFormObj={useFormObj}
              name={totalName}
              style={{
                width: 168,
                height: 32,
                margin: 0,
                padding: 0,
              }}
              woLabel
              textFieldStyle={{
                height: 32,
                margin: 0,
                padding: "0px 4px",
                position: "relative",
              }}
              textFieldInputStyle={{
                height: 32,
                margin: 0,
                padding: 0,
                position: "absolute",
                top: 0,
              }}
            />

            {/* this div prevent textfield from being updated by the user. because textfield disabled not work properly */}
            <div
              style={{
                width: 168,
                height: 32,
                backgroundColor: "transparent",
                zIndex: 4,
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </div>
        </div>
      </div>

      {/* modals */}
      <AddFeesTaxModal
        isOpen={isOpenAdd}
        toggle={toggleAdd}
        handleApply={(payload) => {
          const { fee_tax_name: newTaxName } = payload;
          const isAlreadyExist = controlledFields?.some(
            ({ fee_tax_name }) => fee_tax_name == newTaxName
          );

          // isAlreadyExist
          //   ? errorSnackBar({ msg: `${newTaxName} already exists` })
          //   : append(payload);

          toggleAdd();

          if (isAlreadyExist)
            return errorSnackBar({
              msg: `${newTaxName} ${t("already exists.")}`,
            });

          append(payload);
          successSnackBar({
            msg: (
              <GothamRegular>
                <TextInlineMedium>{newTaxName}</TextInlineMedium>{" "}
                {t("has been added!")}
              </GothamRegular>
            ),
            showClose: true,
          });
        }}
        taxSourceOptions={taxSourceOptions}
        itemSubTotal={itemSubtotal}
        isReimbursement={isReimbursement}
      />

      <EditFeesTaxModal
        defaultValue={editData}
        isOpen={isEditOpen}
        toggle={closeEdit}
        taxSourceOptions={taxSourceOptions}
        itemSubTotal={itemSubtotal}
        handleApply={(val) => {
          const { rowKey, ...rest } = val;

          const newValue = { ...rest, _destroy: false, isEdited: true };
          const keys = Object.keys(newValue);
          keys?.forEach((key) => {
            const propertykey = `${rowKey}.${key}`;
            register(propertykey);
            setValue(propertykey, newValue[key]);
          });

          closeEdit();
        }}
        isReimbursement={isReimbursement}
      />
    </div>
  );
};

export const RecipientInput = ({ useFormObj, name }) => {
  const { register, watch, setValue, errors } = useFormObj;
  const recipientId = watch(name);
  const setRecipientId = (id) => setValue(name, id);
  const {
    data: recipientData,
    loading,
    refetch,
  } = fetch({ url: "/recipient_details", formatter: ({ data }) => data });

  const handleSaveSelect = (id) => {
    setRecipientId(id);
    refetch();
  };

  const recipientDataEmpty = isEmpty(recipientData);

  const { isOpen: isOpenAddBank, toggle: toggleAddBank } = useModalHook();

  useEffect(() => {
    register({ name });
  }, []);

  const isError = errors[name];

  if (loading)
    return <Skeleton style={{ height: 72, width: "100%", borderRadius: 4 }} />;

  if (recipientDataEmpty) {
    return (
      <>
        <AddFirstVendorBank onClick={toggleAddBank} isError={isError} />
        <AddBankRightModal
          isOpen={isOpenAddBank}
          toggle={toggleAddBank}
          afterSuccessAdd={handleSaveSelect}
        />
      </>
    );
  }
  return (
    <VendorBankSelection
      isError={isError}
      recipientData={recipientData}
      selectedId={recipientId}
      refetchSelections={refetch}
      handleSelect={(id) => setRecipientId(id)}
    />
  );
};
