import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";

const DefaultButton = ({ backgroundColor, style }) => (
  <div
    style={{
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor,
      position: "absolute",
      left: "calc(50% - 4px)",
      top: 7,
      ...style,
    }}
  />
);

const DotDecider = ({
  isActive,
  isCurrentUpdate,
  isSteps,
  isRejected,
  isCompleted,
  isSuccess,
  isPartialFailed,
  isFailed,
  isUserLog,
  isRetry,
}) => {
  if (isRetry)
    return (
      <JackIcons
        name="retry-dot-tracker"
        fill="#343434"
        style={{ position: "absolute", top: 0, translate: "1px 0px" }}
      />
    );
  if (isFailed)
    return (
      <JackIcons
        name="rejected-dots-tracker"
        fill="#E73126"
        style={{ position: "absolute", top: 0, translate: "1px 0px" }}
      />
    );
  if (isPartialFailed)
    return (
      <JackIcons
        name="active-dot-tracker"
        fill="#9AD7D0"
        style={{ position: "absolute", top: 0, translate: "1px 0px" }}
      />
    );
  if (isSuccess)
    return (
      <JackIcons
        name="active-dot-tracker"
        fill="#06AC4E"
        style={{ position: "absolute", top: 0, translate: "1px 0px" }}
      />
    );
  if (isRejected)
    return (
      <JackIcons
        name="rejected-dots-tracker"
        fill="#E73126"
        style={{ position: "absolute", top: 0, translate: "1px 0px" }}
      />
    );
  if (isSteps)
    return (
      <JackIcons
        name="three-dots-tracker"
        fill="#343434"
        style={{ position: "absolute", top: 0, translate: "1px 0px" }}
      />
    );
  if (isCompleted)
    return (
      <JackIcons
        name="active-dot-tracker"
        fill="#06AC4E"
        style={{ position: "absolute", top: 0, translate: "1px 0px" }}
      />
    );
  if (isCurrentUpdate) {
    if (isUserLog) {
      return (
        <JackIcons
          name="active-dot-tracker"
          fill="#343434"
          style={{ position: "absolute", top: 0, translate: "1px 0px" }}
        />
      );
    }
    return (
      <JackIcons
        name="current-dot-tracker"
        fill="#B9FC00"
        style={{ position: "absolute", top: 0, translate: "1px 0px" }}
      />
    );
  }
  if (isActive) {
    if (isUserLog) {
      return <DefaultButton backgroundColor={jackColors.neutral600} />;
    }
    return (
      <JackIcons
        name="active-dot-tracker"
        fill="#343434"
        style={{ position: "absolute", top: 0, translate: "1px 0px" }}
      />
    );
  }
  return <DefaultButton backgroundColor={jackColors.neutral600} />;
};

const Line = ({
  isLast = false,
  isFirst = false,
  isSteps = false,
  isNoLine = false,
  isActive = false,
  isRejected = false,
  isCurrentUpdate = false,
  isCompleted = false,
  isSuccess = false,
  isPartialFailed = false,
  isFailed = false,
  isUserLog = false,
  isRetry = false,
}) => {
  const height = "calc(100% + 16px)";

  const DefaultLine = ({ className = "", lineHeight = 0, style = {} }) => {
    const dotProps = {
      isActive,
      isCurrentUpdate,
      isSteps,
      isRejected,
      isCompleted,
      isSuccess,
      isPartialFailed,
      isFailed,
      isUserLog,
      isRetry,
    };

    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            width: 36,
            position: "absolute",
            height,
          }}
          className={`d-flex justify-content-center ${className}`}
        >
          <DotDecider {...dotProps} />
          <div
            style={{
              width: 2,
              backgroundColor: "#d9d9d9",
              height: lineHeight,
              ...style,
              visibility: isNoLine ? "hidden" : "visible",
            }}
          />
        </div>
        <div style={{ width: 36 }}></div>
      </div>
    );
  };

  if (isFirst)
    return (
      <DefaultLine
        className="align-items-end"
        lineHeight={"calc(100% - 8px)"}
      />
    );

  if (isLast) return <DefaultLine />;

  return <DefaultLine lineHeight={"calc(100% + 14px)"} />;
};

export default Line;
