import { useRef, useState } from "react";
import ViewportList from "react-viewport-list";
import { colors, jackColors } from "../../../../../assets/colors";
import checkSquareIcon from "../../../../../assets/images/check-square-icon.svg";
import emptySquareIcon from "../../../../../assets/images/empty-square-icon.svg";
import trashIcon from "../../../../../assets/images/trash-teal-clean.svg";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { EmptyScreenSearch } from "../../../../../components/EmptyScreen";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import {
  arraySplitter,
  windowDimension,
} from "../../../../../components/tools";
import { useTranslation } from "react-i18next";

export const PayrollConfirmationTable = ({
  headerArr: headerArrProps,
  itemArr: itemArrProps,
  isEmptyData,
  sticky = 1,
  page = 1,
  selectionTable,
  isDelete,
  onClick,
  isLocalTransfer = false,
}) => {
  const [hoverIndex, setHoverIndex] = useState(false);
  const {
    isAllChecked,
    handleClickItem,
    selectedIds,
    handleRemoveAllId,
    handleClickAll,
  } = selectionTable || {};
  const { t } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const isSelected = !!selectedIds.length;

  let stickyArray = [];
  let defaultArray = [];

  headerArrProps.forEach((item, index) => {
    if (sticky - 1 == index) return stickyArray.push(item);
    return defaultArray.push(item);
  });

  const isOnlyOneData = itemArrProps.length === 1;

  // stickies
  const deleteObj = selectionTable
    ? {
        label: (
          <JackIcons
            name={isAllChecked ? "checkmark-square-2" : "square"}
            fill={isAllChecked ? jackColors.neutral900 : jackColors.neutral600}
            style={{
              width: 20,
              height: 20,
              cursor: "pointer",
              visibility: isSelected ? "initial" : "hidden",
              position: "absolute",
            }}
            onClick={() => {
              if (!isSelected) return;
              if (isAllChecked) return handleRemoveAllId();
              handleClickAll();
            }}
          />
        ),
        value: "table_delete",
        width: 48,
      }
    : {};
  const actionObj = {
    label: "Action",
    value: isOnlyOneData ? "-" : "table_action",
    width: 48,
  };
  const numberObj =
    selectionTable && isDelete
      ? {}
      : { label: "No", value: "table_number", width: 48 };
  // stickies

  const isNoSticky = headerArrProps.length <= 4;

  const arrays = arraySplitter(defaultArray, 4)[page - 1];

  const headerArr = [
    deleteObj,
    // numberObj,
    ...stickyArray,
    ...arrays,
    actionObj,
  ].filter(({ value }) => value);

  const itemArr = itemArrProps.map((item, index) => {
    const { id } = item || {};

    const table_number = index + 1;

    const table_action = (
      <div
        className="d-flex justify-content-center"
        style={{ cursor: "pointer" }}
      >
        <JackIcons
          name="delete-outline"
          fill={jackColors.neutral700}
          style={{ width: 20, height: 20 }}
          onClick={() => onClick({ type: "delete", item })}
        />
      </div>
    );

    const isActive = selectedIds.includes(id);

    const table_delete = (
      <JackIcons
        name={isActive ? "checkmark-square-2" : "square"}
        fill={isActive ? jackColors.neutral900 : jackColors.neutral600}
        style={{ width: 20, height: 20, cursor: "pointer" }}
        onClick={() => handleClickItem(id, isActive)}
      />
    );

    return { ...item, table_number, table_action, table_delete };
  });

  const bodyArr = itemArr.map((obj) => {
    const headerArrValues = headerArr.map(({ value }) => value);
    const result = headerArrValues.map((name) => {
      if (name == "notes" && obj?.notes == null) return "";
      return obj[name];
    });
    return result || "";
  });
  const ref = useRef();

  const { screenRealHeight } = windowDimension();

  if (isEmptyData) return null;

  return (
    <div
      style={
        {
          // height: screenRealHeight,
          // paddingBottom: 300,
          // overflowY: "scroll",
        }
      }
    >
      <table
        className="w-100 mb-0"
        style={{
          minWidth: 1040,
          // overflow: "scroll",
          position: "relative",
          tableLayout: "fixed",
          wordBreak: "break-word",
          border: `1px solid ${jackColors.neutral500}`,
          borderCollapse: "separate",
          backgroundColor: "white",
          padding: "8px 8px 0px 8px",
          borderRadius: 8,
          marginBottom: 32,
        }}
        cellSpacing={0}
      >
        <thead
          style={{
            // position: "sticky",
            // top: 0,
            backgroundColor: "white",
            zIndex: 40,
          }}
        >
          <tr>
            {headerArr.map((item, index) => {
              const { label, width } = item || {};
              const isAction = label === "Action";
              const isAmount = label === "Amount";
              const isNumber = label === "No";
              const isName = label === "Name";
              const isNotes = label === "Notes";
              const isDepartment = label === "Department";
              const isBank = label === "Bank";

              const MaskingHeader = (label) => {
                const isString = typeof label === "string";
                if (isAction || isNumber) return "";

                if (!isLocalTransfer) {
                  if (isName) return t("Employee");
                  if (isBank) return t("Bank Account");
                }
                if (isNotes) return t("Transfer Note");
                if (isBank) return t("Bank Account");
                if (isAmount)
                  return isLocalTransfer
                    ? tLocalTransfer("Amount")
                    : t("Amount");
                return isString ? t(label) : label;
              };

              return (
                <th
                  key={index}
                  style={{
                    height: "100%",
                    padding: "12px 8px",
                    width: width ? width : "auto",
                    borderBottom: `1px solid ${jackColors.neutral500}`,
                  }}
                >
                  <GothamRegular
                    className="font12"
                    style={{
                      color: jackColors.neutral700,
                      textAlign: isAmount ? "end" : isNumber && "center",
                    }}
                  >
                    {MaskingHeader(label)}
                  </GothamRegular>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody ref={ref}>
          <ViewportList
            viewportRef={ref}
            items={bodyArr}
            itemMinSize={100}
            margin={8}
            overscan={10}
          >
            {(array, idx) => {
              const isHovered = hoverIndex === idx;
              const bgColorDecider = () => {
                if (isHovered) return jackColors.neutral500;
              };
              return (
                <tr
                  key={idx}
                  onMouseEnter={() => setHoverIndex(idx)}
                  onMouseLeave={() => setHoverIndex(false)}
                  style={{
                    transition: "all 300ms",
                    backgroundColor: bgColorDecider(),
                    height: 64,
                  }}
                >
                  {array.map((item, index) => {
                    const isFirst = idx === 0;
                    const isLast = array.length - 1 == index;
                    {
                      /* const hideButtonDelete = bodyArr.length == 1 && index == 5; */
                    }
                    const isSection = index == sticky;
                    const isNumber = index === (isDelete ? 1 : 0);
                    const isNotCustomComponents =
                      typeof item === "string" || typeof item === "number";
                    const isCheckbox = index === 0;

                    const showButton = isLast || isCheckbox;

                    const MaskingValue = () => {
                      if (showButton && (isHovered || isSelected)) {
                        return item;
                      }
                      if (isHovered && isLast) return item;
                      if (showButton) return "";
                      if (item) return item;
                      return "-";
                    };

                    const styleCustom = {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    };

                    return (
                      <td
                        key={index}
                        style={{
                          height: 64,
                          padding: "12px 8px",
                          borderTop: !isFirst && "1px solid #e6e6e8",
                        }}
                      >
                        {isNotCustomComponents ? (
                          <GothamRegular
                            style={{
                              // display: hideButtonDelete && "none",
                              color: jackColors.neutral900,
                              textAlign: isNumber && "center",
                              ...styleCustom,
                            }}
                          >
                            {MaskingValue()}
                          </GothamRegular>
                        ) : showButton ? (
                          <>{MaskingValue()}</>
                        ) : (
                          item
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            }}
          </ViewportList>
        </tbody>
      </table>
    </div>
  );
};
