import { forwardRef } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { GothamMedium } from "../../../../../components/Text";
import CategoryIcon from "../CategoryIcon";
import SearchDropdown from "../SearchDropdown";
import styles from "./CategoryIconSelectionDropdown.module.css";

const categoryIcons = [
  "category",
  "wallet",
  "bank",
  "savings",
  "briefcase",
  "money",
  "invoice",
  "coin",
  "lock",
  "trendingup",
  "car",
  "building",
  "client",
  "build",
  "book",
  "meals",
  "coffee",
  "cart",
  "medical",
  "education",
  "games",
  "palette",
  "bulb",
  "plane",
  "giftcard",
  "card",
  "withdraw",
  "clock",
  "alert",
  "inventory",
  "monitor",
  "settings",
  "budget",
];

const CategoryIconSelectionDropdown = forwardRef(
  (
    {
      type = "normal",
      isOpen = false,
      selectedIcon = "",
      style = {},
      onClose = () => {},
      onSelect = () => {},
      roleUpAnimationCN = null,
      roleDownAnimationCN = null,
      t = () => {},
    },
    ref
  ) => {
    const isWide = type === "wide";

    const firstRow = isWide
      ? categoryIcons.slice(0, 14)
      : categoryIcons.slice(0, 7);
    const secondRow = isWide
      ? categoryIcons.slice(14, 28)
      : categoryIcons.slice(7, 14);
    const thirdRow = isWide
      ? categoryIcons.slice(28, 35)
      : categoryIcons.slice(14, 21);
    const forthRow = isWide ? [] : categoryIcons.slice(21, 28);
    const fifthRow = isWide ? [] : categoryIcons.slice(28, 35);

    return (
      <SearchDropdown
        woSearch
        ref={ref}
        isOpen={isOpen}
        roleUpAnimationCN={roleUpAnimationCN ?? styles["closed"]}
        roleDownAnimationCN={roleDownAnimationCN ?? styles["opened"]}
        style={style}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles["header"]}>
          <GothamMedium
            className="font12"
            style={{ color: jackColors.neutral900 }}
          >
            {t("Select Icon")}
          </GothamMedium>
          <JackIcons
            name="close"
            fill={jackColors.neutral900}
            style={{ cursor: "pointer", scale: "0.8" }}
            onClick={onClose}
          />
        </div>

        <div className={styles["horizontal-line"]} />

        <div className={styles["icons-container"]}>
          {categoryIcons.map((categoryIcon) => {
            return (
              <div
                key={categoryIcon}
                className={styles["icon-container"]}
                style={{
                  backgroundColor:
                    categoryIcon === selectedIcon && jackColors.neutral500,
                }}
                onClick={() => onSelect(categoryIcon)}
              >
                <CategoryIcon name={categoryIcon} />
              </div>
            );
          })}
        </div>
      </SearchDropdown>
    );
    return <div className={styles["category-icon-selection-dropdown"]}></div>;
  }
);

export default CategoryIconSelectionDropdown;
