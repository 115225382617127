import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { jackColors } from "../../../../../../assets/colors";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { Counter } from "../../../../../../components/Counter";
import { useModalHook } from "../../../../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../../../../components/Text";
import { useDebounce } from "../../../../../../components/tools";
import { useLayout } from "../../../../../../contexts/Layout/parent";
import { useClickOutside } from "../../../../../../universalFunctions/useClickOutside";
import { queryArray } from "../../../../../eWalletComponents/home/main/filter/modal/content/parent";
import LoadingSpinner from "../../../../../../components/Loading";
import { eventsTracker } from "../../../../../../universalFunctions/events";
import { useTranslation } from "react-i18next";

export const transactionFilterLayoutProps = (props) => {
  const {
    icon,
    name,
    label,
    labelPill,
    isModal,
    fetchLoading: loading,
    modalTitle,
    ...rest
  } = props;
  return {
    ...rest,
    queryName: name,
    modalTitle: modalTitle || label,
    buttonText: labelPill || label,
    icon: icon,
    isModal,
    loading,
  };
};

export const TransactionFilterDropdownLayout = ({
  buttonText = "Add Filter",
  modalTitle = "",
  onClick,
  component = (onClickWithToggle) => null,
  icon = "plus-outline",
  isDropdown = true,
  queryName = "",
  listener = () => {},
  counter,
  reset,
  isModal,
  loading,
  modalStyle,
  withModal = true,
  isSmall = false,
  buttonStyle = {},
  disabled = false,
  noCounter = false,
}) => {
  //to make sure document is defined
  if (typeof window === "undefined") return null;
  const { t } = useTranslation("api-history/api-history");
  const { query } = useRouter();
  const queryValue = queryArray(query, queryName);
  //test
  const { isOpen, toggle, close } = useModalHook();
  const ref = useRef();
  useClickOutside({ ref, clickOutside: close });

  useEffect(() => listener(isOpen), [isOpen]);

  const [clientHeight, setClientHeight] = useState(0);

  const handleClick = (value) => {
    onClick(value);
    close();
  };

  const count = noCounter ? null : counter || queryValue.length;

  const rightIconDecider = () => {
    if (loading)
      return (
        <LoadingSpinner
          style={{ width: 16, height: 16, marginRight: 4, marginLeft: 4 }}
        />
      );
    if (count) return <Counter number={count} />;
    if (!isDropdown) return null;
    return (
      <JackIcons
        name="chevron-down"
        fill={jackColors.black34}
        style={{ width: 16, height: 16, marginBottom: 3 }}
      />
    );
  };

  const debouncedIsOpen = useDebounce(isOpen, 10);
  const debouncedDebouncedIsOpen = useDebounce(debouncedIsOpen, 410);

  const padding = 8;
  const modalHeight = clientHeight + 44 + padding * 3;

  const {
    offsetTop = 0,
    offsetLeft = 0,
    clientWidth = 0,
    clientHeight: clientHeightButton = 0,
  } = ref?.current || {};

  const { scrollTop, scrollLeft } = useLayout();

  useEffect(close, [scrollTop, scrollLeft]);

  const modalElement = document.getElementsByClassName("right-modal-content");
  const modalElementWidth = modalElement?.[0]?.clientWidth || 0;
  const modalWidth = modalElementWidth;
  const dropdownStyle = isModal
    ? {
        position: "fixed",
        right: modalWidth - offsetLeft - clientWidth,
        top: offsetTop + clientHeightButton,
      }
    : {
        position: "fixed",
        marginLeft: -scrollLeft + clientWidth - 256,
        marginTop: -scrollTop,
      };

  return (
    <div style={{ position: "relative" }} ref={ref}>
      <ButtonJack
        classNameText="font12"
        onClick={() => {
          if (loading) return;
          if (!isOpen && debouncedDebouncedIsOpen) return;
          eventsTracker("open_filter_dropdown");
          toggle();
        }}
        type="outline"
        style={{
          whiteSpace: "nowrap",
          border: count ? "1px solid black" : "",
          ...buttonStyle,
        }}
        isBullet
        leftIcon={
          <JackIcons
            name={icon}
            fill={jackColors.black34}
            style={{ width: 16, height: 16, marginBottom: 3 }}
          />
        }
        rightIcon={rightIconDecider()}
        isSmall={isSmall}
        disabled={disabled}
      >
        {t(buttonText)}
      </ButtonJack>
      {withModal && (
        <div
          style={{
            width: 256,
            padding,
            boxShadow: "0px 8px 16px -4px rgba(22, 34, 51, 0.08)",
            borderRadius: 8,
            visibility: isOpen ? "initial" : "hidden",
            backgroundColor: "white",
            height: isOpen ? modalHeight : 0,
            transition: isOpen ? "all 0.4s linear" : "",
            overflow: "hidden",
            zIndex: 2000,
            ...dropdownStyle,
            ...(isOpen ? modalStyle : {}),
          }}
        >
          <div>
            <div
              className="d-flex justify-content-between"
              style={{
                minHeight: 44,
                borderBottom: `1px solid ${jackColors.greyE6}`,
                paddingTop: 10,
                padding: 8,
                paddingBottom: 18,
              }}
            >
              <GothamMedium className="font12 " style={{ marginTop: 6 }}>
                {t(modalTitle)}
              </GothamMedium>
              {reset ? (
                <GothamMedium
                  className="font12 hover"
                  onClick={() => {
                    toggle();
                    reset();
                  }}
                  style={{
                    color: jackColors.redE7,
                    textDecoration: "underline",
                    marginTop: 6,
                  }}
                >
                  {t("Clear")}
                </GothamMedium>
              ) : (
                <JackIcons
                  name="close"
                  fill={jackColors.black34}
                  className="hover"
                  onClick={toggle}
                  style={{
                    height: 20,
                    width: 20,
                    marginBottom: 2,
                  }}
                />
              )}
            </div>
            <div
              ref={(e) => {
                if (!e) return;
                setClientHeight((prev) => {
                  if (prev == e.clientHeight) return prev;
                  return e.clientHeight;
                });
              }}
            >
              <div style={{ height: 12 }} />
              {component(handleClick)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
