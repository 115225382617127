export const HAS_SEEN_SINGLE_LOCAL_ONBOARD_MODAL =
  "HAS_SEEN_SINGLE_LOCAL_ONBOARD_MODAL";
export const HAS_SEEN_SINGLE_LOCAL_FIRST_PAGE_TOUR =
  "HAS_SEEN_SINGLE_LOCAL_FIRST_PAGE_TOUR";
export const HAS_SEEN_SINGLE_LOCAL_SECOND_PAGE_TOUR =
  "HAS_SEEN_SINGLE_LOCAL_SECOND_PAGE_TOUR";
export const HAS_SEEN_SINGLE_LOCAL_THIRD_PAGE_TOUR =
  "HAS_SEEN_SINGLE_LOCAL_THIRD_PAGE_TOUR";
export const HAS_SEEN_SINGLE_LOCAL_FOURTH_PAGE_TOUR =
  "HAS_SEEN_SINGLE_LOCAL_FOURTH_PAGE_TOUR";
export const HAS_SEEN_SINGLE_LOCAL_FIFTH_PAGE_TOUR =
  "HAS_SEEN_SINGLE_LOCAL_FIFTH_PAGE_TOUR";
