import { digitDateWithDigitHourFormatter } from "../../components/tools";

export const CompletedFormatter = (item) => {
  const { status_history, created_at } = item;

  const array = [
    {
      date: digitDateWithDigitHourFormatter(created_at),
      text: "You created a transaction",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[0]?.created_at
      ),
      text: "Your payment has been received",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[1]?.created_at
      ),
      text: "We sent out your money to the recipient",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[2]?.created_at
      ),
      text: "All done! Your transaction has been completed",
      isActive: true,
      isGreenButton: true,
    },
  ];

  return array;
};
