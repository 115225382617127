import Image from "next/image";
import React from "react";
import { jackColors } from "../../../assets/colors";
import InfoIcon from "../../../public/images/info-icon-grey-full.svg";
import { useModalHook } from "../../../components/Modals";
import { GothamRegular, TextInlineMedium } from "../../../components/Text";
import { FileInputJack } from "../../../components/inputs/fileInputs/fileInput";
import GuideTemplateModalJack from "../../../modals/guideTemplateModalPayroll";
import { useTranslation } from "react-i18next";

export const TalentaTemplate = ({ useFormObj }) => {
  const { isOpen, toggle } = useModalHook();
  const { t } = useTranslation("payroll/create");
  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <GothamRegular
          className="font14"
          style={{ color: jackColors.grey6C, marginBottom: 16 }}
        >
          Upload{" "}
          <a
            href="#"
            onClick={() => toggle()}
            style={{
              textDecoration: "underline",
              color: "inherit",
              cursor: "pointer",
            }}
          >
            <TextInlineMedium
              className="font14"
              style={{
                color: jackColors.grey6C,
                textDecorationLine: "underline",
              }}
            >
              {t("Talenta Salary Detail")}
            </TextInlineMedium>
          </a>{" "}
          {t("to move your existing payroll data.")}
        </GothamRegular>
        <FileInputJack
          maxSizeInfoInside
          showLabel={false}
          formData
          fileTypes={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          useFormObj={useFormObj}
          name="upload_file_custom_payroll"
          firstCopy={t("Upload Talenta Salary Detail")}
          multiple={false}
        />
      </div>
      <div
        className="d-flex align-items-center"
        style={{
          padding: 12,
          gap: 8,
          border: `1px solid ${jackColors.neutral600}`,
          borderRadius: 4,
          backgroundColor: jackColors.neutral400,
        }}
      >
        <Image src={InfoIcon} height={20} width={20} alt="icon" />
        <GothamRegular
          className="font12"
          style={{ color: jackColors.neutral700 }}
        >
          {t("Need help on which template should be used?")}{" "}
          <a
            href="#"
            onClick={() => toggle()}
            style={{
              textDecoration: "underline",
              color: "inherit",
              cursor: "pointer",
            }}
          >
            <TextInlineMedium
              style={{
                textDecorationLine: "underline",
              }}
            >
              {t("See upload guide")}
            </TextInlineMedium>
          </a>
        </GothamRegular>
      </div>

      <GuideTemplateModalJack isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export const BCATemplate = () => {
  return <div>uploadCarddfgdfgComponent</div>;
};
