import { isDate } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  deepRemoveDuplicates,
  simpleDate,
  unformatCurrency,
} from "../../../components/tools";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { fetch } from "../../../tools/api";
import { queryArray } from "../../eWalletComponents/home/main/filter/modal/content/parent";
import { reimbursementFormatter } from "./formatter";
export const useReimbursements = () => {
  const [page, setPage] = useState(1);
  const { data, loading, refetch, setData } = fetch({
    url: `/reimbursements?page=${page}`,
    afterSuccess: (res) => {
      setPage((p) => {
        const { total_page } = res || {};
        //if (page == total_page) setGrandLoading(false);
        if (!total_page) return p;

        const canSubmit = total_page > p;
        if (canSubmit) return p + 1;

        return p;
      });
    },

    formatter: (res, prev) => {
      const { data: prevData = [] } = prev || {};

      const data = deepRemoveDuplicates(
        [...prevData, ...(res?.data || [])],
        "id"
      );

      const result = { ...res, data };

      return result;
    },
    woInit: true,
    defaultValue: {},
  });

  useEffect(refetch, [page]);
  const {
    data: array = [],
    total_drafted_reimbursement,
    total_page,
  } = data || {};
  const grandLoading = loading && page == total_page;
  const { isAdminOrSuperAdmin } = getUserRole();
  const { user } = useGetAuth();
  const { id } = user || {};

  const hasRequired = (approval_tasks) => {
    const userIds = (approval_tasks || []).map(({ user_id }) => user_id);
    const includesThisAccountId = userIds.includes(id);

    const myActiveTask = (approval_tasks || []).filter((item) => {
      const { user_id, approved_at, state } = item;
      if (user_id == id && !Boolean(approved_at) && state === "requested")
        return item;
    });

    return Boolean(myActiveTask.length > 0);
    // return includesThisAccountId;
  };

  const tableArray = reimbursementFormatter(array)
    .filter(({ state }) => state !== "created")
    .filter((item) => {
      if (isAdminOrSuperAdmin) return true;

      const { approval_tasks, user_id } = item || {};
      const isRequired = hasRequired(approval_tasks);

      if (isRequired) return true;

      if (user_id == id) return true;

      return false;
    });

  const tableArrayAll = tableArray.filter(({ state }) => state != "drafted");
  const tableArrayDraft = tableArray.filter(
    ({ state, user_id }) => state == "drafted" && user_id == id
  );

  const tableArrayRequire = tableArray.filter(
    ({ state, rejection_note, approval_tasks }) => {
      if (rejection_note) return false;

      const isRequired = hasRequired(approval_tasks);

      if (!isRequired) return false;

      const isWaitingForApproval = state == "waiting_for_approval";
      return isWaitingForApproval;
    }
  );

  return {
    tableArrayAll,
    tableArrayRequire,
    tableArrayDraft,
    refetch,
    setData,
    grandLoading,
    draftedNumber: total_drafted_reimbursement,
  };
};

export const useQueriesReimbursement = () => {
  const { query } = useRouter();
  const {
    search_box = "",
    created_by,
    from_date,
    to_date,
    merchant,
    keywords,
    specific_amount,
    minimum_amount,
    maximum_amount,
  } = query;

  const statusArr = queryArray(query, "status");

  const searchBoxUrl = () => {
    const search = keywords || search_box;
    if (!search) return "";
    return `&q[merchant_or_memo_or_user_name_cont]=${search}`;
  };

  const createdBy = () => {
    if (!created_by) return "";
    return `&q[user_name_cont]=${created_by}`;
  };
  const merchantQuery = () => {
    if (!merchant) return "";
    return `&q[merchant_cont]=${merchant}`;
  };

  const fromUrl = () => {
    if (!from_date) return "";
    const fromUrlFormatted = new Date(`${simpleDate(from_date)} 00:00`);
    return `&q[created_at_gteq]=${fromUrlFormatted}`;
  };

  const toUrl = () => {
    if (!to_date) return "";
    const toDateFormatted = new Date(`${simpleDate(to_date)} 23:59`);
    return `&q[created_at_lteq]=${toDateFormatted}`;
  };

  const statusUrl = statusArr
    .map((status) => `&q[state_matches_any][]=${status}`)
    .join("");

  const amountUrl = () => {
    const getNumber = (number) => Number(unformatCurrency(number));
    // const noQuery = !specific_amount && !minimum_amount && !maximum_amount;
    // if (noQuery) return "";

    const amountGTEQ = `&q[amount_gteq]=${getNumber(minimum_amount)}`;
    const amountLTEQ = `&q[amount_lteq]=${getNumber(maximum_amount)}`;

    if (specific_amount) return `&q[amount_eq]=${getNumber(specific_amount)}`;
    if (minimum_amount && maximum_amount) return `${amountGTEQ}${amountLTEQ}`;
    if (minimum_amount) return amountGTEQ;
    if (maximum_amount) return amountLTEQ;
    return "";
    // return amountLTEQ;
  };

  const queries = `${searchBoxUrl()}${toUrl()}${fromUrl()}${statusUrl}${amountUrl()}${createdBy()}${merchantQuery()}`;
  return { queries };
};

export const useReimbursementLazy = ({ type }) => {
  const { push, query } = useRouter();
  const { runQuery } = query;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const isTask = type === "task";
  const { queries } = useQueriesReimbursement(isTask);
  const { user } = useGetAuth();
  const { id: currentUserId } = user;

  const urlDecider = (type) => {
    if (type === "task")
      return `/reimbursements/index_requested?page=${page}&q[originator_type_eq]=Reimbursement&per_page=10${queries}`;
    if (type === "draft")
      return `/reimbursements?page=${page}&per_page=10&q[state_matches_all][]=drafted&q[user_id_eq]=${currentUserId}`;
    return `/reimbursements?page=${page}&per_page=10&q[state_does_not_match_all][]=drafted${queries}`;
  };

  const { data, loading, refetch, setData } = fetch({
    url: urlDecider(type),
    formatter: (res, prev) => {
      const { data: prevData = [] } = prev || {};

      const data = deepRemoveDuplicates(
        [...prevData, ...(res?.data || [])],
        "id"
      );

      const result = { ...res, data };

      return result;
    },
    woInit: true,
    defaultValue: {},
  });

  useEffect(refetch, [page]);
  const {
    data: array = [],
    total_drafted_reimbursement,
    total_page,
    total_reimbursement,
  } = data || {};

  const tableArrayAll = reimbursementFormatter(array);

  const handleScroll = () => {
    if (page >= total_page) return;
    return setPage((p) => p + 1);
  };

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    if (!runQuery) return;

    //set data to empty object
    setData({});

    //if page === 1 do refetch if not set page to 1
    if (page == 1) return refetch();
    setPage(1);
    return;
  }, [runQuery]);

  return {
    tableArrayAll,
    loading,
    setData,
    draftedNumber: total_drafted_reimbursement,
    totalTasks: total_reimbursement,
    handleScroll,
  };
};

export const gmt7 = (date) => {
  const momentDate = moment.utc(date + " 00:00:00+07:00");
  const formatDate = momentDate.toDate();
  const isValidDate = momentDate.isValid();

  if (isValidDate) return formatDate;
  return "";
};

export const handleDateforBE = (date) => {
  if (!date) return date;
  return moment(date).format("YYYY-MM-DD");
};
