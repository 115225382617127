import { useEffect } from "react";
import { useHandlingFee } from "../../../modals/smartActivityModal/crossBorderSingleModal/DanamonReleasePayment/logic";

export const useFetchHandlingFee = ({
  payment_method,
  service: serviceRaw,
  taskId,
  receiptId,
  isRetried,
}) => {
  const {
    data: dataHandlingFee,
    calculateFee,
    loadingCalculateFee,
  } = useHandlingFee();

  const maskingService = () => {
    if (!serviceRaw || serviceRaw == "intrabank") return "optimized_route";
    return serviceRaw;
  };

  const service = maskingService();

  const payload = {
    payment_method,
    service,
    task_ids: [taskId],
    receipt_ids: !!receiptId ? [receiptId] : [],
  };

  if (isRetried) payload.is_after_retry = true;

  useEffect(() => {
    if (payment_method && service) {
      calculateFee(payload);
    }
  }, [payment_method, service]);

  return { dataHandlingFee, loadingCalculateFee };
};
