import React, { useState } from "react";
import { jackColors } from "../../../assets/colors";
import { SubTextComponent } from "../../bankAccountSettingComponents/tools/helpers";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { font12, font14 } from "../../virtualAccountComponents/common/utils";
import {
  GothamLight,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../components/tools";
import { Avatar } from "../../../components/Avatar";
import InfoBanner from "./infoBanner";
import { useGetAuth } from "../../../contexts/AuthContext";
import { useFetchCompanyBalance, useGetBalance } from "../logics";
import Skeleton from "react-loading-skeleton";
import { Trans, useTranslation } from "react-i18next";

export const MenuOptions = ({ onClick }) => {
  const { t } = useTranslation("ledgers/ledgers");
  const {
    vaPocketBalance,
    danamonBalance: primaryBalance,
    refetch: refetchDanamon,
    loading: danamonLoading,
  } = useFetchCompanyBalance();

  const vaPocketBalanceString = `IDR ${formatCurrencyNoDecimal(
    vaPocketBalance
  )}`;
  const danamonBalanceString = `IDR ${formatCurrencyNoDecimal(primaryBalance)}`;

  const selectOptions = [
    {
      text: t("VA Pocket"),
      subText: `${t("From")}:`,
      logoName: "account-balance-wallet",
      nextStep: "upload_csv",
      amount: danamonLoading ? <Skeleton /> : vaPocketBalanceString,
    },
    {
      text: t("Primary Bank Account"),
      subText: `${t("To")}:`,
      logoName: "bank-outline",
      nextStep: "empty_table",
      amount: danamonLoading ? <Skeleton /> : danamonBalanceString,
    },
  ];

  const disableMoveBalance = danamonLoading || vaPocketBalance === 0;

  const SelectionIcon = ({ name }) => {
    return (
      <div style={{ width: 32 }}>
        <Avatar
          size="medium"
          style={{ backgroundColor: jackColors.neutral400 }}
          mainIcon={
            <JackIcons
              name={name}
              fill="black"
              style={{ width: 20, height: 20 }}
            />
          }
        />
      </div>
    );
  };

  return (
    <>
      <div
        // options div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: 36,
            height: 36,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 18,
            position: "absolute",
            top: "40%",
            left: "45%",
            border: `1px solid ${jackColors.neutral500}`,
            boxShadow: "0px 4px 8px 0px rgba(50, 50, 50, 0.06",
          }}
        >
          <Avatar
            size="small"
            mainIcon={
              <JackIcons
                name="arrow-downward"
                fill="white"
                style={{ width: 20, height: 20 }}
              />
            }
          />
        </div>
        {selectOptions?.map((option, index) => {
          const text = option.text;
          const subText = option.subText;
          const logoName = option.logoName;
          const amount = option.amount;
          return (
            <div
              style={{
                display: "flex",
                gap: 16,
                width: "100%",
                alignItems: "center",
                border: `1px solid ${jackColors.neutral500}`,
                borderRadius: 12,
                padding: "16px 16px",
                transition: "all 0.4s ease",
                backgroundColor: "white",
              }}
              key={index}
            >
              <div
                style={{
                  display: "flex",
                  gap: 16,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <SelectionIcon name={logoName} />
                <SubTextComponent
                  divStyle={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  }}
                  text={text}
                  subText={subText}
                  customTextStyle={font14}
                  customSubStyle={font12}
                  reversed
                />
                <div style={{ marginLeft: "auto" }}>
                  <SubTextComponent
                    divStyle={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                    text={amount}
                    subText={t("Current balance")}
                    customTextStyle={{ ...font14, textAlign: "right" }}
                    customSubStyle={{ ...font12, textAlign: "right" }}
                    reversed
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <InfoBanner
        infoText={
          <>
            <GothamLight>
              {/* Please expect{" "}
              <span style={{ display: "inline-block" }}>
                <GothamRegular>max. 24 hours</GothamRegular>
              </span>{" "}
              until the balance are moved. */}
              <Trans
                i18nKey={t("dynamic.dynamic_text_16")}
                components={[<span style={{ fontFamily: "GothamMedium" }} />]}
              />
            </GothamLight>
          </>
        }
        isOwnTextComponent
      />
      <div
        // navigation div
        style={{ display: "flex", justifyContent: "flex-end", gap: 16 }}
      >
        <ButtonJack
          type="outline"
          style={{
            height: "40px",
            padding: 10,
            minWidth: 0,
            cursor: "pointer",
          }}
          onClick={() => onClick({ type: "cancel" })}
        >
          {t("Cancel")}
        </ButtonJack>
        <ButtonJack
          style={{
            height: "40px",
            padding: 10,
            minWidth: 0,
            cursor: "pointer",
          }}
          onClick={() => onClick({ type: "next" })}
          disabled={disableMoveBalance}
        >
          {t("Move Balance")}
        </ButtonJack>
      </div>
    </>
  );
};
