import JackIcons from "assets/jackIcons/typescript/parent";
import { useModalHook } from "components/Modals";
import NextImage from "components/NextImage";
import SimpleCenteredModal from "modals/SimpleCenteredModal/typescript";
import { useRouter } from "next/router";
import { RecipientType } from "pageComponents/localTransferEnhancement/typescript/create/singleTransferComponents/add-recipient/index.types";
import {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import { jackColors } from "../../../assets/colors";
import emptystate from "../../../assets/images/empty-state-search.svg";
import { ButtonJack } from "../../../components/ButtonsJack/typescript/parent";
import { windowDimension } from "../../../components/tools";
import {
  GothamMedium,
  GothamRegular,
} from "../../../components/typescript/Text";
import { pluralize } from "../../../pageComponents/invoiceComponents/data/formatter";
import { TransactionFiltersSearchbox } from "../../../pageComponents/transactionsComponents/transactions/filters/searchbox/parent";
import { fetch } from "../../../tools/api";
import IndividualUser from "./IndividualUser";
import AddRecipientModal from "pageComponents/localTransferEnhancement/typescript/create/singleTransferComponents/add-recipient/modal";
import { ToasterHook } from "contexts/ToasterContext";
import { useTranslation } from "react-i18next";
import RadioButton from "pageComponents/cardPageComponents/components/CardModal/components/RadioButton";
import LoadingSpinner from "components/Loading";
import MIXPANELS from "universalFunctions/mixpanels";
import { useLanguage } from "public/locales/translationFunctions";

export type UserListProps = {
  isPayroll?: boolean;
  isModal?: boolean;
  isAddMore?: boolean;
  woFooter?: boolean;
  woAddButton?: boolean;
  woMultipleSelect?: boolean;
  passedRecipientsFromParent?: RecipientType[] | [];
  isFromTable?: boolean;
  handleAddRecipient: (recipients: RecipientType[]) => void;
  handleCancel?: () => void;
  declareIsMounted?: (val: boolean) => void;
};

export const UserList = ({
  isPayroll = false,
  isModal = false,
  woFooter = false,
  woAddButton = false,
  woMultipleSelect = false,
  passedRecipientsFromParent = [],
  isFromTable = false,
  handleAddRecipient,
  handleCancel = () => {},
  isAddMore = false,
  declareIsMounted = () => {},
}: UserListProps) => {
  const { t } = useTranslation("local-transfer/revamp");

  const [page, setPage] = useState(1);
  const [searching, setSearching] = useState(false);
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);
  const [users, setUsers] = useState<RecipientType[]>([]);
  const [thereAreNoMoreRecipient, setThereAreNoMoreRecipient] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState<
    RecipientType[] | []
  >([]);

  const userListRef = useRef<HTMLDivElement>(null);

  const { query, push } = useRouter();
  const { search_box_recipient = "", category = "" } = query;

  const { successSnackBar } = ToasterHook();
  const { isOpen: isOpenAdd, toggle: toggleAdd } = useModalHook();

  const baseUrl = isPayroll ? "/payroll_recipients" : "/local_recipients";
  const employeeOrRecipient = isPayroll ? "employee" : "recipient";
  const accountSelected = `${selectedRecipients.length} ${t(
    pluralize(selectedRecipients.length, employeeOrRecipient)
  )} ${t("selected")} `;
  const buttonSelected = isPayroll ? "Select Employee" : "Select Recipient";
  const keyword = Boolean(search_box_recipient) ? search_box_recipient : "";

  const isAllChecked = selectedRecipients.length === users.length;
  let selectedItems = users.filter((obj) =>
    passedRecipientsFromParent.some((sel) => sel.id === obj.id)
  );
  let notSelectedItems = users.filter(
    (obj) => !passedRecipientsFromParent.some((sel) => sel.id === obj.id)
  );
  let result = [...selectedItems, ...notSelectedItems];

  const { refetch, loading } = fetch({
    url: `${baseUrl}?page=${page}&per_page=10&category=${category}&sort=registered_name&direction=asc&newest_recipients=true&q[name_or_registered_name_or_account_number_cont]=${search_box_recipient}&q[is_valid_eq]=true`,
    afterSuccess: (res: any) => {
      const { data, page: pageRes } = res;

      if (data.length < 10) {
        setThereAreNoMoreRecipient(true);
      }

      if (pageRes === 1) {
        setUsers(data);
      } else {
        setUsers((prev) => [...prev, ...data]);
      }

      setIsEndOfScroll(false);
      setSearching(false);
    },
    woInit: true,
  });

  const handleSelectRecipient = (recipient: RecipientType, select = true) => {
    if (woFooter) return handleAddRecipient([recipient]);
    if (select) {
      if (woMultipleSelect) {
        return setSelectedRecipients([recipient]);
      }
      return setSelectedRecipients((prev) => [...prev, recipient]);
    }

    setSelectedRecipients((prev) =>
      prev.filter((user) => user.id !== recipient.id)
    );
  };

  const handleContinue = () => {
    handleAddRecipient(selectedRecipients);
  };

  const handleAdd = () => {
    MIXPANELS.LOCAL_TRANSFER.LOCAL_SINGLE_ADD_LIST();
    toggleAdd();
  };

  const onScroll = () => {
    if (userListRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = userListRef.current;
      if (scrollTop + clientHeight <= scrollHeight - 10 && !loading) {
        setPage((prev) => prev + 1);
        setIsEndOfScroll(true);
      }
    }
  };

  const footer = () => {
    if (woFooter) return null;

    return (
      <div
        className={
          isFromTable
            ? "d-flex justify-content-end align-items-center"
            : "d-flex justify-content-between align-items-center"
        }
        style={{
          margin: isModal ? "0px -20px" : "0px",
          padding: isModal ? "20px 20px 0px" : "32px 0px 24px",
          borderTop: "1px solid #E6E6E8",
        }}
      >
        {Boolean(!isFromTable) && (
          <GothamMedium>{accountSelected}</GothamMedium>
        )}
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ gap: 12 }}
        >
          <ButtonJack type="outline" onClick={handleCancel}>
            {t("Cancel")}
          </ButtonJack>
          <ButtonJack
            onClick={handleContinue}
            disabled={selectedRecipients.length === 0}
          >
            {t(buttonSelected)}
          </ButtonJack>
        </div>
      </div>
    );
  };

  const memoizedRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleAfterAdd = (shouldThrowSnackbar: boolean) => {
    if (typeof successSnackBar == "function" && shouldThrowSnackbar) {
      successSnackBar({ msg: "Recipient has been added!" });
      memoizedRefetch();
    }
  };

  useEffect(() => {
    const refetchTimeout = setTimeout(() => {
      if (!loading && isEndOfScroll && !thereAreNoMoreRecipient) {
        memoizedRefetch();
      }
    }, 0);

    return () => clearTimeout(refetchTimeout);
  }, [page, isEndOfScroll, thereAreNoMoreRecipient]);

  useEffect(() => {
    const searchTimeOut = setTimeout(() => {
      if (page === 1 && searching) {
        setThereAreNoMoreRecipient(false);
        memoizedRefetch();
      }
    }, 0);

    return () => clearTimeout(searchTimeOut);
  }, [page, searching]);

  useEffect(() => {
    setPage(1);
    setSearching(true);
  }, [search_box_recipient, category]);

  useEffect(() => {
    if (passedRecipientsFromParent.length > 0) {
      setSelectedRecipients(passedRecipientsFromParent);
    }
  }, [passedRecipientsFromParent]);

  useEffect(() => {
    return () => {
      if (!!query?.category || !!query?.search_box_recipient) {
        delete query?.category; // to clear up query after component unmounts
        delete query?.["search_box_recipient"]; // to clear up query after component unmounts
        push({ query });
      }
    };
  }, []);

  if (loading && users.length === 0) return <LoadingSpinner style={{}} />;

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          margin: isFromTable
            ? "16px 0px"
            : woAddButton
            ? "0 0 16px"
            : "12px 0px 16px 0px",
          display: "flex",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", flex: 1, flexShrink: 1 }}>
          <TransactionFiltersSearchbox
            queryName="search_box_recipient"
            placeholder={t("Search recipient")}
            style={{
              marginTop: woAddButton ? 0 : -8,
              width: "100%",
            }}
            loading={loading}
            withClearFilter
          />
        </div>
        {!woAddButton && (
          <div id="single-fourth" className="d-flex">
            <ButtonJack
              children={t("Add New")}
              leftIcon={
                <JackIcons
                  name="PersonAddOutline"
                  fill={jackColors.black34}
                  style={{ height: 20, width: 20 }}
                />
              }
              style={{ flexShrink: 0, padding: 10 }}
              onClick={handleAdd}
            />
          </div>
        )}
      </div>
      <RecipientFilter loading={loading} />
      <div id={"single-third"}>
        {!!users?.length && (
          <div
            style={{
              borderBottom: `1px solid ${jackColors.neutral500}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "24px",
            }}
          >
            <GothamMedium
              style={{
                color: "#252525",
                padding: "0 0 8px",
              }}
              // className="font14"
            >
              {t("All Recipient")}
            </GothamMedium>

            {!woMultipleSelect && (
              <div>
                <JackIcons
                  name={isAllChecked ? "checkmark-square-2" : "square"}
                  fill={
                    isAllChecked ? jackColors.neutral900 : jackColors.neutral600
                  }
                  style={{ width: 20, height: 20, margin: "0px 8px" }}
                  onClick={() => {
                    setSelectedRecipients((prev) => {
                      if (prev?.length == users.length)
                        return isAddMore ? [...passedRecipientsFromParent] : [];
                      return users;
                    });
                  }}
                />
              </div>
            )}
          </div>
        )}

        <div
          style={{
            height: 400,
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            position: "relative",
          }}
          ref={userListRef}
          onScroll={onScroll}
        >
          {Boolean(users.length) &&
            result.map((user, index) => {
              const checkedFromParent = Boolean(
                selectedRecipients.find((recipient) => recipient.id === user.id)
              );

              // this is [strictly] provided to disable a checkbox (or in this case, an individual user) because it should be adding more (on top of the selected one) instead of freely selecting users (therefore changing the selected one)
              const selectedFirst = Boolean(
                passedRecipientsFromParent.find(
                  (recipient) => recipient.id === user.id
                )
              );
              const disabled = selectedFirst && isAddMore;
              return (
                <div
                  key={index}
                  ref={(e) => {
                    if (!e) return;
                    declareIsMounted(true);
                  }}
                >
                  <IndividualUser
                    user={user}
                    handleSelectRecipient={handleSelectRecipient}
                    checkedFromParent={checkedFromParent}
                    isMinimalistStyle
                    woMultipleSelect={woMultipleSelect}
                    disabled={disabled}
                  />
                </div>
              );
            })}
          {loading && <Shimmer />}
          {!Boolean(users?.length) && !loading && !searching && (
            <EmptyStateRecipientList
              keyword={String(keyword)}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "16px",
                height: "100%",
              }}
            />
          )}
        </div>
      </div>

      {footer()}
      {!woAddButton && (
        <AddRecipientModal
          isOpen={isOpenAdd}
          toggle={toggleAdd}
          nextAction={handleAfterAdd}
        />
      )}
    </div>
  );
};

export default UserList;

const EmptyStateRecipientList = ({
  keyword = "",
  style: styleProps = {},
}: {
  keyword?: string;
  style?: CSSProperties;
}) => {
  const { isBahasa } = useLanguage();
  const style: CSSProperties = {
    fontSize: "12px",
    lineHeight: "16px",
    color: jackColors.grey90,
    textAlign: "center",
  };
  return (
    <div style={{ ...styleProps }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <NextImage
          width={160}
          height={160}
          src={emptystate}
          alt="Empty State Image"
        />
      </div>
      <div>
        <GothamRegular style={{ ...style }}>
          {isBahasa ? "Kami tidak bisa menemukan" : "We can't find"}
          {keyword ? (
            <span style={{ fontFamily: "GothamMedium" }}> {keyword}</span>
          ) : (
            <span>
              {" "}
              {isBahasa ? "Yang anda cari." : "what you're looking for."}
            </span>
          )}
        </GothamRegular>
        <GothamRegular style={{ ...style }}>
          {isBahasa ? "Coba kata kunci lainnya." : "Let's try another keyword."}
        </GothamRegular>
      </div>
    </div>
  );
};

export const RecipientFilter = ({
  extraOnClick,
  loading,
  queryName = "category",
}: {
  extraOnClick?: (arg: string) => void;
  loading?: boolean;
  queryName?: string;
}) => {
  const { t } = useTranslation("local-transfer/revamp");
  const arr = [
    {
      label: "All",
      value: "",
    },
    {
      label: "E-wallet",
      value: "e_wallet",
    },
    {
      label: "Bank",
      value: "bank",
    },
    {
      label: "Virtual Account",
      value: "va_bank",
    },
  ];
  const { push, query } = useRouter();
  const handleClick = (value: string) => {
    let payload = {
      ...query,
    };
    payload[queryName] = value;
    push({
      query: payload,
    });
    if (extraOnClick) extraOnClick(value);
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {arr.map(({ label, value }, index) => {
        const isSelected = (query[queryName] || "") === value;
        return (
          <ButtonJack
            isBullet
            onClick={() => handleClick(value)}
            key={index}
            style={{
              padding: "6px 12px",
              minWidth: "unset",
              backgroundColor: isSelected ? jackColors.greenB9 : "white",
              boxShadow: `inset 0px 0px 0px 1px ${
                isSelected ? "#343434" : "#e6e6e8"
              }`,
            }}
          >
            <GothamMedium style={{ fontSize: 12 }}>{t(label)}</GothamMedium>
          </ButtonJack>
        );
      })}
      {/* <RadioButton
        label={null}
        list={arr}
        loading={loading}
        queryName={"category"}
        defaultValue={arr[0].value}
        customOnclick={handleClick}
        // runQueryName="runQueryFilter"
      /> */}
    </div>
  );
};

const Shimmer = () => {
  const shimmers = Array.from(Array(10).keys());

  return (
    <div>
      {shimmers.map((shimmer) => (
        <div
          key={shimmer}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px 0px",
          }}
        >
          <div style={{ padding: "0px 14px 0px 8px" }}>
            <Skeleton width={16} height={16} />
          </div>
          <Skeleton
            width={32}
            height={32}
            style={{ borderRadius: "4px", marginRight: 16 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Skeleton width={200} height={20} />
            <Skeleton width={150} height={15} />
          </div>
        </div>
      ))}
    </div>
  );
};
