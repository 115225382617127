import { getFlagImagesObj } from "../assets/flags/parent";
import { countries } from "../assets/flags/countryIso";
import { noCase, upperCase } from "change-case";
import { isEmpty } from "lodash";

export const Flag = ({ iso = "", width = 24, height = 24 }) => {
  const country = countries.find((country) => country.code === upperCase(iso));
  const isFlagExist = !isEmpty(country);
  const obj = getFlagImagesObj();
  const flag = obj[country?.name];

  return (
    <img
      src={isFlagExist ? flag : `https://flagcdn.com/h80/${noCase(iso)}.png`}
      width={width}
      height={height}
      className="align-self-center"
      style={{ borderRadius: 20 }}
    />
  );
};
