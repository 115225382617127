import React from "react";
import mail from "../../assets/images/mail-blue-rounded.svg";
import { upperCase } from "lodash";
import { colors } from "assets/colors";
import { GothamMedium } from "./Text";
import { nameInitial } from "@tools";

// Define types for the NameAvatar component
interface NameAvatarProps {
  children: React.ReactNode;
  is36?: boolean;
}

// Define the NameAvatar component
export const NameAvatar: React.FC<NameAvatarProps> = ({
  children,
  is36 = false,
}) => {
  const getName = nameInitial(String(children));
  return (
    <div
      style={{
        color: colors.blue10,
        width: is36 ? 36 : 40,
        height: is36 ? 36 : 40,
        backgroundColor: colors.bluee1,
        borderRadius: 20,
      }}
      className="d-flex align-items-center justify-content-center"
    >
      {getName ? (
        <GothamMedium>{getName}</GothamMedium>
      ) : (
        <img src={mail} alt="Mail Icon" />
      )}
    </div>
  );
};

// Define a type for sizeStyle
type SizeStyle = {
  width: number;
  height: number;
  borderRadius: number;
  fontSize: number;
};

// Define types for the Avatar component
interface AvatarProps {
  name?: string;
  size?: "extra-large" | "large" | "medium" | "small" | "smallest";
  type?: "circle" | "square";
  mainIcon?: React.ReactNode; // React.ReactNode includes JSX elements
  bottomRightIcon?: React.ReactNode;
  bottomRightStyle?: React.CSSProperties;
  customSize?: SizeStyle;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

// Define the Avatar component
export const Avatar: React.FC<AvatarProps> = ({
  name = "",
  size = "large",
  type = "circle",
  mainIcon = null,
  bottomRightIcon = null,
  bottomRightStyle = {},
  customSize = null,
  style = {},
  textStyle = {},
}) => {
  const initialName = nameInitial(upperCase(name));
  const isSquare = type === "square";

  // Define a default size style
  const defaultSizeStyle: SizeStyle = {
    width: 40,
    height: 40,
    borderRadius: 100, // Default for circle
    fontSize: 16,
  };
  // Function to determine the size style
  const getSize = (): SizeStyle => {
    if (customSize) return customSize; // Handle custom size if needed

    switch (size) {
      case "extra-large":
        return {
          width: 60,
          height: 60,
          borderRadius: isSquare ? 8 : 120,
          fontSize: 20,
        };
      case "large":
        return {
          width: 40,
          height: 40,
          borderRadius: isSquare ? 8 : 100,
          fontSize: 16,
        };
      case "medium":
        return {
          width: 32,
          height: 32,
          borderRadius: isSquare ? 8 : 100,
          fontSize: 14,
        };
      case "small":
        return {
          width: 24,
          height: 24,
          borderRadius: isSquare ? 4 : 100,
          fontSize: 12,
        };
      case "smallest":
        return {
          width: 20,
          height: 20,
          borderRadius: isSquare ? 4 : 100,
          fontSize: 10,
        };
      default:
        return defaultSizeStyle;
    }
  };

  const sizeStyle = getSize();
  const fontSize = sizeStyle?.fontSize ?? 16; // Default fontSize if sizeStyle is null

  const containerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    backgroundColor: colors.neutral900,
    color: colors.neutral100,
    ...sizeStyle,
    ...style,
  };

  const bottomRightIconStyle: React.CSSProperties = {
    position: "absolute",
    bottom: -4,
    right: -4,
    ...bottomRightStyle,
  };

  return (
    <div style={containerStyle}>
      {mainIcon ? (
        mainIcon
      ) : (
        <GothamMedium style={{ color: "white", fontSize, ...textStyle }}>
          {initialName}
        </GothamMedium>
      )}
      <div style={bottomRightIconStyle}>{bottomRightIcon}</div>
    </div>
  );
};
