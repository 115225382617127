import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "reactstrap";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { SelectionJack } from "../../../../../components/inputs/selection";
import { SimpleTextAreaJack } from "../../../../../components/inputs/simpleTextArea";
import { useModalHook } from "../../../../../components/Modals";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../../components/Text";
import {
  formatCurrency,
  isID,
  unformatCurrency,
} from "../../../../../components/tools";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import CheckboxSquare from "../../../../alertsAndAttentionComponents/components/CheckboxSquare";
import { idrStringFormatter } from "../../../data/formatter";
import { CloseButton } from "../../uploadPage";
import { redStarLabel } from "../formEdit";
import { useTranslation } from "react-i18next";
import dynamic from "next/dynamic";
import { useLanguage } from "public/locales/translationFunctions";

const TextFieldJack = dynamic(() =>
  import("../../../../../components/inputs/textfield").then(
    (mod) => mod.TextFieldJack
  )
);

const taxOptions = function (isID) {
  return [
    {
      label: (
        <div>
          <GothamMedium className="font12" style={{ color: "#343434" }}>
            {isID ? "Buat baru" : "Customize fee/tax"}
          </GothamMedium>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {isID
              ? "Atur sendiri perhitungan pajak/biaya anda"
              : "Make your own fee or tax calculation"}
          </GothamRegular>
        </div>
      ),
      value: "custom",
    },
    {
      label: (
        <div>
          <GothamMedium className="font12" style={{ color: "#343434" }}>
            PPh 21
          </GothamMedium>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {isID ? "2.5% dari Subtotal Item" : "2.5% from Items Subtotal"}
          </GothamRegular>
        </div>
      ),
      value: "PPh 21",
    },
    {
      label: (
        <div>
          <GothamMedium className="font12" style={{ color: "#343434" }}>
            PPh 23
          </GothamMedium>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {isID ? "2% dari Subtotal Item" : "2% from Items Subtotal"}
          </GothamRegular>
        </div>
      ),
      value: "PPh 23",
    },
    {
      label: (
        <div>
          <GothamMedium className="font12" style={{ color: "#343434" }}>
            PPN
          </GothamMedium>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {isID ? "11% dari Subtotal Item" : "11% from Items Subtotal"}
          </GothamRegular>
        </div>
      ),
      value: "PPN",
    },
  ];
};
const taxOptionsReimbursement = [
  {
    label: (
      <div>
        <GothamMedium className="font12" style={{ color: "#343434" }}>
          Customize fee/tax
        </GothamMedium>
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          Make your own fee or tax calculation{" "}
        </GothamRegular>
      </div>
    ),
    value: "custom",
  },
  {
    label: (
      <div>
        <GothamMedium className="font12" style={{ color: "#343434" }}>
          PPN
        </GothamMedium>
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          11% from Items Subtotal
        </GothamRegular>
      </div>
    ),
    value: "PPN",
  },
  {
    label: (
      <div>
        <GothamMedium className="font12" style={{ color: "#343434" }}>
          Pajak Restoran (PB1)
        </GothamMedium>
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          10% from Items Subtotal
        </GothamRegular>
      </div>
    ),
    value: "Pajak Restoran (PB1)",
  },
  {
    label: (
      <div>
        <GothamMedium className="font12" style={{ color: "#343434" }}>
          Pajak Hotel (PB1)
        </GothamMedium>
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          10% from Items Subtotal
        </GothamRegular>
      </div>
    ),
    value: "Pajak Hotel (PB1)",
  },
];

const TaxDescription = ({ type, itemSubTotal, addOrDeduct }) => {
  const modalProperties = (type, totalAmount) => {
    const titles = {
      PPN: "PPN = Subtotal items x 11%",
      "PPh 23": "PPh 23 = Subtotal items x 2%",
      "PPh 21": "PPh 21 = Subtotal items x 2.5%",
      "Pajak Restoran (PB1)": "PB1 = Subtotal items x 10%",
      "Pajak Hotel (PB1)": "PB1 = Subtotal items x 10%",
    };

    const percentages = {
      PPN: 11,
      "PPh 23": 2,
      "PPh 21": 2.5,
      "Pajak Restoran (PB1)": 10,
      "Pajak Hotel (PB1)": 10,
    };

    const totalTax = Number(totalAmount) * (percentages[type] / 100);
    const typeDecider = () => {
      // if (type == "PH") return "Pajak Hotel (PB1)";
      // if (type == "PR") return "Pajak Restoran (PB1)";

      return type;
    };
    return {
      title: titles[type],
      percentage: percentages[type],
      totalTax,
      type: typeDecider(),
    };
  };

  const {
    title,
    percentage,
    totalTax,
    type: taxType,
  } = modalProperties(type, unformatCurrency(itemSubTotal));

  const isAdded = addOrDeduct == "add";
  const isDeduct = addOrDeduct == "deduct";
  const copyAdd = isAdded ? "added" : "deducted";
  if (!type) return null;
  return (
    <div
      style={{
        borderRadius: 4,
        padding: 12,
        display: "flex",
        flexDirection: "column",
        gap: 12,
        border: "1px solid #e6e6e8",
      }}
    >
      <GothamMedium>{title}</GothamMedium>
      <div style={{ width: "100%", height: 1, backgroundColor: "#e6e6e8" }} />
      <div className="d-flex justify-content-between">
        <GothamRegular>Subtotal items</GothamRegular>
        <GothamRegular>{itemSubTotal}</GothamRegular>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <GothamRegular style={{}}>{percentage}%</GothamRegular>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div style={{ width: "96%", height: 1, backgroundColor: "#E6E6E8" }} />
        <JackIcons
          name="close_outline"
          fill="#909098"
          style={{ width: 12, height: 12 }}
        />
      </div>
      <div className="d-flex justify-content-between">
        <GothamRegular>{taxType}</GothamRegular>
        <div className="d-flex-column ">
          <GothamMedium style={{ textAlign: "end" }}>
            {idrStringFormatter(totalTax)}
          </GothamMedium>
          <GothamRegular style={{ color: "#909098" }} className="font10">
            {isID
              ? `(Jumlah ini akan ${
                  copyAdd === "added" ? "ditambahkan" : "dikurangkan"
                } ke perhitungan)`
              : `(This amount will be ${copyAdd} to the calculation)`}
          </GothamRegular>
        </div>
      </div>
    </div>
  );
};

const TaxSelection = ({ label }) => {
  const { isOpen, toggle } = useModalHook();
  return (
    <>
      <GothamRegular style={{ marginBottom: 8 }} className="font12">
        {label}
      </GothamRegular>
      <div
        style={{
          width: "100%",
          height: 40,
          padding: "10px 12px",
          display: "flex",
          justifyContent: "space-between",
          border: `1px solid ${"#E6E6E8"}`,
          borderRadius: 4,
        }}
      >
        <GothamRegular>{isOpen ? "Select Fee/Tax" : "jembut"}</GothamRegular>
        <JackIcons name="chevron_down" fill="#BBBBC0" onClick={toggle} />
      </div>
    </>
  );
};

const typeOptions = [
  { label: "Percentage", value: "percentage" },
  { label: "Fixed Amount", value: "fixed_amount" },
];

const ToggleButton = ({
  children,
  buttonText,
  buttonLeftIcon,
  isActive,
  onClick,
}) => {
  const buttonFill = isActive ? "#6C6C71" : "#BBBBC0";
  const textColor = isActive ? "#343434" : "#BBBBC0";
  return (
    <ButtonJack
      leftIcon={<JackIcons name={buttonLeftIcon} fill={buttonFill} />}
      style={{
        backgroundColor: isActive ? "#FFF" : "#e6e6e8",
        width: "50%",
        height: 36,
      }}
      onClick={onClick}
    >
      <GothamMedium style={{ color: textColor }}>{children}</GothamMedium>
    </ButtonJack>
  );
};

const AddOrDeduct = ({ useFormObj, name }) => {
  const { watch, register, setValue } = useFormObj;
  const currValue = watch(name);
  const isAdd = currValue == "add";
  const isDeduct = currValue == "deduct";
  const { t } = useTranslation("invoice-payment/invoice-payment");
  const { isBahasa: isID } = useLanguage();

  useEffect(() => {
    if (currValue) return;
    register(name);
    setValue(name, "add");
  }, []);
  return (
    <div style={{ marginBottom: 32 }}>
      <GothamRegular className="mb-1 font12">
        {redStarLabel(t("Add/Deduct"))}
      </GothamRegular>

      <div
        style={{
          width: "100%",
          height: 40,
          padding: 2,
          borderRadius: 4,
          backgroundColor: "#e6e6e8",
          display: "flex",
        }}
      >
        <ToggleButton
          buttonLeftIcon="plus_circle"
          isActive={isAdd}
          onClick={() => {
            if (isAdd) return;
            return setValue(name, "add");
          }}
        >
          {t("Add")}
        </ToggleButton>
        <ToggleButton
          buttonLeftIcon="minus_circle"
          isActive={isDeduct}
          onClick={() => {
            if (isDeduct) return;
            return setValue(name, "deduct");
          }}
        >
          {t("Deduct")}
        </ToggleButton>
      </div>
      <GothamRegular
        className="font10"
        style={{ color: "#909098", marginTop: 4 }}
      >
        {isID
          ? `Pajak/biaya ini akan ${
              isAdd ? "ditambahkan" : "dikurangkan"
            } ke perhitungan.`
          : `This tax/fee will be ${
              isAdd ? "added" : "deducted"
            } to the calculation`}
      </GothamRegular>
    </div>
  );
};

export const extractNumber = (input) => {
  const regex = /[0-9.]+/g;
  const matches = String(input).match(regex);

  if (matches) {
    const numberString = matches.join("");
    const parsedNumber = parseFloat(numberString);
    return isNaN(parsedNumber) ? 0 : parsedNumber;
  }

  return 0;
};

const CustomTaxForm = ({ useFormObj, taxSourceOptions, isEdit = false }) => {
  const { watch, setValue, setError } = useFormObj;
  const isPercentage = watch("fee_tax_element")?.value == "percentage";
  const { t } = useTranslation("invoice-payment/invoice-payment");

  const nameVal = watch("fee_tax_name");
  const restrictedName = taxSourceOptions?.map(({ value }) =>
    value.name?.toLowerCase()
  );

  useEffect(() => {
    const isExist = restrictedName?.includes(nameVal?.toLowerCase());

    if (isExist)
      return setError("fee_tax_name", {
        type: "custom",
        message:
          "This name is already used for other tax/fee. Try another name",
      });
  }, [nameVal]);
  return (
    <div>
      <SimpleTextAreaJack
        label={redStarLabel(t("Name"))}
        style={{ height: 60 }}
        useFormObj={useFormObj}
        name="fee_tax_name"
        maxLength={20}
        placeholder={t("E.g., Shipping fee, Discount, Service tax")}
      />
      <AddOrDeduct useFormObj={useFormObj} name="add_or_deduct" />
      <div style={{ display: "flex", gap: 16 }}>
        <SelectionJack
          useFormObj={useFormObj}
          name="fee_tax_element"
          containerStyle={{ width: 212 }}
          label={redStarLabel(t("Type"))}
          options={typeOptions}
        />
        {!isPercentage ? (
          <TextFieldJack
            useFormObj={useFormObj}
            name="fee_tax_value"
            label={redStarLabel(t("Amount"))}
            style={{ width: 212 }}
            onChange={(e) => {
              const value = e?.target?.value;
              const newValue = `IDR ${formatCurrency(value)}`;

              return setValue("fee_tax_value", newValue);
            }}
          />
        ) : (
          <TextFieldJack
            useFormObj={useFormObj}
            name="fee_tax_value"
            label={redStarLabel(t("Rates"))}
            style={{ width: 212 }}
            onChange={(e) => {
              const value = e?.target?.value;
              const newValue = `${extractNumber(value)}`;

              return setValue("fee_tax_value", newValue);
            }}
          />
        )}
      </div>
      <CustomTaxSourceSelection
        useFormObj={useFormObj}
        name="calculate_from"
        label={t("From")}
        isPercentage={isPercentage}
        options={taxSourceOptions}
        isEdit={isEdit}
      />
    </div>
  );
};

const CustomTaxSourceSelection = ({
  useFormObj,
  label,
  name,
  isPercentage,
  options,
  isEdit,
}) => {
  const { register, setValue, watch } = useFormObj;

  const { isOpen, toggle } = useModalHook();
  const currValue = watch(name);

  const handleCheck = (value) => {
    const isExist = currValue?.map(({ name }) => name)?.includes(value?.name);

    if (isExist) {
      const newValue = currValue?.filter((val) => val != value);
      return setValue(name, newValue);
    }

    const newValue = [...currValue, value];
    return setValue(name, newValue);
  };

  useEffect(() => {
    register(name);
    // setValue(name, isPercentage ? ["item_subtotal"] : []);
  }, []);

  useEffect(() => {
    if (!isEdit)
      setValue(
        name,
        isPercentage
          ? [
              {
                name: "items_subtotal",
                is_custom: false,
              },
            ]
          : []
      );
  }, [isPercentage]);
  return (
    <div
      style={{
        display: !isPercentage ? "none" : "flex",
        flexDirection: "column",
        gap: 8,
      }}
    >
      <GothamRegular className="font12">{redStarLabel(label)}</GothamRegular>
      <div
        style={{
          width: "100%",
          minHeight: 40,
          height: "auto",
          borderRadius: 4,
          border: `1px solid ${isOpen ? "#343434" : "#e6e6e8"}`,
          padding: "10px 12px",
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
          {currValue?.map(({ name }, index) => {
            const lengths = currValue?.length;
            const isLast = index == lengths - 1;
            const isFirst = index == 0;

            const showPlus = lengths > 1 && !isLast;

            const selectedOption = options
              ?.filter(({ value }) => value?.name == name)
              ?.pop();

            const label = selectedOption?.label ?? "Source";
            return (
              <>
                <div
                  style={{
                    width: 100,
                    height: 20,
                    display: "grid",
                    placeItems: "center",
                    backgroundColor: "#E6E6E8",
                    borderRadius: 4,
                  }}
                >
                  <GothamRegular
                    className="font12"
                    style={{ color: "#6C6C71" }}
                  >
                    {label}
                  </GothamRegular>
                </div>
                {showPlus && (
                  <JackIcons
                    name="plus"
                    fill="#343434"
                    style={{ width: 12, height: 12 }}
                  />
                )}
              </>
            );
          })}
        </div>

        <JackIcons name="chevron_down" fill="#BBBBC0" onClick={toggle} />
        {isOpen && (
          <div
            style={{
              position: "absolute",
              top: 44,
              left: 0,
              padding: 8,
              borderRadius: 8,
              border: "1px solid #e6e6e8",
              backgroundColor: "#fff",
              zIndex: 5,
              width: "100%",
              maxHeight: 136,
              height: "auto",
              overflowY: "scroll",
            }}
          >
            {options?.map((option) => {
              const { label, value } = option;
              const isChecked = currValue
                ?.map(({ name }) => name)
                ?.includes(value?.name);
              const isItemSubtotal = label == "Item Subtotal";
              return (
                <div
                  style={{
                    padding: "0px 8px",
                    display: "flex",
                    gap: 8,
                    width: "100%",
                    height: 40,
                    alignItems: "center",
                  }}
                >
                  <CheckboxSquare
                    isChecked={isChecked}
                    onClick={() => handleCheck(value)}
                    isDisabled={isItemSubtotal}
                  />
                  <GothamRegular>{label}</GothamRegular>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const payload = {
  PPN: {
    fee_tax_type: "PPN",
    fee_tax_name: "PPN",
    fee_tax_element: "percentage",
    calculate_from: [
      {
        is_custom: false,
        name: "items_subtotal",
      },
    ],
    add_or_deduct: "add",
    fee_tax_value: 11,
  },
  "PPh 21": {
    fee_tax_type: "PPH 21",
    fee_tax_name: "PPH 21",
    fee_tax_element: "percentage",
    calculate_from: [
      {
        is_custom: false,
        name: "items_subtotal",
      },
    ],
    add_or_deduct: "add",
    fee_tax_value: 2.5,
  },
  "PPh 23": {
    fee_tax_type: "PPH 23",
    fee_tax_name: "PPH 23",
    fee_tax_element: "percentage",
    calculate_from: [
      {
        is_custom: false,
        name: "items_subtotal",
      },
    ],
    add_or_deduct: "add",
    fee_tax_value: 2,
  },
  "Pajak Hotel (PB1)": {
    fee_tax_type: "Pajak Hotel (PB1)",
    fee_tax_name: "Pajak Hotel (PB1)",
    fee_tax_element: "percentage",
    calculate_from: [
      {
        is_custom: false,
        name: "items_subtotal",
      },
    ],
    add_or_deduct: "add",
    fee_tax_value: 10,
  },
  "Pajak Restoran (PB1)": {
    fee_tax_type: "Pajak Restoran (PB1)",
    fee_tax_name: "Pajak Restoran (PB1)",
    fee_tax_element: "percentage",
    calculate_from: [
      {
        is_custom: false,
        name: "items_subtotal",
      },
    ],
    add_or_deduct: "add",
    fee_tax_value: 10,
  },
};

export const AddFeesTaxModal = ({
  isOpen,
  toggle,
  handleApply,
  taxSourceOptions,
  itemSubTotal,
  isReimbursement = false,
}) => {
  const useFormObj = useForm({
    defaultValues: { selection: {} },
  });
  const {
    reset,
    watch: watchMain,
    formState: formStateMain,
    setValue,
  } = useFormObj;
  const useFormCustomForm = useForm({});
  const {
    handleSubmit,
    reset: resetCustomForm,
    watch,
    formState,
  } = useFormCustomForm;
  const selectionVal = watchMain("selection")?.value;
  const addOrDeduct = watchMain("add_or_deduct");
  const nameVal = watch("fee_tax_name");
  const taxElement = watch("fee_tax_element");
  const taxValue = watch("fee_tax_value");
  const isCustom = selectionVal == "custom";
  const { t } = useTranslation("invoice-payment/invoice-payment");
  const { t: tCommon } = useTranslation("common");
  const { isBahasa: isID } = useLanguage();

  const createCustomTaxPayload = (val) => {
    const {
      fee_tax_element: fte,
      calculate_from: cf,
      fee_tax_value: ftv,
      ...rest
    } = val;

    const fee_tax_element = fte?.value;
    return {
      fee_tax_type: "custom",
      calculate_from: isEmpty(cf) ? [] : cf,
      fee_tax_element,
      fee_tax_value: unformatCurrency(ftv),
      ...rest,
    };
  };

  const disableButton = () => {
    if (isCustom) {
      const nameLength = nameVal?.length > 0;
      const taxElementIsNotEmpty = !isEmpty(taxElement);
      const taxValLength =
        taxValue?.length > 0 && Number(unformatCurrency(taxValue)) > 0;

      const disable = !(nameLength && taxElementIsNotEmpty && taxValLength);

      return disable;
    }
    return isEmpty(selectionVal);
  };

  useEffect(() => {
    reset({});
    resetCustomForm({});
  }, [isOpen]);

  const { successSnackBar } = ToasterHook();
  return (
    <Modal
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName="modal-transfez"
      toggle={toggle}
    >
      <div style={{ width: 480 }}>
        {/* modal head */}
        <div
          style={{
            display: "flex",
            padding: 20,
            justifyContent: "space-between",
            borderBottom: "1px solid #e6e6e8",
          }}
        >
          <div>
            <GothamMedium className="font20" style={{ marginBottom: 8 }}>
              {t("Add Fees & Taxes")}
            </GothamMedium>
            <GothamRegular style={{ color: "#909098" }}>
              {t("Apply tax or fee to be calculated in this transaction.")}
            </GothamRegular>
          </div>
          <CloseButton onClick={toggle} />
        </div>

        {/* modal form */}
        <div style={{ padding: 20 }}>
          <SelectionJack
            useFormObj={useFormObj}
            name="selection"
            options={
              isReimbursement ? taxOptionsReimbursement : taxOptions(isID)
            }
            label={redStarLabel(t("Fees & Taxes"))}
            placeholder={t("Select Fee/Tax")}
            defaultValue={{}}
          />
          {isCustom ? (
            <CustomTaxForm
              useFormObj={useFormCustomForm}
              taxSourceOptions={taxSourceOptions}
            />
          ) : (
            <>
              {selectionVal && (
                <AddOrDeduct name="add_or_deduct" useFormObj={useFormObj} />
              )}
              <TaxDescription
                itemSubTotal={itemSubTotal}
                type={selectionVal}
                addOrDeduct={addOrDeduct}
              />
            </>
          )}
        </div>

        {/* modal buttons */}
        <div
          style={{
            padding: 20,
            display: "flex",
            gap: 16,
            borderTop: "1px solid #e6e6e8",
          }}
        >
          <ButtonJack type="outline" style={{ width: "50%" }} onClick={toggle}>
            {tCommon("Cancel")}
          </ButtonJack>
          <ButtonJack
            style={{ width: "50%" }}
            disabled={disableButton()}
            onClick={() => {
              if (isCustom) {
                return handleSubmit((val) => {
                  const newTax = createCustomTaxPayload(val);
                  handleApply(newTax);
                })();
              } else {
                const newTax = payload[selectionVal];
                const addOrDeductValue = watchMain("add_or_deduct");
                handleApply({ ...newTax, add_or_deduct: addOrDeductValue });
              }
            }}
          >
            {Boolean(selectionVal) ? tCommon("Apply") : tCommon("Add")}
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};

export const EditFeesTaxModal = ({
  isOpen,
  toggle,
  handleApply,
  taxSourceOptions: rawSource,
  itemSubTotal,
  defaultValue,
  isReimbursement = false,
}) => {
  const useFormObj = useForm({
    defaultValues: { selection: {} },
  });
  const {
    reset,
    watch: watchMain,
    formState: formStateMain,
    setValue,
    register,
  } = useFormObj;
  const useFormCustomForm = useForm({});
  const { isBahasa: isID } = useLanguage();
  const {
    handleSubmit,
    reset: resetCustomForm,
    watch,
    formState,
    setValue: setValueCustom,
    register: registerCustom,
  } = useFormCustomForm;

  const selectionVal = watchMain("selection")?.value;
  const addOrDeduct = watchMain("add_or_deduct");
  const nameVal = watch("fee_tax_name");
  const taxElement = watch("fee_tax_element");
  const taxValue = watch("fee_tax_value");
  const isCustom = selectionVal == "custom";

  const createCustomTaxPayload = (val) => {
    const {
      fee_tax_element: fte,
      calculate_from: cf,
      fee_tax_value: ftv,
      ...rest
    } = val;

    const fee_tax_element = fte?.value;

    return {
      fee_tax_type: "custom",
      calculate_from: isEmpty(cf) ? [] : cf,
      fee_tax_element,
      fee_tax_value: unformatCurrency(ftv),
      ...rest,
    };
  };

  const disableButton = () => {
    if (isCustom) {
      const nameLength = nameVal?.length > 0;
      const taxElementIsNotEmpty = !isEmpty(taxElement);
      const taxValLength =
        taxValue?.length > 0 && Number(unformatCurrency(taxValue)) > 0;

      const disable = !(nameLength && taxElementIsNotEmpty && taxValLength);

      return disable;
    }
    return isEmpty(selectionVal);
  };

  const taxSourceOptions = rawSource?.filter(
    ({ value }) => value?.name != defaultValue?.fee_tax_name
  );

  useEffect(() => {
    if (!isEmpty(defaultValue)) {
      const simpleTaxes = [
        "PPN",
        "PPH 21",
        "PPH 23",
        "Pajak Restoran (PB1)",
        "Pajak Hotel (PB1)",
      ];

      const {
        fee_tax_type,
        fee_tax_name,
        add_or_deduct,
        fee_tax_element,
        fee_tax_value,
        calculate_from,
      } = defaultValue;
      const taxType = fee_tax_type == "VAT" ? "custom" : fee_tax_type;

      const options = isReimbursement
        ? taxOptionsReimbursement
        : taxOptions(isID);
      const defaultSelected = options?.find(
        ({ value }) =>
          value.toUpperCase() == (taxType ?? "custom").toUpperCase()
      );

      register("selection");
      register("add_or_deduct");
      setValue("selection", defaultSelected);
      setValue("add_or_deduct", add_or_deduct);

      const elementVal = typeOptions?.find(
        ({ value }) => value == fee_tax_element
      );

      const customDefaultValue = {
        fee_tax_name,
        add_or_deduct,
        fee_tax_element: elementVal,
        fee_tax_value,
        calculate_from,
      };

      const emptyDefaultVal = {
        fee_tax_name: "",
        add_or_deduct: "add",
        fee_tax_element: {},
        fee_tax_value: "",
        calculate_from: [{ name: "items_subtotal", is_custom: false }],
      };
      const formValue = simpleTaxes?.includes(fee_tax_type?.toUpperCase())
        ? emptyDefaultVal
        : customDefaultValue;
      const keys = Object.keys(formValue);
      keys?.forEach((key) => {
        registerCustom(key);
        setValueCustom(key, customDefaultValue[key]);
      });
    }
  }, [isOpen]);

  const { successSnackBar } = ToasterHook();
  return (
    <Modal
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName="modal-transfez"
      toggle={toggle}
    >
      <div style={{ width: 480 }}>
        {/* modal head */}
        <div
          style={{
            display: "flex",
            padding: 20,
            justifyContent: "space-between",
            borderBottom: "1px solid #e6e6e8",
          }}
        >
          <div>
            <GothamMedium className="font20" style={{ marginBottom: 8 }}>
              Add Fees & Taxes
            </GothamMedium>
            <GothamRegular style={{ color: "#909098" }}>
              Include more fee or tax calculation for this transaction.
            </GothamRegular>
          </div>
          <CloseButton onClick={toggle} />
        </div>

        {/* modal form */}
        <div style={{ padding: 20 }}>
          <SelectionJack
            useFormObj={useFormObj}
            name="selection"
            options={
              isReimbursement ? taxOptionsReimbursement : taxOptions(isID)
            }
            label={redStarLabel("Fees & Taxes")}
            placeholder="Select Fee/Tax"
            defaultValue={{}}
          />
          {isCustom ? (
            <CustomTaxForm
              useFormObj={useFormCustomForm}
              taxSourceOptions={taxSourceOptions}
              isEdit={true}
            />
          ) : (
            <>
              <AddOrDeduct useFormObj={useFormObj} name="add_or_deduct" />
              <TaxDescription
                itemSubTotal={itemSubTotal}
                type={selectionVal}
                addOrDeduct={addOrDeduct}
              />
            </>
          )}
        </div>

        {/* modal buttons */}
        <div
          style={{
            padding: 20,
            display: "flex",
            gap: 16,
            borderTop: "1px solid #e6e6e8",
          }}
        >
          <ButtonJack type="outline" style={{ width: "50%" }} onClick={toggle}>
            Cancel
          </ButtonJack>
          <ButtonJack
            style={{ width: "50%" }}
            disabled={disableButton()}
            onClick={() => {
              if (isCustom) {
                return handleSubmit((val) => {
                  const newTax = createCustomTaxPayload(val);
                  const newTaxName = newTax?.fee_tax_name;
                  const rowKey = defaultValue?.rowkey;
                  const taxId = defaultValue?.id;
                  handleApply({ ...newTax, rowKey, id: taxId });
                  return successSnackBar({
                    msg: (
                      <GothamRegular>
                        <TextInlineMedium>{newTaxName}</TextInlineMedium> has
                        been edited!
                      </GothamRegular>
                    ),
                    showClose: true,
                  });
                })();
              } else {
                const newTax = payload[selectionVal];
                const rowKey = defaultValue?.rowkey;
                const taxId = defaultValue?.id;
                const newTaxName = newTax?.fee_tax_name;
                const addOrDeductValue = watchMain("add_or_deduct");
                handleApply({
                  ...newTax,
                  add_or_deduct: addOrDeductValue,
                  rowKey,
                  id: taxId,
                });
                return successSnackBar({
                  msg: (
                    <GothamRegular>
                      <TextInlineMedium>{newTaxName}</TextInlineMedium> has been
                      edited!
                    </GothamRegular>
                  ),
                  showClose: true,
                });
              }
            }}
          >
            {Boolean(selectionVal) ? "Apply" : "Edit"}
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};
