import { jackColors } from "../../../../../assets/colors";
import { GothamRegular } from "../../../../../components/Text";
import CategoryIcon from "../CategoryIcon";

const CategoryLabel = ({
  icon = "category",
  color = jackColors.neutral900,
  upperText = "Upper Label",
  lowerText = "Lower Label",
  style = {},
}) => {
  const textStyle = {
    color: jackColors.neutral900,
    maxWidth: "100%",
    textWrap: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "4px",
        maxWidth: "100%",
        ...style,
      }}
    >
      <CategoryIcon
        name={icon}
        fill={color}
        style={{
          minWidth: "19.2px",
          maxWidth: "19.2px",
        }}
      />
      <div
        style={{
          marginTop: "2px",
          maxWidth: "100%",
        }}
      >
        <GothamRegular woFontColor style={textStyle}>
          {upperText}
        </GothamRegular>
        <GothamRegular style={{ ...textStyle, color: jackColors.neutral700 }}>
          {lowerText}
        </GothamRegular>
      </div>
    </div>
  );
};

export default CategoryLabel;
