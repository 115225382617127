import moment from "moment";
import { BILL, LOCAL, PAYROLL, REIMBURSEMENT } from "../constants";
import retryResubmitBooleans from "./retryResubmitBooleans";
import { useLanguage } from "public/locales/translationFunctions";

const grayBannerMessageDecider = ({
  trx,
  user,
  drafter,
  releaser,
  warningType,
  resubmittedAt,
  t = () => {},
}) => {
  const { isBahasa } = useLanguage();
  const { isRetry, isResubmit, isReminder } = retryResubmitBooleans({
    user,
    drafter,
    releaser,
    warningType,
  });

  const isBill = trx?.type === BILL;
  const isReimbursement = trx?.type === REIMBURSEMENT;

  const drafterName = drafter?.name || drafter?.email || "-";
  const releaserName = releaser?.name || releaser?.email || "-";

  const formatDate = moment(resubmittedAt || new Date()).format(
    "dddd, MMMM D YYYY"
  );

  if (isReminder && !resubmittedAt) {
    if (isRetry) {
      if (isBahasa)
        return `Anda telah mengingatkan ${releaserName} untuk mengulangi pembayaran ini.`;
      return `You have reminded ${releaserName} to retry this transaction`;
    }

    if (isResubmit) {
      if (isBahasa)
        return `Anda telah mengingatkan ${drafterName} untuk mengajukan kembali transaksi ini.`;
      return `You have reminded ${drafterName} to resubmit this transaction`;
    }

    return "-";
  }

  if (isRetry) {
    if (isBill || isReimbursement) {
      if (isBahasa)
        return `${releaserName} telah mengulangi pembayaran transaksi ini pada ${formatDate}`;
      return `${releaserName} has retried this transaction on ${formatDate}.`;
    }
    if (isBahasa)
      return `Klik untuk melihat transaksi gagal yang telah mengulangi pembayaran sebagai batch baru pada ${formatDate}`;
    return `The failed transactions(s) have been retried as a new batch on ${formatDate}.`;
  }

  if (isResubmit) {
    if (isBahasa)
      return `Klik untuk melihat transaksi gagal yang telah diajukan kembali sebagai batch baru pada ${formatDate}.`;
    return `The failed transactions(s) have been resubmitted as a new batch on ${formatDate}.`;
  }

  return "-";
};

export default grayBannerMessageDecider;
