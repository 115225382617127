import react, { useState } from "react";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { colors, jackColors } from "../../../../assets/colors";
import DanamonHeaderAccount from "../../../../assets/jackImages/DanamonHeaderAccount.png";
import { Tooltip } from "reactstrap";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import OJKFooter from "../../../../assets/jackImages/OJKFooter.png";
import { useMyCompanyBalance } from "../../crossBorderSingleModal/DanamonReleasePayment/logic";
import { ShimmerOneLine } from "../../../../components/Shimmer";
import { useTranslation } from "react-i18next";

export const JackDanamonInfoComponent = (props) => {
  const { t } = useTranslation("ledgers/ledgers");
  const { accountData, loadingBalance } = useMyCompanyBalance();
  const accountName =
    accountData?.danamon_account_details?.account?.account_holder_name;
  const accountNumber =
    accountData?.danamon_account_details?.account?.account_number;
  const swiftCodeDanamon = "BDINIDJA";
  const bankName = "Danamon Bank";
  const bankCode = "011";

  const titleStyle = {
    color: colors.grey90,
    marginBottom: 8,
  };
  return (
    <div
      className="d-flex flex-column justify-content-between"
      // style={{ height: "100%" }}
    >
      <div
        className="d-flex flex-column"
        style={{
          backgroundColor: jackColors.greyF1,
          borderRadius: 12,
        }}
      >
        <div
          className="d-flex flex-column"
          style={{
            border: `1px solid ${colors.greye6}`,
            borderRadius: 12,
            boxShadow: "0px 4px 8px 0px rgba(50, 50, 50, 0.06)",
            backgroundColor: "white",
          }}
        >
          <img
            src={DanamonHeaderAccount}
            style={{
              width: "100%",
              height: "auto",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            }}
          />
          <div
            style={{
              padding: "16px 12px",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                borderBottom: `1px solid ${colors.greye6}`,
                paddingBottom: 16,
                marginBottom: 16,
              }}
            >
              <GothamRegular className="font12" style={{ ...titleStyle }}>
                {t("Account Name")}
              </GothamRegular>
              {loadingBalance ? (
                <div className="d-flex" style={{ height: 16 }}>
                  <ShimmerOneLine height={16} width={100} />
                </div>
              ) : (
                <div className="d-flex">
                  <GothamMedium className="font12" style={{ marginRight: 4 }}>
                    {accountName}
                  </GothamMedium>
                  <CopyTooltip value={accountName} />
                </div>
              )}
            </div>
            <div
              className="d-flex flex-column"
              style={{
                borderBottom: `1px solid ${colors.greye6}`,
                paddingBottom: 16,
                marginBottom: 16,
              }}
            >
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: 16 }}
              >
                <div style={{ marginRight: 32, minWidth: 107 }}>
                  <GothamRegular className="font12" style={{ ...titleStyle }}>
                    {t("Account Number")}
                  </GothamRegular>
                  {loadingBalance ? (
                    <div className="d-flex" style={{ height: 16 }}>
                      <ShimmerOneLine height={16} width={100} />
                    </div>
                  ) : (
                    <div className="d-flex">
                      <GothamMedium
                        className="font12"
                        style={{ marginRight: 4 }}
                      >
                        {accountNumber}
                      </GothamMedium>
                      <CopyTooltip value={accountNumber} />
                    </div>
                  )}
                </div>
                <div>
                  <GothamRegular className="font12" style={{ ...titleStyle }}>
                    {t("SWIFT Code")}
                  </GothamRegular>

                  <div className="d-flex">
                    <GothamMedium className="font12" style={{ marginRight: 4 }}>
                      {swiftCodeDanamon}
                    </GothamMedium>
                    <CopyTooltip value={swiftCodeDanamon} />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <div style={{ marginRight: 32, minWidth: 107 }}>
                  <GothamRegular className="font12" style={{ ...titleStyle }}>
                    {t("Bank Name")}
                  </GothamRegular>
                  <div className="d-flex">
                    <GothamMedium className="font12" style={{ marginRight: 4 }}>
                      {bankName}
                    </GothamMedium>
                    <CopyTooltip value={bankName} />
                  </div>{" "}
                </div>
                <div>
                  <GothamRegular className="font12" style={{ ...titleStyle }}>
                    {t("Bank Code")}
                  </GothamRegular>
                  <div className="d-flex">
                    <GothamMedium className="font12" style={{ marginRight: 4 }}>
                      {bankCode}
                    </GothamMedium>
                    <CopyTooltip value={bankCode} />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <GothamRegular className="font12" style={{ ...titleStyle }}>
                {t("Billing Address")}
              </GothamRegular>
              <div className="d-flex">
                <GothamMedium
                  className="font12"
                  style={{ marginRight: 4, width: 318 }}
                >
                  PT. BANK DANAMON INDONESIA, TBK, MENARA BANK DANAMON, BLOCK C
                  NO. 10, JL. HR. RASUNA SAID 10, SETIABUDI, JAKARTA, Indonesia
                </GothamMedium>
                <CopyTooltip />
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex align-items-start"
          style={{
            width: "100%",
            backgroundColor: jackColors.greyF1,
            padding: "8px 16px",
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
          }}
        >
          <JackIcons
            name="Lightbulb"
            fill={colors.yellowFFC}
            style={{ width: 16, height: 16, marginRight: 8 }}
          />
          <GothamRegular className="font10" style={{ color: colors.grey90 }}>
            {t(
              "This account functions like your regular bank account. You can deposit and withdraw funds using the details provided."
            )}{" "}
            <span
              style={{
                fontFamily: "GothamMedium",
                textDecoration: "underline",
              }}
            >
              {t("Learn more")}
            </span>
          </GothamRegular>
        </div>
      </div>
      <div style={{ position: "absolute", bottom: "12px", paddingRight: 12 }}>
        <img src={OJKFooter} style={{ width: "100%", height: "auto" }} />
      </div>
    </div>
  );
};

const CopyTooltip = ({ value = "" }) => {
  const { t } = useTranslation("ledgers/ledgers˝");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { successSnackBar } = ToasterHook();
  const toggleTooltip = () => setTooltipOpen((prev) => !prev);
  return (
    <>
      <div
        id={"Tooltip-"}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigator.clipboard.writeText(value);
          successSnackBar({ msg: t("Copied!") });
        }}
      >
        <JackIcons
          style={{ width: 16, height: 16 }}
          name="file-copy"
          fill={colors.greyBB}
        />
      </div>
      <Tooltip
        target={"Tooltip-"}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        style={{ padding: "0px 12px 2px 12px" }}
      >
        <span
          style={{
            fontSize: 10,
            lineHeight: "12px",
            fontFamily: "TypefezNeueRegular",
          }}
        >
          {t("Copy")}
        </span>
      </Tooltip>
    </>
  );
};
