import { useMemo } from "react";
import dynamic from "next/dynamic";
import { camelCase, capitalize } from "lodash";

const categoryIcons = [
  "category",
  "wallet",
  "bank",
  "savings",
  "briefcase",
  "money",
  "invoice",
  "coin",
  "lock",
  "trendingup",
  "car",
  "building",
  "client",
  "build",
  "book",
  "meals",
  "coffee",
  "cart",
  "medical",
  "education",
  "games",
  "palette",
  "bulb",
  "plane",
  "giftcard",
  "card",
  "withdraw",
  "clock",
  "alert",
  "inventory",
  "monitor",
  "settings",
  "budget",
];

const CategoryIcon = ({ fill, style, name, ...rest }) => {
  const props = { fill, style, ...rest };

  const iconName = categoryIcons.find((icon) => icon === name) ?? "category";

  const Icon = useMemo(
    () =>
      dynamic(() => import(`../../assets/${capitalize(iconName)}Icon`), {
        loading: () => <div style={{ width: 24, height: 24 }} />,
      }),
    [iconName]
  );

  return <Icon {...props} />;
};
// use this for cat icon

export default CategoryIcon;
