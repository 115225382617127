import React from "react";
import LogoTransfez from "../../assets/images/logo-only-transfez.svg";
import { CustomButton } from "../../components/Buttons";
import { SmallScreenWrapper } from "../../components/ScreenWrappers";
import { GothamMedium, TypeFezNeueMedium } from "../../components/Text";
import { CrossBorderSingleStatusLine } from "../../modals/smartActivityModal/crossBorderSingleModal/status";
import { TransactionActivities } from "../accountCardDetailsComponents/lists";

export const DefaultSuccessPage = ({
  onClick = () => {},
  title = "",
  array = [],
  buttonText = "Back to home",
  statusLine = null,
  woStateFormatter,
}) => (
  <SmallScreenWrapper woBack>
    <div style={{ textAlign: "center" }}>
      <img src={LogoTransfez} />
      <TypeFezNeueMedium className="font24 mt-3">{title}</TypeFezNeueMedium>
    </div>
    <TransactionActivities
      itemStyle={{ margin: "1.5rem 0" }}
      data={array}
      woStateFormatter={woStateFormatter}
    />
    {statusLine}
    <CustomButton onClick={onClick} className="mt-4" style={{ width: "100%" }}>
      {buttonText}
    </CustomButton>
  </SmallScreenWrapper>
);
