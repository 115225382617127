import { get } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { deepRemoveDuplicates } from "../../../components/tools";
import { fetch, useMutation } from "../../../tools/api";
import { getUserRole } from "../../../contexts/AuthContext";

const useUserInvitation = (per_page = 5) => {
  const [page, setPage] = useState(1);
  const [openedTable, setOpenedTable] = useState("");

  const {
    query: { searchbox, runQuery, created_by },
  } = useRouter();

  const urlRaw = "/business_users/invitation_requests";
  const pageQuery = `?&page=${page}&per_page=${per_page}`;
  let url = urlRaw + pageQuery;
  if (created_by) {
    url += `&q[requested_by_id_eq]=${created_by}`;
  }
  if (searchbox) {
    url += `&q[email_or_name_or_job_position_cont]=${searchbox}`;
  }

  const {
    data: dataRaw,
    refetch,
    loading,
    setData,
  } = fetch({
    url,
    woInit: true,
    formatter: (data, prev) => {
      const raw = get(data, "data");
      const formatted = raw.map((item) => {
        const name = get(item, "requested_by.name");
        return {
          ...item,
          requested_by: name,
          category: "user",
        };
      });
      const arr = deepRemoveDuplicates(
        [...(prev?.array || []), ...(formatted || [])],
        "id"
      );
      const page = data?.page ?? 1;

      return { array: arr, ...data, page };
    },
  });

  const {
    array: data = [],
    count = 0,
    total_data = 0,
    total_page,
  } = dataRaw || {};

  useEffect(refetch, [page]);

  const seeMore = () => {
    setOpenedTable("invitation_request");
    setPage((prev) => prev + 1);
  };

  const seeLess = () => {
    setData({});
    setPage(1);
    setOpenedTable("");
  };

  const handleReset = () => {
    seeLess();
    refetch();
  };

  useEffect(() => {
    const shouldReset = runQuery === "true";

    if (shouldReset) {
      const resetTaskListing = setTimeout(() => {
        handleReset();
      }, 100);

      return () => clearTimeout(resetTaskListing);
    }
  }, [runQuery]);

  const hasMore = (data ?? []).length < total_data && page < total_page; // has more harus dua kondisi, balikan dr filter sama balikan dari refetch biasa. gmn bedainnya

  return {
    data,
    refetch,
    loading,
    openedTable,
    seeLess,
    seeMore,
    handleReset,
    hasMore,
    count,
    total_data,
  };
};

export default useUserInvitation;

export const useBulkApprove = (afterSuccess = () => {}) => {
  const { mutation, loading } = useMutation({
    url: "/business_users/invitation_requests",
    method: "post",
    afterSuccess,
  });

  return { mutation, loading };
};

export const useFilters = () => {
  const { isAdminOrSuperAdmin } = getUserRole();
  const activeFilters = [isAdminOrSuperAdmin && "created_by"];

  const filters = [
    {
      icon: "person-outline",
      label: "Created by",
      type: "fetch",
      name: "created_by",
      urlFunc: (page, text) =>
        `business_users?page=${page}&per_page=15&q[email_or_name_cont]=${text}&q[status_not_eq]=invited`,
    },
  ].filter((filter) => filter);

  return { activeFilters, filters };
};
