export function typeCSVDecider({ value = "" }) {
  const isTalenta = value === "talenta";
  const isBCA = value === "bca";

  if (value === "") {
    return "";
  }

  return { isTalenta, isBCA };
}
