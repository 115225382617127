export const localTransferCounterFormatter = (res) => {
  const {
    total_trx_processed: processed,
    total_trx: total,
    total_trx_invalid,
    data,
  } = res || {};
  const { validate_at } = data || {};
  const isCompleted = !validate_at;
  const isDataValidation = Boolean(total_trx_invalid);
  return { isCompleted, processed, total, isDataValidation, res };
};
