import { colors } from "../../assets/colors";
import { GothamLight, GothamMedium, GothamRegular } from "../Text";
import { JackIcons } from "../../assets/jackIcons/parent";
import styles from "./styles.module.scss";

export const SubTextTableComponent = ({
  text,
  subText,
  textAlign = "left",
  textStyleAux,
  isMediumText,
  subTextStyleAux,
  divStyle,
}) => {
  const textStyle = {
    color: colors.neutral900,
    letterSpacing: "0.1px",
    textAlign,
    ...textStyleAux,
  };

  const subTextStyle = {
    color: colors.neutral700,
    letterSpacing: "0.2px",
    textAlign,
    ...subTextStyleAux,
  };

  return (
    <div style={divStyle}>
      {isMediumText ? (
        <GothamMedium style={textStyle}>{text}</GothamMedium>
      ) : (
        <GothamRegular style={textStyle}>{text}</GothamRegular>
      )}

      <GothamLight style={subTextStyle}>{subText}</GothamLight>
    </div>
  );
};

export const ActionButtonComponent = ({ iconName }) => {
  return (
    <div className={styles["table-button"]}>
      <JackIcons name={iconName} fill={colors.neutral900} />
    </div>
  );
};
