import { getDefaultValuesArray } from "../../../localTransferComponents/localTransfer/formatter";

export const updatePayloadFormatterPayroll = (array, defaultValues) => {
  const defaultValuesArray = getDefaultValuesArray(defaultValues);

  const transactions = array.map((item) => {
    const { id, account_number, bank: payer_id, name } = item;

    const { amount, email, partner_id, local_recipient, notes } =
      defaultValuesArray.filter(({ id: itemId }) => itemId == id)[0] || {};

    const { salary, department_name, team_role } =
      local_recipient?.payroll_recipient || {};

    const payroll_recipient_attributes = {
      department_name,
      salary: Number(salary),
      team_role,
    };

    const local_recipient_attributes = {
      account_number,
      email,
      is_payroll: true,
      name,
      partner_id,
      payer_id,
      payroll_recipient_attributes,
    };

    const recipient = {
      email,
      name,
    };
    const result = {
      amount: Number(amount),
      local_recipient_attributes,
      local_transaction_id: id,
      notes,
      partner_id,
      recipient,
    };

    return result;
  });

  const payload = { mismatched_action: "replace", transactions };

  return payload;
};
