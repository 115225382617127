import ScrollUpButton from "components/typescript/ScrollUpButton";
import { useRouter } from "next/router";
import { useJackTransferStoredData } from "pageComponents/jackTransferComponents/hooks";
import { translateArray } from "pageComponents/payrollCreateComponents/first";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "universalFunctions/useClickOutside";
import { jackColors } from "../../../../assets/colors";
import imgBack from "../../../../assets/images/back-previous-page.svg";
import imgDelete from "../../../../assets/images/delete-trash-grey.svg";
import imgDuplicateModal from "../../../../assets/images/duplicate-recipient.svg";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { deepUseIdsTable } from "../../../../components/Checkbox";
import { useModalHook } from "../../../../components/Modals";
import { ProgressBarJack } from "../../../../components/Stepper";
import TabMenu from "../../../../components/TabMenu/parent";
import { windowDimension } from "../../../../components/tools";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { useHeader } from "../../../../contexts/Layout/parent";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import ResubmitBanner from "../../../../modals/smartActivityModal/retry-resubmit/components/ResubmitBanner";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../../../categoryComponents/general/hooks";
import { EmptyStateSearchImages } from "../../../crossBorder/components";
import { FormatterOptionsBank } from "../../../PayrollCreateEnhancement/create/formatter";
import { ModalConfirmationCloseLocal } from "../modal";
import { stepArrayLocalFunc } from "../parent";
import { dummyDataLocal } from "../second";
import { FloatingButtonLocal, MainTitleLocalTable } from "./components";
import { HeaderDataTableLocal } from "./data";
import {
  CheckerDataDuplicate,
  checkerEmptyWhenUpload,
  DeleteStringDecider,
  isAllDataCompleted,
  OnSubmitLocalCreateFormatter,
  removeAllEmptyField,
  useLocalTransferMixFunc,
  UseLocalTransferTable,
} from "./logic";
import { SearchBarAndButton } from "./searchbar";
import { TableCreateLocalTransfer } from "./table";
import styles from "./TableLocalEnhancement.module.css";
import { usePageTogglerLocal } from "./toggler";
import { isEmpty } from "lodash";

export const CreateLocalTransfer = ({
  defaultValues,
  titleBatch: titleBatchRaw,
  loading,
  isNotFromExcel,
  category,
  onSubmit: onSubmitProps = () => {},
  onChangeCategory = () => {},
}) => {
  const { push, query, pathname } = useRouter();
  const { setHeader } = useHeader();
  const { menu, searchbox } = query || {};
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [titleBatch, setTitleBatch] = useState(titleBatchRaw || "");
  const [afterAddRow, setAfterAddRow] = useState(false);
  const [isMoveToDraft, setIsMoveToDraft] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [isErrorCategory, setIsErrorCategory] = useState(false);
  const [selectedIdsDelete, setSelectedIdsDelete] = useState(0);
  const [isFirstScreenTable, setIsFirstScreenTable] = useState(true);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const ref = useRef();
  const refForScroll = useRef();
  const isLocalTransaction =
    pathname.includes("/local-transfer/create") ||
    pathname.includes("/local-transfer/[id]");
  const { isOpen: isOpenBackModal, toggle: toggleOpenBackModal } =
    useModalHook();
  const { isOpen: isOpenDeleteModal, toggle: toggleDeleteModal } =
    useModalHook();
  const { isOpen: isOpenCloseModal, toggle: toggleCloseModal } = useModalHook();
  const { isOpen: isOpenDuplicate, toggle: toggleDuplicate } = useModalHook();
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const {
    isOpen: isOpenEditTitleAndCategory,
    toggle: toggleEditTitleAndCategory,
    close: closeEditTitleAndCategory,
  } = useModalHook();
  const { getJackTransferData, storeJackTransferData, removeJackTransferData } =
    useJackTransferStoredData();

  useClickOutside({ ref, clickOutside: () => {} });

  const isResubmit = query.action === "resubmit";

  useEffect(() => {
    setHeader({
      type: "close",
      onCloseProps: () => toggleCloseModal(),
      middleComponent: (
        <ProgressBarJack
          arrayStep={translateArray({
            t: tLocalTransfer,
            array: stepArrayLocalFunc(0, isResubmit, isLocalTransaction),
            key: "step",
          })}
        />
      ),
    });
  }, []);

  const { successSnackBar } = ToasterHook();
  const { headerArray, tableBodyRef } = HeaderDataTableLocal();
  const { localTransferBanks: optionsBank } = useConstants();

  const funcPropsTable = deepUseIdsTable(data);

  const { handleUncheckedAll, selectedAmount, isAllChecked } =
    funcPropsTable || {};

  const {
    setScreen,
    isScreenIncomplete,
    dataIncompleteLength,
    dataDuplicate,
    dataIncomplete,
    isEmptyIncompleteData,
    isScreenDuplicate,
    dataDuplicateLength,
    isEmptyDuplicateData,
    isScreenAllTransactions,
  } = usePageTogglerLocal({ data });

  const { filteredData, dataFloatingButton, isKick, isEmptyData } =
    useLocalTransferMixFunc({
      data,
      search,
    });
  const formattedOptionsBank = FormatterOptionsBank({ options: optionsBank });

  const { hasCategoryList } = useAvailableCategories();
  const { category: categoryStore } = getJackTransferData();

  const { isRequiredCategory } = useCategoryFlag();
  const isMissingRequiredCategory = Boolean(
    isRequiredCategory && !category && hasCategoryList
  );

  const handleDeleteSelected = () => {
    const filteredData = data.filter(
      (_, index) => selectedIdsDelete.indexOf(index) === -1
    );
    successSnackBar({
      msg: DeleteStringDecider(selectedAmount, isAllChecked, tLocalTransfer),
      showClose: true,
    });
    setData(isAllChecked ? [dummyDataLocal] : filteredData);
    handleUncheckedAll();
    eventsTracker("local_transfer_submit_delete_recipient");
    isAllChecked && push("/local-transfer/create");
  };

  const handleChangeCategory = (category) => {
    setIsErrorCategory(false);
    onChangeCategory(category);
  };

  const handleSubmitTitleandCategory = (title, category) => {
    if (category) {
      setIsErrorCategory(false);
      onChangeCategory(category);
      storeJackTransferData({
        category: category,
      });
    }
    setTitleBatch(title);
    storeJackTransferData({
      title_batch: title,
    });
  };

  const onSubmit = (type) => {
    const isValidate = type === "validate";
    const isDraft = type === "draft";
    const isBack = type === "back";
    const isBackModal = type === "back_modal";
    const isResubmitBack = type === "resubmit_back";
    const isOpenModalDelete = type === "open_modal_delete";
    const isSubmitDelete = type === "submit_delete";
    const isConfirmDuplicate = type === "confirm_duplicate";

    if (isResubmitBack) {
      localStorage.setItem("autoOpenTrxOrigin", query.id);
      return push({
        pathname: "/transactions",
      });
    }

    if (isSubmitDelete) return handleDeleteSelected();
    if (isOpenModalDelete) {
      return toggleDeleteModal();
    }
    if (isBack) {
      setHeader({
        type: "close",
        onCloseProps: () => toggleCloseModal(),
      });
      removeJackTransferData();
      return push("/local-transfer/create");
    }
    if (isBackModal) return toggleOpenBackModal();

    if (isValidate || isDraft || isConfirmDuplicate) {
      if (isMissingRequiredCategory) {
        return setIsErrorCategory(true);
      }
      const dataChecker = checkerEmptyWhenUpload({ data });
      const dataClean = removeAllEmptyField({ data: dataChecker });
      const isEmptyData = dataClean.length === 0;
      const dataFinal = !!dataClean.length ? dataClean : [dummyDataLocal];
      if (isEmptyData) {
        return setData(dataFinal);
      }
      setData(dataFinal);

      const evenstTrack = isValidate
        ? "local_transfer_table_submit_validate"
        : "local_transfer_table_save_as_draft";

      const isAllCompleted = isAllDataCompleted({ data: dataClean });
      if (isAllCompleted && (isEmptyDuplicateData || isConfirmDuplicate)) {
        const finalData = OnSubmitLocalCreateFormatter(dataClean);
        onSubmitProps({
          type: isDraft || isMoveToDraft ? "draft" : "validate",
          array: isResubmit
            ? finalData.map((item) => {
                delete item.id;
                return item;
              })
            : finalData,
          batch_name: titleBatch,
        });
        return eventsTracker(evenstTrack, finalData);
      }
      if (!isAllCompleted) return setScreen("incomplete");
      if (!isEmptyDuplicateData) {
        setIsMoveToDraft(isDraft);
        toggleDuplicate();
        return;
      }
      if (isEmptyDuplicateData) {
        const isDataIncompleteExist = (data) => {
          if (data.every((item) => item !== undefined || item !== null))
            return false;
          return true;
        };
        setIsMoveToDraft(isDraft);
        return isDataIncompleteExist(dataIncomplete) && toggleDuplicate();
      }
      setScreen("incomplete");
    }
  };
  const setUpFirstValue = () => {
    const { title_batch, category } = getJackTransferData();
    setTitleBatch(title_batch ?? "");
    category && onChangeCategory(category);
  };

  useEffect(() => {
    setTitleBatch(titleBatchRaw);
    if (!titleBatchRaw) {
      setUpFirstValue();
    }
  }, [titleBatchRaw]);

  useEffect(() => {
    if (isNotFromExcel) return setData(defaultValues);

    const dataRaw = UseLocalTransferTable({
      data: defaultValues,
      headerArray,
      optionsBank,
    });
    setData(dataRaw);
  }, [Boolean(defaultValues?.length)]);

  useEffect(() => {
    if (isKick) return push("/local-transfer/create");
  }, [isKick]);

  const { navbarHeight, sidebarGap } = windowDimension();

  useEffect(() => {
    if (!afterAddRow) return;
    setTimeout(() => {
      refForScroll?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 500);
    setAfterAddRow(false);
  }, [afterAddRow]);

  useEffect(() => {
    if (!!data.length) {
      const dataProcessed = CheckerDataDuplicate({ data });
      setData(dataProcessed);
    }
  }, [data]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!titleBatch || (isRequiredCategory && isEmpty(category))) {
        toggleEditTitleAndCategory(); // Open modal if titleBatch is empty
      } else {
        closeEditTitleAndCategory(); // Close modal if titleBatch has a value
      }
    }, 500);

    return () => clearTimeout(timer); // Clean up the timer
  }, [titleBatch, isRequiredCategory]);

  const isAdditionalTableStyles =
    isScreenAllTransactions ||
    (!isScreenAllTransactions && filteredData?.length > 3 && !isSeeMore);

  return (
    <div style={{ margin: "0px 32px 0px 32px", position: "relative" }}>
      <div ref={ref}>
        {isResubmit && <ResubmitBanner batchName={titleBatch} />}
        {!isResubmit && (
          <MainTitleLocalTable
            title={titleBatch}
            isScreenIncomplete={isScreenIncomplete}
            dataIncompleteLength={dataIncompleteLength}
            isScreenDuplicate={isScreenDuplicate}
            dataDuplicateLength={dataDuplicateLength}
            onClick={onSubmit}
            onChangeTitle={handleSubmitTitleandCategory}
            loading={loading}
            isErrorCategory={isErrorCategory}
            setIsErrorCategory={setIsErrorCategory}
            category={category}
            handleChangeCategory={handleChangeCategory}
            isOpenEditTitleAndCategory={isOpenEditTitleAndCategory}
            toggleEditTitleAndCategory={toggleEditTitleAndCategory}
            closeEditTitleAndCategory={closeEditTitleAndCategory}
            categoryStore={categoryStore}
          />
        )}
        <div
          className={
            isOpenEditTitleAndCategory ? styles["tab-menu-disabled"] : ""
          }
        >
          <TabMenu
            componentsAboveScreen={
              <div>
                <SearchBarAndButton
                  data={data}
                  search={search}
                  headerArray={headerArray}
                  showDeleteRow={showDeleteRow}
                  funcPropsTable={funcPropsTable}
                  selectedIdsDelete={selectedIdsDelete}
                  isScreenDuplicate={isScreenDuplicate}
                  isScreenIncomplete={isScreenIncomplete}
                  formattedOptionsBank={formattedOptionsBank}
                  setData={setData}
                  onChange={(e) => setSearch(e)}
                  setAfterAddRow={(e) => setAfterAddRow(e)}
                  setShowDeleteRow={() => setShowDeleteRow((p) => !p)}
                />
                {isEmptyData && (
                  <EmptyStateSearchImages isLocalTransaction search={search} />
                )}
                <div ref={tableBodyRef} style={{ position: "relative" }}>
                  <TableCreateLocalTransfer
                    headerHeight={
                      (tableBodyRef?.current?.clientHeight || 0) +
                      navbarHeight +
                      sidebarGap +
                      100
                    }
                    setData={setData}
                    setSelectedIdsDelete={setSelectedIdsDelete}
                    isScreenAllTransactions={isScreenAllTransactions}
                    setAfterAddRow={(e) => setAfterAddRow(e)}
                    data={data}
                    isEmptyData={isEmptyData}
                    headerArray={headerArray}
                    filteredData={filteredData}
                    showDeleteRow={showDeleteRow}
                    handleSeeMoreLess={() => setIsSeeMore(true)}
                    funcPropsTable={funcPropsTable}
                    isScreenDuplicate={isScreenDuplicate}
                    isFirstScreenTable={isFirstScreenTable}
                    isScreenIncomplete={isScreenIncomplete}
                    isSeeMore={isSeeMore}
                    {...(isAdditionalTableStyles && {
                      stylesTable: {
                        borderLeft: `1px solid ${jackColors.neutral500}`,
                        borderRight: `1px solid ${jackColors.neutral500}`,
                        borderTop: `1px solid ${jackColors.neutral500}`,
                        border: 0,
                        borderRadius: 0,
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    })}
                  />
                </div>
                <ScrollUpButton />
              </div>
            }
            menus={[
              {
                screenName: tLocalTransfer("All transactions"),
                // screen: <Components />,
                screen: <></>,
              },
              ...(!!dataIncompleteLength
                ? [
                    {
                      screenName: "Incomplete",
                      // screen: <Components />,
                      screen: <></>,
                      number: dataIncompleteLength,
                      numberStyle: { background: jackColors.orangeF2 },
                    },
                  ]
                : []),
              ...(!!dataDuplicateLength
                ? [
                    {
                      screenName: "Duplicate",
                      // screen: <Components />,
                      screen: <></>,
                      number: dataDuplicateLength,
                      numberStyle: { background: jackColors.orangeF2 },
                    },
                  ]
                : []),
            ]}
            setIsSeeMore={setIsSeeMore}
          />
          {Boolean(
            isSeeMore && filteredData?.length > 3 && !isScreenAllTransactions
          ) && (
            <FloatingButtonLocal
              array={data}
              loading={loading}
              data={dataFloatingButton}
              showDeleteRow={showDeleteRow}
              funcPropsTable={funcPropsTable}
              isScreenIncomplete={isScreenIncomplete}
              isFirstScreenTable={isFirstScreenTable}
              handleSubmit={onSubmit}
              handleClick={() => setIsSeeMore((p) => !p)}
              onClickOtherFields={setIsFirstScreenTable}
              type="collapse_button"
            />
          )}
          {Boolean(titleBatch) && (
            <FloatingButtonLocal
              array={data}
              loading={loading}
              data={dataFloatingButton}
              showDeleteRow={showDeleteRow}
              funcPropsTable={funcPropsTable}
              isScreenIncomplete={isScreenIncomplete}
              isFirstScreenTable={isFirstScreenTable}
              handleSubmit={onSubmit}
              onClickOtherFields={setIsFirstScreenTable}
            />
          )}
        </div>

        <ConfirmationModalJack
          onClick={() => onSubmit(isResubmit ? "resubmit_back" : "back")}
          modal={isOpenBackModal}
          toggle={toggleOpenBackModal}
          title={tLocalTransfer("Go back to the previous page?")}
          text={tLocalTransfer(
            "Going back will erase your progress and you have to upload the recipient details again."
          )}
          buttonTextRight={tLocalTransfer("Back")}
          buttonTextLeft={tLocalTransfer("Cancel ")}
          img={imgBack}
          customContentClassName="modal-border-radius4"
        />
        <ConfirmationModalJack
          onClick={() => onSubmit("confirm_duplicate")}
          modal={isOpenDuplicate}
          toggle={toggleDuplicate}
          title={tLocalTransfer("We have noticed duplicate accounts")}
          text={tLocalTransfer(
            "By clicking Continue, you acknowledge that the transaction will be sent multiple times to the same recipients."
          )}
          buttonTextRight={tLocalTransfer("Confirm")}
          buttonTextLeft={tLocalTransfer("Back to Edit")}
          img={imgDuplicateModal}
          customContentClassName="modal-border-radius4"
          isCenteredTitle
        />
        <ConfirmationModalJack
          title={
            isAllChecked
              ? tLocalTransfer("Delete all transactions")
              : tLocalTransfer("Delete this transaction?")
          }
          modal={isOpenDeleteModal}
          img={imgDelete}
          toggle={toggleDeleteModal}
          onClick={() => onSubmit("submit_delete")}
          buttonTextRight={tLocalTransfer("Delete")}
          buttonTextLeft={tLocalTransfer("Back")}
          text={
            isAllChecked
              ? tLocalTransfer(
                  "You will delete all transactions and have to start over."
                )
              : tLocalTransfer(
                  "You will delete selected transaction from the list."
                )
          }
          customContentClassName="modal-border-radius4"
        />
        <ModalConfirmationCloseLocal
          toggle={toggleCloseModal}
          isOpen={isOpenCloseModal}
          product="local"
        />
        <div ref={refForScroll} style={{ height: 100 }} />
      </div>
    </div>
  );
};
