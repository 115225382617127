import { useRouter } from "next/router";
import { getUserRole } from "../../../../contexts/AuthContext";
import { MixedFont } from "../components";
import { Trans } from "react-i18next";

const useCategoryWelcomingUpdates = (t = () => {}) => {
  const { pathname } = useRouter();

  const { isEmployee } = getUserRole();

  switch (pathname) {
    case "/transactions":
    case "/my-activity":
    case "/company-transaction":
    case "/api-transaction":
      return [
        {
          name: t("New column for Category"),
          imgSrc: "/images/category-column-update.png",
          description: (
            <Trans
              i18nKey={t("Dynamic.welcome_modal_text_1")}
              components={[<span style={{ fontFamily: "GothamMedium" }} />]}
            />
          ),
        },
        {
          name: t("Transactions with no category"),
          imgSrc: "/images/category-alert-update.png",
          description: (
            <Trans
              i18nKey={t("Dynamic.welcome_modal_text_2")}
              components={[<span style={{ fontFamily: "GothamMedium" }} />]}
            />
          ),
        },
        !isEmployee && {
          name: t("Export transactions"),
          imgSrc: "/images/category-export-update.png",
          description: (
            <Trans
              i18nKey={t("Dynamic.welcome_modal_text_3")}
              components={[<span style={{ fontFamily: "GothamMedium" }} />]}
            />
          ),
        },
      ];

    case "/task":
      return [
        {
          name: t("New column for Category"),
          imgSrc: "/images/category-missing-details-update.png",
          description: (
            <Trans
              i18nKey={t("Dynamic.welcome_modal_text_4")}
              components={[<span style={{ fontFamily: "GothamMedium" }} />]}
            />
          ),
        },
        {
          name: t("Transactions with no category"),
          imgSrc: "/images/category-select-update.png",
          description: (
            <Trans
              i18nKey={t("Dynamic.welcome_modal_text_5")}
              components={[<span style={{ fontFamily: "GothamMedium" }} />]}
            />
          ),
        },
      ];

    default:
      break;
  }
};

export default useCategoryWelcomingUpdates;
