import { useEffect } from "react";

export const useEscapeKeypress = ({ ref, escapeTo }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        ref.current.click();
        escapeTo();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};

export const useCustomKeypress = ({
  ref,
  action,
  key,
  dependency,
  isError = false,
}) => {
  useEffect(() => {
    if (isError) return null;
    const handleKeyDown = (event) => {
      if (event.key === key) {
        ref.current?.click();
        action();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dependency]);
};
