import { isEmpty } from "lodash";
import { booleanState } from "../../../../components/Status";

const retryResubmitValidator = ({
  purpose = "",
  trxStatus,
  drafter,
  releaser,
  warningType,
  failedTrxCount,
}) => {
  const { isFailed, isPartialFailed, isDeclined } = booleanState(trxStatus);

  const isFailedStatus = isFailed || isPartialFailed || isDeclined;

  const hasDrafter = !isEmpty(drafter);
  const hasReleaser = !isEmpty(releaser);
  const hasFailedTrxCount = failedTrxCount > 0;
  const hasWarningType = purpose === "banner" ? true : !!warningType;

  const isValid =
    hasDrafter &&
    hasReleaser &&
    hasFailedTrxCount &&
    isFailedStatus &&
    hasWarningType;

  return isValid;
};

export default retryResubmitValidator;
