import { jackColors } from "assets/colors";
import JackIcons from "assets/jackIcons/typescript/parent";
import { useLayout } from "contexts/Layout/parent";
import React, { Fragment, useEffect, useState } from "react";

type Props = {
  scrollThreshold?: number;
};

export default function ScrollUpButton({ scrollThreshold = 300 }: Props) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { scrollTop, scrollToTop } = useLayout();
  // // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (scrollTop > scrollThreshold) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    toggleVisibility();
  }, [scrollTop]);
  return (
    <Fragment>
      {isVisible && (
        <div
          onClick={scrollToTop}
          className="d-flex justify-content-center"
          style={{
            position: "fixed",
            bottom: 42,
            right: "42px",
            backgroundColor: jackColors.neutral900,
            boxShadow:
              "0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16)",
            borderRadius: 100,
            width: 48,
            height: 48,
            cursor: "pointer",
          }}
        >
          <JackIcons
            name="ChevronUp"
            style={{ width: 32, height: 32 }}
            fill={jackColors.greenB9}
          />
        </div>
      )}
    </Fragment>
  );
}
