import { useMutation } from "tools/api";
import {
  ApiRawResponse,
  CreateApiRawResponse,
  CreateRecipientsApiResponse,
  CreateRecipientsPayload,
  CreateTrxApiResponse,
  CreateTrxPayload,
  ValidateApiRawResponse,
  ValidateApiResponse,
  ValidateRecipientsPayload,
} from "./types";
import { error } from "console";
import { ToasterHook } from "contexts/ToasterContext";
import { isFunction } from "lodash";

export const useValidate = ({
  afterSuccess,
  triggerFromOutside
}: {
  afterSuccess?: (res: ValidateApiResponse) => void;
  triggerFromOutside?: (val: string) => void
}) => {
  const { errorToasterApi } = ToasterHook();
  const url = `/local_recipients/validate_recipient`;
  const {
    result: recipients,
    loading,
    mutation: validate,
  } = useMutation<ValidateRecipientsPayload, ValidateApiResponse>({
    url,
    method: "post",
    afterSuccess: (data) => {
      if (afterSuccess) {
        afterSuccess(data);
      }
    },
    resultFormatter: (response: ValidateApiRawResponse) => {
      return {
        data: response?.data?.data,
      };
    },
    withError: false,
    handleError: (error) => {
      const response = error?.response
      const retryAfter = response?.data?.retry_after
      const status = response?.status
      if (status === 429) {
          isFunction(triggerFromOutside) && triggerFromOutside(retryAfter <= 60 ? "minute_limit" : "daily_limit")
      } else {
        errorToasterApi(error);
      }
      Object.keys(error).forEach(item => {
        console.log(item, error[item])
      })
      console.log(error.error)
    }
  });
  return { recipients, validate, loading };
};

export const useCreateRecipients = ({
  afterSuccess,
}: {
  afterSuccess?: (res: CreateRecipientsApiResponse) => void;
}) => {
  const url = `/local_recipients/bulk_create`;
  const {
    result: recipients,
    loading,
    mutation: create,
    setResult,
  } = useMutation<CreateRecipientsPayload, CreateRecipientsApiResponse>({
    url,
    method: "post",
    afterSuccess: (data) => {
      if (!data) return;
      if (typeof afterSuccess == "function") {
        afterSuccess(data);
      }
    },
    resultFormatter: (response: ApiRawResponse) => {
      return {
        data: response?.data?.data,
      };
    },
  });
  return { recipients, create, loading };
};

// tambah ngeget recipient list

// tambah create local trf
export const useCreateLocal = ({
  afterSuccess,
}: {
  afterSuccess?: (data: CreateTrxApiResponse) => void;
}) => {
  const url = `/local_transaction_batches`;
  const {
    loading,
    mutation: create,
    result,
  } = useMutation<CreateTrxPayload, CreateTrxApiResponse>({
    url,
    method: "post",
    resultFormatter: (response: CreateApiRawResponse) => {
      return {
        data: response?.data?.data,
        status: response?.status,
      };
    },
    afterSuccess: (data) => {
      if (data === null) return;
      if (typeof afterSuccess == "function") afterSuccess(data);
    },
  });
  return { create, loading, result };
};
