import { isEmpty } from "lodash";
import { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { useModalHook } from "../../../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { useConstants } from "../../../../../contexts/ConstantsContext/parent";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { useMutation } from "../../../../../tools/api";
import { useClickOutside } from "../../../../../universalFunctions/useClickOutside";
import { useAvailableCategories, useCategoryFlag } from "../../hooks";
import CategoryIcon from "../CategoryIcon";
import CategorySelectionDropdown from "../CategorySelectionDropdown";
import Category from "../CategorySelectionDropdown/Category";
import ContainedCategoryIcon from "../ContainedCategoryIcon";
import styles from "./CategorySelection.module.css";

const CategorySelection = ({
  model = "",
  isError = false,
  isSimple = false,
  isLoading = false,
  isForTrxDetails = false,
  showAllCategories = false,
  isRounded = false,
  errorMessage: errorMessageRaw = "Please fill out this field",
  withRightIcon = true,
  style = {},
  selectedCategory = {},
  containedCategoryIconStyle = {},
  onSelect,
  ...props
}) => {
  const { t } = useTranslation("category/index");
  const errorMessage = Boolean(errorMessageRaw)
    ? t(errorMessageRaw)
    : t("Please fill out this field");
  const { isOpen, open, close } = useModalHook();
  const {
    isOpenCategoryModalEdit,
    openCategoryModalEdit,
    closeCategoryModalEdit,
  } = props;

  const ref = useRef();

  const { isDeactivatedCategory, isActiveCategory, isRequiredCategory } =
    useCategoryFlag();
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  useClickOutside({
    ref,
    clickOutside: close,
  });

  const { hasCategoryList } = useAvailableCategories();

  const isRequired = isRequiredCategory;

  const opacity = isLoading ? "0.4" : "1";

  if (isDeactivatedCategory || !isActiveCategory) return null;
  if (!!model) {
    switch (model) {
      case "with-edit-button":
        const hasCategory = !(isEmpty(selectedCategory) || !selectedCategory);

        return (
          <div
            ref={ref}
            className={styles["category-selection_details"]}
            style={{
              marginBottom: hasCategoryList ? "32px" : "0px",
              ...style,
              opacity,
            }}
          >
            {hasCategoryList && (
              <Fragment>
                <div
                  className={styles["input_details"]}
                  onClick={isOpen ? close : open}
                >
                  <div className={styles["left-section"]}>
                    <ContainedCategoryIcon
                      name={selectedCategory?.icon}
                      color={selectedCategory?.color}
                      style={{ borderRadius: "100px" }}
                    />
                    <div
                      className={styles["text-container"]}
                      style={{ marginTop: !hasCategory && "-4px" }}
                    >
                      <div className={styles["title"]}>
                        <GothamMedium
                          className="font12"
                          style={{ color: jackColors.neutral900 }}
                        >
                          {t("Transaction Category")}
                        </GothamMedium>
                      </div>
                      <GothamRegular
                        woFontColor
                        className="font12"
                        style={{
                          color:
                            isRequired && !hasCategory
                              ? jackColors.redE7
                              : jackColors.neutral600,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "280px",
                        }}
                      >
                        {selectedCategory?.name ??
                          t("Select your transaction category")}
                      </GothamRegular>
                    </div>
                  </div>
                  <JackIcons
                    name={isOpen ? "close-circle" : "edit-outline"}
                    fill={jackColors.neutral900}
                  />
                </div>
              </Fragment>
            )}
            <CategorySelectionDropdown
              isOpen={isOpen}
              selectedCategory={selectedCategory}
              showAllCategories={showAllCategories}
              style={{ top: "68px" }}
              onSelect={(category) => {
                onSelect && onSelect(category);
                close();
              }}
            />
          </div>
        );

      case "category_modal_edit":
        return (
          <div
            ref={ref}
            className={styles["category-selection_details_modal_edit"]}
            style={{
              ...style,
              opacity,
            }}
          >
            {!isDeactivatedCategory ? (
              <>
                {hasCategoryList && (
                  <Fragment>
                    <div
                      className={`${styles["input_details_modal_edit"]} ${
                        isError
                          ? styles["input_category_error"]
                          : !isEmpty(selectedCategory)
                          ? styles["input_category_background_changed"]
                          : styles["input_category_default_background"]
                      }`}
                      onClick={
                        isOpenCategoryModalEdit
                          ? closeCategoryModalEdit
                          : openCategoryModalEdit
                      }
                    >
                      <div className={styles["left-section"]}>
                        <Category
                          className="font20"
                          key={selectedCategory.id}
                          isSimple
                          category={selectedCategory}
                          categoryIconStyle={{ scale: "1" }}
                          titleCategoryStyle={{
                            fontFamily: "GothamMedium",
                            fontSize: 20,
                            lineHeight: 24,
                          }}
                          isRequiredCategory={isRequiredCategory}
                          disableHoverEffect
                        />
                      </div>
                      <JackIcons
                        name="chevron-down"
                        fill={jackColors.neutral600}
                        style={{ scale: "0.8" }}
                      />
                    </div>
                  </Fragment>
                )}
                {isError && (
                  <GothamRegular
                    woFontColor
                    className="font10"
                    style={{
                      color: jackColors.redE7,
                      marginTop: "-4px",
                      marginBottom: "-15px",
                    }}
                  >
                    {errorMessage}
                  </GothamRegular>
                )}
                <CategorySelectionDropdown
                  isOpen={isOpenCategoryModalEdit}
                  selectedCategory={selectedCategory}
                  showAllCategories={showAllCategories}
                  type="from_table_local"
                  style={{
                    top: "68px",
                  }}
                  onSelect={(category) => {
                    onSelect(category);
                    closeCategoryModalEdit();
                  }}
                />
              </>
            ) : (
              <Fragment />
            )}
          </div>
        );

      default:
        break;
    }
  }

  if (isSimple) {
    const borderColor = () => {
      if (isError) return jackColors.redE7;
      if (isOpen) return jackColors.neutral900;
      return jackColors.neutral500;
    };

    return (
      <div
        ref={ref}
        className={isRounded ? "" : styles["category-selection_simple"]}
        style={{
          marginBottom: hasCategoryList && !isRounded ? "32px" : "0px",
          ...style,
          opacity,
        }}
      >
        {hasCategoryList && (
          <Fragment>
            <div
              className={styles["input_simple"]}
              style={{
                borderColor: borderColor(),
                ...(isRounded && { borderRadius: 100 }),
                justifyContent: "center",
                alignItems: "center",
              }}
              {...(!isRounded && { onClick: isOpen ? close : open })}
            >
              <div>
                <ContainedCategoryIcon
                  name={selectedCategory?.icon}
                  color={selectedCategory?.color}
                  style={{
                    scale: "0.8",
                    ...(isRounded && { borderRadius: 100 }),
                    ...containedCategoryIconStyle,
                  }}
                />
                <GothamMedium
                  woFontColor
                  style={{
                    color: selectedCategory?.name
                      ? jackColors.neutral900
                      : jackColors.neutral600,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "150px",
                  }}
                >
                  {selectedCategory?.name
                    ? selectedCategory?.name
                    : isRounded
                    ? tLocalTransfer("Select Transaction Category")
                    : tLocalTransfer("Select category")}
                </GothamMedium>
              </div>
              {Boolean(withRightIcon) && (
                <JackIcons
                  name={isOpen ? "chevron-up" : "chevron-down"}
                  fill={jackColors.neutral900}
                />
              )}
            </div>
            {isError && (
              <GothamRegular
                woFontColor
                className="font10"
                style={{ color: jackColors.redE7, marginTop: "-4px" }}
              >
                {errorMessage}
              </GothamRegular>
            )}
          </Fragment>
        )}
        {Boolean(!isRounded) && (
          <CategorySelectionDropdown
            isSimple
            isOpen={isOpen}
            showAllCategories={showAllCategories}
            selectedCategory={selectedCategory ?? {}}
            style={{ top: "52px" }}
            onSelect={(category) => {
              onSelect(category);
              close();
            }}
          />
        )}
      </div>
    );
  }

  if (isForTrxDetails) {
    const hasCategory = !(isEmpty(selectedCategory) || !selectedCategory);

    return (
      <div
        ref={ref}
        className={styles["category-selection_details"]}
        style={{
          marginBottom: hasCategoryList ? "32px" : "0px",
          ...style,
          opacity,
        }}
      >
        {hasCategoryList && (
          <Fragment>
            <div
              className={styles["input_details"]}
              onClick={isOpen ? close : open}
            >
              <div className={styles["left-section"]}>
                <ContainedCategoryIcon
                  name={selectedCategory?.icon}
                  color={selectedCategory?.color}
                  style={{ borderRadius: "100px" }}
                />
                <div
                  className={styles["text-container"]}
                  style={{ marginTop: !hasCategory && "-4px" }}
                >
                  <div className={styles["title"]}>
                    <GothamMedium
                      className="font12"
                      style={{ color: jackColors.neutral900 }}
                    >
                      {t("Transaction Category")}
                    </GothamMedium>
                    {!hasCategory && (
                      <JackIcons
                        name="alert-circle-outline"
                        fill={jackColors.yellowFC}
                        style={{ scale: "0.8" }}
                      />
                    )}
                  </div>
                  <GothamRegular
                    woFontColor
                    className="font12"
                    style={{
                      color: jackColors.neutral600,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "280px",
                    }}
                  >
                    {selectedCategory?.name ??
                      t("Please select a category for this transaction")}
                  </GothamRegular>
                </div>
              </div>
              <JackIcons
                name={isOpen ? "close-circle" : "chevron-right"}
                fill={jackColors.neutral900}
              />
            </div>
          </Fragment>
        )}
        <CategorySelectionDropdown
          isOpen={isOpen}
          selectedCategory={selectedCategory}
          showAllCategories={showAllCategories}
          style={{ top: "68px" }}
          onSelect={(category) => {
            onSelect(category);
            close();
          }}
        />
      </div>
    );
  }

  return (
    <div
      ref={ref}
      className={styles["category-selection"]}
      style={{
        marginBottom: hasCategoryList ? "32px" : "0px",
        ...style,
        opacity,
      }}
    >
      {hasCategoryList && (
        <Fragment>
          <div className={styles["label"]}>
            <GothamRegular
              woFontColor
              className="font12"
              style={{
                color: isError ? jackColors.redE7 : jackColors.neutral900,
              }}
            >
              {t("Transaction Category")}
              {isRequired ? (
                <span className="font12" style={{ color: jackColors.redE7 }}>
                  *
                </span>
              ) : (
                <span
                  className="font10"
                  style={{ color: jackColors.neutral700 }}
                >
                  {" "}
                  ({t("Optional")})
                </span>
              )}
            </GothamRegular>
          </div>

          <div
            className={styles["input"]}
            style={{
              borderColor: isError
                ? jackColors.redE7
                : isOpen && jackColors.neutral900,
            }}
            onClick={isOpen ? close : open}
          >
            <div>
              <CategoryIcon
                name={selectedCategory.icon}
                fill={selectedCategory.color}
              />
              <GothamRegular
                woFontColor
                style={{
                  color: selectedCategory.name
                    ? jackColors.neutral900
                    : jackColors.neutral600,
                }}
              >
                {selectedCategory.name || t("Select category")}
              </GothamRegular>
            </div>
            <JackIcons
              name="chevron-down"
              fill={jackColors.neutral600}
              style={{ scale: "0.8" }}
            />
          </div>

          {isError && (
            <GothamRegular
              woFontColor
              className="font10"
              style={{ color: jackColors.redE7, marginTop: "-4px" }}
            >
              {errorMessage}
            </GothamRegular>
          )}
        </Fragment>
      )}

      <CategorySelectionDropdown
        isOpen={isOpen}
        selectedCategory={selectedCategory}
        showAllCategories={showAllCategories}
        style={{ top: "76px" }}
        onSelect={(category) => {
          onSelect(category);
          close();
        }}
      />
    </div>
  );
};

export const CategorySelectionNew = ({
  url = "",
  payloadFormatter = (category) => ({ category_id: category?.id }),
  afterSuccessUpdateCategory = () => {},
  successMsg = "Transaction category has been updated.",
  ...props
}) => {
  const { onSelect = () => {} } = props ?? {};

  const { successToaster } = ToasterHook();

  const { categories } = useConstants();

  const { mutation: updateCategory, loading: loadingUpdateCategory } =
    useMutation({
      url,
      afterSuccess: (res, payload) => {
        const category = categories?.find(
          ({ id }) => id === payload?.category_id
        );

        afterSuccessUpdateCategory(res, category);
        successToaster({ msg: successMsg });
      },
    });

  const handleSelect = (category) => {
    onSelect(category);

    if (!url) return;

    const payload = payloadFormatter(category);
    updateCategory(payload);
  };

  return (
    <CategorySelection
      {...props}
      isLoading={loadingUpdateCategory}
      onSelect={handleSelect}
    />
  );
};

export default CategorySelection;
