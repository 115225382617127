import React, { useEffect } from "react";
import { useModalHook } from "../../../../components/Modals";
import { UseFormReturn } from "react-hook-form";
import { downloadApi } from "tools/api";
import { eventsTracker } from "universalFunctions/events";
import {
  DummyLocalType,
  FormDataLocalTransfer,
  OnSubmitProps,
} from "../typeLocalTransfer";
import ModalOptionalCreateTransfer from "./modal";
import { HeaderDataTableLocal } from "./table/data";
import { ToasterHook } from "contexts/ToasterContext";
import { useTranslation } from "react-i18next";

export const dummyDataLocal: DummyLocalType[] = [
  { name: "delete" },
  { name: "number" },
  { name: "name", value: "", label: "Name" },
  { name: "bank", value: "", label: "Bank" },
  { name: "account_number", value: "", label: "Bank Account Number" },
  { name: "notes", value: "", label: "Notes" },
  { name: "email", value: "", label: "Email" },
  { name: "amount", value: "", label: "Amount" },
  { name: "action" },
];

interface HandleClickParams {
  values: string;
  type: string;
}

type LocalTransferSecondFormProps = {
  onSubmit: OnSubmitProps;
  toggle: () => void;
  loading: boolean;
  useFormObj: UseFormReturn<FormDataLocalTransfer>;
  selectedItem: string;
};

const LocalTransferSecondForm: React.FC<LocalTransferSecondFormProps> = ({
  onSubmit,
  toggle,
  loading,
  useFormObj,
  selectedItem,
}) => {
  const { headerArray, tableBodyRef } = HeaderDataTableLocal();
  const { isOpen: isOpenModalBatch, toggle: toggleModalBatch } = useModalHook();
  const { successSnackBar } = ToasterHook();
  const { handleSubmit } = useFormObj;
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const afterSuccess = () => {
    eventsTracker("download_template_local_transfer");
    successSnackBar?.({
      msg: tLocalTransfer("Template has been downloaded."),
      showClose: true,
    });
  };

  const toggleModalBatchClose = () => {
    toggle();
    toggleModalBatch();
  };

  const onClickDownload = () => {
    downloadApi({
      url: "/local_transaction_batches/template_batch_import?format_template=excel",
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      fileName: "Jack-Template-Local-Transfer.xlsx",
      afterSuccess,
    });
  };

  const setCombineData = (values: [DummyLocalType[]]) =>
    handleSubmit(() => onSubmit({ type: "second", values: values }))();

  const handleClick = ({ values, type }: HandleClickParams) => {
    const isBack = type === "back";
    const isNext = type === "next";
    const isEmptyTable = values === "empty_table";

    if (isEmptyTable) return setCombineData([dummyDataLocal]);
    if (isNext) {
      handleSubmit(() => onSubmit({ type: "second", values: values }))();
    }

    if (isBack) return toggleModalBatchClose();
  };

  useEffect(() => {
    toggleModalBatch();
  }, []);

  return (
    <div ref={tableBodyRef}>
      <ModalOptionalCreateTransfer
        toggle={toggleModalBatchClose}
        isOpen={isOpenModalBatch}
        useFormObj={useFormObj}
        onClickDownload={onClickDownload}
        handleClick={handleClick}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default LocalTransferSecondForm;
