import React, { useEffect, useRef } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import LoadingSpinner from "../../../../../components/Loading";
import { TextFieldJackState } from "../../../../../components/inputs/textfield";

export const NakedTransactionFiltersSearchbox = ({
  onChange,
  value,
  style,
  placeholder,
  loading,
  ...props
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.value = value;
  }, []);

  return (
    <div style={{ width: 360, ...style }}>
      <TextFieldJackState
        value={value}
        onChange={loading ? () => {} : onChange}
        inputRef={inputRef}
        icon={
          loading ? (
            <LoadingSpinner
              style={{ height: 20, width: 20, marginRight: 4 }}
              size={16}
            />
          ) : (
            <JackIcons
              name="search"
              fill={jackColors.greyBB}
              style={{ width: 20, height: 20 }}
              styleDiv={{ marginBottom: 4, marginRight: 4 }}
            />
          )
        }
        placeholder={loading ? "" : placeholder}
        style={{ marginBottom: 0 }}
        {...props}
      />
    </div>
  );
};
