import React from "react";
import { colors, jackColors } from "../../../assets/colors";
import validating from "../../../assets/images/validating-screen.svg";
import validateGif from "../../../assets/images/validating-local.gif";
import { ProgressBar } from "../../../components/progressBar";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { windowDimension } from "../../../components/tools";
import { useTranslation } from "react-i18next";

export const ValidatingScreen = ({
  processed,
  total,
  title = "Validating bank accounts",
}) => {
  const {
    screenHeight,
    sidebarGap,
    isTabOrPhone,
    width: docWidth,
  } = windowDimension();
  const { t } = useTranslation("payroll/create");
  const width = isTabOrPhone ? docWidth - 40 : 240;
  return (
    <div
      className="align-items-center justify-content-center d-flex"
      style={{
        height: screenHeight - sidebarGap * 2,
        flexDirection: "column",
      }}
    >
      <img src={validateGif} style={{ width, marginBottom: 32 }} />
      <GothamMedium className="font20" style={{ marginBottom: 16 }}>
        {title}
      </GothamMedium>
      <GothamRegular style={{ color: jackColors.neutral700 }}>
        {t("Please don't close this page yet, we're still working on it.")}
      </GothamRegular>
      <ProgressBar width={430} progress={processed / total} />
      <GothamRegular>
        {processed} {t("of")} {total} {t("transactions")}
      </GothamRegular>
    </div>
  );
};
