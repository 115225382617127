import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { fetch } from "../../../tools/api";
import { taskResultFormatter } from "../../taskComponents/logics/formatters";
import { dateUrlMaker, modalDataFormatter } from "./modals.js/formatter";

export const useSchedulePaymentCarDashboard = () => {
  const [page, setPage] = useState(1);
  const { data, loading, refetch } = fetch({
    url: `/scheduled_payments/index_for_dashboard?page=${page}&per_page=5`,
    woInit: true,
  });

  const { total_page, records_of_scheduled_payments } = data;
  const dataEmpty = isEmpty(records_of_scheduled_payments);

  const disablePrev = page == 1;
  const onClickPrev = useCallback(() => {
    if (disablePrev) return;
    return setPage((p) => p - 1);
  }, [disablePrev]);

  const disableNext = page == total_page || dataEmpty;
  const onClickNext = useCallback(() => {
    if (disableNext) return;
    return setPage((p) => p + 1);
  }, [disableNext]);

  useEffect(() => {
    if (page) refetch();
  }, [page]);
  return {
    data: records_of_scheduled_payments,
    loading,
    disableNext,
    disablePrev,
    onClickPrev,
    onClickNext,
    refetch,
  };
};

export const useModalHookData = () => {
  const [data, setData] = useState([]);

  const isOpen = !isEmpty(data);
  const close = () => setData([]);
  const open = (data) => setData(data);

  return { data, isOpen, close, open };
};

export const useScheduleIndexModal = ({ date }) => {
  const [page, setPage] = useState(1);
  const [openedTable, setOpenedTable] = useState("");

  const urlRaw = dateUrlMaker(date);
  const pageQuery = `&page=${page}&per_page=5`;
  const url = urlRaw + pageQuery;
  const { data, refetch, loading, setData } = fetch({
    url,
    formatter: taskResultFormatter,
    woInit: true,
  });

  useEffect(refetch, [page]);

  const seeMore = () => {
    setOpenedTable("index");
    setPage((prev) => prev + 1);
  };

  const seeLess = () => {
    setData({});
    setPage(1);
    setOpenedTable("");
  };

  const handleSee = useCallback((name) => {
    if (!name) return seeLess();
    return seeMore();
  }, []);

  useEffect(() => {
    if (date) {
      setData({});
      setPage(1);
    }
  }, [date]);

  const { total_schedules, data: schedules } = data;
  const hasMore = (schedules ?? []).length < total_schedules;

  const newData = modalDataFormatter(data);
  return {
    data: newData,
    refetch,
    loading,
    openedTable,
    seeLess,
    seeMore,
    hasMore,
    handleSee,
  };
};
