import { useEffect, useRef, useState } from "react";
import { colors } from "../../../../../../assets/colors";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { TransactionFilterButtons } from "../../../../../transactionsComponents/transactions/filters/buttons/parent";
import { TransactionFiltersSearchbox } from "../../../../../transactionsComponents/transactions/filters/searchbox/parent";
import RadioButton from "../../components/RadioButton";
import { TableJackSimple } from "../../../../../../components/Table/parent";
import { useCardTransactionTable } from "./hooks";
import ViewportList from "react-viewport-list";
import { TransitionTr } from "../../../../../../components/Transition";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { GothamBold, GothamRegular } from "../../../../../../components/Text";
import emptySearchIllustration from "../../../../../../assets/jackImages/empty-search-illustration.svg";
import nothingHereIllustration from "../../../../../../assets/jackImages/nothing-here-illustration.svg";
import { useCardDetailContext } from "../../../../../../contexts/Cards/CardDetailContext";
import { useTranslation } from "react-i18next";

const CardTransactionFilter = ({ woFilter, loading }) => {
  const { t } = useTranslation("credit/credit");
  const handleClick = (value) => {
    if (!value) return setActiveFilters(activeFiltersRaw);
    setActiveFilters((prev) => [...prev, value]);
  };

  const sortList = [
    { label: t("Newest"), value: "date desc" },
    { label: t("Oldest"), value: "date asc" },
  ];

  const filterProps = {
    activeFilters: ["transaction_status"],
    filters: [
      {
        label: t("Transaction Status"),
        name: "transaction_status",
        type: "status",
        icon: "loader-outline",
        array: [
          {
            label: "Success",
            value: "approved",
          },
          {
            label: "Failed",
            value: "failed",
          },
          {
            label: "Cancelled",
            value: "cancelled",
          },
          {
            label: "Returned",
            value: "returned",
          },
        ],
      },
    ],
    onClick: handleClick,
    woResetFilter: true,
    isModal: true,
  };

  const styles = {
    container: {
      marginTop: "32px",
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      width: "100%",
      marginBottom: "18px",
    },
    search: {
      width: "100%",
    },
    sortAndFilter: {
      display: woFilter ? "none" : "grid",
      gridTemplateColumns: "2fr 1fr",
    },
  };

  return (
    <div style={styles.container}>
      <TransactionFiltersSearchbox
        style={styles.search}
        queryName="merchant_name"
        placeholder={t("Search keywords")}
        runQueryName="runQueryTransaction"
      />

      <div style={styles.sortAndFilter}>
        <RadioButton
          queryName="sort_by"
          label={t("Sort By")}
          list={sortList}
          defaultValue={sortList[0].value}
          runQueryName="runQueryTransaction"
          loading={loading}
        />
        <TransactionFilterButtons
          runQueryName="runQueryTransaction"
          {...filterProps}
        />
      </div>
    </div>
  );
};

const CardTransaction = () => {
  const { t } = useTranslation("credit/credit");
  const { i18n } = useTranslation();
  const {
    cardDetail,
    updatedTransaction,
    closeTransactionList,
    selectedTransaction,
    setSelectedTransaction,
    setUpdatedTransaction,
  } = useCardDetailContext();

  const [canRefetch, setCanRefetch] = useState(false);
  const {
    headerTable,
    bodyTable,
    transactions,
    loading: loadingTransactions,
    setTransactions,
    nextPage,
  } = useCardTransactionTable({
    cardDetail,
    t,
    i18n,
  });

  const tbodyRef = useRef(null);
  const { query, replace, pathname } = useRouter();
  const { status, merchant_name, originator_id } = query;
  const isSearching = merchant_name;
  const isFiltering = status || merchant_name;

  const modalRef = useRef();

  useEffect(() => {
    const element = document.getElementById("right-modal-1");
    element.addEventListener("scroll", () => {
      if (
        element.scrollTop + element.clientHeight >
        element.scrollHeight - 30
      ) {
        setCanRefetch(true);
      }
    });
    modalRef.current = element;
  }, []);

  useEffect(() => {
    if (originator_id && bodyTable) {
      const newData = bodyTable.find((e) => e.rawData.id === +originator_id);
      setSelectedTransaction(newData?.rawData);
    }
  }, [originator_id, bodyTable.length]);

  useEffect(() => {
    if (!canRefetch) return;
    nextPage();
    setCanRefetch(false);
  }, [canRefetch]);

  useEffect(() => {
    if (updatedTransaction) {
      const newTransactions = [...transactions];
      const index = newTransactions.findIndex(
        (transaction) => transaction.id === updatedTransaction.id
      );
      newTransactions[index] = updatedTransaction;
      setTransactions(newTransactions);
      setUpdatedTransaction(null);
    }
  }, [updatedTransaction]);

  const handleResetFilter = () => {
    replace({
      pathname,
      query: {
        merchant_name,
        sort_by: "date desc",
      },
    });
  };

  const leftIcon = (
    <JackIcons name="cornerUpLeftOutline" fill={colors.neutral900} />
  );

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "12px",
    },
    notFoundHeader: {
      color: colors.neutral900,
    },
    notFoundDescription: {
      color: colors.neutral800,
    },
    th: {
      height: "40px",
      top: "-16px",
    },
  };

  const contentDecider = () => {
    if (isEmpty(bodyTable)) {
      if (loadingTransactions)
        return (
          <>
            <div
              style={{
                ...styles.container,
                height: "calc(100vh - 300px)",
              }}
            ></div>
          </>
        );

      if (isSearching)
        return (
          <>
            <div
              style={{
                ...styles.container,
                height: "calc(100vh - 300px)",
              }}
            >
              <img src={emptySearchIllustration} />
              <GothamBold style={styles.notFoundHeader}>
                {t("We can't find what you’re looking for")}
              </GothamBold>
              <GothamRegular style={styles.notFoundDescription}>
                {t("Let’s try another keyword")}
              </GothamRegular>
            </div>
          </>
        );

      if (isFiltering)
        return (
          <>
            <div
              style={{
                ...styles.container,
                height: "calc(100vh - 400px)",
              }}
            >
              <img src={emptySearchIllustration} />
              <GothamBold style={styles.notFoundHeader}>
                {t("We can’t find what you‘re looking for")}
              </GothamBold>
              <GothamRegular style={styles.notFoundDescription}>
                {t("Try removing some filter")}
              </GothamRegular>
              <GothamRegular style={styles.notFoundDescription}>
                {t("or")}
              </GothamRegular>
              <ButtonJack onClick={handleResetFilter}>
                {t("Reset Filter")}
              </ButtonJack>
            </div>
          </>
        );

      return (
        <div
          style={{
            ...styles.container,
            height: "calc(100vh - 100px)",
          }}
        >
          <img src={nothingHereIllustration} />
          <GothamBold>{t("Nothing to see here")}</GothamBold>
          <GothamRegular style={styles.notFoundDescription}>
            {t("Your card transactions will appear here")}
          </GothamRegular>
        </div>
      );
    }

    return (
      <>
        <TableJackSimple
          tbodyRef={tbodyRef}
          headerTable={headerTable}
          thStyle={styles.th}
          bodyComponent={
            <ViewportList
              viewportRef={tbodyRef}
              items={bodyTable}
              itemMinSize={100}
              margin={8}
              overscan={10}
            >
              {({ rawData, tableRow }, index) => {
                const isSelected = rawData.id === selectedTransaction?.id;
                return (
                  <TransitionTr
                    isQuick
                    counter={index}
                    perPage={20}
                    key={index}
                    onClick={() => {
                      setSelectedTransaction(rawData);
                    }}
                    isSelected={isSelected}
                  >
                    {tableRow.map((item, index) => {
                      return <td key={index}>{item}</td>;
                    })}
                  </TransitionTr>
                );
              }}
            </ViewportList>
          }
        />
      </>
    );
  };
  const handleCloseTrx = () => {
    delete query?.originator_id;
    closeTransactionList();
  };
  const content = contentDecider();

  const isShowCardTransactionFilter =
    (isEmpty(bodyTable) &&
      (loadingTransactions || isSearching || isFiltering)) ||
    !isEmpty(bodyTable);
  const isWoFilter = isEmpty(bodyTable) && (loadingTransactions || isSearching);

  return (
    <div>
      <ButtonJack leftIcon={leftIcon} type="outline" onClick={handleCloseTrx}>
        {t("Close Transactions")}
      </ButtonJack>
      {isShowCardTransactionFilter && (
        <CardTransactionFilter
          woFilter={isWoFilter}
          loading={loadingTransactions}
        />
      )}
      {content}
    </div>
  );
};

export default CardTransaction;
