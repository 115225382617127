import React, { useState, useRef } from "react";
import bcaIcon from "../../../../assets/images/bca-icon.svg";
import bniIcon from "../../../../assets/images/bni-icon.svg";
import briIcon from "../../../../assets/images/bri-icon.svg";
import danamonIcon from "../../../../assets/images/danamon-icon.svg";
import mandiriIcon from "../../../../assets/images/mandiri-icon.svg";
import caretDown from "../../../../assets/images/caret-down-pink.svg";
import copyIcon from "../../../../assets/images/copy-icon.svg";
import { bankLists } from "../../../../assets/data";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../../components/Text";
import { colors, jackColors } from "../../../../assets/colors";
import { Tooltip } from "reactstrap";
import { useModalHook } from "../../../../components/Modals";
import { useClickOutside } from "../../../../universalFunctions/useClickOutside";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { useTranslation } from "react-i18next";

export const BankDetailsSection = ({ data, bank, selectedBank }) => {
  const { t } = useTranslation("international/details");
  const bankOptions = [
    { label: "BCA", value: "BCA", icon: bcaIcon },
    { label: "BNI", value: "BNI", icon: bniIcon },
    { label: "BRI", value: "BRI", icon: briIcon },
    { label: "Danamon", value: "Danamon", icon: danamonIcon },
    { label: "Mandiri", value: "Mandiri", icon: mandiriIcon },
  ];

  return (
    <div style={{ marginTop: 32 }}>
      <GothamMedium style={{ marginBottom: 16 }}>
        {t("Select Bank")}
      </GothamMedium>
      <BankSelection
        option={bankLists}
        bank={bank}
        setActiveBank={(val) => selectedBank(val)}
      />
      <GothamMedium style={{ marginBottom: 16 }}>
        {t("Transfer to ")}
      </GothamMedium>
      <BankDetailsData bank={bank} data={data} />
    </div>
  );
};

const BankSelection = ({ option, bank, setActiveBank }) => {
  const [isHover, setIsHover] = useState(false);
  const {
    isOpen: openSelection,
    toggle: toggleSelection,
    close,
  } = useModalHook();

  const { bank: bankName, icon } = bank || {};

  return (
    <div>
      <div
        className="borderE6"
        style={{
          position: "relative",
          padding: 12,
          cursor: "pointer",
          backgroundColor: isHover && "rgba(234, 234, 234, 0.4)",
          marginBottom: 32,
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={toggleSelection}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img src={icon} className="mr-2" />
            <TypeFezNeueMedium>{bankName}</TypeFezNeueMedium>
          </div>
          <JackIcons name="chevron-down" fill={jackColors.neutral900} />
        </div>
      </div>
      {openSelection && (
        <ModalBankSelection
          close={close}
          option={option}
          setActiveBank={setActiveBank}
        />
      )}
    </div>
  );
};

const ModalBankSelection = ({ close, option, setActiveBank }) => {
  const ref = useRef();
  useClickOutside({ clickOutside: close, ref });
  return (
    <div
      style={{
        position: "absolute",
        border: `1px solid ${colors.greye6}`,
        backgroundColor: "white",
        borderRadius: 8,
        boxShadow:
          "0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)",
        width: "92%",
        zIndex: 3,
        marginTop: -20,
      }}
      ref={ref}
    >
      {option?.map((item, index) => {
        const { bank } = item || {};
        return (
          <div
            key={index}
            className="hover-600"
            style={{
              padding: "12px 8px",
              cursor: "pointer",
            }}
            onClick={() => {
              setActiveBank(item);
              close();
            }}
          >
            <GothamRegular
              className="font12"
              style={{ color: jackColors.neutral800 }}
            >
              {bank}
            </GothamRegular>
          </div>
        );
      })}
    </div>
  );
};

const BankDetailsData = ({ bank, data }) => {
  const { t } = useTranslation("international/details");
  const { total_trx_amount } = data || {};
  const { name, number } = bank || {};

  const dataRendered = [
    {
      title: t("Account Name"),
      value: name,
      copyValue: name,
    },
    {
      title: t("Account Number"),
      value: number,
      copyValue: number,
    },
    {
      title: t("Total Payment"),
      value: `IDR ${formatCurrencyNoDecimal(total_trx_amount)}`,
      copyValue: total_trx_amount,
    },
  ];

  return (
    <div>
      <div className="borderE6" style={{ padding: 12 }}>
        {dataRendered?.map((item, index) => {
          const [tooltipOpen, setTooltipOpen] = useState(false);
          const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
          const { title, value, copyValue, icon } = item || {};
          const isLast = index === dataRendered.length - 1;
          return (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center"
              style={{ marginBottom: !isLast && 12 }}
            >
              <div>
                <div className="d-flex align-items-center">
                  <GothamRegular
                    className="font12 mb-1"
                    style={{ color: jackColors.neutral800 }}
                  >
                    {title}
                  </GothamRegular>
                  {icon && (
                    <div style={{ position: "relative", height: 14 }}>
                      <img
                        src={icon}
                        style={{ width: 10, marginLeft: 4, marginTop: -12 }}
                      />
                    </div>
                  )}
                </div>
                <GothamMedium>{value}</GothamMedium>
              </div>
              <div
                id={"Tooltip-" + (index + 1)}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(copyValue);
                }}
              >
                <JackIcons name="file-copy" fill={jackColors.neutral900} />
              </div>
              <Tooltip
                target={"Tooltip-" + (index + 1)}
                isOpen={tooltipOpen}
                toggle={toggleTooltip}
                style={{ padding: "0px 12px 2px 12px" }}
              >
                <span
                  style={{
                    fontSize: 10,
                    lineHeight: "12px",
                    fontFamily: "TypefezNeueRegular",
                  }}
                >
                  Copy
                </span>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};
