import React from "react";
import {
  useDeleteTeam,
  useMainModalContent,
  usePeopleData,
  useRoles,
  useSelectingMemberRole,
} from "./logics/context-hooks";
import { useSubscriptionUsage } from "../subscriptionsJackComponents/logics/general-hooks";

const teamDetailsSubTemplate = {
  name: "",
  color: "",
  members: [{}],
  manager: {},
};

const rolesTemplate = {
  roles: [{}],
  allRoles: [{}],
  rolesWoBusinessOwner: [{}],
  loadingRoles: false,
};

const deleteTeamTemplate = {
  loadingDeleteTeam: false,
  teamToDelete: {},
  deleteTeam: () => {},
  setTeamToDelete: () => {},
};

const peopleDataTemplate = {
  loadingUsers: false,
  loadingTeams: false,
  loadingGeneral: false,
  loadingRequestedUsers: false,
  isTeamDetailPage: false,
  users: [{}],
  teams: [{}],
  usersRaw: [{}],
  cleanUsers: [{}],
  teamsWithUsers: [{}],
  unassignedUsers: [{}],
  unformattedUsers: [{}],
  requestedUsers: [{}],
  teamDetail: {},
  refetchUsers: async () => {},
  refetchTeams: async () => {},
  refetchPeopleData: async () => {},
  refetchRequestedUsers: async () => {},
};

const mainModalContentTemplate = {
  dedicate: "",
  isInviteUser: false,
  hasNewAdmins: false,
  isDedicatedMode: false,
  isTeamDetailStep: false,
  isAddMembersStep: false,
  isAddManagerStep: false,
  isDetectedChanges: false,
  isConfirmationStep: false,
  isAgreedAdminPolicy: false,
  hasNewAdminTeamManager: false,
  isDetectedAddMembersChanges: false,
  isDetectedAddManagerChanges: false,
  isDetectedCreateTeamChanges: false,
  isDetectedInviteUserChanges: false,
  isDetectedTeamDetailsChanges: false,
  contentIndex: 0,
  createTeamStep: 1,
  selectedMembers: [{}],
  selectedManagers: [{}],
  selectedManager: {},
  teamDetails: teamDetailsSubTemplate,
  changesReference: teamDetailsSubTemplate,
  nextContent: () => {},
  prevContent: () => {},
  setDedicate: () => {},
  setTeamDetails: () => {},
  setContentIndex: () => {},
  setIsAgreedAdminPolicy: () => {},
};

const selectingMemberRoleTemplate = {
  showRoleDropdown: false,
  memberListScrollPosition: 0,
  selectedMemberRefs: [{}],
  selectingMemberRole: {},
  setSelectedMemberRefs: () => {},
  setSelectingMemberRole: () => {},
  resetSelectingMemberRole: () => {},
  setMemberListScrollPosition: () => {},
};

const subscriptionUsageTemplate = {
  isSuspended: false,
  isReachedMaxSeats: false,
  loadingSubscriptionUsage: false,
  max_users: -1,
  total_users: 0,
  refetchUsage: async () => {},
};

const contextTemplate = {
  closeModal: () => {},
  ...rolesTemplate,
  ...deleteTeamTemplate,
  ...peopleDataTemplate,
  ...mainModalContentTemplate,
  ...subscriptionUsageTemplate,
  ...selectingMemberRoleTemplate,
};

export const PeopleManagementContext = React.createContext(contextTemplate);

const PeopleManagementProvider = ({ children }) => {
  const subscriptionUsage = useSubscriptionUsage();

  const roles = useRoles();
  const deleteTeam = useDeleteTeam();
  const peopleData = usePeopleData();
  const mainModalContent = useMainModalContent();
  const selectingMemberRole = useSelectingMemberRole();

  const closeModal = () => {
    mainModalContent.delayedReset();
    mainModalContent.setTeamDetails({});
    selectingMemberRole.resetSelectingMemberRole();
  };

  const value = {
    ...roles,
    ...deleteTeam,
    ...peopleData,
    ...mainModalContent,
    ...subscriptionUsage,
    ...selectingMemberRole,
    closeModal,
    refetchUsage: subscriptionUsage.refetch,
  };

  return (
    <PeopleManagementContext.Provider value={value}>
      {children}
    </PeopleManagementContext.Provider>
  );
};

export default PeopleManagementProvider;
