import {
  GothamLight,
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "components/Text";
import styles from "./styles.module.css";
import BlockingBlurModal from "modals/BlockingBlurModal";
import NextImage from "components/NextImage";
import { jackColors } from "assets/colors";
import { useEffect, useState } from "react";
import { JackIcons } from "assets/jackIcons/parent";
import { useModalHook } from "components/Modals";
import LoadingSpinner from "components/Loading";
import { useMutation } from "tools/api";
import { useGetAuth } from "contexts/AuthContext";

export const TEN_MINUTES_DURATION = 10 * 60 * 1000;
export const ONE_WEEK_DURATION = 10 * 60 * 1000 * 6 * 24 * 7;

export const usePendingDanamon = ({
  type = "request",
  duration = ONE_WEEK_DURATION,
  recheckParameters = [],
}) => {
  const { user } = useGetAuth();

  const storageName = (() => {
    switch (type) {
      case "request":
        return `${user?.id ?? ""}_pending_open_checking_account_request_time`;
      case "release":
        return `${user?.id ?? ""}_pending_release_ads_time`;
      default:
        return `${user?.id ?? ""}_pending_open_checking_account_request_time`;
    }
  })();

  const [isPendingDanamon, setIsPendingDanamon] = useState(false);

  const storeTimer = () => {
    localStorage.setItem(storageName, new Date().getTime());
    setIsPendingDanamon(true);
  };

  useEffect(() => {
    const storedValue = localStorage.getItem(storageName);
    if (!storedValue) return;

    const currentTime = new Date().getTime();

    if (currentTime - Number(storedValue) > duration) {
      setIsPendingDanamon(false);
      localStorage.removeItem(storageName);
      return;
    }

    storeTimer();
  }, recheckParameters);

  return {
    isPendingDanamon,
    storeTimer,
  };
};

const OrangeLight = () => {
  return (
    <svg
      width="354"
      height="363"
      viewBox="0 0 354 363"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "absolute", bottom: "0px", right: "0px", zIndex: -1 }}
    >
      <g filter="url(#filter0_f_5563_48944)">
        <rect
          x="300"
          y="300"
          width="137"
          height="137"
          rx="68.5"
          fill="url(#paint0_linear_5563_48944)"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_5563_48944"
          x="0"
          y="0"
          width="737"
          height="737"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="150"
            result="effect1_foregroundBlur_5563_48944"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5563_48944"
          x1="368.5"
          y1="300"
          x2="368.5"
          y2="437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8B00" />
          <stop offset="1" stopColor="#FEBA00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SparkleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6236_2577)">
        <path
          d="M15.8335 0.833374L14.7835 3.12504L12.5002 4.16671L14.7835 5.21671L15.8335 7.50004L16.8752 5.21671L19.1668 4.16671L16.8752 3.12504M7.50016 3.33337L5.41683 7.91671L0.833496 10L5.41683 12.0834L7.50016 16.6667L9.5835 12.0834L14.1668 10L9.5835 7.91671M15.8335 12.5L14.7835 14.7834L12.5002 15.8334L14.7835 16.875L15.8335 19.1667L16.8752 16.875L19.1668 15.8334L16.8752 14.7834"
          fill="#B9FC00"
        />
      </g>
      <defs>
        <clipPath id="clip0_6236_2577">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MouseIcon = () => {
  return (
    <svg
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9C6 7.4087 6.63214 5.88258 7.75736 4.75736C8.88258 3.63214 10.4087 3 12 3C13.5913 3 15.1174 3.63214 16.2426 4.75736C17.3679 5.88258 18 7.4087 18 9V15C18 16.5913 17.3679 18.1174 16.2426 19.2426C15.1174 20.3679 13.5913 21 12 21C10.4087 21 8.88258 20.3679 7.75736 19.2426C6.63214 18.1174 6 16.5913 6 15V9Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M12 7V11"
        stroke="#B9FC00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const slidingCardsAnimationCNDecider = ({ index, selectionDirection }) => {
  switch (selectionDirection) {
    case "one-two":
      switch (index) {
        case 0:
          return styles.slideFromOneToTwoImageOne;
        case 1:
          return styles.slideFromOneToTwoImageTwo;
        case 2:
          return styles.slideFromOneToTwoImageThree;
        default:
          return "";
      }
    case "one-three":
      switch (index) {
        case 0:
          return styles.slideFromOneToThreeImageOne;
        case 1:
          return styles.slideFromOneToThreeImageTwo;
        case 2:
          return styles.slideFromOneToThreeImageThree;
        default:
          return "";
      }
    case "two-one":
      switch (index) {
        case 0:
          return styles.slideFromTwoToOneImageOne;
        case 1:
          return styles.slideFromTwoToOneImageTwo;
        case 2:
          return styles.slideFromTwoToOneImageThree;
        default:
          return "";
      }
    case "two-three":
      switch (index) {
        case 0:
          return styles.slideFromTwoToThreeImageOne;
        case 1:
          return styles.slideFromTwoToThreeImageTwo;
        case 2:
          return styles.slideFromTwoToThreeImageThree;
        default:
          return "";
      }
    case "three-one":
      switch (index) {
        case 0:
          return styles.slideFromThreeToOneImageOne;
        case 1:
          return styles.slideFromThreeToOneImageTwo;
        case 2:
          return styles.slideFromThreeToOneImageThree;
        default:
          return "";
      }
    case "three-two":
      switch (index) {
        case 0:
          return styles.slideFromThreeToTwoImageOne;
        case 1:
          return styles.slideFromThreeToTwoImageTwo;
        case 2:
          return styles.slideFromThreeToTwoImageThree;
        default:
          return "";
      }
    default:
      return "";
  }
};

const badgeOrderAnimationCNDecider = ({ index, scrollDirection }) => {
  switch (index) {
    case 0:
      switch (scrollDirection) {
        case "down":
          return styles.badgeOneVanish;
        case "up":
          return styles.badgeOneReveal;
        default:
          return styles.badgeOneReveal;
      }
    case 1:
      switch (scrollDirection) {
        case "down":
          return styles.badgeTwoVanish;
        case "up":
          return styles.badgeTwoReveal;
        default:
          return styles.badgeTwoReveal;
      }
    case 2:
      switch (scrollDirection) {
        case "down":
          return styles.badgeThreeVanish;
        case "up":
          return styles.badgeThreeReveal;
        default:
          return styles.badgeThreeReveal;
      }
    case 3:
      switch (scrollDirection) {
        case "down":
          return styles.badgeFourVanish;
        case "up":
          return styles.badgeFourReveal;
        default:
          return styles.badgeFourReveal;
      }
    default:
      return "";
  }
};

const imagesPositioningAnimationCNDecider = ({ index, scrollDirection }) => {
  switch (index) {
    case 0:
      switch (scrollDirection) {
        case "down":
          return styles.imageOneReveal;
        case "up":
          return styles.imageOneVanish;
        default:
          return "";
      }
    case 1:
      switch (scrollDirection) {
        case "down":
          return styles.imageTwoReveal;
        case "up":
          return styles.imageTwoVanish;
        default:
          return "";
      }
    case 2:
      switch (scrollDirection) {
        case "down":
          return styles.imageThreeReveal;
        case "up":
          return styles.imageThreeVanish;
        default:
          return "";
      }

    default:
      return "";
  }
};

const OPTIONS = [
  {
    type: 1,
    order: 1,
    title: "Zero Transaction Fees",
    description:
      "Enjoy unlimited free transactions and save up to IDR 2,500,000 or more each month!",
  },
  {
    type: 2,
    order: 2,
    title: "All-in-One Checking Account",
    description:
      "Consolidate your funds into a single account & do all your transactions hassle-free, with no need for any top-ups.",
  },
  {
    type: 3,
    order: 3,
    title: "Multiple Security Protection",
    description: (
      <>
        Your funds are secured in a checking account issued by{" "}
        <TextInlineMedium>Bank Danamon Indonesia</TextInlineMedium>, a leading
        bank licensed by OJK, Bank Indonesia, and guaranteed by LPS.
      </>
    ),
  },
];

const DanamonTeaserModal = ({ isOpen = false, toggle = () => {} }) => {
  const [selectedOption, setSelectionOption] = useState(OPTIONS[0]);
  const [selectionDirection, setSelectionDirection] = useState("");
  const [scrollDirection, setScrollDirection] = useState("");
  const [isShowSuccessRequestSection, setIsShowSuccessRequestSection] =
    useState(false);
  const [isEnableConfirmationModal, setIsEnabledConfirmationModal] =
    useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const { isPendingDanamon, storeTimer } = usePendingDanamon({
    type: "request",
    recheckParameters: [isOpen],
    duration: TEN_MINUTES_DURATION,
  });

  const {
    isOpen: isOpenOpenCheckingAccountConfirmationModal,
    open: openOpenCheckingAccountConfirmationModal,
    close: closeOpenCheckingAccoutConfirmationModal,
  } = useModalHook();

  const {
    mutation: requestOpenCheckingAccount,
    loading: isLoadingRequestOpenCheckingAccount,
  } = useMutation({
    method: "post",
    url: "/business_users/banking_notif",
    afterSuccess: () => {
      setIsShowSuccessRequestSection(true);
      closeOpenCheckingAccoutConfirmationModal();
      storeTimer();
      return;
    },
  });

  const isDisabledOptionSelection = isOpenOpenCheckingAccountConfirmationModal;
  const isDisabledScroll = isOpenOpenCheckingAccountConfirmationModal;
  const isDisabledBackToTop =
    isDisabledScroll ||
    scrollDirection === "up" ||
    scrollDirection === "" ||
    isScrolling;

  const resetStates = () => {
    setSelectionOption(OPTIONS[0]);
    setSelectionDirection("");
    setScrollDirection("");
    setIsShowSuccessRequestSection(false);
    setIsScrolling(false);
    setIsEnabledConfirmationModal(false);
    closeOpenCheckingAccoutConfirmationModal();
  };

  const handleClose = () => {
    toggle();
    setTimeout(() => {
      resetStates();
    }, 500);
  };

  const handleBackToTop = () => {
    if (isDisabledBackToTop) return;
    const isSelectFirstOption = selectedOption?.order === 1;
    if (isSelectFirstOption) {
      setSelectionDirection("");
      setScrollDirection("up");
      return;
    }
    handleSelect(OPTIONS[0]);
    setTimeout(() => {
      setSelectionDirection("");
      setScrollDirection("up");
    }, 400);
  };

  const handleDisableScroll = () => {
    setIsScrolling(true);
    setTimeout(() => {
      setIsScrolling(false);
    }, 2500);
  };

  const handleScroll = (event) => {
    if (isScrolling) return;
    if (isShowSuccessRequestSection) return;
    const deltaY = event.deltaY;
    const isValidScroll = Math.abs(deltaY) > 20;
    if (isValidScroll) {
      const isScrollUp = deltaY < 0;
      const isScrollDown = deltaY > 0;
      if (isScrollUp) {
        handleBackToTop();
        handleDisableScroll();
        return;
      }
      if (isScrollDown) {
        setScrollDirection("down");
        handleDisableScroll();
        return;
      }
    }
  };

  const handleConfirmRequest = () => {
    if (isLoadingRequestOpenCheckingAccount) return;
    requestOpenCheckingAccount();
  };

  const handleCloseSuccessSection = () => {
    setIsShowSuccessRequestSection(false);
  };

  const handleSelect = (option) => {
    if (isDisabledOptionSelection) return;
    const isSelected = option?.title === selectedOption?.title;
    if (isSelected) return;
    const isOneToTwo = selectedOption?.order === 1 && option?.order === 2;
    const isOneToThree = selectedOption?.order === 1 && option?.order === 3;
    const isTwoToOne = selectedOption?.order === 2 && option?.order === 1;
    const isTwoToThree = selectedOption?.order === 2 && option?.order === 3;
    const isThreeToOne = selectedOption?.order === 3 && option?.order === 1;
    const isThreeToTwo = selectedOption?.order === 3 && option?.order === 2;
    if (isOneToTwo) setSelectionDirection("one-two");
    if (isOneToThree) setSelectionDirection("one-three");
    if (isTwoToOne) setSelectionDirection("two-one");
    if (isTwoToThree) setSelectionDirection("two-three");
    if (isThreeToOne) setSelectionDirection("three-one");
    if (isThreeToTwo) setSelectionDirection("three-two");
    setSelectionOption(option);
  };

  const mainContentContainerAnimationCN = (() => {
    if (
      isOpenOpenCheckingAccountConfirmationModal ||
      isShowSuccessRequestSection
    ) {
      return styles.mainContentContainerFadeOut;
    }

    return styles.mainContentContainerFadeIn;
  })();

  const firstSectionAnimationCN = (() => {
    switch (scrollDirection) {
      case "down":
        return styles.firstSectionVanish;
      case "up":
        return styles.firstSectionReveal;
      default:
        return "";
    }
  })();

  const optionsContainerAnimationCN = (() => {
    switch (scrollDirection) {
      case "down":
        return styles.optionsReveal;
      case "up":
        return styles.optionsVanish;
      default:
        return "";
    }
  })();

  const slidesContainerAnimationCN = (() => {
    switch (scrollDirection) {
      case "down":
        return styles.slidesContainerReveal;
      case "up":
        return styles.slidesContainerVanish;
      default:
        return "";
    }
  })();

  return (
    <BlockingBlurModal isOpen={isOpen}>
      <div onWheel={handleScroll} className={styles.mainContainer}>
        {/* MOVING BACKGROUND */}
        <div className={styles.backgroundContainer}>
          <div className={styles.movingLayer}></div>
        </div>

        {/* CLOSE BUTTON */}
        <div className={`${styles.closeButtonContainer}`}>
          <GothamMedium style={{ color: "white" }}>Close</GothamMedium>
          <div className={styles.closeButton} onClick={handleClose}>
            <JackIcons name="close" fill="white" />
          </div>
        </div>

        <div
          className={`${styles.mainContentContainer} ${mainContentContainerAnimationCN}`}
        >
          <div className={`${styles.firstSection} ${firstSectionAnimationCN}`}>
            {/* TITLE */}
            <div className={styles.titleContainer}>
              <GothamLight
                woFontColor
                style={{
                  color: "white",
                  fontSize: "56px",
                  lineHeight: "72px",
                  width: "834px",
                }}
              >
                Elevate Your Finance <br />
                Get Next Level Business Banking
              </GothamLight>
              <GothamLight
                woFontColor
                style={{ color: "white", fontSize: "20px", lineHeight: "32px" }}
              >
                Open a <TextInlineMedium>Checking Account</TextInlineMedium> to
                enjoy free transactions in Jack! It's a powerful account for all
                your <br />
                business needs with exclusive perks and security by Bank Danamon
                Indonesia. Switch it up today!
              </GothamLight>
            </div>

            {/* SCROLLER */}
            <div className={styles.scrollerContainer}>
              <div className={styles.scroller}>
                <MouseIcon />
              </div>
              <GothamMedium
                style={{
                  color: "white",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (isDisabledScroll) return;
                  setScrollDirection("down");
                }}
              >
                Scroll to discover more
              </GothamMedium>
            </div>

            {/* BADGES */}
            {[
              "/images/danamon-teaser-badge-1.png",
              "/images/danamon-teaser-badge-2.png",
              "/images/danamon-teaser-badge-3.png",
              "/images/danamon-teaser-badge-4.png",
            ].map((img, index) => {
              const badgeOrderAnimationCN = badgeOrderAnimationCNDecider({
                index,
                scrollDirection,
              });

              return (
                <div
                  key={img}
                  className={`${styles.badgeContainer} ${badgeOrderAnimationCN}`}
                >
                  <NextImage width={160} height={160} src={img} />
                </div>
              );
            })}
          </div>

          <div className={styles.secondSection}>
            <div className={styles.slideContentContainer}>
              {/* OPTIONS */}
              <div
                className={`${styles.optionsContainer} ${optionsContainerAnimationCN}`}
              >
                <GothamMedium className="font20" style={{ color: "white" }}>
                  Elevate Your Business with These Benefits:
                </GothamMedium>
                {OPTIONS.map((option, index) => {
                  const { type, title, description } = option;

                  const isSelected = title === selectedOption?.title;

                  const animationCN = (() => {
                    switch (type) {
                      case 1:
                        if (isSelected) return styles.openOptionTypeOne;
                        return styles.closeOptionTypeOne;
                      case 2:
                        if (isSelected) return styles.openOptionTypeTwo;
                        return styles.closeOptionTypeTwo;
                      case 3:
                        if (isSelected) return styles.openOptionTypeThree;
                        return styles.closeOptionTypeThree;
                      default:
                        return "";
                    }
                  })();

                  const isDisabled = isDisabledOptionSelection || isSelected;

                  return (
                    <div
                      key={title}
                      className={`${styles.optionContainer} ${animationCN}`}
                      onClick={() => handleSelect(option)}
                      style={{
                        height: isSelected ? "fit-content" : "88px",
                      }}
                    >
                      <div
                        key={title}
                        className={`${styles.option} ${
                          isSelected ? styles.optionSelected : ""
                        }`}
                        style={{
                          cursor: isDisabled ? "default" : "pointer",
                        }}
                      >
                        <div
                          className={styles.optionGreenSign}
                          style={{ bottom: index === 0 ? "2px" : "6px" }}
                        />
                        <GothamMedium
                          className="font20"
                          style={{ color: jackColors.neutral700 }}
                        >
                          {index + 1}
                        </GothamMedium>
                        <div>
                          <GothamMedium
                            className="font20"
                            style={{ color: "white" }}
                          >
                            {title}
                          </GothamMedium>
                          <GothamRegular
                            woFontColor
                            className={`font20`}
                            style={{
                              color: "white",
                              marginTop: "16px",
                              opacity: isSelected ? 1 : 0,
                            }}
                          >
                            {description}
                          </GothamRegular>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* SLIDING CARDS */}
              <div
                className={`${styles.slidesContainer} ${slidesContainerAnimationCN}`}
              >
                {[
                  "/images/danamon-teaser-slide-1.png",
                  "/images/danamon-teaser-slide-2.png",
                  "/images/danamon-teaser-slide-3.png",
                ].map((image, index) => {
                  const animationCN = slidingCardsAnimationCNDecider({
                    index,
                    selectionDirection,
                  });

                  const positioningAnimationCN =
                    imagesPositioningAnimationCNDecider({
                      index,
                      scrollDirection,
                    });

                  const imageStyle = (() => {
                    switch (index) {
                      case 0:
                        return styles.imageOne;
                      case 1:
                        return styles.imageTwo;
                      case 2:
                        return styles.imageThree;
                      default:
                        return "";
                    }
                  })();

                  return (
                    <div
                      key={index}
                      className={`${styles.imageContainer} ${imageStyle} ${positioningAnimationCN} ${animationCN}`}
                    >
                      <NextImage src={image} width={767} height={480} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* CTA BUTTON */}
          <button
            type="button"
            className={`${styles.submitButton} ${(() => {
              switch (scrollDirection) {
                case "down":
                  return styles.submitButtonEnlarge;
                case "up":
                  return styles.submitButtonReduce;
                default:
                  return "";
              }
            })()}`}
            style={{ cursor: isPendingDanamon ? "default" : "pointer" }}
            onClick={() => {
              if (isPendingDanamon) return;
              setIsEnabledConfirmationModal(true);
              openOpenCheckingAccountConfirmationModal();
            }}
          >
            {scrollDirection === "down" ? (
              <>
                <SparkleIcon />
                <GothamMedium style={{ color: "white", textWrap: "nowrap" }}>
                  Get your checking account now and experience advanced business
                  banking
                </GothamMedium>
                <div
                  style={{ borderLeft: "2px solid #FFFFFF33", height: "60%" }}
                />
                {isPendingDanamon ? (
                  <>
                    <JackIcons
                      name="checkmark-circle-2"
                      fill={jackColors.greenB9}
                    />
                    <GothamMedium style={{ color: jackColors.greenB9 }}>
                      Requested
                    </GothamMedium>
                  </>
                ) : (
                  <>
                    <GothamMedium style={{ color: jackColors.greenB9 }}>
                      Open Account
                    </GothamMedium>
                    <JackIcons name="arrow-forward" fill={jackColors.greenB9} />
                  </>
                )}
              </>
            ) : (
              <>
                {isPendingDanamon ? (
                  <>
                    <JackIcons
                      name="checkmark-circle-2"
                      fill={jackColors.greenB9}
                    />
                    <GothamMedium style={{ color: "white" }}>
                      Request Submitted
                    </GothamMedium>
                  </>
                ) : (
                  <>
                    <GothamMedium style={{ color: "white" }}>
                      Open Checking Account
                    </GothamMedium>
                    <JackIcons
                      name="arrow-forward"
                      fill={jackColors.neutral100}
                    />
                  </>
                )}
              </>
            )}
          </button>
        </div>

        {/* SUCCESS REQUEST SCREEN */}
        <div
          className={`${styles.successRequestContainer} ${
            isShowSuccessRequestSection
              ? styles.successRequestContainerReveal
              : styles.successRequestContainerVanish
          }`}
        >
          <div
            className={`${styles.successRequestImageContainer} ${
              isShowSuccessRequestSection
                ? styles.successRequestImageContainerReveal
                : styles.successRequestImageContainerVanish
            }`}
          >
            <NextImage
              src="/images/open-checking-account-confirmation-image-2.png"
              width={300}
              height={300}
            />
          </div>
          <div
            className={`${styles.successRequestTextContainer} ${
              isShowSuccessRequestSection
                ? styles.successRequestTextContainerReveal
                : styles.successRequestTextContainerVanish
            }`}
          >
            <GothamMedium
              className="font28"
              style={{ color: "white", textAlign: "center" }}
            >
              We've got your request!
            </GothamMedium>
            <GothamRegular
              woFontColor
              className="font20"
              style={{
                color: jackColors.neutral600,
                textAlign: "center",
                marginTop: "8px",
                marginBottom: "16px",
              }}
            >
              Thank you for your interest in our checking account. Our team will
              contact you shortly to complete the process.
            </GothamRegular>
            <button
              type="button"
              className={styles.successRequestCloseButton}
              onClick={handleCloseSuccessSection}
            >
              <GothamMedium style={{ color: "white" }}>Close</GothamMedium>
            </button>
          </div>
        </div>

        {/* BACK TO TOP BUTTON */}
        <button
          type="button"
          className={`${styles.backToTopButton} ${(() => {
            if (
              isShowSuccessRequestSection ||
              isOpenOpenCheckingAccountConfirmationModal
            ) {
              return styles.backToTopButtonVanish;
            }

            switch (scrollDirection) {
              case "down":
                return styles.backToTopButtonReveal;
              case "up":
                return styles.backToTopButtonVanish;
              default:
                return "";
            }
          })()}`}
          style={{
            cursor: isDisabledBackToTop ? "default" : "pointer",
          }}
          onClick={handleBackToTop}
        >
          <GothamMedium>Back to top</GothamMedium>
          <JackIcons name="arrow-up" fill={jackColors.neutral900} />
        </button>

        {/* CONFIRMATION MODAL */}
        {isEnableConfirmationModal && (
          <div
            className={`${styles.openCheckingAccountConfirmationModal} ${
              isOpenOpenCheckingAccountConfirmationModal
                ? styles.openCheckingAccountConfirmationModalOpen
                : styles.openCheckingAccountConfirmationModalClose
            }`}
          >
            <JackIcons
              name="close"
              fill="white"
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                scale: "1.2",
                cursor: "pointer",
              }}
              onClick={() => {
                closeOpenCheckingAccoutConfirmationModal();
              }}
            />
            <div className={styles.confirmationImageOneContainer}>
              <NextImage
                src="/images/open-checking-account-confirmation-image-1.png"
                width={200}
                height={200}
              />
            </div>
            <GothamMedium
              className="font20"
              style={{ color: "white", textAlign: "center" }}
            >
              Ready to open your checking account?
            </GothamMedium>
            <GothamRegular
              woFontColor
              style={{ color: "white", textAlign: "center" }}
            >
              Click the button below, and our team will contact you shortly to
              finalize your setup.
            </GothamRegular>
            <button
              type="button"
              className={styles.confirmOpenAccountButton}
              onClick={handleConfirmRequest}
            >
              <GothamMedium style={{ color: "white" }}>
                Open My Account
              </GothamMedium>

              {isLoadingRequestOpenCheckingAccount && (
                <LoadingSpinner style={{ scale: "0.6", color: "white" }} />
              )}
            </button>
            <OrangeLight />
          </div>
        )}
      </div>
    </BlockingBlurModal>
  );
};

export default DanamonTeaserModal;
