import { booleanState } from "../../../../components/Status";
import { VALID_ERROR_CODES } from "../constants";
import ancestoryLinesFormatter from "./ancestoryLinesFormatter";
import longestAncestoryLineDecider from "./longestAncestoryLineDecider";

const retriableOrResubmittableTrxsDecider = (transactions) => {
  const ancestoryLines = ancestoryLinesFormatter(transactions);
  const longestAncestoryLine = longestAncestoryLineDecider(ancestoryLines);
  const longestAncestoryLineLength = longestAncestoryLine.length;
  const retriableOrResubmittableTrxs = Object.values(ancestoryLines)
    ?.filter((ancestoryLine) => {
      const trx = ancestoryLine[ancestoryLine.length - 1];
      const { isFailed, isDeclined } = booleanState(trx?.state);
      const isValidErrorCode = VALID_ERROR_CODES.includes(trx?.error_code);
      const isFailedLastLine = isFailed || isDeclined;
      const isLongestAncestoryLine =
        ancestoryLine.length === longestAncestoryLineLength;
      return isLongestAncestoryLine && isFailedLastLine && isValidErrorCode;
    })
    .map((ancestoryLine) => {
      return ancestoryLine[ancestoryLine.length - 1];
    });
  return retriableOrResubmittableTrxs;
};

export default retriableOrResubmittableTrxsDecider;
