import { pickBy } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { GothamMedium } from "../../../../../components/Text";
import { arraySplitter, customSum } from "../../../../../components/tools";
import dynamic from "next/dynamic";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { TransactionFilterDateCustom } from "./dropdowns/date/parent";
import { useTranslation } from "react-i18next";

const TransactionFilterAdd = dynamic(() => import("./dropdowns/add"), {
  ssr: false,
});
const TransactionFilterAmount = dynamic(
  () => import("./dropdowns/amount/amount"),
  { ssr: false }
);
const TransactionFilterCards = dynamic(() => import("./dropdowns/card"), {
  ssr: false,
});
const TransactionFilterDate = dynamic(() => import("./dropdowns/date/parent"), {
  ssr: false,
});
const TransactionFilterFetch = dynamic(() => import("./dropdowns/fetch"), {
  ssr: false,
});
const TransactionFilterRadio = dynamic(() => import("./dropdowns/radio"), {
  ssr: false,
});
const TransactionFilterStatus = dynamic(
  () => import("./dropdowns/status/parent"),
  { ssr: false }
);
const TransactionFilterCountry = dynamic(
  () => import("./dropdowns/country/parent"),
  { ssr: false }
);

const Component = ({ children, listener = () => {} }) => {
  const [ref, setRef] = useState(null);
  useEffect(() => {
    listener(ref?.clientWidth || 0);
  }, [ref?.clientWidth]);

  return <div ref={(e) => setRef(e)}>{children}</div>;
};

export const TransactionFilterButtons = ({
  activeFilters,
  filters,
  onClick,
  className,
  isModal,
  woResetFilter,
  woAdd,
  loading,
  runQueryName = "runQuery",
}) => {
  const { t } = useTranslation("api-history/api-history");
  const queries = filters
    .map((item) => {
      const { name, queryNames = [] } = item;
      return [...queryNames, name];
    })
    .flat(1);

  const { query, push } = useRouter();

  const activeQueryKeys = queries.filter((key) => !!query[key]);

  const showReset = activeQueryKeys.length;

  // divider props
  const [ref, setRef] = useState(null);
  const [buttonWidths, setButtonWidths] = useState({});

  const cleanButtonWidths = pickBy(buttonWidths, (value) => value);

  const widthValues = Object.values(cleanButtonWidths);
  const safeWidthValues = widthValues.length ? widthValues : [0];

  const widthTotal = customSum(safeWidthValues);

  const getDivider = Math.floor(
    (ref?.clientWidth || 0) / (widthTotal / safeWidthValues.length)
  );
  // divider props

  const [globalResetTriggered, setGlobalResetTriggered] = useState(false);

  const onReset = () => {
    let obj = query;
    activeQueryKeys.forEach((key) => delete obj[key]);
    push({ query: { ...obj, [runQueryName]: false } });
    push({ query: { ...obj, [runQueryName]: true } });
    eventsTracker("reset_filter_global");
    setButtonWidths({});
    setGlobalResetTriggered(true);
    onClick();
  };

  const inactiveFilters = filters
    .filter((item) => {
      const { name } = item;
      return !activeFilters.includes(name);
    })
    .filter((item) => item);

  let activeFiltersArray = [
    ...filters.filter(({ name }) => activeFilters.includes(name)),
    ...[{ add: true }, { reset: showReset }],
  ];

  if (woAdd) activeFiltersArray = activeFiltersArray.filter(({ add }) => !add);

  const dividedArr = arraySplitter(activeFiltersArray, getDivider);

  return (
    <div className="w-100" ref={(e) => setRef(e)}>
      {dividedArr.map((activeFiltersArray, index) => (
        <div
          key={index}
          className={`d-flex align-items-center ${className}`}
          style={{
            width: "100%",
            height: 52,
          }}
        >
          <div className="d-flex align-items-center" style={{ gap: 12 }}>
            {activeFiltersArray.map((item, index) => {
              const component = () => {
                if (!item) return null;
                const { type, add, reset } = item;

                const props = {
                  isModal,
                  fetchLoading: loading,
                  runQueryName,
                  globalReset: globalResetTriggered,
                  globalResetTrigger: setGlobalResetTriggered,
                  ...item,
                };

                const isCard = type == "card";
                const isStatus = type == "status";
                const isFetch = type == "fetch";
                const isAmount = type == "amount";
                const isRadio = type == "radio";
                const isDate = type == "date";
                const isDateCustom = type == "dateCustom";
                const isCurrency = type == "currencies";

                if (isStatus) return <TransactionFilterStatus {...props} />;
                if (isFetch) return <TransactionFilterFetch {...props} />;
                if (isAmount) return <TransactionFilterAmount {...props} />;
                if (isRadio) return <TransactionFilterRadio {...props} />;
                if (isCard) return <TransactionFilterCards {...props} />;
                if (isDate) return <TransactionFilterDate {...props} />;
                if (isDateCustom)
                  return <TransactionFilterDateCustom {...props} />;
                if (isCurrency) return <TransactionFilterCountry {...props} />;

                if (add) {
                  return (
                    <TransactionFilterAdd
                      isModal={isModal}
                      array={inactiveFilters}
                      onClick={onClick}
                    />
                  );
                }

                if (reset && !woResetFilter)
                  return (
                    <GothamMedium
                      id="reset-filter-button"
                      className="font12 hover"
                      style={{
                        textDecoration: "underline",
                        whiteSpace: "nowrap",
                      }}
                      onClick={onReset}
                    >
                      {t("Reset Filter")}
                    </GothamMedium>
                  );
                return null;
              };

              // added this to prevent stray empty divs -> weird whitespace because of flex gap
              if (!component()) return null;

              return (
                <Component
                  key={index}
                  listener={(width) => {
                    setButtonWidths((prev) => {
                      let obj = {};
                      obj[index] = width;
                      return { ...prev, ...obj };
                    });
                  }}
                >
                  {component()}
                </Component>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
