import { Modal } from "reactstrap";
import styles from "../../styles.module.css";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { pluralize } from "../../../invoiceComponents/data/formatter";
import { useTranslation } from "react-i18next";

const mainTextDecider = (type = "") => {
  switch (type) {
    case "invoice":
      return "You can’t submit any more invoice";

    case "reimbursement":
      return "You can’t submit any more receipt";

    case "cards":
      return "Cannot issue more cards";

    case "physical-cards":
      return "Cannot issue more physical cards";

    case "physical-cards-soft":
      return "Are you sure to continue?";

    case "virtual-cards":
      return "Cannot issue more virtual cards";

    case "user-seats":
      return "Cannot add more user";

    case "suspended":
      return "You can’t create any transactions";
    case "suspended-user-seats":
      return "You can’t invite anymore members";
    case "suspended-create-team":
      return "You can’t create any teams";
    case "suspended-edit-team":
      return "You can’t edit any teams";
    case "suspended-delete-team":
      return "You can’t delete any teams";
    case "suspended-edit-manager":
      return "You can’t edit any team managers";
    case "suspended-add-member":
      return "You can’t add anymore members";
    case "suspended-approve":
      return "You can’t approve or reject requests";
    case "suspended-invite":
      return "You can’t invite new user to Jack";
    case "suspended-retry-resubmit":
      return "You can’t retry or resubmit transactions";

    default:
      return "You can’t submit any more invoice";
  }
};

const secondaryTextDecider = (type = "", number = 0, t = () => {}) => {
  switch (type) {
    case "invoice":
      return "Your company has reached its monthly limit for submitting bill payments. Upgrade your plan to enjoy unlimited invoice submission.";

    case "reimbursement":
      return "Your company has reached its monthly limit for reimbursing receipts. Upgrade your plan to enjoy unlimited reimbursement.";

    case "cards":
      return "You’ve issued a maximum number of cards. Please upgrade your plan to issue more cards.";

    case "physical-cards":
      return (
        <>
          You’ve issued a maximum of{" "}
          <span
            style={{ fontFamily: "GothamMedium", color: jackColors.neutral800 }}
          >
            {number} physical {pluralize(number, "card")}
          </span>
          . Please upgrade your plan to issue more cards.
        </>
      );

    case "physical-cards-soft":
      return (
        <>
          By issuing a new physical card,{" "}
          <span
            style={{ fontFamily: "GothamMedium", color: jackColors.neutral800 }}
          >
            IDR {formatCurrencyNoDecimal(number)}{" "}
          </span>
          will be added to this month's bill and carried forward to the upcoming
          bills.
        </>
      );

    case "virtual-cards":
      return (
        <>
          You’ve issued a maximum of{" "}
          <span
            style={{ fontFamily: "GothamMedium", color: jackColors.neutral800 }}
          >
            {number} virtual card(s)
          </span>
          . Please upgrade your plan to issue more cards.
        </>
      );

    case "user-seats":
      return (
        <>
          {t("You’ve reached the limit of")}{" "}
          <span
            style={{ fontFamily: "GothamMedium", color: jackColors.neutral800 }}
          >
            {number} {t("active user(s)")}
          </span>
          . {t("Please upgrade your plan to add more.")}
        </>
      );

    case "suspended":
    case "suspended-user-seats":
    case "suspended-create-team":
    case "suspended-edit-team":
    case "suspended-delete-team":
    case "suspended-edit-manager":
    case "suspended-add-member":
    case "suspended-approve":
    case "suspended-invite":
    case "suspended-retry-resubmit":
      return t("This happens because your account has been suspended. Please contact your Account Manager to reactivate or downgrade your account.");

    default:
      return t("Your company has reached its monthly limit for submitting bill payments. Upgrade your plan to enjoy unlimited invoice submission.");
  }
};

const imageDecider = (type = "") => {
  switch (type) {
    case "physical-cards":
    case "physical-cards-soft":
      return <img src="/images/physical-card-image.svg" />;
    case "virtual-cards":
      return <img src="/images/virtual-card-image.svg" />;
    case "cards":
      return <img src={"/images/card-image.svg"} />;

    case "user-seats":
      return <img src="/images/no-seat-image.svg" />;

    case "invoice":
    case "reimbursement":
      return <img src="/images/reached-limit-image.svg" />;

    case "suspended":
    case "suspended-user-seats":
    case "suspended-create-team":
    case "suspended-edit-team":
    case "suspended-delete-team":
    case "suspended-edit-manager":
    case "suspended-add-member":
    case "suspended-approve":
      return <img src="/images/suspended-image.svg" />;

    case "suspended-invite":
      return <JackIcons name="SuspendedInvite" />;

    default:
      return <img src="/images/waiting-person-image.svg" />;
  }
};

const buttonTextDecider = (type = "") => {
  switch (type) {
    case "physical-cards":
    case "virtual-cards":
    case "cards":
      return "Upgrade Plan";

    case "physical-cards-soft":
      return "Yes, continue";

    case "suspended":
    case "suspended-user-seats":
    case "suspended-create-team":
    case "suspended-edit-team":
    case "suspended-delete-team":
    case "suspended-edit-manager":
    case "suspended-add-member":
    case "suspended-approve":
    case "suspended-invite":
    case "suspended-retry-resubmit":
      return "I understand";

    default:
      return "Upgrade Plan";
  }
};

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <button className={styles["close-btn"]} onClick={onClick}>
      <JackIcons name="close-outline" fill={jackColors.neutral900} />
    </button>
  );
};

const PreventionModal = ({
  type = "",
  number = 0,
  isOpen = false,
  withCancel = true,
  toggle = () => {},
  onClick = () => {},
}) => {
  const { t } = useTranslation("common");
  const mainText = mainTextDecider(type);
  const secondaryText = secondaryTextDecider(type, number, t);
  const image = imageDecider(type);
  const buttonText = buttonTextDecider(type);

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} style={{ width: "480px" }}>
      <div className={styles["prevention-modal"]}>
        <CloseButton onClick={toggle} />
        {image}
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900, marginTop: "32px" }}
        >
          {t(mainText)}
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "16px" }}
        >
          {typeof secondaryText === "string" ? t(secondaryText) : secondaryText}
        </GothamRegular>
        <div style={{ display: "flex", width: "100%", gap: "16px" }}>
          {withCancel && (
            <ButtonJack
              type="outline"
              style={{ width: "100%", marginTop: "32px" }}
              onClick={toggle}
            >
              {t("Cancel")}
            </ButtonJack>
          )}
          <ButtonJack
            style={{ width: "100%", marginTop: "32px" }}
            onClick={onClick}
          >
            {t(buttonText)}
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};

export default PreventionModal;
