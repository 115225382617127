import { getUserRole } from "../../../contexts/AuthContext";

export const teamsWithUsersFormatter = ({
  users = [],
  usersRaw = [],
  teams: teamsRaw = [],
  query = {},
}) => {
  let teams = [...teamsRaw];
  let teamsWithUsers = [];

  const { isWeakManager, managedTeams } = getUserRole();

  // if (isWeakManager) {
  //   const managedTeamIds = managedTeams.map(({ id }) => id);

  //   teams = teamsRaw.filter(({ id }) => managedTeamIds.includes(id));
  // }

  teams.forEach((team) => {
    const teamJoinedWithUsers = { ...team };

    teamJoinedWithUsers.users = users.filter(
      ({ team_id }) => team_id === team.id
    );
    teamJoinedWithUsers.usersRaw = usersRaw.filter(
      ({ team_id }) => team_id === team.id
    );

    teamsWithUsers.push(teamJoinedWithUsers);
  });

  const searchFilter = query.searchbox_team;

  const isFilteredBySearch = !!searchFilter;

  if (isFilteredBySearch) {
    teamsWithUsers = teamsWithUsers.filter(({ name }) => {
      const loweredName = name.toLocaleLowerCase();
      const loweredSearch = searchFilter.toLocaleLowerCase();

      return loweredName.includes(loweredSearch);
    });
  }

  return teamsWithUsers ?? [];
};

export const filteredUserFormatter = ({
  users = [],
  teams = [],
  query = {},
}) => {
  let filteredUsers = [...users];

  // const { isWeakManager } = getUserRole();

  // if (isWeakManager) {
  //   const teamsWithUsers = teamsWithUsersFormatter({
  //     users: filteredUsers,
  //     teams,
  //     query,
  //   });

  //   const users = teamsWithUsers.map(({ users }) => users).flat();

  //   filteredUsers = users;
  // }

  const parseArray = (data) => {
    data = data ?? [];
    const isArray = Array.isArray(data);
    if (isArray) return data;
    return [data];
  };

  const teamFilters = parseArray(query.team);
  const roleFilters = parseArray(query.role);
  const statusFilters = parseArray(query.status);
  const searchFilter = query.searchbox_user;

  const isFilteredByTeam = teamFilters.length > 0;
  const isFilteredByRole = roleFilters.length > 0;
  const isFilteredByStatus = statusFilters.length > 0;
  const isFilteredBySearch = !!searchFilter;

  if (isFilteredByTeam) {
    filteredUsers = filteredUsers.filter(({ team_id }) => {
      return teamFilters.includes(String(team_id));
    });
  }

  if (isFilteredByRole) {
    filteredUsers = filteredUsers.filter(({ roles }) => {
      const mainRoleName =
        (roles ?? []).find(({ name }) => name.includes("partner"))?.name ?? "";
      return roleFilters.includes(mainRoleName);
    });
  }

  if (isFilteredByStatus) {
    filteredUsers = filteredUsers.filter(({ status }) => {
      return statusFilters.includes(status);
    });
  }

  if (isFilteredBySearch) {
    filteredUsers = filteredUsers.filter(({ name, email }) => {
      const loweredName = name.toLocaleLowerCase();
      const loweredEmail = email.toLocaleLowerCase();
      const loweredSearch = searchFilter.toLocaleLowerCase();

      return (
        loweredName.includes(loweredSearch) ||
        loweredEmail.includes(loweredSearch)
      );
    });
  }

  return filteredUsers;
};
