import React from "react";
import { jackColors } from "../../../assets/colors";
import { GothamLight, GothamMedium } from "../../../components/Text";
import { font14, font24 } from "../../virtualAccountComponents/common/utils";
import { MenuOptions } from "./menuOptions";
import { useTranslation } from "react-i18next";

export const MoveBalanceMainMenu = ({ handleClick }) => {
  const { t } = useTranslation("ledgers/ledgers");
  return (
    <div
      // page div
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div
        // middle container div
        style={{
          width: 438,
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <div
          // title div
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
        >
          <GothamMedium style={{ ...font24 }}>
            {t("Move to Primary Bank Account")}
          </GothamMedium>
          <GothamLight style={{ ...font14, color: jackColors.neutral800 }}>
            {t("Relocate all of your VA Pocket’s balance for extra security.")}
          </GothamLight>
        </div>
        <MenuOptions onClick={handleClick} />
      </div>
    </div>
  );
};
