import { jackColors } from "assets/colors";
import { JackIcons } from "assets/jackIcons/parent";
import { ButtonJack } from "components/ButtonsJack/parent";
import { useModalHook } from "components/Modals";
import { GothamMedium } from "components/Text";
import { useLayout } from "contexts/Layout/parent";
import Image from "next/image";
import { useRouter } from "next/router";
import { font12 } from "pageComponents/virtualAccountComponents/common/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { eventsTracker } from "universalFunctions/events";
import { useClickOutside } from "universalFunctions/useClickOutside";
import id from "../../assets/jackImages/circle-flag-id.svg";
import en from "../../assets/jackImages/circle-flag-us.svg";
import { useGetAuth } from "contexts/AuthContext";
import { useMutation } from "tools/api";
import EPIndicator from "components/EPIndicator";

const localesArray = [
  { label: "EN", value: "en", icon: en },
  { label: "ID", value: "id", icon: id },
];
const padding = 4;

export const SwitchLocaleButton = ({
  array = localesArray,
  noAuth = false,
}) => {
  const { i18n } = useTranslation();
  const { push, pathname, asPath, locale, query } = useRouter();
  const { banner = "" } = query ?? {};

  const { user, refetchUser } = useGetAuth();
  const userId = user?.id;
  const currentUserLanguage = user?.language;

  const currentLocale = localesArray.find(
    ({ value }) => value === (currentUserLanguage || locale)
  );
  const [activeLocale, setActiveLocale] = useState(currentLocale);
  const { label, icon } = activeLocale ?? {};

  const { scrollTop } = useLayout();
  const { isOpen, toggle, close } = useModalHook();
  useEffect(close, [scrollTop]);

  const ref = useRef();
  useClickOutside({ ref, clickOutside: close });

  const [refChild, setRefChild] = useState({});
  const componentHeight = (refChild?.clientHeight || 0) + padding * 2;

  const pageTitleDecider = () => {
    switch (pathname) {
      case "/login":
        return "login";
      case "/home":
      case "/dashboard":
        return "dashboard";
      case "/register":
        switch (banner) {
          case "general":
            return "registration_page_general_banner";
          case "transfez":
            return "registration_page_transfez_app_banner";
          default:
            return "registration_page_no_banner";
        }
      case "/kyb":
      case "/kyb/basis":
      case "/kyb-jack":
      case "/kyb-jack/onboard":
      case "/kyb-jack/details":
      case "/kyb-jack/bank":
      case "/kyb-jack/representative":
      case "/kyb-jack/structure":
      case "/kyb-jack/support":
      case "/kyb-jack/review":
      case "/kyb-jack/finish":
      case "/kyb-jack/on-review":
        return "kyb";
      default:
        return "";
    }
  };
  const page_title = pageTitleDecider();

  const { mutation: mutationChangeLanguage } = useMutation({
    url: "/business_users/change_language",
    type: "put",
    afterSuccess: () => {
      refetchUser();
    },
  });

  useEffect(() => {
    if (!locale) return;
    if (locale !== currentUserLanguage) {
      push({ pathname, query }, asPath, { locale: currentUserLanguage });
      i18n.changeLanguage(currentUserLanguage);
    }
  }, [locale, currentUserLanguage]);

  const button = (
    <div ref={ref}>
      <ButtonJack
        style={{
          width: "76px",
        }}
        type="outline"
        leftIcon={<Image src={icon} width={20} height={20} />}
        rightIcon={
          <JackIcons name="ChevronDown" style={{ height: 20 }} fill="black" />
        }
        onClick={toggle}
      >
        <GothamMedium>{label}</GothamMedium>
      </ButtonJack>
      <div
        style={{
          marginTop: 4,
          backgroundColor: "white",
          boxShadow: "0px 8px 16px -4px rgba(22, 34, 51, 0.08)",
          borderRadius: 8,
          position: "fixed",
          padding: 4,
          zIndex: 10,
          height: isOpen ? componentHeight : 0,
          overflow: "hidden",
          transition: "all 0.4s linear",
          visibility: isOpen ? "initial" : "hidden",
        }}
      >
        <div
          ref={(e) => setRefChild(e)}
          style={{
            display: "flex",
            gap: 4,
            flexDirection: "column",
          }}
        >
          {array.map((item, index) => {
            const { label, value, icon: currentIcon } = item;
            const isActive = value === activeLocale?.value;

            return (
              <div
                key={index}
                style={{
                  borderRadius: "4px",
                  display: "flex",
                  height: "40px",
                  alignItems: "center",
                  gap: "8px",
                  alignSelf: "stretch",
                  width: 92,
                  backgroundColor: isActive ? "#F1F1F1" : "white",
                  cursor: "pointer",
                  padding: 10,
                }}
                onClick={() => {
                  setActiveLocale(item);
                  i18n.changeLanguage(value);
                  if (!noAuth) {
                    mutationChangeLanguage({ id: userId, language: value });
                  }
                  localStorage.setItem("locale", value);
                  push({ pathname, query }, asPath, { locale: value });
                  const event_payload = {
                    language: value,
                    page_title,
                  };
                  eventsTracker("switch_language", event_payload);
                  if (process.env.NODE_ENV === "development") {
                    console.log("event_name: switch_language");
                    console.log("event_payload: ", event_payload);
                  }
                  close();
                }}
              >
                {/* <Flag iso={value} /> */}
                <Image src={currentIcon} width={20} height={20} />
                <GothamMedium style={{ ...font12, color: jackColors.black34 }}>
                  {label}
                </GothamMedium>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  if (noAuth)
    return (
      <div className="d-flex align-items-center" style={{ gap: 16 }}>
        <EPIndicator />
        {button}
      </div>
    );
  return button;
};

export default SwitchLocaleButton;
