import { head, isEmpty } from "lodash";
import { useRef } from "react";
import { colors, jackColors } from "../../assets/colors";
import { GothamRegular } from "../Text";
import { customSum, windowDimension } from "../tools";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export const tableAutoWidth = (headerTable) => {
  const filtered = headerTable.filter((item) => item);
  const totalTableWidth = customSum(filtered.map(({ width }) => width));

  const getTableWidth = (number) => `${(number / totalTableWidth) * 100}%`;

  const result = filtered.map((item) => {
    const { width: widthCurr } = item;
    const width = getTableWidth(widthCurr);
    return { ...item, width };
  });

  return result;
};

const Td = ({ children }) => {
  const isString = typeof children === "String";
  if (isString)
    return (
      <td>
        <GothamRegular
          style={{
            color: colors.neutral900,
          }}
        >
          {children}
        </GothamRegular>
      </td>
    );

  return <td>{children}</td>;
};

export const TableJack = ({
  headerTable,
  bodyTable,
  headerFloatTable,
  bodyFloatTable,
  rightOffset, //adjust the value if the position is not right
  style,
}) => {
  //Table component accept 5 props which are headerTable, bodyTable, headerFloatTable, bodyFloatTable, rightOffset
  //headerTable and bodyTable are required props
  //headerFloatTable, bodyFloatTable, rightOffset are optional props

  //headerTable are array of object which contain label, width, and textAlign (default value is left aligned)
  //bodyTable are array of array of component or string
  //if you input string, it will be wrapped with GothamRegular component
  //if you input component, it will be rendered as it is

  //headerFloatTable, bodyFloatTable, rightOffset are used to create float table
  //if you want to create float table, you need to input headerFloatTable and bodyFloatTable
  //rightOffset is used to adjust the position horizontally of the float table

  //notes:
  //some of basic component for table are available in components.js

  //example of headerTable and headerFloatTable
  // const headerTable = [
  //   {
  //     label: "Created At",
  //     width: 94,
  //   },
  //   {
  //     label: "Last Update",
  //     width: 100,
  //     textAlign: "right",
  //   },

  //example of bodyTable and bodyFloatTable
  // const bodyTable = [
  //   [
  //     "2021-01-01 00:00:00",
  //     <SubTextTableComponent text="06/10/23" subText="12:00" />,
  // ]

  const isUseFloatTable =
    !isEmpty(bodyFloatTable) && !isEmpty(headerFloatTable);

  const floatTableRef = useRef(null);
  const offsetWidth =
    isUseFloatTable && floatTableRef?.current?.clientWidth - 6;

  return (
    <div className={styles["table-container"]}>
      <table
        style={{
          width: "100%",
        }}
        className={styles["table-seamless"]}
      >
        <thead>
          <tr>
            {headerTable.map((headerItem, index) => (
              <th
                key={index}
                style={{
                  width: headerItem.width,
                  textAlign: headerItem.textAlign,
                }}
              >
                {headerItem.label}
              </th>
            ))}
            {
              //add empty th to adjust the offset
              offsetWidth && (
                <th
                  style={{
                    width: offsetWidth,
                  }}
                />
              )
            }
          </tr>
        </thead>
        <tbody>
          {bodyTable.map((bodyItem, index) => (
            <tr key={index}>
              {bodyItem.map((dataItem, index) => (
                <Td key={index}>{dataItem}</Td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isUseFloatTable && (
        <FloatTable
          headerFloatTable={headerFloatTable}
          bodyFloatTable={bodyFloatTable}
          floatTableRef={floatTableRef}
          rightOffset={rightOffset}
        />
      )}
    </div>
  );
};

export const TableJackSimple = ({
  headerTable = [],
  bodyComponent = null,
  tbodyRef,
  style = {},
  tableStyle = {},
  trStyle = {},
  thStyle = {},
  isDumb,
  isAllowOverflow,
  ...props
}) => {
  const filteredHeaders = headerTable.filter((item) => item);
  const { t } = useTranslation("reimbursement/reimbursement");
  return (
    <div
      className={styles[isDumb ? "table-container-dumb" : "table-container"]}
      style={{
        position: "relative",
        overflow: isAllowOverflow ? "visible" : "clip",
        width: "100%",
        ...style,
      }}
      {...props}
    >
      <table
        style={{
          width: "100%",
          tableLayout: "fixed",
          position: "relative",
          ...tableStyle,
        }}
        className={styles[isDumb ? "table-seamless-dumb" : "table-seamless"]}
      >
        <thead>
          <tr style={{ height: 40, maxHeight: 40, ...trStyle }}>
            {filteredHeaders.map((headerItem, index) => {
              if (!headerItem) return null;
              const maskingHeader = () => {
                if (headerItem?.label === "Bank") return "Bank Name";
                const isText = typeof headerItem?.label === "string";
                return isText ? t(headerItem?.label) : headerItem?.label;
              };
              return (
                <th
                  key={index}
                  style={{
                    width: headerItem.width,
                    textAlign: headerItem.textAlign,
                    backgroundColor: isDumb ? "transparent" : "white",
                    height: 40,
                    minHeight: 40,
                    ...thStyle,
                  }}
                >
                  <GothamRegular
                    style={{ color: jackColors.grey90 }}
                    className="font12"
                  >
                    {maskingHeader()}
                  </GothamRegular>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody ref={tbodyRef}>{bodyComponent}</tbody>
      </table>
    </div>
  );
};

export const FloatTable = ({
  headerFloatTable,
  bodyFloatTable,
  floatTableRef,
  rightOffset = "32px",
}) => {
  const { width } = windowDimension();
  const is1920 = width >= 1920;
  const margin = is1920 ? (width - 1920) / 2 : 0;

  const rightOffsetValue = is1920
    ? `calc(${rightOffset} + ${margin}px )`
    : rightOffset;

  return (
    <div className={styles["float-table-container"]}>
      <div
        className={styles["fixed-position"]}
        style={{
          //adjust the right offset by adding props if needed
          right: rightOffsetValue,
        }}
        ref={floatTableRef}
      >
        <table className={` ${styles["table-seamless"]}`}>
          <thead>
            <tr>
              {headerFloatTable.map((headerItem, index) => {
                return (
                  <th
                    key={index}
                    style={{
                      width: headerItem.width,
                      textAlign: headerItem.textAlign,
                    }}
                  >
                    {headerItem.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {bodyFloatTable.map((bodyItem, index) => {
              return (
                <tr key={index}>
                  {bodyItem.map((dataItem, index) => {
                    return <Td key={index}>{dataItem}</Td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
