import {
  ACTIVE,
  COMPLETED,
  FAILED,
  FOLDED,
  PARTIAL_FAILED,
  RETRY,
  SECONDARY,
} from "../constants";

const foldedUpdatesFormatter = (updates) => {
  const importantUpdates = [COMPLETED, FAILED, ACTIVE, PARTIAL_FAILED, RETRY];

  let latestImportantUpdateIndex = updates.length - 1;

  for (let i = 0; i < updates.length; i++) {
    const { status } = updates[i];
    const isImportantUpdate = importantUpdates.includes(status);
    if (isImportantUpdate) {
      latestImportantUpdateIndex = i;
    }
  }

  const upperFoldedUpdatesCount = latestImportantUpdateIndex - 1;
  const lowerFoldedUpdatesCount =
    updates.length - 1 - latestImportantUpdateIndex;

  const hasUpperFoldedUpdates = upperFoldedUpdatesCount > 0;
  const hasLowerFoldedUpdates = lowerFoldedUpdatesCount > 0;

  const foldedUpdates = (count) => ({
    status: FOLDED,
    contents: [
      {
        type: SECONDARY,
        content: `${count} more ${count > 1 ? "steps" : "step"}`,
      },
    ],
  });

  const upperFoldedUpdates =
    hasUpperFoldedUpdates && foldedUpdates(upperFoldedUpdatesCount);
  const lowerFoldedUpdates =
    hasLowerFoldedUpdates && foldedUpdates(lowerFoldedUpdatesCount);

  return [
    updates[0],
    upperFoldedUpdates,
    updates[latestImportantUpdateIndex],
    lowerFoldedUpdates,
  ].filter((value) => value);
};

export default foldedUpdatesFormatter;
