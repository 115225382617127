import { isEmpty } from "lodash";
import { jackColors } from "../../../../../../assets/colors";
import { GothamBold, GothamRegular } from "../../../../../../components/Text";
import CategoryIcon from "../../CategoryIcon";
import styles from "./Category.module.css";
import JackIcons from "assets/jackIcons/typescript/parent";
import { useTranslation } from "react-i18next";

const Category = ({
  isSimple = false,
  isSelected = false,
  category = {},
  onSelect = () => {},
  disableHoverEffect = false,
  isRequiredCategory = false,
  titleCategoryStyle = {},
  categoryIconStyle = {},
}) => {
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const titleCategory = () => {
    if (category.name) {
      return category.name;
    } else if (isRequiredCategory) {
      return tLocalTransfer(`Select category`);
    } else {
      return tLocalTransfer(`Select category (optional)`);
    }
  };

  return (
    <div
      className={` ${
        disableHoverEffect ? styles["category-no-hover"] : styles["category"]
      }`}
      style={{
        backgroundColor: isSelected ? jackColors.neutral500 : "",
      }}
      onClick={() => onSelect(category)}
    >
      <div>
        <CategoryIcon
          name={category.icon}
          fill={category.color}
          style={{ scale: "0.8", ...categoryIconStyle }}
        />
        <GothamRegular
          woFontColor
          style={{
            color: isEmpty(category)
              ? jackColors.greyBB
              : jackColors.neutral900,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "90%",
            ...titleCategoryStyle,
          }}
        >
          {titleCategory()}
        </GothamRegular>
      </div>
      {!isSimple && (
        <GothamRegular
          woFontColor
          className="font12"
          style={{
            color: jackColors.neutral700,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {category.description}
        </GothamRegular>
      )}
    </div>
  );
};

export default Category;

export const NewCategory = ({
  isSimple = false,
  isSelected = false,
  category = {},
  onSelect = () => {},
  disableHoverEffect = false,
  isRequiredCategory = false,
}) => {
  const titleCategory = () => {
    if (category.name) {
      return category.name;
    } else if (isRequiredCategory) {
      return `Select category`;
    } else {
      return `Select category (optional)`;
    }
  };

  return (
    <div
      className={` ${
        disableHoverEffect ? styles["category-no-hover"] : styles["category"]
      }`}
      style={{
        backgroundColor: isSelected ? jackColors.neutral500 : "",
      }}
      onClick={() => onSelect(category)}
    >
      <div>
        <div style={{ marginTop: -18 }}>
          <CategoryIcon
            name={category.icon}
            fill={category.color}
            style={{ scale: "0.8" }}
          />
        </div>
        <div
          className="d-flex flex-column"
          style={{ flexGrow: 1, minWidth: 0 }}
        >
          <GothamRegular
            woFontColor
            style={{
              color: isEmpty(category)
                ? jackColors.greyBB
                : jackColors.neutral900,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "90%",
            }}
          >
            {titleCategory()}
          </GothamRegular>
          {!isSimple && (
            <div>
              <GothamRegular
                woFontColor
                className="font12"
                numberOfLines={1}
                style={{
                  color: jackColors.neutral700,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {category.description}
              </GothamRegular>
            </div>
          )}
        </div>
        {Boolean(isSelected) && (
          <JackIcons
            name="CheckmarkCircle2"
            fill={jackColors.black34}
            style={{ width: 20, height: 20 }}
          />
        )}
      </div>
    </div>
  );
};
