import { format, parse } from "date-fns";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { useModalHook } from "../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { TransitionTr } from "../../../components/Transition";
import { confirmIcon } from "../../reimbursementComponents/data/constant";
import { pcDescription } from "../../scheduledPaymentComponents/formatter";
import { isID } from "@tools";
import { useTranslation } from "react-i18next";

export const extractTime = (dateString, woClock = false) => {
  if (!dateString) return "🕐  -- : --";
  const parts = dateString.split("T")[1].split("+")[0].split(".")[0].split(":");
  const time = `${parts[0]}:${parts[1]}`;
  if (woClock) return time;
  return `🕐  ${time}`;
};

const DefaultTag = () => (
  <div
    style={{
      width: 57,
      height: 20,
      padding: "2px 8px",
      borderRadius: 4,
      backgroundColor: "#B9FC00",
    }}
  >
    <GothamMedium className="font12">{isID ? "Utama" : "Default"}</GothamMedium>
  </div>
);

const PCTableRow = ({
  paymentCycle = {},
  index,
  onClickEdit,
  handleDelete,
}) => {
  const { isOpen, toggle } = useModalHook();
  const { id, user, date_and_time, recurrence, is_default } = paymentCycle;

  const description = pcDescription(paymentCycle);
  const { t } = useTranslation("schedule-payment/payment-cycle");
  return (
    <TransitionTr
      isQuick
      perPage={5}
      counter={index}
      style={{ cursor: "pointer" }}
    >
      <td>
        <GothamRegular style={{ color: jackColors.neutral900 }}>
          {user?.name}
        </GothamRegular>
      </td>

      <td valign="middle">
        <div style={{ display: "flex", gap: 16 }}>
          <GothamRegular style={{ color: jackColors.neutral900 }}>
            {description}
          </GothamRegular>
          {is_default && <DefaultTag />}
        </div>
      </td>

      <td>
        <GothamRegular style={{ color: jackColors.neutral900 }}>
          {extractTime(date_and_time)}
        </GothamRegular>
      </td>

      <td>
        <GothamRegular style={{ color: jackColors.neutral900 }}>
          <JackIcons
            name="edit_outline"
            fill="#BBBBC0"
            style={{ transform: "scale(0.8)" }}
            onClick={onClickEdit}
            className="iconHover"
          />
        </GothamRegular>
      </td>

      <td>
        <GothamRegular style={{ color: jackColors.neutral900 }}>
          <JackIcons
            name="delete_outline"
            fill="#BBBBC0"
            style={{ transform: "scale(0.8)" }}
            onClick={toggle}
            className="iconHover"
          />
        </GothamRegular>
      </td>
      <ConfirmationModalJack
        modal={isOpen}
        img={confirmIcon.tongSampah}
        buttonTextLeft={t("Cancel")}
        buttonTextRight={t("Delete")}
        title={t("Delete this payment cycle?")}
        text={t(
          "Rest assured, the existing linked transactions will continue to be paid at the initial schedule."
        )}
        onClick={() => handleDelete(id)}
        toggle={toggle}
      />
    </TransitionTr>
  );
};

export default PCTableRow;
