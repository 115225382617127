import { eventsTracker } from "../events";

const RETRY_RESUBMIT = {
  // Local Transfer
  RESUBMIT_LOCAL_TRANSFER: () => {
    eventsTracker("resubmit_local_transfer");
  },
  RESUBMIT_LOCAL_TRANSFER_SUBMIT: () => {
    eventsTracker("resubmit_local_transfer_submit");
  },
  RESUBMIT_REMIND_LOCAL_TRANSFER: () => {
    eventsTracker("resubmit_remind_local_transfer");
  },
  RESUBMIT_REMIND_LOCAL_TRANSFER_CONFIRM: () => {
    eventsTracker("resubmit_remind_local_transfer_confirm");
  },
  RETRY_LOCAL_TRANSFER: () => {
    eventsTracker("retry_local_transfer");
  },
  RETRY_INPUT_PIN_LOCAL_TRANSFER_CONFIRM: () => {
    eventsTracker("retry_input_pin_local_transfer_confirm");
  },
  RETRY_REMIND_LOCAL_TRANSFER: () => {
    eventsTracker("retry_remind_local_transfer");
  },
  RETRY_REMIND_LOCAL_TRANSFER_CONFIRM: () => {
    eventsTracker("retry_remind_local_transfer_confirm");
  },
  // Local Transfer

  // Payroll
  RESUBMIT_PAYROLL: () => {
    eventsTracker("resubmit_payroll");
  },
  RESUBMIT_PAYROLL_SUBMIT: () => {
    eventsTracker("resubmit_payroll_submit");
  },
  RESUBMIT_REMIND_PAYROLL: () => {
    eventsTracker("resubmit_remind_payroll");
  },
  RESUBMIT_REMIND_PAYROLL_CONFIRM: () => {
    eventsTracker("resubmit_remind_payroll_confirm");
  },
  RETRY_PAYROLL: () => {
    eventsTracker("retry_payroll");
  },
  RETRY_INPUT_PIN_PAYROLL_CONFIRM: () => {
    eventsTracker("retry_input_pin_payroll_confirm");
  },
  RETRY_REMIND_PAYROLL: () => {
    eventsTracker("retry_remind_payroll");
  },
  RETRY_REMIND_PAYROLL_CONFIRM: () => {
    eventsTracker("retry_remind_payroll_confirm");
  },
  // Payroll

  // Reimbursement
  RETRY_REIMBURSEMENT: () => {
    eventsTracker("retry_reimbursement");
  },
  RETRY_INPUT_PIN_REIMBURSEMENT_CONFIRM: () => {
    eventsTracker("retry_input_pin_reimbursement_confirm");
  },
  RETRY_REMIND_REIMBURSEMENT: () => {
    eventsTracker("retry_remind_reimbursement");
  },
  RETRY_REMIND_REIMBURSEMENT_CONFIRM: () => {
    eventsTracker("retry_remind_reimbursement_confirm");
  },
  // Reimbursement

  // Invoice
  RETRY_INVOICE: () => {
    eventsTracker("retry_invoice");
  },
  RETRY_INPUT_PIN_INVOICE_CONFIRM: () => {
    eventsTracker("retry_input_pin_invoice_confirm");
  },
  RETRY_REMIND_INVOICE: () => {
    eventsTracker("retry_remind_invoice");
  },
  RETRY_REMIND_INVOICE_CONFIRM: () => {
    eventsTracker("retry_remind_invoice_confirm");
  },
  // Invoice

  // Onboard
  RETRY_ONBOARDING_TO_ARTICLE: () => {
    eventsTracker("retry_onboarding_to_article");
  },
  RETRY_ONBOARDING_CONFIRM: () => {
    eventsTracker("retry_onboarding_confirm");
  },
  RETRY_ONBOARDING_CLOSE: () => {
    eventsTracker("retry_onboarding_close");
  },
  // Onboard
};

export default RETRY_RESUBMIT;
