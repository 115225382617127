import { BILL, LOCAL, PAYROLL, REIMBURSEMENT } from "../constants";

const formattedRawTrxDecider = ({ rawTrx, trx }) => {
  let formattedRawTrx = null;
  switch (trx?.type) {
    case LOCAL:
      formattedRawTrx = {
        ...rawTrx,
        local_transactions: trx?.failedTrxs,
        total_amount: trx?.totalAmount,
        total_batch_amount: trx?.totalPayment,
        total_fee: trx?.fee,
        type: "local_transfer",
      };
      return formattedRawTrx;
    case PAYROLL:
      formattedRawTrx = {
        ...rawTrx,
        batch: {
          ...rawTrx?.batch,
          local_transactions: trx?.failedTrxs,
          total_amount: trx?.totalAmount,
          total_batch_amount: trx?.totalPayment,
          total_fee: trx?.fee,
          type: "payroll",
        },
        type: "payroll",
      };
      return formattedRawTrx;
    case BILL:
      formattedRawTrx = {
        ...rawTrx,
        type: "invoice",
      };
      return formattedRawTrx;
    case REIMBURSEMENT:
      formattedRawTrx = {
        ...rawTrx,
        type: "reimbursement",
      };
      return formattedRawTrx;
    default:
      return rawTrx;
  }
};

export default formattedRawTrxDecider;
