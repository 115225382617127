import { getUserRole, useGetAuth } from "../../../../contexts/AuthContext";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";

const useAvailableCategories = (props) => {
  const { showAllCategories } = props ?? {};

  const { user } = useGetAuth();

  const userTeamId = user?.team_id;

  const { isEmployee } = getUserRole();

  const { categories: categoriesRaw } = useConstants();

  const cleanCategories = categoriesRaw.filter(
    ({ isDeleted, is_archived }) => !isDeleted && !is_archived
  );

  const categories =
    !isEmployee || showAllCategories
      ? cleanCategories
      : cleanCategories.filter(({ teams }) => {
          const isAvailableForAnyone = teams.length === 0;

          if (!userTeamId) return isAvailableForAnyone;

          const team_ids = teams.map(({ id }) => id);
          const isUserTeamCategory = team_ids.includes(userTeamId);

          return isAvailableForAnyone || isUserTeamCategory;
        });

  return { categories, hasCategoryList: categories.length > 0 };
};

export default useAvailableCategories;
