import { format } from "date-fns";
import { jackColors } from "../../../assets/colors";
import { RightModal } from "../../../components/Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import UpdateTrackerV2 from "../../../components/UpdateTrackerV2";
import {
  LongDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { SubTextComponent } from "../../bankAccountSettingComponents/tools/helpers";
import {
  font12,
  font14,
  font24,
} from "../../virtualAccountComponents/common/utils";
import Skeleton from "react-loading-skeleton";
import { useFetchMoveBalance } from "../parents";
import { useEffect, useState } from "react";
import { useFetchCompanyBalance } from "../logics";
import { useSubscriptionUsage } from "../../subscriptionsJackComponents/logics/general-hooks";
import { StatusAccountIconJack } from "../../../components/Status/JackStatusIcon";
import { StatusLabelJack } from "../../../components/StatusLabel";
import { canUpdateCategoryDecider } from "../../categoryComponents/general/helpers";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useMutation } from "../../../tools/api";
import CategorySelection from "../../categoryComponents/general/components/CategorySelection";
import { useTranslation } from "react-i18next";

export const MoveBalanceTransactionModal = ({
  isOpen,
  toggle,
  fromTransactionPage = false,
  id = null,
}) => {
  const { moveBalanceData, refetchMoveBalance, loadingMoveBalance } =
    useFetchMoveBalance({
      url: fromTransactionPage
        ? `/business_internal_transfers/${id}`
        : "/business_internal_transfers/show_move_balance",
      formatter: (res) => res?.data,
    });

  const { isUltimate } = useSubscriptionUsage();

  const { vaPocketBalance, danamonBalance, refetch } = useFetchCompanyBalance();

  const {
    amount,
    created_at,
    state,
    user,
    updated_at,
    reference_id,
    category_id,
    originator_type,
    source,
    target,
  } = moveBalanceData || {};
  const { name } = user || {};

  const isCompleted = state === "completed";
  const isFromPrimary =
    source === "client_danamon" && target === "client_transfezhub";

  const isFromVA =
    source || target
      ? source === "client_transfezhub" && target === "client_danamon"
      : true;

  const amountDecider = () => {
    if (isCompleted) {
      return {
        from: Number(amount),
        to:
          Number(isFromVA ? danamonBalance : vaPocketBalance) - Number(amount),
      };
    }
    return {
      from: Number(amount),
      to: Number(isFromVA ? danamonBalance : vaPocketBalance),
    };
  };

  const modalDetails = {
    amount: Number(amount),
    created_at,
    updated_at,
    state,
    name,
    from: amountDecider().from,
    to: amountDecider().to,
    reference_id,
    category_id,
    id,
    originator_type,
    isFromPrimary,
    isFromVA,
  };

  useEffect(() => {
    if (!isOpen) return;
    if (!isUltimate) return;
    refetch();
    refetchMoveBalance();
  }, [isOpen, isUltimate]);

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      array={[
        <ModalComponent
          details={modalDetails}
          loading={loadingMoveBalance}
          fromTransactionPage={fromTransactionPage}
        />,
      ]}
    />
  );
};

const ModalComponent = ({
  details,
  loading = false,
  fromTransactionPage = false,
}) => {
  const {
    name,
    created_at,
    updated_at,
    from,
    to,
    reference_id,
    state,
    amount,
    category_id,
    id,
    originator_type,
    isFromVA,
    isFromPrimary,
  } = details || {};

  const [selectedCategory, setSelectedCategory] = useState(null);
  const { successSnackBar } = ToasterHook();
  const { categories } = useConstants();
  const { t } = useTranslation("ledgers/ledgers");
  const { mutation: updateCategory } = useMutation({
    url: `/business_internal_transfers/${id}`,
    afterSuccess: () => {
      successSnackBar({ msg: t("Transaction category has been updated.") });
    },
  });

  const canUpdateCategory =
    fromTransactionPage &&
    canUpdateCategoryDecider({ deps: state }) &&
    !originator_type &&
    isFromPrimary;

  const onCategorySelect = (category) => {
    setSelectedCategory(category);
    updateCategory({ category_id: category?.id });
  };

  const fromToObj = isFromVA
    ? { from: t("VA Pocket"), to: t("Primary Bank Account") }
    : { from: t("Primary Bank Account"), to: t("VA Pocket") };

  const fromString = `IDR ${formatCurrencyNoDecimal(from)}`;
  const toString = `IDR ${formatCurrencyNoDecimal(to)}`;
  const totalString = `IDR ${formatCurrencyNoDecimal(
    Number(from) + Number(to)
  )}`;
  const amountString = `IDR ${formatCurrencyNoDecimal(amount)}`;
  const createdByString = `${t("Created by")} ${name}`; //
  const isFailed = state === "failed";
  const isOnProgress = state === "in_progress" || state === "on_progress";

  const dateFormatter = (inputDate) => {
    const date = inputDate ? new Date(inputDate) : new Date();
    const dateOnly = LongDateFormatter(inputDate);
    const timeOnly = format(date, "HH:mm");
    return `${dateOnly}, ${timeOnly}`;
  };

  const createdDate = dateFormatter(created_at);
  const updatedDate = dateFormatter(updated_at);

  const amountDecider = ({ type, text, isFrom }) => {
    if (from === undefined || to === undefined)
      return <Skeleton width={150} height={20} />;
    if (type === "text") {
      return (
        <GothamRegular style={{ ...font12, color: jackColors.neutral900 }}>
          {text}
        </GothamRegular>
      );
    }
    if (type === "total") {
      return (
        <GothamRegular style={{ ...font12, color: jackColors.neutral900 }}>
          {totalString}
        </GothamRegular>
      );
    }

    return (
      <SubTextComponent
        text={type}
        subText={isFrom ? fromString : toString}
        customTextStyle={{ textAlign: "right", ...font12 }}
        customSubStyle={{ textAlign: "right", ...font12 }}
        isNotBold
      />
    );
  };

  const amountDetails = [
    {
      text: t("From"),
      valueComponent: amountDecider({
        type: fromToObj.from,
        isFrom: true,
      }),
    },
    {
      text: t("Move To"),
      valueComponent: amountDecider({ type: fromToObj.to }),
      style: {
        borderBottom: `1px solid ${jackColors.neutral500}`,
        paddingBottom: 16,
      },
    },
    {
      text: t("Final Amount"),
      valueComponent: amountDecider({ type: "total" }),
    },
  ];

  const amountDetailsTrx = [
    {
      text: t("Created at"),
      valueComponent: amountDecider({ type: "text", text: createdDate }),
    },
    {
      text: t("Transaction ID"),
      valueComponent: amountDecider({ type: "text", text: reference_id }),
    },
  ];

  const detailsArray = fromTransactionPage
    ? [...amountDetailsTrx, ...amountDetails]
    : amountDetails;

  const fromText = `${t("moved balance from")} ${fromToObj.from}`;
  const toText = `${t("Sending balance to")} ${fromToObj.to}`;

  const trackerDecider = () => {
    if (fromTransactionPage) {
      if (isFailed) {
        return [
          {
            status: "success", // completed | success | failed | active | inactive (default) | partial_failed | retry
            contents: [
              {
                type: "primary", // primary | secondary | reason | custom
                content: (
                  <GothamRegular>
                    <span style={{ fontWeight: "bold" }}>{`[${name}]`}</span>{" "}
                    {fromText}
                  </GothamRegular>
                ),
              },
              {
                type: "secondary", // primary | secondary | reason | custom
                content: (
                  <GothamRegular style={{ color: jackColors.neutral700 }}>
                    {createdDate}
                  </GothamRegular>
                ),
              },
            ],
          },
          {
            status: "failed",
            contents: [
              {
                type: "primary",
                content: <GothamRegular>{toText}</GothamRegular>,
              },
              {
                type: "secondary", // primary | secondary | reason | custom
                content: (
                  <GothamRegular style={{ color: jackColors.neutral700 }}>
                    {updatedDate}
                  </GothamRegular>
                ),
              },
            ],
          },
          {
            status: "inactive",
            contents: [
              {
                type: "primary",
                content: (
                  <GothamRegular style={{ color: jackColors.neutral700 }}>
                    {t("Balance has been sent")}
                  </GothamRegular>
                ),
              },
            ],
          },
        ];
      }
      if (isOnProgress) {
        return [
          {
            status: "success", // completed | success | failed | active | inactive (default) | partial_failed | retry
            contents: [
              {
                type: "primary", // primary | secondary | reason | custom
                content: (
                  <GothamRegular>
                    <span style={{ fontWeight: "bold" }}>{`[${name}]`}</span>{" "}
                    {fromText}
                  </GothamRegular>
                ),
              },
              {
                type: "secondary", // primary | secondary | reason | custom
                content: (
                  <GothamRegular style={{ color: jackColors.neutral700 }}>
                    {createdDate}
                  </GothamRegular>
                ),
              },
            ],
          },
          {
            status: "active",
            contents: [
              {
                type: "primary",
                content: <GothamRegular>{toText}</GothamRegular>,
              },
              {
                type: "secondary", // primary | secondary | reason | custom
                content: (
                  <GothamRegular style={{ color: jackColors.neutral700 }}>
                    {updatedDate}
                  </GothamRegular>
                ),
              },
            ],
          },
          {
            status: "inactive",
            contents: [
              {
                type: "primary",
                content: (
                  <GothamRegular style={{ color: jackColors.neutral700 }}>
                    {t("Balance has been sent")}
                  </GothamRegular>
                ),
              },
            ],
          },
        ];
      }
      return [
        {
          status: "success", // completed | success | failed | active | inactive (default) | partial_failed | retry
          contents: [
            {
              type: "primary", // primary | secondary | reason | custom
              content: (
                <GothamRegular>
                  <span style={{ fontWeight: "bold" }}>{`[${name}]`}</span>{" "}
                  {fromText}
                </GothamRegular>
              ),
            },
            {
              type: "secondary", // primary | secondary | reason | custom
              content: (
                <GothamRegular style={{ color: jackColors.neutral700 }}>
                  {createdDate}
                </GothamRegular>
              ),
            },
          ],
        },
        {
          status: "success",
          contents: [
            {
              type: "primary",
              content: <GothamRegular>{toText}</GothamRegular>,
            },
            {
              type: "secondary", // primary | secondary | reason | custom
              content: (
                <GothamRegular style={{ color: jackColors.neutral700 }}>
                  {createdDate}
                </GothamRegular>
              ),
            },
          ],
        },
        {
          status: "completed",
          contents: [
            {
              type: "primary",
              content: (
                <GothamRegular style={{ color: jackColors.neutral700 }}>
                  {t("Balance has been sent")}
                </GothamRegular>
              ),
            },
            {
              type: "secondary", // primary | secondary | reason | custom
              content: (
                <GothamRegular style={{ color: jackColors.neutral700 }}>
                  {createdDate}
                </GothamRegular>
              ),
            },
          ],
        },
      ];
    }

    return [
      {
        status: "success", // completed | success | failed | active | inactive (default) | partial_failed | retry
        contents: [
          {
            type: "primary", // primary | secondary | reason | custom
            content: (
              <GothamRegular>
                <span style={{ fontWeight: "bold" }}>{`[${name}]`}</span>{" "}
                {fromText}
              </GothamRegular>
            ),
          },
          {
            type: "secondary", // primary | secondary | reason | custom
            content: (
              <GothamRegular style={{ color: jackColors.neutral700 }}>
                {updatedDate}
              </GothamRegular>
            ),
          },
        ],
      },
      {
        status: "active",
        contents: [
          {
            type: "primary",
            content: <GothamRegular>{toText}</GothamRegular>,
          },
        ],
      },
      {
        status: "inactive",
        contents: [
          {
            type: "primary",
            content: (
              <GothamRegular style={{ color: jackColors.neutral700 }}>
                {t("Balance has been sent")}
              </GothamRegular>
            ),
          },
        ],
      },
    ];
  };

  useEffect(() => {
    if (!fromTransactionPage) return;
    if (selectedCategory) return;
    const category = (categories ?? []).find(({ id }) => id === category_id);
    if (category) setSelectedCategory(category);
  }, [categories, selectedCategory, category_id]);

  const trackerSkeleton = loading || !name;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 32,
        // overflowY: "scroll",
        marginBottom: 80,
      }}
    >
      {fromTransactionPage && (
        <>
          <StatusAccountIconJack state={"money-movement"} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <GothamRegular style={{ ...font14 }}>
              {`${t("Moved balance to")} ${fromToObj.to} `}
            </GothamRegular>
            <GothamMedium style={{ ...font24 }}>{amountString}</GothamMedium>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <GothamRegular
                style={{ ...font14, color: jackColors.neutral700 }}
              >
                {createdByString}
              </GothamRegular>
              <StatusLabelJack status={state} isOutline />
            </div>
          </div>
        </>
      )}

      {!fromTransactionPage && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <GothamMedium style={{ ...font24 }}>
            {t("Move balance is in progress")}
          </GothamMedium>
          <GothamRegular style={{ ...font14, color: jackColors.neutral800 }}>
            {t("Track any updates by checking the tracker below.")}
          </GothamRegular>
        </div>
      )}

      {trackerSkeleton ? (
        <Skeleton height={124} />
      ) : (
        <UpdateTrackerV2
          // dotStyle={{ marginTop: 4 }}
          progressLineStyle={{ padding: "10px 0" }}
          updates={trackerDecider()}
        />
      )}
      {canUpdateCategory && (
        <CategorySelection
          isForTrxDetails
          style={{ marginBottom: 0 }}
          showAllCategories={true}
          selectedCategory={selectedCategory ?? {}}
          onSelect={(category) => {
            onCategorySelect(category);
          }}
        />
      )}

      <div
        style={{
          padding: 12,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          border: `1px solid ${jackColors.neutral500}`,
          borderRadius: 4,
        }}
      >
        {detailsArray.map((detail, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              ...(detail.style || {}),
            }}
          >
            <GothamRegular style={{ ...font12, color: jackColors.neutral700 }}>
              {detail.text}
            </GothamRegular>
            {detail.valueComponent}
          </div>
        ))}
      </div>
    </div>
  );
};
