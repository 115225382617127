import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React from "react";
import { colors, jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { GothamMedium } from "../../../components/Text";
import { useTranslation } from "react-i18next";

export const CloseHeader = ({
  middleComponent,
  woIcon,
  onClose,
  style = {},
}) => {
  const { push } = useRouter();
  const { t } = useTranslation("common");

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{
        padding: "20px 32px",
        marginBottom: 20,
        ...style,
      }}
    >
      {!woIcon && (
        <JackIcons name="fullsize" onClick={() => push("/dashboard")} />
      )}
      <div>{middleComponent}</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <GothamMedium
          style={{
            color: colors.neutral800,
          }}
        >
          {t("Close")}
        </GothamMedium>
        <div
          style={{
            width: "40px",
            height: "40px",
            border: `1px solid ${colors.neutral500}`,
            display: "grid",
            placeItems: "center",
            borderRadius: "4px",
          }}
          className="hover"
          onClick={onClose}
        >
          <JackIcons name="close" fill={jackColors.black34} />
        </div>
      </div>
    </div>
  );
};
