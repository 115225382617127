import { colors } from "../../assets/colors";
import { GothamRegular, SpanColor } from "../../components/Text";
import { digitDateWithDigitHourFormatter } from "../../components/tools";

export const ValidatingFormatter = (item) => {
  const { status_history, created_at } = item;

  const array = [
    {
      date: digitDateWithDigitHourFormatter(created_at),
      text: "You created a transaction",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(status_history[0]?.created_at),
      text: "We have received your payment and we will verify your transaction ASAP!",
      isActive: true,
      customComponents: (
        <>
          <GothamRegular>
            We have received your payment and{" "}
            <SpanColor color={colors.orangeFF}>
              we will verify your transaction
            </SpanColor>{" "}
            ASAP!
          </GothamRegular>
        </>
      ),
    },
    {
      text: "Transferring money to recipients",
    },
    {
      text: "Completed",
    },
  ];

  return array;
};
