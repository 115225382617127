import { jackColors } from "../assets/colors";
import { GothamMedium } from "./Text";
import React from "react";

export const Counter = ({ number, isOrange, style }) => {
  const numberToUse = number > 99 ? "99+" : number;

  return (
    <div
      style={{
        backgroundColor: isOrange ? jackColors.orangeF2 : jackColors.black34,
        minWidth: 23,
        height: 20,
        borderRadius: 4,
        padding: "0 3px",
        ...style,
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <GothamMedium className="font12" style={{ color: "white" }}>
        {numberToUse}
      </GothamMedium>
    </div>
  );
};
