import { eventsTracker } from "../events";

const LOCAL_TRANSFER = {
  // Local Transfer General
  OPEN_CREATE_BTN: () => {
    eventsTracker("open_create");
  },
  CREATE_LOCAL_TRX: () => {
    eventsTracker("create_to_local");
  },
  LOCAL_SINGLE: () => {
    eventsTracker("local_single");
  },
  LOCAL_EXCEL: () => {
    eventsTracker("local_excel");
  },
  LOCAL_TABLE: () => {
    eventsTracker("local_table");
  },

  // SINGLE 
  LOCAL_SINGLE_NEW_VALIDATE: () => {
    eventsTracker("local_single_new_validate");
  },
  LOCAL_SINGLE_NEW_CONTINUE: () => {
    eventsTracker("local_single_new_continue");
  },
  LOCAL_SINGLE_CONTINUE: () => {
    eventsTracker("local_single_continue");
  },
  LOCAL_SINGLE_CONTINUE_BATCH: () => {
    eventsTracker("local_single_continue_batch");
  },
  LOCAL_SINGLE_ADD_LIST: () => {
    eventsTracker("local_single_add_list");
  },
  LOCAL_SINGLE_ADD_LIST_VALIDATE: () => {
    eventsTracker("local_single_add_list_validate");
  },
  LOCAL_SINGLE_ADD_LIST_CONTINUE: () => {
    eventsTracker("local_single_add_list_continue");
  },
  LOCAL_SINGLE_ADD_LIST_VALIDATE: () => {
    eventsTracker("local_single_add_list_validate");
  },
  LOCAL_SINGLE_SUBMIT: () => {
    eventsTracker("local_single_submit");
  },
  LOCAL_SINGLE_SCREEN_VIEW: () => {
    eventsTracker("local_single_screen_view");
  },
  LOCAL_SINGLE_ADD: () => {
    eventsTracker("local_single_add");
  },
  LOCAL_SINGLE_ADD_BATCH: () => {
    eventsTracker("local_single_add_batch");
  },
};

export default LOCAL_TRANSFER;
