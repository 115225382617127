import { titleCase } from "change-case";
import React, { useEffect } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { useFileViewer } from "../../../contexts/FilePreviewContext/parent";
import { useGdrivehook } from "../../../contexts/GoogleDrivePreviewContext/parent";
import { showPDFOrJPEGHooks } from "../../inputs";
import { GothamMedium, GothamRegular } from "../../Text";
import { Files, SpanError } from "./components";
import {
  fileTypesTranslatorArrayToString,
  useSetsValueFileInput,
} from "./logics";
import { useTranslation } from "react-i18next";
import { isID } from "../../tools";
import { useLanguage } from "public/locales/translationFunctions";

export const FileInputButtonJack = ({
  useFormObj,
  name,
  label: labelProps,
  multiple = false,
  maxFiles = 5,
  maxSizeMB = 10,
  fileTypes = ["image/png", "application/pdf"],
  showLabel = true,
  enablePreview = false,
}) => {
  const fileTypeString = fileTypesTranslatorArrayToString(fileTypes);
  const { t: tCommon } = useTranslation("common");
  const label = labelProps || titleCase(name);
  const { register, watch, setValue } = useFormObj;
  const { setsValue, ref } = useSetsValueFileInput({
    fileTypes,
    maxFiles,
    maxSizeMB,
    multiple,
    name,
    useFormObj,
  });

  useEffect(() => {
    register({ name });
  }, []);

  const currValue = watch(name);
  const { show } = useGdrivehook();
  const filesDecider = () => {
    if (Array.isArray(currValue)) return currValue;
    if (currValue) return [currValue];
    return [];
  };
  const files = filesDecider();
  const handleShow = (index = 0) => show({ files, index });
  const { isBahasa: isID } = useLanguage();

  return (
    <div>
      {showLabel && <GothamMedium className="mb-3">{label}</GothamMedium>}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 0,
        }}
      >
        <label
          className="d-flex align-items-center justify-content-between hover"
          style={{
            border: `1px solid ${jackColors.greyE6}`,
            padding: "10px 0px",
            borderRadius: 4,
            width: isID ? 170 : 154,
            height: 40,
            marginBottom: 0,
          }}
        >
          <JackIcons
            name="plus-outline"
            fill={jackColors.black34}
            style={{ width: 18, height: "auto", marginLeft: 13.33 }}
          />
          <GothamMedium className="font14" style={{ marginRight: 10 }}>
            {tCommon("Add Documents")}
          </GothamMedium>
          <input
            type="file"
            style={{ display: "none" }}
            multiple={multiple}
            onChange={(e) => {
              if (!e) return;
              setsValue(e);
            }}
          />
        </label>
        <GothamRegular
          className="font10 ml-2"
          style={{ color: jackColors.grey90 }}
        >
          <SpanError>
            {tCommon("File types")}: {fileTypeString}
          </SpanError>{" "}
          <br />
          <SpanError>
            {tCommon("Max. upload")}: {maxFiles} {tCommon("files")}
          </SpanError>
          ,{" "}
          <SpanError>
            {maxSizeMB}
            {tCommon("MB each")}
          </SpanError>
        </GothamRegular>
      </div>
      <Files
        files={files}
        multiple
        name={name}
        setValue={setValue}
        enablePreview={enablePreview}
        handleShow={handleShow}
        inputRef={ref}
      />
    </div>
  );
};
