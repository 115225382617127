import { useRef } from "react";

export const HeaderDataTableLocal = () => {
  const tableBodyRef = useRef();
  const tableBodyWidth = tableBodyRef?.current?.clientWidth || 0;

  // should be width = 1408

  const ngitungLebar = (number) => {
    return `${(number / tableBodyWidth) * 100}%`;
  };

  const headerArray = [
    {
      name: "delete",
      value: "Delete",
      width: ngitungLebar(40),
      maxWidth: 40,
    },
    {
      name: "number",
      value: "No",
      width: ngitungLebar(40),
      maxWidth: 40,
    },
    // {
    //   name: "number",
    //   value: "No",
    //   width: ngitungLebar(60),
    //   maxWidth: 60
    //   // width: 43,
    // },
    {
      name: "name",
      value: "Name",
      width: ngitungLebar(240),
      maxWidth: 240,
      // width: 210,
    },
    {
      name: "bank",
      value: "Bank",
      width: ngitungLebar(176),
      maxWidth: 176,
      // width: 120,
    },
    {
      name: "account_number",
      value: "Bank Account Number",
      width: ngitungLebar(176),
      maxWidth: 176,
      // width: 181.33,
    },

    {
      name: "notes",
      value: "Notes",
      width: ngitungLebar(256),
      maxWidth: 256,
      // width: 181.33,
    },
    {
      name: "email",
      value: "Email",
      width: ngitungLebar(256),
      maxWidth: 256,
      // width: 264,
    },
    {
      name: "amount",
      value: "Amount",
      width: ngitungLebar(160),
      maxWidth: 160,
      // width: 181.33,
    },
    {
      name: "action",
      value: "Action",
      width: ngitungLebar(56),
      maxWidth: 56,
      // width: 71,
    },
  ];

  return { headerArray, tableBodyRef };
};
