import styles from "./Pulse.module.css";

const Pulse = ({ isActive = false, children = null, style = {} }) => {
  return (
    <div
      className={styles["pulse"]}
      style={{
        ...style,
        animation: isActive ? "" : "none",
        backgroundColor: isActive ? "" : "transparent",
      }}
    >
      {children}
    </div>
  );
};

export default Pulse;
