import { colors } from "../../assets/colors";
import { GothamRegular, SpanColor } from "../../components/Text";
import { digitDateWithDigitHourFormatter } from "../../components/tools";

export const OnProgressFormatter = (item) => {
  const { status_history, created_at } = item;

  const array = [
    {
      date: digitDateWithDigitHourFormatter(created_at),
      text: "You created a transaction",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(status_history[0]?.created_at),
      text: "We have received your payment",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(status_history[1]?.created_at),
      text: "Sit tight, we are working on our magic to deliver your money",
      isActive: true,
      customComponents: (
        <>
          <GothamRegular>
            Sit tight, we are working on our magic to{" "}
            <SpanColor color={colors.orangeFF}>deliver your money</SpanColor>
          </GothamRegular>
        </>
      ),
    },
    {
      text: "Completed",
    },
  ];

  return array;
};
