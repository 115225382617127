import { useRouter } from "next/router";
import React, { useRef, useState } from "react";
import ViewportList from "react-viewport-list";
import { colors, jackColors } from "../../../../assets/colors";
import imgDelete from "../../../../assets/images/delete-trash-grey.svg";
import editIcon from "../../../../assets/images/edit-outline.svg";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { CheckBox } from "../../../../components/Checkbox";
import CustomHoverInfo from "../../../../components/CustomHoverInfo/CustomHoverInfo";
import { GothamRegular } from "../../../../components/Text";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { EmailInput } from "../../../localTransferEnhancement/create/table/inputs/email/parent";
import { isDuplicateOnDataTable, isErrorOnDataTable } from "../logic";
import {
  ModalEditSelectTable,
  ModalEditTable,
  ModalEditTableCombination,
  defaultOnChangeTablePayroll,
} from "../table/modal";
import { TooltipEdit } from "../tooltip";
import {
  DataCombinationPayroll,
  renderedValue,
  useBooleanTable,
} from "./logic";
import { useTranslation } from "react-i18next";

export const TheadTablePayroll = ({
  headerArray,
  isAllChecked,
  handleClickAll = () => {},
  renderFirst,
  showDeleteRow = false,
  fromModal = false,
  isAnythingSelected,
  isScreenIncomplete,
}) => {
  const { query } = useRouter();
  const isResubmit = query.action === "resubmit";
  const { t } = useTranslation("payroll/create");

  return (
    <thead
      style={{
        // position: "sticky",
        top: 0,
        backgroundColor: "white",
        zIndex: 40,
        // border: "1px solid black",
      }}
    >
      <tr>
        {headerArray.map((item, index) => {
          const { name, value, width } = item || {};
          const {
            isNumber,
            isName,
            isBank,
            isAccNumber,
            isSalary,
            isNotes,
            isAction,
            isRole,
            isDept,
            isEmail,
            isDelete,
          } = useBooleanTable({ name });
          const secondRender = isRole || isDept || isEmail;
          const firstRender = isBank || isAccNumber || isSalary || isNotes;
          const isHideButton = isNumber || isAction;

          const MaskingLabel = (value) => {
            if (isName) return t("Employee");
            if (isAccNumber) return t("Account Number");
            if (isSalary) return t("Salary");
            if (isNotes) return t("Transfer Note");
            if (isBank) return t("Bank Name");
            if (isAction) return "";
            return value;
          };

          if (isNumber || isRole || isDept) return null;

          if ((isAction || isDelete) && isResubmit) return null;

          const renderedValue = () => {
            if (isDelete) {
              if (isAnythingSelected && !isScreenIncomplete) {
                return (
                  <div
                    className="text-center"
                    style={{
                      maxHeight: 20,
                    }}
                  >
                    <CheckBox
                      fillCustom={
                        isAllChecked
                          ? jackColors.neutral900
                          : jackColors.neutral600
                      }
                      isActive={isAllChecked}
                      onClick={handleClickAll}
                      style={{ width: 16, height: 16 }}
                    />
                  </div>
                );
              }
              return "";
            }
            return (
              <GothamRegular
                className="font12"
                style={{
                  color: jackColors.neutral700,
                  textAlign: isSalary && "end",
                }}
              >
                {MaskingLabel(value)}
              </GothamRegular>
            );
          };

          return (
            <th
              key={index}
              style={{
                padding: "12px 8px 11.5px 8px",
                color: colors.grey6c,
                // whiteSpace: "nowrap",
                // overflow: "hidden",
                width: width,
                maxWidth: width,
                textAlign: isHideButton && "center",
                // borderBottom: `1px solid ${jackColors.neutral500}`,
              }}
            >
              {renderedValue()}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export const TbodyTablePayroll = ({
  data,
  close,
  isOpen,
  setData,
  noBorder,
  modalIndex,
  renderFirst,
  handleClick,
  headerArray,
  selectedIds,
  filteredData,
  isOpenDelete,
  toggleDelete,
  showDeleteRow,
  confirmDelete,
  handleClickItem,
  isScreenDuplicate,
  isAnythingSelected,
  isScreenIncomplete,
}) => {
  const [isHoverTr, setIsHoverTr] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [hoveredErrorInfo, setHoveredErrorInfo] = useState("");
  const [validResubmitFields, setValidResubmitFields] = useState({
    bank: false,
    account: false,
  });

  const { t } = useTranslation("payroll/create");

  const { query } = useRouter();
  const isResubmit = query.action === "resubmit";

  const ref = useRef(null);

  const filteredArrNumbers = filteredData.map((array) => array[2]?.value);

  const { localTransferBanks: optionsBank, idBanks } = useConstants();

  const newArr = data?.map((item) => {
    if (!item) return undefined;
    const value = item[2]?.value;
    if (filteredArrNumbers.includes(value)) return item;
    return undefined;
  });

  return (
    <tbody ref={ref}>
      <ViewportList
        viewportRef={ref}
        items={newArr}
        itemMinSize={100}
        margin={8}
        overscan={10}
      >
        {(array, index) => {
          if (!array) return null;
          const isActive = (selectedIds || []).includes(index);
          const isThereError = isErrorOnDataTable({ array });
          const isThereDuplicate = isDuplicateOnDataTable({ array });
          const isHovered = isHoverTr === index;
          const isFirst = index === 0;

          if (isScreenIncomplete && !isThereError) return <tr></tr>;
          if (isScreenDuplicate && !isThereDuplicate) return <tr></tr>;

          const bgColorDecider = () => {
            if (isScreenDuplicate || isScreenIncomplete) {
              if (isHovered) return jackColors.neutral500;
              return "transparent";
            }
            if (isThereError && !isScreenIncomplete) return "#FFEBE8";
            if (isThereDuplicate && !isScreenDuplicate) return "#FFF6E6";
            if (isHovered) return jackColors.neutral500;
          };

          const { array: dataCombination, objDataCombination } =
            DataCombinationPayroll(array);

          return (
            <tr
              key={index}
              style={{
                transition: "all 300ms",
                backgroundColor: bgColorDecider(),
                height: 64,
              }}
              onMouseEnter={() => setIsHoverTr(index)}
              onMouseLeave={() => setIsHoverTr(false)}
            >
              {array.map((item, childIndex) => {
                if (!item) return <td>-</td>;
                const { name, value } = item;
                const getWidth = headerArray.filter(
                  ({ name: nameTd }) => nameTd === name
                )[0]?.maxWidth;
                const doubleIndex = [index, childIndex];
                const isIndexSame =
                  JSON.stringify(doubleIndex) === JSON.stringify(modalIndex);
                const isHoverIndexSame =
                  JSON.stringify(doubleIndex) === JSON.stringify(isHover);
                const isShowIcon = isHoverIndexSame ? "initial" : "none";
                const {
                  isNumber,
                  isName,
                  isBank,
                  isNotes,
                  isAction,
                  isSalary,
                  isEmail,
                  isDelete,
                  isRole,
                  isDept,
                  isId,
                  isLocalId,
                  isRequired,
                  isAccNumber,
                } = useBooleanTable({ name });
                const isHideButton = isNumber || isAction || isDelete;
                const isSelectModal = isBank;
                const isCombinationModal = isName || isDept || isRole;

                const finalValue = renderedValue({
                  item,
                  index,
                  showDeleteRow,
                  isActive,
                  handleClickItem,
                  options: idBanks, // optionsBank, --> used to this but somehow the data filtered not complete
                  isHovered,
                  isAnythingSelected,
                  objDataCombination,
                  t,
                });

                const styleCustom = {
                  maxWidth: getWidth,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                };

                if (isId || isLocalId || isNumber || isRole || isDept)
                  return null;

                if ((isDelete || isAction) && isResubmit) return null;

                const onEditConfirm = () => {
                  const makeValid = (fieldName) => {
                    return setValidResubmitFields((prev) => ({
                      ...prev,
                      [fieldName]: true,
                    }));
                  };

                  if (isBank) return makeValid(`bank_${index}`);
                  if (isAccNumber) return makeValid(`account_${index}`);
                };

                const props = {
                  data,
                  item,
                  setData,
                  index,
                  childIndex,
                  close,
                  onEditConfirm,
                };

                const ModalDecider = () => {
                  if (isSelectModal) {
                    return (
                      <ModalEditSelectTable {...props} required={isRequired} />
                    );
                  }
                  if (isCombinationModal) {
                    return (
                      <ModalEditTableCombination
                        {...props}
                        dataCombination={dataCombination}
                      />
                    );
                  }
                  if (isEmail)
                    return (
                      <EmailInput
                        index={index}
                        // scrollHeight={scrollTop}
                        // headerHeight={headerHeight}
                        trHeight={46}
                        onlyModal
                        value={value}
                        close={close}
                        isLast={index > 1 && index >= newArr.length - 3}
                        setValue={(val) =>
                          defaultOnChangeTablePayroll({
                            setData,
                            index,
                            name,
                            value: val,
                            woInvalid: true,
                          })
                        }
                      />
                    );
                  return <ModalEditTable notRequired={isNotes} {...props} />;
                };

                return (
                  <td
                    key={childIndex}
                    style={{
                      maxWidth: getWidth,
                      padding: "12px 8px",
                      position: "relative",
                      // borderTop:
                      //   !isFirst && `1px solid ${jackColors.neutral500}`,
                      borderTop: `1px solid ${jackColors.neutral500}`,
                    }}
                    onMouseEnter={() => setIsHover(doubleIndex)}
                    onMouseLeave={() => setIsHover(false)}
                  >
                    <div
                      style={{
                        position: "relative",
                        cursor: !isNumber && "pointer",
                      }}
                      className={`d-flex ${
                        isHideButton
                          ? "justify-content-center"
                          : isSalary && "justify-content-end"
                      }`}
                      onClick={() => handleClick(data, item, index, childIndex)}
                    >
                      {isResubmit &&
                        ((isBank && !validResubmitFields[`bank_${index}`]) ||
                          (isAccNumber &&
                            !validResubmitFields[`account_${index}`])) && (
                          <div
                            style={{ position: "relative" }}
                            onMouseEnter={() =>
                              setHoveredErrorInfo(`${name}_${index}`)
                            }
                            onMouseLeave={() => setHoveredErrorInfo("")}
                          >
                            <JackIcons
                              name="alert-circle-outline"
                              fill={jackColors.redE7}
                              style={{
                                scale: "0.8",
                                translate: "0px -2px",
                                marginRight: "4px",
                              }}
                            />
                            <CustomHoverInfo
                              show={hoveredErrorInfo === `${name}_${index}`}
                              style={{
                                width: "320px",
                                padding: "12px 8px",
                                translate: "-142px -80px",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                              translateTrianglePoint="150px 38px"
                              rotateTrianglePoint="-90deg"
                              backgroundColor="white"
                            >
                              <GothamRegular
                                woFontColor
                                className="font12"
                                style={{ color: jackColors.neutral800 }}
                              >
                                Please recheck and update the{" "}
                                <span style={{ fontFamily: "GothamMedium" }}>
                                  {isBank
                                    ? "bank details"
                                    : isAccNumber
                                    ? "account number"
                                    : ""}
                                </span>
                                .
                              </GothamRegular>
                            </CustomHoverInfo>
                          </div>
                        )}
                      {!isHideButton && isSalary && (
                        <TooltipEdit>
                          <img
                            src={editIcon}
                            style={{
                              display: isShowIcon,
                              width: 20,
                              marginRight: 8,
                            }}
                          />
                        </TooltipEdit>
                      )}
                      <GothamRegular
                        style={{
                          color: colors.grey33,
                          ...styleCustom,
                        }}
                      >
                        {finalValue}
                      </GothamRegular>
                      {!isHideButton && !isSalary && (
                        <TooltipEdit>
                          <img
                            src={editIcon}
                            style={{
                              display: isShowIcon,
                              width: 20,
                              marginLeft: 8,
                            }}
                          />
                        </TooltipEdit>
                      )}
                    </div>
                    {isOpen && isIndexSame && ModalDecider()}
                  </td>
                );
              })}
            </tr>
          );
        }}
      </ViewportList>
      <ConfirmationModalJack
        modal={isOpenDelete}
        toggle={toggleDelete}
        onClick={confirmDelete}
        img={imgDelete}
        buttonTextRight={t("Delete")}
        buttonTextLeft={t("Back")}
        title={t("Delete this transaction?")}
        text={t("You will delete this transaction from the list.")}
      />
    </tbody>
  );
};
