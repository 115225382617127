import React from "react";
import { colors } from "../assets/colors";
import noResult from "../assets/images/no-result-found.png";
import transaction from "../assets/images/transaction.svg";
import { GothamMedium, GothamRegular } from "../components/Text";
import { windowDimension } from "../components/tools";
import { CustomButton } from "./Buttons";

const EmptyScreen = ({
  title = "You can see the summary of your transaction here.",
  msg = "You don’t have any transactions yet.",
  msg2 = "",
  style,
  titleStyle,
  msgStyle,
  image = transaction,
  button = "",
  onClick = () => {},
}) => {
  const { height, figmaHeight } = windowDimension();
  return (
    <div
      style={{
        display: "flex",
        height: height / 1.2,
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <div>
        <div
          style={{
            flex: 1,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img src={image} />
        </div>
        <GothamMedium
          style={{
            fontSize: 14,
            lineHeight: "22px",
            marginTop: figmaHeight(24),
            textAlign: "center",
            ...titleStyle,
            marginBottom: 4,
          }}
        >
          {title}
        </GothamMedium>
        <GothamRegular
          style={{
            textAlign: "center",
            lineHeight: "22px",
            fontSize: 14,
            color: "#727272",
            ...msgStyle,
          }}
        >
          {msg}
          <br />
          {msg2 && msg2}
        </GothamRegular>
        {button && (
          <CustomButton onClick={onClick} style={{ width: 400, marginTop: 24 }}>
            {button}
          </CustomButton>
        )}
      </div>
    </div>
  );
};
export default EmptyScreen;

export const emptyTitle = "No Results Found";
export const emptyMsg = "Sorry we can’t found the item you are searching.";
export const emptyMsg2 = "Please try different keywords";

export const EmptyScreenSearch = ({
  title = emptyTitle,
  msg = emptyMsg,
  msg2 = emptyMsg2,
  isTransaction,
  img,
  titleStyle,
}) => {
  const { isTabOrPhone } = windowDimension();

  const imgDecider = () => {
    if (img) return img;
    if (isTransaction) return transaction;
    return noResult;
  };

  return (
    <EmptyScreen
      title={title}
      msg={msg}
      msg2={msg2}
      noButton
      image={imgDecider()}
      style={{ height: isTabOrPhone ? 300 : 400 }}
      titleStyle={{ fontSize: 14, ...titleStyle }}
      msgStyle={{ color: colors.grey6c, fontSize: 14 }}
    />
  );
};
