import { colors } from "assets/colors";
import { ReactNode } from "react";
import {
  TextInlineRegularProps,
  GothamRegularProps,
  GothamMediumProps,
} from "./Text.types";

export const GothamRegular = ({
  children,
  style,
  className = "",
  woClassName = false,
  woFontColor = false,
  woNewLine = false,
  ...props
}: GothamRegularProps) => {
  const isFontClassName = className.includes("font");
  const DefaultText = ({ children }: { children: ReactNode }) => (
    <h1
      className={isFontClassName ? className : `font14 ${className}`}
      style={{
        fontFamily: "GothamBook",
        fontWeight: 400,
        color: !woFontColor ? colors.grey25 : undefined,
        marginBottom: 0,
        ...style,
      }}
      {...props}
    >
      {children}
    </h1>
  );
  const hasNewLine = String(children).includes("\n") && !woNewLine;
  const childrenIsString = typeof children == "string";
  if (hasNewLine && childrenIsString) {
    const splittedStrings = children.split("\n");
    return (
      <>
        {splittedStrings.map((string, index) => (
          <DefaultText key={index}>{string}</DefaultText>
        ))}
      </>
    );
  }

  return <DefaultText>{children}</DefaultText>;
};

export const GothamMedium = ({
  woClassName,
  className = "",
  children,
  style,
  woFontColor,
  ...props
}: GothamMediumProps) => {
  const isFontClassName = className.includes("font");

  const DefaultText = ({
    children,
  }: {
    children: GothamMediumProps["children"];
  }) => (
    <h1
      style={{
        fontFamily: "GothamMedium",
        marginBottom: 0,
        ...style,
      }}
      className={isFontClassName ? className : `font14 ${className}`}
      {...props}
    >
      {children}
    </h1>
  );

  const hasNewLine = String(children).includes("\n");
  const childrenIsString = typeof children == "string";
  if (hasNewLine && childrenIsString) {
    const splittedStrings = children.split("\n");
    return (
      <>
        {splittedStrings.map((string, index) => (
          <DefaultText key={index}>{string}</DefaultText>
        ))}
      </>
    );
  }

  return <DefaultText>{children}</DefaultText>;
};

export const TextInlineRegular = ({
  children,
  className = "",
  style,
}: TextInlineRegularProps) => (
  <span
    style={{
      fontFamily: "GothamBook",
      display: "inline",
      ...style,
    }}
    className={`my-0 ${className}`}
  >
    {children}
  </span>
);

export const TextInlineMedium = ({
  children,
  className = "",
  style,
  ...props
}: TextInlineRegularProps) => (
  <span
    style={{
      fontFamily: "GothamMedium",
      display: "inline",
      ...style,
    }}
    className={`my-0 ${className}`}
    {...props}
  >
    {children}
  </span>
);
