import { Fragment } from "react";
import { GothamMedium } from "../../../../../components/Text";
import { jackColors } from "../../../../../assets/colors";
import { Banner } from "../../../../../components/Banner";
import { useLanguage } from "public/locales/translationFunctions";

const ResubmitBanner = ({ batchName = "" }) => {
  const { isBahasa } = useLanguage();
  const resubmitTitle = isBahasa ? "Ajukan Kembali" : "Resubmit Transaction";
  const msg1 = isBahasa
    ? "Ini adalah transaksi yang gagal dalam"
    : "These are the failed transaction(s) in";
  const msg2 = isBahasa
    ? "Harap periksa dengan teliti sebelum anda memvalidasi dan mengajukan ulang transaksi."
    : "Please review the details thoroughly before you validate and resubmit the transaction(s).";

  return (
    <Fragment>
      <GothamMedium
        className="font24"
        style={{ color: jackColors.neutral900, marginBottom: "24px" }}
      >
        {resubmitTitle}
      </GothamMedium>
      <Banner
        style={{ marginBottom: "24px" }}
        msg={
          <Fragment>
            {msg1}{" "}
            <span style={{ fontFamily: "GothamMedium" }}>{batchName}</span>.
            {msg2}
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default ResubmitBanner;
