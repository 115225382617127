import { isEmpty } from "lodash";
import useAvailableCategories from "./useAvailableCategories";
import useCategoryFlag from "./useCategoryFlag";

const useHasRequiredCategory = ({ category = {} }) => {
  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory } = useCategoryFlag();
  const hasRequiredCategory =
    !isRequiredCategory ||
    (hasCategoryList && isRequiredCategory && !isEmpty(category ?? {}));
  return hasRequiredCategory;
};

export default useHasRequiredCategory;
