import React from "react";
import { Modal } from "reactstrap";
import { jackColors } from "../assets/colors";
import { JackIcons } from "../assets/jackIcons/parent";
import { useModalWidth } from "../components/Modals";
import { GothamMedium } from "../components/Text";

export const CenteredModal = ({
  hideModal,
  toggle,
  isOpen,
  children,
  style,
  scrollable,
  contentClassName = "",
  className,
  modalWidth: modalWidthProps,
}) => {
  const modalWidth = modalWidthProps || useModalWidth(528);
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className={`${className} my-0`}
      centered
      contentClassName={
        scrollable
          ? contentClassName
          : `modal-transfez ${
              hideModal ? "fade-out" : "fade-in"
            } ${contentClassName}`
      }
    >
      <div
        style={{
          backgroundColor: "white",
          width: modalWidth,
          borderRadius: 12,
          padding: 24,
          ...style,
        }}
      >
        {children}
      </div>
    </Modal>
  );
};

export const JackCenteredModal = ({
  isOpen,
  toggle,
  children,
  title = "Previous Recipient",
  modalWidth,
  contentClassName,
}) => {
  return (
    <CenteredModal
      isOpen={isOpen}
      toggle={toggle}
      modalWidth={modalWidth}
      contentClassName={contentClassName}
    >
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginBottom: 16 }}
      >
        <GothamMedium className="font20" style={{ lineHeight: "24px" }}>
          {title}
        </GothamMedium>
        <div
          style={{ padding: 10, cursor: "pointer" }}
          className="borderE6 hover-600"
          onClick={toggle}
        >
          <JackIcons
            name="close"
            fill={jackColors.neutral900}
            styleDiv={{ height: 20 }}
            style={{ width: 20, height: 20 }}
          />
        </div>
      </div>
      <hr style={{ margin: "0px -24px" }} />
      {children}
    </CenteredModal>
  );
};
