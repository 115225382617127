import { booleanState } from "../../../../components/Status";
import { getUserRole } from "../../../../contexts/AuthContext";

const canUpdateCategoryDecider = ({
  deps = { isDrafter: false, isApprover: false, state: "" },
}) => {
  const { state, isDrafter, isApprover } = deps ?? {};

  const { isEmployee } = getUserRole();

  const { isDraft, isComplete, isCompleted, isSuccess, isWaitingApprovalTask } =
    booleanState(state);

  const isSucceed = isComplete || isCompleted || isSuccess;

  if (isDraft) return false;

  if (isWaitingApprovalTask) {
    if (isApprover) return true;
    return false;
  }

  if (isSucceed) {
    if (isDrafter) return true;
    if (isEmployee) return false;
    return true;
  }

  if (isEmployee) return false;
  return true;
};

export default canUpdateCategoryDecider;
