import { upperCase } from "change-case";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { Avatar } from "../../components/Avatar";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { windowDimension } from "../../components/tools";

const IndividualUser = ({
  user,
  handleSelectRecipient,
  checkedFromParent,
  isMinimalistStyle,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checkedFromParent) return setChecked(true);
    setChecked(false);
  }, [checkedFromParent]);

  const handleClick = (user) => {
    setChecked((prev) => !prev);
    if (checked) return handleSelectRecipient(user, false);
    return handleSelectRecipient(user);
  };

  const { isTabOrPhone } = windowDimension();

  const { name = "", bank_name = "", account_number = "" } = user || {};

  return (
    <HoveredDiv
      className="hover-600"
      onClick={() => handleClick(user)}
      style={{ backgroundColor: checked && jackColors.neutral400 }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!isTabOrPhone && (
          <div>
            <JackIcons
              name={checked ? "checkmark-square-2" : "square"}
              fill={checked ? jackColors.neutral900 : jackColors.neutral600}
              style={{ width: 20, height: 20, margin: "0px 10px" }}
            />
            {/* <img src={checked ? checkedIcon : uncheckedIcon} /> */}
          </div>
        )}
        <Avatar name={name} size="medium" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 16,
          }}
        >
          <GothamMedium style={{ marginBottom: 2 }}>{name}</GothamMedium>
          {isMinimalistStyle ? (
            <div className="d-flex">
              <GothamRegular
                style={{ fontSize: 12, color: jackColors.neutral700 }}
              >
                {upperCase(bank_name)} - {account_number}
              </GothamRegular>
            </div>
          ) : (
            <>
              <GothamRegular>{upperCase(bank_name)}</GothamRegular>
              <GothamRegular>{account_number}</GothamRegular>
            </>
          )}
        </div>
      </div>
    </HoveredDiv>
  );
};

const HoveredDiv = styled.div`
  padding: 12px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  position: relative;
  /* right: 24px; */
  border-bottom: 1px solid #e6e6e8;
`;

export default IndividualUser;
