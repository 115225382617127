import { useRouter } from "next/router";
import React, { useState } from "react";
import { colors } from "../assets/colors";
import { ProgressBarJack } from "../components/Stepper";
import { GothamMedium, GothamRegular } from "../components/Text";

export const ChooseTransaction = ({
  item: { title, msg, disabled, img },
  index,
  toggle,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { push } = useRouter();
  const createBatch = index == 1;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="hover"
      onClick={onClick}
      style={{
        width: 320,
        height: 300,
        border: `1px solid ${colors.greyea}`,
        borderRadius: 16,
        backgroundColor: disabled ? "#f8f8f8" : "",
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 24,
      }}
    >
      <div
        style={{
          backgroundColor:
            disabled || !isHovered ? "rgba(255,255,255,.5)" : "transparent",
          borderRadius: 16,
          width: 320,
          height: 284,
          position: "absolute",
        }}
      ></div>
      <div
        className="d-flex justify-content-center"
        style={{
          marginBottom: 36,
        }}
      >
        <img src={img} />
      </div>
      <GothamMedium
        style={{
          fontSize: 20,
          lineHeight: "28px",
          textAlign: "center",
        }}
      >
        {title}
      </GothamMedium>
      <GothamRegular
        style={{
          fontSize: 12,
          lineHeight: "18px",
          color: colors.grey72,
          textAlign: "center",
        }}
      >
        {msg}
      </GothamRegular>
    </div>
  );
};

export const ProgressBarComponent = ({ array, style }) => (
  <div
    style={{
      position: "absolute",
      left: "50%",
      top: "-70px",
      transform: "translate(-50%, -50%)",
      ...style,
    }}
  >
    <ProgressBarJack arrayStep={array} customLineLength={200} />
  </div>
);
