import { useState } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import CustomHoverInfo from "../../../../../components/CustomHoverInfo/CustomHoverInfo";
import { GothamRegular } from "../../../../../components/Text";
import { useTranslation } from "react-i18next";

const MissingDetailWarning = () => {
  const { t } = useTranslation("category/index");
  const [showHoverInfo, setShowHoverInfo] = useState(false);

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setShowHoverInfo(true)}
      onMouseLeave={() => setShowHoverInfo(false)}
    >
      <JackIcons name="alert-triangle" fill={jackColors.yellowFC} />
      <CustomHoverInfo
        show={showHoverInfo}
        rotateTrianglePoint="-90deg"
        translateTrianglePoint="46px 30px"
        style={{ width: "100px", translate: "-38px -68px" }}
      >
        <GothamRegular
          woFontColor
          className="font12"
          style={{ color: jackColors.neutral800, whiteSpace: "nowrap"  }}
        >
          {t("Missing Details")}
        </GothamRegular>
      </CustomHoverInfo>
    </div>
  );
};

export default MissingDetailWarning;
