import React from "react";
import { colors, jackColors } from "../assets/colors";
import radioActive from "../assets/images/radio-active.svg";
import radio from "../assets/images/radio.svg";
import pencil from "../assets/images/thin-pencil.svg";
import trash from "../assets/images/trash-teal-clean.svg";
import { JackIcons } from "../assets/jackIcons/parent";
import { ConfirmationModal } from "../components/Buttons";
import LoadingSpinner from "../components/Loading";
import { useModalHook } from "../components/Modals";
import { GothamMedium, GothamRegular } from "../components/Text";
import { Avatar } from "./Avatar";
import { ButtonJack } from "./ButtonsJack/parent";
import { ModalAction } from "../modals/smartUploadReceiptModal/modals";
import { useTranslation } from "react-i18next";

export const BankCard = ({
  isActive,
  item,
  style,
  onClick,
  onDelete,
  onEdit,
  isUploadReceipt,
  onChangeSource,
  handleAction,
  loading,
  woDelete,
}) => {
  const { t } = useTranslation("international/details");
  const { top, middle, bottom, id } = item;

  const defaultOnClick = (e, customFunction) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
    customFunction && customFunction(id);
  };

  const { isOpen: isButtonModalOpen, toggle: toggleButtonModal } =
    useModalHook();
  const { isOpen, toggle, close } = useModalHook();

  const arrayModalAction = [
    { title: t("Edit"), icon: "edit-outline", action: "edit" },
    ...(isActive
      ? []
      : [{ title: t("Delete"), icon: "delete", isRed: true, action: "delete" }]),
  ];

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          onClick={() => onClick && onClick(item)}
          className={`d-flex align-items-center justify-content-between ${
            isUploadReceipt ? "borderE6" : "hover-600"
          }`}
          style={{
            borderBottom:
              !isUploadReceipt && `1px solid ${jackColors.neutral500}`,
            cursor: "pointer",
            position: "relative",
            padding: "16px 8px",
            ...style,
          }}
        >
          <div className="d-flex align-items-center" style={{ gap: 16 }}>
            <Avatar name={middle} size="medium" style={{ maxWidth: 32 }} />
            <div>
              <GothamMedium>{middle}</GothamMedium>
              <GothamRegular
                className="font12"
                style={{ color: jackColors.neutral700, wordWrap: "break-word" }}
              >
                {top} - {bottom}
              </GothamRegular>
            </div>
          </div>
          <div>
            {isUploadReceipt && (
              <div
                className="borderE6 hover-600"
                style={{ padding: 6, cursor: "pointer" }}
                onClick={(e) => defaultOnClick(e, onChangeSource)}
              >
                <GothamRegular className="font12">{t("Change")}</GothamRegular>
              </div>
            )}
            <>
              {/* <img
                  src={pencil}
                  style={{ height: 20 }}
                  className="darkhover"
                  onClick={(e) => defaultOnClick(e, onEdit)}
                />
                {!woDelete && (
                  <img
                    onClick={(e) => defaultOnClick(e, toggleButtonModal)}
                    src={trash}
                    className="darkhover"
                    style={{ marginLeft: 8 }}
                  />
                )} */}
            </>
          </div>
        </div>
        {!isUploadReceipt && (
          <JackIcons
            style={{
              width: 20,
              height: 20,
              cursor: "pointer",
              position: "absolute",
              right: 0,
              top: 22,
            }}
            name="more-vertical"
            fill={jackColors.neutral900}
            onClick={toggle}
          />
        )}
        {isOpen && (
          <ModalAction
            isActive={isActive}
            close={close}
            array={arrayModalAction}
            onClick={handleAction}
            itemData={item}
          />
        )}
      </div>
      <ConfirmationModal
        toggle={toggleButtonModal}
        modal={isButtonModalOpen}
        onClick={() => {
          onDelete && onDelete(id);
        }}
      />
    </>
  );
};
