import { jackColors } from "../../../../../assets/colors";
import { GothamBold, GothamRegular } from "../../../../../components/Text";
import { getUserRole } from "../../../../../contexts/AuthContext";
import SimpleCenteredModal from "../../../../../modals/SimpleCenteredModal";
import styles from "./CategoryInfoModal.module.css";
import { categoryInfoTextDecider } from "../../helpers";
import NextImage from "../../../../../components/NextImage";

const CategoryInfoModal = ({ isOpen = false, toggle = () => {} }) => {
  const { isEmployee } = getUserRole();

  const text = (type) =>
    categoryInfoTextDecider({ type, isAdminRole: !isEmployee });

  const fontStyle = {
    woFontColor: false,
    style: { color: jackColors.neutral800 },
  };

  return (
    <SimpleCenteredModal
      isOpen={isOpen}
      toggle={toggle}
      buttonText="Got it!"
      onClick={toggle}
      modalClassName={styles["modal"]}
    >
      <div className={styles["content-container"]}>
        <div>
          <NextImage
            width={476}
            height={200}
            src="/images/category-onboard.png"
            alt="Category Info Image"
          />
          <GothamBold woFontSize className="font20">
            {text("title")}
          </GothamBold>
        </div>
        <div>
          <GothamRegular {...fontStyle}>{text("first")}</GothamRegular>
          <GothamRegular {...fontStyle}>{text("second")}</GothamRegular>
          <GothamRegular {...fontStyle}>{text("third")}</GothamRegular>
        </div>
      </div>
    </SimpleCenteredModal>
  );
};

export default CategoryInfoModal;
