import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";

const CheckboxSquare = ({
  isChecked = false,
  onClick = () => {},
  isDisabled = false,
}) => {
  const name = isChecked ? "checkbox-square-filled" : "checkbox-square-empty";
  const fill =
    jackColors[
      isChecked ? (isDisabled ? "neutral600" : "neutral900") : "neutral600"
    ];

  return (
    <JackIcons
      isStopBubbling
      name={name}
      fill={fill}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    />
  );
};

export default CheckboxSquare;
