import { JackIcons } from "../../../../assets/jackIcons/parent";
import styles from "./styles.module.css";

const JackTransferIcon = ({ name = "swap-outline", style = {} }) => {
  return (
    <div className={styles.jackTransferIcon} style={style}>
      <JackIcons name={name} fill="white" style={{ scale: "0.8" }} />
    </div>
  );
};

export default JackTransferIcon;
