import UpdateTracker from "../../../components/UpdateTracker/parent";
import {
  ReasonNotes,
  StatusPayrollLine,
} from "../../../pageComponents/payrollCreateComponents/loop/success/status";
import { TransactionStatusFlow } from "../../../pageComponents/transactionDetailsComponents";
import { useSchedulePaymentTransaction } from "../invoicePayment/hooks/schedulePayment";
import { useCrossBorderSingleWorkflow } from "./hooks/workflow";

export const CrossBorderSingleStatusLine = ({
  transaction,
  isModal,
  additionalUpdates = [],
  isOverrideLastUpdate = false,
}) => {
  const {
    firstLineObj,
    completedLineObj,
    cancelledLineObj,
    approverLineArray,
    isWorkflow,
    isCancelled,
    isValidating,
    isCompleted,
    isRefunded,
    isAwaitingRefundStrict,
    validatingObj,
    rejection_note,
    partialFailedObj,
    isPartialFailed,
    isOnProgress,
    isFailed,
    failedObj,
    isRejected,
  } = useCrossBorderSingleWorkflow(transaction);
  const { invoice_file: isInvoice, local_recipient } = transaction || {};

  const { name } = local_recipient || {};
  // Transferring money to recipients
  const nameDecider = () => {
    if (name && name != "-") return name;
    return "recipients";
  };

  const { isScheduledTransaction, isScheduled, scheduledLineArray, queuedObj } =
    useSchedulePaymentTransaction(transaction, approverLineArray);

  if (isWorkflow) {
    const arrayDecider = () => {
      if (isRefunded)
        return [
          firstLineObj,
          cancelledLineObj,
          {
            isActive: true,
            text: isAwaitingRefundStrict
              ? "Your refund request has been completed, you should have the funds in your designated account."
              : "Sit tight. The refund process has been initiated. It may take up to 2x24 hours for your funds to be returned",
          },
        ];
      if (isCompleted)
        return [firstLineObj, ...approverLineArray, completedLineObj];
      if (isValidating)
        return [
          firstLineObj,
          ...approverLineArray,
          validatingObj,
          completedLineObj,
        ];

      if (isCancelled) return [firstLineObj, cancelledLineObj];

      if (isPartialFailed)
        return [firstLineObj, ...approverLineArray, partialFailedObj];

      if (isFailed) return [firstLineObj, ...approverLineArray, failedObj];

      if (isRejected) {
        return [firstLineObj, ...approverLineArray];
      }

      return [
        firstLineObj,
        ...approverLineArray,
        isInvoice
          ? { ...validatingObj, text: `Transferring money to ${nameDecider()}` }
          : validatingObj,
        completedLineObj,
      ];
    };

    const schedulePaymentArray = () => {
      if (isScheduled) return [firstLineObj, ...scheduledLineArray, queuedObj];
      if (isCompleted)
        return [firstLineObj, ...scheduledLineArray, completedLineObj];
      if (isOnProgress)
        return [
          firstLineObj,
          ...scheduledLineArray,
          {
            ...validatingObj,
            text: `Transferring money to ${nameDecider()}`,
          },
        ];
      return [firstLineObj, ...scheduledLineArray];
    };

    //if scheduledtransaction return schedule payment line array
    const updates = isScheduledTransaction
      ? schedulePaymentArray()
      : arrayDecider();

    const inactiveUpdatesCount = (updates ?? []).filter(
      ({ isActive }) => isActive
    ).length;

    const updatesToUse = isOverrideLastUpdate
      ? updates.slice(0, updates.length - inactiveUpdatesCount)
      : updates;

    return (
      <div style={{ marginTop: 12 }}>
        {/* <StatusPayrollLine array={arrayDecider()} isModal={isModal} /> */}
        <UpdateTracker
          updates={[...updatesToUse, ...additionalUpdates]}
          rejectionNote={rejection_note}
        />
      </div>
    );
  }
  return (
    <TransactionStatusFlow item={transaction} status={transaction?.state} />
  );
};
