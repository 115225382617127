import { ToasterContext } from "contexts/ToasterContext";
import _, { isEmpty } from "lodash";
import { SpanMedium } from "modals/smartActivityModal/crossBorderSingleModal/transferServiceModal/components";
import { useUploadReceiptScreenType } from "modals/smartUploadReceiptModal/logics";
import { useRouter } from "next/router";
import CategorySelection from "pageComponents/categoryComponents/general/components/CategorySelection";
import { useCategoryFlag } from "pageComponents/categoryComponents/general/hooks";
import { Fragment, useContext, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Banner } from "../../../../components/Banner";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { MinimalistTextField } from "../../../../components/inputs";
import { useModalHook } from "../../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import {
  formatCurrencyNoDecimal,
  windowDimension,
} from "../../../../components/tools";
import imgDraft from "../../../../public/images/draft-transaction.svg";
import { useClickOutside } from "../../../../universalFunctions/useClickOutside";
import { pluralize } from "../../../invoiceComponents/data/formatter";
import { ValidationValueContainWhiteSpace } from "../../../payrollCreateComponents/logics";
import { isThereIncompleteData } from "../../../PayrollCreateEnhancement/create/logic";
import { useBooleanTableLocal } from "./logic";

export const MainTitleLocalTable = ({
  data,
  title,
  onClick,
  loading,
  isPayroll = false,
  onChangeTitle,
  isScreenIncomplete,
  isScreenDuplicate,
  dataIncompleteLength,
  dataDuplicateLength,
  isErrorCategory,
  category,
  handleChangeCategory,
  isOpenEditTitleAndCategory,
  closeEditTitleAndCategory,
  toggleEditTitleAndCategory,
  categoryStore = {},
  setIsErrorCategory = () => {},
}) => {
  const { pathname } = useRouter();
  const isLocalTransaction =
    pathname.includes("/local-transfer/create") ||
    pathname.includes("/local-transfer/[id]");

  const { isOpen: isOpenSaveDraft, toggle: toggleSaveDraft } = useModalHook();
  const { t } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const tDecider = isLocalTransaction ? tLocalTransfer : t;
  const tData = {
    trxNum: dataIncompleteLength,
    trx: pluralize(dataIncompleteLength, "transaction"),
  };
  const tKeyEdgeCondition = !isLocalTransaction
    ? t("dynamic.dynamic_text_2", tData)
    : tLocalTransfer("dynamic.dynamic_text_1", tData);

  const {
    isOpen: isOpenEditTitle,
    toggle: toggleEditTitle,
    close: closeEditTitle,
  } = useModalHook();
  const { width } = windowDimension();

  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginBottom: 24 }}
      >
        {isLocalTransaction ? (
          <>
            <div
              className="d-flex align-items-center"
              style={{ position: "relative", marginTop: 32 }}
            >
              <GothamMedium
                className="font20"
                style={{ color: jackColors.neutral900 }}
              >
                {title}
              </GothamMedium>
              <div style={{ marginLeft: 12 }}>
                <CategorySelection
                  isSimple
                  isError={isErrorCategory}
                  selectedCategory={category}
                  onSelect={handleChangeCategory}
                  withRightIcon={false}
                  containedCategoryIconStyle={{
                    backgroundColor: "white",
                    border: `1px solid white`,
                    scale: "1",
                  }}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  isRounded
                />
              </div>
              <JackIcons
                onClick={toggleEditTitleAndCategory}
                name="edit-outline"
                fill={jackColors.neutral900}
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: 16,
                  cursor: "pointer",
                }}
              />
              {isOpenEditTitleAndCategory && (
                <ModalEditTitle
                  close={closeEditTitleAndCategory}
                  containerStyle={{ width: 363 }}
                  value={title}
                  onSubmit={onChangeTitle}
                  onClick={onClick}
                  model="with_category"
                  width={width}
                  handleChangeCategory={handleChangeCategory}
                  isErrorCategory={isErrorCategory}
                  selectedCategory={category}
                  categoryStore={categoryStore}
                  setIsErrorCategory={setIsErrorCategory}
                />
              )}
            </div>
            <ButtonJack
              isLoading={loading}
              onClick={toggleSaveDraft}
              type="outline"
              leftIcon={
                <JackIcons
                  name="inbox-outline"
                  style={{ width: 20, height: 20 }}
                  fill={loading ? jackColors.neutral500 : jackColors.neutral900}
                />
              }
              style={{ height: 32, padding: 6 }}
            >
              {tLocalTransfer("Move to Draft")}
            </ButtonJack>
          </>
        ) : (
          <>
            <div
              className="d-flex align-items-center"
              style={{ position: "relative" }}
            >
              <GothamMedium
                className="font20"
                style={{ color: jackColors.neutral900 }}
              >
                {title}
              </GothamMedium>
              <JackIcons
                onClick={toggleEditTitle}
                name="edit-outline"
                fill={jackColors.neutral900}
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: 16,
                  cursor: "pointer",
                }}
              />
              {isOpenEditTitle && (
                <ModalEditTitle
                  close={closeEditTitle}
                  containerStyle={{ width: 363 }}
                  value={title}
                  onSubmit={onChangeTitle}
                />
              )}
            </div>
            <ButtonJack
              isLoading={loading}
              onClick={toggleSaveDraft}
              type="outline"
              leftIcon={
                <JackIcons
                  name="inbox-outline"
                  style={{ width: 20, height: 20 }}
                  fill={loading ? jackColors.neutral500 : jackColors.neutral900}
                />
              }
              style={{ height: 32, padding: 6 }}
            >
              {tLocalTransfer("Move to Draft")}
            </ButtonJack>
          </>
        )}
      </div>
      {isScreenIncomplete && (
        <div style={{ marginBottom: 24 }}>
          <Banner
            type="error"
            title={<Trans i18nKey={tKeyEdgeCondition} />}
            titleStyle={{ fontSize: 14, lineHeight: "20px" }}
            iconStyle={{ marginTop: -14 }}
            msg={
              <div>
                <Trans
                  i18nKey={t("dynamic.style_text_1")}
                  components={[<span style={{ fontFamily: "GothamMedium" }} />]}
                />
              </div>
            }
          />
        </div>
      )}
      {isScreenDuplicate && (
        <div style={{ marginBottom: 24 }}>
          <Banner
            type="warning"
            title={
              <Trans
                i18nKey={tDecider("dynamic.dynamic_text_3", {
                  dupNum: dataDuplicateLength,
                })}
              />
            }
            titleStyle={{ fontSize: 14, lineHeight: "20px" }}
            iconStyle={{ marginTop: -14 }}
            msg={
              <Trans
                i18nKey={tDecider("dynamic.dynamic_text_4", {
                  dupNum: dataDuplicateLength,
                })}
              />
            }
          />
        </div>
      )}
      <ConfirmationModalJack
        onClick={() => onClick(isPayroll ? { type: "draft" } : "draft")}
        modal={isOpenSaveDraft}
        toggle={toggleSaveDraft}
        title={tDecider("Move this request to draft?")}
        text={
          isLocalTransaction ? (
            <>
              <Trans
                i18nKey={tLocalTransfer("dynamic.style_text_10")}
                components={{
                  SpanMedium: <SpanMedium />,
                }}
              />
            </>
          ) : (
            <>
              <Trans
                i18nKey={t("dynamic.style_text_2")}
                components={[<span style={{ fontFamily: "GothamMedium" }} />]}
              />
            </>
          )
        }
        buttonTextLeft={t("Back")}
        buttonTextRight={t("Move to Draft")}
        img={imgDraft}
        customContentClassName="modal-border-radius4"
        isCenteredTitle
      />
    </div>
  );
};

export const FloatingButtonLocal = ({
  data,
  array,
  funcPropsTable,
  isConfirmationPage,
  handleSubmit = () => {},
  loading,
  showDeleteRow,
  handleClick = () => {},
  type = "",
}) => {
  const { selectedAmount, handleUncheckedAll, handleRemoveAllId } =
    funcPropsTable;
  const UncheckedAll = handleUncheckedAll || handleRemoveAllId;
  const {
    totalTrx,
    totalAmount: totalAmountRaw,
    totalFee: totalFeeRaw,
  } = data || {};
  const totalAmount = formatCurrencyNoDecimal(totalAmountRaw);
  const totalFee = formatCurrencyNoDecimal(totalFeeRaw || 0);
  const amountPlusFee = formatCurrencyNoDecimal(
    Number(totalAmountRaw || 0) + Number(totalFeeRaw || 0)
  );

  const isStillThereIncomplete = isThereIncompleteData({ data: array });
  const wordingButton = isConfirmationPage ? "Submit" : "Validate";
  const hideBigWhiteBar = showDeleteRow;
  const { t } = useTranslation("local-transfer/local-transfer");

  const dataRendered = [
    {
      title: t("Number of Transaction"),
      desc: `${totalTrx} ${t(pluralize(totalTrx, "Transaction"))}`,
    },
    ...(isConfirmationPage
      ? [
          {
            title: "Total Fee",
            desc: `IDR ${formatCurrencyNoDecimal(
              totalFeeRaw / totalTrx
            )} x ${totalTrx} = IDR ${totalFee}`,
          },
        ]
      : []),
    {
      title: t("Total Amount"),
      desc: `IDR ${totalAmount}`,
    },
  ];

  if (type == "collapse_button") {
    return (
      <Fragment>
        <div
          onClick={handleClick}
          className="d-flex justify-content-center"
          style={{
            transform: "translate(-50%, -50%)",
            position: "fixed",
            left: "50%",
            bottom: 110,
            boxShadow:
              "0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16)",
            cursor: "pointer",
            backgroundColor: "white",
          }}
        >
          <ButtonJack
            type="outline"
            rightIcon={
              <JackIcons
                name="ChevronUp"
                fill={jackColors.neutral900}
                style={{ width: 20, height: 20, marginLeft: 8 }}
              />
            }
          >
            {t("See Less")}
          </ButtonJack>
        </div>
      </Fragment>
    );
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: -10,
          left: "50%",
          transform: "translate(-50%, -50%)",
          // zIndex: 1,
          // margin: "10px 30px",
          width: 900,
          backgroundColor: jackColors.neutral900,
          boxShadow:
            "0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16)",
          borderRadius: 8,
          padding: selectedAmount ? "20px 24px" : "16px 24px",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          {selectedAmount ? (
            <GothamMedium
              className="font16"
              style={{ color: jackColors.neutral100 }}
            >
              {selectedAmount} {t(pluralize(selectedAmount, "transaction"))}{" "}
              {t("selected")}
            </GothamMedium>
          ) : (
            <div className="d-flex">
              {dataRendered?.map(({ title, desc }, index) => {
                const isLast = index == dataRendered.length - 1;
                return (
                  <div key={index} className="d-flex">
                    <div>
                      <GothamRegular
                        className="font12"
                        style={{
                          color: jackColors.neutral100,
                          marginBottom: 8,
                        }}
                      >
                        {title}
                      </GothamRegular>
                      <GothamMedium
                        className="font16"
                        style={{ color: jackColors.neutral100 }}
                      >
                        {desc}
                      </GothamMedium>
                    </div>
                    {!isLast && (
                      <div
                        style={{
                          borderLeft: `1px solid ${colors.greye6}`,
                          margin: "0px 20px",
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <div className="d-flex" style={{ gap: 12 }}>
            <ButtonJack
              type="outline-transparent"
              children={selectedAmount ? t("Cancel") : t("Back")}
              style={{ minWidth: 65 }}
              onClick={() =>
                selectedAmount ? UncheckedAll() : handleSubmit("back_modal")
              }
              leftIcon={
                !selectedAmount && (
                  <JackIcons
                    name="arrow-back-outline"
                    fill="white"
                    style={{ width: 20, height: 20 }}
                  />
                )
              }
            />
            {selectedAmount ? (
              <ButtonJack
                children={t("Delete selected")}
                onClick={() => handleSubmit("open_modal_delete")}
              />
            ) : (
              <ButtonJack
                isLoading={loading}
                // disabled={isStillThereIncomplete}
                children={t("Validate")}
                style={{ minWidth: 60 }}
                onClick={() => handleSubmit("validate")}
                rightIcon={
                  <JackIcons
                    style={{ width: 20, height: 20 }}
                    name="arrow-forward"
                    // fill={
                    //   isStillThereIncomplete ? jackColors.neutral600 : "black"
                    // }
                    fill="black"
                  />
                }
              />
            )}
            {/* <OutlineButton
              style={{ height: 40, padding: "0px 12px" }}
              onClick={() => handleSubmit("draft")}
              isLoading={loading}
              disabled={loading}
            >
              Save as Draft
            </OutlineButton>
            <CustomButton
              style={{ height: 40, padding: "0px 12px" }}
              disabledGrey={isStillThereIncomplete}
              onClick={() => handleSubmit("validate")}
              isLoading={loading}
            >
              {wordingButton} <img src={caretRightIcon} />
            </CustomButton> */}
          </div>
        </div>
      </div>
      {/* <div
        className="d-flex"
        style={{
          position: "absolute",
          backgroundColor: "#DA649F",
          boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
          borderRadius: 32,
          gap: 8,
          padding: "8px 12px",
          bottom: bottomDeciderOtherFields(),
          left: "45%",
          zIndex: 9,
          cursor: "pointer",
        }}
        onClick={() => onClickOtherFields((p) => !p)}
      >
        {!isFirstScreenTable && <img src={ImgIcon} />}
        <GothamRegular className="font12" style={{ color: "white" }}>
          Other fields
        </GothamRegular>
        {isFirstScreenTable && <img src={ImgIcon} />}
      </div> */}
    </>
  );
};

export const PlaceholderLocalTable = (name, tLocalTransfer = () => {}) => {
  const { isName, isEmail, isAmount, isAccNumber, isBank, isNotes } =
    useBooleanTableLocal({
      name,
    });

  const placeholderDecider = () => {
    if (isName) return "Enter recipient name";
    if (isBank) return "Select bank";
    if (isAccNumber) return "Enter account number";
    if (isNotes) return "Enter transfer note";
    if (isEmail) return "Enter email";
    if (isAmount) return "IDR 0";
    return "-";
  };

  const placeholder = placeholderDecider();
  return (
    <span style={{ color: jackColors.neutral600 }}>
      {tLocalTransfer(placeholder)}
    </span>
  );
};

export const ModalEditTitle = ({
  close,
  value,
  containerStyle,
  onSubmit,
  maxLength = 50,
  model = "",
  width = 265,
  handleChangeCategory = () => {},
  selectedCategory = {},
  categoryStore = {},
  isErrorCategory = false,
  setIsErrorCategory = () => {},
  onClick = () => {},
}) => {
  const [tempValue, setTempValue] = useState(value || "");
  const [tempValueCategory, setTempValueCategory] = useState(
    selectedCategory || ""
  );
  const [showError, setShowError] = useState(false);
  const { successSnackBar } = useContext(ToasterContext);
  const {
    isOpen: isOpenCategoryModalEdit,
    open: openCategoryModalEdit,
    close: closeCategoryModalEdit,
  } = useModalHook();

  const { t } = useTranslation("payroll/create");
  const { isLocalTransfer } = useUploadReceiptScreenType();

  const ref = useRef(null);
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  if (model !== "with_category") {
    useClickOutside({ ref, clickOutside: () => setTimeout(close, 20) });
  }

  const { isDeactivatedCategory, isRequiredCategory } = useCategoryFlag();
  const { isDisabled, isDisabledCategory } = ValidationValueContainWhiteSpace(
    tempValue,
    tempValueCategory,
    isRequiredCategory
  );
  const customIsDisabled =
    value == tempValue && _.isEqual(categoryStore, tempValueCategory);

  const errorCondition =
    isDisabled ||
    (isLocalTransfer &&
      (isErrorCategory ||
        isDisabledCategory ||
        (isRequiredCategory ? isEmpty(tempValueCategory) : false)));
  const isMoreThanMaxLength = tempValue?.length > maxLength;
  const isError = showError && errorCondition;

  const errorGenerator = ({ value }) => {
    if (!value) return t("Please fill out this field");
    if (value?.length > maxLength) return `Max. ${maxLength} character reached`;
  };
  const errorMsg = errorGenerator({ value: tempValue });

  const handleOnChange = (value) => {
    const { valueValidation, isDisabled } =
      ValidationValueContainWhiteSpace(value);
    if (valueValidation.length > maxLength) return;
    if (!!valueValidation && !isDisabled) setShowError(false);
    if (isDisabled) setShowError(true);
    setTempValue(valueValidation);
  };

  const handleCancel = () => setTimeout(close, 20);
  const handleSubmit = () => {
    isLocalTransfer && closeCategoryModalEdit();
    if (errorCondition || isMoreThanMaxLength) {
      if (isLocalTransfer) {
        !tempValue && setShowError(true);
        if (isRequiredCategory && isEmpty(tempValueCategory)) {
          setIsErrorCategory(true);
        }
        errorGenerator({ value: tempValue });
      }
      return;
    }
    if (customIsDisabled) return;
    onSubmit(tempValue, selectedCategory);
    value &&
      successSnackBar({
        msg: tLocalTransfer("Details have been saved!"),
        showClose: true,
      });
    setTimeout(close, 20);
  };

  const handlePressTextField = () => {
    if (isDisabled || isMoreThanMaxLength) {
      if (isLocalTransfer) {
        !tempValue && setShowError(true);
        errorGenerator({ value: tempValue });
      }
      return;
    }
    if (isEmpty(tempValueCategory)) {
      openCategoryModalEdit();
    } else {
      !customIsDisabled && handleSubmit();
    }
  };

  const props = {
    isOpenCategoryModalEdit,
    openCategoryModalEdit,
    closeCategoryModalEdit,
  };

  if (!!model) {
    switch (model) {
      case "with_category":
        return (
          <div
            ref={ref}
            style={{
              position: "absolute",
              backgroundColor: "white",
              top: -17,
              left: -2,
              minWidth: isDeactivatedCategory ? 960 : width * 0.96,
              padding: "24px 16px",
              boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
              borderRadius: 4,
              zIndex: 10,
              gap: 16,
              justifyContent: "space-evenly",
              // ...containerStyle,
            }}
            className="d-flex align-items-center"
          >
            <MinimalistTextField
              label={""}
              error={isError}
              errorMsg={errorMsg}
              name="batch_name"
              // required={isRequired && "*"}
              value={tempValue}
              setValue={handleOnChange}
              onSubmit={
                !isDeactivatedCategory ? handlePressTextField : handleSubmit
              }
              autoFocus
              maxLength={50}
              placeholder={tLocalTransfer("Enter batch name")}
              t={tLocalTransfer}
              inputPropsStyle={{
                fontFamily: "GothamMedium",
                fontSize: 20,
                lineHeight: 24,
              }}
              showHelperBeneathTextInputStyle={{ right: 0 }}
            />
            <CategorySelection
              model={"category_modal_edit"}
              isError={isErrorCategory}
              selectedCategory={selectedCategory}
              onSelect={(value) => {
                setTempValueCategory(value);
                handleChangeCategory(value);
              }}
              style={{ width: "100%" }}
              {...props}
            />

            <ButtonJack
              onClick={() => {
                if (!value) return onClick("back_modal");
                handleChangeCategory(tempValueCategory);
                close();
              }}
              style={{ height: 32, padding: 6 }}
              type="outline"
            >
              {!value ? tLocalTransfer("Back") : tLocalTransfer(" Cancel")}
            </ButtonJack>

            <ButtonJack
              disabled={customIsDisabled}
              onClick={() => {
                handleSubmit(tempValue, tempValueCategory);
              }}
              style={{ height: 32, padding: 6 }}
            >
              {tLocalTransfer("Save")}
            </ButtonJack>
          </div>
        );
        break;

      default:
        break;
    }
  }

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        backgroundColor: "white",
        top: -17,
        left: -2,
        minWidth: 265,
        padding: showError ? "18px 8px 26px 8px" : "18px 8px",
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
        borderRadius: 4,
        zIndex: 10,
        ...containerStyle,
      }}
      className="d-flex align-items-center"
    >
      <MinimalistTextField
        label={""}
        error={isError}
        errorMsg={errorMsg}
        // name={name}
        // required={isRequired && "*"}
        value={tempValue}
        setValue={handleOnChange}
        onSubmit={handleSubmit}
        style={{ width: 272 }}
        autoFocus={true}
      />
      <div className="d-flex ml-2" style={{ gap: 8 }}>
        <JackIcons
          name="checkmark-circle-2"
          fill={jackColors.greenB9}
          style={{ cursor: "pointer" }}
          onClick={handleSubmit}
        />
        <JackIcons
          name="close-circle"
          fill={jackColors.neutral900}
          style={{ cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};

export const AdditionalButtonTable = ({
  handleAddRow,
  handleSeeMoreLess,
  isSeeMore,
}) => {
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const { pathname } = useRouter();
  const isShowButtonMore =
    pathname.includes("/local-transfer/") &&
    pathname.includes("state=validation");

  const [isHover, setIsHover] = useState(false);

  const titleButtonDecider = () => {
    if (isShowButtonMore) return tLocalTransfer("See More");
    return tLocalTransfer("Add Empty Row");
  };

  const contentColor = () =>
    isShowButtonMore ? jackColors.black34 : jackColors.grey90;

  const bgColorDecider = () => {
    if (isHover) return jackColors.neutral500;
    return "white";
  };

  const handleClick = () => {
    isShowButtonMore ? handleSeeMoreLess() : handleAddRow();
  };

  if (isSeeMore) return <></>;

  return (
    <div
      onClick={handleClick}
      style={{
        position: "absolute",
        width: "100.15%",
        marginLeft: -9,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        borderLeft: `1px solid ${jackColors.neutral500}`,
        borderRight: `1px solid ${jackColors.neutral500}`,
        borderBottom: `1px solid ${jackColors.neutral500}`,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        backgroundColor: "white",
        height: 52,
      }}
    >
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
          backgroundColor: bgColorDecider(),
          width: "99%",
          borderTop: `1px solid ${jackColors.neutral500}`,
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {Boolean(!isShowButtonMore) && (
          <JackIcons
            name="plus-outline"
            fill={jackColors.grey90}
            style={{ width: 20, height: 20, marginRight: 8 }}
          />
        )}
        <GothamMedium
          style={{
            color: contentColor(),
          }}
        >
          {titleButtonDecider()}
        </GothamMedium>
        {Boolean(isShowButtonMore) && (
          <JackIcons
            name="ChevronDown"
            fill={contentColor()}
            style={{ width: 20, height: 20, marginLeft: 8 }}
          />
        )}
      </div>
    </div>
  );
};
