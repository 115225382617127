import { useRouter } from "next/router";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { LOCAL, PAYROLL, BILL, REIMBURSEMENT } from "../constants";
import { useLanguage } from "public/locales/translationFunctions";

const remindAfterSuccessDecider = ({
  isRetry,
  isResubmit,
  drafter,
  releaser,
  remindTiming,
  successSnackBar,
}) => {
  const { isBahasa } = useLanguage();

  const drafterName = drafter?.name || drafter?.email || "-";
  const releaserName = releaser?.name || releaser?.email || "-";

  if (isRetry) {
    return () => {
      remindTiming.enableRemindTiming();
      successSnackBar({
        msg: isBahasa
          ? "Notifikasi pengingat telah dikirim!"
          : `We've sent a reminder email to ${releaserName}`,
      });
    };
  }

  if (isResubmit) {
    return () => {
      remindTiming.enableRemindTiming();
      successSnackBar({
        msg: isBahasa
          ? "Notifikasi pengingat telah dikirim!"
          : `We've sent a reminder email to ${drafterName}`,
      });
    };
  }
};
const retryAfterSuccessDecider = ({ trx, remindTiming, push }) => {
  const defaultQuery = { type: "", id: trx?.id };

  switch (trx?.type) {
    case LOCAL:
      defaultQuery.type = "local_transfer";
      break;
    case PAYROLL:
      defaultQuery.type = "payroll";
      break;
    case BILL:
      defaultQuery.type = "invoice";
      break;
    case REIMBURSEMENT:
      defaultQuery.btcid = trx?.id;
      defaultQuery.type = "reimbursement";
      break;

    default:
      break;
  }

  return () => {
    remindTiming.disableRemindTiming();
    push({ pathname: "/success", query: defaultQuery });
  };
};

const useRetryResubmitButtonAfterSuccess = ({
  trx,
  drafter,
  releaser,
  isRetry,
  isResubmit,
  remindTiming,
}) => {
  const { push } = useRouter();
  const { successSnackBar } = ToasterHook();
  return {
    retryAfterSuccessDecider: () =>
      retryAfterSuccessDecider({ trx, push, remindTiming }),
    remindAfterSuccessDecider: () =>
      remindAfterSuccessDecider({
        isRetry,
        isResubmit,
        drafter,
        releaser,
        remindTiming,
        successSnackBar,
      }),
  };
};

export default useRetryResubmitButtonAfterSuccess;
