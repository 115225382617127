import { useRouter } from "next/router";
import { jackColors } from "../../../../../../../assets/colors";
import { CheckBox } from "../../../../../../../components/Checkbox";
import { GothamRegular } from "../../../../../../../components/Text";
import { usePushQuery } from "../../../../../../../components/tools";
import { useTranslation } from "react-i18next";

export const TransactionFiltersRadioComponent = ({
  array,
  name,
  style,
  onClick,
  currValue,
}) => {
  const { query } = useRouter();
  const { pushQuery } = usePushQuery();
  const { t } = useTranslation("draft/draft");
  return array.map((item, index) => {
    const { label, value, desc } = item;
    const isActive = value == (currValue || query[name] || "");
    const isFirst = index == 0;
    return (
      <div
        key={index}
        className="d-flex align-items-center hover"
        style={{
          padding: 8,
          paddingTop: 4,
          paddingBottom: 4,
          marginTop: isFirst ? 0 : 4,
          gap: 8,
          ...style,
        }}
        onClick={() => {
          if (onClick) return onClick(value);
          pushQuery(name, value);
        }}
      >
        <CheckBox type="circle" isActive={isActive} />
        <div>
          <GothamRegular
            className="font12 mt-1"
            style={{ color: jackColors.grey6C }}
          >
            {t(label)}
          </GothamRegular>
          {!!desc && (
            <GothamRegular
              className="font12"
              style={{ marginTop: 2, color: jackColors.grey90 }}
            >
              {desc}
            </GothamRegular>
          )}
        </div>
      </div>
    );
  });
};
