import { useMemo } from "react";
import useAvailableCategories from "./useAvailableCategories";

const useSearchedCategories = ({
  showAllCategories = false,
  search = "",
  dependencies = [],
}) => {
  const { categories } = useAvailableCategories({ showAllCategories });

  return useMemo(() => {
    let allCategories = [...categories];
    const recentCategories = [];
    const otherCategories = [];

    if (search) {
      allCategories = categories.filter(({ name }) =>
        name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );
    }

    allCategories.forEach((category) =>
      category.isRecent
        ? recentCategories.push(category)
        : otherCategories.push(category)
    );

    return { allCategories, recentCategories, otherCategories };
  }, [search, categories, showAllCategories, ...dependencies]);
};

export default useSearchedCategories;
