import { Fragment, useEffect, useState } from "react";
import { jackColors } from "../../assets/colors";
import { GothamMedium, GothamRegular } from "../Text";

// PROPS SAMPLE
// array = [
//   { title: "Selected Transaction(s)", msg: "10 Transaction(s)" },
//   { title: "Selected Transaction(s)", msg: "10 Transaction(s)" },
// ],
// buttons = [<div style={{ backgroundColor: "red" }}>hoi</div>],
// PROPS SAMPLE

export const SummarySheet = ({ array = [], buttons = [], wSidebar }) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const cleanArr = array.filter((item) => item);

  useEffect(() => setTimeout(() => setIsFirstRender(false), 200), []);

  const height = 80;

  return (
    <div
      style={{
        position: "fixed",
        bottom: isFirstRender ? -height - 24 : 24,
        transition: "all .4s linear",
        marginLeft: `calc((100% - ${wSidebar ? 1376 : 900}px) / 2)`,
        backgroundColor: jackColors.black34,
        borderRadius: 8,
        width: 900,
        height,
        padding: 24,
        paddingTop: 16,
        paddingBottom: 16,
        zIndex: 4,
      }}
      className="d-flex justify-content-between"
    >
      <div className="d-flex">
        {cleanArr.map((item, index) => {
          const { msg, title } = item;

          const isOnlyOneCondition = cleanArr.length === 1;
          const isFirst = index == 0;
          const isLast = index == array.length - 1;
          return (
            <div
              key={index}
              className="d-flex justify-content-between"
              style={{
                marginLeft: isFirst ? 0 : 20,
                flexDirection: "column",
                paddingRight: isLast ? 0 : 20,
                borderRight:
                  isLast || isOnlyOneCondition ? "" : "1px solid white",
              }}
            >
              <GothamRegular style={{ color: "white" }} className="font12">
                {title}
              </GothamRegular>
              <GothamMedium style={{ color: "white" }} className="font16">
                {msg}
              </GothamMedium>
            </div>
          );
        })}
      </div>
      <div className="d-flex align-items-center" style={{ gap: 12 }}>
        {buttons.map((button, index) => (
          <Fragment key={index}>{button}</Fragment>
        ))}
      </div>
    </div>
  );
};
