import React, { useEffect, useState } from "react";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { BankCard } from "../../components/bankCards";
import { CustomButton, OutlineButton } from "../../components/Buttons";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import {
  RightModal,
  useSmoothRightModal,
} from "../../components/Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { arrayToObjByID } from "../../components/tools";
import { ToasterHook } from "../../contexts/ToasterContext";
import { WrapperModalButtons } from "../../pageComponents/homeComponents/topup/components";
import { useMutation } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";
import { BankAccountFormModal, useAddBank } from "./bankAccountFormModal";
import { banksFormatter } from "./finalUploadReceiptModal";
import { useTranslation } from "react-i18next";

export const BankSelectionModal = ({
  toggle,
  isOpen,
  data,
  setData,
  isClose = true,
  onSubmit,
  selectedId,
}) => {
  const { t } = useTranslation("international/details");
  const [middleHeight, setMiddleHeight] = useState(0);
  const [activeId, setActiveId] = useState(selectedId);
  const { toToggle, toListener } = useSmoothRightModal();

  useEffect(() => {
    if (data.length !== 1) return;
    setActiveId(data[0]?.id);
  }, [data.length]);

  const { successSnackBar } = ToasterHook();

  const middleData = data[0];
  // const bodyData = data.filter((_, index) => index !== 0);
  const bodyData = data;

  const onClick = ({ id }) => {
    const isReselect = id == activeId;
    if (isReselect) return setActiveId(false);
    setActiveId(id);
    handleChoose(id);
  };

  // handleDelete
  const [deleteId, setDeleteId] = useState(false);

  const onDelete = async (id) => setDeleteId(id);

  const afterSuccessDelete = () => {
    // resets deleteId and updates setData
    setData((prev) => prev.filter(({ id: prevId }) => prevId != deleteId));
    if (deleteId == activeId) setActiveId(false);

    successSnackBar({
      msg: t("Bank account deleted"),
      showClose: true,
    });
    eventsTracker("delete_bank", { id: deleteId });
    setDeleteId(false);
  };

  const { mutation: handleDelete } = useMutation({
    url: `/partner_bank_accounts/${deleteId}`,
    method: "delete",
    afterSuccess: afterSuccessDelete,
  });

  useEffect(() => {
    if (!deleteId) return;
    handleDelete();
  }, [deleteId]);
  // handleDelete

  const handleChoose = (idRaw) => {
    const id = idRaw || activeId;
    eventsTracker("choose_bank", { id });
    onSubmit && onSubmit(id);
  };

  // edit bank
  const [defaultValues, setDefaultValues] = useState(null);
  const [editId, setEditId] = useState(0);

  const openEditModal = (id) => {
    const object = arrayToObjByID(data, id);
    const { bank_name, account_name, account_number } = object;
    const result = { bank_name, account_name, account_number };
    eventsTracker("to_edit_bank", { id });
    setDefaultValues(result);
    setEditId(id);
  };

  const handleAction = (action, item) => {
    const { id } = item || {};
    const isEdit = action === "edit";
    const isDelete = action === "delete";

    if (isEdit) return openEditModal(id);
    if (isDelete) return onDelete(id);
  };

  const afterSuccessEdit = (res) => {
    successSnackBar({
      msg: "Bank account updated",
      showClose: true,
    });

    const { data: dataRaw = {} } = res?.data || {};
    const { account_name, local_recipient, ...rest } = dataRaw || {};
    const data = {
      account_name: local_recipient?.name,
      ...rest,
    };
    const { id } = data;

    setActiveId(id);

    setData((prev) => {
      const newData = prev.map((item) => {
        const { id: itemId } = item;
        if (itemId == id) return data;
        return item;
      });
      const result = banksFormatter({ data: newData });
      return result;
    });
    eventsTracker("edit_bank", { id: editId });
    setDefaultValues(null);
    // handleChoose(id);
  };

  const { mutation: handleSubmitEdit, loading: loadingEdit } = useMutation({
    url: `/partner_bank_accounts/${editId}`,
    afterSuccess: afterSuccessEdit,
  });
  // edit bank

  // adds bank
  const { isOpenAddBank, loadingAddBank, onSubmitAddBank, toggleAddBank } =
    useAddBank({
      setActiveId,
      setData,
      toFinal: handleChoose,
    });

  // adds bank
  const cardProps = (item) => ({
    item,
    isActive: activeId == item?.id,
    onClick,
    onDelete,
    handleAction,
    onEdit: openEditModal,
    woDelete: selectedId == item?.id,
  });

  const hideModal = isOpenAddBank || Boolean(defaultValues);

  const components = [
    <WrapperModalButtons
      childrenButton={
        <ButtonJack
          type="outline"
          children={t("Back")}
          style={{ width: "100%", marginRight: 16 }}
          onClick={toToggle}
        />
      }
    >
      <GothamMedium className="font24" style={{ marginBottom: 8 }}>
        {t("Source of Payment")}
      </GothamMedium>
      <GothamRegular style={{ color: jackColors.neutral700, marginBottom: 32 }}>
        {t("We need this information to verify your payment.")}
      </GothamRegular>
      <ButtonJack
        type="outline"
        leftIcon={
          <JackIcons
            name="plus"
            fill={jackColors.black34}
            style={{ width: 20, height: 20, marginTop: -2 }}
          />
        }
        style={{ width: "100%" }}
        onClick={() => {
          eventsTracker("to_add_bank");
          toggleAddBank();
        }}
      >
        {t("Add new bank account")}
      </ButtonJack>
      <div className="d-flex align-items-center" style={{ margin: "32px 0px" }}>
        <hr style={{ borderTop: "1px solid #E6E6E8", width: "100%" }} />
        <GothamRegular
          style={{
            margin: "0px 16px",
            width: "100%",
            whiteSpace: "nowrap",
          }}
        >
          {t("or choose existing recipient")}
        </GothamRegular>
        <hr style={{ borderTop: "1px solid #E6E6E8", width: "100%" }} />
      </div>
      {bodyData.map((item, index) => (
        <BankCard key={index} {...cardProps(item)} />
      ))}
    </WrapperModalButtons>,
  ];

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        array={components}
        listener={toListener}
        width={[420]}
      />
      <BankAccountFormModal
        isClose={false}
        isOpen={isOpenAddBank}
        toggle={toggleAddBank}
        onSubmit={onSubmitAddBank}
        loading={loadingAddBank}
      />
      {/* Edits Bank Account */}
      {Boolean(defaultValues) && (
        <BankAccountFormModal
          isClose={false}
          isEdit
          loading={loadingEdit}
          isOpen={Boolean(defaultValues)}
          toggle={() => setDefaultValues(null)}
          defaultValues={defaultValues}
          onSubmit={handleSubmitEdit}
        />
      )}
      {/* Edits Bank Account */}
    </>
  );

  // return (
  //   <>
  //     <RightCardModalAtomic
  //       isOpen={isOpen}
  //       hideModal={hideModal}
  //       middleHeight={middleHeight}
  //       header={
  //         <MainCardHeaderMolecule
  //           isClose={isClose}
  //           middleHeight={middleHeight}
  //           toggle={toggle}
  //           title="Where Do You Send Your Money From"
  //         />
  //       }
  //       middle={
  //         <MiddleWrapper
  //           setHeight={setMiddleHeight}
  //           height={middleHeight}
  //           style={{ zIndex: 1 }}
  //         >
  //           <BankCard {...cardProps(middleData)} />
  //         </MiddleWrapper>
  //       }
  //       body={
  //         <>
  //           {bodyData.map((item, index) => (
  //             <BankCard
  //               key={index}
  //               {...cardProps(item)}
  //               style={{ marginBottom: 24 }}
  //             />
  //           ))}
  //           <OutlineButton
  //             className="w-100"
  //             style={{ fontSize: 16, marginBottom: 16 }}
  //             onClick={() => {
  //               eventsTracker("to_add_bank");
  //               toggleAddBank();
  //             }}
  //           >
  //             Add Bank Account
  //           </OutlineButton>
  //           <CustomButton
  //             className="w-100"
  //             disabled={!activeId}
  //             style={{ fontSize: 16 }}
  //             onClick={() => handleChoose(activeId)}
  //           >
  //             Choose Bank Account
  //           </CustomButton>
  //         </>
  //       }
  //     />
  //     {/* Create New Bank Account */}
  //     <BankAccountFormModal
  //       isClose={false}
  //       isOpen={isOpenAddBank}
  //       toggle={toggleAddBank}
  //       onSubmit={onSubmitAddBank}
  //       loading={loadingAddBank}
  //     />
  //     {/* Create New Bank Account */}
  //   </>
  // );
};
