import React from "react";
import { fileSizeString, windowDimension } from "@tools";
import { jackColors } from "assets/colors";
import JackIcons from "assets/jackIcons/typescript/parent";
import { GothamRegular } from "components/typescript/Text";
import { Banner } from "components/typescript/Banner";

// Define FileItem component
const FileItem: React.FC<{
  file: { name: string; size: number };
  enablePreview: boolean;
  handleDelete: (index: number) => void;
  onClickShow: (file: { name: string; size: number }) => void;
  index: number;
}> = ({ file, enablePreview, handleDelete, onClickShow, index }) => {
  const { name, size } = file;
  const sizeLabel = fileSizeString(size);
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{
        padding: 12,
        border: `1px solid ${jackColors.greyE6}`,
        borderRadius: 4,
        marginBottom: 8,
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => enablePreview && onClickShow(file)}
    >
      <div className="d-flex align-items-center">
        <JackIcons
          name="Uploaded_File"
          fill={jackColors.green06}
          style={{ marginRight: 8 }}
        />
        <div>
          <GothamRegular
            className="font12"
            style={{
              color: jackColors.black34,
              width: 250,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {name}
          </GothamRegular>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.grey90 }}
          >
            {sizeLabel}
          </GothamRegular>
        </div>
      </div>
      <div
        className="hover"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(index);
        }}
      >
        <JackIcons name="close" fill={jackColors.grey90} />
      </div>
    </div>
  );
};

// Define Files component
export const Files: React.FC<{
  files: Array<{ name: string; size: number }>;
  multiple: boolean;
  name: string;
  setValue: (name: string, value: any) => void;
  containerMaxHeight?: number;
  customError?: string;
  enablePreview?: boolean;
  handleShow: (file: { name: string; size: number }) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}> = ({
  files,
  multiple,
  name,
  setValue,
  containerMaxHeight,
  customError,
  enablePreview = false,
  handleShow,
  inputRef,
}) => {
  const handleDelete = (index: number) => {
    const filtered = files.filter((_, number) => number !== index);
    const isUndefined = !filtered.length;

    if (inputRef?.current) inputRef.current.value = "";
    const result = isUndefined ? "" : filtered;

    setValue(name, result);
  };

  const onClickShow = (file: { name: string; size: number }) =>
    handleShow(file);
  const { isTabOrPhone } = windowDimension();

  if (!multiple) return null;
  if (!files.length) return null;

  return (
    <>
      <div style={{ marginBottom: 8, marginTop: 4 }}>
        {customError && <Banner type="error" msg={customError} />}
      </div>
      <GothamRegular
        style={{ marginBottom: 8, marginTop: customError ? 0 : 16 }}
      >
        {files.length} file(s) uploaded
      </GothamRegular>
      <div
        style={{
          maxHeight: containerMaxHeight,
          overflow: containerMaxHeight ? "auto" : "visible",
          marginTop: customError ? 8 : 0,
        }}
      >
        {files.map((file, index) => (
          <FileItem
            key={index}
            file={file}
            enablePreview={enablePreview}
            handleDelete={handleDelete}
            onClickShow={onClickShow}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

// Define SpanError component
export const SpanError: React.FC<{
  isError: boolean;
  children: React.ReactNode;
}> = ({ isError, children }) => (
  <span style={{ color: isError ? jackColors.redE7 : "" }}>{children}</span>
);
