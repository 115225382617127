import { booleanState } from "../../../../components/Status";

const findRetryIterationCount = ({
  ancestoryLines = [],
  desiredAncestoryLineLength = 1,
  isOnlyFindFailedTrx = false,
  isFindLastIndex = false,
}) => {
  const retryIterationCount = Object.values(ancestoryLines).filter(
    (ancestoryLine) => {
      const isDesiredLength =
        ancestoryLine.length === desiredAncestoryLineLength;

      if (isOnlyFindFailedTrx) {
        const { isDeclined, isFailed } = booleanState(
          ancestoryLine[isFindLastIndex ? ancestoryLine.length - 1 : 0]?.state
        );
        return (isDeclined || isFailed) && isDesiredLength;
      }

      return isDesiredLength;
    }
  ).length;

  return retryIterationCount;
};

export default findRetryIterationCount;
