import { forwardRef } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { GothamMedium } from "../../../../../components/Text";
import SearchDropdown from "../SearchDropdown";
import styles from "./CategoryColorSelectionDropdown.module.css";
import { useTranslation } from "react-i18next";

const categoryColors = [
  jackColors.neutral700,
  "#47001E",
  "#F8762D",
  "#FFCB00",
  "#04C7B3",
  "#1C4C6C",
  "#238730",
  "#876FDE",
  "#DE738B",
  "#C69B7B",
  "#DC2F44",
  "#6087E7",
  "#5A489C",
  "#7F0B5C",
  "#AA3511",
  "#F26727",
  "#F4D53D",
  "#FF7B5A",
  "#2CC4B5",
  "#54C4D8",
  "#0A7C8C",
];

const CategoryIconSelectionDropdown = forwardRef(
  (
    {
      isOpen = false,
      selectedColor = "",
      style = {},
      onClose = () => {},
      onSelect = () => {},
    },
    ref
  ) => {
    const { t } = useTranslation("category/index");
    const firstRow = categoryColors.slice(0, 10);
    const secondRow = categoryColors.slice(10, 20);
    const thirdRow = categoryColors.slice(20, 21);

    return (
      <SearchDropdown
        ref={ref}
        woSearch
        isOpen={isOpen}
        roleUpAnimationCN={styles["closed"]}
        roleDownAnimationCN={styles["opened"]}
        style={style}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles["header"]}>
          <GothamMedium
            className="font12"
            style={{ color: jackColors.neutral900 }}
          >
            {t("Select Color")}
          </GothamMedium>
          <JackIcons
            name="close"
            fill={jackColors.neutral900}
            style={{ cursor: "pointer", scale: "0.8" }}
            onClick={onClose}
          />
        </div>

        <div className={styles["horizontal-line"]} />

        <div className={styles["icons-container"]}>
          {categoryColors.map((categoryColor) => {
            return (
              <div
                key={categoryColor}
                className={styles["icon-container"]}
                style={{
                  backgroundColor: categoryColor,
                  border:
                    selectedColor === categoryColor
                      ? `2px solid ${jackColors.neutral700}`
                      : "none",
                }}
                onClick={() => onSelect(categoryColor)}
              >
                {selectedColor === categoryColor && (
                  <JackIcons name="checkmark" fill="white" />
                )}
              </div>
            );
          })}
        </div>
      </SearchDropdown>
    );
    return <div className={styles["category-icon-selection-dropdown"]}></div>;
  }
);

export default CategoryIconSelectionDropdown;
