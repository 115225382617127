import { useTranslation } from "react-i18next";
import {
  SINGLE,
  BATCH,
  DATA_ERROR,
  SYSTEM_ERROR,
  MIXED_ERROR,
  // SINGULAR_DATA_ERROR,
  // SINGULAR_SYSTEM_ERROR,
  // SINGULAR_MIXED_ERROR,
  // PLURAL_DATA_ERROR,
  // PLURAL_SYSTEM_ERROR,
  // PLURAL_MIXED_ERROR,
} from "../constants";

const retryResubmitBannerTitleDecider = ({
  trxType,
  warningType,
  failedTrxCount,
  t = () => {},
}) => {
  const singular_data_error = t("This transaction failed due to data inaccuracies");
  const singular_system_error = t("This transaction failed due to a system issue");
  const singular_mixed_error = t("This transaction failed due to some reason(s)");
  
  const singular_data_error_2 = t("transaction failed due to data inaccuracies")
  const singular_system_error_2 = t("transaction failed due to a system issue");
  const singular_mixed_error_2 = t("transaction failed due to some reason(s)");

  const plural_data_error = t("transactions failed due to data inaccuracies")
  const plural_system_error = t("transactions failed due to a system issue");
  const plural_mixed_error = t("transactions failed due to some reason(s)");

  switch (trxType) {
    case SINGLE:
      switch (warningType) {
        case DATA_ERROR:
          return singular_data_error;
        case SYSTEM_ERROR:
          return singular_system_error;
        case MIXED_ERROR:
          return singular_mixed_error;
        default:
          return "-";
      }

    case BATCH:
      const hasOneFailedTrx = failedTrxCount === 1;

      switch (warningType) {
        case DATA_ERROR:
          if (hasOneFailedTrx)
            //   return `${failedTrxCount} ${SINGULAR_DATA_ERROR}`;
            // return `${failedTrxCount} ${PLURAL_DATA_ERROR}`;
            return `${failedTrxCount} ${singular_data_error_2}`;
          return `${failedTrxCount} ${plural_data_error}`;
        case SYSTEM_ERROR:
          if (hasOneFailedTrx)
          //   return `${failedTrxCount} ${SINGULAR_SYSTEM_ERROR}`;
          // return `${failedTrxCount} ${PLURAL_SYSTEM_ERROR}`;
            return `${failedTrxCount} ${singular_system_error_2}`;
          return `${failedTrxCount} ${plural_system_error}`;
        case MIXED_ERROR:
          if (hasOneFailedTrx)
          //   return `${failedTrxCount} ${SINGULAR_MIXED_ERROR}`;
          // return `${failedTrxCount} ${PLURAL_MIXED_ERROR}`;
            return `${failedTrxCount} ${singular_mixed_error_2}`;
          return `${failedTrxCount} ${plural_mixed_error}`;
        default:
          return "-";
      }

    default:
      return "-";
  }
};

export default retryResubmitBannerTitleDecider;
