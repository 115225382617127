import { useConstants } from "contexts/ConstantsContext/parent";
import { ToasterHook } from "../../contexts/ToasterContext";
import { fetch, useMutation } from "../../tools/api";

export const useMoveBalance = ({ afterSuccess }) => {
  const { mutation, result, loading } = useMutation({
    url: "/business_internal_transfers",
    method: "post",
    afterSuccess,
  });

  return { mutation, result, loading };
};

export const useFetchCompanyBalance = () => {
  const {
    data: companyBalance,
    refetch,
    loading,
  } = fetch({
    url: "/my_company_balance",
    method: "GET",
    formatter: ({ data }) => {
      return data;
    },
    afterSuccess: (res) => {
      const danamonAccountNum =
        res?.data?.danamon_account_details?.account?.account_number;

      const lastFourDigits = danamonAccountNum.slice(-4);

      const danamonLastFourDigitsStorage = localStorage.getItem(
        "danamonLastFourDigit"
      );

      const existButDifferent =
        danamonLastFourDigitsStorage &&
        lastFourDigits !== danamonLastFourDigitsStorage;

      const notExist = danamonAccountNum && !danamonLastFourDigitsStorage;
      if (!danamonAccountNum)
        return localStorage.removeItem("danamonLastFourDigits");

      if (notExist || existButDifferent)
        return localStorage.setItem("danamonLastFourDigits", lastFourDigits);

      return null;
    },
    woInit: false,
  });

  const { disbursement_balance } = companyBalance || {};
  const vaPocketAmount = (disbursement_balance || [])[0]?.balance;
  const danamonBalance =
    companyBalance?.danamon_account_details?.balance?.current_balance;
  const danamonAccountName =
    companyBalance?.danamon_account_details?.account?.account_holder_name;
  const danamonAccountNum =
    companyBalance?.danamon_account_details?.account?.account_number;

  const danamonAccountFull = companyBalance?.danamon_account_details;

  return {
    vaPocketBalance: vaPocketAmount,
    danamonBalance,
    danamonAccountName,
    danamonAccountNum,
    danamonAccountFull,
    refetch,
    loading,
  };
};

// export const myCompanyBalanceFormatter = (res) => {
//   const companyBalance = res?.data || {};
//   console.log("companyBalance:", companyBalance);
//   const { disbursement_balance } = companyBalance || {};
//   const vaPocketAmount = (disbursement_balance || [])[0]?.balance;
//   const danamonBalance =
//     companyBalance?.danamon_account_details?.balance?.current_balance;
//   const danamonAccountName =
//     companyBalance?.danamon_account_details?.account?.account_holder_name;
//   const danamonAccountNum =
//     companyBalance?.danamon_account_details?.account?.account_number;

//   const danamonAccountFull = companyBalance?.danamon_account_details;

//   return {
//     vaPocketBalance: vaPocketAmount,
//     danamonBalance,
//     danamonAccountName,
//     danamonAccountNum,
//     danamonAccountFull,
//   };
// };
