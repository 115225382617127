import { format } from "date-fns";
import { isEmpty } from "lodash";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { Banner } from "../../../../../components/Banner";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import {
  GothamMedium,
  GothamRegular,
  TextInlineBold,
} from "../../../../../components/Text";
import { downloadApi, useDownload } from "../../../../../tools/api";
import { useTranslation } from "react-i18next";
import { isID } from "@tools";
import { enUS, id } from "date-fns/locale";

export const SchedulePaymentButtons = ({
  loading,
  children,
  onClickLeft,
  onClickRight,
  onClickCancel,
  buttonRightText = "Pay Now",
  style,
  isIRM,
}) => {
  const { t } = useTranslation("schedule-payment/index");
  return (
    <div className="d-flex-column w-100">
      <div
        className="d-flex justify-content-between w-100"
        style={{ gap: 12, marginTop: 16, ...style }}
      >
        <ButtonJack
          style={{ width: "50%" }}
          isLoading={loading}
          type="outline"
          leftIcon={
            <JackIcons name="Reschedule_fill" fill={jackColors.neutral900} />
          }
          onClick={() => onClickLeft && onClickLeft()}
        >
          {t("Reschedule")}
        </ButtonJack>
        <ButtonJack
          style={{ width: "50%" }}
          isLoading={loading}
          leftIcon={
            <JackIcons name="checkmark-circle-2" fill={jackColors.neutral900} />
          }
          onClick={() => onClickRight && onClickRight()}
        >
          {t(buttonRightText)}
        </ButtonJack>
        {children}
      </div>
      <div onClick={() => onClickCancel && onClickCancel()}>
        <GothamRegular
          className="font12"
          style={{
            paddingBottom: isIRM && 20,
            paddingTop: 16,
            textAlign: "center",
          }}
        >
          {t("Not paying this transaction?")}{" "}
          <TextInlineBold
            className="font12"
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            {t("Cancel transaction")}
          </TextInlineBold>
        </GothamRegular>
      </div>
    </div>
  );
};

export const ScheduledBanner = ({ scheduled_payment, style }) => {
  if (isEmpty(scheduled_payment)) return null;

  const { date_and_time, is_overdue, state } = scheduled_payment;
  const isPaid = state == "paid";
  const isCancelled = state == "cancelled";

  const { t } = useTranslation("schedule-payment/index");
  if (isCancelled) return null;
  if (isPaid) return null;
  if (is_overdue)
    return (
      <div style={style}>
        <Banner
          title={t("Payment delayed due to insufficient balance.")}
          type="warning"
        />
      </div>
    );

  const label = format(
    new Date(date_and_time),
    "eeee, d MMMM yyyy (HH:mm 'WIB')",
    { locale: isID ? id : enUS }
  );
  return (
    <div className="d-flex-column" style={{ ...style }}>
      <GothamRegular className="font12" style={{ marginBottom: 4 }}>
        {t("Scheduled to be paid on:")}
      </GothamRegular>
      <div
        style={{
          width: "100%",
          borderRadius: 4,
          border: `1px solid #6087E7`,
          padding: "10px 12px",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#F4F9FF",
          alignItems: "center",
          position: "relative",
          zIndex: 10,
          cursor: "pointer",
        }}
      >
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          <JackIcons
            name="calendar"
            fill="#343434"
            style={{ width: 20, height: 20 }}
          />
          <GothamMedium
            className="font14"
            style={{
              height: 20,
              color: "#343434",
            }}
          >
            {label}
          </GothamMedium>
        </div>
      </div>
    </div>
  );
};

export const EditButton = ({ onClick, hideButton }) => {
  if (hideButton) return null;
  const { t } = useTranslation("invoice-payment/invoice-payment");
  return (
    <ButtonJack
      type="outline"
      style={{ height: 24, width: 134, padding: "0px 8px" }}
      rightIcon={
        <JackIcons
          fill="#343434"
          name="edit_outline"
          style={{ width: 16, height: 16 }}
        />
      }
      onClick={onClick}
    >
      <GothamMedium className="font12">{t("Change details")}</GothamMedium>
    </ButtonJack>
  );
};

export const DownloadButton = ({
  buttonRightText = "Download Receipt",
  downloadLink,
  style,
}) => {
  return (
    <div
      className="d-flex justify-content-between w-100"
      style={{ gap: 12, marginTop: 16, ...style }}
    >
      <ButtonJack
        style={{ width: "100%" }}
        type="outline"
        // isLoading={loading}
        leftIcon={
          <JackIcons name="download_outline" fill={jackColors.neutral900} />
        }
        onClick={() => {
          downloadApi({
            url: downloadLink,
            fileName: "bill-payment-completed-receipt.pdf",
          });
        }}
      >
        {buttonRightText}
      </ButtonJack>
    </div>
  );
};
