import React from "react";
import { colors } from "../../../../assets/colors";
import { GothamMedium } from "../../../../components/Text";

export const SuccessMoreOrLessButton = ({
  style,
  text,
  toggle = () => {},
  isShowAll,
}) => {
  const caretDirection =
    isShowAll === "Few less" ? "M3.5 10L8.5 5L13.5 10" : "M13.5 6L8.5 11L3.5 6";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        marginTop: "16px",
        ...style,
      }}
    >
      <div
        style={{
          height: "4px",
          width: "45px",
          backgroundColor: colors.greye6,
        }}
      />
      <div
        onClick={toggle}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <GothamMedium style={{ fontSize: "16px" }}>{text}</GothamMedium>
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={caretDirection}
            stroke="#909098"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        style={{
          height: "4px",
          width: "45px",
          backgroundColor: colors.greye6,
        }}
      />
    </div>
  );
};
