const ancestoryLinesFormatter = (transactions = []) => {
  const findAncestors = (ancestorId, ancestoryLine) => {
    const foundAncestor = transactions.find(({ id }) => id === ancestorId);

    if (foundAncestor) {
      return findAncestors(foundAncestor?.ancestor_id, [
        ...ancestoryLine,
        foundAncestor,
      ]);
    }

    return ancestoryLine;
  };

  const ancestoryLines = {};

  transactions.forEach((trx) => {
    const { id, ancestor_id } = trx;
    if (!ancestor_id) {
      ancestoryLines[id] = [trx];
      return;
    }

    const ancestors = findAncestors(ancestor_id, [trx]);
    ancestoryLines[id] = ancestors.reverse();
  });

  return ancestoryLines;
};

export default ancestoryLinesFormatter;
