import { retriableOrResubmittableTrxsDecider } from "../../../../modals/smartActivityModal/retry-resubmit/helpers";
import { getEmails } from "../../../localTransferComponents/localTransfer/parent";

export const payrollDefaultValuesFormatter = (
  res,
  isValidationScreen,
  isResubmit,
  isDetailsScreen
) => {
  const { batch, name, batch_id } = res?.data || {};
  const { local_transactions = [], state } = batch || {};

  const resubmittedTrxs =
    retriableOrResubmittableTrxsDecider(local_transactions);
  const resubmittedTrxIds = resubmittedTrxs?.map(({ id }) => id);

  let defaultValues = {};

  let invalidAccountNumber = 0;

  let mismatchedName = 0;
  const getLocalTrxs = () => {
    if (isValidationScreen)
      return local_transactions.filter(
        ({ is_match_name, is_valid }) => !is_match_name || !is_valid
      );

    if (isResubmit && isDetailsScreen) {
      return local_transactions?.filter(({ id }) =>
        resubmittedTrxIds?.includes(id)
      );
    }

    return local_transactions;
  };

  getLocalTrxs().forEach((item, index) => {
    const { local_recipient, recipient } = item || {};
    const {
      payroll_recipient,
      account_number,
      // name,
      payer_id: bank,
      // email,
      registered_name,
      is_valid,
      is_match_name,
    } = local_recipient || {};

    const {
      name,
      email: emailRaw,
      additional_emails: addEmailRaw,
    } = recipient || {};
    const emailCombination = `${emailRaw},${addEmailRaw}`;
    const email = getEmails(emailCombination);

    const {
      salary: net_salary,
      local_recipient_id,
      department_name: department,
      team_role: role,
    } = payroll_recipient || {};

    const isValidation = state == "validate_recipient";

    const getObj = () => {
      const defaultObj = {
        ...item,
        account_number,
        department,
        net_salary,
        role,
        name,
        email,
        local_recipient_id,
        bank,
      };
      if (isValidationScreen) {
        const name_check_result = registered_name || "";
        return { ...defaultObj, name_check_result };
      }
      return defaultObj;
    };

    const counter = () => {
      if (!is_valid) return (invalidAccountNumber += 1);
      if (!is_match_name) return (mismatchedName += 1);
    };

    const _ = counter();
    const obj = getObj();

    Object.keys(obj).forEach((key) => {
      const number = index + 1;
      const newName = `${key}_${number}`;
      const value = obj[key];
      defaultValues[newName] = value;
    });
  });
  return {
    ...defaultValues,
    name,
    batch_id,
    invalidAccountNumber,
    mismatchedName,
    batch,
  };
};

export const defaultValuesCleaner = (defaultValues) => {
  let obj = {};
  Object.keys(defaultValues).forEach((key) => {
    const value = defaultValues[key];
    const isNumber = Boolean(Number(key.split("_").pop()));
    if (isNumber) return;
    obj[key] = value;
  });
  return obj;
};
