import { Tooltip, makeStyles } from "@material-ui/core";
import React from "react";
import { colors } from "../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { useBooleanTableLocal } from "../../localTransferEnhancement/create/table/logic";
import { useBooleanTable } from "./table/logic";
import { Trans, useTranslation } from "react-i18next";

export const TooltipMsgGenerator = ({
  item,
  t = (item) => item,
  isLocalTransfer = false,
}) => {
  const { name, duplicate, invalidFormat } = item || {};
  const { isAmount } = useBooleanTableLocal({ name });
  const { isBank, isAccNumber, isSalary, isName } = useBooleanTable({ name });
  if (isBank) {
    if (duplicate) {
      return {
        title: "",
        desc: (
          <span style={{ whiteSpace: "nowrap" }}>
            {t("This recipient's bank account details is duplicated.")}
            <br /> {t("You can edit, keep one, or choose to ignore.")}
          </span>
        ),
      };
    }
    return {
      title: "",
      desc: (
        <Trans
          i18nKey={t(
            isLocalTransfer ? "dynamic.style_text_4" : "dynamic.style_text_8"
          )}
          components={[<span style={{ fontFamily: "GothamMedium" }} />]}
        />
      ),
    };
  }
  if (isAccNumber) {
    if (duplicate) {
      return {
        title: "",
        desc: (
          <span style={{ whiteSpace: "nowrap" }}>
            {t("This recipient's bank account details is duplicated.")}
            <br /> {t("You can edit, keep one, or choose to ignore.")}
          </span>
        ),
      };
    }
    return {
      title: "",
      desc: (
        <Trans
          i18nKey={t(
            isLocalTransfer ? "dynamic.style_text_3" : "dynamic.style_text_7"
          )}
          components={[<span style={{ fontFamily: "GothamMedium" }} />]}
        />
      ),
    };
  }
  if (isSalary) {
    return {
      title: "",
      desc: t("Please recheck and update the salary amount."),
    };
  }
  if (isAmount) {
    return {
      title: "",
      desc: (
        <Trans
          i18nKey={t(
            isLocalTransfer ? "dynamic.style_text_2" : "dynamic.style_text_6"
          )}
          components={[<span style={{ fontFamily: "GothamMedium" }} />]}
        />
      ),
    };
  }
  if (isName) {
    if (invalidFormat) {
      return {
        title: "",
        desc: t(
          "Please recheck the recipient name. Make sure to include alphabetic characters, not just numeric."
        ),
      };
    }
    return {
      title: "",
      desc: (
        <Trans
          i18nKey={t(
            isLocalTransfer ? "dynamic.style_text_1" : "dynamic.style_text_5"
          )}
          components={[<span style={{ fontFamily: "GothamMedium" }} />]}
        />
      ),
    };
  }
};

const useStylesEdit = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "white",
    color: colors.grey6c,
    padding: "4px 8px",
    filter: "drop-shadow(0px 4px 8px rgba(88, 88, 88, 0.06))",
    fontFamily: "GothamBook",
    fontSize: 12,
  },
  customArrow: {
    color: "white",
  },
}));

export const TooltipEdit = ({ children }) => {
  const classes = useStylesEdit();
  const { t } = useTranslation("payroll/create");
  return (
    <Tooltip
      title={t("Edit field")}
      placement="top"
      arrow
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
    >
      {children}
    </Tooltip>
  );
};

const useStylesIncorrect = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "white",
    filter: "drop-shadow(0px 4px 8px rgba(88, 88, 88, 0.06))",
    // minWidth: 301,
  },
  customArrow: {
    color: "white",
  },
  customPopper: {},
}));

export const TooltipError = ({
  children,
  title,
  desc,
  style,
  containerStyle,
  placement = "top",
}) => {
  const classes = useStylesIncorrect();
  return (
    <Tooltip
      title={
        <div style={{ padding: 8, ...containerStyle }}>
          <GothamMedium style={{ color: colors.redca, ...style }}>
            {title}
          </GothamMedium>
          <GothamRegular
            style={{ fontSize: 12, color: colors.grey6c, lineHeight: "16px" }}
          >
            {desc}
          </GothamRegular>
        </div>
      }
      placement={placement}
      arrow
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
        popper: classes.customPopper,
      }}
    >
      {children}
    </Tooltip>
  );
};

export const TooltipIncorrect = ({
  children,
  item,
  t = (item) => item,
  isLocalTransfer = false,
}) => {
  const { title, desc } = TooltipMsgGenerator({ item, t, isLocalTransfer });
  return (
    <TooltipError desc={desc} title={title}>
      {children}
    </TooltipError>
  );
};
