export const DataValidationHeaderArray = ({
  isPayroll = false,
  t = (item) => item,
}) => {
  const validationHeaderArray = [
    {
      value: "number",
      label: "No",
      width: 40,
    },
    {
      value: "name",
      label: isPayroll ? t("Employee") : "Recipient",
      width: 308,
    },
    {
      value: "name_check_result",
      label: t("Account Name"),
      width: 308,
    },
    {
      value: "bank",
      label: t("Bank Name "),
      width: 308,
    },
    {
      value: "account_number",
      label: t("Account Number"),
      width: 308,
    },
    {
      value: "action",
      label: "",
      width: 80,
    },
  ];

  const validationInvalidHeaderArray = [
    {
      value: "number",
      label: "No",
      width: 40,
    },
    {
      value: "name",
      label: isPayroll ? t("Employee") : "Recipient",
      width: 410,
    },
    {
      value: "bank",
      label: t("Bank Name "),
      width: 410,
    },
    {
      value: "account_number",
      label: t("Account Number"),
      width: 410,
    },
    {
      value: "action",
      label: "",
      width: 80,
    },
  ];

  return { validationHeaderArray, validationInvalidHeaderArray };
};

export const validationArrayStep = [
  { step: "Batch Name", isActive: false },
  { step: "Input Recipient", isActive: false },
  { step: "Transfer Details", isActive: false },
  { step: "Bank Validation", isActive: true },
  { step: "Confirm to Submit", isActive: false },
];
