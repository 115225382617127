import { booleanState } from "../../../../components/Status";
import ancestoryLinesFormatter from "./ancestoryLinesFormatter";
import longestAncestoryLineDecider from "./longestAncestoryLineDecider";

const failedTrxCountDecider = (transactions = []) => {
  const ancestoryLines = ancestoryLinesFormatter(transactions);
  const longestAncestoryLine = longestAncestoryLineDecider(ancestoryLines);
  const longestAncestoryLineLength = longestAncestoryLine.length;
  const failedTrxCount = Object.values(ancestoryLines).filter(
    (ancestoryLine) => {
      const trx = ancestoryLine[ancestoryLine.length - 1];
      const { isFailed, isDeclined } = booleanState(trx?.state);
      const isFailedLastTrx = isFailed || isDeclined;
      const isLongestAncestoryLine =
        ancestoryLine.length === longestAncestoryLineLength;
      return isFailedLastTrx && isLongestAncestoryLine;
    }
  ).length;

  return failedTrxCount;
};

export default failedTrxCountDecider;
