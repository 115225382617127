import { PDFOrImageViewer } from "./PDFPreview";

const FullScreenViewer = ({
  isOpen,
  toggle: onBack,
  url,
  file,
  woHeader,
  fileName,
}) => {
  if (!isOpen) return null;

  const containerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 5000,
  };

  return (
    <div style={containerStyle}>
      <PDFOrImageViewer
        url={url}
        file={file}
        onBack={onBack}
        isFullScreen
        woHeader={woHeader}
        fileName={fileName}
      />
    </div>
  );
};

export default FullScreenViewer;
