import ancestoryLinesFormatter from "./ancestoryLinesFormatter";
import longestAncestoryLineDecider from "./longestAncestoryLineDecider";

const retriedTrxTotalAmountDecider = (transactions = []) => {
  const ancestoryLines = ancestoryLinesFormatter(transactions);
  const longestAncestoryLine = longestAncestoryLineDecider(ancestoryLines);
  const longestAncestoryLineLength = longestAncestoryLine.length;
  const allTransactions = Object.values(ancestoryLines)
    .map((ancestoryLine) => {
      return ancestoryLine;
    })
    .flat();
  const retriedTransactions = Object.values(ancestoryLines)
    .filter((ancestoryLine) => {
      return ancestoryLine.length === longestAncestoryLineLength;
    })
    .map((ancestoryLine) => {
      const ancestorId = ancestoryLine[ancestoryLine.length - 1]?.ancestor_id;
      return allTransactions.find(({ id }) => id === ancestorId);
    });
  const retriedTrxTotalAmount = retriedTransactions.reduce(
    (accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue?.amount);
    },
    0
  );
  return retriedTrxTotalAmount;
};

export default retriedTrxTotalAmountDecider;
