import {
  deepRemoveDuplicates,
  removeDuplicates,
} from "../../../../components/tools";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";

const getObj = (array, name, isMismatch) => {
  const getObjAtomic = (name) =>
    array.filter(({ name: nameProps }) => nameProps == name)[0] || {};

  const error = isMismatch
    ? {
        title: "Mismatched name",
        msg: "Please match recipient name to bank account name",
      }
    : {
        title: "Account does not exist",
        msg: "Please change account number or/and Banks",
      };

  const result = {
    ...getObjAtomic(name),
    invalid: !isMismatch,
    invalidYellow: isMismatch,
    error,
  };

  return result;
};

export const mismatchOrInvalidPayroll = (array) => {
  let invalidArr = [];

  let mismatchArr = [];

  const { localTransferBanks } = useConstants();

  array.forEach((array) => {
    const isInvalid = Boolean(
      array.filter(({ value, name }) => !value && name == "is_valid")[0]?.name
    );

    const getObjInvalid = (name) => getObj(array, name);
    const getObjMismatch = (name) => getObj(array, name, true);

    if (isInvalid) {
      const bankObj = {
        ...getObjInvalid("bank"),
        options: localTransferBanks,
      };

      const accountNumberObj = getObjInvalid("account_number");

      const nameCheckResultObj = {
        ...getObjInvalid("name_check_result"),
        type: "text",
        invalid: false,
      };

      return invalidArr.push(
        deepRemoveDuplicates(
          [bankObj, accountNumberObj, nameCheckResultObj, ...array],
          "name"
        )
      );
    }

    const nameObj = getObjMismatch("name");
    const bankObj = {
      ...getObjMismatch("bank"),
      options: localTransferBanks,
      invalidYellow: false,
    };
    const nameCheckResultObj = {
      ...getObjMismatch("name_check_result"),
      type: "text",
      invalidYellow: false,
    };

    mismatchArr.push([bankObj, nameObj, nameCheckResultObj, ...array]);
  });

  mismatchArr = mismatchArr.filter((array) => {
    const obj =
      (array || []).filter(({ name }) => name == "is_match_name")[0] || {};
    const isMatch = obj?.value;
    return !isMatch;
  });

  return { invalidArr, mismatchArr };
};

export const payrollValidationFormatter = (
  defaultValues,
  additionalData = [],
  defaultData = []
) => {
  let newDefaultValues = [];
  Object.keys(defaultValues).forEach((key) => {
    const splittedKey = key.split("_");
    const index = Number(splittedKey.pop());
    index && newDefaultValues.push(index);
  });
  const indexes = removeDuplicates(newDefaultValues);

  newDefaultValues = indexes.map((number) => {
    const isNumber = typeof number == "number";
    if (!isNumber) return number;
    let item = {};
    Object.keys(defaultValues).forEach((keyName) => {
      const splittedName = keyName.split("_");
      const childNumber = Number(splittedName.pop());
      const name = splittedName.join("_");
      const value = defaultValues[keyName];
      if (number == childNumber) item[name] = value;
    });
    return item;
  });

  newDefaultValues = newDefaultValues.map((obj) => {
    let arr = [];
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      let item = {};
      const value = obj[key];
      item["name"] = key;
      item["value"] = value;
      arr.push(item);
    });

    return [...defaultData, ...arr];
  });

  if (additionalData) {
    newDefaultValues = newDefaultValues.map((array) => {
      return [...additionalData, ...array];
    });
  }

  return newDefaultValues;
};

export const validationPayrollSubmitFormatter = (
  data,
  invalidArr,
  additionArr = []
) => {
  const getId = (array) => array.filter(({ name }) => name == "id")[0]?.value;

  const submittedIds = data.map((array) => getId(array));
  const filtered = invalidArr
    .filter((array) => {
      const id = getId(array);
      return submittedIds.includes(id);
    })
    .map((array) => deepRemoveDuplicates(array, "name"));

  const filteredAdditionArr = additionArr.map((array) => {
    const result = deepRemoveDuplicates(array, "name");
    const filtered = result.filter(({ value }) => value);
    return filtered;
  });

  const mergedWithNewValues = [
    ...filteredAdditionArr,
    ...filtered.map((array) => {
      const id = getId(array);
      const changedArr = data.filter((array) => {
        const childId = array.filter(({ name }) => name == "id")[0]?.value;

        return childId == id;
      })[0];

      const result = [...changedArr, ...array].filter(({ value }) => value);

      return deepRemoveDuplicates(result, "name");
    }),
  ];

  const values = mergedWithNewValues.map((array) => {
    let value = {};
    array.forEach((item) => {
      const { name, value: valueChild, options } = item || {};
      const isBank = name == "bank";
      if (isBank) {
        const obj = options.filter(({ value }) => valueChild == value)[0];
        return (value[name] = obj);
      }
      value[name] = valueChild;
    });
    return value;
  });

  return values;
};

export const validationOnChange = (data, setData, setMultiData) => {
  const isChangedFunc = (array) =>
    array.filter((item) => item?.invalid).length == 1;

  const invalidDefaultValues = data.map((array) => {
    const isChanged = isChangedFunc(array);
    if (isChanged) return array.map((item) => ({ ...item, invalid: false }));
    return array;
  });
  const isRun = data.filter((array) => {
    const isChanged = isChangedFunc(array);
    return isChanged;
  }).length;

  setMultiData((prev) => ({ ...prev, invalidDefaultValues }));
  if (isRun) setData(invalidDefaultValues);
};

const getObjProps = (array) => {
  const getObj = (array, name) =>
    array.filter(({ name: nameProps }) => name == nameProps)[0];

  const getValue = (name) => getObj(array, name)?.value;
  const nameObj = getObj(array, "name");

  return { getValue, nameObj };
};

export const validationOnChangeMismatch = (data, setData, setMultiData) => {
  const filteredData = data.filter((array) => {
    const { getValue, nameObj } = getObjProps(array);

    const { invalidYellow, value } = nameObj || {};

    if (!invalidYellow) return false;
    const name = value;
    const name_check_result = getValue("name_check_result");

    return name == name_check_result;
  });

  if (filteredData.length) {
    const result = data.map((array) => {
      const { getValue, nameObj } = getObjProps(array);

      const { invalidYellow, value } = nameObj || {};
      if (!invalidYellow) return array;

      const name = value;
      const name_check_result = getValue("name_check_result");

      if (name == name_check_result) {
        const result = array.map((obj) => {
          const { name } = obj;
          const isName = name == "name";
          if (isName) return { ...obj, invalidYellow: false };
          return obj;
        });
        return result;
      }

      return array;
    });
    setData(result);
  }

  setMultiData((prev) => ({ ...prev, mismatchDefaultValues: data }));
};

export const validationPayrollSubmitFormatterSkip = ({
  values: valuesRaw,
  array,
  ids,
}) => {
  const values = validationPayrollSubmitFormatter(valuesRaw, valuesRaw, array);

  const result = values.map((item) => {
    const { id } = item;
    if (ids.includes(id)) return { ...item, unprocessed: true };
    return item;
  });

  return result;
};
