// ChatGPT
const hexToRgbaConverter = (hex, alpha) => {
  // Remove the '#' symbol if it exists
  hex = hex.replace("#", "");

  // Check if the hex value is a short version (e.g., #abc)
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Parse the hex components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Validate alpha value (default to 1 if not provided)
  const validAlpha =
    alpha !== undefined && alpha >= 0 && alpha <= 1 ? alpha : 1;

  // Return the RGBA color in the format "rgba(r, g, b, alpha)"
  return `rgba(${r}, ${g}, ${b}, ${validAlpha})`;
};

export default hexToRgbaConverter;
