import Image from "next/image";

const NextImage = ({ width, height, src, alt, ...props }) => {
  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      loader={imageLoader}
      {...props}
    />
  );
};

export default NextImage;
