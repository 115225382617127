import { format } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { Avatar } from "../../../../../components/Avatar";
import { Banner } from "../../../../../components/Banner";
import { useModalHook } from "../../../../../components/Modals";
import { RightModal } from "../../../../../components/Modals/RightModal/parent";
import PINModal from "../../../../../components/PINModal/parent";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../../components/Text";
import { formatCurrencyNoDecimal, isID } from "../../../../../components/tools";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import { WrapperModalButtons } from "../../../../../pageComponents/homeComponents/topup/components";
import { spesificDateFormat } from "../../../../../pageComponents/scheduledPaymentComponents/formatter";
import { ScheduledPaymentOptions } from "../../../../../pageComponents/scheduledPaymentComponents/paymentOptions";
import {
  CustomResultBanner,
  ScheduleInfo,
} from "../../../../../pageComponents/scheduledPaymentComponents/paymentOptions/modals";
import { RescheduleForm } from "../../../../../pageComponents/scheduledPaymentComponents/reschedulePayment";
import {
  ApproveBackButton,
  CompanyBalanceAndTopUp,
} from "../../../crossBorderSingleModal/ReleasePaymentModal";
import { ModalCardLeftRight } from "../../../payroll/rejectModal";
import { StatusAccountIconJack } from "../../../../../components/Status/JackStatusIcon";
import { idrStringFormatter } from "../../../../../pageComponents/invoiceComponents/data/formatter";
import { enUS, id } from "date-fns/locale";
import { useTranslation } from "react-i18next";
export const PayNowModal = ({
  isOpen,
  toggle,
  data,
  detailData,
  onClick,
  onClickTopUp,
  schedulePaymentState,
}) => {
  const { user, refetchUser, userLoading } = useGetAuth();
  const { mainBalance } = user;
  const { balance } = mainBalance;
  const companyBalanceIdr = `IDR ${formatCurrencyNoDecimal(balance)}`;
  const { totalPaymentRaw, titleModalHeader, title, originator } = detailData;
  const titleModal = !!titleModalHeader ? titleModalHeader : "Confirm to pay";

  const isPayroll = title === "Payroll";
  const isLocalTransfer = title === "Local Transfer";
  const isTribeNgerepotin = isPayroll || isLocalTransfer;

  const remainingBalance = balance - totalPaymentRaw;
  const isSufficient = remainingBalance >= 0 ? true : false;

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      noPadding={true}
      width={[420]}
      array={[
        <WrapperModalButtons
          childrenButton={
            <>
              <CompanyBalanceAndTopUp
                companyBalanceIdr={companyBalanceIdr}
                onClickTopUp={onClickTopUp}
                refresh={refetchUser}
                loading={userLoading}
              />
              <ApproveBackButton
                onClick={onClick}
                onClickBack={toggle}
                disableButton={!isSufficient}
                isTribeNgerepotin={isTribeNgerepotin}
              />
            </>
          }
        >
          <div
            style={{
              padding: 16,
              width: "100%",
              height: "calc(100vh - 180px)",
            }}
          >
            <GothamMedium
              style={{
                fontSize: 24,
                color: "#000000",
                marginBottom: 32,
                marginTop: 0,
              }}
            >
              {titleModal}
            </GothamMedium>
            <ModalCardLeftRight data={detailData} />
          </div>
        </WrapperModalButtons>,
      ]}
    />
  );
};

const CustomInformation = ({ detailData, totalAmount, prevDate }) => {
  const { iconName, title, recipient_name, totalPaymentRaw, originator } =
    detailData;

  const { t } = useTranslation("schedule-payment/release");
  return (
    <div
      style={{
        minHeight: 94,
        height: "auto",
        borderRadius: 8,
        border: "1px solid #e6e6e8",
        backgroundColor: "#f1f1f1",
      }}
    >
      <div
        style={{
          height: 58,
          border: "1px solid #e6e6e8",
          backgroundColor: "#FFF",
          borderRadius: 8,
          padding: 12,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="d-flex align-items-center">
          <Avatar
            size="medium"
            mainIcon={
              <JackIcons
                name={iconName}
                style={{ width: 20, height: "auto" }}
              />
            }
          />
          <div className="d-flex-column" style={{ marginLeft: 8 }}>
            <GothamMedium className="font12">{title}</GothamMedium>
            <GothamRegular className="font12" style={{ color: "#909098" }}>
              {recipient_name}
            </GothamRegular>
          </div>
        </div>
        <GothamMedium>{idrStringFormatter(totalPaymentRaw)}</GothamMedium>
      </div>
      <div style={{ padding: "10px 12px", display: "flex", gap: 8 }}>
        <JackIcons
          name="alert_circle"
          fill="#909098"
          style={{ width: 16, height: 16 }}
        />
        <GothamRegular style={{ color: "#6C6C71" }} className="font12">
          {t("Previous Schedule:")}{" "}
          <TextInlineMedium>{prevDate}</TextInlineMedium>
        </GothamRegular>
      </div>
    </div>
  );
};

export const RescheduleOptionModal = ({
  isOpen,
  toggle,
  data,
  detailData,
  onClick,
  onClickTopUp,
  schedulePaymentState,
}) => {
  const { t } = useTranslation("schedule-payment/release");
  const { user, refetchUser, userLoading } = useGetAuth();
  const { mainBalance } = user;
  const { balance } = mainBalance;
  const companyBalanceIdr = `IDR ${formatCurrencyNoDecimal(balance)}`;
  const { totalPaymentRaw, titleModalHeader, title, originator } = detailData;
  const titleModal = !!titleModalHeader ? titleModalHeader : "Confirm to pay";

  const isPayroll = title === "Payroll";
  const isLocalTransfer = title === "Local Transfer";
  const isTribeNgerepotin = isPayroll || isLocalTransfer;

  const remainingBalance = balance - totalPaymentRaw;
  const isSufficient = remainingBalance >= 0 ? true : false;

  const { due_date: invoiceDueDate } = data;
  const [rescheduleItem, setItem] = schedulePaymentState;
  const isPayImmediately = rescheduleItem?.type == "default";
  const disableButton = useMemo(() => {
    if (isEmpty(rescheduleItem)) return true;

    const { type, payload } = rescheduleItem;
    const isPayloadEmpty = isEmpty(payload);
    const isPayImmediately = type == "default";

    if (isPayImmediately) return !isSufficient;

    return isPayloadEmpty;
  }, [rescheduleItem]);
  const { date_and_time } = rescheduleItem?.payload || {};

  const text = spesificDateFormat(date_and_time);
  const bannerDate = format(
    new Date(data?.scheduled_payment?.date_and_time),
    "eeee, d MMMM yyyy (HH:mm 'WIB')",
    { locale: isID ? id : enUS }
  );

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      noPadding={true}
      width={[420]}
      array={[
        <WrapperModalButtons
          customComponentsTopButton={
            <ScheduleInfo dateObj={rescheduleItem?.payload} />
          }
          customComponentsStyle={{ top: -36 }}
          childrenButton={
            <>
              {isPayImmediately && (
                <CompanyBalanceAndTopUp
                  companyBalanceIdr={companyBalanceIdr}
                  onClickTopUp={onClickTopUp}
                  refresh={refetchUser}
                  loading={userLoading}
                />
              )}
              <ApproveBackButton
                onClick={onClick}
                onClickBack={toggle}
                disableButton={disableButton}
                isTribeNgerepotin={isTribeNgerepotin}
                rightIcon="arrow_forward"
                rightText={t("Continue")}
                hideLeftIcon
              />
            </>
          }
        >
          <div
            style={{
              padding: 16,
              width: "100%",
              height: "calc(100vh - 180px)",
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
          >
            <div>
              <GothamMedium
                style={{
                  fontSize: 24,
                  color: "#000000",
                  marginBottom: 8,
                  marginTop: 0,
                }}
              >
                {t("Reschedule Payment")}
              </GothamMedium>
              <GothamRegular style={{ color: jackColors.grey6C }}>
                {t(
                  "Choose between rescheduling by paying immediately, a specific date, or a payment cycle."
                )}
              </GothamRegular>
            </div>
            {/* <Banner title={`Previous Schedule: ${bannerDate}`} /> */}
            <CustomInformation detailData={detailData} prevDate={bannerDate} />

            <RescheduleForm
              product={originator}
              invoiceDueDate={invoiceDueDate}
              state={schedulePaymentState}
              defaultValue={rescheduleItem}
            />
            {/* {text && <CustomResultBanner showResult text={text} />} */}
          </div>
        </WrapperModalButtons>,
      ]}
    />
  );
};

export const RescheduleModal = (props) => {
  const { toggle, onSubmit, isLoading } = props;
  const [rescheduleItem, setItem] = useState({});
  const { isOpen: openPinModal, toggle: togglePinModal } = useModalHook();
  return (
    <>
      <RescheduleOptionModal
        {...props}
        schedulePaymentState={[rescheduleItem, setItem]}
        onClick={() => {
          toggle();
          togglePinModal();
        }}
      />
      <PINModal
        isLoading={isLoading}
        isOpen={openPinModal}
        toggle={() => {
          togglePinModal();
          toggle();
        }}
        onBack={() => {
          togglePinModal();
          toggle();
        }}
        onSubmit={({ pin }) => onSubmit({ pin, ...rescheduleItem })}
      />
    </>
  );
};
