import { Popover } from "@material-ui/core";
import { colors, jackColors } from "assets/colors";
import JackIcons from "assets/jackIcons/typescript/parent";
import { Avatar } from "components/Avatar";
import { ButtonJack } from "components/ButtonsJack/typescript/parent";
import { useModalHook } from "components/Modals";
import { TextFieldJack } from "components/typescript/inputs/textfield/textfield";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
  TextInlineRegular,
} from "components/typescript/Text";
import { useForm } from "components/typescript/tools";
import UserList from "modals/AddFromListModal/typescript/UserList";
import SimpleCenteredModal from "modals/SimpleCenteredModal/typescript";
import { useRouter } from "next/router";

// import InputChip from "pageComponents/localTransferEnhancement/create/table/inputs/email/inputChip";
import { FormDataBatchProps } from "pageComponents/localTransferEnhancement/typescript/typeLocalTransfer";
import Divider from "pageComponents/onboardCardPageComponents/components/divider";
import { useLanguage } from "public/locales/translationFunctions";
import {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MIXPANELS from "universalFunctions/mixpanels";
import { RecipientCard } from "../add-recipient/components";
import { RecipientType } from "../add-recipient/index.types";
import {
  AdjustableRecipientCardProps,
  ConfirmModalProps,
  MultiEmailFormProps,
} from "./index.types";
import styles from "./styles.module.css";
import { InputChip } from "pageComponents/localTransferEnhancement/create/table/inputs/email/chip";
// import InputChip from "pageComponents/localTransferEnhancement/create/table/inputs/email/inputChip";

type handleAddRecipientType = (val: RecipientType[]) => void;

export const AddMoreRecipientButton = ({
  selectedRecipients,
  setSelectedRecipients,
  handleToTableBatch,
}: {
  selectedRecipients: RecipientType[] | [];
  setSelectedRecipients: Dispatch<SetStateAction<RecipientType[]>>;
  handleToTableBatch: (recipients: FormDataBatchProps[]) => void;
}) => {
  const { t } = useTranslation("local-transfer/revamp");

  const { isOpen, toggle } = useModalHook();
  const { push } = useRouter();
  const handleAddRecipient = (val: FormDataBatchProps[]) => {
    const dataLength = val?.length;
    const isMultipleRecipient = dataLength > 1;
    // if (typeof setSelectedRecipients == "function") setSelectedRecipients(val);
    setSelectedRecipients(val as unknown as RecipientType[]);
    if (isMultipleRecipient) {
      MIXPANELS.LOCAL_TRANSFER.LOCAL_SINGLE_ADD_BATCH();
      handleToTableBatch(val);
      return;
    }

    MIXPANELS.LOCAL_TRANSFER.LOCAL_SINGLE_ADD();
    push({ query: { step: "details" } });
  };

  return (
    <Fragment>
      <ButtonJack
        onClick={toggle}
        leftIcon={
          <JackIcons
            name="PeopleOutline"
            fill={jackColors.greenB9}
            style={{ height: "20px", width: "20px" }}
          />
        }
        className={styles["add-more-btn"]}
        rightIcon={
          <JackIcons
            name="PlusOutline"
            fill={"white"}
            styleDiv={{
              background: "rgba(255, 255, 255, 0.20)",
              border: "1px solid #E6E6E8",
              borderRadius: "16px",
              padding: "2px 8px",
            }}
            style={{ height: "20px", width: "20px" }}
          />
        }
        styleText={{
          color: "white",
          width: "100%",
          textAlign: "left",
        }}
      >
        {t("Click here to add more recipients")}
      </ButtonJack>
      <SimpleCenteredModal
        isOpen={isOpen}
        toggle={toggle}
        modalClassName={styles["simple-centered-modal"]}
        onClick={() => {}}
        leftHeaderComponent={
          <GothamMedium className="font20">
            {t("Select recipient(s)")}
          </GothamMedium>
        }
        woButtons
      >
        {/* <div
          className={styles["modal-header"]}
          style={{
            borderBottom: `1px solid ${jackColors.neutral500}`,
          }}
        >
          <GothamMedium className="font20">
            {t("Select recipient(s)")}
          </GothamMedium>
          <ModalCloseButton onClick={toggle} />
        </div> */}
        <div style={{ margin: "12px 20px 20px" }}>
          <UserList
            isModal
            isAddMore
            handleAddRecipient={
              handleAddRecipient as unknown as handleAddRecipientType
            }
            passedRecipientsFromParent={selectedRecipients}
            handleCancel={toggle}
          />
        </div>
      </SimpleCenteredModal>
    </Fragment>
  );
};

export const ConfirmSubmitModal = ({
  isOpen,
  toggle,
  transferDetail,
  handleClick,
  handleClickLeft,
  loading,
  forceDisable = false,
}: ConfirmModalProps) => {
  const { t } = useTranslation("local-transfer/revamp");
  const { account_number, bank_name, name, amount } = transferDetail;
  return (
    <SimpleCenteredModal
      onClick={handleClick}
      isOpen={isOpen}
      toggle={toggle}
      leftHeaderComponent={
        <GothamMedium className="font20">
          {t("Submit this request?")}
        </GothamMedium>
      }
      customStyle={{ width: 480 }}
      children={
        <div className={styles["modal-confirm"]}>
          <div className={styles["info-container"]}>
            <div className={styles["info-upper-card"]}>
              <JackIcons
                name="SendMoney"
                fill={"white"}
                style={{ height: 24 }}
                styleDiv={{
                  display: "flex",
                  width: "40px",
                  height: "40px",
                  padding: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100px",
                  background: "var(--Neutral-900, #343434)",
                }}
              />
              <div className={styles["upper-text-container"]}>
                <GothamMedium className="font12 text-black34">
                  {t("Local Transfer")}
                </GothamMedium>
                <GothamRegular className="font12 text-grey90" woFontColor>
                  {t("To")} {name}
                </GothamRegular>
              </div>
            </div>
            <Divider />
            <div className={styles["info-lower-card"]}>
              <div className={styles["info-lower-card-child"]}>
                <div>
                  <GothamRegular className="font12 text-grey6c" woFontColor>
                    {t("Recipient")}
                  </GothamRegular>
                </div>
                <RecipientCardSmall
                  name={name}
                  bankName={bank_name}
                  accountNumber={account_number}
                />
              </div>
              <div className={styles["info-lower-card-child"]}>
                <div>
                  <GothamRegular className="font12 text-grey6c" woFontColor>
                    {t("Amount")}
                  </GothamRegular>
                </div>
                <div>
                  <GothamMedium className="font12 text-black34" woFontColor>
                    IDR {amount}
                  </GothamMedium>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      customButtons={
        <div className={styles["modal-confirm-btn-container"]}>
          <ButtonJack
            children={t("Save as Draft")}
            type="outline"
            style={{ width: "100%" }}
            onClick={handleClickLeft}
            isLoading={loading || forceDisable}
          />
          <ButtonJack
            onClick={handleClick}
            children={t("Submit Request")}
            style={{ width: "100%" }}
            isLoading={loading || forceDisable}
          />
        </div>
      }
    />
  );
};
export const RecipientCardSmall = ({
  name = "",
  bankName = "",
  accountNumber = "",
  withAvatar = false,
}: {
  name: string;
  bankName: string;
  accountNumber: string;
  withAvatar?: boolean;
}) => {
  return (
    <div className="d-flex" style={{ gap: 8 }}>
      <div style={{ textAlign: "right" }}>
        <GothamRegular
          className={`font12 text-black34 ${styles["truncated-text"]}`}
          woFontColor
        >
          {name}
        </GothamRegular>
        <GothamRegular
          className="font10 text-grey90"
          woFontColor
          style={{ textAlign: "end", marginTop: "2px" }}
        >
          {bankName?.toUpperCase()} - {accountNumber}
        </GothamRegular>
      </div>
      {withAvatar && <Avatar name={name} size="small" />}
    </div>
  );
};

export type FormData = {
  "email-placeholder": string;
};

type TempData = { emails: string[]; ccEmails: string[] };
type ListenerPropType = { values: string[]; tempValue: string };
type HandleChangeProps = {
  email?: ListenerPropType;
  additional_emails?: ListenerPropType;
  error?: boolean;
};

export const MultiEmail = ({
  required = false,
  isOptional = true,
  useFormObj,
}: MultiEmailFormProps) => {
  const { isBahasa } = useLanguage();
  const { t } = useTranslation("local-transfer/revamp");

  const defaultHelper = (
    <GothamRegular className="font10 text-grey90" woFontColor>
      {t("You can notify up to 5 email addresses")}
    </GothamRegular>
  );
  const defaultPlaceholder = t(
    "Enter recipient’s email that will get the receipt."
  );
  const [stillTyping, setStillTyping] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [ccEmails, setCCEmails] = useState<string[]>([]);
  const [helperText, setHelperText] = useState<ReactNode>(defaultHelper);
  const [placeholder, setPlaceholder] = useState<ReactNode | string>(
    defaultPlaceholder
  );
  const [temp, setTemp] = useState<TempData>({
    emails: [],
    ccEmails: [],
  });

  const { isOpen, toggle } = useModalHook();
  const useFormObjPlaceholder = useForm();
  const { setValue, watch } = useFormObj;
  const defaultEmail = watch("email");
  const defaultCcEmails = watch("additional_emails");

  const handleClick = () => {};

  const handleChange = ({ email, error }: HandleChangeProps) => {
    const { values = [], tempValue } = email ?? {};
    if (!!values && tempValue == "") {
      setEmails(values);
    }
  };

  const typingHelperText = isBahasa ? (
    <GothamRegular className="font10 text-grey90" woFontColor>
      Tekan <TextInlineMedium>ENTER</TextInlineMedium> atau{" "}
      <TextInlineMedium>SPASI</TextInlineMedium> untuk simpan
    </GothamRegular>
  ) : (
    <GothamRegular className="font10 text-grey90" woFontColor>
      Press <TextInlineMedium>ENTER</TextInlineMedium> or{" "}
      <TextInlineMedium>SPACE</TextInlineMedium> to save
    </GothamRegular>
  );

  const handleChangeCC = ({
    additional_emails,
    error = false,
  }: HandleChangeProps) => {
    const { values: valuesCC = [], tempValue: tempValueCC } =
      additional_emails ?? {};

    if (error) {
      setHelperText(
        <GothamRegular className="font10" style={{ color: jackColors.redE7 }}>
          {t("Please enter a valid email address")}
        </GothamRegular>
      );
    } else if (!!tempValueCC && !error) {
      setStillTyping(true);
      setHelperText(typingHelperText);
      return;
    } else if (!!valuesCC && tempValueCC == "") {
      setCCEmails(valuesCC);
      setHelperText(defaultHelper);
    }
    setStillTyping(false);
  };

  const handleSave = () => {
    setValue("email", emails);
    setValue("additional_emails", ccEmails);
    setTemp((prev) => {
      return {
        ...prev,
        emails,
        ccEmails,
      };
    });
    toggle();
  };

  const allEmails = [...(temp["emails"] || []), ...(temp["ccEmails"] || [])];
  const firstTwoEmails = allEmails?.slice(0, 2).join(", ");
  const disableSave = stillTyping;

  const allEmailsWatcher = [
    ...(defaultEmail || []),
    ...(defaultCcEmails || []),
  ];
  const firstTwoEmailsWatcher = allEmailsWatcher?.slice(0, 2).join(", ");
  const disableCC = emails.length < 1;

  const chipProps = {
    style: {
      marginRight: 5,
      width: "fit-content",
      backgroundColor: colors.greye6,
      color: colors.grey6c,
      fontSize: "14px",
      lineHeight: "20px",
      borderRadius: 100,
      padding: 4,
      border: `1px solid ${jackColors.greyE6}`,
      background: jackColors.greyFC,
      height: "unset",
      opacity: disableCC ? 0.6 : 1,
    },
    deleteIcon: (
      <JackIcons
        name="Close"
        fill={jackColors.greyBB}
        style={{ height: 14, width: 14 }}
      />
    ),
  };

  const ChipLabel = ({ label }: { label: string }) => (
    <h1
      style={{
        color: jackColors.black34,
        fontFamily: "GothamBook",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
      }}
    >
      {label}
    </h1>
  );

  useEffect(() => {
    if (
      (!temp?.emails?.length && !temp?.ccEmails?.length) ||
      (!!temp?.ccEmails?.length && !temp?.emails?.length)
    ) {
      setPlaceholder(defaultPlaceholder);
      return;
    }
    setPlaceholder(
      <GothamRegular>
        {firstTwoEmails}{" "}
        <TextInlineRegular style={{ color: jackColors.grey6C }}>
          {allEmails?.length > 2
            ? ` +${allEmails.length - 2} ${t("more")}`
            : ""}
        </TextInlineRegular>
      </GothamRegular>
    );
  }, [temp?.emails, temp?.ccEmails]);

  useEffect(() => {
    if (!defaultEmail?.length && !defaultCcEmails?.length) return;
    setTemp({ emails: defaultEmail, ccEmails: defaultCcEmails });
  }, [defaultEmail, defaultCcEmails]);

  return (
    <Fragment>
      <TextFieldJack
        readOnly
        isOptional={isOptional}
        required={required}
        label="Email"
        name="email-placeholder"
        onClick={toggle}
        useFormObj={useFormObjPlaceholder}
        iconRight={
          <JackIcons
            name="EmailOutline"
            fill={jackColors.greyBB}
            style={{ height: 20 }}
          />
        }
        style={{ marginBottom: 0 }}
        isTransparent
        placeholder={placeholder}
      />
      <SimpleCenteredModal
        isOpen={isOpen}
        toggle={toggle}
        onClick={handleClick}
        leftHeaderComponent={
          <div className="d-flex flex-column" style={{ gap: 2 }}>
            <GothamMedium className="font20">{t("Input Email")}</GothamMedium>
            <GothamRegular className="text-grey90" woFontColor>
              {t("Enter recipient’s email that will get the transfer receipt.")}{" "}
            </GothamRegular>
          </div>
        }
        customStyle={{ width: 480 }}
        backdrop={"static"}
        children={
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
          >
            <div>
              <ChipLabel label={t("Recipient Email")} />
              <InputChip
                noLabel
                nameLeftIcon="Person"
                name="email"
                type="email"
                isSingle
                limit={1}
                isReset={false}
                disabled={false}
                placeholder={t("Enter email here")}
                listener={handleChange}
                defaultValues={temp.emails as never[]}
                customStyle={{ marginBottom: 0 }}
                showHelper
                chipProps={chipProps}
                customErrorStyle={{
                  position: "absolute",
                }}
              />
            </div>
            <div>
              <ChipLabel label={t("Send CC to")} />
              <InputChip
                nameLeftIcon="Person"
                name="additional_emails"
                noLabel
                type="email"
                limit={5}
                isReset={false}
                disabled={disableCC}
                placeholder={t("Enter email here")}
                listener={handleChangeCC}
                defaultValues={temp.ccEmails as never[]}
                showError={false}
                customStyle={{ marginBottom: 4 }}
                chipProps={chipProps}
              />
              {helperText}
            </div>
          </div>
        }
        customButtons={
          <div className={styles["modal-confirm-btn-container"]}>
            <ButtonJack
              onClick={toggle}
              children={t("Cancel")}
              type="outline"
              style={{ width: "100%" }}
            />
            <ButtonJack
              onClick={handleSave}
              children={t("Save")}
              style={{ width: "100%" }}
            />
          </div>
        }
      />
    </Fragment>
  );
};

export const AdjustableRecipientCard = ({
  recipient,
  handleChange: handleChangeRaw,
  passedFunction,
}: AdjustableRecipientCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickChange = () => {
    if (typeof passedFunction == "function") passedFunction();
    setAnchorEl(cardRef?.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (val: RecipientType[]) => {
    handleChangeRaw(val);
    handleClose();
  };

  return (
    <Fragment>
      <div ref={cardRef}>
        <RecipientCard
          recipient={recipient}
          onClickAction={handleClickChange}
          isBiggerFont
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: "438px",
            padding: "0 12px 0px",
            borderRadius: "4px",
            border: "0.5px solid var(--Neutral-500, #E6E6E8)",
            background: "var(--Neutral-100, #FFF)",
            boxShadow: "0px 8px 16px -4px rgba(22, 34, 51, 0.08)",
            marginTop: 10,
          },
        }}
      >
        <UserList
          woFooter
          woAddButton
          woMultipleSelect
          handleAddRecipient={handleChange}
          passedRecipientsFromParent={[recipient]}
        />
      </Popover>
    </Fragment>
  );
};
