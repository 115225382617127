// import { camelCase } from "change-case";
import { camelCase } from "lodash";
import dynamic from "next/dynamic";
import { forwardRef, useMemo } from "react";
import { jackImages } from "../constants/parent";
import { IconProps, JackIconProps, JackIconPropsWoName } from "./parent.types";
// if your image is not the one you want, try this steps:
// 1. adds "2" in your name. Eg: "book", change it to "book2", if its still not your img. Try "book3" and so on
// 2. If it doesnt exist, feel free to add it in images/jack. And import it into `jackImages` constant

// importing rule (if icon doesn't exist):
// Please follow figma's name. So every developer can guess it intuitively

// u can pass naming "camelCase", or "dash-case" or "snake_case", or even "normal text", JackIcons doesnt care.
// Whatever you type on `name` props will always be converted to camelCase

const images: Record<
  string,
  React.ComponentType<IconProps>
> = Object.fromEntries(
  jackImages.map((url) => [
    url,
    dynamic(() => import(`../../jackIcons/constants/images/${url}`), {
      loading: ({ isLoading }) => <div style={{ width: 24, height: 24 }}></div>,
    }),
  ])
);

const JackIcons = forwardRef<HTMLDivElement, JackIconProps>((propsRaw, ref) => {
  const {
    name,
    style = {},
    className = "",
    fill = "white",
    onClick,
    styleDiv = {},
    isStopBubbling = false,
    alignItems = "align-items-center",
    role,
    ...props
  } = propsRaw;

  const url = jackImages.find((key) => camelCase(key) === camelCase(name));
  const Icon = !!url ? useMemo(() => images[url], [url]) : null;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (isStopBubbling) event.stopPropagation();
    if (onClick) onClick(event);
  };

  const jackIconProps: JackIconPropsWoName = {
    className: `d-flex justify-content-center ${alignItems} ${className}`,
    onClick: handleClick,
    style: { ...styleDiv },
    ...props,
  };

  if (!!propsRaw["data-testid"]) {
    jackIconProps["data-testid"] = propsRaw["data-testid"];
  }
  if (!!propsRaw.role) {
    jackIconProps.role = propsRaw.role;
  }

  if (!Icon) return null;

  return (
    <div ref={ref} {...jackIconProps}>
      <Icon fill={fill} style={style} />
    </div>
  );
});

export default JackIcons;
