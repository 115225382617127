export const transactionsBooleans = (type) => {
  const isMine = type == "mine";
  const isTeam = type == "team";
  const isPayIn = type == "payin";
  const isPayOut = type == "payout";
  const isCompany = type == "company";
  const isFiltered = type == "filtered";
  const isStatement = type == "statement";
  const isAccountLedger = type == "account_ledger";
  const isGeneralLedger = type == "general_ledger";
  const isCardReport = type === "card";
  // const isCompany = !isMine && !isTeam;
  return {
    isTeam,
    isMine,
    isCompany,
    isPayIn,
    isPayOut,
    isFiltered,
    isStatement,
    isAccountLedger,
    isGeneralLedger,
    isCardReport,
  };
};
