import { useTranslation } from "react-i18next";
import styles from "./CategoryArticle.module.css";

const CategoryArticle = ({
  url = "https://support.itsjack.com/en/support/solutions/articles/150000142739-all-about-categories-feature",
}) => {
  const { t } = useTranslation("category/index");
  return (
    <a className={styles.categoryArticle} href={url} target="_blank">
      {t("this article.")}
    </a>
  );
};

export default CategoryArticle;
