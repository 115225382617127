import { colors } from "../../../assets/colors";

const Divider = () => {
  const style = {
    width: "100%",
    height: "1px",
    backgroundColor: colors.neutral500,
  };

  return <div style={style} />;
};

export default Divider;
