import { useRouter } from "next/router";
import { useEffect } from "react";
import { fetch } from "../../../../tools/api";
import { createQueryIds, invoiceFormatter } from "../../data/formatter";
import { invoicesValidation } from "../../data/parent";

export const useInvoiceCreateIndex = ({ uploadLength }) => {
  const { query } = useRouter();
  const {
    data,
    refetch: getData,
    setData,
  } = fetch({
    url: createQueryIds(query.id, false),
    defaultValue: {},
  });

  const { data: array = [], fee_on_index_created: feeConstant } = data || {};

  const uploadedData = invoicesValidation(invoiceFormatter(array));
  const uploadedDataLength = uploadedData?.length;
  const isUploading = uploadLength > 0 && uploadLength - uploadedDataLength > 0;

  useEffect(() => {
    if (query?.id) getData();
  }, [query?.id]);

  return {
    uploadedData,
    setData,
    feeConstant,
    isUploading,
    refetchIndex: getData,
  };
};
