import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { useRouter } from "next/router";
import { useState } from "react";
import { colors } from "../../../../../assets/colors";
import { eventsTracker } from "../../../../../universalFunctions/events";

const RadioButton = ({
  label,
  queryName,
  list,
  defaultValue,
  runQueryName = "runQuery",
  loading,
  fontClassName = "",
  customOnclick = () => {}
}) => {
  const { query, push } = useRouter();
  const { [queryName]: queryValue } = query;

  const [selectedValue, setSelectedValue] = useState(
    queryValue || defaultValue
  );
  const [hoveredValue, setHoveredValue] = useState(null);

  const onChange = (text) => {
    if (queryValue === text) return;
    let obj = {};
    obj[queryName] = text;
    push({ query: { ...query, [runQueryName]: false } });
    const result = { ...query, ...obj, [runQueryName]: true };
    push({ query: result });
    setSelectedValue(text);
  };

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  };

  const buttonStyle = {
    backgroundColor: "#fff",
    padding: "0px 8px",
    height: "32px",
    borderRadius: "32px",
    border: `1px solid ${colors.neutral500}`,
    cursor: "pointer",
    fontSize: "14px",
    transition: "all 0.2s ease-in-out",
  };

  const selectedButtonStyle = {
    backgroundColor: colors.neutral500,
    border: `1px solid ${colors.neutral900}`,
  };

  return (
    <div style={containerStyle}>
      <GothamRegular>{label}</GothamRegular>
      {list.map((item) => {
        const {disabled = false} = item
        return (
          <button
            disabled={disabled}
            key={item.value}
            onClick={() => {
              if (loading) return;
              onChange(item.value);
              customOnclick(item);
            }}
            onMouseEnter={() => setHoveredValue(item.value)}
            onMouseLeave={() => setHoveredValue(null)}
            style={{
              ...buttonStyle,
              ...(selectedValue === item.value ? selectedButtonStyle : {}),
              ...(hoveredValue === item.value
                ? { backgroundColor: colors.neutral500 }
                : {}),
            }}
          >
            <GothamMedium className={fontClassName}>{item.label}</GothamMedium>
          </button>
        );
      })}
    </div>
  );
};

export default RadioButton;
