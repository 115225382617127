import { colors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { Avatar } from "../../../../../components/Avatar";
import { TableJackSimple } from "../../../../../components/Table/parent";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { TransitionTr } from "../../../../../components/Transition";
import ViewportList from "react-viewport-list";
import Skeleton from "react-loading-skeleton";
import { fetch } from "../../../../../tools/api";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";
import { times } from "lodash";
import { Banner } from "../../../../../components/Banner";
import emptyIllustration from "../../../../../assets/jackImages/empty-search-illustration.svg";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { Trans, useTranslation } from "react-i18next";

const SkeletonTd = () => (
  <td>
    <Skeleton width={"90%"} />
  </td>
);

const MerchantLinked = () => {
  const { t } = useTranslation("credit/credit");
  const { headerTable, bodyTable, loading } = useMerchantLinkedTable();
  const { closeAdjacentModal } = useCardDetailContext();

  const isBodyTableEmpty = bodyTable.length <= 1;

  const styles = {
    container: {
      position: "relative",
      marginBottom: "52px",
      height: "100vh",
    },
    headerContainer: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
    },
    title: {
      fontSize: "24px",
      lineHeight: "32px",
      color: colors.neutral900,
    },
    subtitle: {
      fontSize: "12px",
      lineHeight: "16px",
      color: colors.neutral800,
      letterSpacing: "0.2px",
      marginLeft: "48px",
      marginTop: "8px",
    },
    table: {
      marginTop: "32px",
    },
    row: {
      cursor: "default",
    },
    placeholderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
      height: "calc(100vh - 290px)",
    },
    placeholderText: {
      fontSize: "14px",
      letterSpacing: "0.1px",
      color: colors.neutral800,
      lineHeight: "20px",
    },
    buttonContainer: {
      padding: "20px",
      borderTop: `1px solid ${colors.neutral500}`,
      margin: "0px -16px 0px",
      position: "absolute",
      width: "calc(100% + 32px)",
      bottom: "20px",
      backgroundColor: colors.neutral100,
    },
    button: {
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <Avatar mainIcon={<JackIcons name="card-renewal" />} type="square" />
        <GothamMedium style={styles.title}>
          {t("Merchants linked to card")}
        </GothamMedium>
      </div>
      <GothamRegular style={styles.subtitle}>
        <Trans i18nKey={t("dynamic.merchant_desc")} components={[<br />]} />
      </GothamRegular>
      <TableJackSimple
        headerTable={headerTable}
        style={styles.table}
        bodyComponent={
          <>
            {!isBodyTableEmpty ? (
              <ViewportList
                items={bodyTable}
                itemMinSize={100}
                margin={8}
                overscan={10}
              >
                {(tableRow, index) => {
                  return (
                    <TransitionTr
                      isQuick
                      counter={index}
                      perPage={20}
                      key={index}
                      style={styles.row}
                    >
                      <td key={index}>{tableRow}</td>
                    </TransitionTr>
                  );
                }}
              </ViewportList>
            ) : (
              <div style={styles.placeholderContainer}>
                <img src={emptyIllustration} />
                <GothamRegular style={styles.placeholderText}>
                  {t("You don’t have any merchants linked to this card")}
                </GothamRegular>
              </div>
            )}
            {loading &&
              times(5, (index) => (
                <tr key={index}>
                  {times(2, (index) => (
                    <SkeletonTd key={index} />
                  ))}
                </tr>
              ))}
          </>
        }
      />
      <div style={styles.buttonContainer}>
        <ButtonJack
          type="outline"
          style={styles.button}
          onClick={closeAdjacentModal}
        >
          {t("Back")}
        </ButtonJack>
      </div>
    </div>
  );
};

export default MerchantLinked;

const useMerchantLinkedTable = () => {
  const { t } = useTranslation("credit/credit");
  const { cardDetail } = useCardDetailContext();
  const { data: merchantList, loading } = fetch({
    url: `/cards/virtual_card/${cardDetail?.id}/top_merchant_list`,
    formatter: (data) => data?.merchant_list,
  });

  const headerTable = [
    {
      label: t("Top Merchant List"),
    },
  ];

  const bodyTable = merchantList?.map((merchant, index) => {
    const styles = {
      container: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
      },
      numberContainer: {
        display: "grid",
        placeItems: "center",
        borderRadius: "50%",
        width: "16px",
        height: "16px",
        backgroundColor: colors.neutral500,
      },
      number: {
        fontSize: "10px",
        color: colors.neutral900,
        lineHeight: "12px",
      },
      merchant: {
        letterSpacing: "0.1px",
        color: colors.neutral900,
      },
    };

    return (
      <div style={styles.container}>
        <div style={styles.numberContainer}>
          <GothamMedium style={styles.number}>{index + 1}</GothamMedium>
        </div>
        <GothamRegular style={styles.merchant}>{merchant}</GothamRegular>
      </div>
    );
  });

  bodyTable.push(
    <Banner msg="Don't forget to update other merchants if there are more." />
  );

  return {
    headerTable,
    bodyTable,
    loading,
  };
};
