import { useRouter } from "next/router";
import React from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Counter } from "../../../../components/Counter";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { capitalizeFirstLetter } from "../../../../pageComponents/bankAccountSettingComponents/tools/helpers";
import { NewFeature } from "../../../../pageComponents/categoryComponents/general/components";
import CustomHoverInfo from "components/CustomHoverInfo/CustomHoverInfo";
import { useActiveModules } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";

export const SidebarList = ({ array, onClick, isDropdown }) => {
  const { pathname } = useRouter();
  const { t: tCommon } = useTranslation("common");

  const {
    showInactiveModuleTooltip,
    handleHoverModuleOption,
    handleUnhoverModuleOption,
  } = useActiveModules();

  const handleContactUs = () => {
    window.open("https://wa.me/6285282051660", "_blank");
  };

  return array.map((item, index) => {
    const { array, title } = item;
    const isFirst = index == 0;
    return (
      <div
        style={{
          paddingTop: isDropdown ? 0 : isFirst ? 36 : 16,
          marginLeft: isDropdown ? 8 : 0,
          marginRight: isDropdown ? 8 : 0,
          borderTop: `1px solid ${jackColors.greyE6}`,
        }}
        key={index}
      >
        <GothamRegular
          className="font10"
          style={{ marginLeft: 8 + 24, color: jackColors.grey90 }}
        >
          {title}
        </GothamRegular>
        {array.map((item, index) => {
          if (!item) return null;
          const {
            iconName,
            text,
            number,
            isOrange,
            value,
            counterStyle,
            isDropdownButton,
            openDropdownButton,
            openDropdownLedgers,
            // setOpenDropdownButton,
            tag,
            isNew: isNewProps,
            onNavigate = () => {},
            customTextComponent,
            customOptionStyle,
            isDisabled,
          } = item;
          const isCustomText = !!customTextComponent;
          const isFirst = index == 0;
          const isLast = index == array.length - 1;
          const valueNotLink = !value.includes("/");

          const isActive = pathname.includes(value) && !valueNotLink;

          const canShowNumber = number != undefined;

          const noIcon = !iconName;
          const isNew = isNewProps || tag === "new";
          const isSoon = tag === "soon";
          const isDropdownOpen = openDropdownButton === value;

          // const handleClick = () => {
          //   if (isDropdownButton)
          //     return setOpenDropdownButton(!openDropdownButton);
          //   if (isSoon) return null;
          //   return onClick(value);
          // };

          const tagDecider = (text) => {
            if (!text) return null;

            const style = {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 4,
              width: "fit-content",
              height: 20,
              padding: "2px 8px",
              backgroundColor: isNew ? "#B9FC00" : jackColors.neutral400,
            };

            const textStyle = {
              fontSize: 12,
              lineHeight: "16px",
              color: isNew ? jackColors.neutral900 : jackColors.neutral600,
            };

            return (
              <div style={style}>
                <GothamMedium style={textStyle}>
                  {tCommon(capitalizeFirstLetter(text))}
                </GothamMedium>
              </div>
            );
          };

          return (
            <div
              id={`sidebar-${value}`}
              key={index}
              className="d-flex darkhover align-items-center justify-content-between"
              onClick={() => {
                if (isDropdownOpen) return onClick("closeDropdown");
                onClick(value);
                onNavigate();
              }}
              style={{
                height: 40,
                marginTop: isFirst ? 8 : 0,
                marginBottom: isLast ? 16 : 4,
                borderRadius: 4,
                backgroundColor: isActive ? jackColors.greyE6 : "",
                marginRight: isDropdown ? 0 : 24,
                marginLeft: isDropdown ? 0 : 24,
                paddingLeft: 8,
                paddingRight: 8,
                cursor: isSoon ? "not-allowed" : "pointer",
                position: "relative",
                ...customOptionStyle,
              }}
              onMouseEnter={() => handleHoverModuleOption(text)}
              onMouseLeave={handleUnhoverModuleOption}
            >
              <div className="d-flex align-items-center">
                <JackIcons
                  name={iconName}
                  fill={isActive ? jackColors.black34 : jackColors.grey6C}
                  style={{ marginRight: 8, height: 20, width: 20 }}
                />
                {noIcon && (
                  <div style={{ marginRight: 8, height: 20, width: 20 }} />
                )}
                {!isCustomText && (
                  <GothamRegular
                    style={{
                      color: isActive
                        ? jackColors.black34
                        : isSoon
                        ? jackColors.neutral600
                        : jackColors.grey6C,
                      fontFamily: isActive ? "GothamMedium" : "GothamBook",
                    }}
                    className="font12"
                  >
                    {text}
                  </GothamRegular>
                )}
                {isCustomText && customTextComponent}
              </div>
              {isNew && <NewFeature woHoverInfo />}
              {isSoon && <NewFeature woHoverInfo isSoon />}
              {canShowNumber && (
                <Counter
                  isOrange={isOrange}
                  number={number}
                  style={counterStyle}
                />
              )}
              {isDropdownButton && (
                <JackIcons
                  name={
                    openDropdownButton === value ? "chevron-up" : "chevron-down"
                  }
                  fill={isActive ? jackColors.black34 : jackColors.grey6C}
                  style={{ marginRight: 0, height: 20, width: 20 }}
                />
              )}
              {/* {tag && tagDecider(tag)} */}
              {isDisabled && (
                <CustomHoverInfo
                  show={showInactiveModuleTooltip === text}
                  style={{
                    width: "320px",
                    padding: "12px 8px",
                    translate: "220px 0px",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                  translateTrianglePoint="-8px 50px"
                  rotateTrianglePoint="0deg"
                  backgroundColor="white"
                >
                  <GothamMedium style={{ color: jackColors.neutral900 }}>
                    This service is temporarily disabled
                  </GothamMedium>
                  <GothamRegular
                    woFontColor
                    className="font12"
                    style={{ color: jackColors.neutral800 }}
                  >
                    Please contact us if you need any further details and
                    assistance.
                  </GothamRegular>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleContactUs}
                  >
                    <GothamMedium
                      style={{
                        color: jackColors.neutral900,
                        textDecoration: "underline",
                      }}
                    >
                      Contact us
                    </GothamMedium>
                    <JackIcons
                      name="arrow-forward"
                      fill={jackColors.neutral900}
                      style={{ scale: "0.8" }}
                    />
                  </div>
                </CustomHoverInfo>
              )}
              {isDisabled && (
                <JackIcons
                  name="alert-circle"
                  fill={jackColors.yellowFC}
                  style={{
                    scale: "0.8",
                    position: "absolute",
                    right: "8px",
                    top: "8px",
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  });
};
