import { JackIcons } from "../../../assets/jackIcons/parent";
import { GothamRegular } from "../../../components/Text";
import { colors } from "../../../assets/colors";

export const CardSummary = ({
  userName,
  maskedCardNumber,
  cardName,
  logo = "mastercard",
  logoPos = "right",
  containerStyle,
}) => {
  const maskedNumberWithName = `**${maskedCardNumber} - ${cardName}`;
  const isRightLogo = logoPos === "right";
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        marginLeft: "auto",
        alignItems: "center",
        justifyContent: "flex-end",
        ...containerStyle,
      }}
    >
      {!isRightLogo && <JackIcons name={logo} fill="#1434CB" />}
      <div>
        <GothamRegular
          style={{
            fontSize: "12px",
            marginBottom: "2px",
            color: colors.neutral900,
          }}
        >
          {userName}
        </GothamRegular>
        <GothamRegular
          style={{
            color: colors.neutral700,
            marginTop: "-4px",
            fontSize: "10px",
          }}
        >
          {maskedNumberWithName}
        </GothamRegular>
      </div>
      {isRightLogo && <JackIcons name={logo} fill="#1434CB" />}
    </div>
  );
};
