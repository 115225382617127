import { useRouter } from "next/router";
import { useState, useMemo, useCallback } from "react";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { InvoicePaymentTableJack } from "../../tableJack";
import {
  getTotalAmountSummary,
  pluralize,
  stringysort,
} from "../../data/formatter";
import { apiBusiness, useMutation } from "../../../../tools/api";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import backInvoiceModal from "../../../../assets/images/back-invoice-modal.svg";
import { useSubscriptionUsage } from "../../../subscriptionsJackComponents/logics/general-hooks";
import { useModalHook } from "../../../../components/Modals";
import closeBillPayment from "../../../../assets/images/close-bill-payment.svg";
import { confirmIcon } from "../../../reimbursementComponents/data/constant";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../../../categoryComponents/general/hooks";
import { useInvoiceCreateIndex } from "./hooks";
import { useTranslation } from "react-i18next";
import { useLanguage } from "public/locales/translationFunctions";
import dynamic from "next/dynamic";

const InvoiceFormEditModal = dynamic(() =>
  import("../modals/formEdit").then((mod) => mod.InvoiceFormEditModal)
);
const UploadInvoiceModal = dynamic(() =>
  import("../../modal").then((mod) => mod.UploadInvoiceModal)
);
const SubmitConfirmModal = dynamic(() =>
  import("../modals/submitConfirmModal").then((mod) => mod.SubmitConfirmModal)
);
const ConfirmationModalJack = dynamic(() =>
  import("../../../../components/ButtonsJack/confirmation").then(
    (mod) => mod.ConfirmationModalJack
  )
);
const PreventionModal = dynamic(() =>
  import(
    "../../../subscriptionsJackComponents/general-components/PreventionModal/parent"
  ).then((mod) => mod.default)
);
const SummaryLimit = dynamic(() =>
  import(
    "../../../subscriptionsJackComponents/general-components/SummaryLimit/parent"
  ).then((mod) => mod.default)
);

const InvoiceCreateHeader = ({ addInvoices, isSelect = false }) => {
  const { t } = useTranslation("invoice-payment/invoice-payment");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: 52,
        marginBottom: 32,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <GothamMedium className="font24" style={{ color: jackColors.black34 }}>
          {isSelect
            ? t("Select ready invoice to submit for approval")
            : t("Review and complete invoice details")}
        </GothamMedium>
        <GothamRegular style={{ color: colors.grey6c }}>
          {isSelect
            ? t("You may only select invoices that are ready to submit.")
            : t(
                "Make sure to fill in any missing details and review your invoice before submitting it for approval."
              )}
        </GothamRegular>
      </div>
      {!isSelect && (
        <ButtonJack
          leftIcon={<JackIcons name="plus_Outline" fill="#343434" />}
          type="outline"
          onClick={addInvoices}
          isSmall={true}
          style={{ backgroundColor: "transparent" }}
        >
          {t("Add Invoice")}
        </ButtonJack>
      )}
    </div>
  );
};

export const SummarySheetContainer = ({
  children,
  isSmall = false,
  additionalComponent = false,
}) => {
  const classNameDecider = isSmall
    ? "summary-sheet-small"
    : "summary-sheet-responsive";

  return (
    <div className={classNameDecider}>
      {additionalComponent && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 4,
            minHeight: 24,
            transform: "translate(0,-100%)",
            borderRadius: "12px 12px 0px 0px",
            backgroundColor: jackColors.greenB9,
            width: "100%",
          }}
        >
          {additionalComponent}
        </div>
      )}
      {children}
    </div>
  );
};

export const GreenLoadingInfo = ({
  uploadLength,
  uploadedDataLength,
  product = "invoice",
}) => {
  const { t } = useTranslation("common");
  const { isBahasa: isID } = useLanguage();
  const startUploadText = isID
    ? `Memproses ${uploadLength} ${product}`
    : `Processing ${uploadLength} ${pluralize(uploadLength, product)}`;
  const isReimbursement = product == "file";
  const remainingLength = isReimbursement
    ? uploadLength
    : uploadLength - uploadedDataLength;

  const uploadedText = isID
    ? `Memproses ${remainingLength} ${product} lainnya`
    : `Processing ${remainingLength} remaining ${pluralize(
        remainingLength,
        product
      )}`;
  return (
    <div className="d-flex" style={{ padding: 4 }}>
      <div
        style={{
          position: "relative",
          width: 16,
          height: 16,
          marginRight: 4,
        }}
        className="loading-bank"
      >
        <JackIcons
          fill="#343434"
          name="loader_Outline"
          style={{
            height: 16,
            width: "auto",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
      </div>
      <div className="d-flex-column">
        <GothamMedium className="font12">
          {uploadedDataLength > 0 ? uploadedText : startUploadText}
        </GothamMedium>
        {uploadedDataLength ? (
          <GothamRegular className="font12">
            {t(
              "While you wait, take some time to complete the missing details first."
            )}
          </GothamRegular>
        ) : null}
      </div>
    </div>
  );
};

const threeDotsData = [
  {
    label: "Submit Invoice",
    name: "submit",
    value: "submit",
    icon: "invoice_payment",
    fillIcon: jackColors.neutral900,
  },
  {
    label: "Edit",
    name: "editIp",
    value: "edit",
    icon: "edit_outline",
    fillIcon: jackColors.neutral900,
  },
  {
    label: "Move to Draft",
    name: "draft",
    value: "saveDraft",
    icon: "inbox_outline",
    fillIcon: jackColors.neutral900,
  },
  {
    label: "Delete",
    name: "delete",
    value: "delete",
    icon: "delete",
    fillIcon: colors.redDC,
  },
];

export const InvoiceCreateIndex = ({ uploadLength, onDelete, onUpload }) => {
  const { query, push } = useRouter();

  const { successSnackBar, errorSnackBar, errorToasterApi } = ToasterHook();
  const { t } = useTranslation("reimbursement/reimbursement");
  const { t: tCommon } = useTranslation("common");
  const [upload, toggleUpload] = useState(false);
  const [details, setDetails] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [singleSubmitId, setSingleSubmitId] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [saveDraftData, setSaveDraftData] = useState({});
  const [selectState, setSelectState] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const {
    feeConstant,
    isUploading: uploadFlag,
    setData,
    uploadedData,
    refetchIndex,
  } = useInvoiceCreateIndex({ uploadLength });

  const {
    isOpen: isOpenPreventionModal,
    open: openPreventionModal,
    close: closePreventionModal,
  } = useModalHook();
  const {
    isOpen: isOpenCancelModal,
    open: openCancelModal,
    close: closeCancelModal,
  } = useModalHook();
  const { isOpen: openConfirm, toggle: toggleConfirm } = useModalHook();
  const { isOpen: openConfirmDelete, toggle: toggleDelete } = useModalHook();

  //subscriptions
  const { invoice_qty_left, isSeedPlan, loadingSubscriptionUsage } =
    useSubscriptionUsage();
  const isV2SeedPlan = invoice_qty_left != null && isSeedPlan;
  const isReachedV2SeedLimit =
    isV2SeedPlan && (uploadedData?.length ?? 0) >= (invoice_qty_left ?? 0);

  const defaultSelectedIds =
    uploadedData
      ?.filter(({ check_is_complete }) => check_is_complete)
      ?.map(({ id }) => id) ?? [];

  const setSelectedAndVerify = useCallback(
    (newDatas) => {
      if (!newDatas) return;

      const sidStringify = stringysort(selectedIds);
      const newDatasStringify = stringysort(newDatas);
      if (sidStringify == newDatasStringify) return;
      const allIds = uploadedData?.map(({ id }) => id);
      return setSelectedIds(
        newDatas.filter((newData) => allIds.includes(newData))
      );
    },
    [selectedIds, uploadedData]
  );

  const defaultTryCatch = async (func) => {
    try {
      await func();
    } catch (error) {
      errorToasterApi(error);
    }
  };

  const handleAddQuery = (arrayOfIds) => {
    const oldQuery = () => {
      if (!query.id) return [];
      if (typeof query.id == "string") return [query.id];
      if (Array.isArray(query?.id)) return query.id;
      return [];
    };

    push({
      pathname: `/invoice-payment/create`,
      query: { id: [...arrayOfIds, ...oldQuery()], step: "review" },
    });
  };

  const handleRemove = (selectedId) => {
    const arrayIds = Array.isArray(query.id) ? query.id : [query.id];
    arrayIds?.forEach((id) => onDelete(id));
    let newQuery = [];
    if (Array.isArray(selectedId)) {
      newQuery = arrayIds
        ?.map((id) => +id)
        ?.filter((id) => !selectedId?.includes(id));
    } else {
      newQuery = arrayIds?.filter((id) => id != selectedId);
    }

    if (!newQuery?.length) setData({});
    return push({
      pathname: "/invoice-payment/create",
      query: { step: "review", id: newQuery },
    });
  };

  const { mutation: submitForApproval, loading: submitInvoicesLoading } =
    useMutation({
      url: "/invoice_transactions/change_to_waiting_approval",
      afterSuccess: async ({ data: resData }) => {
        const { data } = resData;
        const submitLength = data?.length;
        const isSingleSubmit = submitLength == 1;
        const ids = data?.map(({ id }) => id);
        handleRemove(ids);
        setSelectedIds([]);
        setSelectState(false);
        setSummaryModal(false);
        setSingleSubmitId(false);
      },
      handleError: (err) => {
        setSelectedIds([]);
        setSummaryModal(false);
        setSelectState(false);
        setSingleSubmitId(false);
        console.log(err);
      },
    });

  const { mutation: submitAndRedirect, loading: anotherSubmitLoading } =
    useMutation({
      url: "/invoice_transactions/change_to_waiting_approval",
      afterSuccess: async ({ data: resData }) => {
        const { data } = resData;
        const ids = data?.map(({ id }) => id);
        push({
          pathname: "/success",
          query: { type: "invoice", id: ids },
        });
      },
      handleError: (err) => {
        errorToasterApi(err);
        console.log(err);
      },
    });

  const handleSaveDraft = ({ id, invoice_number }) => {
    successSnackBar({
      title: "Succes Change State!",
      msg: `Bill payment ${invoice_number} has been sent to draft`,
    });
    handleRemove(id);
    if (query?.id == id) push("/dashboard");
    return;
  };

  const handleDelete = () => {
    defaultTryCatch(async () => {
      await apiBusiness.delete(`/invoice_transactions/${deleteId}`);
      successSnackBar({
        msg: t("Bill payment transaction successfully deleted"),
      });
      onDelete();
      handleRemove(deleteId);
      if (query?.id == deleteId) push("/dashboard");
    });
    return;
  };

  const handleClick = async (item) => {
    const { id, type, arrayItem, is_duplicate, invoice_number, isSelect } =
      item;
    const navigateToDetail = (id) => {
      return setDetails(id);
    };

    if (type == "singleSubmit") {
      const ids = [singleSubmitId];
      defaultTryCatch(async () => {
        await apiBusiness.put(
          "/invoice_transactions/change_to_waiting_approval",
          { ids }
        );
        successSnackBar({
          msg: "You have submitted payment request for invoice",
        });

        handleRemove(id);
      });
      setSelectedIds([]);
      return;
    }
    if (arrayItem) {
      if (selectState) return;
      const { value } = arrayItem;
      if (value == "edit") return navigateToDetail(id);

      if (value == "delete") {
        setDeleteId(id);
        return toggleDelete();
      }
      if (value == "saveDraft") {
        setSaveDraftData({ id, invoice_number });
        return toggleConfirm();
      }
      if (value == "submit") {
        setSelectedIds([id]);
        setSingleSubmitId(true);
        return setSummaryModal(true);
      }
    }
    if (type === "invoice_payment") {
      if (selectState) return;
      return navigateToDetail(id);
    }

    if (type === "back") {
      if (tableArray?.length > 0) return setOpenBackConfirmModal(true);
      return push("/invoice-payment");
    }
    if (type === "upload") {
      tableArray?.length == 10
        ? errorSnackBar(
            "You have reached the limit",
            "You have already uploaded 10 invoices. Remove one or upload it on the next batch."
          )
        : toggleUpload(true);
    }
    if (type === "submit") {
      const ids = selectedIds;
      if (selectedIds?.length == uploadedData?.length && !singleSubmitId)
        return submitAndRedirect({ ids });

      return submitForApproval({ ids });
    }
    if (type === "showCheckbox") {
      if (isSelect) return setSummaryModal(true);
      return setSelectState(true);
    }
    if (type === "backOrCancel") {
      if (isSelect) return setSelectState(false);
      return setOpenConfirmationModal(true);
    }
    if (type == "fastSingleSubmit") {
      setSelectedIds([id]);
      setSummaryModal(true);

      return;
    }
  };

  const handleAddInvoices = () => {
    if (loadingSubscriptionUsage || uploadFlag) return;
    if (isReachedV2SeedLimit) return openPreventionModal();
    toggleUpload(true);
  };

  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory } = useCategoryFlag();

  const summaryDataExtractor = useMemo(() => {
    const allData = uploadedData;
    const selectedData = allData?.filter(({ id }) => selectedIds?.includes(id));
    const disableSubmit =
      selectedData?.some(({ check_is_complete, category_id }) => {
        const isMissingRequiredCategory =
          isRequiredCategory && !category_id && hasCategoryList;
        return check_is_complete == false || isMissingRequiredCategory;
      }) || selectedData?.length == 0;

    const someDataIsComplete = allData?.length
      ? allData?.some(({ check_is_complete }) => check_is_complete == true)
      : false;

    return { allData, selectedData, disableSubmit, someDataIsComplete };
  }, [uploadedData, selectedIds]);

  const additionalComponentDecider = useCallback(() => {
    if (uploadFlag) {
      return (
        <GreenLoadingInfo
          uploadLength={uploadLength}
          uploadedDataLength={uploadedData?.length}
        />
      );
    }

    if (isV2SeedPlan) {
      return (
        <SummaryLimit
          used={uploadedData?.length ?? 0}
          limit={invoice_qty_left ?? 0}
          onExplore={openCancelModal}
        />
      );
    }

    return null;
  }, [uploadFlag, isV2SeedPlan, uploadLength, uploadedData, invoice_qty_left]);

  const additionalComponent = additionalComponentDecider();

  return (
    <div style={{ padding: "0px 32px 32px 32px" }}>
      <InvoiceCreateHeader
        addInvoices={handleAddInvoices}
        isSelect={selectState}
      />
      <InvoicePaymentTableJack
        tableType="create"
        bodyArr={uploadedData}
        selection={threeDotsData}
        UploadingLength={uploadLength}
        onClick={handleClick}
        showCheckBox={selectState}
        setSelectedIds={setSelectedAndVerify}
        defaultSelectedIds={defaultSelectedIds}
        isLoading={uploadFlag || true}
      />
      <SummarySheetContainer additionalComponent={additionalComponent}>
        <InvoiceSummarySheet
          data={summaryDataExtractor}
          fee={feeConstant}
          handleClick={handleClick}
          isSelect={selectState}
          isLoading={loadingSubscriptionUsage}
        />
      </SummarySheetContainer>
      <InvoiceFormEditModal
        detailId={details}
        toggle={() => setDetails(null)}
        refetchMain={refetchIndex}
      />
      <UploadInvoiceModal
        isOpen={upload}
        toggle={() => toggleUpload(false)}
        onUpload={onUpload}
        uploadQuota={10 - uploadedData?.length}
      />
      <SubmitConfirmModal
        isOpen={summaryModal}
        data={summaryDataExtractor}
        toggle={() => {
          if (singleSubmitId) setSelectedIds([]);
          setSummaryModal(false);
          setSingleSubmitId(false);
        }}
        onClickSubmit={() => handleClick({ type: "submit" })}
        loading={submitInvoicesLoading || anotherSubmitLoading}
        fee={feeConstant}
      />
      <ConfirmationModalJack
        modal={openConfirmationModal}
        isCentered={true}
        img={backInvoiceModal}
        title={t("Go back to the previous page?")}
        text={t(
          "Going back will erase your progress and require you to upload the invoices again."
        )}
        buttonTextLeft={tCommon("Cancel")}
        buttonTextRight={tCommon("Back")}
        toggle={() => setOpenConfirmationModal(false)}
        onClick={() => push("/invoice-payment/create?step=upload")}
      />
      <ConfirmationModalJack
        modal={openConfirm}
        isCentered={true}
        img={confirmIcon.draftIcon}
        title={t("Move this request to draft?")}
        text={t("You can always visit Draft to edit and submit this request.")}
        buttonTextLeft={tCommon("Back")}
        buttonTextRight={tCommon("Move to Draft")}
        toggle={() => {
          setSaveDraftData({});
          toggleConfirm();
        }}
        onClick={() => handleSaveDraft(saveDraftData)}
      />
      <ConfirmationModalJack
        img={confirmIcon.tongSampah}
        modal={openConfirmDelete}
        isCentered={true}
        title={t("Delete this request?")}
        text={t("You will delete this request from the list.")}
        buttonTextLeft={t("Back")}
        buttonTextRight={t("Delete")}
        toggle={() => {
          setDeleteId(null);
          toggleDelete();
        }}
        onClick={handleDelete}
      />
      <PreventionModal
        isOpen={isOpenPreventionModal}
        onClick={openCancelModal}
        toggle={closePreventionModal}
      />
      <ConfirmationModalJack
        img={closeBillPayment}
        modal={isOpenCancelModal}
        isCentered={true}
        title="Leave Bill Payment?"
        text="Leaving means losing all progress made."
        buttonTextLeft="Cancel"
        buttonTextRight="Leave"
        toggle={() => closeCancelModal(false)}
        onClick={() => push("/plans/showcase")}
      />
    </div>
  );
};

const InvoiceSummarySheet = ({
  handleClick,
  isSelect = false,
  data,
  fee = 0,
  isLoading = false,
}) => {
  const { allData, selectedData, disableSubmit, someDataIsComplete } =
    data || {};
  const { isBahasa: isID } = useLanguage();
  const { t } = useTranslation("invoice-payment/invoice-payment");

  const totalLength = allData?.length;
  const selectedLength = selectedData?.length;
  const lengthForDisplay = isSelect ? selectedLength : totalLength;

  const { totalAmount } = getTotalAmountSummary(allData);
  const { totalAmount: totalAmountSelected } =
    getTotalAmountSummary(selectedData);
  const feeFormatted = `IDR ${formatCurrencyNoDecimal(fee)}`;
  const totalDataFee = Number(fee) * totalLength;
  const selectedFee = Number(fee) * selectedLength;
  const totalAmountIdrAll = `IDR ${formatCurrencyNoDecimal(
    Number(totalAmount)
  )}`;
  const totalAmountIdrSelected = `IDR ${formatCurrencyNoDecimal(
    Number(totalAmountSelected)
  )}`;

  const isSingleLength = totalLength == 1;

  const submitText = (function () {
    if (isID) {
      return isSelect
        ? `Ajukan yang Dipilih (${selectedLength})`
        : isSingleLength
        ? "Ajukan untuk Persetujuan"
        : "Pilih untuk Ajukan";
    }
    return isSelect
      ? `Submit Selected (${selectedLength})`
      : isSingleLength
      ? "Submit for Approval"
      : "Select to Submit";
  })();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", gap: 20, height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <GothamRegular style={{ fontSize: 12, color: "#FFFFFF" }}>
            {isSelect ? t("Selected Invoice(s)") : t("Number of Invoice")}
          </GothamRegular>
          <GothamRegular style={{ fontSize: 16, color: "#FFFFFF" }}>
            {lengthForDisplay}{" "}
            {isID ? "Invoice" : pluralize(lengthForDisplay, "Invoice")}
          </GothamRegular>
        </div>
        <div style={{ height: "100%", width: 1, backgroundColor: "#FFFFFF" }} />
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <GothamRegular style={{ fontSize: 12, color: "#FFFFFF" }}>
            Handling Fee
          </GothamRegular>
          <GothamRegular style={{ fontSize: 16, color: "#FFFFFF" }}>
            {feeFormatted} x {lengthForDisplay}
          </GothamRegular>
        </div>
        <div style={{ height: "100%", width: 1, backgroundColor: "#FFFFFF" }} /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <GothamRegular style={{ fontSize: 12, color: "#FFFFFF" }}>
            {t("Total Amount")}
          </GothamRegular>
          <GothamRegular style={{ fontSize: 16, color: "#FFFFFF" }}>
            {isSelect ? totalAmountIdrSelected : totalAmountIdrAll}
          </GothamRegular>
        </div>
      </div>
      <div style={{ display: "flex", gap: 12 }}>
        <ButtonJack
          leftIcon={!isSelect && <JackIcons name="arrow_back_outline" />}
          type="outline"
          style={{
            backgroundColor: "transparent",
            color: "#FFFFFF",
            minWidth: 58,
          }}
          classNameText="text-white"
          onClick={() =>
            handleClick({
              type: "backOrCancel",
              isSelect,
            })
          }
        >
          {isSelect ? t("Cancel") : t("Back")}
        </ButtonJack>
        <ButtonJack
          style={{ minWidth: 56 }}
          onClick={() =>
            handleClick({
              type: isSingleLength ? "fastSingleSubmit" : "showCheckbox",
              isSelect,
              id: allData?.[0]?.id,
            })
          }
          disabled={
            (isSelect ? disableSubmit : !someDataIsComplete) || isLoading
          }
        >
          {submitText}
        </ButtonJack>
      </div>
    </div>
  );
};
