import React, { useContext, useEffect, useState } from "react";
import { TableJackSimple } from "../../../components/Table/parent";
import ViewportList from "react-viewport-list";
import { TransitionTr } from "../../../components/Transition";
import { tableFormer } from "../tools/helpers";
import { useLayout } from "../../../contexts/Layout/parent";
import { DraftSearchAndFilter, SkeletonTd } from "../commonComponents/parents";
import { SmartActivityModal } from "../../../modals/smartActivityModal/parent";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { times } from "lodash";
import { useRouter } from "next/router";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { useDeleteRow } from "../tools/useDeleteRow";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import deleteImage from "../../../assets/images/delete-trash-grey.svg";
import { colors, jackColors } from "../../../assets/colors";
import MarkAsDeleteSummary from "../tools/MarkAsDeletedSummary";
import { DraftContext } from "../parent";
import { useAutoOpen } from "../../notifications/useNotifications";
import { useTranslation } from "react-i18next";
import { isID } from "@tools";

export const DraftTabPage = ({
  page,
  modalType,
  skeletonAmount,
  user_id /* selectedItem, setSelectedItem */,
}) => {
  // States
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDelete, setShowDelete] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { toBeDeleted, setToBeDeleted } = useContext(DraftContext);

  // Constants
  const { canRefetch } = useLayout();
  const { query, push, isReady, locale } = useRouter();
  const menuForTable = Object.keys(query).length ? query.menu : page;
  const { headerArr, tbodyRef, bodyTable, nextPage, loading, afterDelete } =
    tableFormer({ menuForTable, user_id });
  const currentBody = bodyTable ? bodyTable : [];
  const isEmptyArray = !bodyTable.length;
  const { mutationDeleteDraft } = useDeleteRow({
    page: menuForTable,
    deleteId,
    afterSuccess: () => setSelectedItem(null),
  });
  const hideCheckBox = toBeDeleted.length < 1;
  const { t } = useTranslation("draft/draft");
  const handleDelete = (id) => {
    setOpenConfirmationModal(true);
    setDeleteId(id);
  };

  // handles auto open from notif
  useAutoOpen(setSelectedItem);
  // useEffect(() => {
  //   const { id, type, isOpen } = query ?? {};
  //   if (id && isOpen) {
  //     setSelectedItem({ originator_id: id, type });
  //     delete query?.isOpen;
  //     push({ query });
  //   }
  // }, [query?.id, query?.type, query?.isOpen]);

  useEffect(() => {
    if (!canRefetch) return;
    nextPage();
  }, [canRefetch]);

  useEffect(() => {
    if (!isReady) return;
    if (Object.keys(query).length !== 0 || query.menu) return;
    push("draft?menu=local_transfer");
  }, [isReady]);

  useEffect(() => {
    if (!deleteId) return;
    if (!deleteConfirm) return;
    mutationDeleteDraft();
    setTimeout(() => {
      afterDelete();
    }, 1000);
    setDeleteConfirm(false);
    setDeleteId(null);
  }, [deleteId, deleteConfirm]);

  return (
    <div style={{ marginTop: 32, marginLeft: 32, marginRight: 32 }}>
      {/* Filter */}
      <DraftSearchAndFilter />

      {/* Main Table */}
      {isEmptyArray && !loading ? (
        <div style={{ padding: 40 }} className="d-flex justify-content-center">
          <div className="w-100">
            <JackIcons
              name="archive"
              style={{ height: 60, width: 60 }}
              fill={jackColors.black34}
            />
            <GothamMedium
              style={{
                textAlign: "center",
                color: jackColors.black34,
                marginTop: 16,
              }}
            >
              {t("There’s no transaction yet")}
            </GothamMedium>
            {locale === "id" && (
              <GothamRegular
                style={{
                  textAlign: "center",
                  marginTop: 8,
                  color: jackColors.grey6C,
                }}
              >
                Belum ada draft yang perlu anda selesaikan.
              </GothamRegular>
            )}
          </div>
        </div>
      ) : (
        <TableJackSimple
          headerTable={headerArr}
          tbodyRef={tbodyRef}
          bodyComponent={
            <>
              <ViewportList
                viewportRef={tbodyRef}
                items={currentBody}
                itemMinSize={100}
                margin={8}
                overscan={10}
              >
                {({ rawData, tableRow }, index) => {
                  return (
                    <TransitionTr
                      isQuick
                      counter={index}
                      perPage={15}
                      key={index}
                      onMouseEnter={() => setShowDelete(index)}
                      onMouseLeave={() => setShowDelete(null)}
                    >
                      {showDelete == index || !hideCheckBox ? (
                        <td>{tableRow[0]}</td>
                      ) : (
                        <td></td>
                      )}
                      {tableRow?.slice(1).map((item, indexTd) => {
                        return (
                          <td
                            key={indexTd}
                            onClick={() => {
                              setSelectedItem(rawData);
                            }}
                          >
                            {item}
                          </td>
                        );
                      })}
                      <td style={{ display: "flex", justifyContent: "center" }}>
                        {showDelete == index && !toBeDeleted.length && (
                          <JackIcons
                            name="delete-outline"
                            fill={colors.neutral600}
                            onClick={() => handleDelete(rawData.id)}
                          />
                        )}
                      </td>
                    </TransitionTr>
                  );
                }}
              </ViewportList>
              {loading &&
                times(10, (index) => (
                  <tr key={index}>
                    {times(skeletonAmount, (index) => (
                      <SkeletonTd key={index} />
                    ))}
                  </tr>
                ))}
            </>
          }
        />
      )}
      <SmartActivityModal
        data={{ ...selectedItem, type: modalType }}
        isOpen={!!selectedItem}
        toggle={() => {
          setSelectedItem(null);
        }}
        handleDelete={handleDelete}
      />
      <ConfirmationModalJack
        modal={openConfirmationModal}
        isCentered={true}
        img={deleteImage}
        title={t("Delete this transaction?")}
        text={t("You will delete this transaction from draft.")}
        buttonTextLeft={t("Back")}
        buttonTextRight={t("Delete")}
        toggle={() => setOpenConfirmationModal(false)}
        onClick={() => setDeleteConfirm(true)}
      />
      <MarkAsDeleteSummary menu={menuForTable} afterSuccess={afterDelete} />
    </div>
  );
};
