import React, { useEffect, useState } from "react";
import { useModalHook } from "../../../components/Modals";
import { GothamRegular } from "../../../components/Text";
import { jackColors } from "../../../assets/colors";
import { SelectBusinessTypeModal } from "./modal";
import { JackIcons } from "../../../assets/jackIcons/parent";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

export const SelectionBusinessType = ({
  data = [],
  setBusinessType,
  loading,
  watchDestinationCountry,
}) => {
  const { t } = useTranslation("international/create");
  const isSomeB2B = data.some((item) => item === "B2B");
  const isSomeB2C = data.some((item) => item === "B2C");
  const dataRender = [
    ...(isSomeB2B
      ? [
          {
            title: t("Business/Institution"),
            value: "business",
          },
        ]
      : []),
    ...(isSomeB2C
      ? [
          {
            title: t("Individual/Personal"),
            value: "personal",
          },
        ]
      : []),
  ];

  const [selectedData, setSelectedData] = useState(false);
  const { isOpen, toggle, close } = useModalHook();
  const { title, iconPink } = selectedData || {};

  const handleClick = (val) => {
    const { value } = val;
    setSelectedData(val);
    setBusinessType(value);
    close();
  };

  useEffect(() => {
    setSelectedData(false);
    setBusinessType("");
  }, [watchDestinationCountry?.label]);

  // useEffect(() => {
  //   setSelectedData(dataRender[0]);
  // }, [data]);

  return (
    <div style={{ marginBottom: 32 }}>
      <SelectionTypeCrossBorder
        optionTitle={t("Recipient Type")}
        isOpen={isOpen}
        title={title}
        toggle={toggle}
        placeholder={t("Select recipient type")}
        loading={loading}
      />
      <SelectBusinessTypeModal
        isOpen={isOpen}
        close={close}
        toggle={toggle}
        data={dataRender}
        handleClick={handleClick}
        selectedData={selectedData}
      />
    </div>
  );
};

export const SelectionTypeCrossBorder = ({
  isOpen,
  optionTitle = "Recipient Type",
  title = "",
  placeholder = "",
  toggle,
  loading,
}) => {
  const [hover, setHover] = useState(false);
  const renderTitle = !!title ? title : placeholder;
  return (
    <div>
      <GothamRegular
        className="font12"
        style={{ marginBottom: 8, color: jackColors.neutral900 }}
      >
        {optionTitle}
      </GothamRegular>
      {loading ? (
        <Skeleton width={"100%"} height={42} />
      ) : (
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            cursor: "pointer",
            padding: "10px 12px",
            borderRadius: 4,
            border: `1px solid ${
              isOpen
                ? jackColors.neutral900
                : hover
                ? jackColors.neutral600
                : jackColors.greyE6
            }`,
            transition: "all 100ms",
          }}
          onClick={toggle}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className="d-flex align-items-center">
            <GothamRegular
              style={{
                color: !title && !!placeholder && jackColors.neutral600,
              }}
            >
              {renderTitle}
            </GothamRegular>
          </div>
          <JackIcons
            name="chevron-down"
            fill={jackColors.neutral900}
            style={{ width: 20, height: 20 }}
            className={`rotate-icon ${isOpen ? "down" : ""}`}
          />
        </div>
      )}
    </div>
  );
};
