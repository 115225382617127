import React, { useRef, useState } from "react";
import { colors } from "../assets/colors";
import { useModalHook } from "./Modals";
import { GothamBold, GothamRegular } from "./Text";

export const AdvancedHoverInfo = ({
  children,
  className = "",
  style,
  text,
  width = 320,
}) => {
  const { open, close, isOpen } = useModalHook();
  const ref = useRef();
  const { clientHeight: height } = ref?.current || {};

  const childRef = useRef();

  const { clientWidth: childWidth } = childRef?.current || {};

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          width,
          left: -width / 2,
          top: -height,
          visibility: isOpen ? "" : "hidden",
          padding: 12,
          zIndex: 9,
          borderRadius: 4,
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          opacity: isOpen ? 1 : 0,
          transition: "opacity 0.4s linear",
        }}
        ref={ref}
      >
        <GothamRegular className="font12" style={{ color: colors.grey6c }}>
          {text}
        </GothamRegular>
      </div>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            zIndex: 10,
            left: childWidth / 2 - 1,
          }}
        >
          <div
            className="hovered-info"
            style={{
              clipPath: "polygon(50% 0, 100% 100%, 0 100%)",
              rotate: "180deg",
              backgroundColor: "white",
              height: "9px",
              width: "13px",
            }}
          />
        </div>
      )}
      <div style={style} className={className} ref={childRef}>
        {children}
      </div>
    </div>
  );
};

const HoverInfo = ({
  isShow,
  left = "20px",
  top = "0px",
  width = "210px",
  height = "80px",
  info = "To increase the total limit, please contact our sales/AM",
  fontSize = "12px",
  color = "#6C6C71",
  pointerBottom = false,
  pointerTop = false,
  title,
  style,
}) => {
  const pointerDirectionDecider = () => {
    if (pointerBottom)
      return (
        <div
          style={{
            position: "absolute",
            left: width,
            top: height,
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            zIndex: 10,
          }}
        >
          <div
            className={isShow ? "hovered-info" : "unhovered-info"}
            style={{
              clipPath: "polygon(50% 0, 100% 100%, 0 100%)",
              rotate: "180deg",
              backgroundColor: "white",
              height: "9px",
              width: "13px",
            }}
          />
        </div>
      );

    if (pointerTop)
      return (
        <div
          style={{
            position: "absolute",
            left: +left.slice(0, -2) + +width.slice(0, -2) / 2,
            top: "-8px",
            zIndex: 10,
          }}
        >
          <div
            className={isShow ? "hovered-info" : "unhovered-info"}
            style={{
              clipPath: "polygon(50% 0, 100% 100%, 0 100%)",
              backgroundColor: "white",
              height: "9px",
              width: "13px",
            }}
          />
        </div>
      );

    return (
      <div
        style={{
          position: "absolute",
          left: left,
          top: top,
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          zIndex: 10,
        }}
      >
        <div
          className={isShow ? "hovered-info" : "unhovered-info"}
          style={{
            clipPath: "polygon(100% 0, 0 50%, 100% 100%)",
            backgroundColor: "white",
            height: "13px",
            width: "9px",
          }}
        />
      </div>
    );
  };

  return (
    <>
      {pointerDirectionDecider()}
      <div
        style={{
          position: "absolute",
          left: `calc(${left} + 9px)`,
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          zIndex: 9,
        }}
      >
        <div
          className={isShow ? "hovered-info" : "unhovered-info"}
          style={{
            height: height,
            width: width,
            backgroundColor: "white",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px",
            ...style,
          }}
        >
          <GothamBold>{title}</GothamBold>
          <GothamRegular
            style={{
              fontSize: fontSize,
              lineHeight: "16px",
              letterSpacing: "0.2px",
              color: color,
            }}
          >
            {info}
          </GothamRegular>
        </div>
      </div>
    </>
  );
};

export default HoverInfo;
