import { useState } from "react";
import { useMutation } from "../../../tools/api";
import { ToasterHook } from "../../../contexts/ToasterContext";
import useJackTransferStoredData from "./useJackTransferStoredData";
import { useRouter } from "next/router";
import { useLanguage } from "public/locales/translationFunctions";

const useSubmitJackTransfer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { push } = useRouter();

  const { errorSnackBar, errorToasterApi } = ToasterHook();

  const { removeJackTransferData } = useJackTransferStoredData();

  const { isBahasa } = useLanguage();

  const { mutation: mutationSubmitJackTransfer } = useMutation({
    method: "post",
    url: "/balance_transfers",
    withError: false,
    afterSuccess: (res) => {
      const data = res?.data?.data ?? {};
      removeJackTransferData();
      push({
        pathname: "/success",
        query: { id: data?.id, type: "balance_transfer" },
      });
      return;
    },
    handleError: (res) => {
      setIsLoading(false);
      const errorMessage = res?.message;

      const status = res?.response?.status;

      if (status == 422) return errorToasterApi(res);

      const isNotFoundError = errorMessage.split(" ").reverse()[0] === "404";

      if (isNotFoundError) {
        return errorSnackBar({
          msg: "Recipient not found.",
        });
      }

      return errorSnackBar({
        msg: "Oops, We're sorry there is a slight hiccup on our system",
      });
    },
  });

  const submitJackTransfer = (payload) => {
    setIsLoading(true);
    mutationSubmitJackTransfer(payload);
    return;
  };

  return { submitJackTransfer, isLoading };
};

export default useSubmitJackTransfer;
