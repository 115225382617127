import { useRouter } from "next/router";
import { DateInputJackState } from "../../../../../../../components/inputs/date/parent";
import { usePushQuery } from "../../../../../../../components/tools";
import { queryDate } from "./logics";

export const DateInputQuery = ({
  queryName,
  label,
  placeholder,
  disabled,
  customStyle,
  ...props
}) => {
  const { query } = useRouter();
  const { pushQuery } = usePushQuery();
  const value = queryDate(query, queryName);

  return (
    <DateInputJackState
      isModal
      value={disabled ? null : value}
      disabled={disabled}
      onChange={(val) => pushQuery(queryName, String(val))}
      label={label}
      style={{ marginLeft: 8, marginRight: 8, ...customStyle }}
      placeholder={placeholder}
      {...props}
    />
  );
};
