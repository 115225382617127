import { formatCurrencyNoDecimal } from "../../../tools";

const getTotalText = ({
  someTrxAreNotCovered = false,
  totalFeeRaw = 0,
  totalFreeTrx = 0,
  individualFeeUnformatted = 0,
}) => {
  if (someTrxAreNotCovered)
    return `= IDR ${formatCurrencyNoDecimal(
      totalFeeRaw - individualFeeUnformatted * totalFreeTrx
    )}`;

  return "= Free";
};

export default getTotalText;
