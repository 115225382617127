const CancelCreateTeamImage = () => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: "32px" }}
    >
      <path
        d="M100.751 49.5497C102.821 55.0697 103.951 61.0497 103.951 67.2897C103.951 73.3897 102.881 79.3597 100.771 85.0097L100.711 85.1597C98.3909 91.3297 94.9209 96.9297 90.4109 101.79L90.3209 101.89C88.9809 103.33 87.5609 104.68 86.0709 105.96C85.7009 106.28 85.3209 106.59 84.9409 106.9C84.3709 107.36 83.7809 107.81 83.1909 108.24C82.6009 108.67 82.0009 109.1 81.3909 109.51C73.0409 115.1 63.2809 118.06 53.1909 118.06C43.0909 118.06 33.3409 115.1 24.9909 109.51C24.1609 108.95 23.3409 108.37 22.5409 107.77C22.1409 107.47 21.7509 107.16 21.3609 106.85C20.3809 106.07 19.4409 105.24 18.5309 104.39C18.3509 104.22 18.1709 104.05 17.9809 103.87C17.8009 103.69 17.6209 103.52 17.4409 103.34C17.2609 103.16 17.0809 102.99 16.9109 102.81C16.5609 102.45 16.2109 102.09 15.8709 101.72L15.7809 101.62C14.2809 99.9997 12.9109 98.2897 11.6509 96.4997C11.5309 96.3297 11.4209 96.1597 11.3009 95.9997C10.9609 95.4997 10.6209 94.9997 10.3009 94.4797C9.8709 93.7997 9.4509 93.0997 9.0509 92.3997C8.5509 91.5197 8.0709 90.6297 7.6309 89.7197C7.3609 89.1797 7.1009 88.6197 6.8509 88.0697C6.6809 87.6997 6.5209 87.3297 6.3709 86.9497C6.1309 86.3897 5.9109 85.8197 5.6909 85.2497L5.6309 85.0997C3.5009 79.3997 2.4209 73.4197 2.4209 67.2897C2.4209 39.2997 25.1909 16.5297 53.1909 16.5297C59.0109 16.5297 64.6009 17.5097 69.8009 19.3197C72.7709 9.14973 82.1709 1.71973 93.3109 1.71973C106.841 1.71973 117.801 12.6897 117.801 26.2097C117.811 37.1397 110.641 46.3997 100.751 49.5497Z"
        fill="white"
      />
      <path
        d="M53.1904 118.28C43.0504 118.28 33.2504 115.31 24.8604 109.69C21.5204 107.45 18.4404 104.82 15.7004 101.85C11.2304 97.0098 7.79043 91.4498 5.47043 85.3098C3.30043 79.5698 2.19043 73.4998 2.19043 67.2898C2.19043 39.1698 25.0704 16.2998 53.1804 16.2998C81.2904 16.2998 104.17 39.1798 104.17 67.2898C104.17 73.4798 103.08 79.5198 100.92 85.2498C98.5704 91.4898 95.0604 97.1498 90.4904 102.05C87.8004 104.94 84.7804 107.51 81.5104 109.69C73.1304 115.31 63.3304 118.28 53.1904 118.28ZM53.1904 17.1498C25.5404 17.1498 3.05043 39.6398 3.05043 67.2898C3.05043 73.3998 4.13043 79.3598 6.27043 85.0198C8.55043 91.0498 11.9304 96.5198 16.3304 101.28C19.0204 104.2 22.0504 106.79 25.3304 108.99C33.5804 114.51 43.2104 117.43 53.1804 117.43C63.1504 117.43 72.7804 114.51 81.0304 108.99C84.2404 106.84 87.2104 104.31 89.8604 101.47C94.3604 96.6498 97.8104 91.0898 100.12 84.9498C102.24 79.3198 103.32 73.3798 103.32 67.2898C103.32 39.6398 80.8304 17.1498 53.1904 17.1498Z"
        fill="black"
      />
      <path
        d="M81.5203 109.699L81.0404 108.989C84.2504 106.839 87.2204 104.309 89.8704 101.469C94.2904 96.7293 97.6904 91.2793 100 85.2793C96.3204 83.0893 92.1104 81.9293 87.8004 81.9293C83.7704 81.9293 79.7803 82.9493 76.2703 84.8893L75.8604 84.1393C79.5004 82.1293 83.6304 81.0693 87.8004 81.0693C92.3904 81.0693 96.8704 82.3393 100.76 84.7293L101.06 84.9193L100.94 85.2493C98.5904 91.4893 95.0804 97.1393 90.5104 102.049C87.8104 104.939 84.7803 107.509 81.5203 109.699Z"
        fill="black"
      />
      <path
        d="M87.8 79.3393C80.74 79.3393 75 73.5993 75 66.5393C75 59.4793 80.74 53.7393 87.8 53.7393C94.86 53.7393 100.6 59.4793 100.6 66.5393C100.6 73.5893 94.86 79.3393 87.8 79.3393ZM87.8 54.5893C81.21 54.5893 75.85 59.9493 75.85 66.5393C75.85 73.1293 81.21 78.4893 87.8 78.4893C94.39 78.4893 99.75 73.1293 99.75 66.5393C99.74 59.9493 94.38 54.5893 87.8 54.5893Z"
        fill="black"
      />
      <path
        d="M24.8606 109.699C21.5106 107.459 18.4306 104.819 15.7006 101.859C11.2306 97.0293 7.79059 91.4593 5.47059 85.3193L5.35059 84.9893L5.65059 84.7993C9.57059 82.3593 14.0906 81.0693 18.7206 81.0693C22.8106 81.0693 26.8606 82.0893 30.4406 84.0093L30.0306 84.7593C26.5806 82.8993 22.6606 81.9193 18.7206 81.9193C14.3706 81.9193 10.1206 83.0993 6.41059 85.3293C8.69059 91.2393 12.0306 96.5893 16.3406 101.259C19.0206 104.169 22.0506 106.769 25.3406 108.969L24.8606 109.699Z"
        fill="black"
      />
      <path
        d="M18.7209 79.3393C11.6609 79.3393 5.9209 73.5993 5.9209 66.5393C5.9209 59.4793 11.6609 53.7393 18.7209 53.7393C25.7809 53.7393 31.5209 59.4793 31.5209 66.5393C31.5209 73.5893 25.7809 79.3393 18.7209 79.3393ZM18.7209 54.5893C12.1309 54.5893 6.7709 59.9493 6.7709 66.5393C6.7709 73.1293 12.1309 78.4893 18.7209 78.4893C25.3109 78.4893 30.6709 73.1293 30.6709 66.5393C30.6709 59.9493 25.3109 54.5893 18.7209 54.5893Z"
        fill="black"
      />
      <path
        d="M53.1907 118.28C43.0507 118.28 33.2507 115.31 24.8607 109.69C21.5207 107.45 18.4407 104.82 15.7007 101.85L15.5107 101.64L15.6207 101.38C18.6807 94.3696 23.6507 88.3696 29.9807 84.0296C36.7807 79.3596 44.7607 76.8896 53.0507 76.8896C61.4107 76.8896 69.4507 79.3997 76.3007 84.1497C78.3007 85.5397 80.2007 87.1196 81.9307 88.8496C85.6107 92.5296 88.5207 96.8196 90.5707 101.57L90.6807 101.83L90.4907 102.03C87.8007 104.92 84.7807 107.49 81.5107 109.67C73.1307 115.31 63.3307 118.28 53.1907 118.28ZM16.5207 101.48C19.1607 104.31 22.1307 106.84 25.3307 108.98C33.5807 114.5 43.2107 117.42 53.1807 117.42C63.1507 117.42 72.7807 114.5 81.0307 108.98C84.1707 106.88 87.0707 104.42 89.6707 101.67C87.6707 97.1096 84.8607 93.0097 81.3207 89.4697C79.6307 87.7796 77.7707 86.2297 75.8107 84.8697C69.1107 80.2197 61.2407 77.7597 53.0507 77.7597C44.9407 77.7597 37.1307 80.1796 30.4607 84.7496C24.3507 88.9496 19.5307 94.7297 16.5207 101.48Z"
        fill="black"
      />
      <path
        d="M53.0603 73.4497C41.4903 73.4497 32.0703 64.0397 32.0703 52.4597C32.0703 40.8797 41.4803 31.4697 53.0603 31.4697C64.6403 31.4697 74.0503 40.8797 74.0503 52.4597C74.0403 64.0397 64.6303 73.4497 53.0603 73.4497ZM53.0603 32.3397C41.9603 32.3397 32.9303 41.3697 32.9303 52.4697C32.9303 63.5697 41.9603 72.5997 53.0603 72.5997C64.1603 72.5997 73.1903 63.5697 73.1903 52.4697C73.1903 41.3697 64.1603 32.3397 53.0603 32.3397Z"
        fill="black"
      />
      <path
        d="M93.3103 50.6997C106.836 50.6997 117.8 39.7352 117.8 26.2097C117.8 12.6843 106.836 1.71973 93.3103 1.71973C79.7849 1.71973 68.8203 12.6843 68.8203 26.2097C68.8203 39.7352 79.7849 50.6997 93.3103 50.6997Z"
        fill="#B5F800"
      />
      <path
        d="M95.9806 37.79C95.9806 39.24 94.8006 40.42 93.3506 40.42H93.2706C91.8206 40.42 90.6406 39.24 90.6406 37.79C90.6406 36.34 91.8206 35.16 93.2706 35.16H93.3506C94.8006 35.16 95.9806 36.34 95.9806 37.79ZM93.3106 12C94.7606 12 95.9406 13.18 95.9406 14.63V17.3L94.8206 30.38C94.7506 31.15 94.1106 31.73 93.3406 31.73C92.5706 31.73 91.9406 31.15 91.8706 30.38L90.6806 17.3V14.63C90.6806 13.18 91.8606 12 93.3106 12Z"
        fill="white"
      />
    </svg>
  );
};

export default CancelCreateTeamImage;
