import { colors } from "../../assets/colors";
import { GothamRegular, SpanColor } from "../../components/Text";
import { digitDateWithDigitHourFormatter } from "../../components/tools";

export const RejectedFormatter = (item) => {
  const { status_history, created_at } = item;

  const array = [
    {
      date: digitDateWithDigitHourFormatter(created_at),
      text: "You created a transaction",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(status_history[0]?.created_at),
      text: "Your payment has been received",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(status_history[1]?.created_at),
      text: "Your transaction was cancelled, Please ask your account manager for more info",
      isRedButton: true,
      isActive: true,
      customComponents: (
        <>
          <GothamRegular>
            Your transaction was{" "}
            <SpanColor color={colors.orangeFF}>cancelled</SpanColor>. Please ask
            your account manager for more info
          </GothamRegular>
        </>
      ),
    },
  ];

  return array;
};
