import { kebabCase } from "lodash";

export const safeDate = (date) => {
  if (!date) return null;
  const getDate = new Date(date);
  const isInvalid = getDate == "Invalid Date";
  if (isInvalid) return null;
  return getDate;
};

const classNamePicker = (className) => {
  const docClass = document.getElementsByClassName(className);
  return docClass[0];
};

const styleEditor = (doc, obj) => {
  Object.keys(obj || {}).forEach((key) => {
    const value = obj[key];
    doc.style[kebabCase(key)] = value;
  });
};

export const classNamePickAndEditStyle = (className, style) => {
  const doc = classNamePicker(className);
  if (!doc) return;
  styleEditor(doc, style);
};

export const classNameHiders = (classNames) => {
  classNames.forEach((className) => {
    const doc = classNamePicker(className);
    if (!doc) return;
    styleEditor(doc, {
      visiblity: "hidden",
      height: "0px",
      overflow: "hidden",
    });
  });
};
