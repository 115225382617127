import { useEffect } from "react";
import { ProgressBarJack } from "../../../../components/Stepper";
import { arraySplitter } from "../../../../components/tools";
import { useHeader } from "../../../../contexts/Layout/parent";
import { apiBusiness } from "../../../../tools/api";
import { DataValidationPage } from "../../../localTransferComponents/validation/parent";
import { defaultValuesCleaner } from "../create/formatter";
import { updatePayloadFormatterPayroll } from "./logics";
import { ModalConfirmationCloseLocal } from "../../../localTransferEnhancement/create/modal";
import { useModalHook } from "../../../../components/Modals";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { translateArray } from "pageComponents/payrollCreateComponents/first";

export const stepArrayFunc = (index, isResubmit) =>
  (isResubmit
    ? [
        { step: "Transfer Details" },
        { step: "Bank Validation" },
        { step: "Confirm to Submit" },
      ]
    : [
        { step: "Create Payroll" },
        { step: "Input Employee" },
        { step: "Transfer Details" },
        { step: "Bank Validation" },
        { step: "Confirm to Submit" },
      ]
  ).map((item, indexChild) => ({ ...item, isActive: index == indexChild }));

export const ValidationPayroll = ({ defaultValues }) => {
  const { setHeader } = useHeader();
  const { isOpen: isOpenCloseModal, toggle: toggleCloseModal } = useModalHook();

  const { query } = useRouter();
  const { action, id } = query;
  const isResubmit = action === "resubmit";
  const { t } = useTranslation("payroll/create");

  useEffect(() => {
    setHeader({
      type: "close",
      middleComponent: (
        <ProgressBarJack
          arrayStep={translateArray({
            t,
            array: stepArrayFunc(isResubmit ? 1 : 3, isResubmit),
            key: "step",
          })}
        />
      ),
      onCloseProps: () => toggleCloseModal(),
    });
  }, []);

  const cleanDefaultValues = defaultValuesCleaner(defaultValues) || {};

  const { batch_id } = cleanDefaultValues;

  const onUpdate = async (values) => {
    const splitted = arraySplitter(values, 10);

    for (let i = 0; i < splitted.length; i++) {
      const payload = updatePayloadFormatterPayroll(splitted[i], defaultValues);

      await apiBusiness.put(
        `local_transaction_batches/${batch_id}/update_transaction`,
        payload
      );
    }
  };

  return (
    <>
      <DataValidationPage
        defaultValues={defaultValues}
        onUpdate={onUpdate}
        batchId={batch_id}
        isPayroll
      />
      <ModalConfirmationCloseLocal
        toggle={toggleCloseModal}
        isOpen={isOpenCloseModal}
        product="payroll"
        isAfterDraft
        batchId={id}
      />
    </>
  );
};
