const getCalculationText = ({
  someTrxAreNotCovered = false,
  desc = "",
  individualFee = "",
  totalUncoveredTrx = 0,
}) => {
  if (someTrxAreNotCovered)
    return `IDR ${individualFee} x ${totalUncoveredTrx}`;

  return desc.split("=")[0];
};

export default getCalculationText;
