import tanganCoin from "../../../assets/images/close-bill-payment.svg";
import kertasRobek from "../../../assets/images/back-invoice-modal.svg";
import tongSampah from "../../../assets/images/trash-confirmation.svg";
import tanganReceipt from "../../../assets/images/continue_wo_receipt.svg";
import reuploadReceipt from "../../../assets/images/reupload-receipt.svg";
import draftIcon from "../../../assets/images/draft-confirm-icon.svg";
import turnON from "../../../assets/images/turn-on-ap.svg";
import turnOFF from "../../../assets/images/turn-off-ap.svg";
import pintuExit from "../../../assets/images/pintu-exit.svg";
export const confirmIcon = {
  tanganCoin,
  kertasRobek,
  tongSampah,
  tanganReceipt,
  reuploadReceipt,
  draftIcon,
  turnON,
  turnOFF,
  pintuExit,
};
