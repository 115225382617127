import { useEffect, useState } from "react";

const useRemindTiming = ({ module = "", trxId = null, hourPeriod = 1 }) => {
  const [shouldWaitRemindTiming, setShouldWaitRemindTiming] = useState(false);

  const storageName = `${module}-${trxId}-remind-timing`;

  const enableWait = () => setShouldWaitRemindTiming(true);
  const disableWait = () => setShouldWaitRemindTiming(false);

  const enableRemindTiming = () => {
    enableWait();
    localStorage.setItem(storageName, String(new Date()));
  };
  const disableRemindTiming = () => {
    disableWait();
    localStorage.removeItem(storageName);
  };

  useEffect(() => {
    const canProceed = !!module && !!trxId && !!hourPeriod;

    if (!canProceed) return disableWait();

    const checkRemindTiming = () => {
      const foundRemindTiming = localStorage.getItem(storageName);

      if (!foundRemindTiming) return disableWait();

      const milliSecondPeriod = hourPeriod * 60 * 60 * 1000;

      const timedDate = new Date(foundRemindTiming);
      const timedDatePlusPeriod = timedDate.setTime(
        timedDate.getTime() + milliSecondPeriod
      );

      const currentDate = new Date();

      const isAlreadySurpassedTheTiming = timedDatePlusPeriod <= currentDate;

      if (!isAlreadySurpassedTheTiming) return enableWait();

      disableRemindTiming();
    };

    checkRemindTiming();
  }, [module, trxId, hourPeriod, storageName]);

  return { shouldWaitRemindTiming, enableRemindTiming, disableRemindTiming };
};

export default useRemindTiming;
