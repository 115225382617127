import ancestoryLinesFormatter from "./ancestoryLinesFormatter";
import longestAncestoryLineDecider from "./longestAncestoryLineDecider";

const isRetriedBatchDecider = (transactions = []) => {
  const ancestoryLines = ancestoryLinesFormatter(transactions);
  const longestAncestoryLine = longestAncestoryLineDecider(ancestoryLines);
  const longestAncestoryLineLength = longestAncestoryLine.length;
  const isRetriedTrx = longestAncestoryLineLength > 1;
  return isRetriedTrx;
};

export default isRetriedBatchDecider;
