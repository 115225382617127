import { useState, useRef, useEffect } from "react";
import { GothamMedium, GothamRegular } from "./Text";
import { colors } from "../assets/colors";
import { useTranslation } from "react-i18next";

const SeeMoreButton = ({ onClick, buttonAlign }) => {
  const { t } = useTranslation("credit/credit");
  const isButtonAlignLeft = buttonAlign === "left";

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        background: "#FDFCFC",
        pointerEvents: "none",
        width: "100%",
        display: "flex",
        justifyContent: isButtonAlignLeft ? "flex-start" : "flex-end",
      }}
    >
      <GothamMedium
        onClick={onClick}
        style={{
          border: "none",
          cursor: "pointer",
          pointerEvents: "all",
          textDecoration: "underline",
          color: colors.neutral900,
          fontSize: "12px",
        }}
      >
        {t("See more")}
      </GothamMedium>
    </div>
  );
};

const SeeLessButton = ({ onClick }) => {
  return (
    <div>
      <GothamMedium
        onClick={onClick}
        style={{
          border: "none",
          cursor: "pointer",
          textDecoration: "underline",
          color: colors.neutral900,
          fontSize: "12px",
        }}
      >
        See less
      </GothamMedium>
    </div>
  );
};

export const SeeMore = ({
  children,
  lines = 3,
  height,
  width,
  buttonAlign = "left",
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(true);
  const containerRef = useRef(null);

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsFirstRender(false);
    }, 200);
  }, []);

  useEffect(() => {
    if (isFirstRender) return;

    if (containerRef.current) {
      const { clientHeight, scrollHeight } = containerRef.current;
      if (scrollHeight > clientHeight) {
        return setIsOverflow(true);
      } else {
        if (isExpanded) return;
        setIsOverflow(false);
      }
    }
  }, [isFirstRender]);
  if (isFirstRender) return null;

  const seeMoreStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: isOverflow ? height || lines * 24 : "auto",
  };

  const containerStyle = {
    width: width || "100%",
    position: "relative",
    ...(isExpanded ? {} : seeMoreStyle),
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const buttonDecider = () => {
    if (!isOverflow) return null;
    if (isExpanded) return <SeeLessButton onClick={handleClick} />;
    return <SeeMoreButton onClick={handleClick} buttonAlign={buttonAlign} />;
  };

  const button = buttonDecider();

  return (
    <div ref={containerRef} style={containerStyle}>
      {children}
      {button}
    </div>
  );
};
