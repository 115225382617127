import { useRouter } from "next/dist/client/router";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../../../components/tools";
import { NakedTransactionFiltersSearchbox } from "./component";
import JackIcons from "assets/jackIcons/typescript/parent";
import { jackColors } from "assets/colors";
import { useClickOutside } from "universalFunctions/useClickOutside";

export const TransactionFiltersSearchbox = ({
  placeholder,
  queryName = "searchbox",
  runQueryName = "runQuery",
  style,
  loading,
  withClearFilter = false,
}) => {
  const ref = useRef();
  const { query, push } = useRouter();
  const [isFocused, setIsFocused] = useState(false);

  const [text, setText] = useState(query[queryName] || "");

  const debouncedText = useDebounce(text, 800);

  const onChange = (text) => {
    setText(text);
  };

  const handleClear = () => {
    setText("");
    delete query[queryName];
    push({ query });
  };

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    push({ query: { ...query, [runQueryName]: false, [queryName]: text } });
    push({ query: { ...query, [runQueryName]: true, [queryName]: text } });
  }, [debouncedText]);

  // const handleFocus = () => setIsFocused(true);
  const handleFocus = () => setIsFocused(true);
  const showClearBtn = isFocused && withClearFilter && !!text;

  useClickOutside({ clickOutside: () => setIsFocused(false), ref });

  return (
    <div ref={ref} style={{ width: "100%" }}>
      <NakedTransactionFiltersSearchbox
        onChange={onChange}
        placeholder={placeholder}
        onFocus={handleFocus}
        value={text}
        style={style}
        loading={loading}
        iconRight={
          showClearBtn && (
            <JackIcons
              name="CloseCircle"
              fill={jackColors.black34}
              style={{ width: 20, height: 20, cursor: "pointer" }}
              onClick={handleClear}
            />
          )
        }
        // withClearFilter={withClearFilter}
      />
    </div>
  );
};
