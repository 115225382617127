import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { jackColors } from "../../assets/colors";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { windowDimension } from "../../components/tools";
import {
  EmptyScreenSearchRecipients,
  EmptyStateSearchImages,
} from "../../pageComponents/crossBorder/components";
import { pluralize } from "../../pageComponents/invoiceComponents/data/formatter";
import { TransactionFiltersSearchbox } from "../../pageComponents/transactionsComponents/transactions/filters/searchbox/parent";
import { fetch } from "../../tools/api";
import IndividualUser from "./IndividualUser";
import { Trans, useTranslation } from "react-i18next";
import { useUploadReceiptScreenType } from "modals/smartUploadReceiptModal/logics";

export const UserList = ({
  handleClickButton,
  handleAddRecipient,
  toggle,
  closeModal,
  isPayroll = false,
}) => {
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [page, setPage] = useState(1);
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);
  const [users, setUsers] = useState([]);
  const [thereAreNoMoreRecipient, setThereAreNoMoreRecipient] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const { isLocalTransfer } = useUploadReceiptScreenType();
  const { t } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const tDecider = isLocalTransfer ? tLocalTransfer : t;

  const { isTabOrPhone } = windowDimension();

  const { query } = useRouter();

  const { search_box_recipient } = query;

  const baseUrl = isPayroll ? "/payroll_recipients" : "/local_recipients";
  const employeeOrRecipient = isPayroll ? t("employee") : "recipient";
  // const accountSelected = `${selectedRecipients.length} ${pluralize(
  //   selectedRecipients.length,
  //   employeeOrRecipient
  // )} selected `;
  const accountSelected = (
    <Trans
      i18nKey={tDecider("dynamic.dynamic_text_7", {
        empNum: selectedRecipients.length,
        emp: pluralize(selectedRecipients.length, employeeOrRecipient),
      })}
    />
  );
  const buttonSelected = isPayroll
    ? t("Select Employee")
    : tLocalTransfer("Select Recipient");

  const { refetch, loading } = fetch({
    url: `${baseUrl}?page=${page}&per_page=10&q[is_valid_eq]=true&q[name_or_account_number_cont]=${
      search_box_recipient || ""
    }`,
    afterSuccess: (res) => {
      const { data, page: pageRes } = res;

      if (data.length < 10) {
        setThereAreNoMoreRecipient(true);
      }

      if (pageRes === 1) {
        setUsers(data);
      } else {
        setUsers((prev) => [...prev, ...data]);
      }

      setIsEndOfScroll(false);
      setSearching(false);
    },
    woInit: true,
  });

  useEffect(() => {
    const refetchTimeout = setTimeout(() => {
      if (!loading && isEndOfScroll && !thereAreNoMoreRecipient) {
        refetch();
      }
    }, 0);

    return () => clearTimeout(refetchTimeout);
  }, [page, isEndOfScroll, thereAreNoMoreRecipient]);

  useEffect(() => {
    const searchTimeOut = setTimeout(() => {
      if (page === 1 && searching) {
        setThereAreNoMoreRecipient(false);
        refetch();
      }
    }, 0);

    return () => clearTimeout(searchTimeOut);
  }, [page, searching]);

  useEffect(() => {
    setPage(1);
    setSearching(true);
  }, [search_box_recipient]);

  const handleSelectRecipient = (recipient, select = true) => {
    if (isTabOrPhone) {
      handleAddRecipient([recipient]);
      if (toggle) {
        return toggle();
      }

      return closeModal();
    }

    if (select) {
      return setSelectedRecipients((prev) => [...prev, recipient]);
    }

    setSelectedRecipients((prev) =>
      prev.filter((user) => user.id !== recipient.id)
    );
  };

  const handleContinue = () => {
    handleAddRecipient(selectedRecipients);
    toggle();
  };

  // const onChange = (search) => {
  //   setSearch(search);
  // };

  const userListRef = useRef();

  const onScroll = () => {
    if (userListRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = userListRef.current;
      if (scrollTop + clientHeight <= scrollHeight - 10 && !loading) {
        setPage((prev) => prev + 1);
        setIsEndOfScroll(true);
      }
    }
  };

  const footer = () => {
    if (isTabOrPhone) return null;

    return (
      <>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{
            padding: "20px 24px 0px 24px",
            margin: "0px -24px 0px -24px",
            borderTop: "1px solid #E6E6E8",
          }}
        >
          <GothamMedium>{accountSelected}</GothamMedium>
          <ButtonJack
            children={buttonSelected}
            onClick={handleContinue}
            disabled={selectedRecipients.length === 0}
            style-
          />
        </div>
      </>
    );
  };

  const Shimmer = () => {
    const shimmers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    return (
      <div>
        {shimmers.map((shimmer) => (
          <div
            key={shimmer}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px 0px",
            }}
          >
            <div style={{ padding: "0px 14px 0px 8px" }}>
              <Skeleton width={16} height={16} />
            </div>
            <Skeleton
              width={32}
              height={32}
              style={{ borderRadius: "50%", marginRight: 16 }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Skeleton width={200} height={20} />
              <Skeleton width={150} height={15} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const emptyStateComponent = isLocalTransfer ? (
    <EmptyStateSearchImages isLocalTransaction search={search_box_recipient} />
  ) : (
    <EmptyScreenSearchRecipients
      customText={
        <>
          We can't find what you’re looking for. <br />
          Let's try another keyword.
        </>
      }
    />
  );

  return (
    <div>
      {/* <SearchFieldWithButton
        handleClickButton={handleClickButton}
        queryName={"search_box_recipient"}
        noButton
      /> */}
      <div style={{ margin: "12px 0px 20px 0px" }}>
        <TransactionFiltersSearchbox
          queryName="search_box_recipient"
          placeholder={t("Search keywords")}
          style={{ width: "100%" }}
        />
      </div>
      {!!users?.length && (
        <div
          style={{
            borderBottom: `1px solid ${jackColors.neutral500}`,
          }}
        >
          <GothamRegular
            style={{
              color: jackColors.neutral700,
              padding: "8px 12px",
              marginLeft: 28,
            }}
            className="font12"
          >
            {tDecider("All Recipient")}
          </GothamRegular>
        </div>
      )}
      <div
        style={{
          height: 300,
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          position: "relative",
        }}
        ref={userListRef}
        onScroll={onScroll}
      >
        {Boolean(users.length) &&
          users.map((user, index) => (
            <div key={index}>
              <IndividualUser
                key={index}
                user={user}
                handleSelectRecipient={handleSelectRecipient}
                checkedFromParent={selectedRecipients.find((recipient) => {
                  return recipient.id === user.id;
                })}
                isMinimalistStyle
              />
            </div>
          ))}
        {loading && <Shimmer />}
        {!Boolean(users.length) && !loading && (
          <EmptyScreenSearchRecipients
            customText={
              <>
                {t("We can't find what you’re looking for")} <br />
                {t("Let's try another keyword.")}
              </>
            }
          />
        )}
      </div>
      {footer()}
    </div>
  );
};

export default UserList;
