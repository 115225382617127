import { GothamMedium } from "components/typescript/Text";
import { RecipientFilter } from "modals/AddFromListModal/typescript/UserList";
import { useEffect, useState } from "react";
import { components, MenuListProps } from "react-select";
import { SelectionJack } from "../../../../components/inputs/selection";
import useLocalRecipients from "./hooks";
import {
  BankSelectDropdownProps,
  LocalTransferBank,
} from "./mainContent.types";
import { useTranslation } from "react-i18next";

export const BankSelectDropdown = ({
  name,
  useFormObj,
  isDisabled,
  placeholder = "Select Bank",
  label = "Select Bank",
  error = false,
  containerStyle,
}: BankSelectDropdownProps) => {
  const { t } = useTranslation("local-transfer/revamp");
  const { data, loading } = useLocalRecipients();
  const allMappedEntities = [
    ...(data?.banks ?? []),
    ...(data?.e_wallets ?? []),
    ...(data?.va_banks ?? []),
  ];
  const [filteredEntities, setFilteredEntities] = useState(allMappedEntities);

  useEffect(() => {
    if (allMappedEntities.length > 0 && filteredEntities.length === 0) {
      setFilteredEntities(allMappedEntities);
    }
  }, [allMappedEntities]);

  const CustomMenuList = (props: MenuListProps) => {
    return (
      <div>
        <div /* style={{ borderBottom: "1px solid #E6E6E8" }} */>
          <div style={{ padding: 12 }}>
            <RecipientFilter
              extraOnClick={handleFilterChange}
              loading={loading}
              queryName={"type"}
            />
          </div>
          <GothamMedium className="font12" style={{ margin: "8px 12px" }}>
            {t("All")}
          </GothamMedium>
          <div
            style={{
              width: "94%",
              margin: "0 auto",
              borderBottom: "1px solid #E6E6E8",
            }}
          />
        </div>
        <components.MenuList {...props}>{props.children}</components.MenuList>
      </div>
    );
  };

  const handleFilterChange = (category: string) => {
    let filteredList: LocalTransferBank[];
    switch (category) {
      case "banks":
        filteredList = data?.banks || [];
        break;
      case "e_wallet":
        filteredList = data?.e_wallets || [];
        break;
      case "va_bank":
        filteredList = data?.va_banks || [];
        break;
      default:
        filteredList = allMappedEntities; // Show all entities by default
    }
    setFilteredEntities(filteredList);
  };

  return (
    <SelectionJack
      name={name}
      label={label}
      options={filteredEntities}
      useFormObj={useFormObj}
      placeholder={placeholder}
      isDisabled={isDisabled}
      error={error}
      components={{ MenuList: CustomMenuList }}
      containerStyle={containerStyle}
      inputStyle={{}}
      className={""}
      onChange={() => {}}
      helperText={""}
      divStyle={{}}
      customLabelStyle={{}}
      woAsterisk={false}
      asterixRequired
    />
  );
};
