import { useEffect, useState } from "react";

export const useCleanQueries = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsFirstRender(false), 200);
  }, [isFirstRender]);

  return { isFirstRender };
};
