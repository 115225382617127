import React, { Fragment } from "react";
import { colors } from "../../../assets/colors";
import xIcon from "../../../assets/images/x-icon-grey.svg";
import dynamic from "next/dynamic";

const CustomButton = dynamic(() =>
  import("../../../components/Buttons").then((mod) => mod.CustomButton)
);
const CenteredModal = dynamic(() =>
  import("../../../modals/centeredComponents").then((mod) => mod.CenteredModal)
);
const TypeFezNeueMedium = dynamic(() =>
  import("../../../components/Text").then((mod) => mod.TypeFezNeueMedium)
);
const TypeFezNeueRegular = dynamic(() =>
  import("../../../components/Text").then((mod) => mod.TypeFezNeueRegular)
);
const TextInlineMedium = dynamic(() =>
  import("../../../components/Text").then((mod) => mod.TextInlineMedium)
);

export const Cards = ({ array, isMinimMargin }) =>
  array?.map((stillArray, index) => {
    const number = index + 1;
    const isLast = index == array.length - 1;
    const isSecond = index == 1;
    const isFirst = index == 0;
    const lineHeight = isLast ? 0 : 113 / 2;
    return (
      <div
        className="d-flex align-items-center justify-content-between"
        key={index}
        style={{
          marginBottom: lineHeight,
          marginTop: isFirst ? 0 : isMinimMargin ? -40 : -30,
        }}
      >
        <div
          style={{
            backgroundColor: colors.blue2C,
            color: "white",
            borderRadius: 22,
            height: 22,
            width: 22,
          }}
        >
          <TypeFezNeueMedium
            style={{ textAlign: "center", marginTop: 2, color: colors.white }}
            className="font12"
          >
            {number}
          </TypeFezNeueMedium>
          <div className="justify-content-center d-flex">
            <div
              style={{
                height:
                  isSecond && !isLast ? (isMinimMargin ? 50 : 70) : lineHeight,
                width: 2,
                backgroundColor: colors.blue2C,
              }}
            />
          </div>
        </div>
        <div style={{ width: 12 }} />
        <div
          style={{
            border: "1px solid #E6E6E8",
            borderRadius: 12,
            padding: 12,
            width: "100%",
          }}
        >
          <TypeFezNeueRegular>
            {stillArray.map((text, index) => {
              const isOdd = (index + 1) % 2;
              if (isOdd)
                return <TextInlineMedium key={index}>{text}</TextInlineMedium>;
              return (
                <Fragment key={index}>
                  {` `}
                  {text}
                </Fragment>
              );
            })}
          </TypeFezNeueRegular>
        </div>
      </div>
    );
  });

export const OnBoardingInvoicePaymentModal = ({
  isOpen,
  toggle,
  onSubmit,
  onBoardingData,
  isMinimMargin = false,
}) => {
  return (
    <CenteredModal isOpen={isOpen} toggle={toggle} style={{ width: 516 }}>
      <div className="d-flex justify-content-between">
        <TypeFezNeueMedium className="font24" style={{ color: colors.blue10 }}>
          {onBoardingData?.headline}
        </TypeFezNeueMedium>
        <img
          src={xIcon}
          style={{ height: 24 }}
          className="hover"
          onClick={toggle}
        />
      </div>
      <TypeFezNeueRegular style={{ marginTop: 16, marginBottom: 24 }}>
        {onBoardingData?.subHeadline}
      </TypeFezNeueRegular>
      <Cards array={onBoardingData?.array} isMinimMargin={isMinimMargin} />
      <CustomButton
        className="w-100"
        style={{ marginTop: 24 }}
        onClick={() => {
          onSubmit && onSubmit();
          toggle();
        }}
      >
        Got it
      </CustomButton>
    </CenteredModal>
  );
};
