import { get, isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import upperCase from "upper-case";
import { colors } from "../assets/colors";
import { bankLists } from "../assets/data";
import downloadIcon from "../assets/images/download-icon.svg";
import paymentIcon from "../assets/images/payment-summary-card.svg";
import profileIcon from "../assets/images/recipient-details-person.svg";
import transArrowIcon from "../assets/images/transaction-arrow.svg";
import coinIcon from "../assets/images/transaction-details-coin.svg";
import { CustomButton } from "../components/Buttons";
import { GetFlag } from "../components/inputs";
import { RefundModal } from "../components/Modals";
import {
  ApiTransactionDetailsShimmer,
  TransactionDetailsShimmer,
} from "../components/Shimmer";
import { booleanState, StatusBadge } from "../components/Status";
import { GothamMedium, GothamRegular } from "../components/Text";
import {
  asyncDownload,
  dateAndHoursFormatter,
  flagIso3,
  formatCurrency,
  formatCurrencyFixed,
  formatCurrencyNoDecimal,
  ShortDateFormatter,
  windowDimension,
} from "../components/tools";
import UpdateTracker from "../components/UpdateTracker/parent";
import { eventsTracker } from "../universalFunctions/events";
import { StatusPayrollLine } from "./payrollCreateComponents/loop/success/status";
import { transactionStatusFormatter } from "./transactionFlowComponents/parent";

export const transactionBeneficiaryDetails = (beneficiary) => {
  const getBeneficiary = (string) => get(beneficiary, string, "");
  const getName =
    getBeneficiary("receiving_registered_name") ||
    getBeneficiary("receiving_first_name") +
      " " +
      getBeneficiary("receiving_last_name");
  const getEmail = getBeneficiary("recipient_email");
  const getBankName = getBeneficiary("bank_name");
  const getBranch = getBeneficiary("branch_number");
  const getBsb = getBeneficiary("bsb_number");
  const getAccountNumber =
    getBeneficiary("bank_account_number") || getBeneficiary("account_number");
  const getIban = getBeneficiary("iban");
  const getSwift = getBeneficiary("swift_bic_code");
  const getBankAccountName = getBeneficiary("bank_account_name");
  const getAbaNumber = getBeneficiary("aba_routing_number");
  const getRoutingCode = getBeneficiary("routing_code");

  const data = [
    {
      title: getName && "Name",
      msg: getName,
    },
    {
      title: getEmail && "Email",
      msg: getEmail,
    },
    {
      title: getBankName && "Bank Name",
      msg: getBankName,
    },
    {
      title: getBankAccountName && "Bank Account Name",
      msg: getBankAccountName,
    },
    {
      title: getBranch && "Branch Number",
      msg: getBranch,
    },
    {
      title: getBsb && "BSB Number",
      msg: getBsb,
    },
    {
      title: getAccountNumber && "Bank Account Number",
      msg: getAccountNumber,
    },
    {
      title: getIban && "IBAN",
      msg: getIban,
    },
    {
      title: getSwift && "Swift BIC Code",
      msg: getSwift,
    },
    {
      title: getAbaNumber && "ABA Routing Number",
      msg: getAbaNumber,
    },
    {
      title: getRoutingCode && "Routing Code",
      msg: getRoutingCode,
    },
  ];
  return {
    icon: <img src={profileIcon} />,
    title: "Recipient Details",
    data,
  };
};

export const TransactionList = ({
  item,
  loading,
  paymentOnClick,
  isSingle,
}) => {
  const { push } = useRouter();

  const {
    state,
    source_amount,
    destination_amount,
    destination_currency,
    rate,
    fee,
    disbursement_type = "",
    payment_method,
  } = item;

  const isWallet = payment_method === "indonesian_wallet";

  const { isWaitingForPayment, isCancelled } = booleanState(state);
  const isPaid = Boolean(isWaitingForPayment || isCancelled);

  const dataTransactionDetails = [
    {
      title: "Disbursement Type",
      msg: upperCase(disbursement_type),
    },
    {
      title: "Source Amount (Fee Included)",
      msg: `IDR ${formatCurrencyNoDecimal(source_amount)}`,
    },
    {
      title: "Destination Amount",
      msg: `${destination_currency} ${destination_amount}`,
    },
    {
      title: "Rate",
      msg: `${destination_currency} 1 = IDR ${rate}`,
    },
    {
      title: "Fee",
      msg: `IDR ${fee}`,
    },
  ];
  const getSinglePartner = get(item, "partner_bank_account", {});
  const getBatchPartner = get(item, "batch_partner_bank_account", {});
  const getPaymentDetails = isEmpty(getBatchPartner)
    ? getSinglePartner
    : getBatchPartner;

  const dataRecipientDetails = transactionBeneficiaryDetails(
    item?.beneficiary || {}
  );

  const dataDetails = [
    {
      icon: <img src={coinIcon} />,
      title: "Transaction Details",
      data: dataTransactionDetails,
    },
    dataRecipientDetails,
  ];

  const handleClick = (id) => {
    eventsTracker("transaction_detail_to_batch_detail", { id });
    push(`/history/batch-payment/${id}`);
  };

  if (loading) {
    return <TransactionDetailsShimmer />;
  }

  return (
    <>
      <TransactionDetailsHeader
        item={item}
        handleClick={handleClick}
        paymentOnClick={paymentOnClick}
        isSingle={isSingle}
      />
      <div className="mx-4 d-flex justify-content-between">
        <div>
          <TransactionDetailsCard dataDetails={dataDetails} />
          {Boolean(!isPaid) && (
            <PaymentDetail
              item={getPaymentDetails}
              isWallet={isWallet}
              transfer={item.batch_transfer_to || item.transfer_to}
            />
          )}
        </div>
        <div>
          <TransactionStatus item={item} />
        </div>
      </div>
    </>
  );
};

export const apiTransactionFormatter = (data) => {
  const {
    sender,
    sent_amount,
    source,
    beneficiary,
    destination,
    rate,
    disbursement_reference_id,
    id,
    payer_name,
    paid_at,
    compliance,
    user_email,
    reference_id,
    state,
  } = data;

  const {
    firstname: safeFirstname = "",
    lastname: safeLastname = "",
    country_iso_code: safeIsoCode = "",
    date_of_birth: safeDateBirth = "",
  } = sender || {};
  const {
    country_iso_code: safeSourceIso = "",
    currency: safeSenderCurrency = "",
  } = source || {};

  const {
    firstname: safeBeneFirstName = "",
    lastname: safeBeneLastName = "",
    account: safeBeneAccount = "",
    date_of_birth: safeBeneDateBirth = "",
  } = beneficiary || {};

  const {
    amount: safeDestAmount = "",
    currency: safeDestCurr = "",
    country_iso_code: safeDestIso = "",
  } = destination || {};

  const safeBeneIso = get(beneficiary, "date_of_birth", "");

  const formattedRate = formatCurrency(rate) || 0;
  const safeSentAmount = sent_amount || 0;
  const safePaidAt = dateAndHoursFormatter(paid_at) || "";
  const safeCompBeneRelationship = get(
    compliance,
    "beneficiary_relationship",
    ""
  );
  const safeCompPurpose = get(compliance, "purpose_of_remittance", "");
  const safeCompSourceFunds = get(compliance, "source_of_funds", "");
  const beneIsoCode = safeBeneIso || safeDestIso;
  const senderIsoCode = safeIsoCode || safeSourceIso;
  const senderFlag = flagIso3(safeSourceIso);
  const beneFlag = flagIso3(safeIsoCode);
  const senderName = `${safeFirstname} ${safeLastname}`;
  const beneName = `${safeBeneFirstName} ${safeBeneLastName}`;
  const senderAmount = `${safeSenderCurrency} ${safeSentAmount}`;
  const beneAmount = `${safeDestCurr} ${safeDestAmount}`;
  const textRate = `${safeSenderCurrency} 1 = ${safeDestCurr} ${formattedRate}`;

  const totalAmount = `${safeSenderCurrency} ${formatCurrencyFixed(
    safeSentAmount
  )}`;
  // const getFieldEntries = Object.entries(safeBene);

  const overviewData = [
    {
      title: "Sender",
      msg: senderName,
    },
    {
      title: "",
      flag: senderFlag,
      msg: totalAmount,
    },
    {
      title: "Beneficiary",
      msg: beneName,
    },
    {
      title: "",
      flag: beneFlag,
      msg: `${safeDestCurr} ${formatCurrencyNoDecimal(safeDestAmount)}`,
    },
  ];

  const transactionData = [
    {
      title: "Rate",
      msg: textRate,
    },
    {
      title: "User",
      msg: user_email,
    },
    {
      title: "ID",
      msg: id,
    },
    {
      title: "Paid at",
      msg: safePaidAt,
    },
    {
      title: "Purpose of Remittance",
      msg: safeCompPurpose,
    },
    {
      title: "Source of Funds",
      msg: safeCompSourceFunds,
    },
    {
      title: "Beneficiary Relationship",
      msg: safeCompBeneRelationship,
    },
  ];
  const senderDetails = [
    {
      title: safeFirstname && "First Name",
      msg: safeFirstname,
    },
    {
      title: safeLastname && "Last Name",
      msg: safeLastname,
    },
    {
      title: senderIsoCode && "Country ISO Code",
      msg: senderIsoCode,
    },
    {
      title: safeDateBirth && "Date Of Birth",
      msg: safeDateBirth,
    },
  ];
  const BeneficiaryDetails = [
    {
      title: safeBeneFirstName && "First Name",
      msg: safeBeneFirstName,
    },
    {
      title: safeBeneLastName && "Last Name",
      msg: safeBeneLastName,
    },
    {
      title: beneIsoCode && "Country ISO Code",
      msg: beneIsoCode,
    },
    {
      title: payer_name && "Bank Name",
      msg: payer_name,
    },
    {
      title: safeBeneAccount && "Account",
      msg: safeBeneAccount,
    },
  ];

  // DYNAMIC BENEFICIARY FOR FUTURE USE

  // const DynamicBeneficiary = () => {
  //   let data = [];
  //   getFieldEntries.map((item) =>
  //     data.push({
  //       title: item[1] && item[0],
  //       msg: item[1],
  //     })
  //   );
  //   return setBeneDynamic(data);
  // };

  // useEffect(() => {
  //   DynamicBeneficiary()
  // }, [data.id])

  const dataDetails = [
    {
      title: "Sender Details",
      data: senderDetails,
    },
    {
      title: "Beneficiary Details",
      data: BeneficiaryDetails,
    },
  ];
  const item = {
    reference_id,
    state,
  };
  return {
    overviewData,
    item,
    transactionData,
    dataDetails,
    senderName,
    beneName,
    totalAmount,
  };
};

export const ApiTransactionList = ({ data, loading }) => {
  const { id } = data;
  const { dataDetails, item, overviewData, transactionData } =
    apiTransactionFormatter(data);

  const dataHeader = { ...item, id };

  if (loading) {
    return <ApiTransactionDetailsShimmer />;
  }
  return (
    <>
      <TransactionDetailsHeader item={dataHeader} isApi />
      <div className="mx-5">
        <TransactionDetailsRow dataDetails={overviewData} isOverview />
        <TransactionDetailsRow dataDetails={transactionData} />
      </div>
      <div className="mx-5 d-flex justify-content-between">
        <TransactionDetailsCard
          dataDetails={dataDetails}
          isApi
          style={{ width: "500px" }}
        />
      </div>
    </>
  );
};

export const TransactionDetailsCard = ({
  dataDetails,
  style,
  childStyle,
  isApi,
}) => {
  const { figmaWidth } = windowDimension();

  return dataDetails.map(({ icon, title, data }, index) => (
    <DetailsDiv style={{ width: figmaWidth(544), ...style }} key={index}>
      <div
        className="d-flex align-items-center mb-3"
        style={{ marginLeft: !icon ? 0 : 0, ...childStyle }}
      >
        {icon}
        <GothamMedium
          style={{
            fontSize: 16,
            marginBottom: 0,
            lineHeight: "24px",
            marginLeft: 8,
          }}
        >
          {title}
        </GothamMedium>
      </div>
      {data.map(({ title, msg }, index) => (
        <div className="mb-3" key={index}>
          <TitleText>{title}</TitleText>
          <PointText fsize={isApi && "14px"}>{msg}</PointText>
        </div>
      ))}
    </DetailsDiv>
  ));
};

export const TransactionDetailsRow = ({ dataDetails, style, isOverview }) => {
  return (
    <DetailsDiv
      style={{ ...style, paddingBottom: "0px", position: "relative" }}
    >
      <Row>
        {dataDetails?.map(({ title, msg, flag }, index) => {
          const isCenter = index == 2;
          return (
            <Col lg={3} key={index} className="position-relative">
              {isOverview && isCenter && (
                <img
                  src={transArrowIcon}
                  style={{
                    position: "absolute",
                    top: "20%",
                    left: "-20%",
                  }}
                />
              )}
              {title ? (
                <TitleText>{title}</TitleText>
              ) : (
                <TitleText>&nbsp;</TitleText>
              )}
              <PointText
                fsize={isOverview ? "16px!important" : "14px!important"}
                style={{ marginBottom: "24px" }}
              >
                {flag && (
                  <GetFlag
                    iso={flag}
                    style={{ marginRight: "10px", marginLeft: "-15px" }}
                  />
                )}
                {msg}
              </PointText>
            </Col>
          );
        })}
      </Row>
    </DetailsDiv>
  );
};

const TransactionDetailsHeader = ({
  item,
  handleClick,
  paymentOnClick,
  isSingle,
  isApi,
}) => {
  const { reference_id, state, batch_id, job_id, id } = item;
  const isCompleted = state == "completed";
  const apiCanDownload = isApi && isCompleted;

  const downloadReceipt = async (id) => {
    await asyncDownload({
      url: `/prints/${id}/disbursement_receipt.pdf`,
      filename: `Jack-Invoice-${id}.pdf`,
    });
  };

  return (
    <Row className="mx-4 justify-content-between">
      <div className=" mb-4">
        <div>
          <GothamRegular style={{ fontSize: "24px", lineHeight: "32px" }}>
            {reference_id}
          </GothamRegular>
          {Boolean(batch_id) && (
            <div className="d-flex mb-2">
              <GothamRegular>Included in batch&nbsp;</GothamRegular>
              <BatchIdLink onClick={() => handleClick(job_id)}>
                {batch_id}
              </BatchIdLink>
            </div>
          )}
          <StatusBadge state={state} />
        </div>
      </div>
      {apiCanDownload && (
        <CustomButton
          className="btn-blue"
          style={{ borderRadius: 18, height: 38 }}
          leftIcon={<img src={downloadIcon} className="mr-2 " />}
          onClick={() => {
            downloadReceipt(id);
          }}
        >
          Download Receipt
        </CustomButton>
      )}
      {!isApi && (
        <Buttons
          paymentOnClick={paymentOnClick}
          data={item}
          isSingle={isSingle}
        />
      )}
      <Buttons />
    </Row>
  );
};

const Buttons = ({
  paymentOnClick,
  isSingle,
  data,
  // data: { state, id, partner_bank_account, reference_id, is_refundable },
}) => {
  const { state, id, partner_bank_account, is_refundable } = data || {};
  const { isWaitingForPayment, isRejected, isCompleted } = booleanState(state);
  const [showModal, setShowModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const beneficiary = partner_bank_account || {};

  const refundToggle = () => {
    setRefundModal((p) => !p);
  };

  if (isWaitingForPayment) {
    const toggle = () => {
      setShowModal((p) => !p);
    };
    return (
      <div className="d-flex">
        <CustomButton
          className="btn-blue mr-2 px-4"
          style={{ height: 38 }}
          onClick={() => {
            paymentOnClick(id);
          }}
        >
          Payment Instructions
        </CustomButton>
      </div>
    );
  }

  if (isRejected) {
    return (
      <div className="d-flex">
        <CustomButton
          onClick={refundToggle}
          style={{ padding: "10px 74px 10px 74px" }}
        >
          Refund
        </CustomButton>
        <RefundModal
          isOpen={refundModal}
          toggle={refundToggle}
          item={beneficiary}
          isSingle={isSingle}
        />
      </div>
    );
  }

  if (isCompleted) {
    const downloadReceipt = async (id) => {
      await asyncDownload({
        url: `/prints/${id}/transaction_receipt.pdf`,
        filename: `Jack-Invoice-${id}.pdf`,
      });
    };
    return (
      <div className="d-flex">
        <CustomButton
          className="btn-blue mr-2 px-4"
          leftIcon={<img src={downloadIcon} className="mr-2 " />}
          onClick={() => {
            downloadReceipt(id);
          }}
        >
          Download Receipt
        </CustomButton>
      </div>
    );
  }

  if (is_refundable) {
    const toggle = () => {
      setRefundModal((p) => !p);
    };
    return (
      <>
        <div className="d-flex">
          <CustomButton
            style={{ paddingLeft: 74, paddingRight: 74 }}
            onClick={() => {
              toggle({});
            }}
          >
            Refund
          </CustomButton>
          <RefundModal
            isSingle={isSingle}
            isOpen={refundModal}
            toggle={toggle}
            item={{ ...beneficiary }}
          />
        </div>
      </>
    );
  }

  return null;
};

const TransactionStatus = ({ item }) => {
  const { state } = item;
  const { figmaWidth } = windowDimension();
  return (
    <>
      <DetailsDiv
        style={{
          width: figmaWidth(410),
          marginLeft: "1rem",
        }}
      >
        <div className="d-flex align-items-center mb-3">
          <GothamMedium
            style={{ fontSize: 16, marginBottom: 16, lineHeight: "24px" }}
          >
            Transaction Status
          </GothamMedium>
        </div>
        <TransactionStatusFlow status={state} item={item} />
      </DetailsDiv>
    </>
  );
};

const PaymentDetail = ({ item, transfer, isWallet }) => {
  let filteredBank = bankLists.filter((bankList) => bankList.bank === transfer);
  const { name = "", number = "", icon = "" } = filteredBank[0] || {};
  const { account_name, account_number, bank_name } = item || {};
  const { figmaWidth, figmaHeight } = windowDimension();

  if (!bank_name) return null;

  return (
    <>
      <DetailsDiv style={{ width: figmaWidth(544) }}>
        <div
          className="d-flex align-items-center mb-3"
          style={{ marginLeft: "-10px" }}
        >
          <img src={paymentIcon} className="ml-2" />
          <GothamMedium
            style={{
              fontSize: 16,
              marginBottom: 0,
              lineHeight: "24px",
              marginLeft: 8,
            }}
          >
            Payment Details
          </GothamMedium>
        </div>
        {isWallet ? (
          <div className="mb-3">
            <TitleText>You Transferred from</TitleText>
            <PointText>Wallet</PointText>
          </div>
        ) : (
          <>
            <div className="mb-3">
              <TitleText>You Transferred to</TitleText>
              <div className="d-flex align-items-center">
                <img src={icon} className="mr-2" />
                <PointText className="mb-0">{name}</PointText>
              </div>
              <PointText>{number}</PointText>
            </div>
            <div className="mb-3">
              <TitleText>You Transferred from</TitleText>
              <PointText>{bank_name}</PointText>
              <PointText>{account_number}</PointText>
              <PointText>{account_name}</PointText>
            </div>
          </>
        )}
      </DetailsDiv>
    </>
  );
};

export const TransactionProgressBar = ({ data }) => {
  const { isTabOrPhone } = windowDimension();

  return (
    <div>
      {data.map((item, index) => {
        const {
          date,
          title,
          text,
          textGrey,
          titlePink,
          titleRed,
          titleHeight,
          textHeight,
        } = item;
        const isLast = data.length - 1 === index;

        if (isTabOrPhone) {
          const heightAddons = Boolean(date) ? 15 : 0;

          const lineHeightRaw = titleHeight
            ? titleHeight
            : textHeight
            ? textHeight
            : isLast && "0px";

          const getLineHeight = (lineHeightRaw) => {
            if (String(lineHeightRaw).includes("px")) {
              const number = lineHeightRaw.split("px")[0];
              const addWithHeightAddons = Number(number) + Number(heightAddons);
              return `${addWithHeightAddons}px`;
            }
            return lineHeightRaw;
          };

          const lineHeight = getLineHeight(lineHeightRaw);

          return (
            <div key={index}>
              <div className=" d-flex mb-4 pl-3">
                <div className="position-relative ">
                  <ProgressPoint
                    circleColor={
                      textGrey ? colors.greyC2 : title ? "white" : colors.blue48
                    }
                    lineColor={colors.greyC2}
                    lineHeight={lineHeight}
                    circleDiameter={title && `12px`}
                    borderColor={
                      titlePink
                        ? `2px solid ${colors.pink}`
                        : titleRed && `2px solid #EB5B42`
                    }
                    circleLeft={title && "-1px"}
                    circleTop={title && "0px"}
                  >
                    <div className="ml-2">
                      {Boolean(date) && (
                        <GothamRegular
                          className="font10 mb-1"
                          style={{
                            color: colors.grey6c,
                          }}
                        >
                          {date}
                        </GothamRegular>
                      )}

                      {title && (
                        <GothamMedium
                          style={{
                            color: titlePink
                              ? colors.pink
                              : titleRed
                              ? "#EB5B42"
                              : colors.grey33,
                            marginBottom: 0,
                            marginTop: -5,
                          }}
                          className="font16"
                        >
                          {title}
                        </GothamMedium>
                      )}
                      {text && (
                        <GothamRegular
                          className="mb-0"
                          style={{
                            fontSize: 12,
                            color: textGrey && colors.greyC2,
                          }}
                        >
                          {text}
                        </GothamRegular>
                      )}
                    </div>
                  </ProgressPoint>
                </div>
              </div>
            </div>
          );
        }
        return (
          <div key={index}>
            <div className="row d-flex mb-4 ml-0">
              <div className="col-md-4 px-0">
                <GothamRegular style={{ color: colors.grey6c, fontSize: 10 }}>
                  {date}
                </GothamRegular>
              </div>
              <div
                className="position-relative col-md-8"
                style={{ marginLeft: "-20px" }}
              >
                <ProgressPoint
                  circleColor={
                    textGrey ? colors.greyC2 : title ? "white" : colors.blue48
                  }
                  lineColor={colors.greyC2}
                  lineHeight={
                    titleHeight
                      ? titleHeight
                      : textHeight
                      ? textHeight
                      : isLast && "0px"
                  }
                  circleDiameter={title && `12px`}
                  borderColor={
                    titlePink
                      ? `2px solid ${colors.pink}`
                      : titleRed && `2px solid #EB5B42`
                  }
                  circleLeft={title && "-1px"}
                  circleTop={title && "0px"}
                >
                  {title && (
                    <GothamMedium
                      style={{
                        color: titlePink
                          ? colors.pink
                          : titleRed
                          ? "#EB5B42"
                          : colors.grey33,
                        fontSize: 16,
                        marginBottom: 0,
                        lineHeight: "24px",
                        marginTop: -5,
                      }}
                    >
                      {title}
                    </GothamMedium>
                  )}
                  {text && (
                    <GothamRegular
                      className="mb-0"
                      style={{ fontSize: 12, color: textGrey && colors.greyC2 }}
                    >
                      {text}
                    </GothamRegular>
                  )}
                </ProgressPoint>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const TransactionStatusFlow = ({ status, item, type, isBatch }) => {
  const array = transactionStatusFormatter({ status, item, type, isBatch });
  return <UpdateTracker updates={array} />;
  // return <StatusPayrollLine array={array} style={{ marginTop: 20 }} isModal />;
};

const DetailsDiv = styled.div`
  background-color: white;
  border-radius: 12px;
  border: 1px solid rgba(194, 194, 194, 0.5);
  padding: 16px 16px 0px 16px;
  margin-bottom: 1.5rem;
`;

const TitleText = styled(GothamRegular)`
  color: #727272 !important;
  font-size: 10px !important;
  line-height: 16px;
  margin-bottom: 0px;
`;

const PointText = styled(GothamMedium)`
  font-size: ${(p) => p.fsize || "14px !important"};
  line-height: 22px;
  margin-bottom: 0;
  word-wrap: break-word;
`;

const BatchIdLink = styled(GothamMedium)`
  font-size: 14px;
  color: #49abb8;
  text-decoration: underline;
  cursor: pointer;
`;

const ProgressPoint = styled.div`
  margin-left: 10px;
  ::before {
    content: "";
    width: ${(props) => props.circleDiameter || "9px"};
    height: ${(props) => props.circleDiameter || "9px"};
    background-color: ${(props) => props.circleColor || "#C2C2C2"};
    border: ${(p) => p.borderColor};
    border-radius: 50%;
    position: absolute;
    top: ${(props) => props.circleTop || "3px"};
    left: ${(props) => props.circleLeft || "0px"};
    z-index: 3;
  }
  ::after {
    content: "";
    width: 1px;
    height: ${(props) => props.lineHeight || "45px"};
    background-color: ${(props) => props.lineColor || "#C2C2C2"};
    position: absolute;
    left: 4px;
    bottom: ${(props) => props.lineBottom || "-35px"};
  }
`;
