import { isEmpty, pickBy } from "lodash";
import {
  deepRemoveDuplicates,
  unformatCurrency,
} from "../../../components/tools";
import { queryArray } from "../../eWalletComponents/home/main/filter/modal/content/parent";

export const taskResultFormatter = (fetchedData, prevData) => {
  const data = deepRemoveDuplicates(
    [...(prevData?.data ?? []), ...(fetchedData?.data ?? [])],
    "id"
  );

  const page = fetchedData?.page ?? 1;

  return {
    ...fetchedData,
    data,
    page,
  };
};

export const arrayizedFilterFormatter = (filterQuery) => {
  const arrayizedFilter = Array.isArray(filterQuery)
    ? filterQuery
    : [filterQuery];

  return arrayizedFilter.filter((filter) => filter);
};

export const amountRangePayloadFormatter = ({
  amountMinFilter = "",
  amountMaxFilter = "",
}) => {
  const checker = ({ gte, lte }) => {
    const obj = { gte, lte };
    const result = pickBy(obj, (value) => value);
    const empty = isEmpty(result);
    return { empty, result };
  };

  const amountDecider = () => {
    const { empty, result } = checker({
      gte: Number(unformatCurrency(amountMinFilter)),
      lte: Number(unformatCurrency(amountMaxFilter)),
    });

    if (empty) return null;

    return { amount: result };
  };

  const range = [amountDecider()].filter((item) => item);

  if (range.length) return { range };

  return {};
};

export const arrayPayloadFormatter = ({
  queryName = "",
  name = "",
  query = {},
}) => {
  const array = queryArray(query, queryName);
  if (!array.length) return {};
  let obj = {};
  obj[name] = array.map((item) => Number(item));
  return obj;
};

export const amountEqualPayloadFormatter = (amount = "") => {
  if (!amount) return {};
  return { amount_numeric: Number(unformatCurrency(amount)) };
};
