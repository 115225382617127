import { useForm } from "@tools";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import { GothamMedium } from "../../../../../../components/Text";
import { colors } from "../../../../../../assets/colors";
import { CustomRichTextArea } from "../../../../../../components/CustomRichTextArea";
import BottomContainer from "../../components/BottomContainer";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { useMutation } from "../../../../../../tools/api";
import { ToasterHook } from "../../../../../../contexts/ToasterContext";
import { eventsTracker } from "../../../../../../universalFunctions/events";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const AddDescription = ({
  handleBack,
  transactionDetail,
  refetchTransactionDetail,
  onUploadDocument = () => {},
}) => {
  const { t } = useTranslation("credit/credit");
  const { notes } = transactionDetail || {};
  const { successSnackBar } = ToasterHook();

  const headerTitle = notes ? t("Edit description") : t("Add description");
  const primaryButtonText = notes ? "Edit" : t("Save");

  const { mutation: mutationNotes, loading: loadingNotes } = useMutation({
    url: `/card_transactions/${transactionDetail?.id}/update`,
    afterSuccess: () => {
      onUploadDocument();
      refetchTransactionDetail();
      handleBack();
      successSnackBar({ msg: "Transaction description has been updated" });
      eventsTracker("card_transaction_description_updated", {
        card_transaction_id: transactionDetail?.id,
      });
    },
  });

  const useFormObj = useForm();
  const { handleSubmit, watch } = useFormObj;

  const descriptionWatch = watch("description");
  const isDescriptionSame = descriptionWatch === notes;

  const refRichText = useRef(null);
  const richTextInputHeight = refRichText?.current?.clientHeight;

  const onSubmit = async (data) => {
    const { description } = data;
    await mutationNotes({
      notes: description,
    });
  };

  const containerStyle = {
    position: "relative",
    height: "100%",
    marginBottom: `${richTextInputHeight - 450}px`,
  };

  const innerContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  };

  const titleStyle = {
    marginBottom: "32px",
    color: colors.neutral900,
    fontSize: "24px",
  };

  const richTextStyle = {
    marginTop: "-32px",
  };

  const bottomButtonContainerStyle = {
    padding: "20px 20px 0px 20px",
    borderTop: `1px solid ${colors.neutral500}`,
    display: "flex",
    gap: "16px",
  };

  const buttonStyle = {
    width: "100%",
  };

  return (
    <div style={containerStyle}>
      <div style={innerContainerStyle}>
        <GothamMedium style={titleStyle}>{headerTitle}</GothamMedium>
        <CustomRichTextArea
          label={t("Description")}
          name="description"
          useFormObj={useFormObj}
          defaultValue={notes}
          helperText="Please write a different description from the current one."
          placeholder={t("Explain more about your transaction")}
          forwardRef={refRichText}
          style={richTextStyle}
        />
      </div>
      <BottomContainer style={bottomButtonContainerStyle}>
        <ButtonJack
          leftIcon={<JackIcons name="close-circle" fill={colors.neutral900} />}
          type="outline"
          style={buttonStyle}
          onClick={handleBack}
        >
          {t("cancel")}
        </ButtonJack>
        <ButtonJack
          leftIcon={
            <JackIcons name="checkmark-circle-2" fill={colors.neutral900} />
          }
          style={buttonStyle}
          isLoading={loadingNotes}
          isLoadingLeft
          disabled={isDescriptionSame}
          onClick={handleSubmit(onSubmit)}
        >
          {primaryButtonText}
        </ButtonJack>
      </BottomContainer>
    </div>
  );
};

export default AddDescription;
