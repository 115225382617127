export const countries = [
  { code: "DZ", name: "algeria" },
  { code: "TL", name: "eastTimor" },
  { code: "CP", name: "rapaNui" },
  { code: "AB", name: "abkhazia" },
  { code: "AF", name: "afghanistan" },
  { code: "AX", name: "alandIslands" },
  { code: "AL", name: "albania" },
  { code: "AS", name: "americanSamoa" },
  { code: "AD", name: "andorra" },
  { code: "AO", name: "angola" },
  { code: "AI", name: "anguilla" },
  { code: "AG", name: "antiguaAndBarbuda" },
  { code: "AR", name: "argentina" },
  { code: "AM", name: "armenia" },
  { code: "AW", name: "aruba" },
  { code: "AU", name: "australia" },
  { code: "AT", name: "austria" },
  { code: "AZ", name: "azerbaijan" },
  //   { code: "PT-20", name: "azoresIslands" },
  { code: "BS", name: "bahamas" },
  { code: "BH", name: "bahrain" },
  //   { code: "ES-07", name: "balearicIslands" },
  { code: "BD", name: "bangladesh" },
  { code: "BB", name: "barbados" },
  //   { code: "ES-16", name: "basqueCountry" },
  { code: "BY", name: "belarus" },
  { code: "BE", name: "belgium" },
  { code: "BZ", name: "belize" },
  { code: "BJ", name: "benin" },
  { code: "BM", name: "bermuda" },
  { code: "BT", name: "bhutan" },
  { code: "BO", name: "bolivia" },
  { code: "BQ", name: "bonaire" },
  { code: "BA", name: "bosniaAndHerzegovina" },
  { code: "BW", name: "botswana" },
  { code: "BR", name: "brazil" },
  //   { code: "CA-BC", name: "britishColumbia" },
  { code: "IO", name: "britishIndianOceanTerritory" },
  { code: "VG", name: "britishVirginIslands" },
  { code: "BN", name: "brunei" },
  { code: "BG", name: "bulgaria" },
  { code: "BF", name: "burkinaFaso" },
  { code: "BI", name: "burundi" },
  { code: "KH", name: "cambodia" },
  { code: "CM", name: "cameroon" },
  { code: "CA", name: "canada" },
  { code: "IC", name: "canaryIslands" },
  { code: "CV", name: "capeVerde" },
  { code: "KY", name: "caymanIslands" },
  { code: "CF", name: "centralAfricanRepublic" },
  { code: "EA", name: "ceuta" },
  { code: "TD", name: "chad" },
  { code: "CL", name: "chile" },
  { code: "CN", name: "china" },
  { code: "CC", name: "cocosIsland" },
  { code: "CO", name: "colombia" },
  { code: "KM", name: "comoros" },
  { code: "CK", name: "cookIslands" },
  //   { code: "Corsica", name: "corsica" },
  { code: "CR", name: "costaRica" },
  { code: "HR", name: "croatia" },
  { code: "CU", name: "cuba" },
  { code: "CW", name: "curacao" },
  { code: "CY", name: "cyprus" },
  { code: "CZ", name: "czechRepublic" },
  { code: "CD", name: "democraticRepublicOfCongo" },
  { code: "DK", name: "denmark" },
  { code: "DJ", name: "djibouti" },
  { code: "DM", name: "dominica" },
  { code: "DO", name: "dominicanRepublic" },
  { code: "EC", name: "ecuador" },
  { code: "EG", name: "egypt" },
  { code: "SV", name: "elSalvador" },
  //   { code: "GB-ENG", name: "england" },
  { code: "GQ", name: "equatorialGuinea" },
  { code: "ER", name: "eritrea" },
  { code: "EE", name: "estonia" },
  { code: "ET", name: "ethiopia" },
  { code: "EU", name: "europeanUnion" },
  { code: "FK", name: "falklandIslands" },
  { code: "FO", name: "faroeIslands" },
  { code: "FJ", name: "fiji" },
  { code: "FI", name: "finland" },
  { code: "FR", name: "france" },
  { code: "PF", name: "frenchPolynesia" },
  { code: "GA", name: "gabon" },
  { code: "GalapagosIslands", name: "galapagosIslands" },
  { code: "GM", name: "gambia" },
  { code: "GE", name: "georgia" },
  { code: "DE", name: "germany" },
  { code: "GH", name: "ghana" },
  { code: "GI", name: "gibraltar" },
  { code: "GR", name: "greece" },
  { code: "GL", name: "greenland" },
  { code: "GD", name: "grenada" },
  { code: "GU", name: "guam" },
  { code: "GT", name: "guatemala" },
  { code: "GG", name: "guernsey" },
  { code: "GW", name: "guineaBissau" },
  { code: "GN", name: "guinea" },
  { code: "GY", name: "guyana" },
  { code: "HT", name: "haiti" },
  { code: "HI", name: "hawaii" },
  { code: "HN", name: "honduras" },
  { code: "HK", name: "hongKong" },
  { code: "HU", name: "hungary" },
  { code: "IS", name: "iceland" },
  { code: "IN", name: "india" },
  { code: "ID", name: "indonesia" },
  { code: "IR", name: "iran" },
  { code: "IQ", name: "iraq" },
  { code: "IE", name: "ireland" },
  { code: "IM", name: "isleOfMan" },
  { code: "IL", name: "israel" },
  { code: "IT", name: "italy" },
  { code: "CI", name: "ivoryCoast" },
  { code: "JM", name: "jamaica" },
  { code: "JP", name: "japan" },
  { code: "JE", name: "jersey" },
  { code: "JO", name: "jordan" },
  { code: "KZ", name: "kazakhstan" },
  { code: "KE", name: "kenya" },
  { code: "KI", name: "kiribati" },
  { code: "XK", name: "kosovo" },
  { code: "KW", name: "kuwait" },
  { code: "KG", name: "kyrgyzstan" },
  { code: "LA", name: "laos" },
  { code: "LV", name: "latvia" },
  { code: "LB", name: "lebanon" },
  { code: "LS", name: "lesotho" },
  { code: "LR", name: "liberia" },
  { code: "LY", name: "libya" },
  { code: "LI", name: "liechtenstein" },
  { code: "LT", name: "lithuania" },
  { code: "LU", name: "luxembourg" },
  { code: "MO", name: "macao" },
  { code: "MG", name: "madagascar" },
  //   { code: "PT-30", name: "madeira" },
  { code: "MW", name: "malawi" },
  { code: "MY", name: "malaysia" },
  { code: "MV", name: "maldives" },
  { code: "ML", name: "mali" },
  { code: "MT", name: "malta" },
  { code: "MH", name: "marshallIsland" },
  { code: "MQ", name: "martinique" },
  { code: "MR", name: "mauritania" },
  { code: "MU", name: "mauritius" },
  { code: "EA", name: "melilla" },
  { code: "MX", name: "mexico" },
  { code: "FM", name: "micronesia" },
  { code: "MD", name: "moldova" },
  { code: "MC", name: "monaco" },
  { code: "MN", name: "mongolia" },
  { code: "ME", name: "montenegro" },
  { code: "MS", name: "montserrat" },
  { code: "MA", name: "morocco" },
  { code: "MZ", name: "mozambique" },
  { code: "MM", name: "myanmar" },
  { code: "NA", name: "namibia" },
  { code: "NT", name: "nato" },
  { code: "NR", name: "nauru" },
  { code: "NP", name: "nepal" },
  { code: "NL", name: "netherlands" },
  { code: "NZ", name: "newZealand" },
  { code: "NI", name: "nicaragua" },
  { code: "NE", name: "niger" },
  { code: "NG", name: "nigeria" },
  { code: "NU", name: "niue" },
  { code: "NF", name: "norfolkIsland" },
  { code: "KP", name: "northKorea" },
  { code: "CY", name: "northernCyprus" },
  { code: "MP", name: "northernMarianasIslands" },
  { code: "NO", name: "norway" },
  { code: "OM", name: "oman" },
  { code: "OA", name: "orkneyIslands" },
  { code: "OS", name: "ossetia" },
  { code: "PK", name: "pakistan" },
  { code: "PW", name: "palau" },
  { code: "PS", name: "palestine" },
  { code: "PA", name: "panama" },
  { code: "PG", name: "papuaNewGuinea" },
  { code: "PY", name: "paraguay" },
  { code: "PE", name: "peru" },
  { code: "PH", name: "philippines" },
  { code: "PN", name: "pitcairnIslands" },
  { code: "PL", name: "poland" },
  { code: "PT", name: "portugal" },
  { code: "PR", name: "puertoRico" },
  { code: "QA", name: "qatar" },
  { code: "MK", name: "republicOfMacedonia" },
  { code: "CG", name: "republicOfTheCongo" },
  { code: "RO", name: "romania" },
  { code: "RU", name: "russia" },
  { code: "RW", name: "rwanda" },
  { code: "BQ", name: "sabaIsland" },
  { code: "EH", name: "sahrawiArabDemocraticRepublic" },
  { code: "WS", name: "samoa" },
  { code: "SM", name: "sanMarino" },
  { code: "ST", name: "saoTomeAndPrince" },
  { code: "IT", name: "sardinia" },
  { code: "SA", name: "saudiArabia" },
  //   { code: "GB-SCT", name: "scotland" },
  { code: "SN", name: "senegal" },
  { code: "RS", name: "serbia" },
  { code: "SC", name: "seychelles" },
  { code: "SL", name: "sierraLeone" },
  { code: "SG", name: "singapore" },
  //   { code: "BQ-SE", name: "sintEustatius" },
  { code: "SX", name: "sintMaarten" },
  { code: "SK", name: "slovakia" },
  { code: "SI", name: "slovenia" },
  { code: "SB", name: "solomonIslands" },
  { code: "SO", name: "somalia" },
  { code: "XS", name: "somaliland" },
  { code: "ZA", name: "southAfrica" },
  { code: "KR", name: "southKorea" },
  { code: "SS", name: "southSudan" },
  { code: "ES", name: "spain" },
  { code: "LK", name: "sriLanka" },
  { code: "BL", name: "stBarts" },
  { code: "LC", name: "stLucia" },
  { code: "VC", name: "stVincentAndTheGrenadines" },
  { code: "SD", name: "sudan" },
  { code: "SR", name: "suriname" },
  { code: "SZ", name: "swaziland" },
  { code: "SE", name: "sweden" },
  { code: "CH", name: "switzerland" },
  { code: "SY", name: "syria" },
  { code: "TW", name: "taiwan" },
  { code: "TJ", name: "tajikistan" },
  { code: "TZ", name: "tanzania" },
  { code: "TH", name: "thailand" },
  //   { code: "CN-XZ", name: "tibet" },
  { code: "TG", name: "togo" },
  { code: "TK", name: "tokelau" },
  { code: "TO", name: "tonga" },
  { code: "MD", name: "transnistria" },
  { code: "TT", name: "trinidadAndTobago" },
  { code: "TN", name: "tunisia" },
  { code: "TR", name: "turkey" },
  { code: "TM", name: "turkmenistan" },
  { code: "TC", name: "turksAndCaicos" },
  { code: "TV", name: "tuvalu" },
  { code: "UG", name: "uganda" },
  { code: "UA", name: "ukraine" },
  { code: "AE", name: "unitedArabEmirates" },
  { code: "GB", name: "unitedKingdom" },
  { code: "UN", name: "unitedNations" },
  { code: "US", name: "unitedStates" },
  { code: "UY", name: "uruguay" },
  { code: "UZ", name: "uzbekistN" },
  { code: "VU", name: "vanuatu" },
  { code: "VA", name: "vaticanCity" },
  { code: "VE", name: "venezuela" },
  { code: "VN", name: "vietnam" },
  { code: "VI", name: "virginIslands" },
  { code: "WA", name: "wales" },
  { code: "YE", name: "yemen" },
  { code: "ZM", name: "zambia" },
  { code: "ZW", name: "zimbabwe" },
];
