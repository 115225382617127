import { fetch } from "tools/api";
import { LocalTransferBank, LocalTransferFinalData } from "./mainContent.types";

const useLocalRecipients = () => {
  const { data, loading } = fetch<LocalTransferFinalData>({
    url: "/local_transaction_batches/payers_by_category",
    formatter: (res) => {
      const { e_wallets, va_banks, banks } = res?.data ?? {};

      const mapper = (item: LocalTransferBank) => {
        const { full_name, name, id, alias } = item;
        const label = alias ? alias.toUpperCase() : name?.toUpperCase() || "";
        return { id, value: full_name, label };
      };

      return {
        e_wallets: e_wallets?.map(mapper) || [],
        va_banks: va_banks?.map(mapper) || [],
        banks: banks?.map(mapper) || [],
      };
    },
  });
  return { data, loading };
};

export default useLocalRecipients;
