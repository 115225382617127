import ancestoryLinesFormatter from "./ancestoryLinesFormatter";
import findRetryIterationCount from "./findRetryIterationCount";
import longestAncestoryLineDecider from "./longestAncestoryLineDecider";

const retriedTrxCountDecider = (transactions = []) => {
  const ancestoryLines = ancestoryLinesFormatter(transactions);
  const longestAncestoryLine = longestAncestoryLineDecider(ancestoryLines);
  const longestAncestoryLineLength = longestAncestoryLine.length;
  const retriedTrxCount = findRetryIterationCount({
    ancestoryLines,
    desiredAncestoryLineLength: longestAncestoryLineLength,
  });
  return retriedTrxCount;
};

export default retriedTrxCountDecider;
