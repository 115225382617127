import { useState } from "react";
import { useClickOutside } from "../../universalFunctions/useClickOutside";
import { GothamRegular } from "../../components/Text";
import { JackIcons } from "../../assets/jackIcons/parent";
import { jackColors } from "../../assets/colors";

export const ModalAction = ({ close, array, onClick, itemData, isActive }) => {
  const [ref, setRef] = useState(null);

  useClickOutside({
    clickOutside: () => setTimeout(close, 20),
    ref: { current: ref },
  });

  return (
    <div
      ref={(e) => setRef(e)}
      style={{
        position: "absolute",
        zIndex: 1,
        right: 0,
        bottom: isActive ? -35 : -75,
        padding: "8px 0px",
        backgroundColor: "white",
        width: 160,
        boxShadow:
          " 0px 3px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12)",
        borderRadius: 8,
      }}
    >
      {array?.map((item, index) => {
        const { title, action, isRed, icon } = item || {};
        return (
          <div
            key={index}
            className="d-flex align-items-center hover-600"
            style={{ padding: "10px 8px", cursor: "pointer" }}
            onClick={() => {
              onClick(action, itemData);
              close();
            }}
          >
            <JackIcons
              name={icon}
              fill={isRed ? jackColors.redDC : jackColors.neutral900}
              style={{ marginRight: 18, width: 20, height: 20 }}
            />
            <GothamRegular>{title}</GothamRegular>
          </div>
        );
      })}
    </div>
  );
};
