import { GothamMedium } from "../../Text";
import styles from "./FeeCalculation.module.css";
import getCalculationText from "./helpers/getCalculationText";
import getTotalText from "./helpers/getTotalText";

const FeeCalculation = ({
  someTrxAreNotCovered = false,
  totalFeeRaw = 0,
  totalFreeTrx = 0,
  totalUncoveredTrx = 0,
  individualFeeUnformatted = 0,
  desc = "",
  individualFee = "",
}) => {
  const calculationText = getCalculationText({
    desc,
    individualFee,
    totalUncoveredTrx,
    someTrxAreNotCovered,
  });

  const totalText = getTotalText({
    totalFeeRaw,
    totalFreeTrx,
    someTrxAreNotCovered,
    individualFeeUnformatted,
  });

  const classNameToUse = someTrxAreNotCovered
    ? styles["text-blue"]
    : styles["text-gray-striked"];

  return (
    <div className={styles["main-container"]}>
      <GothamMedium className={classNameToUse}>{calculationText}</GothamMedium>
      <GothamMedium className={styles["text-blue"]}>{totalText}</GothamMedium>
    </div>
  );
};

export default FeeCalculation;
