import { customDateFormatter } from "../../../../components/tools";
import UpdateTracker from "../../../../components/UpdateTracker/parent";
import { useCrossBorderSingleWorkflow } from "../../crossBorderSingleModal/hooks/workflow";
import { CancelledBox } from "../logics";
import { ApprovedByInfo, RejectedByInfo } from "./components";

const approverLineArrayCutter = (approverLineArray) => {
  const isLastApprover =
    approverLineArray[approverLineArray.length - 1]?.isActive;

  if (isLastApprover) return approverLineArray;

  const activeIndex = approverLineArray?.findIndex(({ isActive }) => !isActive);

  return approverLineArray?.slice(0, activeIndex);
};

export const ReviewModalUpdateTracker = ({
  containerStyle,
  transaction,
  detailId,
}) => {
  const { reimbursement_child } = transaction;
  const {
    firstLineObj,
    isDraft,
    rejection_note,
    approverLineArray: approverlineArrayRaw,
    validatingObj,
    completedLineObj,
    isCompleted,
    isCancelled,
    cancelledLineObj,
  } = useCrossBorderSingleWorkflow(transaction);

  const {
    state,
    rejection_note: childRejectNote,
    option,
  } = reimbursement_child?.filter(({ id }) => id == detailId)?.pop() ?? {};
  const {
    state: optionState,
    rejection_note: optionRejectionNote,
    mark_as_rejected_at,
  } = option ?? {};

  const isMarkAsApproved = optionState == "approved";
  const isRejected = state == ("cancelled" || "canceled");

  const rejectionNoteDecider = () => {
    if (optionState == "rejected") return optionRejectionNote;
    if (isRejected) return childRejectNote;

    return null;
  };

  const approverLineArray = approverLineArrayCutter(approverlineArrayRaw);
  const arrayDecider = () => {
    if (isRejected) return [firstLineObj, cancelledLineObj];

    if (optionState) {
      if (isMarkAsApproved) {
        return [
          firstLineObj,
          ...approverLineArray?.slice(0, -1),
          {
            text: "Marked as approved by You",
            isActive: true,
            isCompleted: true,
          },
        ];
      }
      return [
        firstLineObj,
        ...approverLineArray?.slice(0, -1),
        {
          text: "Marked as rejected by You",
          isRejected: true,
          isActive: true,
          date: customDateFormatter(mark_as_rejected_at),
        },
      ];
    }
    return [firstLineObj, ...approverLineArray];
  };
  return (
    <div className="w-100" style={{ marginTop: 12, ...containerStyle }}>
      <UpdateTracker
        updates={arrayDecider()}
        rejectionNote={rejectionNoteDecider()}
      />
    </div>
  );
};

export const DetailModalUpdateTracker = ({
  containerStyle,
  transaction,
  detailId,
  workflowObjs,
}) => {
  const {
    firstLineObj,
    isDraft,
    rejection_note,
    approverLineArray: approverlineArrayRaw,
    validatingObj,
    completedLineObj,
    isCompleted,
    isCancelled,
    cancelledLineObj,
  } = workflowObjs;

  const {
    state,
    rejection_note: childRejectNote,
    rejected_by_id,
    user_id,
  } = transaction?.filter(({ id }) => id == detailId)?.pop() ?? {};
  const isCanceled = state == "cancelled";
  const isRejected = isCanceled && Boolean(rejected_by_id);

  const approverLineArray = approverLineArrayCutter(approverlineArrayRaw);
  const rejectLineObj = {
    ...cancelledLineObj,
    text: cancelledLineObj?.text?.replace("Canceled by", "Rejected by"),
  };

  const arrayDecider = () => {
    if (isRejected) return [firstLineObj, rejectLineObj];
    if (isCanceled) return [firstLineObj, cancelledLineObj];

    const completedLineArray = approverLineArray?.map((data, index) => {
      if (index == approverLineArray?.length - 1) {
        return { ...data, isCompleted: true };
      }
      return data;
    });

    if (isCompleted) return [firstLineObj, ...completedLineArray];
    return [firstLineObj, ...approverLineArray];
  };

  if (isRejected)
    return (
      <RejectedByInfo
        rejection_note={childRejectNote}
        user_id={rejected_by_id}
        canEdit={false}
      />
    );
  if (isCanceled)
    return (
      <CancelledBox
        isDrafter={false}
        rejection_note={rejection_note || childRejectNote}
        user_id={user_id}
      />
    );

  if (isCompleted) {
    const lastapproverId =
      arrayDecider()?.pop()?.data?.[0]?.approved_by_user_id ?? null;
    return <ApprovedByInfo userId={lastapproverId} />;
  }
  return null;
  // return (
  //   <div className="w-100" style={{ marginTop: 12, ...containerStyle }}>
  //     <UpdateTracker
  //       updates={arrayDecider()}
  //       rejectionNote={rejectionNoteDecider()}
  //     />
  //   </div>
  // );
};
