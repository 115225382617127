import { jackColors } from "../../../../../assets/colors";
import { hexToRgbaConverter } from "../../helpers";
import CategoryIcon from "../CategoryIcon";
import styles from "./ContainedCategoryIcon.module.css";

const ContainedCategoryIcon = ({ color = "", name = "", style = {} }) => {
  const colorToUse = color || jackColors.neutral700;

  const iconBorderColor = hexToRgbaConverter(colorToUse, 0.2);
  const iconBackgroundColor = hexToRgbaConverter(colorToUse, 0.1);

  return (
    <div
      className={styles["contained-category-icon"]}
      style={{
        backgroundColor: iconBackgroundColor,
        border: `1px solid ${iconBorderColor}`,
        ...style,
      }}
    >
      <CategoryIcon name={name} fill={colorToUse} style={{ scale: "0.8" }} />
    </div>
  );
};

export default ContainedCategoryIcon;
