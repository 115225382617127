import { booleanState } from "../../components/Status";
import { CancelledFormatter } from "./cancelled";
import { CompletedFormatter } from "./completed";
import { OnProgressFormatter } from "./onProgress";
import { RefundFormatter } from "./refund";
import { RejectedFormatter } from "./rejected";
import { ValidatingFormatter } from "./validating";
import { WaitingForPaymentFormatter } from "./waitingForPayment";
import { AwaitingRefundFormatter } from "./awaitingRefund";
import { digitDateWithDigitHourFormatter } from "../../components/tools";
import { failedFormatter } from "./failed";
import { ExpiredFormatter } from "./expired";

export const transactionStatusFormatter = ({ status, item, type }) => {
  if (type === "approval") {
    return [
      {
        date: digitDateWithDigitHourFormatter(item?.created_at),
        text: "You created a transaction",
      },
      {
        date: digitDateWithDigitHourFormatter(item?.created_at),
        title: "Waiting for Approval",
        titlePink: true,
        titleHeight: "80px",
        text: "Transaction created. Waiting for approval review",
      },
      {
        text: "Completed",
        textGrey: true,
      },
    ];
  }

  const {
    isWaitingForPayment,
    isValidatingPayment,
    isOnProgress,
    isCompleted,
    isCancelled,
    isRejected,
    isRefund,
    isAwaitingRefund,
    isExpired,
    isPaid
  } = booleanState(status);

  const isFailed = status == "failed";

  if (isWaitingForPayment) return WaitingForPaymentFormatter(item);
  if (isAwaitingRefund) return AwaitingRefundFormatter(item);
  if (isValidatingPayment) return ValidatingFormatter(item);
  if (isOnProgress) return OnProgressFormatter(item);
  if (isCompleted || isPaid) return CompletedFormatter(item);
  if (isCancelled) return CancelledFormatter(item);
  if (isRejected) return RejectedFormatter(item);
  if (isExpired) return ExpiredFormatter(item);
  if (isRefund) return RefundFormatter(item);
  if (isFailed) return failedFormatter(item);

  return [];
};
