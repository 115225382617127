import { colors } from "../../../assets/colors";
import { GothamRegular, SpanColor } from "../../../components/Text";
import { digitDateWithDigitHourFormatter } from "../../../components/tools";
import UpdateTracker from "../../../components/UpdateTracker/parent";
import {
  ReasonNotes,
  StatusPayrollLine,
} from "../../../pageComponents/payrollCreateComponents/loop/success/status";
import { useCrossBorderSingleWorkflow } from "../crossBorderSingleModal/hooks/workflow";

export const LocalDisbursementProgressModal = ({ data, isModal = true }) => {
  const { state, status_history } = data?.data || {};
  const isDraft = state == "draft" || state == "validate_recipient";
  const isWaitingForPayment = state == "waiting_for_payment";
  const isWaitingForConfirmation = state == "waiting_for_confirmation";
  const isOnProgress = state == "on_progress" || "on-progress";
  const isCompleted = state == "completed";
  const isFailed = state == "failed";
  const isWaitingRefund = state == "awaiting_refund";
  const isRefunded = state == "refunded";
  const isPartialFailed = state == "partial_failed";

  const createdAtFunc = (state) =>
    (status_history || []).filter(({ to }) => to == state).pop()?.created_at;

  const createdDraft =
    createdAtFunc("validate_recipient") || createdAtFunc("draft");

  const createdByIndex = (index = 0) =>
    (status_history || [])[(status_history || []).length - 1 - index]
      ?.created_at;

  const getArray = () => {
    if (isDraft) return [];
    // return [
    //   {
    //     date: digitDateWithDigitHourFormatter(createdDraft),
    //     title: "Draft",
    //     text: "Edit draft to continue transaction",
    //     titleHeight: "65px",
    //     titlePink: true,
    //   },
    //   { text: "Payment", textGrey: true },
    //   { text: "On Progress", textGrey: true },
    //   { text: "Completed", textGrey: true },
    // ];

    const createdTrx = {
      date: digitDateWithDigitHourFormatter(createdDraft),
      text: "You created a transaction",
      isActive: true,
    };

    const weReceivedYourPayment = (index = 3) => ({
      date: digitDateWithDigitHourFormatter(createdByIndex(index)),
      text: "Your payment has been received",
      isActive: true,
    });

    const weSentYourMoney = (index = 2) => ({
      date: digitDateWithDigitHourFormatter(createdByIndex(index)),
      text: "We sent out your money to the recipient",
      isActive: true,
    });

    if (isRefunded)
      return [
        createdTrx,
        weReceivedYourPayment(),
        weSentYourMoney(),
        {
          date: digitDateWithDigitHourFormatter(createdByIndex(1)),
          text: "The recipient’s bank has declined the transaction",
        },
        {
          date: digitDateWithDigitHourFormatter(createdByIndex(0)),
          title: "Refunded",
          titlePink: true,
          text: "We sent refunded funds to your main balance",
        },
      ];

    if (isWaitingRefund)
      return [
        createdTrx,
        weReceivedYourPayment(),
        weSentYourMoney(),
        {
          date: digitDateWithDigitHourFormatter(createdByIndex(1)),
          text: "The recipient’s bank has declined the transaction",
        },
        {
          date: digitDateWithDigitHourFormatter(createdByIndex(0)),
          text: "Sit tight. The refund process has been initiated. It may take up to 2x24 hours for your funds to be returned",
        },
      ];
    if (isFailed)
      return [
        createdTrx,
        weReceivedYourPayment(2),
        weSentYourMoney(1),
        {
          date: digitDateWithDigitHourFormatter(createdByIndex(0)),
          text: "The recipient’s bank has declined the transaction. We suggest you to contact our customer support team before asking to refund",
        },
      ];
    if (isCompleted || isPartialFailed)
      return [
        createdTrx,
        weReceivedYourPayment(2),
        weSentYourMoney(1),
        {
          date: digitDateWithDigitHourFormatter(createdByIndex(0)),
          text: "All of your funds has been processed",
          isActive: true,
          isGreenButton: true,
        },
      ];
    if (isOnProgress)
      return [
        createdTrx,
        weReceivedYourPayment(1),
        {
          date: digitDateWithDigitHourFormatter(createdByIndex(0)),
          text: "We are working our magic to deliver your money ASAP! Sit tight and relax",
          isActive: true,
          customComponents: (
            <>
              <GothamRegular>
                Sit tight, we are working on our magic to{" "}
                <SpanColor color={colors.orangeFF}>
                  deliver your money
                </SpanColor>
              </GothamRegular>
            </>
          ),
        },
        {
          text: "Completed",
          textGrey: true,
        },
      ];
    if (isWaitingForConfirmation)
      return [
        createdTrx,
        {
          date: digitDateWithDigitHourFormatter(createdByIndex(0)),
          text: "Awesome! We have received your payment and we will verify your transaction ASAP!",
          isActive: true,
          customComponents: (
            <>
              <GothamRegular>
                We have received your payment and{" "}
                <SpanColor color={colors.orangeFF}>
                  we will verify your transaction
                </SpanColor>{" "}
                ASAP!
              </GothamRegular>
            </>
          ),
        },
        { text: "Transferring money to recipients", textGrey: true },
        { text: "Completed", textGrey: true },
      ];
    if (isWaitingForPayment)
      return [
        createdTrx,
        {
          text: "It’s time to continue the payment process!",
        },
        { text: "Transferring money to recipients", textGrey: true },
        { text: "Completed", textGrey: true },
      ];

    return [];
  };

  const array = getArray();

  const {
    firstLineObj,
    completedLineObj,
    cancelledLineObj,
    approverLineArray,
    isWorkflow,
    isCancelled,
    rejection_note,
    isValidating,
    validatingObj,
  } = useCrossBorderSingleWorkflow(data?.data);

  if (isWorkflow) {
    const arrayDecider = () => {
      if (isCompleted)
        return [firstLineObj, ...approverLineArray, completedLineObj];
      if (isValidating)
        return [
          firstLineObj,
          ...approverLineArray,
          validatingObj,
          completedLineObj,
        ];
      if (isCancelled) return [firstLineObj, cancelledLineObj];

      return [
        firstLineObj,
        ...approverLineArray,
        validatingObj,
        completedLineObj,
      ];
    };
    return (
      <div style={{ marginTop: 12 }}>
        {/* <StatusPayrollLine array={arrayDecider()} isModal={isModal} /> */}
        <UpdateTracker updates={arrayDecider()} />
        {rejection_note && <ReasonNotes text={rejection_note} />}
      </div>
    );
  }

  return (
    <div style={{ marginTop: 12 }}>
      {/* <StatusPayrollLine array={array} style={{ marginTop: 20 }} isModal /> */}
      <UpdateTracker updates={array} />
      {/* <OverviewCardsBatch array={overviewArray} /> */}
    </div>
  );
};
