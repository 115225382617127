import { Fragment } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { GothamMedium } from "../../../../../components/Text";
import UpdateTrackerV2 from "../../../../../components/UpdateTrackerV2";
import { booleanState } from "../../../../../components/Status";
import { customDateFormatter } from "../../../../../components/tools";
import { useTranslation } from "react-i18next";

const SingleRetryUpdateTracker = ({
  style = {},
  releaser = {},
  transaction = {},
  onSeeDetails = () => {},
}) => {
  const { t } = useTranslation("retry-resubmit/retry-resubmit");
  const { created_at, updated_at, state } = transaction ?? {};

  const releaserName = releaser?.name || releaser?.email || "-";

  const { isFailed, isDeclined, isCompleted } = booleanState(state);

  const retryStatus = () => {
    if (isFailed || isDeclined) return "failed";
    if (isCompleted) return "completed";
    return "active";
  };

  const retryContent = () => {
    if (isFailed || isDeclined) return "Transaction failed";
    if (isCompleted) return "Transaction completed";
    return "Sending money to recipient(s)";
  };

  return (
    <UpdateTrackerV2
      updates={[
        {
          status: "retry",
          contents: [
            {
              type: "primary",
              content: (
                <Fragment>
                  <span style={{ fontFamily: "GothamMedium" }}>
                    {releaserName}
                  </span>{" "}
                  {t("retried the failed transaction(s)")}
                </Fragment>
              ),
            },
            {
              type: "custom",
              content: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                    width: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={onSeeDetails}
                >
                  <GothamMedium
                    className="font12"
                    style={{
                      color: jackColors.neutral900,
                      fontFamily: "GothamMedium",
                      textDecoration: "underline",
                    }}
                  >
                    {t("See original transaction")}
                  </GothamMedium>
                  <JackIcons
                    name="arrow-forward"
                    fill={jackColors.neutral900}
                    style={{ scale: "0.7" }}
                  />
                </div>
              ),
            },
            {
              type: "secondary",
              content: <Fragment>{customDateFormatter(created_at)}</Fragment>,
            },
          ],
        },
        {
          status: retryStatus(),
          contents: [
            {
              type: "primary",
              content: <Fragment>{retryContent()}</Fragment>,
            },
            (isFailed || isCompleted) && {
              type: "secondary",
              content: <Fragment>{customDateFormatter(updated_at)}</Fragment>,
            },
          ],
        },
      ]}
      style={style}
    />
  );
};

export default SingleRetryUpdateTracker;
