import { useRef } from "react";

// Define the type for the header array items
type HeaderItem = {
  name: string;
  value: string;
  width: string;
  maxWidth: number;
};

export const HeaderDataTableLocal = () => {
  // Specify the type of the ref as React.RefObject<HTMLDivElement>
  const tableBodyRef = useRef<HTMLDivElement>(null);

  // Get the client width of the referenced element or default to 0
  const tableBodyWidth = tableBodyRef.current?.clientWidth || 0;

  // Define the ngitungLebar function
  const ngitungLebar = (number: number): string => {
    return `${(number / tableBodyWidth) * 100}%`;
  };

  // Define the header array with types
  const headerArray: HeaderItem[] = [
    {
      name: "delete",
      value: "Delete",
      width: ngitungLebar(40),
      maxWidth: 40,
    },
    {
      name: "number",
      value: "No",
      width: ngitungLebar(40),
      maxWidth: 40,
    },
    {
      name: "name",
      value: "Name",
      width: ngitungLebar(240),
      maxWidth: 240,
    },
    {
      name: "bank",
      value: "Bank",
      width: ngitungLebar(176),
      maxWidth: 176,
    },
    {
      name: "account_number",
      value: "Bank Account Number",
      width: ngitungLebar(176),
      maxWidth: 176,
    },
    {
      name: "notes",
      value: "Notes",
      width: ngitungLebar(256),
      maxWidth: 256,
    },
    {
      name: "email",
      value: "Email",
      width: ngitungLebar(256),
      maxWidth: 256,
    },
    {
      name: "amount",
      value: "Amount",
      width: ngitungLebar(160),
      maxWidth: 160,
    },
    {
      name: "action",
      value: "Action",
      width: ngitungLebar(56),
      maxWidth: 56,
    },
  ];

  // Return the header array and ref object
  return { headerArray, tableBodyRef };
};
