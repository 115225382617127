import { CategoryArticle } from "../components";
import { Fragment } from "react";

const categoryInfoTextDecider = ({ type = "", isAdminRole = false }) => {
  switch (type) {
    case "title":
      if (isAdminRole) return "Elevate your bookkeeping efficiency!";
      return "Let's categorize your transactions!";

    case "first":
      if (isAdminRole) {
        return (
          <Fragment>
            You can cut off manual work in book closing and gain insights into
            company spending faster with{" "}
            <span style={{ fontFamily: "GothamMedium" }}>Categories</span>.
          </Fragment>
        );
      }
      return "On this page, you can see categories that are available to you.";

    case "second":
      if (isAdminRole) {
        return <Fragment></Fragment>;
      }
      return "Let’s contribute to building well-organized transactions by selecting suitable category for your transactions!";

    case "third":
      return (
        <Fragment>
          {isAdminRole ? "For full guidance," : "For further details"} you can
          read <CategoryArticle />
        </Fragment>
      );
    default:
      break;
  }
};

export default categoryInfoTextDecider;
