import { useState } from "react";
import SimpleCenteredModal from "../../../../../modals/SimpleCenteredModal";
import styles from "./CategorySetupModal.module.css";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { jackColors } from "../../../../../assets/colors";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../../../components/Text";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { useConstants } from "../../../../../contexts/ConstantsContext/parent";
import { useCategoryFlag } from "../../hooks";
import { useRouter } from "next/router";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import NextImage from "../../../../../components/NextImage";
import CategoryArticle from "../CategoryArticle";
import { Trans, useTranslation } from "react-i18next";
import { useLanguage } from "public/locales/translationFunctions";

const CategorySetupModal = ({ isOpen = false, toggle = () => {} }) => {
  const { t } = useTranslation("category/index");
  const [step, setStep] = useState(1);
  const [selectedPreference, setSelectedPreference] = useState("");

  const { push } = useRouter();

  const { refetchUser } = useGetAuth();

  const { categories } = useConstants();

  const { activateCategory, loadingActivateCategory } = useCategoryFlag();

  const { successSnackBar } = ToasterHook();

  const isDisabledContinue = !selectedPreference;

  const handleResetState = () => {
    setStep(1);
    setSelectedPreference("");
    toggle();
  };

  const handleToggle = () => {
    if (loadingActivateCategory) return;
    handleResetState();
  };

  const handleActivateCategory = async () => {
    await activateCategory({
      setting: selectedPreference,
      afterSuccess: async () => {
        await refetchUser();
        successSnackBar({
          msg: "Transaction category feature has been activated.",
        });
      },
    });
    handleResetState();
  };

  const buttons = () => {
    switch (step) {
      case 1:
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ButtonJack type="outline" onClick={handleToggle}>
              {t("Cancel")}
            </ButtonJack>
            <ButtonJack
              disabled={isDisabledContinue}
              rightIcon={
                <JackIcons
                  name="arrow-forward-outline"
                  fill={
                    isDisabledContinue
                      ? jackColors.neutral600
                      : jackColors.neutral900
                  }
                />
              }
              onClick={() => setStep((prev) => prev + 1)}
            >
              {t("Continue")}
            </ButtonJack>
          </div>
        );

      case 2:
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ButtonJack
              type="outline"
              isLoading={loadingActivateCategory}
              leftIcon={
                <JackIcons
                  name="arrow-back"
                  fill={
                    loadingActivateCategory
                      ? jackColors.neutral600
                      : jackColors.neutral900
                  }
                />
              }
              onClick={() => setStep((prev) => prev - 1)}
            >
              {t("Back")}
            </ButtonJack>
            <div
              style={{
                display: "flex",
                gap: "16px",
              }}
            >
              <ButtonJack
                type="outline"
                isLoading={loadingActivateCategory}
                onClick={handleActivateCategory}
              >
                {t("Keep as it is")}
              </ButtonJack>
              <ButtonJack
                isLoading={loadingActivateCategory}
                leftIcon={
                  <JackIcons
                    name="edit-outline"
                    fill={
                      loadingActivateCategory
                        ? jackColors.neutral600
                        : jackColors.neutral900
                    }
                  />
                }
                onClick={() => {
                  push({
                    pathname: "/category/onboard",
                    query: { preference: selectedPreference },
                  });
                }}
              >
                {t("Edit")}
              </ButtonJack>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const content = () => {
    const { isBahasa } = useLanguage();
    switch (step) {
      case 1:
        return (
          <div className={styles["category-setup-content"]}>
            <GothamMedium
              className="font24"
              style={{ color: jackColors.neutral900 }}
            >
              {t("Set Categories Preferences")}
            </GothamMedium>
            <div className={styles["category-preference-container"]}>
              <div
                className={styles["category-preference"]}
                style={{
                  borderColor:
                    selectedPreference === "required" && jackColors.neutral900,
                }}
                onClick={() => setSelectedPreference("required")}
              >
                <div style={{ minWidth: 180, minHeight: 120 }}>
                  <img
                    width={180}
                    height={120}
                    src={`/images/category-required${
                      isBahasa ? "-id" : ""
                    }.png`}
                    alt="Category Intro Image"
                  />
                </div>
                <div>
                  <GothamMedium style={{ color: jackColors.neutral900 }}>
                    {t("Required")}
                  </GothamMedium>
                  <GothamRegular
                    woFontColor
                    className="font12"
                    style={{ color: jackColors.neutral800, marginTop: "8px" }}
                  >
                    <Trans
                      i18nKey={t("Dynamic.onboard_preference")}
                      components={[
                        <span style={{ fontFamily: "GothamMedium" }} />,
                      ]}
                    />
                  </GothamRegular>
                </div>
                {selectedPreference === "required" && (
                  <JackIcons
                    name="checkmark-circle-2"
                    fill={jackColors.neutral900}
                    style={{ position: "absolute", right: "16px", top: "16px" }}
                  />
                )}
              </div>
              <div
                className={styles["category-preference"]}
                style={{
                  borderColor:
                    selectedPreference === "not_required" &&
                    jackColors.neutral900,
                }}
                onClick={() => setSelectedPreference("not_required")}
              >
                <div style={{ minWidth: 180, minHeight: 120 }}>
                  <img
                    width={180}
                    height={120}
                    src={`/images/category-optional${
                      isBahasa ? "-id" : ""
                    }.png`}
                    alt="Category Intro Image"
                  />
                </div>
                <div>
                  <GothamMedium style={{ color: jackColors.neutral900 }}>
                    {t("Optional")}
                  </GothamMedium>
                  <GothamRegular
                    woFontColor
                    className="font12"
                    style={{ color: jackColors.neutral800, marginTop: "8px" }}
                  >
                    {t(
                      "Transaction category is optional. Missing category will not appear in Task."
                    )}
                  </GothamRegular>
                </div>
                {selectedPreference === "not_required" && (
                  <JackIcons
                    name="checkmark-circle-2"
                    fill={jackColors.neutral900}
                    style={{ position: "absolute", right: "16px", top: "16px" }}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div
            className={styles["category-setup-content"]}
            style={{ paddingBottom: "36px" }}
          >
            <NextImage
              width={476}
              height={200}
              src="/images/category-onboard.png"
              alt="Category Intro Image"
            />
            <GothamBold
              woFontSize
              className="font20"
              style={{
                color: jackColors.neutral900,
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              <Trans
                i18nKey={t("Dynamic.onboard_step_2_count", {
                  count:
                    (categories ?? []).filter(
                      ({ isDeleted, is_archived }) => !isDeleted && !is_archived
                    ).length || 10,
                })}
              />
            </GothamBold>
            <GothamRegular
              woFontColor
              style={{ color: jackColors.neutral800, marginTop: "12px" }}
            >
              {t(
                "Feel free to edit or keep it as it is. You can also add new categories in single, bulk, or import from your Chart of Account."
              )}
            </GothamRegular>
            <GothamRegular
              woFontColor
              style={{ color: jackColors.neutral800, marginTop: "20px" }}
            >
              {t("For full guidance, you can go check")} <CategoryArticle />
            </GothamRegular>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <SimpleCenteredModal
      modalClassName={styles["category-setup-modal"]}
      isOpen={isOpen}
      toggle={handleToggle}
      customButtons={buttons()}
      leftHeaderComponent={
        <GothamRegular
          woFontColor
          className="font12"
          style={{ color: jackColors.neutral700 }}
        >
          <Trans
            i18nKey={t("Dynamic.onboard_step", {
              step: step,
            })}
          />
        </GothamRegular>
      }
    >
      {content()}
    </SimpleCenteredModal>
  );
};

export default CategorySetupModal;
