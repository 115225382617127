import styles from "./CategoryIntroModal.module.css";
import SimpleCenteredModal from "../../../../../modals/SimpleCenteredModal";
import { GothamBold, GothamRegular } from "../../../../../components/Text";
import { jackColors } from "../../../../../assets/colors";
import { useRouter } from "next/router";
import { useMutation } from "../../../../../tools/api";
import { getUserRole, useGetAuth } from "../../../../../contexts/AuthContext";
import { Fragment } from "react";

const CategoryIntroModal = ({ isOpen = false, toggle = () => {} }) => {
  const { push } = useRouter();

  const { user } = useGetAuth();

  const { isEmployee } = getUserRole();

  const {
    mutation: flagShownIntroCategory,
    loading: loadingFlagShownIntroCategory,
  } = useMutation({
    method: "post",
    url: `/business_users/${user?.id}/create_user_flag`,
  });

  return (
    <SimpleCenteredModal
      modalClassName={styles["category-intro-modal"]}
      isOpen={isOpen}
      toggle={async () => {
        flagShownIntroCategory({ flags: "SHOWN_CATEGORY_INTRO" });
        toggle();
      }}
      onClick={async () => {
        flagShownIntroCategory({ flags: "SHOWN_CATEGORY_INTRO" });
        await push("/category");
        toggle();
      }}
      isLoading={loadingFlagShownIntroCategory}
      buttonText="Go to Categories"
      leftHeaderComponent={
        !isEmployee && (
          <GothamBold
            woFontSize
            className="font20"
            style={{ color: jackColors.neutral900, margin: 0 }}
          >
            Introducing new feature: Categories
          </GothamBold>
        )
      }
    >
      <div className={styles["category-intro-content"]}>
        <img
          width={476}
          height={200}
          src="/images/category-intro.png"
          alt="Category Intro Image"
        />
        <GothamBold
          woFontSize
          className="font20"
          style={{
            color: jackColors.neutral900,
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          {isEmployee
            ? "Your company needs your help with Categories!"
            : "Elevate your company's bookkeeping efficiency!"}
        </GothamBold>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral800, marginTop: "12px" }}
        >
          {isEmployee ? (
            "Would you like to lend a hand by organizing your transactions?"
          ) : (
            <Fragment>
              Let's gain finance insights of company spending faster using{" "}
              <span style={{ fontFamily: "GothamMedium" }}>Categories!</span>
            </Fragment>
          )}
        </GothamRegular>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral800, marginTop: "20px" }}
        >
          {isEmployee
            ? "Find out how you can help your company by clicking the button below!"
            : "Shall we explore how it works?"}
        </GothamRegular>
      </div>
    </SimpleCenteredModal>
  );
};

export default CategoryIntroModal;
