import { useEffect } from "react";
import { useUserFlags } from "../../../../../contexts/AuthContext";
import SimpleCenteredModal from "../../../../SimpleCenteredModal";
import { useModalHook } from "../../../../../components/Modals";
import styles from "./styles.module.css";
import NextImage from "../../../../../components/NextImage";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../../components/Text";
import { jackColors } from "../../../../../assets/colors";
import MIXPANELS from "../../../../../universalFunctions/mixpanels";
import { Trans, useTranslation } from "react-i18next";
import { useLanguage } from "public/locales/translationFunctions";
import onboardImgBahasa from "../../../../../assets/images/retry-resubmit-onboard-bahasa.png";

const SEEN_RETRY_RESUBMIT_ONBOARD_PORTAL = "SEEN_RETRY_RESUBMIT_ONBOARD_PORTAL";

const RetryResubmitOnboardModal = () => {
  const { hasUser, userFlags, isLoadingUserFlags, addUserFlag } =
    useUserFlags();

  const { t } = useTranslation("retry-resubmit/retry-resubmit");
  const { isBahasa } = useLanguage();
  const { isOpen, open, close } = useModalHook();

  const handleClose = () => {
    close();
    addUserFlag(SEEN_RETRY_RESUBMIT_ONBOARD_PORTAL);
  };

  useEffect(() => {
    if (!hasUser) return;
    if (isLoadingUserFlags) return;
    if (userFlags?.length <= 0) return;
    if (userFlags?.includes(SEEN_RETRY_RESUBMIT_ONBOARD_PORTAL)) return;

    setTimeout(() => {
      open();
    }, 2000);
  }, [isLoadingUserFlags, userFlags, hasUser]);

  return (
    <SimpleCenteredModal
      isOpen={isOpen}
      leftHeaderComponent={
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900 }}
        >
          {t("Introducing new feature: Retry & Resubmit")}
        </GothamMedium>
      }
      buttonText={t("Noted for later!")}
      modalClassName={styles.retryResubmitModal}
      toggle={() => {
        handleClose();
        MIXPANELS.RETRY_RESUBMIT.RETRY_ONBOARDING_CLOSE();
      }}
      onClick={() => {
        handleClose();
        MIXPANELS.RETRY_RESUBMIT.RETRY_ONBOARDING_CONFIRM();
      }}
    >
      <div className={styles.content}>
        <NextImage
          src={
            isBahasa ? onboardImgBahasa : "/images/retry-resubmit-onboard.png"
          }
          alt="Retry Resubmit Onboard Image"
          width={520}
          height={240}
        />

        <GothamMedium
          className="font20"
          style={{
            color: jackColors.neutral900,
            textAlign: "center",
            marginTop: "24px",
          }}
        >
          {t("Re-attempt failed transactions in a snap!")}
        </GothamMedium>

        <GothamRegular
          style={{ color: jackColors.neutral800, marginTop: "24px" }}
        >
          {t(
            "Failed transactions may occur for various reasons. Whether it's the bank's system failure or a tiny data entry mistake."
          )}
        </GothamRegular>
        <GothamRegular
          style={{ color: jackColors.neutral800, marginTop: "24px" }}
        >
          <Trans
            i18nKey={t("Dynamic.onboarding_description")}
            components={[
              <TextInlineMedium />,
              <a
                style={{ fontFamily: isBahasa && "GothamMedium" }}
                target="_blank"
                href="https://support.itsjack.com/support/solutions/articles/150000106971-what-if-my-local-transfer-failed-partially-failed-"
                className={styles.articleLink}
                onClick={() =>
                  MIXPANELS.RETRY_RESUBMIT.RETRY_ONBOARDING_TO_ARTICLE()
                }
              />,
            ]}
          />
        </GothamRegular>
      </div>
    </SimpleCenteredModal>
  );
};

export default RetryResubmitOnboardModal;
