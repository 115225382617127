import { useRouter } from "next/router";
import { jackColors } from "../../../../assets/colors";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import styles from "../../styles.module.css";
import { eventsTracker } from "../../../../universalFunctions/events";

const SidebarAdvertisement = () => {
  const { push } = useRouter();

  const handleClick = () => {
    eventsTracker("to_explore_plan_from_sidebar_dropdown");
    push("/plans/showcase");
  };

  return (
    <div className={styles["sidebar-ads"]}>
      <div>
        <GothamMedium className="font10" style={{ color: "white" }}>
          SEED PLAN
        </GothamMedium>
      </div>
      <GothamRegular
        woFontColor
        className="font12"
        style={{ color: jackColors.neutral800 }}
      >
        Upgrade for unlimited users, lower fees, boundless transactions, and
        many more!
      </GothamRegular>
      <ButtonJack
        style={{ height: "32px" }}
        styleText={{ fontSize: "12px" }}
        onClick={handleClick}
      >
        Upgrade Plan
      </ButtonJack>
    </div>
  );
};

export default SidebarAdvertisement;
