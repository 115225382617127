import React, { useEffect, useState } from "react";
import { TransactionFilterButtons } from "./buttons/parent";
import { TransactionFiltersSearchbox } from "./searchbox/parent";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

export const TransactionsFilters = ({
  activeFilters: activeFiltersRaw,
  filters,
  woAdd,
  searchQueryName = "searchbox",
  searchPlaceholder = "Search keywords",
  woSearch,
  loading,
  isApi = false,
  customComponent = <></>,
}) => {
  const [activeFilters, setActiveFilters] = useState(activeFiltersRaw);
  const { t } = useTranslation("api-history/api-history");

  const handleClick = (value) => {
    if (!value) return setActiveFilters(activeFiltersRaw);
    setActiveFilters((prev) => [...prev, value]);
  };
  const isDivided = activeFilters.length >= 5;

  const filterProps = {
    activeFilters,
    filters,
    onClick: handleClick,
    woAdd,
    loading,
  };

  const searchboxProps = {
    queryName: searchQueryName,
    placeholder: t(searchPlaceholder),
    loading,
  };

  const { query } = useRouter();
  const { menu } = query || {};

  useEffect(() => {
    if (isEmpty(activeFiltersRaw)) return;
    if (!menu) return;
    setActiveFilters(activeFiltersRaw);
  }, [menu]); // dep changed to menu from activeFiltersRaw -> bakal reset default filter kalo ganti menu aja

  if (isDivided && !isApi)
    return (
      <div>
        <TransactionFiltersSearchbox {...searchboxProps} />
        <div
          style={{
            width: "100%",
            marginTop: 14,
            marginBottom: 0,
          }}
        >
          <TransactionFilterButtons {...filterProps} />
        </div>
      </div>
    );

  if (isApi)
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <TransactionFiltersSearchbox {...searchboxProps} />
          {customComponent}
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 14,
            marginBottom: 0,
          }}
        >
          <TransactionFilterButtons {...filterProps} />
        </div>
      </div>
    );

  return (
    <div className="d-flex">
      {!woSearch ? (
        <TransactionFiltersSearchbox {...searchboxProps} />
      ) : (
        <div style={{ width: "360px" }} />
      )}
      <div style={{ width: "calc(100% - 360px)" }} className="d-flex ">
        <TransactionFilterButtons
          {...filterProps}
          className="justify-content-end "
        />
      </div>
    </div>
  );
};
