import { HAS_SEEN_SINGLE_LOCAL_FIRST_PAGE_TOUR, HAS_SEEN_SINGLE_LOCAL_ONBOARD_MODAL, HAS_SEEN_SINGLE_LOCAL_SECOND_PAGE_TOUR } from "pageComponents/localTransferEnhancement/typescript/create/singleTransferComponents/constants";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { useMutation } from "../../../../tools/api";

const useCategoryFlag = () => {
  const { user, refetchUser } = useGetAuth();
  const { partner, all_flags } = user ?? {};
  const { category_setting, id } = partner ?? {};

  const flags = all_flags ?? [];

  const { mutation } = useMutation({
    method: "post",
    url: `/business_users/${user?.id}/create_user_flag`,
    afterSuccess: () => {
      refetchUser();
    },
  });

  const { mutation: activateCategoryRaw, loading: loadingActivateCategory } =
    useMutation({
      url: `/business_partners/${id}/trx_category_setting`,
    });

  const categoryFlags = {
    hasSeenCategory: "SEEN_CATEGORY_FEATURE",
    hasIntroducedCategory: "SHOWN_CATEGORY_INTRO",
    hasWelcomedCategory: "SHOWN_CATEGORY_WELCOME",
    hasWelcomedCategoryTrx: "SHOWN_CATEGORY_WELCOME_TRX",
    hasWelcomedCategoryTask: "SHOWN_CATEGORY_WELCOME_TASK",
    hasSeenNotifGuidedTour: "SEEN_NOTIF_GUIDED_TOUR",
  };

  const userInvitationFlags = {
    hasSeenUserAndTeams: "SEEN_USER_AND_TEAMS_NEW_FEATURE_NEW",
    hasSeenInviteButtonTooltip: "SEEN_USER_INVITATION_INVITE_BUTTON_NEW",
    hasSeenTrackerTooltip: "SEEN_USER_INVITATION_TRACKER_NEW",
    hasSeenInvitationRequestButtonTooltip:
      "SEEN_USER_INVITATION_REQUEST_BUTTON_NEW",
    hasSeenApproveRequestTooltip: "SEEN_USER_INVITATION_APPROVE_TASK_NEW",
  };

  const schedulePaymentFlags = {
    hasWelcomedMultiScheduleTask: "SHOWN_MULTI_SCHEDULE_WELCOME_TASK",
  };

  const bankingFlags = {
    hasSeenBankingOnboarding: "SEEN_BANKING_ONBOARDING",
  }

  const singleTransferFlags = {
    hasSeenSingleFirstPage: HAS_SEEN_SINGLE_LOCAL_FIRST_PAGE_TOUR,
    hasSeenSingleSecondPage: HAS_SEEN_SINGLE_LOCAL_SECOND_PAGE_TOUR,
    hasSeenSingleThirdPage: HAS_SEEN_SINGLE_LOCAL_SECOND_PAGE_TOUR,
    hasSeenSingleFourthPage: HAS_SEEN_SINGLE_LOCAL_SECOND_PAGE_TOUR,
    hasSeenSingleOnboardModal: HAS_SEEN_SINGLE_LOCAL_ONBOARD_MODAL
  }

  const isActiveCategory = category_setting !== "off";
  const isDeactivatedCategory = category_setting === "deactivated";
  const isRequiredCategory = category_setting === "required";
  const isNotRequiredCategory = category_setting === "not_required";

  const shallCheckMissingCategory = isRequiredCategory;

  const hasSeenCategory = flags.includes(categoryFlags.hasSeenCategory);
  const hasIntroducedCategory = flags.includes(
    categoryFlags.hasIntroducedCategory
  );
  const hasWelcomedCategory = flags.includes(categoryFlags.hasWelcomedCategory);
  const hasWelcomedCategoryTrx = flags.includes(
    categoryFlags.hasWelcomedCategoryTrx
  );
  const hasWelcomedCategoryTask = flags.includes(
    categoryFlags.hasWelcomedCategoryTask
  );
  const hasSeenNotifGuidedTour = flags.includes(
    categoryFlags.hasSeenNotifGuidedTour
  );
  const hasSeenUserAndTeams = flags.includes(
    userInvitationFlags.hasSeenUserAndTeams
  );
  const hasSeenInviteButtonTooltip = flags.includes(
    userInvitationFlags.hasSeenInviteButtonTooltip
  );
  const hasSeenTrackerTooltip = flags.includes(
    userInvitationFlags.hasSeenTrackerTooltip
  );
  const hasSeenInvitationRequestButtonTooltip = flags.includes(
    userInvitationFlags.hasSeenInvitationRequestButtonTooltip
  );
  const hasSeenApproveRequestTooltip = flags.includes(
    userInvitationFlags.hasSeenApproveRequestTooltip
  );

  const hasWelcomedMultiScheduleOnTask = flags.includes(
    schedulePaymentFlags.hasWelcomedMultiScheduleTask
  );

  const hasSeenBankingOnboarding = flags.includes(
    bankingFlags.hasSeenBankingOnboarding
  );

  const hasSeenSingleFirstPage = flags.includes(
    singleTransferFlags.hasSeenSingleFirstPage
  );
  const hasSeenSingleSecondPage = flags.includes(
    singleTransferFlags.hasSeenSingleSecondPage
  );
  const hasSeenSingleThirdPage = flags.includes(
    singleTransferFlags.hasSeenSingleThirdPage
  );
  const hasSeenSingleFourthPage = flags.includes(
    singleTransferFlags.hasSeenSingleFourthPage
  );
  const hasSeenSingleOnboardModal = flags.includes(
    singleTransferFlags.hasSeenSingleOnboardModal
  );

  const addCategoryFlag = async (flag) => {
    const isExist = flags.includes(flag);
    if (isExist) return;
    await mutation({ flags: flag });
  };

  const activateCategory = async ({
    setting = "off",
    afterSuccess = () => { },
  }) => {
    try {
      await activateCategoryRaw({ setting });
      afterSuccess();
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return {
    category_setting,
    categoryFlags,
    isActiveCategory,
    isDeactivatedCategory,
    isRequiredCategory,
    isNotRequiredCategory,
    hasSeenCategory,
    hasIntroducedCategory,
    hasWelcomedCategory,
    hasWelcomedCategoryTrx,
    hasWelcomedCategoryTask,
    shallCheckMissingCategory,
    addCategoryFlag,
    activateCategory,
    loadingActivateCategory,
    hasSeenNotifGuidedTour,
    userInvitationFlags,
    hasSeenInviteButtonTooltip,
    hasSeenTrackerTooltip,
    hasSeenInvitationRequestButtonTooltip,
    hasSeenApproveRequestTooltip,
    hasSeenUserAndTeams,
    schedulePaymentFlags,
    hasWelcomedMultiScheduleOnTask,
    bankingFlags,
    hasSeenBankingOnboarding,
    singleTransferFlags,
    hasSeenSingleFirstPage,
    hasSeenSingleSecondPage,
    hasSeenSingleThirdPage,
    hasSeenSingleFourthPage,
    hasSeenSingleOnboardModal,

  };
};

export default useCategoryFlag;
