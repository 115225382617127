import { times } from "lodash";
import React, { useEffect, useState } from "react";
import { CustomButton } from "../../../components/Buttons";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { NakedTextField } from "../../../components/inputs";
import { formatCurrency } from "../../../components/tools";
import { getUserRole } from "../../../contexts/AuthContext";

export const PayrollPinModal = ({
  isOpen,
  toggle,
  onSubmit: onSubmitProps,
  totalAmount = 0,
  loading,
  title = "Input your PIN to pay",
  buttonText: buttonTextProps,
}) => {
  if (!isOpen) return null;

  const [pin, setPin] = useState("");
  const [maskedPin, setMaskedPin] = useState("");
  const [middleHeight, setMiddleHeight] = useState(0);

  const onSubmit = () => onSubmitProps({ pin }, "pin");

  const formattedAmount = formatCurrency(totalAmount);
  const buttonText = buttonTextProps || `Pay IDR ${formattedAmount}`;

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      middleHeight={middleHeight}
      paddingTop={16}
      header={
        <MainCardHeaderMolecule
          isClose={false}
          toggle={toggle}
          title={title}
          middleHeight={middleHeight}
          alignLeft
        />
      }
      middle={
        <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
          <div style={{ height: 44 }}>
            <NakedTextField
              onKeyPress={(e) => {
                if (e.key !== "Enter") return;
                onSubmit();
              }}
              label="Enter PIN"
              style={{ backgroundColor: "white" }}
              onChange={(event) => {
                const text = event?.target?.value || "";

                const numberPin = text.split("•").pop();

                if (!numberPin) {
                  setPin("");
                  setMaskedPin("");
                  return;
                }

                const masked = times(text.length, () => "•").join("");

                setPin((prev) => {
                  if (!text) return "";

                  if (!numberPin) return prev.slice(0, prev.length - 1);

                  return prev + numberPin;
                });

                setMaskedPin((prev) => {
                  const isErased = prev.length > masked.length;

                  if (isErased) {
                    setPin("");
                    return "";
                  }

                  return masked;
                });
              }}
              value={maskedPin}
            />
          </div>
        </MiddleWrapper>
      }
      body={
        <div>
          <CustomButton
            className="w-100"
            style={{ marginTop: 16 }}
            disabled={!maskedPin}
            onClick={onSubmit}
            isLoading={loading}
          >
            {buttonText}
          </CustomButton>
        </div>
      }
    />
  );
};
