import { pickBy } from "lodash";
import { customSum, formatCurrency } from "../../../components/tools";
import { getDefaultValuesArray } from "../localTransfer/formatter";

export const defaultValuesLocalTransferFormatter = ({
  defaultValues,
  batch,
}) => {
  const amounts = pickBy(defaultValues, (_, key) => key.includes("amount_"));
  let newAmounts = {};

  Object.keys(amounts).forEach((key) => {
    const value = amounts[key];
    const newValue = `IDR ${formatCurrency(value)}`;
    const formattedKey = `formatted_${key}`;
    newAmounts[formattedKey] = newValue;
  });

  const mergedDefaultValues = { ...defaultValues, batch, ...newAmounts };

  return mergedDefaultValues;
};

export const localTransferTotalAmount = (defaultValues, ids) => {
  const amounts = customSum(
    getDefaultValuesArray(defaultValues)
      .filter(({ id }) => !ids.includes(id))
      .map((item) => Number(item?.amount))
  );

  return amounts;
};
