import { noCase } from "change-case";
import { useRouter } from "next/router";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { eWalletSuccessBoolean } from "./logics/boolean";

export const useEWalletSuccessFormatter = () => {
  const { query } = useRouter();
  const { ids: idsRaw, transaction_type } = query;
  const { isAllocationRequest, isReturnRequestByAdmin, isReturnRequestCancel } =
    eWalletSuccessBoolean(transaction_type);

  const categoryDecider = () => {
    const isIncludes = (string) => String(transaction_type).includes(string);

    if (isIncludes("allocation_request")) return "allocation_request";

    if (isIncludes("return_balance")) return "return_request";

    return transaction_type;
  };

  const category = categoryDecider();

  const ids = typeof idsRaw == "string" ? [idsRaw] : idsRaw;

  const filters = {
    category: [category],
    originator_id: ids,
  };

  const resultFormatter = (res) => {
    const { data = [] } = res?.data;
    const result = data.map((item) => {
      const {
        category: type,
        status,
        description: leftTop,
        created_at,
        amount,
      } = item;
      const rightTop = `IDR ${formatCurrencyNoDecimal(amount)}`;

      const leftBottom = customDateFormatter(created_at, true);

      const leftStatusDecider = () => {
        const isDraft = ["draft", "pending"].includes(status);
        if (isReturnRequestCancel) return "cancelled";
        if (isAllocationRequest || isReturnRequestByAdmin || isDraft)
          return "in_progress";
        return status;
      };

      const leftStatus = leftStatusDecider();
      const obj = { leftTop, leftStatus, leftBottom, rightTop, type };

      return { ...item, ...obj };
    });
    return result;
  };

  return { resultFormatter, filters };
};
