import { jackColors } from "assets/colors";
import { useEffect, useState } from "react";
import { apiBusiness } from "tools/api";

const Component = () => {
  const [isError, setIsError] = useState(true);
  const { href } = window?.location || {};

  const isProd =
    href.includes("business.itsjack.com") || href.includes("jack-main");
  // const isProd = true;

  useEffect(() => {
    if (!isProd) return setIsError(false);
    const func = async () => {
      try {
        await apiBusiness.get("/ping");
        setIsError(false);
      } catch (error) {
        setIsError(true);
        const envType = localStorage.getItem("env_type");
        if (envType) return;
        localStorage.setItem("env_type", "prod_unbanned");
      }
    };
    func();
    let interval = setInterval(func, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        width: 24,
        height: 24,
        backgroundColor: isError ? jackColors.redE7 : jackColors.green06,
        borderRadius: 4,
        border: "1px solid #e6e6e8",
      }}
    />
  );
};

// for clients that has blocked internet access to jack API, we provides automatically change api for em
const EPIndicator = () => <Component />;
export default EPIndicator;
