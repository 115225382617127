import { useEffect, useState } from "react";
import { fetch } from "../api";

const useSubscriptionBillings = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const { data, loading, refetch } = fetch({
    url: "/subscription_module/bills",
    params: { page },
    woInit: true,
    formatter: (res, prev) => {
      if (page === 1) return res;
      return { ...prev, data: [...prev.data, ...res.data] };
    },
    afterSuccess: (res) => setTotalPage(res.total_page),
  });

  useEffect(() => {
    if (page <= totalPage) refetch();
  }, [page, totalPage]);

  const billings = data.data ?? [];
  const dueDate = data.due_date ?? billings[0]?.due_date ?? new Date();
  const billingEmail = data.bill_email ?? "";
  const costBreakdown = data.cost_breakdown ?? {};
  const totalFreeFeeLocalTransfer =
    data?.total_free_qty?.total_free_local_transfer_quantity ?? 0;

  return {
    dueDate,
    billings,
    billingEmail,
    costBreakdown,
    loading,
    page,
    totalFreeFeeLocalTransfer,
    setPage,
    cleanRefetch: async () => {
      if (page === 1) return refetch();
      setPage(1);
    },
  };
};

export default useSubscriptionBillings;
