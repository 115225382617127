import { useTranslation } from "react-i18next";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { GothamRegular } from "../../../../../components/Text";
import styles from "./MissingDetailTag.module.css";

const MissingDetailTag = () => {
  const { t } = useTranslation("category/index");
  return (
    <div className={styles["missing-detail-tag"]}>
      <JackIcons
        name="alert-triangle"
        fill={jackColors.yellowFC}
        style={{ scale: "0.8" }}
      />
      <GothamRegular woFontColor style={{ color: jackColors.neutral900 }}>
        {t("Missing Details")}
      </GothamRegular>
    </div>
  );
};

export default MissingDetailTag;
