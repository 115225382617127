import { jackColors } from "../../../../assets/colors";
import deleteIcon from "../../../../assets/images/delete-icon-pink.svg";
import incorrectIcon from "../../../../assets/images/incorrect-icon.svg";
import duplicateIcon from "../../../../assets/images/duplicate-icon.svg";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { CheckBox } from "../../../../components/Checkbox";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import {
  formatCurrencyNoDecimal,
  stringCutter,
} from "../../../../components/tools";
import {
  EmailInfoLocalTransfer,
  EmailTextLocalTransfer,
} from "../../../localTransferEnhancement/create/table/inputs/email/parent";
import { TooltipIncorrect } from "../tooltip";
import { useTranslation } from "react-i18next";

export const useBooleanTable = ({ name }) => {
  const isDelete = name === "delete";
  const isNumber = name === "number";
  const isName = name === "name";
  const isBank = name === "bank";
  const isAccNumber = name === "account_number";
  const isSalary = name === "net_salary";
  const isNotes = name === "notes";
  const isRole = name === "role";
  const isDept = name === "department";
  const isEmail = name === "email";
  const isAction = name === "action";
  const isAmount = name === "amount";
  const isPayerId = name === "payer_id";
  const isBankCode = name === "bank_code";
  const isId = name === "id";
  const isLocalId = name === "local_recipient_id";

  const isRequired = isName || isBank || isAccNumber || isSalary;
  const isSecondRender = isRole || isDept || isEmail;
  const isFirstRender = isBank || isAccNumber || isSalary || isNotes;
  return {
    isDelete,
    isNumber,
    isName,
    isAction,
    isRole,
    isDept,
    isEmail,
    isBank,
    isAccNumber,
    isSalary,
    isNotes,
    isAmount,
    isPayerId,
    isBankCode,
    isRequired,
    isFirstRender,
    isSecondRender,
    isId,
    isLocalId,
  };
};

export const renderedValue = ({
  item,
  objDataCombination,
  showDeleteRow,
  index,
  isActive,
  handleClickItem,
  options,
  isHovered,
  isAnythingSelected,
  t = (item) => item,
}) => {
  const { name, value, invalid, duplicate, invalidFormat } = item || {};

  const {
    isNumber,
    isDelete,
    isSalary,
    isAction,
    isBank,
    isEmail,
    isAccNumber,
    isName,
    isNotes,
  } = useBooleanTable({
    name,
  });
  const isValueEmpty = !Boolean(value);
  const bankValue = options.filter((item) => item.name === value)[0];

  const IncorrectDiv = (children) => {
    return (
      <div className="d-flex" style={{ gap: 4 }}>
        <TooltipIncorrect t={t} item={item}>
          <img src={incorrectIcon} />
        </TooltipIncorrect>
        {children}
      </div>
    );
  };

  const DuplicateDiv = (children) => {
    return (
      <div className="d-flex" style={{ gap: 4 }}>
        <TooltipIncorrect item={item} t={t}>
          <img src={duplicateIcon} />
        </TooltipIncorrect>
        {children}
      </div>
    );
  };

  if (isNumber) return index + 1;
  if (isDelete) {
    if (isHovered || isAnythingSelected) {
      return (
        <td>
          <CheckBox
            nameCustom={isActive ? "checkmark-square-2" : "square"}
            fillCustom={
              isActive ? jackColors.neutral900 : jackColors.neutral600
            }
            isActive={isActive}
            onClick={() => handleClickItem(index, isActive)}
            style={{ width: 20, height: 20 }}
          />
        </td>
      );
    }
    return "";
  }

  if (isSalary) {
    if (invalid) return IncorrectDiv(`IDR ${formatCurrencyNoDecimal(value)}`);
    if (isValueEmpty) return PlaceholderTablePayroll(name, t);
    return `IDR ${formatCurrencyNoDecimal(value)}`;
  }

  if (isEmail) {
    if (invalid) return "-";
    if (isValueEmpty) return PlaceholderTablePayroll(name, t);
    const [email, ...additional_emails] = value.split(",");
    return (
      <EmailInfoLocalTransfer
        additional_emails={additional_emails}
        email={email}
      >
        <EmailTextLocalTransfer
          additional_emails={additional_emails}
          email={stringCutter(email)}
        />
      </EmailInfoLocalTransfer>
    );
  }

  if (isAction) {
    if (isHovered) {
      return (
        <JackIcons
          name="delete-outline"
          fill={jackColors.neutral800}
          style={{ width: 20, height: 20 }}
        />
      );
    }
    return "";
  }

  if (isName) {
    const {
      name: nameComb,
      role: roleComb,
      department: departComb,
    } = objDataCombination || {};
    const isOptionalValue = !!roleComb || !!departComb;

    const isEmpty = (val) => val.length === 0;

    if (invalidFormat) return IncorrectDiv(value);
    if (invalid) return IncorrectDiv("-");
    if (isEmpty(nameComb) && isEmpty(roleComb) && isEmpty(departComb))
      return PlaceholderTablePayroll(name, t);

    return (
      <div>
        <GothamMedium style={{ lineHeight: "20px", marginBottom: 2 }}>
          {nameComb}
        </GothamMedium>
        {isOptionalValue && (
          <GothamRegular
            className="font12"
            style={{ color: jackColors.neutral700 }}
          >
            {roleComb} {!!roleComb && !!departComb && "-"} {departComb}
          </GothamRegular>
        )}
      </div>
    );
  }

  if (isBank) {
    if (invalid) return IncorrectDiv("-");
    if (duplicate) return DuplicateDiv(bankValue?.label);
    if (!bankValue) return PlaceholderTablePayroll(name, t);
    return bankValue?.label;
  }

  if (isAccNumber || isNotes) {
    if (invalid) return IncorrectDiv(`-`);
    if (duplicate) return DuplicateDiv(value);
    if (isValueEmpty) return PlaceholderTablePayroll(name, t);
  }

  return value;
};

export const PlaceholderTablePayroll = (name, t = (item) => item) => {
  const { isName, isEmail, isSalary, isAccNumber, isBank, isNotes } =
    useBooleanTable({ name });

  const placeholderDecider = () => {
    if (isName) return t("Enter employee name");
    if (isBank) return t("Select bank");
    if (isAccNumber) return t("Enter account number");
    if (isNotes) return t("Enter transfer note");
    if (isEmail) return t("Enter email");
    if (isSalary) return "IDR 0";
    return "-";
  };

  const placeholder = placeholderDecider();

  return <span style={{ color: jackColors.neutral600 }}>{placeholder}</span>;
};

export const DataCombinationPayroll = (array) => {
  let arr = [];

  for (let item of array) {
    const { name } = item;
    const { isName, isRole, isDept } = useBooleanTable({ name });
    const isCombination = isName || isRole || isDept;
    if (isCombination) {
      arr.push(item);
    }
  }

  let objDataCombination = {};

  array.forEach((item) => {
    objDataCombination[item.name] = item.value;
  });

  return { array: arr, objDataCombination };
};

export const AddPrevRecipientPayroll = ({
  data,
  formattedOptionsBank,
  defaultData,
  dummyData,
}) => {
  const isDummySameAsIndexZero =
    JSON.stringify(dummyData) === JSON.stringify(defaultData[0]);
  const isOnlyOneEmptyData = defaultData.length === 1 && isDummySameAsIndexZero;
  let formatted = isOnlyOneEmptyData ? [] : [...defaultData];

  const dataFormatted = data?.map((item) => {
    const { id, name, bank_name, account_number, email, payroll_recipient } =
      item || {};

    const { department_name, team_role } = payroll_recipient;

    const bankData = formattedOptionsBank.filter(
      ({ name }) => name === bank_name
    )[0];

    const itemRaw = [
      { name: "delete" },
      { name: "number" },
      { name: "name", value: name, label: "Name" },
      { name: "role", value: team_role, label: "Role" },
      { name: "department", value: department_name, label: "Department" },
      {
        name: "bank",
        value: bankData?.name,
        label: "Bank",
        id: bankData?.id,
        details: bankData,
      },
      {
        name: "account_number",
        value: account_number,
        label: "Bank Account Number",
      },
      { name: "notes", value: "", label: "Notes" },
      { name: "email", value: email, label: "Email" },
      { name: "net_salary", value: "", label: "Net Salary" },
      { name: "local_recipient_id", value: id, label: "Local Recipient Id" },
      { name: "action" },
    ];
    formatted.push(itemRaw);
  });

  return formatted;
};
