import { useTranslation } from "react-i18next";
import { colors } from "../../../assets/colors";

export const customStatusColors = (t) => {
  return [
    {
      statuses: ["ACTIVE"],
      color: colors.green06,
      customTextLabel: t("Active"),
    },
    {
      statuses: ["TEMP_BLOCK"],
      color: colors.salmonFE,
      customTextLabel: t("Temporary Blocked"),
    },
    {
      statuses: ["REJECTED"],
      color: colors.salmonFE,
      customTextLabel: t("Rejected"),
    },
    {
      statuses: ["P_BLOCK"],
      color: colors.redDC,
      customTextLabel: t("Permanent Blocked"),
    },
    {
      statuses: ["SUSPENDED"],
      color: colors.redDC,
      customTextLabel: t("Suspended"),
    },
    {
      statuses: ["REQUEST_ACTIVE"],
      color: colors.blue60,
      customTextLabel: t("Activating"),
    },
    {
      statuses: ["REQUEST_NEW_CARD", "NOT_ACTIVE"],
      color: colors.yellowFF,
      customTextLabel: t("On Request"),
    },
    {
      statuses: ["REQUEST_TEMP_BLOCK", "REQUEST_P_BLOCK"],
      color: colors.yellowFF,
      customTextLabel: t("Requesting Block"),
    },
    {
      statuses: ["REQUEST_UNBLOCK"],
      color: colors.yellowFF,
      customTextLabel: t("Request Unblock"),
    },
    {
      statuses: ["DELIVERED"],
      color: colors.neutral600,
      customTextLabel: t("Not Active"),
    },
    {
      statuses: ["REQUEST_LIMIT"],
      color: colors.yellowFF,
      customTextLabel: t("Request Limit"),
    },
    {
      statuses: ["PENDING"],
      color: "#C3C3C8",
      customTextLabel: "Pending",
    },
  ];
};

export const customStatusColorsStatement = (t) => [
  {
    statuses: ["paid"],
    color: colors.green06,
    customTextLabel: t("Paid"),
  },
  {
    statuses: ["generated", "waiting_for_payment"],
    color: colors.redDC,
    customTextLabel: t("Unpaid"),
  },
];

export const customStatusColorsTransaction = (t) => [
  {
    statuses: ["approved"],
    color: colors.green06,
    customTextLabel: t("Success"),
  },
  {
    statuses: ["returned"],
    color: colors.green06,
    customTextLabel: t("Returned"),
  },
  {
    statuses: ["on_progress"],
    color: colors.yellowF4,
    customTextLabel: t("In Progress"),
  },
  {
    statuses: ["failed", "declined"],
    color: colors.redDC,
    customTextLabel: t("Failed"),
  },
  {
    statuses: ["cancelled"],
    color: colors.redDC,
    customTextLabel: t("Cancelled"),
  },
];

export const customStatusColorsBilling = (t) => [
  {
    statuses: ["waiting_for_payment"],
    color: colors.yellowFFC,
    customTextLabel: t("Waiting for Payment"),
  },
  {
    statuses: ["paid"],
    color: colors.green06,
    customTextLabel: t("Success"),
  },
  {
    statuses: ["scheduled"],
    color: colors.blue60,
    customTextLabel: t("Scheduled"),
  },
  {
    statuses: ["payment_delayed"],
    color: colors.orangeFF,
    customTextLabel: t("Payment Delayed"),
  },
];
