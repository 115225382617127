import styles from "./styles.module.css";
import {
  ACTIVE,
  COMPLETED,
  FAILED,
  FOLDED,
  INACTIVE,
  PARTIAL_FAILED,
  RETRY,
  SUCCESS,
} from "../../constants";
import { JackIcons } from "../../../../assets/jackIcons/parent";

const StatusDot = ({ status = INACTIVE }) => {
  switch (status) {
    case COMPLETED:
      return (
        <JackIcons
          role="dot"
          data-testid="dot-completed"
          name="active-dot-tracker"
          fill="#06AC4E"
        />
      );
    case SUCCESS:
      return (
        <JackIcons
          role="dot"
          data-testid="dot-success"
          name="active-dot-tracker"
          fill="#343434"
        />
      );
    case FAILED:
      return (
        <JackIcons
          role="dot"
          data-testid="dot-failed"
          name="rejected-dot-tracker"
          fill="#E73126"
        />
      );
    case ACTIVE:
      return (
        <JackIcons
          role="dot"
          data-testid="dot-active"
          name="current-dot-tracker"
          fill="#B9FC00"
        />
      );
    case PARTIAL_FAILED:
      return (
        <JackIcons
          role="dot"
          data-testid="dot-partial_failed"
          name="active-dot-tracker"
          fill="#9AD7D0"
        />
      );
    case RETRY:
      return (
        <JackIcons
          role="dot"
          data-testid="dot-retry"
          name="retry-dot-tracker"
          fill="#343434"
        />
      );
    case FOLDED:
      return (
        <JackIcons
          role="dot"
          data-testid="dot-folded"
          name="three-dots-tracker"
          fill="#343434"
        />
      );
    case INACTIVE:
    default:
      return (
        <div
          role="dot"
          data-testid="dot-inactive"
          className={styles.inactiveDot}
        >
          <div className={styles.dot} />
        </div>
      );
  }
};

export default StatusDot;
