import { DATA_ERROR, SYSTEM_ERROR, MIXED_ERROR } from "../constants";

const retryResubmitBooleans = ({ user, drafter, releaser, warningType }) => {
  const userId = String(user?.id);
  const drafterId = String(drafter?.id);
  const releaserId = String(releaser?.id);

  const isDrafter = userId === drafterId;
  const isReleaser = userId === releaserId;

  const isDataError = warningType === DATA_ERROR;
  const isSystemError = warningType === SYSTEM_ERROR;
  const isMixedError = warningType === MIXED_ERROR;

  const isRetry = isSystemError;
  const isResubmit = isDataError || isMixedError;

  const isBothDrafterAndReleaser = isDrafter && isReleaser;

  const isReminder =
    !isBothDrafterAndReleaser &&
    ((isRetry && isDrafter) || (isResubmit && isReleaser));

  return {
    isRetry,
    isResubmit,
    isDrafter,
    isReleaser,
    isReminder,
    isBothDrafterAndReleaser,
  };
};

export default retryResubmitBooleans;
