import { forEach, isEmpty } from "lodash";
import { useEffect } from "react";
import { useForm } from "@tools";
import { Modal } from "reactstrap";
import { colors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { SimpleTextAreaJack } from "../../../../components/inputs/simpleTextArea";
import { PDFOrImageViewer } from "../../../../components/PDFPreview";
import { unformatCurrency } from "../../../../components/tools";
import {
  LineItems,
  TaxLineItems,
} from "../../../../pageComponents/invoiceComponents/createPage/modals/formComponents";
import { InvoiceFormSummary } from "../../../../pageComponents/invoiceComponents/createPage/modals/formComponents/component";
import { approverFormResolver } from "../../../../pageComponents/invoiceComponents/createPage/modals/formComponents/formSchema";
import {
  FormHeader,
  PDFLeftComponent,
} from "../../../../pageComponents/invoiceComponents/createPage/modals/formEdit";
import { idrStringFormatter } from "../../../../pageComponents/invoiceComponents/data/formatter";
import {
  createInvoiceSummaryForm,
  customNoteLabel,
  formatTransactionItems,
  useSubmitInvoiceDetails,
} from "./logic";
import { useTranslation } from "react-i18next";
import { useLanguage } from "public/locales/translationFunctions";

export const InvoiceDetailForm = ({
  defaultValue,
  isOpen,
  toggle,
  afterSuccessEdit,
}) => {
  const useFormObj = useForm({
    resolver: approverFormResolver,
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const { setValue, register, handleSubmit, formState, errors } = useFormObj;

  const isFormDirty = formState?.isDirty;
  const { submit, loading } = useSubmitInvoiceDetails({
    url: `/invoice_transactions/${defaultValue?.id}`,
    afterSuccess: afterSuccessEdit,
  });
  return (
    <EditSummaryModal
      isOpen={isOpen}
      toggle={toggle}
      defaultValue={defaultValue}
      useFormObj={useFormObj}
      onSubmit={handleSubmit(submit)}
      submitLoading={loading}
    />
  );
};

export const EditSummaryModal = ({
  isOpen,
  toggle,
  useFormObj,
  defaultValue,
  submitLoading,
  onSubmit,
  isReimbursement = false,
}) => {
  const pdf = defaultValue?.pdf || defaultValue?.file_url;
  const { t } = useTranslation("invoice-payment/invoice-payment");
  return (
    <Modal
      className="my-0"
      contentClassName="modal-full"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "50%",
            position: "relative",
            height: "100vh",
          }}
        >
          <div
            style={{
              height: "calc(100vh - 80px)",
              overflow: "scroll",
              padding: "12px 32px 52px 32px",
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
            className="hideScrollbar"
          >
            <FormHeader
              title={t("Edit Summary")}
              text={t(
                "Make sure the details matched with the uploaded receipt."
              )}
              style={{ borderBottom: "", marginBottom: 0 }}
            />
            <Form
              useFormObj={useFormObj}
              defaultValue={defaultValue}
              isReimbursement={isReimbursement}
            />
          </div>
          <FormSummarySheet
            handleBack={toggle}
            submitLoading={submitLoading}
            onClick={onSubmit}
            disableSubmit={false}
          />
        </div>
        <div style={{ width: "50%", height: "100vh" }}>
          <PDFOrImageViewer
            url={pdf}
            leftHeaderComponent={
              <PDFLeftComponent
                is_duplicate={defaultValue?.is_duplicate}
                pdf={pdf}
              />
            }
          />
        </div>
      </div>
    </Modal>
  );
};
const Form = ({ useFormObj, defaultValue, isReimbursement = false }) => {
  const { register, setValue, watch, reset } = useFormObj;
  const { fee } = defaultValue;
  const { isBahasa: isID } = useLanguage();

  const items = watch("transaction_items_attributes");
  const taxes = watch("transaction_taxes_attributes");
  const amount = unformatCurrency(watch("amount"));
  const tax = unformatCurrency(watch("tax"));
  const summary = createInvoiceSummaryForm({ items, taxes, fee, amount, tax });

  useEffect(() => {
    if (!isEmpty(defaultValue)) {
      const {
        transaction_items: ti_raw,
        transaction_taxes,
        amount,
        tax,
      } = defaultValue;
      const transaction_items = formatTransactionItems(ti_raw);
      const formattedAmount = idrStringFormatter(amount);
      const formDefaultValue = {
        transaction_items_attributes: transaction_items,
        transaction_taxes_attributes: transaction_taxes,
        amount: formattedAmount,
        initial_amount: amount,
        tax: tax,
      };

      const keys = Object?.keys(formDefaultValue);
      register("initial_amount");
      register("amount");
      register("tax");
      keys?.forEach((key) => setValue(key, formDefaultValue[key]));
    }
  }, [defaultValue]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 32,
      }}
      className="hideScrollbar"
    >
      <LineItems
        name="transaction_items_attributes"
        totalName="amount"
        useFormObj={useFormObj}
        ignoreEmptyValue={false}
      />
      <TaxLineItems
        name="transaction_taxes_attributes"
        totalName="tax"
        useFormObj={useFormObj}
        isReimbursement={isReimbursement}
      />
      <InvoiceFormSummary
        data={summary}
        isReimbursement={isReimbursement}
        hideFee
      />
      <SimpleTextAreaJack
        useFormObj={useFormObj}
        name="notes_from_editing"
        label={customNoteLabel()}
        placeholder={isID ? "Ketik disini..." : "Type here..."}
        maxLength={100}
      />
    </div>
  );
};
const FormSummarySheet = ({
  handleBack,
  onClick,
  submitLoading,
  disableSubmit,
}) => {
  const { t } = useTranslation("common");
  return (
    <div
      style={{
        width: "100%",
        height: 80,
        padding: "12px 24px 12px 24px",
        backgroundColor: colors.neutral900,
        position: "absolute",
        bottom: 0,
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", gap: 12 }}>
        <ButtonJack
          type="outline"
          style={{
            border: "1px solid #E6E6E8",
            backgroundColor: "transparent",
            color: "#FFFFFF",
            minWidth: 58,
          }}
          classNameText="text-white"
          onClick={handleBack}
        >
          {t("Cancel")}
        </ButtonJack>
        <ButtonJack
          type="filled"
          leftIcon={
            <JackIcons
              name="save_outline"
              fill={submitLoading ? colors.greyBB : colors.neutral900}
            />
          }
          style={{ minWidth: 56 }}
          onClick={onClick}
          isLoading={submitLoading}
          classNameText={submitLoading ? "text-greyBB" : "text-black34"}
          disabled={disableSubmit}
        >
          {t("Save Changes")}
        </ButtonJack>
      </div>
    </div>
  );
};

const myComp = () => {
  const [isOpen, setIsOpen] = usestate(false);
  const componentHeight = isOpen ? calculatedHeight : 40;
  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          height: "calc(100vh - 80px)",
          overflow: "scroll",
          padding: "12px 32px 52px 32px",
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <CustomComponent
          style={{
            borderRadius: 8,
            border: "1px solid var(--neutral-500, #E6E6E8)",
            height: componentHeight,
            overflow: "hidden",
            width: "100%",
            padding: "0px 8px",
          }}
        >
          {/* my customComponent */}
        </CustomComponent>
      </div>
    </div>
  );
};
