import React, { createContext, useEffect, useState } from "react";
import { fetchGetAll } from "../pageComponents/localTransferComponents/localTransfer/fetch";
import { deepRemoveDuplicates } from "../components/tools";
import { getUserRole, useGetAuth } from "./AuthContext";
import { isEmpty } from "lodash";
import { fetch } from "../tools/api";

export const AlertAttentionContext = createContext();

export const AlertAttentionProvider = ({ children }) => {
  const { user } = useGetAuth();
  const { isEmployee } = getUserRole();

  const userPresent = !isEmpty(user);

  const { data: failedTransactionData, refetch: failedTransactionRefetch } =
    fetchGetAll({
      url: "/notifications/failed_transaction",
      additionalQuery: "&q[is_read_eq]=false&q[activity_id_not_null]=true",
    });

  const { data: repaymentDataCounter, refetch: repaymentRefetch } = fetch({
    url: "/bills?per_page=1&q[state_eq]=waiting_for_payment",
    woInit: true,
    formatter: (res) => res?.total_page || 0,
    defaultValue: 0,
  });

  const { data: cardExpiredData, refetch: cardExpiredRefetch } = fetch({
    url: "/notifications/expired_card",
    woInit: true,
  });

  const failedTransactionArray = failedTransactionData?.data || [];
  const cardExpiredDataCounter = cardExpiredData?.data?.length;
  const uniqueArray = deepRemoveDuplicates(
    failedTransactionArray,
    "activity_id"
  );

  const alertsAttentionsTotalData =
    repaymentDataCounter + cardExpiredDataCounter || 0;

  useEffect(() => {
    if (!userPresent) return;
    failedTransactionRefetch();
    !isEmployee && repaymentRefetch();
    cardExpiredRefetch();
  }, [userPresent]);

  return (
    <AlertAttentionContext.Provider
      value={{
        alertsAttentionsTotalData,
        refetch: failedTransactionRefetch,
        repaymentRefetch,
      }}
    >
      {children}
    </AlertAttentionContext.Provider>
  );
};
