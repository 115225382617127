import { useRouter } from "next/router";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "@tools";
import { Modal } from "reactstrap";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { FileInputJack } from "../../../../components/inputs/fileInputs/fileInput";
import LoadingSpinner from "../../../../components/Loading";
import { PDFOrImageViewer } from "../../../../components/PDFPreview";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import {
  formatCurrency,
  schemaGenerator,
  toBase64,
  unformatCurrency,
  useDebounce,
} from "../../../../components/tools";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { apiBusiness, fetch, useMutation } from "../../../../tools/api";
import { uploadFilesFormatter } from "../../../invoiceComponents/components";
import {
  FormHeader,
  FormSummarySheet,
  PDFLeftComponent,
  redStarLabel,
} from "../../../invoiceComponents/createPage/modals/formEdit";
import { attachmentDataFormatter } from "../../../invoiceComponents/data/formatter";
import { reimbursementFormatter } from "../../data/formatter";
import { ReimbursementFormInput } from "../../modals/formEdit";
import * as yup from "yup";
import { useModalHook } from "../../../../components/Modals";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import backIcon from "../../../../assets/images/back-invoice-modal.svg";
import { handleDateforBE } from "../../data";
import { confirmIcon } from "../../data/constant";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../../../categoryComponents/general/hooks";
import {
  amountMissMatchChecker,
  formatSubmitItems,
  useInvoiceFormDebounce,
} from "../../../invoiceComponents/createPage/modals/formLogic";
import { formatTaxesForSubmit } from "../../../../modals/smartActivityModal/invoicePayment/editDetailModal/logic";
import { useReimbursementSingle } from "./formLogic";
import { useTranslation } from "react-i18next";

export const ReceiptForm = ({
  detailId,
  toggle,
  refetchMain = () => {},
  deleteBack = false,
}) => {
  const { t } = useTranslation("reimbursement/reimbursement");

  //======================================LOCALIZATION NEEDS====================================================
  const resolver = schemaGenerator({
    stringArr: [
      {
        name: "merchant",
        yup: yup
          .string()
          .required("Please fill out this field")
          .max(255, "Maximum 255 characters reached."),
      },
      {
        name: "purchased_date",
        yup: yup
          .string()
          .required("Please fill out this field")
          .test(
            "is-ok",
            "Please fill out this field",
            (val) =>
              val !=
              "Thu Jan 01 1970 07:00:00 GMT+0700 (Western Indonesia Time)"
          )
          .test("is-ok", "Please fill out this field", (val) => val != null)
          .nullable(),
      },
      {
        name: "amount",
        yup: yup
          .string()
          .required("Please fill out this field")
          .test(
            "is-ok",
            "Please fill out this field",
            (val) => Number(unformatCurrency(val)) > 0
          )
          .test(
            "is-above-10k",
            t("Minimum amount: IDR 10,000"),
            (val) => unformatCurrency(val) >= 10000
          ),
      },
      {
        name: "description",
        yup: yup
          .string()
          .transform((_, originalValue) => {
            if (typeof originalValue === "object" && originalValue !== null)
              return "";

            return originalValue;
          })
          .required("Please fill out this field")
          .test(
            "is-notempty",
            "Please fill out this field",
            (val) =>
              `${val}`.replace(/<[^>]*>?/gm, "").replace(/(\r\n|\n|\r)/gm, "")
                ?.length > 0
          ),
      },
    ],
  });

  //===========================================================================================================

  const noReceipt = typeof detailId == "boolean";
  const handleClose = async () => {
    if (noReceipt)
      await apiBusiness.delete(`/reimbursement_receipts/${reimbursement.id}`);
    setDataRmb({});
    reset({});
    toggle();
  };
  const [updateLoading, setUpdateLoading] = useState(false);
  const { toggle: toggleBackFormModal, isOpen: openFormBack } = useModalHook();

  const useFormObj = useForm({
    resolver,
    mode: "all",
    reValidateMode: "onChange",
  });

  // const {
  //   data: reimbursement,
  //   loading: fetchLoading,
  //   refetch,
  //   setData: setDataRmb,
  // } = fetch({
  //   url: `/reimbursement_receipts/${detailId}`,
  //   formatter: ({
  //     data,
  //     attachment_data = [],
  //     attachment_data_reimbursement_doc,
  //     ...rest
  //   }) => {
  //     const fileId = attachment_data_reimbursement_doc?.[0]?.id;
  //     const [newData] = reimbursementFormatter([data]);
  //     const newAttachmentData = attachmentDataFormatter(
  //       (attachment_data || []).map((item, index) => {
  //         const url = (newData?.supporting_documents_files || [])[index];
  //         return { ...item, url };
  //       })
  //     );

  //     const reimbursementData = {
  //       ...newData,
  //       fileId,
  //       attachment_data: newAttachmentData,
  //     };
  //     //reset(defaultValues);
  //     return reimbursementData;
  //   },
  //   woInit: true,
  //   defaultValue: {},
  // });

  const { reset, handleSubmit, setValue, watch, errors, register } = useFormObj;
  const { reimbursement, refetch, reimbursementFetchLoading, setDataRmb } =
    useReimbursementSingle({ detailId, reset, register });

  const [isErrorCategory, setIsErrorCategory] = useState(false);
  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory } = useCategoryFlag();
  const category = watch("category");
  const isMissingRequiredCategory =
    isRequiredCategory && !category && hasCategoryList;

  const { successSnackBar } = ToasterHook();
  const onSubmit = async (val) => {
    try {
      if (isMissingRequiredCategory) return;
      eventsTracker("reimbursement_save_receipt_details", {
        receipt_id: detailId,
      });
      const {
        merchant,
        purchased_date,
        amount: amountRaw,
        description,
        upload_file = [],
        category,
        transaction_items: ti_raw,
        transaction_taxes: tt_raw,
        initial_amount,
      } = val;

      const amount = unformatCurrency(amountRaw);
      const transaction_items_attributes = formatSubmitItems(ti_raw);
      const transaction_taxes_attributes = formatTaxesForSubmit(tt_raw);
      const { deletedFileIds, uploadFileNew } = uploadFilesFormatter(
        reimbursement?.attachment_data,
        upload_file
      );

      setUpdateLoading(true);

      const payload = {
        merchant,
        purchased_date: handleDateforBE(purchased_date),
        // amount,
        memo_text: description,
        transaction_items_attributes,
        transaction_taxes_attributes,
      };

      const isAmountMissmatch = amountMissMatchChecker(
        transaction_items_attributes,
        amount
      );

      if (Number(amount) != initial_amount || isAmountMissmatch)
        payload.amount = amount;
      if (category?.id) payload.category_id = category?.id;

      const updateReimbursement = async (data) =>
        await apiBusiness.put(
          `/reimbursement_receipts/${reimbursement?.id}`,
          data
        );

      await updateReimbursement(payload);
      const formData = new FormData();

      for (let i = 0; i < uploadFileNew.length; i++) {
        const file = uploadFileNew[i];
        formData.append("supporting_documents[]", file);
      }
      if (isAmountMissmatch) formData.append("amount", amount);
      await updateReimbursement(formData);

      for (let i = 0; i < deletedFileIds.length; i++) {
        const deletedId = deletedFileIds[i];
        await apiBusiness.delete(
          `reimbursement_receipts/${detailId}/attachment/${deletedId}`
        );
      }

      successSnackBar({
        msg: t("Expense details has been updated!"),
        showClose: true,
      });
      refetchMain();
    } catch (error) {
      console.log(error);
    } finally {
      if (isMissingRequiredCategory) return;
      setUpdateLoading(false);
      if (noReceipt)
        return push(`/reimbursement/create?step=review&btcid=${btcid}`);
      handleClose();
    }
  };
  const { query, push } = useRouter();
  const { btcid } = query;

  const onCreate = async (val) => {
    if (isMissingRequiredCategory) return;
    eventsTracker("reimbursement_save_receipt_details", {
      receipt_id: detailId,
    });
    const {
      merchant,
      amount: amountRaw,
      description: memo_text,
      purchased_date,
      upload_file = [],
      category,
    } = val;
    const payload = {
      merchant,
      amount: unformatCurrency(amountRaw),
      memo_text,
      purchased_date: handleDateforBE(purchased_date),
      reimbursement_id: btcid,
    };
    if (category?.id) payload.category_id = category?.id;
    const formData = new FormData();
    const keys = Object.keys(payload);
    keys.forEach((key) => formData.append(key, payload[key]));
    if (upload_file) {
      upload_file.forEach((file) =>
        formData.append("supporting_documents[]", file)
      );
    }

    const { status, ...rest } = await apiBusiness.post(
      "/reimbursement_receipts/create",
      formData
    );

    if (status == 200) {
      push(`/reimbursement/create?step=review&btcid=${btcid}`);
      toggle();
    }
  };

  // const amountOnForm = watch("amount");
  // const getAmount = useDebounce(amountOnForm, 50);
  // useEffect(
  //   () => setValue("amount", `IDR ${formatCurrency(getAmount)}`),
  //   [getAmount]
  // );

  const { mutation: uploadReceipt, loading: loadingUpload } = useMutation({
    url: "/reimbursement_receipts/create",
    method: "post",
    afterSuccess: ({ data: resData }) => {
      const { data } = resData;
      const [formattedData] = reimbursementFormatter([data]);
      setDataRmb(formattedData);
    },
    formatter: ({ data, attachment_data = [], ...rest }) => {
      const [newData] = reimbursementFormatter([data]);
      const newAttachmentData = attachmentDataFormatter(
        (attachment_data || []).map((item, index) => {
          const url = (newData?.supporting_documents_files || [])[index];
          return { ...item, url };
        })
      );

      const reimbursementData = {
        ...newData,
        attachment_data: newAttachmentData,
      };
      return reimbursementData;
    },
  });

  const [loadingUpdateR, setUpdateLoadingR] = useState(false);
  const updateReceipt = async (val) => {
    try {
      setUpdateLoadingR(true);
      const respon = await apiBusiness.put(
        `/reimbursement_receipts/${detailId}`,
        val
      );
    } finally {
      await refetch();
      setUpdateLoadingR(false);
      // Boolean(reimbursement?.id) && !noReceipt && handleClose();
    }
  };

  const handleDeleteReceipt = async () => {
    const { status } = await apiBusiness.delete(
      `/reimbursement_receipts/${detailId}/document/${reimbursement?.fileId}`
    );
    if (status == 200) refetch();
  };

  const pdfViewerFlag = Boolean(reimbursement?.file_url);

  useEffect(() => {
    if (!detailId) return;
    refetch();
  }, [detailId]);

  const onClickSave = async () => {
    const noReceiptButUploaded = noReceipt && Boolean(reimbursement?.id);

    const onSave = () => {
      if (isRequiredCategory && !category) setIsErrorCategory(true);
      if (noReceiptButUploaded) return onSubmit;
      if (noReceipt) return onCreate;
      return onSubmit;
    };

    handleSubmit(onSave())();
  };

  const topRef = useRef(null);

  useEffect(() => {
    const scrollIntoView = (ref) =>
      ref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });

    const errorKeys = Object.keys(errors);
    if (errorKeys?.length == 0) return;

    return scrollIntoView(topRef);
  }, [errors]);

  //partial takeout
  useInvoiceFormDebounce(useFormObj);

  return (
    <Modal
      className="my-0"
      contentClassName="modal-full"
      isOpen={detailId}
      toggle={handleClose}
      keyboard={false}
    >
      {reimbursementFetchLoading ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "50%",
              position: "relative",
              minHeight: "100vh",
            }}
          >
            <div
              style={{
                height: "calc(100vh - 80px)",
                overflow: "scroll",
                padding: "12px 32px 52px 32px",
                display: "flex",
                flexDirection: "column",
                gap: 32,
              }}
              className="hideScrollbar"
            >
              <FormHeader
                title={t("Expense Details")}
                text={t("Make sure all details match the receipt.")}
              />
              <ReimbursementFormInput
                useFormObj={useFormObj}
                data={reimbursement}
                isErrorCategory={isErrorCategory}
                setIsErrorCategory={setIsErrorCategory}
                description={reimbursement?.memo_text}
                topRef={topRef}
              />
            </div>
            <FormSummarySheet
              handleBack={noReceipt ? toggleBackFormModal : handleClose}
              submitLoading={updateLoading}
              onClick={onClickSave}
            />
          </div>
          <div
            style={{
              width: "50%",
              height: "100vh",
              backgroundColor: "#F1F1F1",
            }}
          >
            <ReimbursementFileDisplay
              flag={pdfViewerFlag}
              fileUrl={reimbursement?.file_url}
              handleUploadReceipt={noReceipt ? uploadReceipt : updateReceipt}
              btcid={btcid}
              isUpdate={!noReceipt && Boolean(reimbursement?.file_url)}
              isDuplicate={reimbursement?.is_duplicate}
            />
          </div>
        </div>
      )}
      <LoadingModal isOpen={loadingUpload || loadingUpdateR} />
      <ConfirmationModalJack
        modal={openFormBack}
        img={backIcon}
        buttonTextLeft="Cancel"
        buttonTextRight="Yes"
        title={t("Are you sure?")}
        text={t("Changes you made so far will not be saved.")}
        onClick={handleClose}
        toggle={toggleBackFormModal}
      />
    </Modal>
  );
};

const ReceiptUpload = ({ upload, id, isUpdate = false }) => {
  const useFormObj = useForm();
  const { watch, setValue } = useFormObj;
  const currValue = watch("upload_file");
  const { toggle, isOpen } = useModalHook();
  const handleUpload = () => {
    const formData = new FormData();
    formData.append("reimbursement_document", currValue);
    formData.append("reimbursement_id", id);
    upload(formData);
  };
  const { t } = useTranslation("reimbursement/reimbursement");

  const handleReset = () => setValue("upload_file", undefined);

  useEffect(() => {
    if (currValue) {
      if (isUpdate) return toggle();
      return handleUpload();
    }
  }, [currValue]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
      }}
    >
      <div style={{ width: 360, height: 144 }}>
        <FileInputJack
          fileTypes={["image/jpeg", "application/pdf", "image/png"]}
          useFormObj={useFormObj}
          name="upload_file"
          defaultValue={[]}
          multiple={false}
          showLabel={false}
          firstCopy={t("Browse or drag files here")}
        />
        <ConfirmationModalJack
          modal={isOpen}
          img={confirmIcon.reuploadReceipt}
          isCentered={true}
          title="Reupload receipt?"
          text="You're about to replace the current receipt. All details will be deleted and updated according to the new receipt."
          buttonTextLeft="Back"
          buttonTextRight="Reupload"
          toggle={() => {
            handleReset();
            toggle();
          }}
          onClick={handleUpload}
        />
      </div>
    </div>
  );
};

const LoadingModal = ({ isOpen }) => {
  const { t } = useTranslation("reimbursement/reimbursement");
  return (
    <Modal
      className="my-0"
      isOpen={isOpen}
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          width: 195,
          height: 136,
          borderRadius: 15,
          display: "flex",
          flexDirection: "column",
          gap: 24,
          padding: 24,
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            width: 40,
            height: 40,
          }}
          className="loading-bank"
        >
          <JackIcons
            name="loader_outline"
            style={{
              height: 40,
              width: 40,
              // position: "absolute",
              // top: 0,
              // right: 0,
            }}
            fill={jackColors.black34}
          />
        </div>
        <GothamMedium className="font16" style={{ color: jackColors.black34 }}>
          {t("Processing...")}
        </GothamMedium>
      </div>
    </Modal>
  );
};

export const DeleteReceiptButton = memo(({ onClick }) => {
  const { isOpen, toggle } = useModalHook();
  const { t } = useTranslation("reimbursement/reimbursement");

  return (
    <>
      <div>
        <JackIcons
          style={{ height: 20, width: "auto" }}
          name="delete_outline"
          className="darkhover"
          onClick={toggle}
        />
      </div>
      <ConfirmationModalJack
        img={confirmIcon.tongSampah}
        modal={isOpen}
        isCentered={true}
        title={t("Delete Receipt?")}
        text={t(
          "You will need to upload another receipt if you delete this one."
        )}
        buttonTextLeft="Cancel"
        buttonTextRight={t("Delete")}
        toggle={toggle}
        onClick={onClick}
      />
    </>
  );
});

const ReimbursementFileDisplay = ({
  flag: showViewer,
  fileUrl,
  handleUploadReceipt,
  btcid,
  isUpdate,
  isDuplicate = false,
}) => {
  const [softDelete, setSoftDelete] = useState(false);
  const handleClick = useCallback(() => {
    eventsTracker("reimbursement_soft_delete_receipt", { batch_id: btcid });
    setSoftDelete(true);
  }, []);
  if (showViewer && !softDelete)
    return (
      <PDFOrImageViewer
        url={fileUrl}
        onClickReimbursement={handleClick}
        leftHeaderComponent={
          <PDFLeftComponent
            is_duplicate={isDuplicate}
            pdf={fileUrl}
            product="receipt"
          />
        }
      />
    );

  return (
    <ReceiptUpload
      upload={handleUploadReceipt}
      id={btcid}
      isUpdate={isUpdate}
    />
  );
};
