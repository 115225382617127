import { jackColors } from "assets/colors";
import { JackIcons } from "assets/jackIcons/parent";
import { ButtonJack } from "components/ButtonsJack/parent";
import FileInputJack from "components/typescript/inputs/fileInputs/fileInput";
// import { ButtonJack } from "components/ButtonsJack/parent";
import { useModalWidth } from "components/Modals";
import { GothamMedium, GothamRegular } from "components/typescript/Text";
// import { GothamMedium } from "components/Text";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Modal } from "reactstrap";
import { FormDataLocalTransfer } from "../typeLocalTransfer";
import { useTranslation } from "react-i18next";

type ModalOptionalCreateTransfer = {
  toggle: any;
  isOpen: boolean;
  onClickDownload?: () => void;
  useFormObj: UseFormReturn<FormDataLocalTransfer>;
  woButton?: boolean;
  handleClick: (props: { type: string; values: string }) => void;
  selectedItem: string;
};
const ModalOptionalCreateTransfer: React.FC<ModalOptionalCreateTransfer> = ({
  toggle,
  isOpen,
  onClickDownload,
  useFormObj,
  woButton,
  handleClick,
  selectedItem,
}) => {
  const { watch } = useFormObj;
  const isHasValue = watch("upload_file");
  const modalWidth = useModalWidth(480);
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const arrayUploadDetails = [
    {
      title: tLocalTransfer("1. Download this template"),
      desc: tLocalTransfer(
        "Download and fill in the transfer details according to this template."
      ),
      actionTemplate: (
        <ButtonJack
          onClick={onClickDownload}
          className="w-100"
          type="outline"
          children="Download template"
          style={{ height: 32 }}
          rightIcon={
            <JackIcons
              style={{ width: 20, height: 20 }}
              name="download-outline"
              fill={jackColors.neutral900}
            />
          }
        />
      ),
    },
    {
      title: tLocalTransfer("2. Upload the filled template"),
      desc: tLocalTransfer(
        "Upload the filled Excel/CSV to start creating your transaction."
      ),
      actionTemplate: (
        <FileInputJack
          showLabel={false}
          fileTypes={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          useFormObj={useFormObj}
          name="upload_file"
          firstCopy={tLocalTransfer("Browse or drag files here")}
          multiple={false}
          customError={tLocalTransfer(
            "Please ensure you upload a supported file type (Excel)."
          )}
        />
      ),
    },
  ];
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName={"modal-transfez"}
    >
      <div
        style={{
          width: modalWidth,
          borderRadius: 8,
          flexDirection: "column",
        }}
        className="d-flex"
      >
        <div
          style={{
            padding: 20,
            borderBottom: `1px solid ${jackColors.greyE6}`,
            borderColor: jackColors.greyE6,
            alignItems: "center",
          }}
        >
          <GothamMedium className="font20">Upload Excel</GothamMedium>
          <div
            className="hover-600"
            style={{
              width: 40,
              height: 40,
              border: "1px solid #E6E6E8",
              borderRadius: 4,
              display: "grid",
              placeItems: "center",
              position: "absolute",
              cursor: "pointer",
              right: 20,
              top: 15,
            }}
            onClick={toggle}
          >
            <JackIcons
              name="close_Outline"
              fill="#343434"
              style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
        {arrayUploadDetails?.map((item, index) => {
          const { title, desc, actionTemplate } = item;
          return (
            <div key={index} style={{ padding: 20 }}>
              <div>
                <GothamMedium className="mb-1">{title}</GothamMedium>
                <GothamRegular
                  className="font12"
                  style={{ color: jackColors.neutral700, marginBottom: 16 }}
                >
                  {desc}
                </GothamRegular>
                {actionTemplate}
              </div>
            </div>
          );
        })}
      </div>
      {!woButton && (
        <div
          className="d-flex justify-content-end"
          style={{
            gap: 16,
            marginTop: 32,
            padding: 20,
            borderTop: `1px solid ${jackColors.greyE6}`,
          }}
        >
          <ButtonJack
            type="outline"
            children={tLocalTransfer("Back")}
            style={{ minWidth: 65 }}
            onClick={() => handleClick({ type: "back", values: selectedItem })}
            leftIcon={
              <JackIcons
                name="arrow-back-outline"
                fill="black"
                style={{ width: 20, height: 20 }}
              />
            }
          />
          <ButtonJack
            disabled={!isHasValue}
            type="filled"
            children={tLocalTransfer("Upload")}
            style={{ minWidth: 60 }}
            onClick={() => handleClick({ type: "next", values: isHasValue })}
            rightIcon={
              <JackIcons
                style={{ width: 20, height: 20 }}
                name="arrow-forward"
                fill={!isHasValue ? jackColors.neutral600 : "black"}
              />
            }
          />
        </div>
      )}
    </Modal>
  );
};

export default ModalOptionalCreateTransfer;
