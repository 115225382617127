import { ModalOption } from "../paymentOptions/components";
import {
  PaymentCycleOption,
  SpesificDateOption,
} from "../paymentOptions/modals";
import { optionsFormatter } from "../paymentOptions/formatter";
import { fetch } from "../../../tools/api";

export const RescheduleForm = ({
  product,
  invoiceDueDate,
  state,
  defaultValue,
}) => {
  const [rescheduleItem, setItem] = state;
  const { type, description } = rescheduleItem;
  const isPayImmediately = type == "default";
  const isSpesificDate = type == "spesific_date";
  const isPaymentCycle = type == "payment_cycle";

  const {
    data: predefinedSchedules,
    loading,
    refetch: refetchList,
  } = fetch({
    url: `/predefined_schedules?q[transaction_type_eq]=${product}`,
    formatter: ({ data: rawData }) => optionsFormatter(rawData, invoiceDueDate),
  });

  const handleChangeOption = (property, value) => {
    if (!property) return;
    if (property == "type") return setItem((p) => ({ type: value }));

    return setItem(value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
      <ModalOption
        type="default"
        isActive={isPayImmediately}
        onClick={handleChangeOption}
      />
      <ModalOption
        type="spesific_date"
        isActive={isSpesificDate}
        onClick={handleChangeOption}
      >
        <SpesificDateOption
          onChange={handleChangeOption}
          // defaultValue={spesificDateDefaultValue}
        />
      </ModalOption>
      <PaymentCycleOption
        isActive={isPaymentCycle}
        description={description}
        onClick={handleChangeOption}
        list={predefinedSchedules}
        refetch={refetchList}
        loading={loading}
        product={product}
      />
    </div>
  );
};
