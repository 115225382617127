import { useTranslation } from "react-i18next";
import { RETRY_TEXT, RESUBMIT_TEXT } from "../constants";
import retryResubmitBooleans from "./retryResubmitBooleans";
import { useLanguage } from "public/locales/translationFunctions";

const retryResubmitBannerMessageDecider = ({
  warningType,
  user,
  drafter,
  releaser,
  t = () => {},
}) => {
  const { isBahasa } = useLanguage();
  const { isRetry, isResubmit, isReminder } = retryResubmitBooleans({
    user,
    drafter,
    releaser,
    warningType,
  });

  if (isReminder) {
    const drafterName = drafter?.name || drafter?.email || "-";
    const releaserName = releaser?.name || releaser?.email || "-";
    if (isRetry) {
      if (isBahasa)
        return `Klik tombol di bawah jika anda ingin mengulangi pembayaran transaksi ini.`;
      return `You can remind ${releaserName} retry the failed transaction(s) by clicking the button below.`;
    }
    if (isResubmit) {
      if (isBahasa)
        return `Klik tombol di bawah jika anda ingin mengingatkan Drafter untuk mengajukan kembali transaksi.`;
      return `You can remind ${drafterName} resubmit the failed transaction(s) by clicking the button below.`;
    }
    return "-";
  }

  // if (isRetry) return `You can ${RETRY_TEXT}`;
  // if (isResubmit) return `You can ${RESUBMIT_TEXT}`;
  if (isRetry)
    return t(
      "You can retry the failed transaction(s) by clicking the button below."
    );
  if (isResubmit)
    return t(
      "You can resubmit the failed transaction(s) by clicking the button below."
    );
  return "-";
};

export default retryResubmitBannerMessageDecider;
