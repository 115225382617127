import { GothamMedium } from "components/typescript/Text";
import React from "react";
import { FaSpinner } from "react-icons/fa";
import { jackIconsTo20 } from "../../inputs/textfield";
import { CSSProperties, ReactNode } from "react";

interface ButtonJackProps {
  children?: ReactNode;
  className?: string;
  type?: "filled" | "outline" | "borderless" | "outline-light";
  isBullet?: boolean;
  leftIcon?: ReactNode | null;
  rightIcon?: ReactNode | null;
  disabled?: boolean;
  isSmall?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
  buttonType?: "submit" | "button" | "reset";
  style?: CSSProperties;
  classNameText?: string;
  isLoadingLeft?: boolean;
  styleText?: CSSProperties;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  [key: string]: any; // To capture any other props
}

export const buttonsProps: ButtonJackProps = {
  children: <></>,
  className: "",
  type: "filled",
  isBullet: false,
  leftIcon: null,
  rightIcon: undefined,
  disabled: false,
  isSmall: false,
  onClick: () => {},
  isLoading: false,
  buttonType: "submit",
  style: {},
  classNameText: "",
  isLoadingLeft: false,
  styleText: {},
  onKeyDown: () => {},
};

export const ButtonJack = ({
  children = <></>,
  className: classNameProps = "",
  type = "filled",
  isBullet = false,
  leftIcon: leftIconProps = null,
  rightIcon: rightIconProps = undefined,
  disabled = false,
  isSmall = false,
  onClick = () => {},
  isLoading = false,
  buttonType = "submit",
  style = {},
  classNameText = "",
  isLoadingLeft = false,
  styleText = {},
  onKeyDown = () => {},
  ...props
}: ButtonJackProps) => {
  const [leftIcon, rightIcon] = jackIconsTo20([leftIconProps, rightIconProps]);

  const classNameDecider = (): string => {
    let className = `button`;
    if (isBullet) {
      className += " button-bullet";
      if (isSmall) className += " bullet-small";
    } else {
      className += ` button-${type}`;
      if (isSmall) className += " button-small";
    }
    return className + " " + classNameProps;
  };

  const className = classNameDecider();

  const isDisabled = isLoading || disabled;

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={isDisabled}
      style={{ cursor: isDisabled ? "no-drop" : "pointer", ...style }}
      type={buttonType}
      onKeyDown={onKeyDown}
      {...props}
    >
      {isLoadingLeft && isLoading ? (
        <FaSpinner className="icon-spin" />
      ) : (
        leftIcon && leftIcon
      )}
      <GothamMedium className={classNameText} style={styleText}>
        {children}
      </GothamMedium>
      {isLoading && !isLoadingLeft ? (
        <FaSpinner className="icon-spin" />
      ) : (
        rightIcon && rightIcon
      )}
    </button>
  );
};
