import { digitDateWithDigitHourFormatter } from "../../components/tools";

export const AwaitingRefundFormatter = (item) => {
  const { status_history, created_at } = item;

  const array = [
    {
      date: digitDateWithDigitHourFormatter(created_at),
      text: "You created a transaction",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[0]?.created_at
      ),
      text: "Your payment has been received",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[1]?.created_at
      ),
      text: "We sent out your money to the recipient",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[2]?.created_at
      ),
      text: "The recipient’s bank has declined the transaction",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[3]?.created_at
      ),
      text: "Sit tight. The refund process has been initiated. It may take up to 2x24 hours for your funds to be returned",
      isActive: true,
    },
  ];

  return array;
};
