export const eWalletSuccessBoolean = (type) => {
  const isAllocationRequest = type == "allocation_request"; // requested by user
  const isApproveAllocationRequest = type == "allocation_request_approved"; // approved by admin
  const isRejectAllocationRequest = type == "allocation_request_rejected"; // rejected by admin

  const isReturnRequestByAdmin = type == "return_balance_request_admin"; // requested by admin
  const isReturnRequestByUser = type == "return_balance_request_user"; // requested by user
  const isReturnRequestApproved =
    type == "return_balance_request_admin_approved"; // approved by user

  const isReturnRequestCancel = type == "return_balance_cancel_admin"; // cancelled by admin

  return {
    isAllocationRequest,
    isApproveAllocationRequest,
    isRejectAllocationRequest,
    isReturnRequestByAdmin,
    isReturnRequestByUser,
    isReturnRequestApproved,
    isReturnRequestCancel,
  };
};
