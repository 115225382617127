import { titleCase } from "change-case";
import { isEmpty } from "lodash";
import { unformatCurrencyDecimal } from "../../../components/tools";
import { payrollValidationFormatter } from "../../payrollCreateComponents/loop/validation/formatter";
import { useBooleanTable } from "./table/logic";

export const tableDefaultValues = ({ defaultValues, headerArray }) => {
  const result = defaultValues.map((item) => {
    const newArr = headerArray.map(({ value }) => {
      const bodyArrNames = item.map((item) => item?.name);
      const index = bodyArrNames
        .map((nameChild, index) => {
          if (nameChild == value) return index;
        })
        .filter((item) => typeof item == "number")[0];

      const result = item[index];
      return result;
    });
    return newArr;
  });
  return result;
};

export const DefaultValuesForTable = ({
  defaultValues = [],
  headerArray,
  option,
  isHasId,
  query = {},
}) => {
  // Formatter for "excel upload trx" to table
  const DataAddProps = () => {
    const newData = (defaultValues || []).map((item, index) => {
      const getNetSalary = item.filter(({ name }) => name === "net salary")[0];
      const bankData = GetBankDetails({ array: item, option });

      const objBank = {
        name: "bank",
        value: bankData?.value,
        id: bankData?.id,
        label: "Bank",
        details: bankData,
      };

      const objNetSalary = {
        name: "net_salary",
        value: getNetSalary?.value,
        label: "Salary",
      };

      return [
        objBank,
        objNetSalary,
        { name: "delete" },
        { name: "number" },
        // { name: "notes", value: "", label: "Notes" },
        { name: "action" },
        ...item,
      ];
    });
    return newData;
  };

  // Formatter for "previous trx" to table
  const formattedDefaultValues = payrollValidationFormatter(defaultValues, [
    { name: "delete" },
    { name: "number" },
    { name: "action" },
  ]);

  // Formatter for previous trx Label and Bank to Table
  const finalDataForPrevTrx = FinalFormatterPrevTrx({
    data: formattedDefaultValues,
    option,
  });

  const newData = isHasId ? finalDataForPrevTrx : DataAddProps();
  const fromDraft = query?.source == "draft";

  const result = newData.map((item) => {
    const getLocalId = item?.filter(
      ({ name }) => name === "id" || name === "local_recipient_id"
    );
    const newArr = headerArray.map(({ name }) => {
      const bodyArrNames = item.map((item) => item?.name);
      const index = bodyArrNames
        .map((nameChild, index) => {
          if (nameChild == name) return index;
        })
        .filter((item) => typeof item == "number")[0];

      let result = item[index];
      // // after 2 years code working perfectly and somehow should to change from amount (?)
      const amountField = item?.find(({ name }) => name == "amount");
      if (fromDraft) {
        if (
          result?.label?.toLowerCase().includes("salary") &&
          !isEmpty(amountField?.value)
        ) {
          result.value = amountField.value;
        }
      }
      return result;
    });
    return [...newArr, ...getLocalId];
  });

  return result;
};

export const addDataFormatted = ({ data = [] }) => {
  const newData = data?.map((item, index) => {
    const newItem = item?.map((sub, i) => {
      const { name, value } = sub || {};
      if (name === "number") {
        return {
          ...sub,
          value: index + 1,
        };
      }
      return sub;
    });
    return newItem;
  });
  return newData;
};

export const GetBankDetails = ({ array, option, compareId = false }) => {
  const typeFilter = compareId ? "bank" : "payer_id";
  const getBank = array.filter(({ name }) => name === typeFilter)[0];
  const getBankName = array.filter(({ name }) => name === "bank")[0];
  let specialBankName = "";
  if (getBankName?.value === "sulutgo") {
    specialBankName = "sulut";
  } else if (getBankName?.value === "bpd jabar") {
    specialBankName = "bjb";
  } else if (getBankName?.value === "btn syariah") {
    specialBankName = "btn_syar";
  }

  const getNewBank = option?.filter(({ name, id }) => {
    if (compareId) return id == getBank?.value;
    if (
      specialBankName == "sulut" ||
      specialBankName == "bjb" ||
      specialBankName == "btn_syar"
    ) {
      return name == specialBankName;
    }
    return id == getBank?.value;
  })[0];
  return getNewBank;
};

export const FormatterOptionsBank = ({ options }) => {
  const newOptions = options.map((item) => {
    return {
      ...item,
      value: item?.name,
      // label: item?.name,
    };
  });
  return newOptions;
};

export const dataFormatterFloatingButton = ({ data }) => {
  let totalAmount = 0;
  let totalTrx = 0;
  const newValues = (data || []).map((item, index) => {
    totalTrx += 1;
    const newData = item?.map((sub, i) => {
      if (sub?.name === "net_salary") {
        totalAmount += Number(unformatCurrencyDecimal(sub.value));
      }
    });
    return newData;
  });

  return {
    totalAmount,
    totalTrx,
  };
};

export const checkerEmptyWhenUpload = ({ data }) => {
  const newData = data?.map((item, index) => {
    const newItem = item?.map((sub, i) => {
      const { isRequired } = useBooleanTable({ name: sub?.name });
      return {
        ...sub,
        invalid: isRequired && !Boolean(sub?.value) ? true : false,
      };
    });
    return newItem;
  });
  return newData;
};

export const FinalFormatterPrevTrx = ({ data, option }) => {
  const newData = data?.map((array, index) => {
    const bankData = GetBankDetails({ array, option, compareId: true });

    const objBank = {
      name: "bank",
      value: bankData?.value,
      id: bankData?.id,
      label: "Bank",
      details: bankData,
    };

    const newArray = array?.map((sub, i) => {
      const label = LabelMasking({ name: sub?.name });
      return {
        ...sub,
        label,
      };
    });

    return [objBank, ...newArray];
  });
  return newData;
};

const LabelMasking = ({ name }) => {
  const { isAccNumber, isSalary } = useBooleanTable({ name });
  if (isAccNumber) return `Bank Account Number`;
  if (isSalary) return `Salary`;
  return titleCase(name);
};

export const OnSubmitPayrollCreateFormatter = (data = []) => {
  const newData = data?.map((array, index) => {
    let value = {};
    array.forEach((item) => {
      const { name, value: valueChild, options, details } = item || {};
      const isSalary = name == "net_salary";
      const isBank = name == "bank";
      if (valueChild === undefined) return;
      if (isBank) return (value[name] = { ...details, value: details?.id });
      if (isSalary)
        return (value[name] = Number(unformatCurrencyDecimal(valueChild)));
      value[name] = valueChild;
    });
    return value;
  });
  return newData;
};
