import ancestoryLinesFormatter from "./ancestoryLinesFormatter";
import findRetryIterationCount from "./findRetryIterationCount";

const initialFailedTrxCountDecider = (transactions = []) => {
  const ancestoryLines = ancestoryLinesFormatter(transactions);
  const initialFailedTrxCountDecider = findRetryIterationCount({
    ancestoryLines,
    desiredAncestoryLineLength: 1,
    isOnlyFindFailedTrx: true,
  });
  return initialFailedTrxCountDecider;
};

export default initialFailedTrxCountDecider;
