import { useState, useEffect } from "react";

export const useWorkingHours = (startTime, endTime) => {
  const [isWorkingHours, setIsWorkingHours] = useState(false);

  useEffect(() => {
    const checkIfWorkingHours = () => {
      const currentTime = new Date();
      const startHour = parseInt(startTime.split(":")[0]);
      const startMinute = parseInt(startTime.split(":")[1]);
      const endHour = parseInt(endTime.split(":")[0]);
      const endMinute = parseInt(endTime.split(":")[1]);

      const startDate = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        startHour,
        startMinute
      );
      const endDate = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        endHour,
        endMinute
      );

      setIsWorkingHours(currentTime >= startDate && currentTime <= endDate);
    };

    checkIfWorkingHours();
    const timerId = setInterval(checkIfWorkingHours, 1000 * 60); // Periksa setiap menit

    return () => clearInterval(timerId);
  }, [startTime, endTime]);

  return isWorkingHours;
};
