import { fileSizeString, stringCutter } from "@tools";
import { jackColors } from "assets/colors";
import JackIcons from "assets/jackIcons/typescript/parent";
import { titleCase } from "change-case";
import { useModalHook } from "components/Modals";
import { Avatar } from "components/typescript/Avatar";
import { Banner } from "components/typescript/Banner";
import { GothamMedium, GothamRegular } from "components/typescript/Text";
import { FormDataLocalTransfer } from "pageComponents/localTransferEnhancement/typescript/typeLocalTransfer";
import React, { ForwardedRef, forwardRef, useEffect, useRef } from "react";
import { UseFormReturn } from "react-hook-form";
import { Files } from "./component";
import {
  fileTypesTranslatorArrayToString,
  useSetsValueFileInput,
} from "./logic";
import { Trans, useTranslation } from "react-i18next";

type FileInputJackProps = {
  name: string;
  label?: React.ReactNode;
  labelStyle?: React.CSSProperties;
  multiple?: boolean;
  helperText?: string;
  maxFiles?: number;
  maxSizeMB?: number;
  fileTypes?: string[];
  firstCopy?: string;
  showLabel?: boolean;
  containerMaxHeight?: number;
  customError?: string;
  enablePreview?: boolean;
  middleComponent?: React.ReactNode;
  bottomComponent?: React.ReactNode;
  bannerMessage?: string;
  useFormObj: UseFormReturn<FormDataLocalTransfer>;
};

type FileObject = {
  name: string;
  size: number;
  // Add any other properties you expect to use
};

const singleFile = (
  files: any[],
  multiple: boolean
): { file: FileObject; hasSubmitted: boolean } => {
  const defaultObj = {
    file: { name: "", size: 0 } as FileObject,
    hasSubmitted: false,
  };
  const notSubmitted = Array.isArray(files);

  if (multiple || notSubmitted) return defaultObj;

  return { file: files, hasSubmitted: true };
};

const FileInputJack = forwardRef<HTMLDivElement, FileInputJackProps>(
  (
    {
      useFormObj,
      name,
      label: labelProps,
      labelStyle,
      multiple = false,
      helperText = "",
      maxFiles = 5,
      maxSizeMB = 10,
      fileTypes = ["image/png", "application/pdf"],
      firstCopy = "Browse or drag files here to upload",
      showLabel = true,
      containerMaxHeight,
      customError,
      enablePreview,
      middleComponent = null,
      bottomComponent = null,
      bannerMessage = "",
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { register, setValue, getValues, watch } = useFormObj || {};
    const { t: tLocalTransfer } = useTranslation(
      "local-transfer/local-transfer"
    );
    // Ensure setValue is defined or provide a fallback function
    const handleSetValue = setValue || (() => {});
    const handleShow = (file: { name: string; size: number }) => {
      // Handle showing file preview or any other action
      console.log("File to show:", file);
    };

    const label =
      typeof labelProps === "string"
        ? labelProps
        : labelProps || titleCase(name);

    const fileTypeString = fileTypesTranslatorArrayToString(fileTypes);
    const {
      setsValue,
      errors,
      reset,
      ref: inputRef,
    } = useSetsValueFileInput({
      fileTypes,
      maxFiles,
      maxSizeMB,
      multiple,
      name,
      useFormObj,
      errorWithToaster: false,
      customError: customError,
    });

    const files = getValues ? getValues()[name] || [] : [];

    useEffect(() => {
      if (register) register(name, { required: true });
    }, [name, register]);

    const { isOpen: isDragging, close: notDrag, open: drag } = useModalHook();

    const stopsPropagation = (e: React.DragEvent | React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const refCurrent = (ref as React.MutableRefObject<HTMLDivElement | null>)
      ?.current;
    const height = (refCurrent?.clientHeight ?? 0) + 8;

    const { file, hasSubmitted } = singleFile(files, multiple);

    const content = () => {
      if (hasSubmitted) {
        const { name, size } = file;
        return (
          <>
            <JackIcons
              style={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
              onClick={(e) => {
                stopsPropagation(e);
                reset();
              }}
              name="close"
              fill={jackColors.grey6C}
            />

            <Avatar
              type="square"
              mainIcon={
                <JackIcons
                  name="checkmark-square-2"
                  fill={jackColors.greenB9}
                />
              }
            />
            <GothamMedium className="my-1">
              {stringCutter(name, 30)}
            </GothamMedium>
            {!!size && (
              <GothamRegular className="font12">
                {fileSizeString(size)}
              </GothamRegular>
            )}
          </>
        );
      }

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Avatar
            type="square"
            mainIcon={
              <JackIcons name="file-outline" fill={jackColors.greenB9} />
            }
          />
          <GothamMedium
            className="font14"
            style={{ color: jackColors.black34 }}
          >
            {firstCopy}
          </GothamMedium>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.grey6C }}
          >
            <Trans
              i18nKey={tLocalTransfer("dynamic.dynamic_text_26", { maxSizeMB })}
            />
          </GothamRegular>
          <GothamRegular
            className="font12"
            style={{
              color: jackColors.grey6C,
            }}
          >
            <Trans
              i18nKey={tLocalTransfer("dynamic.dynamic_text_27", {
                fileTypeString: fileTypeString,
              })}
            />
          </GothamRegular>
        </div>
      );
    };

    return (
      <div
        className="d-flex"
        style={{
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div ref={ref}>
          {showLabel && (
            <GothamMedium className="mb-3" style={labelStyle}>
              {label}
            </GothamMedium>
          )}
          {!!bannerMessage && (
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <Banner msg={bannerMessage} />
            </div>
          )}
          <label
            onDragEnter={(e) => {
              stopsPropagation(e);
              drag();
            }}
            onDragLeave={(e) => {
              stopsPropagation(e);
              notDrag();
            }}
            onDragOver={(e) => {
              stopsPropagation(e);
            }}
            onDrop={(e: React.DragEvent<HTMLLabelElement>) => {
              stopsPropagation(e);
              if (!e) return;
              e.persist();
              setsValue(e);
            }}
            style={{
              flexDirection: "column",
              minHeight: 144,
              border: "2px dashed #6C6C71",
              borderRadius: 8,
              backgroundColor: isDragging ? jackColors.greyF1 : "",
              position: "relative",
              marginBottom: 0,
            }}
            className="d-flex align-items-center justify-content-center hover"
          >
            {content()}
            <input
              type="file"
              style={{ display: "none" }}
              multiple={multiple}
              ref={inputRef}
              onChange={(e) => {
                if (!e) return;
                setsValue(e);
              }}
            />
          </label>
        </div>
        <GothamRegular
          className="font10"
          style={{
            color: jackColors.grey90,
            position: "absolute",
            top: height,
          }}
        >
          {helperText}
        </GothamRegular>
        {middleComponent}
        <Files
          files={files}
          multiple={multiple}
          inputRef={inputRef}
          name={name}
          setValue={handleSetValue}
          containerMaxHeight={containerMaxHeight}
          customError={customError}
          enablePreview={enablePreview}
          handleShow={handleShow}
        />
        {bottomComponent}
      </div>
    );
  }
);

FileInputJack.displayName = "FileInputJack";

export default FileInputJack;
