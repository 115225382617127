import React, { useEffect, useState } from "react";
import { fetch } from "../../../tools/api";
import { rowDuplicateFilter } from "../../draftComponents/tools/helpers";

export const useFetchBankAccount = () => {
  const PER_PAGE = 10;
  const [isMaxedOut, setIsMaxedOut] = useState(false);
  const [url, setUrl] = useState(
    `/user_bank_accounts?page=1&per_page=${PER_PAGE}`
  );
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const { loading, refetch } = fetch({
    url: url,
    afterSuccess: (res) => {
      if (res.data.length < PER_PAGE) setIsMaxedOut(true);
      setData((prev) => {
        let newArray;
        if (page == 1) {
          newArray = res.data;
        } else {
          newArray = [...prev, ...res.data];
        }
        const uniqueArray = rowDuplicateFilter(newArray);
        return uniqueArray;
      });
    },
  });

  const nextPage = () => {
    if (isMaxedOut) return;
    if (loading) return;
    setPage((p) => p + 1);
  };

  useEffect(() => {
    if (page == 1) return;
    setUrl(`/user_bank_accounts?page=${page}&per_page=${PER_PAGE}`);
  }, [page]);

  useEffect(() => {
    if (page == 1) return;
    refetch();
  }, [url]);

  return { data, loading, refetch, nextPage };
};

export const useFetchMainBankAccount = () => {
  const [data, setData] = useState([]);
  const url = "/partner_bank_accounts?q[main_acc_eq]=true";

  const { loading, refetch } = fetch({
    url: url,
    afterSuccess: (res) => {
      setData(res.data);
    },
  });

  return { data, loading, refetch };
};
