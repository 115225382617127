import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "@tools";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { CustomButton } from "../../components/Buttons";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { CustomTextField, SelectionForm } from "../../components/inputs";
import { SelectionJack } from "../../components/inputs/selection";
import { SimpleTextAreaJack } from "../../components/inputs/simpleTextArea";
import { SmallScreenWrapper } from "../../components/ScreenWrappers";
import { GothamRegular } from "../../components/Text";
import {
  applyRegexWhiteSpaces,
  dateNow,
  getMonthEN,
  schemaGenerator,
  submitToLabelValue,
} from "../../components/tools";
import { useHeader } from "../../contexts/Layout/parent";
import { WrapperJackCenter } from "../crossBorder/components";
import { ProgressBarComponent } from "../crossBorderComponents";
import { ValidationValueContainWhiteSpace } from "./logics";
import { ProgressBarJack } from "../../components/Stepper";
import { stepArrayFunc } from "./loop/validation/parent";
import { useModalHook } from "../../components/Modals";
import { ModalConfirmationCloseLocal } from "../localTransferEnhancement/create/modal";
import CategorySelection from "../categoryComponents/general/components/CategorySelection";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../categoryComponents/general/hooks";
import { Trans, useTranslation } from "react-i18next";

export const translateArray = ({ t, array, key }) => {
  return array.map((item) => {
    return { ...item, [key]: t(item[key]) };
  });
};

export const CreatePayrollFirstForm = ({ onSubmit: onSubmitProps }) => {
  const [category, setCategory] = useState(null);

  const { push } = useRouter();
  const { setHeader } = useHeader();
  const { isOpen: isOpenCloseModal, toggle: toggleCloseModal } = useModalHook();
  const { t } = useTranslation("payroll/create");

  useEffect(() => {
    setHeader({
      type: "close",
      middleComponent: (
        <ProgressBarJack
          arrayStep={translateArray({
            t,
            array: stepArrayFunc(0),
            key: "step",
          })}
        />
      ),
      onCloseProps: () => toggleCloseModal(),
    });
  }, []);

  const monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const months = monthArr.map((number) => ({
    label: t(getMonthEN(number)),
    value: number,
  }));

  const defaultValuesRaw = dateNow();
  const { month, year } = defaultValuesRaw;

  const defaultValues = {
    month: {
      label: t(getMonthEN(month)),
      value: month,
    },
    year: {
      label: year,
      value: year,
    },
  };

  const yearOptions = () => {
    return [
      { value: year, label: year },
      { value: year + 1, label: year + 1 },
      { value: year + 2, label: year + 2 },
    ];
  };

  const resolver = schemaGenerator({
    stringArr: ["payroll_name"],
  });

  const useFormObj = useForm({
    defaultValues,
    resolver,
  });

  const { watch, setValue, handleSubmit } = useFormObj;

  const yearLabel = watch("year")?.label;
  const monthLabel = watch("month")?.label;

  const [isTyped, setIsTyped] = useState(false);

  useEffect(() => {
    if (isTyped) return;
    setValue("payroll_name", `Payroll ${monthLabel} ${yearLabel}`);
  }, [yearLabel, monthLabel]);

  const onSubmit = (rawValues) => {
    const values = submitToLabelValue(rawValues);
    const payload = { ...values };
    if (category) payload.category = category;
    onSubmitProps({ type: "first", values: payload });
  };

  const { hasCategoryList } = useAvailableCategories();

  const { isRequiredCategory } = useCategoryFlag();
  const isMissingRequiredCategory = Boolean(
    isRequiredCategory && !category && hasCategoryList
  );

  const watchValue = watch("payroll_name");
  const { valueValidation, isDisabled } =
    ValidationValueContainWhiteSpace(watchValue);
  const isDisabledSubmit = isDisabled || isMissingRequiredCategory;
  const placeholder = t("dynamic.dynamic_text_1", {
    month: monthLabel,
    year: yearLabel,
  });

  useEffect(() => {
    setValue("payroll_name", valueValidation);
  }, [watchValue]);

  return (
    <>
      <WrapperJackCenter title={t("Create New Payroll")}>
        <GothamRegular className="font12">{t("Select Period")}</GothamRegular>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex w-100 justify-content-between">
            <div style={{ width: "48%" }}>
              <SelectionJack
                name="month"
                noLabel
                options={months}
                useFormObj={useFormObj}
              />
              {/* <SelectionForm
              name="month"
              label="Month"
              options={months}
              useFormObj={useFormObj}
            /> */}
            </div>
            <div style={{ width: "48%" }}>
              <SelectionJack
                name="year"
                noLabel
                options={yearOptions()}
                useFormObj={useFormObj}
              />
            </div>
          </div>
          <SimpleTextAreaJack
            useFormObj={useFormObj}
            label={t("Payroll Name")}
            name="payroll_name"
            placeholder={placeholder}
            onChange={() => setIsTyped(true)}
            maxLength={50}
            style={{ height: 60 }}
          />

          <CategorySelection
            selectedCategory={category ?? {}}
            onSelect={(category) => setCategory(category)}
          />

          <div className="d-flex justify-content-end">
            <ButtonJack
              buttonType="submit"
              disabled={isDisabledSubmit}
              rightIcon={
                <JackIcons
                  name="arrow-forward"
                  style={{ width: 20, height: 20 }}
                  fill={
                    isDisabledSubmit
                      ? jackColors.neutral600
                      : jackColors.neutral900
                  }
                />
              }
            >
              {t("Continue")}
            </ButtonJack>
          </div>
        </form>
      </WrapperJackCenter>
      <ModalConfirmationCloseLocal
        toggle={toggleCloseModal}
        isOpen={isOpenCloseModal}
        product="payroll"
      />
    </>
  );
};
