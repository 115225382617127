import { jackColors } from "../../../../../../assets/colors";
import { GothamMedium } from "../../../../../../components/Text";

const ListLabel = ({ type = "recent" }) => {
  switch (type) {
    case "recent":
      return (
        <GothamMedium
          className="font12"
          style={{ color: jackColors.neutral700, marginBottom: "12px" }}
        >
          Recently used
        </GothamMedium>
      );

    case "other":
      return (
        <GothamMedium
          className="font12"
          style={{
            color: jackColors.neutral700,
            marginBottom: "12px",
            marginTop: "12px",
          }}
        >
          Other categories
        </GothamMedium>
      );

    default:
      return (
        <GothamMedium
          className="font12"
          style={{ color: jackColors.neutral700, marginBottom: "12px" }}
        >
          Recently used
        </GothamMedium>
      );
  }
};

export default ListLabel;
