import { forwardRef } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import CategoryIcon from "../CategoryIcon";
import SearchDropdown from "../SearchDropdown";
import styles from "./CategoryHeaderSelectionDropdown.module.css";
import { capitalize } from "lodash";

const categoryIcons = [
  "category",
  "wallet",
  "bank",
  "savings",
  "briefcase",
  "money",
  "invoice",
  "coin",
  "lock",
  "trendingup",
  "car",
  "building",
  "client",
  "build",
  "book",
  "meals",
  "coffee",
  "cart",
  "medical",
  "education",
  "games",
  "palette",
  "bulb",
  "plane",
  "giftcard",
  "card",
  "withdraw",
  "clock",
  "alert",
  "inventory",
  "monitor",
  "settings",
  "budget",
];

const CategoryHeaderSelectionDropdown = forwardRef(
  (
    {
      isOpen = false,
      selectedHeader = "",
      headers = [],
      style = {},
      onClose = () => {},
      onSelect = () => {},
    },
    ref
  ) => {
    return (
      <SearchDropdown
        woSearch
        ref={ref}
        isOpen={isOpen}
        roleUpAnimationCN={styles["closed"]}
        roleDownAnimationCN={styles["opened"]}
        style={style}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles["header"]} onClick={onClose}>
          <GothamMedium
            className="font12"
            style={{ color: jackColors.neutral900 }}
          >
            {selectedHeader
              .split("_")
              .map((word) => capitalize(word))
              .join(" ") || "-"}
          </GothamMedium>
          <JackIcons
            name="chevron-down"
            fill={jackColors.neutral900}
            style={{ cursor: "pointer", scale: "0.8" }}
          />
        </div>

        <div className={styles["horizontal-line"]} />

        <GothamMedium
          className="font12"
          style={{ color: jackColors.neutral900 }}
        >
          Select Field
        </GothamMedium>

        <div className={styles["headers-container"]}>
          {headers.map((header) => {
            return (
              <div
                key={header}
                className={styles["header-container"]}
                onClick={() => onSelect(header)}
              >
                <GothamRegular
                  woFontColor
                  style={{ color: jackColors.neutral800 }}
                >
                  {header
                    .split("_")
                    .map((word) => capitalize(word))
                    .join(" ") || "-"}
                </GothamRegular>
              </div>
            );
          })}
        </div>
      </SearchDropdown>
    );
    return <div className={styles["category-icon-selection-dropdown"]}></div>;
  }
);

export default CategoryHeaderSelectionDropdown;
