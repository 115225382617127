import { useGetAuth } from "../../../contexts/AuthContext";

const useJackTransferStoredData = () => {
  const { user } = useGetAuth();

  const storageName = `jack_transfer_data_${user?.id}`;

  const getJackTransferData = () => {
    return JSON.parse(sessionStorage.getItem(storageName) ?? "{}");
  };

  const storeJackTransferData = (data) => {
    const currentData = getJackTransferData();
    const updatedData = { ...currentData, ...data };
    sessionStorage.setItem(storageName, JSON.stringify(updatedData));
  };

  const removeJackTransferData = () => {
    sessionStorage.removeItem(storageName);
  };

  return { getJackTransferData, storeJackTransferData, removeJackTransferData };
};

export default useJackTransferStoredData;
