import { colors } from "../../assets/colors";
import { digitDateWithDigitHourFormatter } from "../../components/tools";

export const WaitingForPaymentFormatter = (item) => {
  const { status_history, created_at } = item;

  const array = [
    {
      date: digitDateWithDigitHourFormatter(created_at),
      text: "You created a transaction",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[0]?.created_at
      ),
      text: "Complete your payment",
      color: colors.orangeFF,
      isActive: true,
    },
    {
      text: "Transferring money to recipients",
    },
    {
      text: "Completed",
    },
  ];

  return array;
};
