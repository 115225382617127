import {
  BILL,
  DATA_ERROR,
  LOCAL,
  MIXED_ERROR,
  PAYROLL,
  REIMBURSEMENT,
  SYSTEM_ERROR,
} from "../constants";

const localWarningTypeDecider = (trx) => {
  const { retry_status } = trx ?? {};

  const isRetriable = retry_status === "retriable";
  const isResubmitable =
    retry_status === "resubmitable" || retry_status === "retry_resubmit";
  const isMixedResubmitable = retry_status === "retry_resubmit";

  if (isMixedResubmitable) return MIXED_ERROR;
  if (isResubmitable) return DATA_ERROR;
  if (isRetriable) return SYSTEM_ERROR;
  return "";
};

const payrollWarningTypeDecider = (trx) => {
  return localWarningTypeDecider(trx);
};

const reimbursementWarningTypeDecider = (trx) => {
  const isRetriable = trx?.local_transaction?.is_retriable;
  if (isRetriable) return SYSTEM_ERROR;
  return "";
};

const billWarningTypeDecider = (trx) => {
  return reimbursementWarningTypeDecider(trx);
};

const retryResubmitWarningTypeDecider = ({ module = "", trx = {} }) => {
  switch (module) {
    case LOCAL:
      return localWarningTypeDecider(trx);
    case PAYROLL:
      return payrollWarningTypeDecider(trx);
    case REIMBURSEMENT:
      return reimbursementWarningTypeDecider(trx);
    case BILL:
      return billWarningTypeDecider(trx);
    default:
      return "";
  }
};

export default retryResubmitWarningTypeDecider;
