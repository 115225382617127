import { TextareaAutosize } from "@material-ui/core";
import { useEffect, useState } from "react";
import { colors, jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { GothamLight, GothamRegular } from "../Text";
import { useUploadReceiptScreenType } from "modals/smartUploadReceiptModal/logics";
import { applyRegexNotes } from "@tools";

const errorStyle = { color: colors.redE7, fontSize: "10px" };

const helperStyle = {
  color: colors.neutral700,
  fontSize: "12px",
};

export const SimpleTextAreaJack = ({
  name = "",
  label = "",
  style,
  placeholder = "Placeholder",
  useFormObj: { setValue, register, watch, errors, setError, clearErrors },
  className = "",
  defaultValue = "",
  disabled,
  maxLength = 0,
  helperText = "",
  containerStyle,
  isRequired,
  fixedHelperText,
  customError,
  showOptionalIndicator = false,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const { isInvoice, isLocalTransfer } = useUploadReceiptScreenType();
  const isSpecialInput = isInvoice || isLocalTransfer;
  const value = watch(name) ?? "";
  const isError = customError || errors?.[name];

  useEffect(() => {
    if (value) clearErrors(name);
  }, [value]);

  const errorMessage = errors?.[name]?.message;
  useEffect(() => register(name), []);

  useEffect(() => {
    if (!defaultValue) return;
    setValue(name, defaultValue);
  }, [defaultValue]);

  const borderColorDecider = () => {
    if (isError) return jackColors.redE7;
    if (isFocus) return jackColors.black34;
    return colors.greye6;
  };

  const textFieldClassNameDecider = () => {
    if (isFocus || isError) return "";
    return "textfield";
  };
  const onChange = (val) => {
    const text = val?.target?.value ?? "";
    const valueDecider = () => {
      if (isSpecialInput) return applyRegexNotes(text);
      return text;
    };

    if (maxLength) {
      const canType = maxLength >= text.length;
      const newValue = valueDecider();
      if (canType) return setValue(name, newValue);
      return;
    }
    setValue(name, text);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && isSpecialInput) {
      event.preventDefault(); // Prevent Enter key from adding a new line
    }
  };

  return (
    <div style={{ position: "relative", marginBottom: 32, ...containerStyle }}>
      {maxLength ? (
        <div style={{ position: "absolute", right: 16, bottom: 15 }}>
          <GothamRegular
            className="font10"
            style={{
              color: isFocus ? jackColors.neutral900 : jackColors.greyBB,
            }}
          >
            {value.length} / {maxLength}
          </GothamRegular>
        </div>
      ) : null}
      <GothamRegular
        className="font12"
        style={{
          color: isError ? jackColors.redE7 : jackColors.black34,
          marginBottom: 8,
          cursor: "grab",
        }}
      >
        {label}
        {isRequired && <span style={{ color: jackColors.redE7 }}>*</span>}
        {showOptionalIndicator && (
          <span
            style={{
              color: jackColors.grey90,
              fontSize: "10px",
              lineHeight: "12px",
            }}
          >
            {" "}
            (Optional)
          </span>
        )}
      </GothamRegular>
      <JackIcons
        name="textarea_Resize"
        fill="#BBBBC0"
        style={{
          position: "absolute",
          bottom: 10,
          right: 5,
          width: 9,
          height: "auto",
        }}
      />
      <TextareaAutosize
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        disabled={disabled}
        defaultValue={defaultValue}
        minRows={3}
        name={name}
        aria-label="minimum height"
        className={textFieldClassNameDecider()}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        value={value}
        style={{
          width: "100%",
          color: jackColors.neutral900,
          border: `1px solid ${borderColorDecider()}`,
          transition: "all 0.3s ease",
          borderRadius: 4,
          padding: "10px 12px 20px 12px",
          outline: "none",
          fontSize: 14,
          lineHeight: "20px",
          fontFamily: "GothamBook",
          ...style,
        }}
      />

      <div style={{ position: "absolute", bottom: -14 }}>
        {isError ? (
          <GothamRegular style={errorStyle}>{errorMessage}</GothamRegular>
        ) : fixedHelperText ? (
          <GothamLight style={{ ...helperStyle, fontSize: "10px" }}>
            {fixedHelperText}
          </GothamLight>
        ) : helperText && isFocus ? (
          <GothamLight style={helperStyle}>{helperText}</GothamLight>
        ) : null}
      </div>
    </div>
  );
};
