import { LOCAL, PAYROLL, BILL, REIMBURSEMENT } from "../constants";

const retryUrlDecider = ({ trx }) => {
  switch (trx?.type) {
    case LOCAL:
      return `/local_transaction_batches/${trx?.id}/retry_transaction`;
    case PAYROLL:
      return `/local_transaction_batches/${trx?.batch_id}/retry_transaction`;
    case BILL:
      return `/invoice_transactions/${trx?.id}/retry_transaction`;
    case REIMBURSEMENT:
      return `/reimbursements/${trx?.id}/retry_transaction`;

    default:
      return "";
  }
};
const remindUrlDecider = ({ trx, isRetry }) => {
  switch (trx?.type) {
    case LOCAL:
      if (isRetry) {
        return `/local_transaction_batches/${trx?.id}/reminder_mailer?type=retry`;
      }
      return `/local_transaction_batches/${trx?.id}/reminder_mailer`;
    case PAYROLL:
      if (isRetry) {
        return `/local_transaction_batches/${trx?.batch_id}/reminder_mailer?type=retry`;
      }
      return `/local_transaction_batches/${trx?.batch_id}/reminder_mailer`;
    case BILL:
      if (isRetry) {
        return `/invoice_transactions/${trx?.id}/reminder_mailer?type=retry`;
      }
      return `/invoice_transactions/${trx?.id}/reminder_mailer`;
    case REIMBURSEMENT:
      if (isRetry) {
        return `/reimbursements/${trx?.id}/reminder_mailer?type=retry`;
      }
      return `/reimbursements/${trx?.id}/reminder_mailer`;
    default:
      return "";
  }
};
const retryResubmitButtonUrlDecider = ({ trx, isRetry }) => {
  return {
    retryUrlDecider: () => retryUrlDecider({ trx }),
    remindUrlDecider: () => remindUrlDecider({ trx, isRetry }),
  };
};

export default retryResubmitButtonUrlDecider;
