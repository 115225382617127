
import { colors } from "../../assets/colors";
import { GothamRegular, SpanColor } from "../../components/Text";
import { digitDateWithDigitHourFormatter } from "../../components/tools";

export const failedFormatter = (item) => {
  const { status_history, created_at } = item;

  const array = [
    {
      date: digitDateWithDigitHourFormatter(created_at),
      text: "You created a transaction",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[0]?.created_at
      ),
      text: "Your payment has been received",
      isActive: true,
    },
    {
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[1]?.created_at
      ),
      text: "We sent out your money to the recipient",
      isActive: true,
    },
    {
      titleRed: true,
      date: digitDateWithDigitHourFormatter(
        (status_history || [])[2]?.created_at
      ),
      text: "The recipient’s bank has declined the transaction. Please ask your account manager for more info",
      isActive: true,
      isRedButton: true,
      customComponents: (
        <>
          <GothamRegular>
            The recipient’s bank has{" "}
            <SpanColor color={colors.orangeFF}>declined{" "}</SpanColor>
            the transaction. Please ask your account manager for more info
          </GothamRegular>
        </>
      ),
    },
  ];

  return array;
};
