import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import FullScreenViewer from "../../components/FullScreenViewer";
import { useModalHook } from "../../components/Modals";

const FilePreviewContext = createContext();

export const FilePreviewProvider = ({ children }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [woHeader, setWoHeader] = useState(true);
  const [fileName, setFileName] = useState("");
  const [onClose, setOnClose] = useState(() => {});

  const { isOpen, toggle, open } = useModalHook();

  useEffect(() => {
    if (fileUrl || file) open();
  }, [fileUrl]);

  const show = ({ fileUrl, woHeader, file, fileName }) => {
    setFileUrl(fileUrl);
    setWoHeader(woHeader);
    file && setFile(file);
    fileName && setFileName(fileName);
  };

  const close = () => {
    setFileUrl(null);
    setFile(null);
    toggle();
    onClose && onClose();
  };

  return (
    <FilePreviewContext.Provider value={{ show, setOnClose }}>
      <FullScreenViewer
        isOpen={isOpen}
        toggle={close}
        url={fileUrl}
        file={file}
        woHeader={woHeader}
        fileName={fileName}
      />
      {children}
    </FilePreviewContext.Provider>
  );
};

export const useFileViewer = ({ onClose } = {}) => {
  const { show, setOnClose } = useContext(FilePreviewContext);
  const callbackOnClose = useCallback(onClose, []);

  useEffect(() => {
    setOnClose(() => callbackOnClose);
  }, [callbackOnClose]);

  return { show };
};
