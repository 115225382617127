import { useRouter } from "next/router";
import { jackColors } from "../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { eventsTracker } from "../../../../universalFunctions/events";
import { useSubscriptionUsage } from "../../logics/general-hooks";
import styles from "../../styles.module.css";
import JackSpade from "./JackSpade";

const textDecider = (type = "") => {
  switch (type) {
    case "invoice":
      return "Upgrade your plan anytime for unlimited invoice limits!";

    case "reimbursement":
      return "Upgrade your plan anytime for unlimited reimbursement limits!";

    case "payroll":
    case "local-transfer":
    case "international-transfer":
    case "international-transfer":
    case "invoice-v1":
    case "reimbursement-v1":
      return "Upgrade your plan for more competitive transaction fees!";

    default:
      return "Upgrade your plan anytime for unlimited invoice limits!";
  }
};

const SuccessAdvertisement = ({ type = "invoice" }) => {
  const { push } = useRouter();

  const {
    invoice_qty_left,
    reimbursement_qty_left,
    isSeedPlan,
    loadingSubscriptionUsage,
  } = useSubscriptionUsage();

  const isPayroll = type === "payroll";
  const isInvoice = type === "invoice" || type === "";
  const isReimbursement = type === "reimbursement";
  const isLocalTransfer = type === "local-transfer";
  const isInternationalTransfer = type === "international-transfer";

  const isPayrollSeed = isPayroll && isSeedPlan;
  const isLocalTransferSeed = isLocalTransfer && isSeedPlan;
  const isInternationalTransferSeed = isInternationalTransfer && isSeedPlan;
  const isSeedV2Invoice = isSeedPlan && isInvoice && invoice_qty_left != null;
  const isSeedV2Reimbursement =
    isSeedPlan && isReimbursement && reimbursement_qty_left != null;
  const isSeedV1Invoice = isSeedPlan && isInvoice && invoice_qty_left == null;
  const isSeedV1Reimbursement =
    isSeedPlan && isReimbursement && reimbursement_qty_left == null;

  const showAds =
    !loadingSubscriptionUsage &&
    (isSeedV2Invoice ||
      isSeedV2Reimbursement ||
      isPayrollSeed ||
      isLocalTransferSeed ||
      isInternationalTransferSeed ||
      isSeedV1Invoice ||
      isSeedV1Reimbursement);

  const handleClick = () => {
    eventsTracker("to_explore_plan_from_success_page");
    push("/plans/showcase");
  };

  const text = textDecider(
    isSeedV1Invoice || isSeedV1Reimbursement ? `${type}-v1` : type
  );

  if (!showAds) return null;

  return (
    <div
      className={styles["success-advertisement"]}
      style={{ marginTop: isInternationalTransfer && "32px" }}
    >
      <img
        src="/images/abstract-left.svg"
        style={{ position: "absolute", left: 0 }}
      />
      <JackSpade />
      <div>
        <GothamRegular
          woFontColor
          className="font12"
          style={{ color: "white" }}
        >
          {text}
        </GothamRegular>
        <GothamMedium
          className="font12"
          style={{ color: jackColors.greenB9, cursor: "pointer" }}
          onClick={handleClick}
        >
          Explore Plan
        </GothamMedium>
      </div>
      <img
        src="/images/abstract-right.svg"
        style={{ position: "absolute", right: 0 }}
      />
    </div>
  );
};

export default SuccessAdvertisement;
