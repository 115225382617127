import { Chip, FormControl, TextField } from "@material-ui/core";
import { titleCase } from "change-case";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { colors, jackColors } from "../../../../../../assets/colors";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import { AdvancedHoverInfo } from "../../../../../../components/HoverInfo";
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SpanMedium } from "modals/smartActivityModal/crossBorderSingleModal/transferServiceModal/components";
import { useLanguage } from "public/locales/translationFunctions";
import { Trans, useTranslation } from "react-i18next";
import { CustomTextFieldError } from "../../../../../../components/inputs";
import { GothamMedium, GothamRegular } from "../../../../../../components/Text";
import {
  removeDuplicates,
  stringCutter,
} from "../../../../../../components/tools";

export const isEmailChecker = (email) => {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return regexEmail.test(email);
};

export const InputChip = ({
  name = "",
  label: labelRaw = "",
  isSingle = false,
  type = "text",
  defaultValues: defaultValuesRaw = [],
  defaultHelperText = "You can notify up to 5 email addresses",
  listener = (val) => {},
  info = "",
  limit,
  isReset,
  disabled,
  showError = true,
  placeholder: placeholderProps = "",
  noLabel = false,
  isMinimalistInput = false,
  variant = "",
  nameRightIcon = "",
  nameLeftIcon = "",
  chipStyle = {},
  minHeight = "",
  handleConfirm = () => {},
  close = () => {},
  customStyle = {},
  customChipStyle = {},
  showHelper = false,
  chipProps = {},
  customErrorStyle = {},
  isFromTable = false,
  ...props
}) => {
  const textFieldRef = useRef(null);
  const defaultValues = defaultValuesRaw?.filter((item) => item);
  const { isBahasa } = useLanguage();
  const { t } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const label = labelRaw || t(titleCase(name));
  const [values, setValues] = useState(defaultValues);
  const [tempValue, setTempValue] = useState("");
  const [error, setError] = useState("");
  const [helperText, setHelperText] = useState(defaultHelperText);
  const [showHelperText, setShowHelperText] = useState(false);
  const invalidEmailText = isBahasa
    ? "Masukkan alamat email yang valid"
    : "Please enter a valid email address";

  useEffect(() => {
    if (!isReset) return;
    setValues(defaultValues);
    setTempValue("");
    setError("");
  }, [isReset]);

  useEffect(() => {
    if (!tempValue) return;
    if (isEmailChecker(tempValue)) return setError("");
    setError(invalidEmailText);
  }, [tempValue, isBahasa]);

  let obj = {};
  obj[name] = { values, tempValue };

  useEffect(() => {
    if (!tempValue == "" && !isEmailChecker(tempValue))
      return listener({ ...obj, error: true });
    listener(obj);
  }, [values.length, tempValue]);

  useEffect(() => {
    if (isEmailChecker(tempValue)) {
      if (showHelper) {
        setShowHelperText(true);
      }
      return setError("");
    }
    if (tempValue && !isEmailChecker(tempValue)) {
      setShowHelperText(false);
      setError(invalidEmailText);
      return;
    }
    setShowHelperText(false);
    setError("");
  }, [tempValue]);

  const isEmail = type === "email";

  const handleDelete = (index) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  const valuesLen = values.length;
  const hasReachedLimit = (isSingle && !isEmpty(values)) || valuesLen === limit;

  useEffect(() => {
    if (valuesLen == 0) return;
    if (hasReachedLimit) {
      textFieldRef.current.blur();
    }
  }, [valuesLen]);
  const handleKeyDown = (e) => {
    if (!e) return;
    const { key } = e || {};
    const isEnter = key === "Enter";
    const isBackspace = key === "Backspace";
    const isSpace = key === " "; // request ficho
    const isDelete = !tempValue && isBackspace;

    if (isDelete) return handleDelete(values.length - 1);

    if (!(isSpace || isEnter)) return;
    if (!tempValue) return;

    const isNotEmail = !isEmailChecker(tempValue);

    if (isEmail && isNotEmail) {
      listener({ ...obj, error: true });
      return setError(invalidEmailText);
    }

    setTempValue("");
    setError("");
    setValues(removeDuplicates([...values, tempValue]));
  };

  const isDynamicBorderBottomColor = {
    borderBottom: !!error
      ? `1px solid var(--Error-Red, #E73126)`
      : `1px solid var(--Neutral-900, #343434)`,
  };

  const helperTextFunc = () => {
    if (name == "recipient_email" || showHelperText) {
      if (!isEmpty(values)) return <></>;
      return (
        <GothamRegular style={{ color: jackColors.grey6C }} className="font10">
          <Trans
            i18nKey={tLocalTransfer("dynamic.style_text_12")}
            components={{ SpanMedium: <SpanMedium /> }}
          />
        </GothamRegular>
      );
    }
    if (name == "additional_email" && !error)
      return (
        <GothamRegular className="font10" style={{ color: jackColors.grey90 }}>
          {helperText}
        </GothamRegular>
      );
  };

  return (
    <div style={{ position: "relative" }}>
      {Boolean(!noLabel) && (
        <div className="d-flex align-items-center mb-2" style={{ height: 16 }}>
          <GothamMedium className="font12">{label}</GothamMedium>
          {!!info && (
            <AdvancedHoverInfo
              style={{ marginBottom: 4 }}
              className="ml-1"
              text={info}
            >
              <JackIcons
                name="question-mark-circle-outline"
                fill={jackColors.black34}
                style={{ width: 12, height: 12 }}
              />
            </AdvancedHoverInfo>
          )}
        </div>
      )}
      <div
        style={{
          position: "relative",
          ...(!isMinimalistInput && { marginBottom: "12px" }),
          ...customStyle,
        }}
        onClick={() => textFieldRef?.current && textFieldRef?.current?.focus()}
      >
        <FormControl
          style={{
            display: "flex",
            flexDirection: isSingle ? "row" : "column",
            // flexDirection: "row",
            gap: "8px",
            padding: 8,
            borderRadius: 4,
            flex: "auto",
            width: "100%",
            minHeight: 40,
            backgroundColor:
              disabled && !isMinimalistInput ? colors.greye6 : "",
            ...(!isMinimalistInput && { border: `1px solid ${colors.greye6}` }),
            ...(isMinimalistInput && !isEmpty(values) && { gap: `0px` }),
            ...(minHeight && { minHeight: 0, height: minHeight }),
          }}
        >
          {!isEmpty(values) && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                ...(isMinimalistInput &&
                  !isEmpty(values) && {
                    borderBottom: `1px solid ${colors.greyBB}`,
                  }),
              }}
            >
              {values.map((value, index) => (
                <Chip
                  key={index}
                  label={stringCutter(value)}
                  size="small"
                  style={{
                    marginRight: 5,
                    width: "fit-content",
                    borderRadius: "4px",
                    backgroundColor: colors.greye6,
                    color: colors.grey6c,
                    fontSize: "12px",
                    ...chipStyle,
                  }}
                  onDelete={() => {
                    if (disabled && !isFromTable) return;
                    handleDelete(index);
                  }}
                  icon={
                    nameLeftIcon ? (
                      <div
                        style={{
                          borderRadius: "100px",
                          backgroundColor: jackColors.greyBB,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <JackIcons
                          name={nameLeftIcon}
                          fill={colors.whiteFC}
                          style={{
                            width: 12,
                            height: 12,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )
                  }
                  deleteIcon={
                    nameRightIcon ? (
                      <JackIcons
                        name={nameRightIcon}
                        fill={jackColors.greyBB}
                        style={{
                          width: 14,
                          height: 14,
                        }}
                      />
                    ) : (
                      ""
                    )
                  }
                  variant={variant ?? "default"}
                  {...chipProps}
                />
              ))}
            </div>
          )}
          <TextField
            inputRef={textFieldRef}
            disabled={disabled}
            name={name}
            value={tempValue}
            InputProps={{
              disableUnderline: isMinimalistInput ? false : true,
              style: {
                fontSize: 14,
                fontFamily: "GothamBook",
                lineHeight: "20px",
                paddingBottom: 0,
                caretColor: hasReachedLimit ? "transparent" : "unset",
              },
            }}
            onBlur={() => {
              if (isEmail && isEmailChecker(tempValue)) {
                setValues(removeDuplicates([...values, tempValue]));
                setTempValue("");
              }
            }}
            type={type}
            style={{ width: "100%" }}
            onChange={(e) => {
              if (isSingle && !isEmpty(values)) return;
              if (limit && limit <= values.length) return;
              setTempValue(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            placeholder={!hasReachedLimit && placeholderProps}
            {...props}
          />
          {Boolean(isMinimalistInput) && (
            <div className="d-flex ml-2" style={{ gap: 8 }}>
              <JackIcons
                name="checkmark-circle-2"
                fill={jackColors.greenB9}
                style={{ cursor: "pointer" }}
                onClick={handleConfirm}
              />
              <JackIcons
                name="close-circle"
                fill={jackColors.neutral900}
                style={{ cursor: "pointer" }}
                onClick={close}
              />
            </div>
          )}
        </FormControl>
        <div
          style={{
            marginLeft: !isMinimalistInput ? 0 : "8px",
            marginRight: "70px",
            justifyContent: !isMinimalistInput ? "flex-start" : "flex-end",
            display: "flex",
            marginTop: !isMinimalistInput ? 4 : 0,
          }}
        >
          {helperTextFunc()}
        </div>
        <CustomTextFieldError
          errorStyle={{
            // fontSize: isMinimalistInput ? 10 : 12,
            fontSize: 10,
            ...(isMinimalistInput && { marginLeft: 8, marginTop: -10 }),
            ...customErrorStyle,
          }}
          message={error}
          show={!!error && showError}
          pointerBottom
        />
      </div>
    </div>
  );
};
