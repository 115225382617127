import { useEffect, useState } from "react";
import CardTransactionDetailParent from "./parent";
import AddReceipt from "./AddReceipt";
import AddDescription from "./AddDescription";
import { fetch } from "../../../../../../tools/api";
import { isEmpty } from "lodash";
import { useGetAuth } from "../../../../../../contexts/AuthContext";
import { isCanManageCardDecider } from "../../../../tools";

const CardTransactionDetail = ({
  transactionDetail,
  setUpdatedTransaction,
  listener,
  onUploadDocument = () => {},
}) => {
  const [currentScreen, setCurrentScreen] = useState("transactionDetail");

  const handleBack = () => {
    setCurrentScreen("transactionDetail");
  };

  const {
    data: transactionDetailLatest,
    refetch: refetchTransactionDetail,
    loading: loadingTransactionDetail,
  } = fetch({
    url: `/card_transactions/${transactionDetail?.id}`,
    formatter: (data) => data.data,
    defaultLoading: true,
    woInit: true,
  });

  useEffect(() => {
    if (!isEmpty(transactionDetail)) {
      refetchTransactionDetail();
      setCurrentScreen("transactionDetail");
    }
  }, [transactionDetail]);

  useEffect(() => {
    if (isEmpty(transactionDetailLatest)) return;
    listener && listener(transactionDetailLatest);
  }, [transactionDetailLatest]);

  useEffect(() => {
    if (transactionDetailLatest) {
      setUpdatedTransaction && setUpdatedTransaction(transactionDetailLatest);
    }
  }, [transactionDetailLatest]);

  const { user_card } = transactionDetailLatest || {};
  const { user } = useGetAuth();
  const isCanManageCard = isCanManageCardDecider(user_card, user);

  const defaultProps = {
    handleBack,
    transactionDetail: transactionDetailLatest,
    refetchTransactionDetail,
    onUploadDocument,
    isCanManageCard,
  };

  switch (currentScreen) {
    case "addReceipt":
      return <AddReceipt {...defaultProps} />;
    case "addDescription":
      return <AddDescription {...defaultProps} />;
    case "transactionDetail":
    default:
      return (
        <CardTransactionDetailParent
          refetchTransactionDetail={refetchTransactionDetail}
          transactionDetail={transactionDetailLatest}
          setCurrentScreen={setCurrentScreen}
          isCanManageCard={isCanManageCard}
          onUploadDocument={onUploadDocument}
          loadingTransactionDetail={loadingTransactionDetail}
        />
      );
  }
};

export default CardTransactionDetail;
