import { useRouter } from "next/router";
import { useEffect } from "react";
import { useLocalDisbursementDetails } from "../../../modals/smartActivityModal/localDisbursementBatchModal/logics";
import { useMutation } from "../../../tools/api";
import { useEWalletSuccessFormatter } from "../../eWalletComponents/success/formatter";
import { DefaultSuccessPage } from "../../successWorkflowComponents/page";

export const LocalTransferSuccessScreen = () => {
  const { query, push } = useRouter();
  const { id } = query;
  const { refetch, statusFlow } = useLocalDisbursementDetails({
    id,
    isModal: false,
  });

  const { resultFormatter } = useEWalletSuccessFormatter();

  const { mutation, result: array } = useMutation({
    url: `/activity_search/`,
    method: "post",
    resultFormatter,
    defaultValue: [],
  });

  const resultArr = array.map((item) => ({ ...item, leftStatus: "pending" }));

  const filters = {
    category: "local_batch",
    originator_id: [id],
  };

  useEffect(() => {
    if (!id) return;
    refetch();
    mutation({ filters });
  }, [id]);

  return (
    <div style={{ height: "100vh", overflowY: "scroll", paddingBottom: 150 }}>
      <DefaultSuccessPage
        woStateFormatter
        array={resultArr}
        title="Send money request is in progress"
        buttonText="Finish"
        onClick={() =>
          push({
            pathname: "/history",
            query: {
              id,
              page_type_details: "batch_payment",
              page_type: "local_batch",
            },
          })
        }
        statusLine={statusFlow}
      />
    </div>
  );
};
