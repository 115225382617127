import InfoIcon from "./svgs/InfoIcon";
import styles from "./FeeBreakdown.module.css";
import { useState } from "react";
import { GothamMedium, GothamRegular } from "../../Text";
import { formatCurrencyNoDecimal } from "../../tools";
import CustomHoverInfo from "../../CustomHoverInfo/CustomHoverInfo";

const FeeBreakdown = ({
  fee = 0,
  freeTransaction = 0,
  totalTransaction = 0,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const uncoveredTransaction = totalTransaction - freeTransaction;

  return (
    <div className={styles["main-container"]}>
      <InfoIcon
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <CustomHoverInfo
        show={isHovered}
        rotateTrianglePoint="270deg"
        translateTrianglePoint="146.5px 72px"
      >
        <div className={styles["content-container"]}>
          <GothamMedium woClassName className={styles["info-title-text"]}>
            Fee breakdown
          </GothamMedium>
          <div className={styles["inner-content-container"]}>
            <div
              className={styles["column-1"]}
              style={{ width: String(freeTransaction) > 2 ? "8%" : "5%" }}
            >
              <GothamRegular
                woFontColor
                woClassName
                className={styles["thin-text"]}
              >
                {freeTransaction}
              </GothamRegular>
              <GothamRegular
                woFontColor
                woClassName
                className={styles["thin-text"]}
              >
                {uncoveredTransaction}
              </GothamRegular>
            </div>
            <div
              className={styles["column-2"]}
              style={{ width: String(freeTransaction) > 2 ? "32%" : "35%" }}
            >
              <GothamRegular
                woFontColor
                woClassName
                className={styles["thin-text"]}
              >
                transactions
              </GothamRegular>
              <GothamRegular
                woFontColor
                woClassName
                className={styles["thin-text"]}
              >
                transactions
              </GothamRegular>
            </div>
            <div className={styles["column-3"]}>
              <GothamMedium woClassName className={styles["thick-text"]}>
                Free
              </GothamMedium>
              <GothamMedium woClassName className={styles["thick-text"]}>
                IDR {formatCurrencyNoDecimal(uncoveredTransaction * fee)}
              </GothamMedium>
            </div>
          </div>
        </div>
      </CustomHoverInfo>
    </div>
  );
};

export default FeeBreakdown;
