import { Fragment } from "react";
import { Banner } from "../../../../../components/Banner";
import { Trans, useTranslation } from "react-i18next";

const MissingCategoryBanner = ({ count = 0 }) => {
  const { t } = useTranslation("category/index");
  if (!count || count <= 0) return null;

  const isPlural = count > 1;

  const trxText = isPlural ? "transactions" : "transaction";
  const beText = isPlural ? "are" : "is";

  return (
    <Banner
      type="warning"
      bannerStyle={{ marginBottom: "20px" }}
      msg={
        <Trans
          i18nKey={t("Dynamic.export_missing_category", {
            count: count,
            be: beText,
            trx: trxText,
          })}
          components={[<span style={{ fontFamily: "GothamMedium" }} />]}
        />
      }
    />
  );
};

export default MissingCategoryBanner;
