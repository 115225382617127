import styles from "./CategorySelectionDropdown.module.css";
import { Fragment, forwardRef, useEffect, useState } from "react";
import Category, { NewCategory } from "./Category";
import ListLabel from "./ListLabel";
import SearchDropdown from "../SearchDropdown";
import { useSearchedCategories } from "../../hooks";
import { useConstants } from "../../../../../contexts/ConstantsContext/parent";
import JackIcons from "assets/jackIcons/typescript/parent";
import { jackColors } from "assets/colors";

const CategorySelectionDropdown = forwardRef(
  (
    {
      isOpen = false,
      isSimple = false,
      showAllCategories = false,
      style = {},
      selectedCategory = {},
      onSelect = () => {},
      type = "",
    },
    ref
  ) => {
    const [search, setSearch] = useState("");

    const { categories } = useConstants();

    const { allCategories, recentCategories, otherCategories } =
      useSearchedCategories({
        data: categories.filter(
          ({ isDeleted, is_archived }) => !isDeleted && !is_archived
        ),
        search,
        showAllCategories,
      });

    const hasRecentCategories = recentCategories.length > 0;
    const hasOtherCategories = otherCategories.length > 0;

    const showRecentLabel = hasRecentCategories && !search;
    const showOtherLabel = showRecentLabel && hasOtherCategories;

    useEffect(() => {
      if (!isOpen) setSearch("");
    }, [isOpen]);

    return (
      <SearchDropdown
        ref={ref}
        value={search}
        style={style}
        isOpen={isOpen}
        woSearch={isSimple}
        onChange={(value) => setSearch(value)}
        iconRight={
          <JackIcons
            onClick={() => setSearch("")}
            name="CloseCircle"
            fill={jackColors.black34}
            style={{ height: 20, cursor: "pointer" }}
          />
        }
      >
        <div
          className={styles["category-list-container"]}
          style={{
            minHeight: isSimple && "100%",
            maxHeight: isSimple && "100%",
          }}
        >
          {isSimple ? (
            <div className={styles["category-list"]}>
              {allCategories.map((category) => (
                <Category
                  key={category.id}
                  isSimple={isSimple}
                  category={category}
                  isSelected={category.id === selectedCategory.id}
                  onSelect={onSelect}
                />
              ))}
            </div>
          ) : (
            <Fragment>
              {hasRecentCategories && (
                <Fragment>
                  {showRecentLabel && <ListLabel type="recent" />}
                  <div className={styles["category-list"]}>
                    {recentCategories.map((category) => (
                      <Category
                        key={category.id}
                        isSimple={isSimple}
                        category={category}
                        isSelected={category.id === selectedCategory.id}
                        onSelect={onSelect}
                      />
                    ))}
                  </div>
                </Fragment>
              )}

              {showOtherLabel && <ListLabel type="other" />}

              <div className={styles["category-list"]}>
                {otherCategories.map((category) => {
                  if ((type = "from_table_local")) {
                    return (
                      <NewCategory
                        key={category.id}
                        isSimple={isSimple}
                        category={category}
                        isSelected={category.id === selectedCategory.id}
                        onSelect={onSelect}
                        type={type}
                      />
                    );
                  } else {
                    return (
                      <Category
                        key={category.id}
                        isSimple={isSimple}
                        category={category}
                        isSelected={category.id === selectedCategory.id}
                        onSelect={onSelect}
                      />
                    );
                  }
                })}
              </div>
            </Fragment>
          )}
        </div>
      </SearchDropdown>
    );
  }
);

export default CategorySelectionDropdown;
