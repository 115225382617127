const InfoIcon = ({ onMouseEnter = () => {}, onMouseLeave = () => {} }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <path
        d="M7.33325 11.3332H8.66659V9.99984H7.33325V11.3332ZM7.33325 8.6665H8.66659V4.6665H7.33325V8.6665ZM7.99992 14.6665C4.31992 14.6665 1.33325 11.6798 1.33325 7.99984C1.33325 4.31984 4.31992 1.33317 7.99992 1.33317C11.6799 1.33317 14.6666 4.31984 14.6666 7.99984C14.6666 11.6798 11.6799 14.6665 7.99992 14.6665ZM7.99992 2.6665C5.05992 2.6665 2.66659 5.05984 2.66659 7.99984C2.66659 10.9398 5.05992 13.3332 7.99992 13.3332C10.9399 13.3332 13.3333 10.9398 13.3333 7.99984C13.3333 5.05984 10.9399 2.6665 7.99992 2.6665Z"
        fill="#FCB037"
      />
    </svg>
  );
};

export default InfoIcon;
