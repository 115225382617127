import { deepRemoveDuplicates, removeDuplicates } from "@tools";
import { ToasterHook } from "contexts/ToasterContext";
import { upperCase } from "lodash";
import { FormDataLocalTransfer } from "pageComponents/localTransferEnhancement/typescript/typeLocalTransfer";
import { useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

// Define types for the file object if not already defined
interface FileObject {
  id?: string;
  name: string;
  size: number;
  type: string;
}

interface MergedFiles {
  [key: string]: FileObject;
}

// Utility function to merge and remove duplicates from files
const mergedFiles = (obj: MergedFiles, array: FileObject[]): MergedFiles => {
  // For single OR empty value
  const isObj = !Array.isArray(array);
  if (isObj) return obj;

  // Convert array to object
  let objFromArray: MergedFiles = {};
  const keys = Object.keys(obj);
  const lastKey = keys[keys.length - 1];

  array.forEach((item, index) => {
    const key = index + 1 + lastKey;
    objFromArray[key] = item;
  });

  const mergedObjAndArray = { ...obj, ...objFromArray };
  const mergedKeys = Object.keys(mergedObjAndArray);

  // Remove duplicates based on 'name'
  const resultArray = deepRemoveDuplicates(
    mergedKeys.map((key) => mergedObjAndArray[key]),
    "name"
  );

  // Convert result array back to object
  let resultObj: MergedFiles = {};
  resultArray.forEach((file: FileObject, index: number) => {
    resultObj[index] = file;
  });

  return resultObj;
};

// Define the hook's parameter types
interface UseSetsValueFileInputProps {
  name: string;
  useFormObj: UseFormReturn<FormDataLocalTransfer>;
  maxFiles: number;
  multiple: boolean;
  maxSizeMB: number;
  fileTypes?: string[];
  errorWithToaster?: boolean;
  customError?: string;
}

// Define the errors state type
interface Errors {
  isTooMuchFiles?: boolean;
  isTooBig?: boolean;
  isTypeNotMatch?: boolean;
}

// The main custom hook
export const useSetsValueFileInput = ({
  name,
  useFormObj,
  maxFiles,
  multiple,
  maxSizeMB,
  fileTypes = [],
  errorWithToaster,
  customError,
}: UseSetsValueFileInputProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { setValue, watch } = useFormObj;
  const value = watch(name);
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const [errors, setErrors] = useState<Errors>({});
  const { errorSnackBar } = ToasterHook();

  const fileListToObject = (fileList: FileList | null): MergedFiles => {
    const fileObject: MergedFiles = {};
    if (fileList) {
      Array.from(fileList).forEach((file, index) => {
        fileObject[index.toString()] = file;
      });
    }
    return fileObject;
  };

  const setsValue = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLDivElement>
      | React.DragEvent<HTMLLabelElement>
  ) => {
    // Convert FileList to MergedFiles
    const filesFromEvent =
      (e as React.DragEvent<HTMLDivElement>)?.dataTransfer?.files ||
      (e as React.ChangeEvent<HTMLInputElement>)?.target?.files ||
      (e as React.DragEvent<HTMLLabelElement>)?.dataTransfer?.files ||
      null;

    const fileObjects = fileListToObject(filesFromEvent);

    const files = mergedFiles(fileObjects, value);
    if (!files) return;

    const keys = Object.keys(files);
    const isTooBig = keys.some(
      (key) => files[key].size > maxSizeMB * 1024 * 1024
    );
    const isTooMuchFiles = keys.length > maxFiles;
    const isTypeNotMatch = keys.some(
      (key) => !fileTypes.includes(files[key].type)
    );

    const array = keys.map((index) => files[index]);

    const hasError = isTooMuchFiles || isTooBig || isTypeNotMatch;

    if (hasError) {
      if (isTypeNotMatch) {
        errorSnackBar?.({
          msg:
            customError ??
            "Upload failed. Please provide a supported file type.",
          showClose: true,
        });
      }

      if (isTooBig) {
        errorSnackBar?.({
          msg: (
            <Trans
              i18nKey={tLocalTransfer("dynamic.dynamic_text_17", {
                dupNum: maxSizeMB,
              })}
            />
          ),
          showClose: true,
        });
      }

      if (isTooMuchFiles) {
        errorSnackBar?.({
          msg: `You’ve reached max ${maxFiles} files. Please remove any extra file(s) to continue.`,
          showClose: true,
        });
      }

      return setErrors({
        isTooMuchFiles,
        isTooBig,
        isTypeNotMatch,
      });
    }

    setErrors({});

    const valueDecider = () => {
      if (!multiple) return files[0];
      if (maxFiles) return array.slice(0, maxFiles);
      return array;
    };
    setValue(name, valueDecider());
  };

  const reset = () => {
    setValue(name, "");
    if (ref.current) ref.current.value = "";
  };

  return { setsValue, errors, reset, ref };
};

// Utility function to translate MIME type to a human-readable string
const fileTypesTranslator = (mime: string): string => {
  const text = (mime || "").split("/")[1];

  const isDocx =
    text === "vnd.openxmlformats-officedocument.wordprocessingml.document";
  const isXlsx =
    text === "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const isXls = text === "vnd.ms-excel";

  if (isXlsx) return "XLSX";
  if (isDocx) return "DOCX";
  if (isXls) return "XLS";
  return upperCase(text);
};

// Utility function to translate an array of MIME types to a string
export const fileTypesTranslatorArrayToString = (array: string[]): string => {
  const result = removeDuplicates(array)
    .map((string: string, index: number) => {
      const isLast = index === array.length - 1;
      const isUseAnd = array.length >= 3;
      const type = fileTypesTranslator(string);
      if (isLast && isUseAnd) return ` and ${type}`;
      return ` ${type}`;
    })
    .join(",");
  return result;
};
