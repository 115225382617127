import dynamic from "next/dynamic";
import { RightModal } from "../../../../components/Modals/RightModal/parent";
import {
  CardDetailContextProvider,
  useCardDetailContext,
} from "../../../../contexts/Cards/CardDetailContext";
import { useRouter } from "next/router";

const DetailBlock = dynamic(() => import("./screens/DetailBlock"));
const DetailIndex = dynamic(() => import("./screens/DetailIndex"));
const PhysicalAM = dynamic(() => import("./screens/PhysicalAM"));
const PhysicalChangePin = dynamic(() => import("./screens/PhysicalChangePin"));
const PhysicalEditInfo = dynamic(() => import("./screens/PhysicalEditInfo"));
const Approval = dynamic(() => import("./screens/PrepaidApproval/index"));
const PrepaidMoveBalanceMethod = dynamic(() =>
  import("./screens/PrepaidMoveBalanceMethod")
);
const PrepaidMoveToCard = dynamic(() => import("./screens/PrepaidMoveToCard"));
const PrepaidMoveToCompany = dynamic(() =>
  import("./screens/PrepaidMoveToCompany")
);
const PrepaidMoveToPrimary = dynamic(() =>
  import("./screens/PrepaidMoveToPrimary")
);
const PrepaidTopUpByCompany = dynamic(() =>
  import("./screens/PrepaidTopUpByCompany")
);
const PrepaidTopUpByPrimary = dynamic(() =>
  import("./screens/PrepaidTopUpByPrimary")
);
const PrepaidTopUpByVA = dynamic(() => import("./screens/PrepaidTopUpByVA"));
const PrepaidTopUpMethod = dynamic(() =>
  import("./screens/PrepaidTopUpMethod")
);
const VirtualAdjustLimit = dynamic(() =>
  import("./screens/VirtualAdjustLimit")
);
const VirtualEditInfo = dynamic(() => import("./screens/VirtualEditInfo"));

export const ModalContent = () => {
  const { currentScreen } = useCardDetailContext();

  const screenDecider = () => {
    switch (currentScreen) {
      case "temporaryBlock":
        return <DetailBlock type="temporary" />;
      case "permanentBlock":
        return <DetailBlock type="permanent" />;
      case "physicalChangePin":
        return <PhysicalChangePin />;
      case "physicalAdjustLimit":
        return <PhysicalAM type="adjustCardLimit" />;
      case "physicalReplaceCard":
        return <PhysicalAM type="replaceCard" />;
      case "physicalEditInfo":
        return <PhysicalEditInfo />;
      case "virtualEditInfo":
        return <VirtualEditInfo />;
      case "virtualAdjustLimit":
        return <VirtualAdjustLimit />;
      case "prepaidMoveBalanceMethod":
        return <PrepaidMoveBalanceMethod />;
      case "prepaidTopUpMethod":
        return <PrepaidTopUpMethod />;
      case "prepaidTopUpCompany":
        return <PrepaidTopUpByCompany />;
      case "prepaidTopUpPrimary":
        return <PrepaidTopUpByPrimary />;
      case "prepaidTopUpVA":
        return <PrepaidTopUpByVA />;
      case "prepaidMoveToCompany":
        return <PrepaidMoveToCompany />;
      case "prepaidMoveToPrimary":
        return <PrepaidMoveToPrimary />;
      case "prepaidMoveToCard":
        return <PrepaidMoveToCard />;
      case "Approval":
        return <Approval />;
      case "index":
      default:
        return <DetailIndex />;
    }
  };

  const screen = screenDecider();

  return screen;
};

const CardDetailModal = ({ isOpen }) => {
  const {
    rightModalArray = [],
    rightModalWidth,
    rightModalToggle,
    isOpenPin,
    isOpenReject,
    hideClose,
    loadingCardDetail,
    isHideModal,
  } = useCardDetailContext();
  const { query } = useRouter();
  const toggleModal = () => {
    delete query?.id;
    delete query?.originator_id;
    rightModalToggle();
  };
  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggleModal}
        array={rightModalArray}
        hideModal={isOpenPin || isOpenReject || isHideModal}
        width={rightModalWidth}
        isLoading={loadingCardDetail}
        woClose={hideClose}
      />
    </>
  );
};

const CardModal = ({
  isOpen,
  toggle,
  cardDetail,
  isArchiveScreen,
  setSelectedItem,
  setCards,
  toggleConfirmDelete,
  isTask,
  isReveal,
  isAlert,
  isNotRenavigate,
}) => {
  return (
    <CardDetailContextProvider
      setCards={setCards}
      cardDetail={cardDetail}
      toggle={toggle}
      isArchiveScreen={isArchiveScreen}
      toggleConfirmDelete={toggleConfirmDelete}
      isTask={isTask}
      setSelectedItem={setSelectedItem}
      isReveal={isReveal}
      isAlert={isAlert}
      isNotRenavigate={isNotRenavigate}
    >
      <CardDetailModal isOpen={isOpen} isReveal={isReveal} />
    </CardDetailContextProvider>
  );
};

export default CardModal;
