import { jackColors } from "assets/colors";
import JackIcons from "assets/jackIcons/typescript/parent";
import { ButtonJack } from "components/ButtonsJack/typescript/parent";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

import { Avatar } from "components/Avatar";
import { Banner } from "components/typescript/Banner";
import {
  CustomTextFieldJack,
  TextFieldJack,
} from "components/typescript/inputs/textfield/textfield";
import { GothamMedium, GothamRegular } from "components/typescript/Text";
import { BankSelectDropdown } from "pageComponents/bankAccountSettingComponents/bankCreatePage/typescript/mainContent";
import Divider from "pageComponents/onboardCardPageComponents/components/divider";
import {
  font12,
  font14,
} from "pageComponents/virtualAccountComponents/common/utils";
import { FormContentProps, RecipientCardProps } from "./index.types";
import { Fragment, useEffect } from "react";

export const FormContent = ({
  handleSubmit,
  useFormObj,
  errors,
  recipient,
  handleClearForm,
  isModal = false,
}: FormContentProps) => {
  const { t } = useTranslation("local-transfer/revamp");
  const { is_new_recipient } = recipient ?? {};

  const isRecipientSet = !!recipient;
  const isError = !!errors["bank_name"];

  return (
    <Fragment>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: !!errors?.bank_name ? 32 : 0,
        }}
      >
        {isError && (
          <Banner
            msg={t(
              "Please try again and make sure the number you entered is correct."
            )}
            type="error"
          />
        )}
        <BankSelectDropdown
          name="bank_name"
          label={t("Transfer Destination")}
          placeholder={t("Select bank/e-wallet/virtual account")}
          useFormObj={useFormObj}
          isDisabled={isRecipientSet}
          error={!!errors?.bank_name?.message}
          containerStyle={{
            marginBottom: 0,
          }}
        />
        <TextFieldJack
          required
          type={"number"}
          name="account_number"
          label={t("Destination Number")}
          useFormObj={useFormObj}
          disabled={isRecipientSet}
          placeholder={t("Enter account number/phone number/VA number")}
          style={{ marginBottom: 0 }}
        />
        {/* <CustomTextFieldJack
          name="account_number"
          label={t("Destination Number")}
          useFormObj={useFormObj}
          placeholder={t("Enter account number/phone number/VA number")}
          style={{ marginBottom: !!errors?.bank_name ? 0 : 32 }}
          disabled={isRecipientSet}
          required
        /> */}
        {isRecipientSet && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 32,
              marginTop: 32,
            }}
          >
            <Divider />
            {!is_new_recipient && (
              <Banner
                msg={t(
                  "This account number is already on your recipient list."
                )}
                type="information"
              />
            )}
            <RecipientCard
              onClickAction={handleClearForm}
              recipient={recipient}
              type={"edit"}
            />
          </div>
        )}
      </form>
    </Fragment>
  );
};

export const RecipientCard = ({
  type,
  recipient,
  onClickAction,
  customActionButton,
  ...isBiggerFont
}: RecipientCardProps) => {
  const { t } = useTranslation("local-transfer/revamp");
  const { name, registered_name, account_number, bank_name } = recipient ?? {};
  const recipientName = registered_name || name;
  const destination = `${bank_name?.toUpperCase()} - ${account_number}`;
  const isEdit = type === "edit";

  const buttonDecider = () => {
    if (!!customActionButton) {
      return customActionButton;
    }
    if (isEdit) {
      return (
        <ButtonJack
          type="outline"
          style={{ padding: 6, minWidth: "100%", height: 32 }}
          leftIcon={
            <JackIcons
              name="EditOutline"
              fill={jackColors.black34}
              style={{ height: 20 }}
            />
          }
          children={"Edit"}
          onClick={onClickAction}
        />
      );
    }
    return (
      <ButtonJack
        // isSmall
        onClick={onClickAction}
        isBullet
        style={{
          padding: "4px 8px",
          borderRadius: "16px",
          minWidth: "unset",
          minHeight: "unset",
          // backgroundColor: "white",
        }}
        children={<GothamMedium className="font12">{t("Change")}</GothamMedium>}
      />
    );
  };
  const button = buttonDecider();

  return (
    <div className={styles.recipient}>
      <div className={styles["recipient-details"]}>
        <div>
          <Avatar name={recipientName} size="large" />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <GothamMedium
            className={styles["truncated-text"]}
            children={recipientName}
            style={{ ...(isBiggerFont ? font14 : font12) }}
          />
          <GothamRegular
            children={destination}
            style={{ color: jackColors.grey90, ...font12 }}
          />
        </div>
      </div>
      <div>{button}</div>
    </div>
  );
};
