import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ViewportList from "react-viewport-list";
import { colors, jackColors } from "../../../../assets/colors";
import imgDelete from "../../../../assets/images/delete-trash-grey.svg";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { useModalHook } from "../../../../components/Modals";
import { DropdownTableSimple } from "../../../../components/Table/dropdown/parent";
import { GothamRegular } from "../../../../components/Text";
import { customSum } from "../../../../components/tools";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import {
  TableInput,
  TableSelectionInput,
} from "../../../payrollCreateComponents/loop/validation/table/inputs";

export const LocalTransferValidationTable = ({
  data,
  setData,
  isInvalid,
  headerArray,
  leftComponent = null,
  isThereValidAndMatchData = false,
  mergedValuesArr = [],
  dropdownProps: { isOpen, name, setIsOpen },
}) => {
  const [deletedId, setDeletedId] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(false);
  const { t } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const { query } = useRouter();
  const { action } = query;
  const isResubmit = action === "resubmit";

  const { isOpen: isOpenDeleteModal, toggle: toggleDeleteModal } =
    useModalHook();
  const { successSnackBar } = ToasterHook();

  const filteredDataRaw = data.filter((item) => item);
  const sliced = filteredDataRaw.slice(0, 3);
  const filteredData = isOpen ? filteredDataRaw : sliced;
  const hasMore = filteredDataRaw.length > 3;
  const isOneDataLeft = filteredDataRaw.length == 1;

  const handleDelete = () => {
    setData((prev) => {
      const result = (prev || []).filter(
        (_, filterIndex) => filterIndex !== deletedId
      );
      return result;
    });
    successSnackBar({
      msg: tLocalTransfer("1 transaction has been deleted."),
      showClose: true,
    });
    // filteredDataRaw
  };

  const ratio = (number) =>
    `${(number * 100) / customSum(headerArray.map(({ width }) => width))}%`;

  const headerTable = headerArray.map((item, index) => ({
    ...item,
    width: ratio(item.width),
    textAlign: index === 0 && "center", // For number
  }));

  const descConfirmationModal = () => {
    if (mergedValuesArr.length < 2) {
      if (isThereValidAndMatchData)
        return tLocalTransfer(
          "Once removed, we’ll direct you to the confirmation page."
        );
      return tLocalTransfer(
        "Once removed, we’ll direct you to the transfer details page"
      );
    }
    return tLocalTransfer("You will delete this recipient from the list.");
  };

  const titleConfirmationModal = () => {
    if (mergedValuesArr.length < 2)
      return tLocalTransfer("Delete  all invalid recipients?");
    else return tLocalTransfer("Delete recipient?");
  };

  const tbodyRef = useRef();

  useEffect(() => {
    if (!filteredDataRaw.length) return setIsOpen(false);
  }, [filteredDataRaw.length]);

  if (!filteredData.length) return null;

  return (
    <div className="w-100" onMouseLeave={() => setHoveredIndex(false)}>
      <DropdownTableSimple
        dropdownProps={{
          hasMore,
          isOpen,
          name,
          setIsOpen,
          additionalOffset: 200,
        }}
        headerTable={headerTable}
        leftComponent={leftComponent}
        rightComponent={
          <GothamRegular
            style={{ color: jackColors.neutral600, marginRight: 12 }}
            className="font12"
          >
            {filteredData?.length} {t("of")} {filteredDataRaw?.length}{" "}
            {t("transactions")}
          </GothamRegular>
        }
        tbodyRef={tbodyRef}
        bodyComponent={
          <ViewportList
            viewportRef={tbodyRef}
            items={filteredData}
            itemMinSize={100}
            margin={8}
            overscan={10}
          >
            {(array, index) => {
              if (!array) return null;
              const isHovered = hoveredIndex === index;
              const isLast = filteredData?.length - 1 === index;
              return (
                <tr
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  className={`${isHovered && "hover-600"}`}
                >
                  {array.map((item, childIndex) => {
                    const { name, type, value, options = [] } = item || {};
                    const isNumber = name == "number";
                    const isAction = name == "action";
                    const isNameCheckResult = name == "name_check_result";
                    const isText = type == "text";
                    const isSelection = options.length;
                    const isId = name == "id";
                    const isLocalRecipientId = name == "local_recipient_id";
                    const hideNameCheck = isInvalid && isNameCheckResult;
                    if (isLocalRecipientId || isId || hideNameCheck)
                      return null;

                    const styleCustom = {
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    };

                    const ValueContainer = ({ children }) => {
                      return (
                        <td
                          style={{
                            padding: "12px 8px",
                            position: "relative",
                            textAlign: (isAction || isNumber) && "center",
                            opacity: isNameCheckResult && "50%",
                          }}
                        >
                          <GothamRegular style={{ color: colors.grey33 }}>
                            {children}
                          </GothamRegular>
                        </td>
                      );
                    };

                    if (isText)
                      return (
                        <ValueContainer key={childIndex}>
                          {value || "-"}
                        </ValueContainer>
                      );
                    if (isNumber)
                      return (
                        <ValueContainer key={childIndex}>
                          {index + 1}
                        </ValueContainer>
                      );

                    if (isAction) {
                      if (!isHovered || isResubmit)
                        return <td key={childIndex} />;
                      return (
                        <ValueContainer key={childIndex}>
                          <JackIcons
                            name="delete"
                            fill={jackColors.neutral700}
                            style={{ width: 20, height: 20 }}
                            onClick={() => {
                              toggleDeleteModal();
                              setDeletedId(index);
                            }}
                          />
                        </ValueContainer>
                      );
                    }
                    const getWidth = 400;
                    const defaultProps = {
                      data,
                      item,
                      index,
                      childIndex,
                      setData,
                      getWidth,
                      styleModal: {
                        width: getWidth,
                        top: isLast ? -15 : -5,
                      },
                    };

                    if (isSelection)
                      return (
                        <TableSelectionInput
                          key={childIndex}
                          {...defaultProps}
                        />
                      );

                    return (
                      <TableInput
                        key={childIndex}
                        styleText={styleCustom}
                        {...defaultProps}
                      />
                    );
                  })}
                </tr>
              );
            }}
          </ViewportList>
        }
      />
      <ConfirmationModalJack
        title={titleConfirmationModal()}
        modal={isOpenDeleteModal}
        img={imgDelete}
        toggle={toggleDeleteModal}
        onClick={handleDelete}
        buttonTextRight={t("Delete")}
        buttonTextLeft={t("Back")}
        text={descConfirmationModal()}
        customContentClassName="modal-border-radius4"
        isCenteredTitle
      />
    </div>
  );
};
