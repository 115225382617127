const longestAncestoryLineDecider = (ancestoryLines = {}) => {
  let longestAncestoryLine =
    ancestoryLines[Object.keys(ancestoryLines)[0]] ?? [];

  Object.entries(ancestoryLines).forEach(([key, value]) => {
    const isLonger = ancestoryLines[key].length > longestAncestoryLine.length;
    if (isLonger) longestAncestoryLine = value;
  });

  return longestAncestoryLine;
};

export default longestAncestoryLineDecider;
