import { camelCase } from "lodash";

const flagImages = [
  "Algeria",
  "East Timor",
  "Rapa Nui",
  "abkhazia",
  "afghanistan",
  "aland islands",
  "albania",
  "american samoa",
  "andorra",
  "angola",
  "anguilla",
  "antigua and barbuda",
  "argentina",
  "armenia",
  "aruba",
  "australia",
  "austria",
  "azerbaijan",
  "azores islands",
  "bahamas",
  "bahrain",
  "balearic islands",
  "bangladesh",
  "barbados",
  "basque country",
  "belarus",
  "belgium",
  "belize",
  "benin",
  "bermuda",
  "bhutan",
  "bolivia",
  "bonaire",
  "bosnia and herzegovina",
  "botswana",
  "brazil",
  "british columbia",
  "british indian ocean territory",
  "british virgin islands",
  "brunei",
  "bulgaria",
  "burkina faso",
  "burundi",
  "cambodia",
  "cameroon",
  "canada",
  "canary islands",
  "cape verde",
  "cayman islands",
  "central african republic",
  "ceuta",
  "chad",
  "chile",
  "china",
  "cocos island",
  "colombia",
  "comoros",
  "cook islands",
  "corsica",
  "costa rica",
  "croatia",
  "cuba",
  "curacao",
  "cyprus",
  "czech republic",
  "democratic republic of congo",
  "denmark",
  "djibouti",
  "dominica",
  "dominican republic",
  "ecuador",
  "egypt",
  "el salvador",
  "england",
  "equatorial guinea",
  "eritrea",
  "estonia",
  "ethiopia",
  "european union",
  "falkland islands",
  "faroe islands",
  "fiji",
  "finland",
  "france",
  "french polynesia",
  "gabon",
  "galapagos islands",
  "gambia",
  "georgia",
  "germany",
  "ghana",
  "gibraltar",
  "greece",
  "greenland",
  "grenada",
  "guam",
  "guatemala",
  "guernsey",
  "guinea bissau",
  "guinea",
  "guyana",
  "haiti",
  "hawaii",
  "honduras",
  "hong kong",
  "hungary",
  "iceland",
  "india",
  "indonesia",
  "iran",
  "iraq",
  "ireland",
  "isle of man",
  "israel",
  "italy",
  "ivory coast",
  "jamaica",
  "japan",
  "jersey",
  "jordan",
  "kazakhstan",
  "kenya",
  "kiribati",
  "kosovo",
  "kuwait",
  "kyrgyzstan",
  "laos",
  "latvia",
  "lebanon",
  "lesotho",
  "liberia",
  "libya",
  "liechtenstein",
  "lithuania",
  "luxembourg",
  "macao",
  "madagascar",
  "madeira",
  "malawi",
  "malaysia",
  "maldives",
  "mali",
  "malta",
  "marshall island",
  "martinique",
  "mauritania",
  "mauritius",
  "melilla",
  "mexico",
  "micronesia",
  "moldova",
  "monaco",
  "mongolia",
  "montenegro",
  "montserrat",
  "morocco",
  "mozambique",
  "myanmar",
  "namibia",
  "nato",
  "nauru",
  "nepal",
  "netherlands",
  "new zealand",
  "nicaragua",
  "niger",
  "nigeria",
  "niue",
  "norfolk island",
  "north korea",
  "northern cyprus",
  "northern marianas islands",
  "norway",
  "oman",
  "orkney islands",
  "ossetia",
  "pakistan",
  "palau",
  "palestine",
  "panama",
  "papua new guinea",
  "paraguay",
  "peru",
  "philippines",
  "pitcairn islands",
  "poland",
  "portugal",
  "puerto rico",
  "qatar",
  "republic of macedonia",
  "republic of the congo",
  "romania",
  "russia",
  "rwanda",
  "saba island",
  "sahrawi arab democratic republic",
  "samoa",
  "san marino",
  "sao tome and prince",
  "sardinia",
  "saudi arabia",
  "scotland",
  "senegal",
  "serbia",
  "seychelles",
  "sierra leone",
  "singapore",
  "sint eustatius",
  "sint maarten",
  "slovakia",
  "slovenia",
  "solomon islands",
  "somalia",
  "somaliland",
  "south africa",
  "south korea",
  "south sudan",
  "spain",
  "sri lanka",
  "st barts",
  "st lucia",
  "st vincent and the grenadines",
  "sudan",
  "suriname",
  "swaziland",
  "sweden",
  "switzerland",
  "syria",
  "taiwan",
  "tajikistan",
  "tanzania",
  "thailand",
  "tibet",
  "togo",
  "tokelau",
  "tonga",
  "transnistria",
  "trinidad and tobago",
  "tunisia",
  "turkey",
  "turkmenistan",
  "turks and caicos",
  "tuvalu",
  "uganda",
  "ukraine",
  "united arab emirates",
  "united kingdom",
  "united nations",
  "united states",
  "uruguay",
  "uzbekistán",
  "vanuatu",
  "vatican city",
  "venezuela",
  "vietnam",
  "virgin islands",
  "wales",
  "yemen",
  "zambia",
  "zimbabwe",
];

export const getFlagImagesObj = () => {
  let obj = {};
  flagImages.forEach((key) => {
    const name = camelCase(key);
    obj[name] = `/images/countries/${key}.svg`;
  });
  return obj;
};
