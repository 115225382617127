import CustomHoverInfo from "components/CustomHoverInfo/CustomHoverInfo";
import styles from "./styles.module.css";
import { GothamMedium, GothamRegular } from "components/Text";
import { jackColors } from "assets/colors";
import { JackIcons } from "assets/jackIcons/parent";

const TemporaryUnavailableTooltipWrapper = ({
  module = "", // essential prop (from useActiveModules)
  activeModulesProps = {}, // essential prop (from useActiveModules)
  children = null,
  trianglePosition = "left",
  tooltipTranslate = "-340px -75px",
  wrapperProps = {},
}) => {
  const trianglePositioning = (() => {
    switch (trianglePosition) {
      case "left":
        return {
          translateTrianglePoint: "320px 50px",
          rotateTrianglePoint: "180deg",
        };
      case "right":
        return {
          translateTrianglePoint: "-8px 50px",
          rotateTrianglePoint: "0deg",
        };
      default:
        return {
          translateTrianglePoint: "320px 50px",
          rotateTrianglePoint: "180deg",
        };
    }
  })();

  const tooltipProps = {
    style: {
      width: "320px",
      padding: "12px 8px",
      translate: tooltipTranslate,
      flexDirection: "column",
      gap: "8px",
    },
    backgroundColor: "white",
    ...trianglePositioning,
  };

  const {
    showInactiveModuleTooltip,
    handleHoverModuleOption,
    handleUnhoverModuleOption,
  } = activeModulesProps ?? {};

  const handleContactUs = () => {
    window.open("https://wa.me/6285282051660", "_blank");
  };

  const show = showInactiveModuleTooltip === module;

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => {
        handleHoverModuleOption && handleHoverModuleOption(module);
      }}
      onMouseLeave={() => {
        handleUnhoverModuleOption && handleUnhoverModuleOption();
      }}
      {...wrapperProps}
    >
      {children}
      <CustomHoverInfo show={show} {...tooltipProps}>
        <GothamMedium style={{ color: jackColors.neutral900 }}>
          This service is temporarily disabled
        </GothamMedium>
        <GothamRegular
          woFontColor
          className="font12"
          style={{ color: jackColors.neutral800 }}
        >
          Please contact us if you need any further details and assistance.
        </GothamRegular>
        <div className={styles.contactUsContainer} onClick={handleContactUs}>
          <GothamMedium
            style={{
              color: jackColors.neutral900,
              textDecoration: "underline",
            }}
          >
            Contact us
          </GothamMedium>
          <JackIcons
            name="arrow-forward"
            fill={jackColors.neutral900}
            style={{ scale: "0.8" }}
          />
        </div>
      </CustomHoverInfo>
    </div>
  );
};

export default TemporaryUnavailableTooltipWrapper;
