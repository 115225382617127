import { useEffect, useState } from "react";
import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { fetch, useDownload } from "../../../tools/api";
import { booleanState } from "../../../components/Status";
import { useGetAuth } from "../../../contexts/AuthContext";
import { LocalDisbursementProgressModal } from "./progress";
import { colors, jackColors } from "../../../assets/colors";
import LocalTransferAvatar from "../../../assets/images/local-transfer-avatar.svg";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { Avatar } from "../../../components/Avatar";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { ArrayAfterReleaseBanking } from "../crossBorderSingleModal/DanamonReleasePayment/arraySummary";
import { originTrxCountDecider } from "../retry-resubmit/helpers";
import { MaskingBankingPayload } from "../crossBorderSingleModal/DanamonReleasePayment/logic";
import { Trans, useTranslation } from "react-i18next";
import { pluralize } from "pageComponents/invoiceComponents/data/formatter";
import { RecipientCardSmall } from "pageComponents/localTransferEnhancement/typescript/create/singleTransferComponents/trf-detail/components";
import { JackIcons } from "assets/jackIcons/parent";
import { font12 } from "pageComponents/virtualAccountComponents/common/utils";

const formatter = (res, prev) => {
  const prevArray = prev?.data || [];
  const resArray = (res?.data || []).map((item) => {
    const {
      local_recipient,
      amount,
      state: leftStatus,
      created_at,
      fee,
    } = item;
    const { name: leftTop } = local_recipient || {};
    const leftBottom = `• ${customDateFormatter(created_at, true)}`;
    const rightTop = `IDR ${formatCurrencyNoDecimal(
      Number(amount) + Number(fee)
    )}`;
    return {
      ...item,
      category: "local_disbursement_batch",
      leftTop,
      leftStatus,
      leftBottom,
      rightTop,
    };
  });

  const data = [...prevArray, ...resArray];
  return { ...res, data };
};

export const useLocalDisbursementBatchTransactions = (id) => {
  // fetch data
  const [page, setPage] = useState(1);

  const { data, refetch, setData } = fetch({
    url: `/local_transaction_batches/${id}/show_transactions?page=${page}&per_page=20`,
    woInit: true,
    defaultValue: {},
    formatter,
    afterSuccess: (res) => {
      const { page, total_page } = res || {};
      if (page == total_page) return;
      if (!total_page) return;
      setPage((p) => p + 1);
    },
  });

  useEffect(() => {
    if (!id) return;
    refetch();
  }, [page, id]);

  return { data, setData };
};

export const defaultAfterSuccessActivity = ({
  res,
  setData,
  obj: { category: categoryProps, id, state: stateProps, changedStatus },
}) => {
  const { state } = res?.data || {};

  if (!setData) return;

  setData((prev) => {
    const { array: prevArray = [] } = prev || {};

    const array = prevArray.map((item) => {
      const { category, originator_id } = item || {};
      const sameCategory = category == categoryProps;
      const sameId = originator_id == id;
      const sameState = state == stateProps;

      if (!sameId || !sameCategory || !sameState) return item;

      return {
        ...item,
        status: changedStatus,
      };
    });

    const result = { ...prev, array };

    return result;
  });
};

export const useLocalDisbursementDetails = ({
  id,
  setData,
  isModal = true,
}) => {
  const {
    data: details,
    refetch,
    loading,
  } = fetch({
    url: `/local_transaction_batches/${id}`,
    woInit: true,
    defaultValue: {},
    noToaster: false,
    afterSuccess: (res) =>
      defaultAfterSuccessActivity({
        res,
        setData,
        obj: {
          changedStatus: "in_progress",
          category: "local_batch",
          id,
          state: "on_progress",
        },
      }),
  });

  const statusFlow = (
    <LocalDisbursementProgressModal data={details} isModal={isModal} />
  );

  return { details, refetch, statusFlow, loading };
};

export const getStatusHistoryPerObj = ({ array }) => {
  const getUniqueListBy = (array, key) => {
    return [...new Map(array?.map((item) => [item[key], item])).values()];
  };

  const statusHistory = getUniqueListBy(array || [], "to");

  const filterByState = ({ key }) =>
    statusHistory?.filter(({ to }) => to === key)[0];

  const draftObj = filterByState({ key: "draft" });
  const validateObj = filterByState({ key: "validate_recipient" });
  const waitingApprovalObj = filterByState({
    key: "waiting_for_approval_tasks",
  });
  const waitingPaymentObj = filterByState({ key: "waiting_for_payment" });
  const onProgressObj = filterByState({ key: "on_progress" });
  const completedObj = filterByState({ key: "completed" });
  const partialFailedObj = filterByState({ key: "partial_failed" });

  return {
    draftObj,
    validateObj,
    waitingApprovalObj,
    waitingPaymentObj,
    onProgressObj,
    completedObj,
    partialFailedObj,
  };
};

export const ArrayDeciderTransactionFlow = ({ data }) => {
  const { user: userLoggedIn } = useGetAuth();
  const { name: nameUserLoggedIn } = userLoggedIn || {};
  const {
    state,
    updated_at,
    created_at,
    status_history,
    local_recipient,
    user,
    recipient,
  } = data || {};
  const { name: nameLocalRecipient } = local_recipient || {};
  const { name: nameRecipientSingle } = recipient || {};
  const { name: user_name } = user || {};

  const name = nameRecipientSingle ? nameRecipientSingle : nameLocalRecipient;

  const drafterName =
    nameUserLoggedIn === user_name ? "You" : user_name || "Drafter";

  const {
    draftObj,
    validateObj,
    waitingApprovalObj,
    waitingPaymentObj,
    onProgressObj,
    completedObj,
    partialFailedObj,
  } = getStatusHistoryPerObj({ array: status_history });

  const {
    isCancelled,
    isOnProgress,
    isCompleted,
    isPartialFailed,
    isPending,
    isFailed,
    isConfirmed,
    isRefund,
    isDeclined,
  } = booleanState(state);

  if (isOnProgress || isConfirmed) {
    const statusArray = [
      {
        text: `${drafterName} requested a transaction(s)`,
        date: customDateFormatter(created_at),
        isActive: true,
      },
      {
        text: "Local payment has been received",
        date: customDateFormatter(onProgressObj?.created_at),
        isActive: true,
      },
      {
        text: `Transferring money to ${name}`,
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isCompleted || isPartialFailed) {
    const finishDate = completedObj?.created_at || partialFailedObj?.created_at;
    const statusArray = [
      {
        text: `${drafterName} requested a transaction(s)`,
        date: customDateFormatter(created_at),
        isActive: true,
      },
      {
        text: "Local payment has been received",
        date: customDateFormatter(onProgressObj?.created_at),
        isActive: true,
      },
      {
        text: `Transferring money to ${name}`,
        date: finishDate && customDateFormatter(finishDate),
        isActive: true,
      },
      {
        text: "Completed",
        date: finishDate && customDateFormatter(finishDate),
        isActive: true,
        styleCustom: { marginTop: -5 },
      },
    ];
    return statusArray;
  }

  if (isCancelled || isFailed || isDeclined) {
    const statusArray = [
      {
        text: `${drafterName} created a transaction(s)`,
        isActive: true,
        date: customDateFormatter(created_at),
      },
      {
        data: [
          {
            text: "We were unable to process your transaction. Please ask your account manager for more info",
            date: customDateFormatter(updated_at),
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  if (isRefund) {
    const statusArray = [
      {
        text: `${drafterName} requested a transaction(s)`,
        isActive: true,
        date: customDateFormatter(created_at),
      },
      {
        data: [
          {
            text: "We were unable to process your transaction. Please ask your account manager for more info",
            date: customDateFormatter(updated_at),
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  if (isPending) {
    const statusArray = [
      {
        text: `${drafterName} created a transaction`,
        isActive: true,
        date: customDateFormatter(created_at),
      },
      {
        text: "Local payment has been received",
      },
      {
        text: `Transferring money to ${name}`,
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  return [];
};

export const useLocalDisbursementBatch = ({
  data,
  array,
  isReleaser,
  wingsSelectedItem,
}) => {
  const {
    name,
    total_batch_amount,
    total_amount,
    total_fee,
    state: stateRaw,
    created_at,
    batch_number,
    local_transactions,
    drafter,
    activity_state,
    notes,
    total_trx = 0,
    is_single_trx = false,
  } = data || {};
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const { t: tLocalRevamp } = useTranslation("local-transfer/revamp");

  // SINGLE TRANSFER
  const {
    local_recipient,
    notes: notesSingleTransfer,
    reference_id,
  } = local_transactions?.[0] ?? {};
  const {
    bank_name,
    account_number,
    name: singleTransferRecipientName,
  } = local_recipient ?? {};
  // SINGLE TRANSFER

  const { transfer_service, payment_method } = MaskingBankingPayload(data);
  //newState activity_state = ["scheduled","payment_delayed"]
  const state = stateRaw == activity_state ? stateRaw : activity_state;
  const { name: nameDrafter } = drafter || {};
  const trxCount = originTrxCountDecider(local_transactions);
  // const isSingleTransfer = trxCount === 1;
  const isSingleTransfer = is_single_trx;

  const { isRejected, isWaitingApprovalTask } = booleanState(state);

  const {
    local_recipient: localRecipientSingle,
    beneficiary_bank_name: beneBankSingle,
    reference_id: referenceIdSingle,
    default_fee: defaultFeeSingle,
    created_at: createdAtSingle,
    recipient: recipientSingle,
    leftTop: descriptionSingle,
    amount: amountRawSingle,
    rightTop: amountSingle,
    leftTop: beneBankName,
    status: statusSingle,
    state: stateSingle,
    notes: notesSingle,
    fee: feeSingle,
    id: idSingle,
  } = wingsSelectedItem || {};

  // LOCAL RECIPIENT ITU SELALU UPDATE
  const {
    name: nameLocalRecipientSingle,
    email: emailLocalRecipientSingle,
    account_number: accNumberLocalRecipientSingle,
  } = localRecipientSingle || {};
  //   LOCAL RECIPIENT ITU SELALU UPDATE

  // RECIPIENT  ITU NEMPEL KE TRANSAKSI
  const { name: nameRecipientSingle, email: emailRecipientSingle } =
    recipientSingle || {};
  // RECIPIENT  ITU NEMPEL KE TRANSAKSI

  const nameWingsSelected = nameRecipientSingle
    ? nameRecipientSingle
    : nameLocalRecipientSingle;

  const isPendingSingle = statusSingle === "pending";

  const stateDecider = () => {
    if (wingsSelectedItem) {
      if (isRejected && isPendingSingle) return state;
      if (isPendingSingle) return null;
      return statusSingle;
    }
    // if (state) return state;
    if (state === "validate_recipient") return "draft";
    return isWaitingApprovalTask ? "pending" : state;
  };

  const stateForStatusLabel = stateDecider();
  const title = `${total_trx > 1 ? "Local batch transfer:" : "Local"} ${name}`;

  // HEADER DATA ==================
  const headerData = {
    title: isSingleTransfer ? (
      `${tLocalRevamp("Local Transfer to")} ${singleTransferRecipientName}`
    ) : (
      <Trans
        i18nKey={tLocalTransfer("dynamic.dynamic_text_11", { dupNum: name })}
      />
    ), //`${isSingleTransfer ? "Local" : "Local batch transfer:"} ${name}`,
    value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
    icon: <img src={LocalTransferAvatar} />,
    status: stateForStatusLabel,
    details: (
      <Trans
        i18nKey={tLocalTransfer("dynamic.dynamic_text_12", {
          dupNum: nameDrafter,
        })}
      />
    ),
  };
  // HEADER DATA ==================

  const { array: arrayLocal, stateAfterPayments } = ArrayAfterReleaseBanking({
    data,
    type: "local_transfer",
  });

  // ARRAY DETAILS ================
  const arrayDetails = [
    {
      title: tLocalTransfer("Created at"),
      value: customDateFormatter(created_at || ""),
    },
    ...(stateAfterPayments && !isSingleTransfer
      ? [
          {
            title: "Transfer service",
            value: transfer_service,
          },
          {
            title: "Source of account",
            value: payment_method,
          },
        ]
      : []),
    {
      title: tLocalTransfer("Transaction ID"),
      value: batch_number,
    },
    {
      title: tLocalTransfer("Reference ID"),
      value: reference_id,
    },
  ];

  const arrayAfterPayments = [
    {
      title: "Number of transactions",
      value: `${trxCount} Transactions`,
    },
    {
      title: "Total amount",
      value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
    },
    {
      title: "Handling fee",
      value: `IDR ${formatCurrencyNoDecimal(total_fee)}`,
      customIcon: "plus-circle",
    },
    {
      spacer: true,
    },
    {
      title: "Total payment",
      value: `IDR ${formatCurrencyNoDecimal(total_batch_amount)}`,
    },
  ];

  const arrayBeforePayments = !isSingleTransfer
    ? [
        {
          title: tLocalTransfer("Number of transactions"),
          value: `${trxCount} ${tLocalTransfer(
            pluralize(trxCount, "Transaction")
          )}`,
        },
        {
          title: tLocalTransfer("Total amount"),
          value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
        },
      ]
    : [
        {
          title: "Recipient",
          value: (
            <RecipientCardSmall
              name={singleTransferRecipientName}
              bankName={bank_name}
              accountNumber={account_number}
              withAvatar
            />
          ),
        },
        {
          title: "Transfer note",
          value: !notesSingleTransfer ? "-" : `“${notesSingleTransfer}”`,
          isFullWidth: true,
          styleValue: {
            borderRadius: 4,
            border: `1px solid ${jackColors.greyE6}`,
            background: "rgba(241, 241, 241, 0.50)",
            width: "100%",
            padding: 12,
            textAlign: "start",
            fontWeight: 600,
            color: jackColors.grey6C,
            ...font12,
          },
        },
      ];

  // ARRAY DETAILS SINGLE ================
  const arrayDetailsSingle = [
    {
      title: tLocalTransfer("Transaction ID"),
      value: referenceIdSingle,
    },
    {
      title: tLocalTransfer("Recipient"),
      value: (
        <div className="d-flex justify-content-end align-items-center">
          <div className="mr-2">
            <GothamRegular className="font12">
              {nameWingsSelected}
            </GothamRegular>
            <GothamRegular
              className="font10"
              style={{ color: jackColors.neutral700 }}
            >
              {beneBankSingle} - {accNumberLocalRecipientSingle}
            </GothamRegular>
          </div>
          <div style={{ width: 24 }}>
            <Avatar
              name={nameWingsSelected}
              size="small"
              textStyle={{ fontSize: 12 }}
            />
          </div>
        </div>
      ),
    },
    {
      title: tLocalTransfer("Transfer note"),
      value: !!notesSingle ? `“${notesSingle}”` : "-",
    },
  ];

  const arrayDetailsTrx =
    stateAfterPayments && !isSingleTransfer ? arrayLocal : arrayBeforePayments;
  // ARRAY DETAILS ================

  const arrayDetailsSingleTransfer =
    isSingleTransfer && !stateAfterPayments
      ? [
          {
            title: "Amount",
            value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
            rightBold: true,
          },
        ]
      : isSingleTransfer && stateAfterPayments
      ? arrayLocal
      : [];
  // ARRAY DETAILS SINGLE ================

  // OVERVIEW THINGS =====================
  const numberFunc = (stateCheck) =>
    (array || []).filter(({ state }) => state == stateCheck).length;

  const completed = numberFunc("completed");
  const failed =
    numberFunc("failed") + numberFunc("refunded") + numberFunc("declined");
  const onProgress = numberFunc("on_progress") + numberFunc("pending");

  const overviewArray = [
    { color: colors.green06, number: completed, title: "Success" },
    {
      color: colors.blue2C,
      number: onProgress,
      title: "In Progress",
    },
    {
      color: colors.redE7,
      number: failed,
      title: "Failed",
    },
  ];
  // OVERVIEW THINGS =====================

  return {
    headerData,
    overviewArray,
    stateForStatusLabel,
    arrayDetails,
    arrayDetailsTrx,
    arrayDetailsSingle,
    stateAfterPayments,
    arrayDetailsSingleTransfer,
  };
};

export const useDownloadLocalReceipt = ({ idBatch, name }) => {
  const { handleDownload, loading } = useDownload();
  const { successSnackBar } = ToasterHook();
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const downloadBulk = async ({ isBulkXls = false }) => {
    await handleDownload({
      url: `/local_transaction_batches/${idBatch}.${
        isBulkXls ? "xlsx" : "pdf"
      }`,
      fileName: `Jack-Local-Transfer-Batch-${name}.${
        isBulkXls ? "xlsx" : "pdf"
      }`,
      afterSuccess: () =>
        successSnackBar({
          msg: (
            <Trans
              i18nKey={tLocalTransfer("dynamic.dynamic_text_20", {
                dupNum: isBulkXls ? `(XLS)` : `(PDF)`,
              })}
            />
          ),
          showClose: true,
        }),
    });
  };

  const downloadSingle = async ({ payload, totalReceipt = 0 }) => {
    await handleDownload({
      type: "application/zip",
      url: `/local_transaction_batches/${idBatch}/bulk_transaction_receipt`,
      fileName: `Jack-Local-Transfer-${name}.zip`,
      isPut: true,
      payloadPut: payload,
      jsonHandler: () => {
        successSnackBar({
          msg: (
            <Trans
              i18nKey={tLocalTransfer("dynamic.dynamic_text_24", {
                trxNum: totalReceipt,
              })}
            />
          ),
          showClose: true,
        });
      },
      afterSuccess: () =>
        successSnackBar({
          msg: (
            <Trans
              i18nKey={tLocalTransfer("dynamic.dynamic_text_22", {
                trxNum: totalReceipt,
              })}
            />
          ),
          showClose: true,
        }),
    });
  };

  return { downloadBulk, downloadSingle, loading };
};
