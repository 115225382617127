import React from "react";
import { colors, jackColors } from "../assets/colors";
import { windowDimension } from "./tools";

export const ProgressBar = ({ width = 310, progress = 0.5 }) => {
  const loadingBarWidth = width * progress;
  const { isTabOrPhone, width: widthReal } = windowDimension();
  return (
    <div
      style={{
        backgroundColor: jackColors.neutral900,
        width: isTabOrPhone ? "100%" : width,
        height: isTabOrPhone ? 16 : 10,
        marginTop: 32,
        marginBottom: 32,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          transition: "all 0.4s linear",
          width: loadingBarWidth,
          height: "100%",
          backgroundColor: jackColors.greenB9,
          top: 0,
          // borderRadius: 12,
        }}
      />
      {!loadingBarWidth && (
        <div
          style={{
            backgroundColor: jackColors.greenB9,
            width: 10,
            height: 10,
            transition: "all 0.2s linear",
            // borderRadius: 24,
            position: "absolute",
            top: 0,
            left: !loadingBarWidth ? 0 : loadingBarWidth - 24,
          }}
        ></div>
      )}
    </div>
  );
};
