import moment from "moment";

export const COMPLETED = "completed";
export const SUCCESS = "success";
export const FAILED = "failed";
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const PARTIAL_FAILED = "partial_failed";
export const RETRY = "retry";

export const FOLDED = "folded";

export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const REASON = "reason";
export const CUSTOM = "custom";

export const EXAMPLE_UPDATES = [
  {
    status: "success",
    contents: [
      {
        type: "primary",
        content: [{ type: "bold", value: "You" }, " created this transaction"],
      },
      {
        type: "secondary",
        content: moment(new Date()).format("LL"),
      },
    ],
  },
  {
    status: "retry",
    contents: [
      {
        type: "primary",
        content: "Transaction retried",
      },
      {
        type: "secondary",
        content: moment(new Date()).format("LL"),
      },
    ],
  },
  {
    status: "partial_failed",
    contents: [
      {
        type: "primary",
        content: "Transaction partially failed",
      },
      {
        type: "reason",
        content: "Something went wrong",
      },
      {
        type: "secondary",
        content: moment(new Date()).format("LL"),
      },
    ],
  },
  {
    status: "failed",
    contents: [
      {
        type: "primary",
        content: "Transaction failed",
      },
      {
        type: "reason",
        content: "Something went wrong",
      },
      {
        type: "secondary",
        content: moment(new Date()).format("LL"),
      },
    ],
  },
  {
    status: "active",
    contents: [
      {
        type: "primary",
        content: "Transaction current update",
      },
      {
        type: "secondary",
        content: moment(new Date()).format("LL"),
      },
    ],
  },
  {
    status: "completed",
    contents: [
      {
        type: "primary",
        content: "Transaction completed",
      },
      {
        type: "secondary",
        content: moment(new Date()).format("LL"),
      },
    ],
  },
  {
    status: "inactive",
    contents: [
      {
        type: "secondary",
        content: "Transaction future update",
      },
      {
        type: "secondary",
        content: moment(new Date()).format("LL"),
      },
    ],
  },
];
