const categoryChecker = (transaction) => {
  const { details } = transaction ?? {};
  const { category_id } = details ?? {};

  const isActiveCategory = category_id !== undefined;

  const isBatchCategory = Array.isArray(category_id);

  const hasCategory = () => {
    if (!isActiveCategory) return true;

    if (isBatchCategory) {
      return category_id?.length > 0 && category_id[0] !== -1;
    }

    if (category_id === null) return false;

    return category_id !== -1;
  };

  const hasMultipleCategories = isBatchCategory && category_id?.length > 1;
  const categoriesCount = hasMultipleCategories
    ? category_id?.filter((id, index, array) => {
        return array.indexOf(id) === index;
      })?.length
    : 0;

  return {
    isBatchCategory,
    hasCategory: hasCategory(),
    hasMultipleCategories,
    categoriesCount,
  };
};

export default categoryChecker;
