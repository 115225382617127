import { colors } from "../assets/colors";
import { GothamMedium } from "./Text";

export const BadgeRole = ({ role = "" }) => (
  <GothamMedium
    className="font10"
    style={{
      color: colors.blue10,
      backgroundColor: colors.yellowFFF,
      padding: "2px 8px",
    }}
  >
    {role}
  </GothamMedium>
);

export const BadgeCounter = ({
  count = 0,
  isOrange = false,
  containerStyle: containerStyleProps,
}) => {
  const containerStyle = {
    backgroundColor: isOrange ? colors.orangeF2 : colors.neutral900,
    padding: "2px 6.5px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...containerStyleProps,
  };

  const textStyle = {
    color: colors.neutral100,
    fontSize: "12px",
    lineHeight: "16px",
  };

  return (
    <div style={containerStyle}>
      <GothamMedium className="font10" style={textStyle}>
        {count}
      </GothamMedium>
    </div>
  );
};
