import { JackIcons } from "../../assets/jackIcons/parent";
import { Avatar } from "../Avatar";
import { booleanState } from "../Status";
import CrossborderAvatar from "../../assets/images/crossborder-single-avatar.svg";
import CrossBorderGrey from "../../assets/images/crossborderIconGrey.svg";

export const nameDecider = (
  state,
  crossBorderIcon = CrossborderAvatar,
  source = ""
) => {
  const {
    isCardTransaction,
    isIncomingPayment,
    isTopUp,
    isApiTransaction,
    isConversion,
    isAllocate,
    isConvert,
    isRefund,
    isWithdraw,
    isReAllocate,
    isPayroll,
    isReimbursement,
    isInvoice,
    isCrossBorder,
    isLocalDisbursements,
    isEWallet,
    isCard,
    isMoneyMovement,
    isCardBillPayment,
    isCollection,
    isCardMoveBalance: isCardMoveBalanceRaw,
    isCardCreditTopUp: isCardCreditTopUpRaw,
    isJackTransfer,
    isDanamonMoveBalance,
  } = booleanState(state);

  const isCardMoveBalance = isCardMoveBalanceRaw && source == "main_balance";
  const isCardCreditTopUp = isCardCreditTopUpRaw && source == "main_balance";
  if (isReimbursement) return "Reimbursement";
  if (isInvoice) return "Invoice Payment";
  if (isPayroll) return "Payroll";
  if (isCrossBorder) return crossBorderIcon;
  if (isLocalDisbursements) return "Send Money";
  if (isEWallet) return "wallet";
  if (isCard) return "Corporate card";
  if (isMoneyMovement) return "Money Movement";
  if (isTopUp || isIncomingPayment) return "top up";
  if (isWithdraw) return "withdraw";
  if (isCardMoveBalance || isCardCreditTopUp) return "Money Movement";
  if (isCardMoveBalanceRaw || isCardCreditTopUpRaw) return "Corporate card";
  if (isCardBillPayment) return "card payment";
  if (isRefund) return "refund";
  if (isCollection) return "coin";

  if (isCardTransaction) return "Corporate card";
  if (isApiTransaction) return "API";
  if (isAllocate || isReAllocate) return "Money Movement";
  if (isConversion || isConvert) return "Money Movement";
  if (isJackTransfer) return "swap-outline";
  if (isDanamonMoveBalance) return "Money Movement";
};

export const StatusAccountIconJack = ({ state, source, size = "medium" }) => {
  const name = nameDecider(state, CrossborderAvatar, source);
  const { isCrossBorder: isExternal } = booleanState(state);

  const { width, iconWidth, customCrossBorderSize } = widthDecider(size);
  if (isExternal) {
    return (
      <div style={{ width: width }}>
        <img src={name} style={{ width: customCrossBorderSize }} />
      </div>
    );
  }

  return (
    <div style={{ width: width }}>
      <Avatar
        size={size}
        mainIcon={
          <JackIcons
            name={name}
            fill="white"
            style={{ width: iconWidth, height: iconWidth }}
          />
        }
      />
    </div>
  );
};

export const SmallIconJack = ({ state }) => {
  const name = nameDecider(state, CrossBorderGrey);
  const { isCrossBorder: isExternal } = booleanState(state);

  if (isExternal) return <img src={name} style={{ width: 20 }} />;

  return (
    <JackIcons name={name} fill="#909098" style={{ width: 16, height: 16 }} />
  );
};

const widthDecider = (size) => {
  if (size == "normal") return { width: 32, iconWidth: 20 };
  if (size == "smallest")
    return { width: 20, iconWidth: 12, customCrossBorderSize: 20 };

  return { width: 32, iconWidth: 20 };
};
