import { useLanguage } from "public/locales/translationFunctions";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { booleanState } from "../../../../components/Status";
import { TextInlineMedium } from "../../../../components/Text";
import {
  customDateFormatter,
  teamManagerTextDecider,
  useExpiredCountDownDetails,
} from "../../../../components/tools";
import { getUserRole, useGetAuth } from "../../../../contexts/AuthContext";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { fetch } from "../../../../tools/api";
import {
  initialFailedTrxCountDecider,
  originTrxCountDecider,
} from "../../retry-resubmit/helpers";
import { useBooleanDanamonProduct } from "../DanamonReleasePayment/boolean";

const useYouOrNameFunc = () => {
  const { user } = useGetAuth();
  const { name: userName, id } = user || {};
  const { t } = useTranslation("common");

  const youOrNameFunc = (name) => {
    if (!name) return "";
    if (userName == name || id === name) return t("You");
    return name;
  };

  return { youOrNameFunc };
};

const workflowProcessFormatter = (workflow_process) => {
  let obj = {};
  Object.keys(workflow_process).forEach((key) => {
    const array = workflow_process[key] || [];

    const includesAdmin = array.filter(
      ({ user_role_name }) => user_role_name == "partner_admin"
    );

    const includesSuperAdmin = array.filter(
      ({ user_role_name }) => user_role_name == "partner_super_admin"
    );

    const includesEmployee = array.filter(
      ({ user_role_name }) => user_role_name == "partner_maker"
    );

    const includesBookkeeper = array.filter(
      ({ user_role_name }) => user_role_name == "partner_viewer"
    );

    const includesManager = array.filter((user) => user.team_manager);

    const includesIndividual = array.filter(
      ({ user_role_name, team_manager }) => !user_role_name && !team_manager
    );

    const arrayDecider = () => {
      if (!includesAdmin.length) return array;
      if (includesSuperAdmin.length)
        return [
          ...includesManager,
          ...includesSuperAdmin,
          ...includesAdmin,
          ...includesBookkeeper,
          ...includesEmployee,
          ...includesIndividual,
        ];
      return [
        ...includesManager,
        ...includesAdmin,
        ...includesBookkeeper,
        ...includesEmployee,
        ...includesIndividual,
      ];
    };

    const cleanArray = arrayDecider();
    const filteredApproved = array.filter(({ approved_at }) => approved_at);
    const newArray = filteredApproved.length ? filteredApproved : cleanArray;
    obj[key] = newArray;
  });

  return obj;
};

export const useCrossBorderSingleWorkflow = (transaction) => {
  const {
    // send money
    send_money_workflow_summary: send_money_workflow_summaryRaw = [],
    destination_country,
    // send money

    // invoice
    invoice_workflow_summary,
    // invoice

    // reimbursement
    reimbursement_workflow_summary,
    last_rejected_receipt_by,
    // reimbursement

    // payroll
    payroll_workflow_summary,
    executor,
    rejected_by_id,
    drafter,
    batch,
    // payroll

    // local transfer
    user_id,
    local_transactions,
    // local transfer

    balance_withdrawal_workflow_summary,

    // general
    workflow_process: workflow_processRaw = {},
    created_at,
    state,
    updated_at,
    rejection_note,
    user: userDefault,
    type,
    payment_expiry_at,
    // general
  } = transaction || {};
  const { isInternationalTransfer } = useBooleanDanamonProduct(type);
  const { isBahasa } = useLanguage();
  const { t: tPayroll } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const { t: tInternational } = useTranslation("international/create");
  const { t: tReim } = useTranslation("reimbursement/reimbursement");
  const { t: tJackTransfer } = useTranslation("jack-transfer/jack-transfer");
  const { t: tWithdraw } = useTranslation("withdraw");

  const isLocalTransaction = !!local_transactions?.length;
  const isReimbursement = reimbursement_workflow_summary;
  const isInvoicePayment = invoice_workflow_summary;
  const isPayroll = payroll_workflow_summary;
  const isBalanceTransfer = type === "balance_transfer";
  const isWithdrawal = !!balance_withdrawal_workflow_summary;

  const send_money_workflow_summary =
    reimbursement_workflow_summary ||
    invoice_workflow_summary ||
    payroll_workflow_summary ||
    send_money_workflow_summaryRaw;

  const workflow_process = workflowProcessFormatter(workflow_processRaw || {});
  const { isExpiredTime } = useExpiredCountDownDetails({ payment_expiry_at });

  const { data: userLocalTransferCreator, refetch } = fetch({
    url: `/business_users/${user_id}`,
    formatter: (res) => res?.data || {},
    defaultValue: null,
    woInit: true,
  });

  const rejectedById = rejected_by_id ?? last_rejected_receipt_by;
  const { data: userReject, refetch: refetchUserReject } = fetch({
    url: `/business_users/${rejectedById}`,
    formatter: (res) => res?.data || {},
    woInit: true,
  });

  useEffect(() => {
    if (!user_id) return;
    refetch();
  }, [user_id]);

  useEffect(() => {
    if (!rejectedById) return;
    refetchUserReject();
  }, [rejectedById]);

  const { users } = useConstants();

  const userFallbackValue = (users ?? []).find(({ id }) => user_id === id);

  const userCreatorObj =
    userDefault || userLocalTransferCreator || userFallbackValue || drafter;

  const isCompleted = isPayroll
    ? batch?.state === "completed"
    : state == "completed";
  const isOnProgress =
    state == "validating_payment" ||
    state == "on_progress" ||
    state == "waiting_for_confirmation";
  const isValidating = state == "validating_payment";
  const isRefunded = state == "awaiting_refund" || state == "refunded";
  const isAwaitingRefundStrict = state == "awaiting_refund";
  const isDraft = state == "drafted";
  const isFailedCrossBorder =
    isRefunded || state == "failed" || state == "declined";

  const localTrxs =
    (isPayroll ? batch?.local_transactions : local_transactions) ?? [];
  const originTrxCount = originTrxCountDecider(localTrxs);
  const initialFailedTrxCount = initialFailedTrxCountDecider(localTrxs);
  const wasPartiallyFailed =
    initialFailedTrxCount > 0 && initialFailedTrxCount < originTrxCount;

  const isPartialFailed = isPayroll
    ? wasPartiallyFailed
    : state == "partial_failed";
  const isFailed = isPayroll ? batch?.state === "failed" : state == "failed";
  const { isRejected } = booleanState(state);

  const { user } = useGetAuth();
  const { name, id } = user || {};

  const isDrafter = user_id == user?.id || drafter?.id == user?.id;

  const { youOrNameFunc } = useYouOrNameFunc();

  const workflowStarted = new Date(
    "Wed Aug 31 2022 19:15:00 GMT+0700 (Western Indonesia Time)"
  ).getTime();

  const createdNumber = new Date(created_at).getTime();
  const isWorkflow = createdNumber > workflowStarted;

  let requestedArrays = [];
  Object.keys(workflow_process).forEach((key) => {
    const array = workflow_process[key];
    array.forEach((item) => {
      const { approved_at } = item || {};
      if (approved_at) return;
      requestedArrays.push(item);
    });
  });

  const currentRoleNames = requestedArrays.map(
    ({ user_role_name }) => user_role_name
  );

  const approverObj = send_money_workflow_summary.filter(
    ({ state, name: user_name }) => state == "requested" && user_name == name
  )[0];

  const { isAdmin, isSuperAdmin: isSuperAdminUser } = getUserRole();

  const isApproverDecider = () => {
    if (currentRoleNames.includes("partner_admin")) return isAdmin;
    if (currentRoleNames.includes("partner_super_admin"))
      return isSuperAdminUser;
    return approverObj?.name == name;
  };

  const isApprover = isApproverDecider();
  const cancelledStates = ["cancelled", "canceled", "rejected", "reject"];

  const cancelledArr = send_money_workflow_summary.filter(({ state }) =>
    cancelledStates.includes(state)
  );

  const cancelledObjDefault = cancelledArr[0];

  const cancelledObjReal = cancelledArr.filter(
    ({ approved_at }) => approved_at
  )[0];

  const cancelledObj = cancelledObjReal || cancelledObjDefault;
  const cancelByExpiredTime =
    !rejected_by_id &&
    isExpiredTime &&
    (isInternationalTransfer || destination_country);

  const isCancelled =
    ["canceled", "cancelled"].includes(state) || Boolean(cancelledObj);

  // Lines objs
  const creatorName = youOrNameFunc(userCreatorObj?.name);

  const textDecider = () => {
    if (isWithdrawal) {
      return `${creatorName} ${tWithdraw("requested to withdraw balance")}`;
    }
    if (isBalanceTransfer)
      return `${creatorName} ${tJackTransfer("requested to transfer balance")}`;
    if (isReimbursement)
      return `${creatorName} ${tReim("requested a reimbursement")}`;
    if (isInvoicePayment)
      return `${creatorName} created a bill payment request`;
    if (isPayroll)
      return `${creatorName} ${tPayroll("requested a payroll transfer")}`;
    if (isLocalTransaction)
      return `${creatorName} ${tLocalTransfer("requested a local transfer")}`;
    if (send_money_workflow_summary)
      return `${creatorName} ${tInternational(
        "requested an international transfer"
      )}`;

    return `${creatorName} created a transaction`;
  };

  const textDeciderValidating = () => {
    if (isPayroll || isLocalTransaction)
      return tPayroll("Sending money to recipient(s)");
    if (send_money_workflow_summary)
      return tInternational("Sending money to recipient");
    return "Transferring money to recipients";
  };

  const failedLocalTrxs = localTrxs.filter(
    ({ state }) => state === "declined" || state === "failed"
  );

  const firstLineObj = {
    text: textDecider(),
    date: customDateFormatter(created_at),
    isActive: true,
  };

  const validatingObj = {
    text: textDeciderValidating(),
    isActive: isOnProgress,
  };

  const partialFailedObj = {
    text: `${failedLocalTrxs.length} ${tLocalTransfer(" of ")} ${
      localTrxs.length
    } ${tLocalTransfer("transactions failed")}`,
    isActive: isPartialFailed,
    isPartialFailed,
    date: customDateFormatter(updated_at),
    reason: tLocalTransfer("Something's gone wrong. Contact us for details."),
  };

  const failedObj = {
    text: tLocalTransfer("Transaction failed"),
    isActive: isFailed,
    isFailed,
    date: customDateFormatter(updated_at),
    reason: tLocalTransfer("Something's gone wrong. Contact us for details."),
  };

  //   approverLineArray
  let approverLineArray = [];
  let isActiveArray = [];
  let rejectChecker = false;

  Object.keys(workflow_process).forEach((key, indexParent) => {
    const array = workflow_process[key];
    const isSingleArray = array?.length === 1;
    const result = array
      .map((item, index) => {
        if (rejectChecker) return null;

        const isFirst = index === 0;
        const isLast = index === array?.length - 1;

        const {
          user_role_name,
          user_name,
          user_email,
          approved_at,
          approved_by_user_id,
          approved_by_user_name,
          rejected_at,
          rejected_by_user_id,
          rejected_by_user_name,
          team_manager,
          bypassed,
        } = item;

        // just for payroll
        if (Boolean(rejected_at)) rejectChecker = true;

        const hasApproved = Boolean(approved_at);

        const date = hasApproved
          ? customDateFormatter(approved_at)
          : rejected_at
          ? customDateFormatter(rejected_at)
          : "";

        const {
          id: execId,
          created_at: execCreatedAt,
          name: execName,
        } = executor || {};

        if (state === "rejected_by_approver" && execId) {
          rejectChecker = true;

          return {
            ...item,
            date: customDateFormatter(execCreatedAt),
            text: `Rejected by ${execName}`,
            isRed: true,
          };
        }
        // just for payroll

        const nameDecider = () => {
          const isAdmin = user_role_name == "partner_admin";
          const isSuperAdmin = user_role_name === "partner_super_admin";
          const isBookkeeper = user_role_name === "partner_viewer";
          const isEmployee = user_role_name === "partner_maker";

          const haveUserName = user_name === "" || user_name;
          const userName = user_name === "" ? user_email : user_name;

          const isTeamManager = team_manager && haveUserName && user_id;

          const prefixAny = isBahasa ? "" : "Any ";

          const teamManagerTextSimplifiedDecider = (text = "") =>
            teamManagerTextDecider({ isTeamManager, text });

          if (approved_at) {
            if (id === approved_by_user_id) {
              return teamManagerTextSimplifiedDecider(tPayroll("You"));
            }

            return teamManagerTextSimplifiedDecider(approved_by_user_name);
          }

          if (rejected_at) {
            if (id === rejected_by_user_id) {
              return teamManagerTextSimplifiedDecider(tPayroll("You"));
            }

            return teamManagerTextSimplifiedDecider(rejected_by_user_name);
          }

          if (isAdmin) return `${prefixAny}Admin`;

          if (isBookkeeper) return `${prefixAny}Bookkeeper`;

          if (isSuperAdmin) {
            if (isSuperAdminUser) return tPayroll("You");
            return "Business Owner";
          }

          if (isEmployee) return `${prefixAny}Employee`;

          return teamManagerTextSimplifiedDecider(youOrNameFunc(userName));
        };

        const isReleaser =
          Object.keys(workflow_process).length - 1 === indexParent;

        const approveReleaseDecider = () => {
          if (isReleaser) return "approve & release";
          return "approve";
        };

        const rawName = nameDecider();
        const name = tReim(rawName);

        const textDeciderr = () => {
          if (
            hasApproved &&
            isReleaser &&
            (isInvoicePayment || isReimbursement)
          )
            return `${tPayroll("Payment has been released by")} ${name}`;
          if (hasApproved) {
            if (isReimbursement) return `${tReim("Reviewed by")} ${name}`;
            return `Approved by ${name}`;
          }
          if (rejected_at) return `Rejected by ${name}`;

          // if (isSingleArray) {
          //   return `Require ${name} to ${approveReleaseDecider()}`;
          // }

          const prefixDecider = () => {
            if (isReleaser) return tPayroll("Waiting payment from");
            if (isReimbursement) return "Waiting review from";

            return "Waiting approval from";
          };

          const prefix = prefixDecider();

          if (isFirst) return `${prefix} ${name}`;
          return `/${name}`;
          if (isLast) return `Or ${name} to ${approveReleaseDecider()}`;
          return `Or ${name}`;
        };

        const textDecider = () => {
          if (hasApproved) {
            if (bypassed)
              return (
                <>
                  <span>System bypassed</span>{" "}
                  <span style={{ fontFamily: "GothamMedium" }}>{name}</span>{" "}
                  <span>approval step.</span>
                </>
              );
            if (isReleaser)
              return `${tPayroll("Payment has been released by")} ${name}`;
            if (isReimbursement) return `${tReim("Reviewed by")} ${name}`;
            return `${tPayroll("Approved by")} ${name}`;
          }
          if (rejected_at) return `${tPayroll("Rejected by")} ${name}`;

          // if (isSingleArray) {
          //   return `Require ${name} to ${approveReleaseDecider()}`;
          // }

          const prefixDecider = () => {
            if (isReleaser) return tPayroll("Waiting payment from");
            if (isReimbursement) return tReim("Waiting review from");

            return tPayroll("Waiting approval from");
          };

          const prefix = prefixDecider();

          if (isFirst) return `${prefix} ${name}`;
          return `/${name}`;
          if (isLast) return `Or ${name} to ${approveReleaseDecider()}`;
          return `Or ${name}`;
        };

        const text = textDecider();

        return {
          ...item,
          date,
          text,
          isCustomText: hasApproved && bypassed,
          // isOrange: !hasApproved && isFirst,
          // isGreen: hasApproved,
          // isRed: Boolean(rejected_at),
          isApproverLine: true,
        };
      })
      .filter((array) => {
        return array;
      });

    const obj = { data: result };

    approverLineArray.push(obj);
    isActiveArray.push(Boolean(result.filter(({ date }) => date).length));
  });

  approverLineArray = approverLineArray
    .map((item, index) => {
      const isActive = isActiveArray[index - 1] || index == 0;
      return { ...item, isActive };
    })
    .filter(({ data }) => data.length > 0);
  //   approverLineArray

  const defaultCompletedLineObj = {
    text: tPayroll("Completed"),
    date: isCompleted ? customDateFormatter(updated_at) : "",
    isActive: isCompleted,
  };

  const invoiceCompletedArray = () => {
    if (isCompleted) {
      const {
        data: [lastApproveData],
      } = approverLineArray[approverLineArray?.length - 1];
      const { approved_by_user_name, approved_at } = lastApproveData;
      const approvedAt = customDateFormatter(approved_at);

      const completeArray = [
        {
          text: tPayroll("Transaction completed!"),
          isCompleted: true,
          isActive: true,
        },
      ];

      return completeArray;
    }
    return defaultCompletedLineObj;
  };

  const completedLineObj =
    isInvoicePayment || isReimbursement
      ? invoiceCompletedArray()
      : defaultCompletedLineObj;

  const cancelDefaultText = Boolean(cancelledObj)
    ? `
  ${tPayroll("Rejected by")}
  ${youOrNameFunc(cancelledObj?.name || userReject?.id)}
`
    : cancelByExpiredTime
    ? tInternational("Canceled by system")
    : `${tPayroll("Canceled by")} ${creatorName}
    `;

  const reimbursementCancelText = `Canceled by ${
    Boolean(last_rejected_receipt_by)
      ? youOrNameFunc(userReject?.name)
      : creatorName
  }`;
  // const cancelCauseExpired = last_rejected_receipt_by
  const cancelledLineObj = {
    text: isReimbursement ? reimbursementCancelText : cancelDefaultText,
    date: customDateFormatter(
      Boolean(cancelledObj) ? cancelledObj?.approved_at : updated_at
    ),
    isRejected: true,
    isActive: true,
  };

  if (cancelByExpiredTime) {
    if (isFailedCrossBorder) {
      cancelledLineObj.reason = tInternational(
        "We're sorry, there's an unexpected issue we couldn't catch. Please reach out to our team for more information."
      );
      cancelledLineObj.isCustomText = true;
      cancelledLineObj.text = tInternational("Transaction failed");
    } else {
      cancelledLineObj.reason = tInternational(
        "The payment deadline has been passed and there was a rate changes."
      );
      cancelledLineObj.isCustomText = true;
      cancelledLineObj.text = (
        <Fragment>
          <TextInlineMedium>{tInternational("Canceled")}</TextInlineMedium>{" "}
          {tInternational("by system")}
        </Fragment>
      );
    }
  }

  // Lines objs

  return {
    isDraft,
    isWorkflow,
    isApprover,
    isDrafter,
    cancelledObj,
    isCancelled,
    isValidating,
    isCompleted,
    isOnProgress,
    isRefunded,
    isAwaitingRefundStrict,
    firstLineObj,
    approverLineArray,
    completedLineObj,
    cancelledLineObj,
    validatingObj,
    rejection_note,
    isPartialFailed,
    partialFailedObj,
    isFailed,
    failedObj,
    isRejected,
  };
};
