const categoryWithHighestCountDecider = ({
  categories = [],
  transactions = [],
}) => {
  const hasData = Array.isArray(transactions) && transactions?.length > 0;
  const hasCategories = Array.isArray(categories) && categories?.length > 0;

  if (!hasData || !hasCategories) return null;

  // To assign the hash with category id as key, and the value is the frequency of the category id
  const categoryIdsHash = {};
  for (let i = 0; i < (transactions ?? []).length; i++) {
    const trx = (transactions ?? [])[i];
    const categoryId = trx?.category_id;
    const hasCategoryId = !!categoryId;
    if (!hasCategoryId) continue;
    const categoryIdInHash = categoryIdsHash[categoryId];
    const isAlreadyExistInHash = !!categoryIdInHash;
    if (!isAlreadyExistInHash) categoryIdsHash[categoryId] = 1;
    else categoryIdsHash[categoryId]++;
  }

  // To find the highest category count
  let highestCategoryCount = { key: null, count: 0, category: null };
  const hashKeys = Object.keys(categoryIdsHash);
  hashKeys.forEach((key) => {
    const foundCategory = categories.find(({ id }) => String(id) === key);
    if (!foundCategory) return;
    const isHigher = categoryIdsHash[key] > highestCategoryCount.count;
    if (isHigher) {
      highestCategoryCount.key = key;
      highestCategoryCount.count = categoryIdsHash[key];
      highestCategoryCount.category = foundCategory;
    }
  });

  return highestCategoryCount.category;
};

export default categoryWithHighestCountDecider;
