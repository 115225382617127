import { useEffect, useState } from "react";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { categoryWithHighestCountDecider } from "../helpers";
import { isEmpty } from "lodash";

const useSelectedCategoryOnBatch = ({ transactions = [] }) => {
  const [selectedCategory, setSelectedCategory] = useState({});

  const { categories } = useConstants();

  useEffect(() => {
    const canContinueEffect =
      categories?.length > 0 &&
      transactions?.length > 0 &&
      isEmpty(selectedCategory);

    if (!canContinueEffect) return;

    const categoryWithHighestCount = categoryWithHighestCountDecider({
      categories,
      transactions,
    });

    if (!categoryWithHighestCount) return;
    setSelectedCategory(categoryWithHighestCount);
  }, [categories, transactions, selectedCategory]);

  return { selectedCategory, setSelectedCategory };
};

export default useSelectedCategoryOnBatch;
