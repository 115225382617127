import { useRef } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { useModalHook } from "../../../../../components/Modals";
import { GothamRegular } from "../../../../../components/Text";
import CategoryIcon from "../CategoryIcon";
import CategoryIconSelectionDropdown from "../CategoryIconSelectionDropdown";
import styles from "./CategoryIconSelection.module.css";
import { useClickOutside } from "../../../../../universalFunctions/useClickOutside";

const CategoryIconSelection = ({
  t = () => {},
  closeOnClickOutside = false,
  selectedIcon = "",
  onSelect = () => {},
}) => {
  const { isOpen, close, toggle } = useModalHook();

  const dropdownRef = useRef();

  useClickOutside({
    ref: dropdownRef,
    clickOutside: () => closeOnClickOutside && close(),
  });

  return (
    <div className={styles["category-icon-selection"]}>
      <GothamRegular
        woFontColor
        className="font12"
        style={{ color: jackColors.neutral900 }}
      >
        {t("Category Icon")}
      </GothamRegular>
      <div className={styles["category-icon-selection-input"]} onClick={toggle}>
        <div className={styles["left-section"]}>
          <div className={styles["category-icon-label"]}>
            <CategoryIcon
              name={selectedIcon}
              fill={jackColors.neutral900}
              style={{ scale: "0.8" }}
            />
          </div>

          <GothamRegular woFontColor style={{ color: jackColors.neutral800 }}>
            {t("Select Icon")}
          </GothamRegular>
        </div>

        <JackIcons name="chevron-down" fill={jackColors.neutral600} />

        <CategoryIconSelectionDropdown
          t={t}
          ref={dropdownRef}
          isOpen={isOpen}
          selectedIcon={selectedIcon}
          style={{ top: "48px" }}
          onSelect={onSelect}
          onClose={(e) => {
            e.stopPropagation();
            close();
          }}
        />
      </div>
    </div>
  );
};

export default CategoryIconSelection;
