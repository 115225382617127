import React from "react";
import { jackColors } from "../../../assets/colors";
import { font12, icon20 } from "../../virtualAccountComponents/common/utils";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { GothamMedium } from "../../../components/Text";

const InfoBanner = ({ customBannerStyle, infoText, isOwnTextComponent }) => {
  const bannerStyle = {
    height: 44,
    width: "100%",
    padding: 12,
    display: "flex",
    gap: 8,
    backgroundColor: jackColors.neutral400,
    border: `1px solid ${jackColors.neutral600}`,
    borderRadius: 4,
    ...customBannerStyle,
  };

  return (
    <div style={bannerStyle}>
      <JackIcons name="info" fill={jackColors.neutral700} style={icon20} />
      {!isOwnTextComponent ? (
        <GothamMedium style={{ color: jackColors.neutral800, ...font12 }}>
          {infoText}
        </GothamMedium>
      ) : (
        infoText
      )}
    </div>
  );
};

export default InfoBanner;
